import React, { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { useQuoteContact } from '../../Contexts/QuoteContactProvider';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import { QuoteContactTypes } from "../../enums/GroupTypes";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { TextBox, Input } from '@progress/kendo-react-inputs';
import { postResponse } from "../../Services/Common/Post";
import { getResponse } from "../../Services/Common/Get";
import DataGridView from "../Layouts/DataGridView"; 

const SearchQuoteContact = ({ handleOkSetContactAndOpenSearch,setValidate,validate }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const { search, setSearch, contactList, setContactList, setFullName, total, setTotal, pageSize, setPageSize, pageNumberSelectItems, setPageNumberSelectItems , searchContactMobile , setSearchContactMobile } = useQuoteContact();
    const PAGE_SIZE = 50;
    const [skipSelectItems, setSkipSelectItems] = useState(0);
    const [takeSelectItems, setTakeSelectItems] = useState(50);
    const [itemspageNumber, setitemsPageNumber] = useState(1);

    const handleSearch = async () => {
        try {
          const wildcardSearch = search.replace(/\*|\?|_|\\|\#|\@|\$|\-|\=/g, '%');
    
          const searchh = {
            name: wildcardSearch,
            filter: 'all',
            pageNumber:itemspageNumber,// pageNumberSelectItems,
            pageSize: pageSize,
          };
    
          const result = await postResponse('Quote/searchquotecontacts', searchh);
          setContactList(result?.data);
          setTotal(result?.count);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    useEffect(() => {
        const fetchData = async () => {
          try {
            if (search === '') {
              // Fetch all users if search is empty
              const data = await getResponse('Quote/getallusers');
              setContactList(data);
              setTotal(data.length);

            } else if(search && validate){
              // Fetch data based on search criteria
              const wildcardSearch = search.replace(/\*|\?|_|\\|\#|\@|\$|\-|\=/g, '%');
              const searchh = {
                name: wildcardSearch,
                filter: 'all',
                pageNumber: itemspageNumber,
                pageSize: pageSize,
              };
              const result = await postResponse('Quote/searchquotecontacts', searchh);
              setContactList(result?.data);
              setTotal(result?.count);
              setValidate(false)
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        fetchData();
      }, [search, itemspageNumber, pageSize]);

    const columns = [
        { field: "fullName", title: "Name" },
        { field: "jobTitle", title: "Title" },
        { field: "mobile", title: "Phone" },
        { field: "email", title: "Email" }
    ];
    
   const paginate = (contactList, pageNumber, pageSize) => {
    const startIndex = (pageNumber - 1) * pageSize;
    return contactList.slice(startIndex, startIndex + pageSize);
  };

  const handlePageChangeSelectItems = ({ skip, take }) => {
    setSkipSelectItems(skip);
    setTakeSelectItems(take);
    setitemsPageNumber(skip / take + 1);
};
    const handleOkSetContact = () => {
                handleOkSetContactAndOpenSearch(selectedRows[0]?.fullName);
              };
    setFullName(selectedRows[0]?.fullName)
    setSearchContactMobile(selectedRows[0]?.mobile)
    return (
        <div className="search-quote-contact-container p-0">
            <div className="d-flex align-items-center">
                <Input
                    className="me-1"
                    id="contactSearch"
                    placeholder="Search"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
                <Button onClick={handleSearch}>Get</Button>
                {/* <Button onClick={() => setPageNumberSelectItems(1)}>Get</Button> */}
            </div>

            {/* Integrate DataGridView component */}
            <DataGridView
               
                data={paginate(contactList, itemspageNumber, PAGE_SIZE)}
                columns={columns}
                idField="userId"
                isHeaderCheckboxRendered={false}
                showEmptyRow={false}
                singleSelect={true}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                skip = {skipSelectItems}
                take={takeSelectItems}
                total={total}
                pageable={true}
                pageSize={pageSize}
                onPageChange={handlePageChangeSelectItems}
               //  onPageChange={({ skip, take }) => { setPageNumberSelectItems(skip / take + 1); }}
            />
        </div>
    );
};

export default SearchQuoteContact;