import React, { useState, useContext, useEffect, useRef, useMemo, useCallback } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Button } from "@progress/kendo-react-buttons";
import ItemLookup from "./ItemLookup";
import { getResponse } from "../../Services/Common/Get";
import LZString from 'lz-string';
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { useParams } from "react-router-dom";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { toast, Slide } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import LabourDataGrid from "../Qoutes/LabourHours";
import QteUserApprovalAction from "../Qoutes/QteUserApprovalAction";
import ImportLogDialog from "../Qoutes/ImportLogDialog";
import { confirmAlert } from "react-confirm-alert";
import { useFirstCustomer } from "../../Contexts/FirstQuoteCustomerContext";
import { CustomerNumberContext } from "../../Contexts/CustomerNumberContext";
import { QuoteCustomerIdContext } from "../../Contexts/QouteCustomerIdContext";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import GroupDataGridView from "../Layouts/GroupableDataGridView";
import { Loadstatuscontext } from "../../Contexts/LoadStatus";
import { useDataRow } from "../../Contexts/ItemGridRowContext";
import { useQtyRow } from "../../Contexts/ItemGridQtyRowContext";
import { useNettEaRow } from "../../Contexts/ItemGridNettEaRowContext";
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { useSystemSettings } from "../../Contexts/SystemSettingsContext";
import ImportDialog from "./ImportDialog";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { useRoundUp } from "../../Contexts/RoundUpProvider";
import { useMarginAndPrice } from "../../Contexts/MarginAndPriceProvider";
import * as XLSX from "xlsx";
import { useResetQuote } from "../../Contexts/ResetProvider";
import { useItemList } from "../../Contexts/ItemListProvider";
import { useSubGroupList } from "../../Contexts/SubGroupListContext";
import { useMarginRow } from "../../Contexts/IGMarginRowContext";
import { HiddenStatusContext } from "../../Contexts/HiddenStatusContext";
import HideRowContext from "../../Contexts/HideRowContext";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import { useNewItemList } from "../../Contexts/NewItemListContextProvider";
import ComponentPartsWhseSOHSub from "../Qoutes/ComponentPartsWhseSOHSub";
import ComponentPartsWhseSubSOH from "../Qoutes/ComponentPartsWhseSubSOH";
import { QteCurGridViewTypeContext } from "../../Contexts/QteCurGridViewTypeContext";
import { QteRepDiscCheckerContext } from "../../Contexts/QteRepDiscCheckerContext";
import { Tooltip } from "react-tooltip";
import ProgressBar from '@ramonak/react-progress-bar';
import {
    TreeList,
    TreeListToolbar,
    mapTree,
    extendDataItem,
    TreeListTextEditor,
    TreeListNumericEditor,
    TreeListDateEditor,
    TreeListBooleanEditor,
    TreeListSelectionCell,
    TreeListHeaderSelectionCell
} from "@progress/kendo-react-treelist";

import {
    getAllItemsInAGroup,
    getAllExistingSubGroupedItems,
    getAllExistingNonSubGroupedItems,
    getGroupedRowItems,
    getAllExistingGroupedItems,
    getGroupRowByGroup,
    getSubGroupRowByGroupAndSubGroup,
    getGroupRowIndex,
    getSubGroupRowIndex,
    getAllExistingSubGroupsByGroup,
    getSubGroupedRowItems,
    getExistingNonGroupedItems,
    getAllItemsWithoutGroupSubGroupAndDbExtra,
    getItemsWithoutAIandDX,
    sortItemListByPosition
} from "../../utils/GroupFilters";
import {
    calculateItemWisePropotionalTotalPrice,
    resetGroupSubGroupRows,
    calculateItemRepDisc,
    calculateItemMargin,
    calculateItemLcMargin,
    calculateItemActMargin,
    calculateItemFrcMargin,
    calculateNettEaForRepDiscChange,
    calculateItemTotalPrice,
    calculateItemTotalCost,
    calculateValuesOnSBLQtyChange,
    calculateProportion,
    calculatePropotionalNettEA,
    calculateItemDcOnLp,
    calculateGroupWiseTotal,
    calculateItemNettEAfromMargin,
    calculateGroupLevelNettEAFromMargin,
    calculateNettEaForDConLP,
    getListWithQtyBasedNettEA,
    gridCalculation,
    getQtyBasedValues,
    getSubGroupsWithoutDX,
    getQtyBasedValuesWithGroups,
    calculateMarginForNetEEPriceChange,
    calculateNettEaForDConLPChange,
    calculateNettEaForMarginChange,
    calculateTotalPriceForDConLPChange,
    calculateTotalPriceForMarginChange
} from "../../utils/Calculations";
import {
    isDbExtraAdded,
    getGroupedItemsWithoutDBExtra,
    getSubGroupedItemsWithoutDBExtra,
    getNonGroupedItemsWithoutDBExtra,
    getExistingGroupWiseDbExtraRow,
    getExistingNonGroupWiseDbExtraRow,
    getItemsWithoutDBExtra,
    getExistingDbExtraRows,
    getExistingSubGroupWiseDbExtraRow,
    getSubGroupItemsWithoutDBExtra,
    getGroupedRowItemsWithoutDBExtra,
    getDbExtraRowIndex,
} from "../../utils/DbExtra/DbExtraFilters";
import {
    calculateDbExtra,
    calcNettEAChangeDbExtraRow,
    getUpdatedGroupSubGroupDbExtraRow,
    getNonGroupDbExtraRow,
    getQtyBasedNonGroupDbExtraRow,
    getQtyBasedGroupDbExtraRow,
} from "../../utils/DbExtra/DbExtraCalculations";
import {
    getDbExtraRow,
    createGroupRow,
    createSubGroupRow,
    createEmptyRow,
    createNonSubGroupRow,
} from "../../utils/RowTypes";
import { usePartConsolidatedDetail } from "../../Contexts/PartConsolidatedDetailContext";
import { GroupItemTypes, GroupTypes, ResetQuoteTypes, NonGroupTypes } from "../../enums/GroupTypes";
import { postResponse } from "../../Services/Common/Post";
import { useSGLNettEA } from "../../Contexts/SGLNettEAProvider";
import { useDconlpRow } from "../../Contexts/IGDconlpRowContext";
import Spinner from "../Common/Spinner";
import { footerCalculation } from "../../utils/FooterCalculations/FooterCalculations";
import { Loader } from "@progress/kendo-react-indicators";
import { filter, isNumber, rest, set, update } from "lodash";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
//#region import approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
import { QuoteApprovalMsg } from "../../enums/QuoteNotifMessage";
//#endregion
import { usePriceListDetails } from "../../Contexts/PriceListDetailsProvider";
import { is } from "immutable";
import { sub } from "date-fns";
import { useImportData } from "../../Contexts/ImportUnitData";


const RepDiscFooterCell = (props) => {
    const totalRepDisc = props.data.reduce((total, item) => total + item.repDisc, 0);
    return <td>{totalRepDisc}</td>;
};

const TotalPriceFooterCell = (props) => {
    const totalTotalPrice = props.data.reduce((total, item) => total + item.totalPrice, 0);
    return <td>{totalTotalPrice}</td>;
};

const TotalCostFooterCell = (props) => {
    const totalTotalCost = props.data.reduce((total, item) => total + item.totalCost, 0);
    return <td>{totalTotalCost}</td>;
};

// Custom cell component for hideRow column
// In your checkbox rendering components
// const HideRowCell = (props) => {
//     const { dataItem, onToggle } = props;
//     const { hiddenStatus } = useContext(HiddenStatusContext);
//     const id = dataItem.rowId;
//     const selected = hiddenStatus[id]?.hideRow || false;
//     const isLastRowEmpty = id === undefined;
//     const isAssemblyHeader = dataItem.rowType === "A"; // Assuming 'type' field identifies Assembly Header
//     const isAssemblyItem = dataItem.rowType === "AI"; // Assuming 'type' field identifies Assembly Item
//     const isEngineeringItem = dataItem.stStockCode === "ENGINEERING";
//     const isLineNote = dataItem.rowType === "LN";
//     const isDbextraItem = dataItem.rowType === "DX";
//     const handleCheckboxChange = () => {
//         if (isAssemblyItem || isEngineeringItem) {
//             // Prevent toggling if it's an Assembly Item
//             return;
//         }
//         onToggle(dataItem, "hideRow");
//     };

//     useEffect(() => {
//         if (isAssemblyHeader || isDbextraItem) {
//             // Automatically toggle hide row on Assembly Header
//             onToggle(dataItem, "hideRow");
//         }
//     }, [isAssemblyHeader , isDbextraItem]);

//     return (
//         <td>
//             <input
//                 type="checkbox"
//                 className="form-check-input form-check-input ip-tick-show"
//                 checked={isAssemblyItem || isEngineeringItem ? true : selected} // Always checked for Assembly Item
//                 onChange={handleCheckboxChange}
//                 disabled={isLastRowEmpty || isAssemblyItem || isEngineeringItem || isLineNote} // Disabled for Assembly Item
//             />
//         </td>
//     );
// };


const HideRowCell = (props) => {
    if(props === undefined) return;
    const { dataItem, onToggle } = props;
    const { hiddenStatus, setHiddenStatus } = useContext(HiddenStatusContext);
    const id = dataItem.rowId;
    const isAssemblyItem = dataItem.rowType === "AI"; // 'AI' for Assembly Item
    const isAssemblyHeader = dataItem.rowType === "A"; // 'A' for Assembly Header
    const isEngineeringItem = dataItem.stStockCode === "ENGINEERING";
    const isLineNote = dataItem.rowType === "LN";
    const isDbextraItem = dataItem.rowType === "DX";
    const isLastRowEmpty = id === undefined;

    // Determine initial checked state, taking into account stored status and type-specific defaults
    const determineInitialCheckedState = () => {
        if (isAssemblyItem || isAssemblyHeader || isDbextraItem || isEngineeringItem) {
            return hiddenStatus[id]?.hideRow ?? true; // Default to true for these types, but respect stored status if present
        }
        return hiddenStatus[id]?.hideRow ?? false; // Default to false for other types
    };

    const [isChecked, setIsChecked] = useState(determineInitialCheckedState);

    // Handle checkbox change
    const handleCheckboxChange = () => {
        // Assembly Items, Engineering Items, or Line Notes should not toggle
        if (isAssemblyItem || isLineNote) {
            return;
        }
        const newStatus = !isChecked;
        setIsChecked(newStatus); // Update local component state
        onToggle(dataItem, "hideRow"); // Propagate changes upwards
        // Update the context to remember the change
        setHiddenStatus(prev => ({ ...prev, [id]: { ...prev[id], hideRow: newStatus } }));
    };

    return (
        <td>
            <input
                type="checkbox"
                className="form-check-input form-check-input ip-tick-show"
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={isLastRowEmpty || isAssemblyItem || isLineNote}
            />
        </td>
    );
};

// Custom cell component for hideQty column
const HideQtyCell = (props) => {
    const { dataItem } = props;
    const { hiddenStatus, handleColumnToggle } = useContext(HiddenStatusContext);
    const id = dataItem.rowId; // Assuming rowId uniquely identifies a row
    const selected = hiddenStatus[id]?.hideQty || false;
    const isLastRowEmpty = id === undefined;
    const isDisabled = isLastRowEmpty || dataItem.type === "DX" || dataItem.rowType === "LN"; // Disable if last row is empty or type is 'DX'

    const handleCheckboxChange = () => {
        if (!isDisabled) {
            handleColumnToggle(dataItem, "hideQty");
        }
    };

    return (
        <td>
            <input
                type="checkbox"
                className="form-check-input form-check-input ip-tick-show"
                checked={selected}
                onChange={handleCheckboxChange}
                disabled={isDisabled}
            />
        </td>
    );
};

// Custom cell component for hidePrice column
const HidePriceCell = (props) => {
    const { dataItem } = props;
    const { hiddenStatus, handleColumnToggle } = useContext(HiddenStatusContext);
    const id = dataItem.rowId; // Assuming rowId uniquely identifies a row
    const selected = hiddenStatus[id]?.hidePrice || false;
    const isLastRowEmpty = id === undefined || dataItem.rowType === "LN";

    const handleCheckboxChange = () => {
        handleColumnToggle(dataItem, "hidePrice");
    };

    return (
        <td>
            <input
                type="checkbox"
                className="form-check-input form-check-input ip-tick-show"
                checked={selected}
                onChange={handleCheckboxChange}
                disabled={isLastRowEmpty}
            />
        </td>
    );
};

//let columnsMapping = ["displayGroupName"];

const ItemsMainDataGrid = ({
    data = [],
    onAddGroup,
    onAddItem,
    totalPartLines,
    setTotalPartLines,
    setTotalCost,
    itemList,
    setItemList,
    mainCustomer,
    footerDisc,
    setFooterDisc,
    viewOptions,
    setviewOptions,
    //columnsMapping,
    itemGridSetup,
    excelExportRef,
    selectedView,
    maxActMargin,
    setActMargin,
    setDropdownValue,
    maxFrcMargin,
    setMaxFrcMargin,
    setSelectedView,
    handleItemGrid
    // roundupPrice,
    // setRoundupPrice
    // setImportShowProgress,
    // importShowProgress,
    // progressPercent,
    // setprogressPercent,



}) => {
    const [labourDialogVisible, setLabourDialogVisible] = useState(false);
    const [labourHoursForm, setLabourFormVisible] = useState(false);
    const [labourCostName,setLabourCostName] = useState("");
    const [componentPartsWhseDialogVisible, setComponentPartsWhseDialogVisible] = useState(false);
    const [componentPartsWhseDialogVisibleSub, setComponentPartsWhseDialogVisibleub] = useState(false);
    const [componentPartsWhseDialogVisibleSubB, setComponentPartsWhseDialogVisibleubB] = useState(false);
    const [isOnTop, setIsOnTop] = useState(false);
    const [isAtBottom, setIsAtBottom] = useState(false);
    const [isGroupingOnTop, setIsGroupingOnTop] = useState(false);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(2000);
    const [isImportFromExcel, setIsImportFromExcel] = useState(false); 
    const [importLog, setImportLog] = useState("");
    const [showImportLogDialog, setShowImportLogDialog] = useState(false);
    const [newFooterPrice, setNewFooterPrice] = useState(0);

    // const [itemListNew, setItemListNew] = useState([]);
    const [groupId, setGroupId] = useState();
    const [groupCount, setGroupCount] = useState(1);
    const [groupAssembly, setGroupAssembly] = useState(1);
    const [subGroupId, setSubGroupId] = useState();
    const [subGroupCount, setSubGroupCount] = useState(1);
    const [nonGroupId, setNonGroupId] = useState();
    const [storedGroupInfo, setStoredGroupInfo] = useState({}); // To store group info for regrouping
    //const [selectedView, setSelectedView] = useState(0);
    const [totalNettEA, setTotalNettEA] = useState(0);
    const [disableNote, setDisableNote] = useState(false);
    const [totalstEecostRound, setTotalstEecostRound] = useState(0);
    const [totalStFutureCost, setTotalStFutureCost] = useState(0);
    const [totalActCostEAExact, setTotalActCostEAExact] = useState(0);
    const { hiddenStatus, setHiddenStatus } = useContext(HiddenStatusContext);
    const [totalListPrice, setTotalListPrice] = useState(0);
    // const [nonGroupedId, setNonGroupedId] = useState();
    //const [hasCustomerId, setHasCustomerId] = useState(false);
    const [previousContext, setPreviousContext] = useState(null);
    const { hiddenRows, toggleRowHiddenStatus } = useContext(HideRowContext);
    const { quoteDetails, updateQuoteDetailsForId, isUngroup, setIsUngroup, isGroup, setIsGroup, checkedItems, setCheckedItems } = useQuoteDetails();
    const {
        setQuoteUpdated,
        isSelectedUnitAdded,
        setIsSelectedUnitAdded,
        isPastedUnits,
        setIsPastedUnits,
        setIsQuoteSaved, importItemShowProgress, setImportItemShowProgress,
        copyPasteItemShowProgress, setCopyPasteItemShowProgress,
        progress, setProgress,
        openShowProgress, setOpenShowProgress
    } = useNodeContext();
    const { updateSGNettEAStatusForId } = useSGLNettEA();
    const { firstCustomer, updateFirstCustomerForId } = useFirstCustomer();
    const { rowData, updateRowForId } = useDataRow();
    const { rowQtyData, updateQtyRowForId } = useQtyRow();
    const { rowMarginData, updateMarginRowForId } = useMarginRow();
    const { rowNettEaData, updateNettEaRowForId } = useNettEaRow();
    const { rowDconlpData, updateDconlpRowForId } = useDconlpRow();
    const { sgList, updateSubGroupListForId } = useSubGroupList();
    const [isSubGroupEnabled, setIsSubGroupEnabled] = useState(false);
    const [isInsertGroupEnabled, setIsInsertGroupEnabled] = useState(false);
    const [isInsertSubGroupEnabled, setIsInsertSubGroupEnabled] = useState(false);
    const [isInsertItems, setIsInsertItems] = useState(false);
    const [isAddDbExtra, setIsAddDbExtra] = useState(true);
    const [isInsertDbExtra, setIsInsertDbExtra] = useState(false);
    const [isEditLabourHoursEnabled, setIsEditLabourHoursEnabled] = useState(false);
    const [isInsertEditLabourHoursEnabled, setIsInserttEditLabourHoursEnabled] = useState(false);
    const [isExportedItems, setIsExportedItems] = useState(false);
    const [isExportedConsolidateItems, setIsExportedConsolidateItems] = useState(false);
    const [isFormModified, setIsFormModified] = useState(false);

    const customerIdContext = useContext(CustomerIdContext);
    const { qteCurGridViewType, setQteCurGridViewType, qteAuthType } = useContext(QteCurGridViewTypeContext);
    const { quoteRepDiscData, setQuoteRepDiscData } = useContext(QteRepDiscCheckerContext);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const { customerId } = useContext(CustomerIdContext);
    const { customerNumber } = useContext(CustomerNumberContext);
    const { quoteCustomerId, setQuoteCustomerId } = useContext(QuoteCustomerIdContext);
    const user = useContext(UserContext);
    const { LoadStatus, SetLoadStatus } = useContext(Loadstatuscontext);
    const { settings } = useSystemSettings(); // Access the context

    const newCustomerStockDetails = useRef();
    const previousMainCustomer = useRef();
    const nonGroupedId = useRef();
    const changedView = useRef();
    const changedScrollView = useRef();

    const [editLabourItems, setEditLabourItems] = useState([]);
    const [editLabourHeader, setEditLabourHeader] = useState([]);
    const [insertLabourItems, setInsertLabourItems] = useState(false);
    const [shouldAddEmptyRow, setShouldAddEmptyRow] = useState(true);
    const [insertIndex, setInsertIndex] = useState(-1);
    const [currentLevel, setCurrentLevel] = useState("");

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [importDialogVisible, setImportDialogVisible] = useState(false);
    const [importOldQuoteDialogVisible, setImportOldQuoteDialogVisible] = useState(false);

    const [insertLineNotes, setInsertLineNotes] = useState(false);

    //labour hours
    const [itemAssemblyList, setItemAssemblyList] = useState([]);
    const [currentMargin, setCurrentMargin] = useState(0);
    const [currtotalCost, setCurrtotalCost] = useState(0);
    const [currTotalPrice, setCurrTotalPrice] = useState(0);
    const [updatedItemListNew, setUpdatedItemListNew] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [loadItem, setLoadItem] = useState([]);
    const [totalTimeApprx, setTotalTimeApprx] = useState(0);
    const [currNetEA, setCurrNetEA] = useState(0);
    const [futCost, setFutCost] = useState(0);
    const [notEditableLabour, setNotEditableLabour] = useState(true);
    const [labourChanges, setLabourChanges] = useState(false);
    const { handleColumnToggle } = useContext(HiddenStatusContext);
    // const [bomPart, setBOMPart] = useState(false);
    const [debtorType, setDebtorType] = useState("");
    const [priceCategory, setpriceCategory] = useState("");
    // const hasCustomerId = !!customerIdContext.customerId;;
    const [lastAdded, setLastAdded] = useState({
        rowType: null, // 'group' or 'subgroup'
        groupId: null,
        subGroupId: null,
    });
    const [hasUngrouped, setHasUngrouped] = useState(false);
    const { allowSet, setAllowSet } = useResetQuote();
    const [isUngrouped, setIsUngrouped] = useState(false);
    const [isInMaingroup, setIsnMaingroup] = useState(false);
    //const excelExportRef = React.useRef(null);
    const excelExportPartConsolidateRef = React.useRef(null);
    // const [allowCopy, setAllowCopy] = useState(false);
    const {
        margin,
        setMargin,
        setTotal,
        totalCostAmt,
        setTotalCostAmt,
        newPrice,
        setNewPrice,
        newMargin,
        allowSetValue,
        setAllowSetValue,
        allowSetMarginValue,
        setAllowSetMarginValue,
        setItemGridCount,
        totalItemPrice, setTotalItemPrice, maxMargin, setMaxMargin, isMarginSelected,
        setIsMarginSelected, isPriceSelected,
        setIsPriceSelected,
    } = useMarginAndPrice();

    const { originalItems, updateItemListForId } = useItemList();
    const { totalCompParts, setTotalCompParts, selectedItemData, setSelectedItemData, stockNumber, setStockNumber, isDesc, setIsDesc, isType, setIsType, isSOH, setIsSOH, isPartNo, setIsPartNo, bomisChecked, setBomIsChecked, stockCode, setStockCode, ststatus, setStstatus, totalSoh, setTotalSoh, compcode, setCompcode, compstatus, setCompstatus, compSOH, setCompSOH, selectedStockType, setSelectedStockType, bomPart, setBOMPart, selectedComponents, setSelectedComponents, substockCode, setSubStockCode, bomPartSub, setBOMPartSub, subSOH, setSubSOH, visiblePasteOption, setVisiblePasteOption
        // setBomIsChecked,
        // setVisiblePasteOption,
        // visiblePasteOption,
        // bomisChecked,
        // stockCode,
        // setTotalSoh,
        // setStockCode,
        // bomPart,
        // setBOMPart,
        // setBOMPartSub,
        // compstatus,
        // selectedComponents,
        // setSelectedComponents,selectedItemData, setSelectedItemData
    } = useStockCode();
    const { itemListNew, setItemListNew, allowCopy, setAllowCopy } = useNewItemList();
    //const fileInputRef = useRef(null);

    //const { partConsolidatedDetail, updatePartConsolidatedDetailForId } = usePartConsolidatedDetail();
    const {
        partConsolidatedDetail,
        allowAddValue,
        setAllowAddValue,
        differ,
        setDiffer,
        differNettEA,
        setDifferNettEA,
        isAppliedDeleted,setIsAppliedDeleted,
        isDconLpChanged, setIsDconLpChanged, isMarginChanged, setIsMarginChanged
    } = usePartConsolidatedDetail();
    //unit
    const { unitCopy, setUnitCopy, copiedUnits, setCopiedUnits, unitSelected, setUnitSelected, selectedUnitData, setSelectedUnitData, selectedSubUnitIndex, setSelectedSubUnitIndex, selectedUnitIndex, setSelectedUnitIndex, selectedUnitName, setSelectedUnitName } = useUniteDetails();
    //import items
    const { isNotLastRow, SetIsNotLastRow } = useImportData();

    const { id } = useParams();
    const [consolidateItems, setConsolidateItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [originalQuoteId, setOriginalQuoteId] = useState();
    //use for authorization
    const { getQuoteColMap, getQuoteViewOpt, getAccountRole, columnsMapping,
        setColumnsMapping } = useAuthorizationContext();
    const authType = getAccountRole();
    //part consolidate grid
    const [consolidateGrid, setConsolidateGrid] = useState(false);
    const { roundupPrice, setRoundupPrice } = useRoundUp();
    //let [viewOptions, setviewOptions] = useState([]);
    const [groupBtn, setGroupBtn] = useState(false);
    let HAS_UNGROUPED = false;
    let isEnabledAddGroup = true;
    // let noViewAccessSetup = 0;
    // let index = 0;
    // let text = "";
    // let viewType = [];
    // let viewText = "";

    const [loaderType, setLoaderType] = useState("pulsing");
    const [showLoaderBtn, setShowLoaderBtn] = useState(false);

    //item grid crashing issue fixed
    const [addButtonClick, setAddButtonClick] = useState(false);

    //PriceList
    const { priceListDetails, updatePriceListDetailsForId, setIsPriceListCopyed, isPriceListCopyed, copiedPriceList, setCopiedPriceList } = usePriceListDetails();

    // const handleItemGrid = (index, viewType) => {
    //     columnsMapping = getQuoteColMap(index, viewType);

    //     const excelColumns = columnsMapping.map((column) => (
    //         <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
    //     ));

    //     excelExportRef.current.columns = excelColumns;
    // };

    //component part forms
    const toggleComponentPartsWhseSub = () => {
        setBomIsChecked(true);
        setBOMPartSub(bomPartSub);

        setStockCode(stockNumber)

        setComponentPartsWhseDialogVisibleub(!componentPartsWhseDialogVisibleSub);
    };

    const toggleComponentPartsWhseSubB = () => {
        setBomIsChecked(true);
        setBOMPartSub(bomPartSub);
        const abc = compcode === '' || compcode === undefined ? '' : compcode[compcode.length - 1].compcode;
        const sohvalue = compcode === '' || compcode === undefined ? 0 : compcode[compcode.length - 1].soh;

        setSubStockCode(abc);
        setSubSOH(sohvalue);

        setComponentPartsWhseDialogVisibleubB(!componentPartsWhseDialogVisibleSubB);
    };

  

    //#region reset quote
    useEffect(() => {
        const fetchDataAndProcess = async () => {
            let systemSettings = null;
            try {
                if (settings === null) {
                    const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                        userId: user?.homeAccountId,
                    });
                    systemSettings = data[0];
                } else {
                    systemSettings = settings;
                }
                if (allowSet === ResetQuoteTypes.SETDEFAULT) {
                    await handleSetDefault(settings);
                } else if (allowSet === ResetQuoteTypes.keepnettEA) {
                    await handleKeepNettEA(settings);
                } else if (allowSet === ResetQuoteTypes.KEEPMARGIN) {
                    await handleKeepMargin(settings);
                }
            } catch (error) {
                console.error("Error fetching Reset Quote:", error);
            }
        };
        
        fetchDataAndProcess();
    }, [allowSet, mainCustomer]);
    const handleSetDefault = async (systemSettings) => {
        setIsLoading(true);
        // setImportShowProgress(true);
        const _loadStatus = true;
        let selectedItemsStId = [];
        let curMargin = 0;
        if (_loadStatus && quoteDetails[id]) {

            try {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", { userId: user?.homeAccountId });
                if(data) {
                    curMargin = data.map((item) => item.lowerMargine);
                }
                if (quoteDetails[id]?.savedQuote?.tblQuoteItems_Drafts) {
                    selectedItemsStId = quoteDetails[id]?.selectedItems.filter((f) =>
                        f.rowType !== "DX" && f.rowType !== "LN" && f.rowType !== "AI" && f.rowType !== "A"
                        && f.rowType !== "CI" && f.rowType !== "G" && f.rowType !== "SG" && f.rowType !== ""
                    );

                }
                else {
                    selectedItemsStId = quoteDetails[id]?.savedQuote?.tblQuoteItems.filter((f) =>
                        f.rowType !== "DX" && f.rowType !== "LN" && f.rowType !== "AI" && f.rowType !== "A"
                        && f.rowType !== "CI" && f.rowType !== "G" && f.rowType !== "SG" && f.rowType !== ""
                    );
                }

                selectedItemsStId = selectedItemsStId.map((item) => ({
                    stStockCode: item.stStockCode ? item.stStockCode : "",
                    qty: item.qty ? item.qty : "",
                }));

                var url = `Stocks/getstockdetailsusingimport?CustomerId=${mainCustomer}&UserId=${user?.homeAccountId}`;
                const response = await postResponse(url, selectedItemsStId);
                // const response = await getResponse("Stocks/getstockdetailsusingarray", {
                //     stStockCodes: String(StIds),
                //     CustomerId: mainCustomer,
                //     UserId: user?.homeAccountId,
                // });

                const stockDetails = response;
                newCustomerStockDetails.current = stockDetails;
                const updatedItemList =
                    itemList.length > 0 &&
                    itemList.map((item) => {
                        const index = stockDetails.findIndex((stock) => {
                            if (stock) {
                                return stock.stStockCode === item.stStockCode;
                            }
                        });

                        const itemListIndex = itemList.findIndex(
                            (stock) => {
                                if (stock) {
                                    return stock.rowId === item.rowId && stock.stStockCode === item.stStockCode;
                                }
                            }
                            // (stock) => stock.stStockCode === item.stStockCode
                        );

                        if (index !== -1 && item.rowType !== "DX") {
                            const updatedNettEa = stockDetails[index].nettEA
                                ? stockDetails[index].nettEA
                                : 0;
                            const updatedQty = itemList[itemListIndex].qty;

                            const updatedTotalPrice = calculateItemTotalPrice(
                                itemList[itemListIndex].qty,
                                stockDetails[index].nettEA
                            );

                            // const updatedTotalCost = parseFloat(
                            //     parseFloat(
                            //         stockDetails[index].stEecost * itemList[itemListIndex].qty
                            //     ).toFixed(2)
                            // );

                            const updatedTotalCost = calculateItemTotalCost(
                                itemList[itemListIndex].qty,
                                stockDetails[index].stEecost
                            );

                            const updatedDcOnLp =
                                typeof stockDetails[index].dcOnLp === "string"
                                    ? // ? parseFloat(
                                    //       ((stockDetails[index].listPrice -
                                    //           stockDetails[index].nettEA) /
                                    //           stockDetails[index].listPrice) *
                                    //           100
                                    //   ).toFixed(1)
                                    calculateItemDcOnLp(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].listPrice
                                    )
                                    : // parseFloat(
                                    //       ((stockDetails[index].listPrice -
                                    //           stockDetails[index].nettEA) /
                                    //           stockDetails[index].listPrice) *
                                    //           100
                                    //   ).toFixed(1);
                                    calculateItemDcOnLp(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].listPrice
                                    );

                            const updatedMargin =
                                typeof itemList[itemListIndex].margin == "string"
                                    ? calculateItemMargin(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].stEecost
                                    )
                                    : calculateItemMargin(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].stEecost
                                    );


                            const actMargin = calculateItemActMargin(
                                stockDetails[index].nettEA,
                                stockDetails[index].actCostExact
                            );


                            const frcMargin = calculateItemFrcMargin(
                                stockDetails[index].nettEA,
                                stockDetails[index].stFutureCost
                            );

                            const lcMargin = calculateItemLcMargin(
                                stockDetails[index].nettEA,
                                stockDetails[index].lastCostExact
                            );
                            const listPrice = stockDetails[index].listPrice === 0 ? '' : stockDetails[index].listPrice;
                            const l60 = stockDetails[index].l60 === 0 ? '' : stockDetails[index].l60;
                            const l55 = stockDetails[index].l55 === 0 ? '' : stockDetails[index].l55;
                            const l50 = stockDetails[index].l50 === 0 ? '' : stockDetails[index].l50;
                            const l20 = stockDetails[index].l20 === 0 ? '' : stockDetails[index].l20;

                            return {
                                ...item,
                                nettEA: updatedNettEa,
                                totalPrice: updatedTotalPrice,
                                repDisc: 0,
                                dcOnLp: updatedDcOnLp,
                                margin: updatedMargin,
                                actMargin: actMargin,
                                frcMargin: frcMargin,
                                lcMargin: lcMargin,
                                // // qty: updatedQty, //// do not included resetting of qty
                                totalCost: updatedTotalCost,
                                // stFutureCostRound: parseFloat(
                                //     itemList[itemListIndex].stFutureCost
                                // ).toFixed(2),
                                // l60: l60,
                                // l55: l55,
                                // l50: l50,
                                // l20: l20,
                                // listPrice: listPrice
                            };
                        } else if (item.rowType === "A") {
                            return {
                                ...item,
                                nettEA: parseFloat((item.stEecost / ((100 - Number(curMargin)) / 100)).toFixed(2)),
                                totalPrice: parseFloat(Number(item.qty) * parseFloat((item.stEecost / ((100 - Number(curMargin)) / 100)).toFixed(2))).toFixed(2),
                                repDisc: 0,
                                margin: parseFloat(curMargin),
                                actMargin: parseFloat(curMargin),
                                frcMargin: parseFloat(curMargin),
                                lcMargin:  "",
                            };
                        } else {
                            return item;
                        }
                    });
                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setNewPrice(0);
                setComputeTotals(true);
            } catch (error) {
                console.error("API Error:", error);
                toast.error("Error setting the default price.");
                setIsLoading(false);
            } finally {
                setIsLoading(false);
                // setImportShowProgress(false);
            }

        } else {
            setIsLoading(false);
            // setImportShowProgress(false);
        }

        previousMainCustomer.current = mainCustomer;
        setAllowSet(null);
    }

    const handleKeepNettEA = async (systemSettings) => {
        setIsLoading(true);
        // setImportShowProgress(true);
        const _loadStatus = true;
        const selectedItemsStId = [];
        if (_loadStatus && quoteDetails[id] && quoteDetails[id].selectedItems) {
            for (let i = 0; i < quoteDetails[id].selectedItems.length; i++) {
                if (quoteDetails[id].selectedItems[i].rowType !== "DX"
                    && quoteDetails[id].selectedItems[i].rowType !== "LN"
                    && quoteDetails[id].selectedItems[i].rowType !== "AI"
                    && quoteDetails[id].selectedItems[i].rowType !== "A"
                    && quoteDetails[id].selectedItems[i].rowType !== "CI"
                    && quoteDetails[id].selectedItems[i].rowType !== "G"
                    && quoteDetails[id].selectedItems[i].rowType !== "SG") {
                    selectedItemsStId.push(quoteDetails[id].selectedItems[i].stStockCode);
                }
            }

            const StIds = JSON.stringify(selectedItemsStId).replace("[", "").replace("]", "").replace(/"/g, "");
            try {
                const response = await getResponse("Stocks/getstockdetailsusingpartno", {
                    stStockCodes: String(StIds),
                    CustomerId: mainCustomer,
                    UserId: user?.homeAccountId,
                });
                const stockDetails = response;
                newCustomerStockDetails.current = stockDetails;
                const updatedItemList =
                    itemList.length > 0 &&
                    itemList.map((item) => {
                        const index = stockDetails.findIndex((stock) => {
                            if (stock) {
                                return stock.stStockCode === item.stStockCode;
                            }
                        });

                        const itemListIndex = itemList.findIndex((stock) => {
                            if (stock) {
                                return stock.stStockCode === item.stStockCode;
                            }
                        });

                        if (index !== -1) {
                            const updatedNettEa = itemList[itemListIndex].nettEA
                                ? itemList[itemListIndex].nettEA
                                : 0;
                            const updatedQty = itemList[itemListIndex].qty;

                            const updatedTotalPrice = calculateItemTotalPrice(
                                itemList[itemListIndex].qty,
                                itemList[itemListIndex].nettEA
                            );

                            const updatedTotalCost = calculateItemTotalCost(
                                itemList[itemListIndex].qty,
                                stockDetails[index].stEecost
                            );

                            const updatedRepDisc = calculateItemRepDisc(
                                itemList[itemListIndex].nettEA,
                                stockDetails[index].nettEA
                            );

                            const updatedDcOnLp =
                                typeof itemList[itemListIndex].dcOnLp === "string"
                                    ? parseFloat(itemList[itemListIndex].dcOnLp).toFixed(1)
                                    : parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));

                            const updatedMargin =
                                typeof itemList[itemListIndex].margin == "string"
                                    ? calculateItemMargin(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].stEecost
                                    )
                                    : calculateItemMargin(
                                        stockDetails[index].nettEA,
                                        stockDetails[index].stEecost
                                    );

                            const actMargin = parseFloat(
                                parseFloat(stockDetails[index].actMargin).toFixed(1)
                            );
                            const frcMargin = parseFloat(
                                parseFloat(stockDetails[index].frcMargin).toFixed(1)
                            );

                            const lcMargin = parseFloat(
                                parseFloat(stockDetails[index].lcMargin).toFixed(1)
                            );
                            const listPrice = stockDetails[index].listPrice === 0 ? '' : stockDetails[index].listPrice;
                            const l60 = stockDetails[index].l60 === 0 ? '' : stockDetails[index].l60;
                            const l55 = stockDetails[index].l55 === 0 ? '' : stockDetails[index].l55;
                            const l50 = stockDetails[index].l50 === 0 ? '' : stockDetails[index].l50;
                            const l20 = stockDetails[index].l20 === 0 ? '' : stockDetails[index].l20;
                            return {
                                ...item,
                                ...stockDetails[index],
                                nettEA: updatedNettEa,
                                totalPrice: updatedTotalPrice,
                                repDisc: updatedRepDisc < 0 ? 0 : updatedRepDisc,
                                dcOnLp: updatedDcOnLp,
                                margin: updatedMargin,
                                actMargin: actMargin,
                                frcMargin: frcMargin,
                                lcMargin: lcMargin,
                                qty: updatedQty,
                                totalCost: updatedTotalCost,
                                stFutureCostRound: parseFloat(stockDetails[index].stFutureCost).toFixed(
                                    2
                                ),
                                l60: l60,
                                l55: l55,
                                l50: l50,
                                l20: l20,
                                listPrice: listPrice,
                            };
                        } else {
                            return item;
                        }
                    });
                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setComputeTotals(true);
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setIsLoading(false);
                // setImportShowProgress(false);
            }

        } else {
            setIsLoading(false);
            // setImportShowProgress(false);
        }

        previousMainCustomer.current = mainCustomer;

    }


    const handleKeepMargin = async (systemSettings) => {
        setIsLoading(true);
        // setImportShowProgress(true);
        const _loadStatus = true;
        const selectedItemsStId = [];
        if (_loadStatus && quoteDetails[id] && quoteDetails[id].selectedItems) {
            for (let i = 0; i < quoteDetails[id].selectedItems.length; i++) {
                if (quoteDetails[id].selectedItems[i].rowType !== "DX"
                    && quoteDetails[id].selectedItems[i].rowType !== "LN"
                    && quoteDetails[id].selectedItems[i].rowType !== "AI"
                    && quoteDetails[id].selectedItems[i].rowType !== "A"
                    && quoteDetails[id].selectedItems[i].rowType !== "CI"
                    && quoteDetails[id].selectedItems[i].rowType !== "G"
                    && quoteDetails[id].selectedItems[i].rowType !== "SG") {
                    selectedItemsStId.push(quoteDetails[id].selectedItems[i].stStockCode);
                }
            }

            const StIds = JSON.stringify(selectedItemsStId).replace("[", "").replace("]", "").replace(/"/g, "");
            try {
                const response = await getResponse("Stocks/getstockdetailsusingarray", {
                    stStockCodes: String(StIds),
                    CustomerId: mainCustomer,
                    UserId: user?.homeAccountId,
                });
                const stockDetails = response;
                newCustomerStockDetails.current = stockDetails;
                const updatedItemList =
                    itemList.length > 0 &&
                    itemList.map((item) => {
                        const index = stockDetails.findIndex((stock) => {
                            if (stock) {
                                return stock.stStockCode === item.stStockCode;
                            }
                        });

                        const itemListIndex = itemList.findIndex(
                            (stock) => {
                                if (stock) {
                                    return stock.stStockCode === item.stStockCode;
                                }
                            }
                            // (stock) => stock.stStockCode === item.stStockCode
                        );

                        if (index !== -1) {
                            const updatedNettEa = stockDetails[index].nettEA
                                ? stockDetails[index].nettEA
                                : 0;
                            const updatedQty = itemList[itemListIndex].qty;

                            const updatedTotalPrice = parseFloat(
                                parseFloat(
                                    stockDetails[index].nettEA * itemList[itemListIndex].qty
                                ).toFixed(2)
                            );
                            const updatedTotalCost = parseFloat(
                                parseFloat(
                                    itemList[itemListIndex].stEecost * itemList[itemListIndex].qty
                                ).toFixed(2)
                            );

                            const updatedRepDisc =
                                stockDetails[index].nettEA === 0
                                    ? 0.0
                                    : parseFloat(
                                        100 -
                                        (itemList[itemListIndex].nettEA /
                                            stockDetails[index].nettEA) *
                                        100
                                    ).toFixed(1);

                            const updatedDcOnLp =
                                typeof itemList[itemListIndex].dcOnLp === "string"
                                    ? parseFloat(itemList[itemListIndex].dcOnLp).toFixed(1)
                                    : parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));
                            // const updatedDcOnLp = parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));

                            const updatedMargin =
                                typeof itemList[itemListIndex].margin == "string"
                                    ? parseFloat(itemList[itemListIndex].margin.toFixed(1))
                                    : parseFloat(itemList[itemListIndex].margin.toFixed(1));

                            const actMargin = parseFloat(
                                parseFloat(itemList[itemListIndex].actMargin).toFixed(1)
                            );
                            const frcMargin = parseFloat(
                                parseFloat(itemList[itemListIndex].frcMargin).toFixed(1)
                            );

                            const lcMargin = parseFloat(
                                parseFloat(itemList[itemListIndex].lcMargin).toFixed(1)
                            );

                            return {
                                ...item,
                                ...stockDetails[index],
                                nettEA: updatedNettEa,
                                totalPrice: updatedTotalPrice,
                                repDisc: updatedRepDisc < 0 ? 0 : updatedRepDisc,
                                dcOnLp: updatedDcOnLp,
                                margin: updatedMargin,
                                actMargin: actMargin,
                                frcMargin: frcMargin,
                                lcMargin: lcMargin,
                                qty: updatedQty,
                                totalCost: updatedTotalCost,
                                stFutureCostRound: parseFloat(
                                    itemList[itemListIndex].stFutureCost
                                ).toFixed(2),
                            };
                        } else {
                            return item;
                        }
                    });
                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setComputeTotals(true);
            } catch (error) {
                console.error("API Error:", error);
            } finally {
                setIsLoading(false);
                // setImportShowProgress(false);
            }

        } else {
            setIsLoading(false);
            // setImportShowProgress(false);
        }

        previousMainCustomer.current = mainCustomer;

    }


    //#endregion



    useEffect(() => {
        if (roundupPrice) {
            //#region 
            // const items = [...itemList];
            // const updatedItemList = items.map((item) => {
            //     // if (item.displayGroupName !== " " || item.displaySubGroupName !== " ") {
            //     if (item.rowType !== "G" && item.rowType !== "SG" && item.rowType !== "DX") {
            //         const itemListIndex = itemList.findIndex(
            //             (stock) => {
            //                 if (stock) {
            //                     return stock.stStockCode === item.stStockCode;
            //                 }
            //             }
            //             // (stock) => stock.stStockCode === item.stStockCode
            //         );
            //         // return {...item,nettEA :parseFloat (parseFloat(item.nettEA)).toFixed(1)}
            //         const updatedNettEa = itemList[itemListIndex].nettEA
            //             ? parseFloat(parseFloat(item.nettEA).toFixed(1))
            //             : 0;
            //         const updatedTotalPrice = parseFloat(
            //             parseFloat(updatedNettEa * itemList[itemListIndex].qty).toFixed(2)
            //         );
            //         const updatedRepDisc = "";
            //         const updatedDcOnLp =
            //             typeof itemList[itemListIndex].dcOnLp === "string"
            //                 ? parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1))
            //                 : parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));
            //         // const updatedDcOnLp = parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));
            //         const updatedMargin =
            //             typeof itemList[itemListIndex].margin == "string"
            //                 ? parseFloat(parseFloat(itemList[itemListIndex].margin).toFixed(1))
            //                 : parseFloat(itemList[itemListIndex].margin.toFixed(1));
            //         const frcMargin = parseFloat(parseFloat(itemList[itemListIndex].frcMargin).toFixed(1));
            //         return {
            //             ...item,
            //             nettEA: updatedNettEa,
            //             totalPrice: updatedTotalPrice,
            //             repDisc: updatedRepDisc < 0 ? 0 : updatedRepDisc,
            //             dcOnLp: updatedDcOnLp,
            //             margin: updatedMargin,
            //             // actMargin: actMargin,
            //             frcMargin: frcMargin,
            //             // lcMargin: lcMargin,
            //         };
            //     } else {
            //         return item;
            //         // return {
            //         //     ...item,
            //         //     repDisc: updatedRepDisc < 0 ? 0 : updatedRepDisc,
            //         //     frcMargin: item.frcMargin,
            //         //     actMargin: item.actMargin,
            //         //     lcMargin: item.lcMargin,
            //         // };
            //     }
            // });
            // const dbExtraCharge = parseInt(settings?.dbextraCharge);
            // console.log("dbExtraCharge", dbExtraCharge);
            // const updatedList = gridCalculation(updatedItemList, dbExtraCharge);
            // const updatedList = roundupPriceFn();
            // console.log("updatedList", updatedList);
            // setItemList(updatedList);
            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
            // setRoundupPrice(false);
            // const totalPrice = calculateTotalPrice(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalItemPrice(totalPrice);
            // const totalCost = calculateTotalCost(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalCost(totalCost);
            // const totalneeta = calculateTotalNettEA(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalNettEA(totalneeta);
            // const totalstEecostRound = calculateTotalstEecostRound(
            //     updatedList.filter((item) => item.stStockCode != " ")
            // );
            // setTotalstEecostRound(totalstEecostRound);
            // const totalStFutureCost = calculateTotalStFutureCost(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalStFutureCost(totalStFutureCost);
            // const totalActCostEa = calculateTotalActCostEAExact(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalActCostEAExact(totalActCostEa);
            // const totalListPrice = calculateTotalListPrice(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalListPrice(totalListPrice);
            //#endregion

            roundupPriceFn();
        }

        async function roundupPriceFn() {
            let systemSettings = null;

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            const items = itemPositioning(true, itemList);

            const updatedItemList = items.map((item) => {
                // if (item.displayGroupName !== " " || item.displaySubGroupName !== " ") {
                if (item.rowType === "" && item.stStockCode.length === 0) return item; /// this is an empty row / last row
                if (item.rowType !== "G" && item.rowType !== "SG" && item.rowType !== "DX" && item.rowType !== "LN") {
                    const itemListIndex = itemList.findIndex(
                        (stock) => {
                            if (stock) {
                                return stock.rowId === item.rowId;
                            }
                        }
                        // (stock) => stock.stStockCode === item.stStockCode
                    );
                    // return {...item,nettEA :parseFloat (parseFloat(item.nettEA)).toFixed(1)}
                    if (itemListIndex !== -1) {
                        const updatedNettEa = itemList[itemListIndex].nettEA
                            ? parseFloat(parseFloat(item.nettEA).toFixed(1))
                            : 0;

                        const updatedTotalPrice = parseFloat((updatedNettEa * itemList[itemListIndex].qty).toFixed(1));
                        const repDiscValue = itemList[itemListIndex].repDisc;

                        const updatedRepDisc = repDiscValue !== undefined && repDiscValue !== ""
                            ? repDiscValue >= 0
                                ? parseFloat((Number(repDiscValue || 0)).toFixed(1))
                                : (repDiscValue || 0)
                            : 0;

                        // const updatedRepDisc = parseFloat(itemList[itemListIndex].repDisc.toFixed(1));

                        const dconlpValue = itemList[itemListIndex].dcOnLp;

                        const updatedDcOnLp = dconlpValue !== undefined && dconlpValue !== ""
                            ? dconlpValue >= 0
                                ? parseFloat(dconlpValue.toFixed(1))
                                : dconlpValue
                            : 0;
                        //   const updatedDcOnLp = parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));

                        const marginValue = itemList[itemListIndex].margin;

                        const updatedMargin = marginValue !== undefined && marginValue !== ""
                            ? marginValue >= 0
                                ? parseFloat(marginValue.toFixed(1))
                                : dconlpValue
                            : 0;
                        //const updatedMargin = parseFloat(itemList[itemListIndex].margin.toFixed(1));
                        const frcValue = itemList[itemListIndex].frcMargin;

                        const frcMargin = frcValue !== undefined && frcValue !== ""
                            ? frcValue >= 0
                                ? parseFloat(frcValue.toFixed(1))
                                : frcValue
                            : 0;
                        //const frcMargin = parseFloat(parseFloat(itemList[itemListIndex].frcMargin).toFixed(1));


                        return {
                            ...item,
                            ...itemList[itemListIndex],
                            nettEA: updatedNettEa,
                            totalPrice: updatedTotalPrice,
                            repDisc: updatedRepDisc < 0 ? 0 : updatedRepDisc,
                            dcOnLp: updatedDcOnLp,
                            margin: updatedMargin,
                            // actMargin: actMargin,
                            frcMargin: frcMargin,
                            // lcMargin: lcMargin,
                        };
                    }
                }
                return item;
            });

            // const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
            // const updatedList = gridCalculation(updatedItemList, dbExtraCharge);
            // const sortedItem = itemPositioning(true, updatedItemList);
            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            setRoundupPrice(false);
            setComputeTotals(true);
            //#region 
            // footer calculation
            // const {
            //     totalPrice,
            //     totalSteEeCostRound,
            //     totalStFutureCost,
            //     totalActCostExact,
            //     totalListPrice,
            //     totalNettEA,
            //     totalCost,
            //     totalMargin,
            //     totalActMargin,
            //     totalFrcMargin,
            //     totalDcOnLp,
            // } = footerCalculation(updatedList);

            // setTotalItemPrice(totalPrice);
            // setTotalstEecostRound(totalSteEeCostRound);
            // setTotalStFutureCost(totalStFutureCost);
            // setTotalActCostEAExact(totalActCostExact);
            // setTotalListPrice(totalListPrice);
            // setTotalCost(totalCost);
            // setTotalNettEA(totalNettEA);
            // setMaxMargin(totalMargin);
            // setActMargin(totalActMargin);
            // setMaxFrcMargin(totalFrcMargin);
            // setFooterDisc(totalDcOnLp);
            // setApprovedLimit(totalListPrice);
            // const totalPrice = calculateTotalPrice(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalItemPrice(totalPrice);

            // const totalCost = calculateTotalCost(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalCost(totalCost);

            // const totalneeta = calculateTotalNettEA(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalNettEA(totalneeta);

            // const totalstEecostRound = calculateTotalstEecostRound(
            //     updatedList.filter((item) => item.stStockCode != " ")
            // );
            // setTotalstEecostRound(totalstEecostRound);

            // const totalStFutureCost = calculateTotalStFutureCost(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalStFutureCost(totalStFutureCost);

            // const totalActCostEa = calculateTotalActCostEAExact(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalActCostEAExact(totalActCostEa);

            // const totalListPrice = calculateTotalListPrice(updatedList.filter((item) => item.stStockCode != " "));
            // setTotalListPrice(totalListPrice);
            //#endregion
        }
    }, [roundupPrice]);

    useEffect(() => {
        if (allowSetMarginValue && isMarginSelected) {
            fetchSetMarginPrice();
        }

        async function fetchSetMarginPrice() {
            let systemSettings = null;

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            // if (allowSetMarginValue && isMarginSelected) {
            const items = [...itemList];
            const updatedItemList = items.map((item) => {
                // if (item.displayGroupName !== " " || item.displaySubGroupName !== " ") {

                if (item.rowType !== "DX"
                    && item.rowType !== "LN"
                    && item.rowType !== "AI" 
                    // && (item.displayGroupName !== " " || item.displaySubGroupName !== " ")) {
                    && (item.rowType !== "G" && item.rowType !== "SG" && item.rowType !== "")) {
                    const itemListIndex = itemList.findIndex(
                        (stock) => {
                            if (stock) {
                                return stock.stStockCode === item.stStockCode && stock.rowId === item.rowId;
                            }
                        }
                        // (stock) => stock.stStockCode === item.stStockCode
                    );
                    // let ogNettEA =
                    // originalItems[id]?.items?.find((item) => item.stStockCode === itemsToProcess.stStockCode)?.nettEA ||
                    // originalItems[originalQuoteId]?.items?.find((item) => item.stStockCode === itemsToProcess.stStockCode)
                    //     ?.nettEA;
                    // let ogNettEA =
                    //     originalItems[id]?.items?.find((originalItem) => originalItem.stStockCode === itemList[itemListIndex].stStockCode)?.nettEA ||
                    //     originalItems[originalQuoteId]?.items?.find((originalItem) => originalItem.stStockCode === itemList[itemListIndex].stStockCode)?.nettEA;

                    let ogNettEA = getOrgiNettEA(item);
                    const updateMarginValue = newMargin ? parseFloat(parseFloat(newMargin).toFixed(1)) : 0;
                    const updatedNettEaValue = parseFloat(parseFloat((itemList[itemListIndex].stEecost * 100) / (100 - updateMarginValue)).toFixed(2));
                    const updateMargin = parseFloat(updateMarginValue);
                    const updatedNettEa = parseFloat(updatedNettEaValue);

                    const updatedTotalPrice = calculateItemTotalPrice(
                        itemList[itemListIndex].qty,
                        updatedNettEa
                    );

                    // const updatedTotalPrice = parseFloat(
                    //     parseFloat(updatedNettEa * itemList[itemListIndex].qty).toFixed(2)
                    // );
                    // const updatedDcOnLp =
                    //     itemList[itemListIndex].listPrice === 0
                    //         ? 0.0
                    //         : parseFloat(
                    //               (itemList[itemListIndex].listPrice - updatedNettEa) /
                    //                   itemList[itemListIndex].listPrice
                    //           ).toFixed(1);

                    const updatedDcOnLp =
                        typeof itemList[itemListIndex].dcOnLp === "string"
                            ?
                            calculateItemDcOnLp(
                                updatedNettEa,
                                itemList[itemListIndex].listPrice
                            )
                            :
                            calculateItemDcOnLp(
                                updatedNettEa,
                                itemList[itemListIndex].listPrice
                            );

                    const updatedRepDisc = calculateItemRepDisc(
                        ogNettEA,
                        updatedNettEa
                    );
                    // const updatedRepDisc =itemList[itemListIndex].nettEA === 0
                    //     ? 0.0
                    //     : parseFloat(100 - (updatedNettEa / itemList[itemListIndex].nettEA) * 100).toFixed(1);
                    const actMargin = calculateItemActMargin(updatedNettEa, itemList[itemListIndex].actCostExact);
                    const frcMargin = calculateItemFrcMargin(updatedNettEa, itemList[itemListIndex].stFutureCost);
                    const finalLcMargin = calculateItemLcMargin(updatedNettEa, itemList[itemListIndex].lastCostExact);
                    const lcMargin = isNaN(finalLcMargin) ? 0 : finalLcMargin;
                    //     const actMargin =
                    //     updatedNettEa === 0
                    //         ? 0.0
                    //         : parseFloat(
                    //               ((updatedNettEa - itemList[itemListIndex].actCostExact) / updatedNettEa) * 100
                    //           ).toFixed(1);

                    // const frcMargin =
                    //     updatedNettEa === 0
                    //         ? 0.0
                    //         : parseFloat(
                    //               ((updatedNettEa - itemList[itemListIndex].stFutureCost) / updatedNettEa) * 100
                    //           ).toFixed(1);

                    // const lcMargin =
                    //     updatedNettEa === 0
                    //         ? 0.0
                    //         : parseFloat(
                    //               ((updatedNettEa - itemList[itemListIndex].lastCostExact) / updatedNettEa) * 100
                    //           ).toFixed(1);

                    return {
                        ...item,
                        // ...itemList[itemListIndex],
                        nettEA: item.rowType === 'AI' ? "" : parseFloat(updatedNettEa),
                        totalPrice: item.rowType === 'AI' ? "" : updatedTotalPrice,
                        repDisc: item.rowType === 'A' || item.rowType === 'AI' ? "" : updatedRepDisc < 0 ? 0 : updatedRepDisc,
                        dcOnLp: item.rowType === 'A' || item.rowType === 'AI' ? "" : updatedDcOnLp,
                        margin: item.rowType === 'AI' ? "" : updateMargin,
                        actMargin: item.rowType === 'AI' ? "" : actMargin,
                        frcMargin: item.rowType === 'AI' ? "" : frcMargin,
                        lcMargin: item.rowType === 'A' || item.rowType === 'AI' || item.rowType === 'G' || item.rowType === 'SG' ? "" : lcMargin,
                    };
                } else {
                    return item;
                }
            });

            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            // const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
            // const updatedList = gridCalculation(updatedItemList, dbExtraCharge);

            // setItemList(updatedList);
            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
            setNewPrice(0);
            setComputeTotals(true);
            setAllowSetMarginValue(false);
            // //isMarginSelected(false)
            // // footer calculation
            // const {
            //     totalPrice,
            //     totalSteEeCostRound,
            //     totalStFutureCost,
            //     totalActCostExact,
            //     totalListPrice,
            //     totalNettEA,
            //     totalCost,
            //     totalMargin,
            //     totalActMargin,
            //     totalFrcMargin,
            //     totalDcOnLp,
            // } = footerCalculation(updatedList);

            // setTotalItemPrice(totalPrice);
            // setTotalstEecostRound(totalSteEeCostRound);
            // setTotalStFutureCost(totalStFutureCost);
            // setTotalActCostEAExact(totalActCostExact);
            // setTotalListPrice(totalListPrice);
            // setTotalCost(totalCost);
            // setTotalNettEA(totalNettEA);
            // setMaxMargin(totalMargin);
            // setActMargin(totalActMargin);
            // setMaxFrcMargin(totalFrcMargin);
            // setFooterDisc(totalDcOnLp);
            // }
        }
        // fetchSetMarginPrice();
    }, [allowSetMarginValue, isMarginSelected, itemList]);

    const handleComputeNettEA = async (systemSettings,dataItem) => {
        setIsQuoteSaved(false);
        setQuoteUpdated(true);

           
        let settings = null;

        if (systemSettings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            settings = data[0];
        } else {
            settings = systemSettings;
        }

        const value = dataItem.nettEA;
        
        setIsNettEAChanged(false);
        const dbExtraCharge = parseInt(settings?.dbextraCharge);
        const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        const itemRow = itemList[itemIndex];
        const updatingItemGroup = itemRow.groupId;
        const updatingItemGroupName = itemRow.groupName;
        const updatingItemSubGroup = itemRow.subGroupId;
        const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        let updatedNettEA = -1;
        let newRepDisc = -1;
        let updatedItem = null;
        let nettEAGrpLvlChanged = false;


        if (!isNaN(parseFloat(value))) {
            updatedNettEA = parseFloat(value);
            // if (updatedNettEA === parseFloat(itemRow.nettEA)) return; /// not to changed.
            setNettEAChangedIndexs([...nettEAChangedIndexs, itemIndex]);
            setIsNettEAChanged(true);
        }

        if (dataItem.rowType === "A") {
            ///Computation applies to Assembly
            updatedItem = {
                ...itemRow,
                nettEA: parseFloat(parseFloat(value).toFixed(2)),
                totalPrice: parseFloat(parseFloat(value * itemRow.qty).toFixed(2)),
            };
            itemList[itemIndex] = updatedItem;
        } else {

            //#region maintain the checking of level pricing to check the original nettEA
            let orignettEA = getOrgiNettEA(itemRow);
            newRepDisc = calculateItemRepDisc(orignettEA, updatedNettEA);
            //#endregion

            const newDcOnLp = calculateItemDcOnLp(updatedNettEA, itemRow.listPrice);

            updatedItem = {
                ...itemRow,
                nettEA: value == "0" ? value : updatedNettEA,
                dcOnLp: newDcOnLp,
                totalPrice: parseFloat((itemRow.qty * updatedNettEA).toFixed(2)),
                repDisc: newRepDisc > 0 ? newRepDisc : 0,
                margin: parseFloat(value) === 0.0 ? 0.0 : calculateItemMargin(updatedNettEA, itemRow.stEecost),
                actMargin:
                    parseFloat(value) === 0.0 ? 0.0 : calculateItemActMargin(updatedNettEA, itemRow.actCostExact),
                frcMargin:
                    parseFloat(value) === 0.0 ? 0.0 : calculateItemFrcMargin(updatedNettEA, itemRow.stFutureCost),
                lcMargin:
                    parseFloat(value) === 0.0 ? 0.0 : calculateItemLcMargin(updatedNettEA, itemRow.lastCostExact),
                //  lcMargin: "",
            };
            itemList[itemIndex] = updatedItem;
        }

        let updatedItemList = [...itemList];

        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        if (nonGroupItems.length > 0) {
            const nonGroupId = nonGroupItems[0].groupId;
            const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

            if (nonGroupDbExtra) {
                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                updatedItemList[dbExtraRowIndex] = dbExtraRow;
            }
        }

        if (firstCustomer[id]?.customer?.customerId === customerId) {
            updateFirstCustomerForId(id, {
                ...firstCustomer[id],
                item: updatedItemList[0],
            });
        }
    }

    useEffect(() => {
        if (allowSetValue && isPriceSelected) {
            changePrice();
        }

        async function changePrice() {
            let systemSettings = null;

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            const dbExtraCharge = parseFloat(systemSettings?.dbextraCharge);
            const items = [...itemList];
            const propotionalItems = calculateItemWisePropotionalTotalPrice(items, newPrice);
            let computeTotPrice = 0;
            
            items.map((item) => {
                const index = propotionalItems.findIndex((itmProp) => itmProp.rowId === item.rowId);
                
                if (index !== -1) {
                    !['LN','AI','G','SG',''].includes(propotionalItems[index].rowType) ? computeTotPrice += Number(propotionalItems[index].totalPrice) : <></>;
                    !['DX','LN','AI','G','SG',''].includes(propotionalItems[index].rowType) ? handleComputeNettEA(systemSettings, propotionalItems[index]) : <></>;
                    if (!['DX','LN','AI','G','SG',''].includes(propotionalItems[index].rowType) && propotionalItems[index].repDisc > 0) {
                        checkRepDiscLimit(propotionalItems[index]);
                    }
                }
                
            });

            //GET THE TOTAL PRICE OF THE ITEMLIST
            // if (computeTotPrice < parseFloat(newPrice)) {
            //     //get the last item in the list
            //     const lastItem = propotionalItems.filter((item) => !['DX','LN','AI','G','SG',''].includes(item.rowType)).at(-1);
            //     const addPrice = parseFloat(parseFloat(parseFloat(newPrice) - computeTotPrice).toFixed(2));
            //     if (lastItem) {
            //         const updatedNettEa = parseFloat(parseFloat(lastItem.nettEA + addPrice).toFixed(2));
            //         const idx = propotionalItems.findIndex((item) => item.rowId === lastItem.rowId);
            //         propotionalItems[idx].nettEA = updatedNettEa;
            //         handleComputeNettEA(systemSettings, propotionalItems[idx]);

            //     }

            // }

            setItemList(itemList);
            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...itemList] });
            setComputeTotals(true);
            setAllowSetValue(false);
            setNewFooterPrice(newPrice);
        }

    }, [allowSetValue, isPriceSelected, itemList]);

    const resetFooterValues = () => {
        setTotalItemPrice(0);
        setTotalstEecostRound(0);
        setTotalStFutureCost(0);
        setTotalActCostEAExact(0);
        setTotalListPrice(0);
        setTotalCost(0);
        setTotalNettEA(0);
        setMaxMargin(0);
        setActMargin(0);
        setMaxFrcMargin(0);
        setFooterDisc(0);
    }

    //When page loads, set the LoadStatus to false

    const approvalListing = async () => {
        const response = await getResponse("Quote/getapprovalstatslist", {});
        if (Object.keys(response).length > 0) {
            setApprovalStatList(response.data);
        }
    }

    const [qteApprovalMessage, setQteApprovalMessage] = useState([]);

    const approvalMessages = async () => {
        let qteApprovalMessage = [];
        let retmsg = await getResponse("Quote/getapprovalmessage", {});

        if (retmsg.statusCode) {
            setQteApprovalMessage(retmsg.data);
        }
    }

    useEffect(() => {
        SetLoadStatus(false);
        resetFooterValues();
        approvalListing();
        approvalMessages();
    }, []);

    //PARTCONSOLIDATION
    useEffect(() => {
        const handleCosolidate = async () => {
            let systemSettings = null;

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            const dbExtraCharge = parseFloat(systemSettings?.dbextraCharge);

            const partDetails = partConsolidatedDetail[id].selectedItems || [];
            const updatedQuoteDetails = itemList.map((originalItem) => {
                const matchingPartDetail =
                    originalItem.rowType === "A"
                        ? partDetails.find((partDetail) => partDetail.stStockCode === originalItem.stStockCode)
                        : partDetails.find((partDetail) => partDetail.stId === originalItem.stId);

                if (matchingPartDetail) {
                    let nettEA =
                        matchingPartDetail.nettEA === ""
                            ? parseFloat(originalItem.nettEA)
                            : parseFloat(matchingPartDetail.nettEA);

                    let oldNettEa  = parseFloat(originalItem.nettEA)
                    


                    let nettEAAssembly = parseFloat(
                        (((nettEA - matchingPartDetail.stEecost) / nettEA) * 100).toFixed(1)
                    );

                    if (originalItem.rowType !== "AI") {
                        return {
                            ...originalItem,
                            nettEA: nettEA,
                            repDisc:
                                originalItem.rowType === "A" ? "" :  originalItem.rowType === 'DX' ? "" : parseFloat(matchingPartDetail.repDisc) === originalItem.repDisc ? originalItem.repDisc  : parseFloat(matchingPartDetail.repDisc) < 0 ? 0 : calculateItemRepDisc(oldNettEa, nettEA),
                            dcOnLp: originalItem.rowType === "A" ? "" : parseFloat(matchingPartDetail.dcOnLp),
                            margin: originalItem.rowType === 'DX' ? "" :
                                //originalItem.rowType === "A" ? nettEAAssembly: parseFloat(matchingPartDetail.margin) === originalItem.margin ? originalItem.margin  :calculateMarginForNetEEPriceChange(matchingPartDetail.stEecost, nettEA),
                                 parseFloat(matchingPartDetail.margin) === originalItem.margin ? originalItem.margin  : matchingPartDetail.margin,
                            actMargin: originalItem.rowType === 'DX' ? "" :
                                originalItem.rowType === "A"
                                    ? nettEAAssembly
                                    :calculateMarginForNetEEPriceChange(matchingPartDetail.actCostExact, nettEA), 
                            frcMargin: originalItem.rowType === 'DX' ? "" :
                                originalItem.rowType === "A"
                                    ? nettEAAssembly
                                    :calculateMarginForNetEEPriceChange(matchingPartDetail.FutureCostExact, nettEA), 
                            lcMargin: originalItem.rowType === 'DX' ? "" : originalItem.rowType === "A" ? "" :calculateMarginForNetEEPriceChange(matchingPartDetail.lastCostExact, nettEA),
                            isDeleted: matchingPartDetail.isDeleted,
                            totalPrice:
                                originalItem.rowType === "A"
                                    ? ""
                                    : parseFloat(parseFloat(nettEA * originalItem.qty).toFixed(2)),
                        };

                       
                    }

                    return originalItem;
                   
                }
                
                return originalItem;
            });

             // Step 2: Handle deletion of assembly headers and their items
   // Step 2: Handle deletion of all assembly headers (rowType === "A") if any one is deleted
   const anyAssemblyDeleted = updatedQuoteDetails.some(item => item.rowType === "A" && item.isDeleted);
   const anyDxDeleted = updatedQuoteDetails.some(item => item.rowType === "DX" && item.isDeleted);

   const finalUpdatedQuoteDetails = updatedQuoteDetails.map((item) => {
       // Ensure that only items with rowType === "A" are affected by the anyAssemblyDeleted condition
       if (item.rowType === "A" && anyAssemblyDeleted) {
           return {
               ...item,
               isDeleted: true
           };
       }

       // Handle associated AI (assembly items) if their parent assembly header is deleted
       if (item.rowType === "AI" && anyAssemblyDeleted) {
           
               return {
                   ...item,
                   isDeleted: true
               };
           
       }

       if (item.rowType === "DX" && anyDxDeleted) {
        return {
            ...item,
            isDeleted: true
        };
    }


       if (!isAppliedDeleted) {
           return item;
       }

       return item;
    });

    const filteredList = finalUpdatedQuoteDetails.filter((item) => item.isDeleted != true);
    const hasAssembly = finalUpdatedQuoteDetails.some((item) => item.rowType === "A");
    const updatedItem = hasAssembly ? computeAssemblytotalByGroup(filteredList, true) : filteredList;

            // triggerComputeGrpTotals([], filteredList, "Update", groupId, subGroupId, dbExtraCharge);

            //// const calculatedList = gridCalculation(filteredList, dbExtraCharge);
            //setItemList(updatedItem);
            //updateQuoteDetailsForId(id, { selectedItems: updatedItem });
            setItemList(filteredList);
            updateQuoteDetailsForId(id, { selectedItems: filteredList });
            setComputeTotals(true);
            //#region commented and replace with the new computation
            // // footer calculation
            // // const {
            // //     totalPrice,
            // //     totalSteEeCostRound,
            // //     totalStFutureCost,
            // //     totalActCostExact,
            // //     totalListPrice,
            // //     totalNettEA: totalNettEAFooter,
            // //     totalCost,
            // //     totalMargin,
            // //     totalActMargin,
            // //     totalFrcMargin,
            // //     totalDcOnLp,
            // // } = footerCalculation(calculatedList);

            // // setTotalItemPrice(totalPrice);
            // // setTotalstEecostRound(totalSteEeCostRound);
            // // setTotalStFutureCost(totalStFutureCost);
            // // setTotalActCostEAExact(totalActCostExact);
            // // setTotalListPrice(totalListPrice);
            // // setTotalCost(totalCost);
            // // setTotalNettEA(totalNettEAFooter);
            // // setMaxMargin(totalMargin);
            // // setActMargin(totalActMargin);
            // // setMaxFrcMargin(totalFrcMargin);
            // // setFooterDisc(totalDcOnLp);
            //#endregion

            setAllowAddValue(false);
            setConsolidateItems(partDetails);
            setComputeTotals(true);
        };

        if (allowAddValue) {
            setIsLoading(true);
            // setImportShowProgress(true);
            // let systemSettings = null;

            // if (settings === null) {
            //     const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
            //         userId: user?.homeAccountId,
            //     });
            //     systemSettings = data[0];
            // } else {
            //     systemSettings = settings;
            // }

            // const partDetails = partConsolidatedDetail[id].selectedItems || [];
            // const updatedQuoteDetails = itemList.map((originalItem) => {
            //     const matchingPartDetail = partDetails.find((partDetail) => partDetail.stId === originalItem.stId);
            //     if (matchingPartDetail) {
            //         return {
            //             ...originalItem,
            //             nettEA: matchingPartDetail.nettEA,
            //             totalPrice: matchingPartDetail.totalPrice,
            //             repDisc: matchingPartDetail.repDisc < 0 ? 0 : matchingPartDetail.repDisc,
            //             dcOnLp: matchingPartDetail.dcOnLp,
            //             margin: matchingPartDetail.margin,
            //             actMargin: matchingPartDetail.actMargin,
            //             frcMargin: matchingPartDetail.frcMargin,
            //             lcMargin: matchingPartDetail.lcMargin,
            //             isDeleted: matchingPartDetail.isDeleted,
            //         };
            //     }
            //     return originalItem;
            // });

            // const filteredList = updatedQuoteDetails.filter((item) => item.isDeleted != true);

            // triggerComputeGrpTotals(
            //     [],
            //     filteredList,
            //     "Update",
            //     groupId,
            //     subGroupId,
            //     dbExtraCharge
            // );

            // //itemGridRecalculation
            // //  setItemList(filterDeletedFromUpdatedQuoteDetails);
            // //updateQuoteDetailsForId(id, { selectedItems: filterDeletedFromUpdatedQuoteDetails });

            // setItemList(itemGridRecalculation(filteredList));
            // updateQuoteDetailsForId(id, { selectedItems: itemGridRecalculation(filteredList) });
            // setAllowAddValue(false);
            // setConsolidateItems(partDetails);

            // // setItemList(updatedQuoteDetails);
            // // updateQuoteDetailsForId(id, { selectedItems: updatedQuoteDetails });
            // // setAllowAddValue(false);
            handleCosolidate();
            setIsLoading(false);
            // setImportShowProgress(false);
        }
    }, [allowAddValue]);

    //useEffect(() => {}, [hasCustomerId]);

    useEffect(() => {
        itemGridSetup(); //fetch dropdown view based on role and populate the column grid. Also, set the initial view.
    }, [firstCustomer]);

    useEffect(() => {
        setGroupId("");
    }, [id]);

    useEffect(() => {
        (async () => {
            try {
                if (mainCustomer) {
                    const responses = await getResponse("Customer/getcustomerdetails", {
                        customerId: mainCustomer,
                    });

                    setDebtorType(responses.debtorType);
                    setpriceCategory(responses.priceCategory);
                    resetQteApprovalForm();
                }
            } catch (error) {
                console.error(error);
            }
        })();
    }, [setDebtorType, setpriceCategory, mainCustomer]);

    // const columns = useMemo(
    //     () => [
    //         { field: "groupName", title: "GroupHidden", show: false, userConfig: false, userSelected: false },
    //         { field: "subGroupName", title: "SubGroupHidden", show: false, userConfig: false, userSelected: false },
    //         { field: "rowCategory", title: "RowCategoryHidden", show: false, userConfig: false, userSelected: false },
    //         { field: "headerRow", title: " ", width: "10px", editable: false, show: true, userConfig: false, userSelected: false},
    //         { field: "selected", title: " ", width: "10px", cell: TreeListSelectionCell, headerCell: TreeListHeaderSelectionCell, editable: false, show: true, userConfig: false, userSelected: false},
    //         { field: "IconIndicator", title: " ",  width: "10px", editable: false, show: true, userConfig: false, userSelected: false },
    //         { field: "groupLevelName", title: "Groups", minwidth: "110px", editable: false, show: true, userConfig: true, userSelected: true, expandable: true },
    //         { field: "displayGroupName", title: "Groups", width: "110px", editable: true, show: false, userConfig: false, userSelected: false },
    //         { field: "displaySubGroupName", title: "Sub Groups", editable: false, show: false, userConfig: false, userSelected: false },
    //         { field: "assemblyGroupName", title: "AssemblyGroupName", editable: false, show: false, userConfig: false, userSelected: false },
    //         { field: "displayAssemblyGroupName", title: "Assembly", editable: false, show: true, userConfig: false, userSelected: false },
    //         { field: "stStockCode", title: "Part No", width: "110px", editable: true, show: true, minWidth: 100, userConfig: true, userSelected: true },
    //         { field: "description", title: "Description", width: "110px", editable: true, show: true, minWidth: 150, userConfig: true, userSelected: true, editCell : TreeListTextEditor },
    //         { field: "type", title: "Type", show: true, userConfig: true, userSelected: true },
    //         { field: "rowType", title: "Row Type", show: false, userConfig: false, userSelected: false },
    //         { field: "dcCode", title: "Dc Code", show: true, userConfig: true, userSelected: true },
    //         { field: "dcRate", title: "Dc Rate(%)", show: true, userConfig: true, userSelected: true },
    //         { field: "listPrice", title: "List Price", show: true, userConfig: true, userSelected: true },
    //         { field: "l60", title: "L60 Price", show: true, userConfig: true, userSelected: true },
    //         { field: "l50", title: debtorType ? `L50 (${debtorType})` : "L50 Price", show: true, userConfig: true, userSelected: true },
    //         //20/12/2023 changed by sachini -add L35 but same as L55 but this use for getting NettEA and changed order of price levels
    //         { field: "l55", title: "L35 Price", show: true, userConfig: true, userSelected: true },
    //         { field: "l20", title: priceCategory ? `L20 (${priceCategory})` : "L20 Price", show: true, userConfig: true, userSelected: true },
    //         { field: "stEecostRound", title: "CostEA", editable: true, show: true, userConfig: false, userSelected: false },
    //         { field: "stEecost", title: "CostEA Exact", show: false, userConfig: false, userSelected: false },
    //         { field: "costEA", title: "CostEA old", show: false, userConfig: false, userSelected: false },
    //         { field: "actCost", title: "Avg. Cost", editable: true, show: true, userConfig: false, userSelected: false },
    //         { field: "stFutureCostRound", title: "Fut. Cost", editable: true, show: true, userConfig: false, userSelected: false },
    //         { field: "stFutureCost", title: "Fut.Real Cost exact ", show: false, userConfig: false, userSelected: false },
    //         { field: "lastCost", title: "Last Cost", show: true, userConfig: false, userSelected: false },
    //         { field: "costEAExact", title: "CostEAExact old", show: false, userConfig: false, userSelected: false },
    //         { field: "dcOnLp", title: "Dc on LP(%)", editable: true, show: true, userConfig: true, userSelected: true },
    //         { field: "soh", title: "SOH", show: true, userConfig: true, userSelected: true },
    //         { field: "qty", title: "Qty", editable: true, show: true, userConfig: true, userSelected: true, editCell : TreeListTextEditor },
    //         { field: "nettEA", title: "NettEA", editable: true, show: true, userConfig: true, userSelected: true, editCell : TreeListTextEditor },
    //         {
    //             field: "repDisc",
    //             title: "Rep Disc (%)",
    //             editable: true,
    //             footerCell: RepDiscFooterCell,
    //             show: true,
    //             userConfig: false,
    //             userSelected: false,
    //             editCell : TreeListTextEditor
    //         },
    //         { field: "margin", title: "Margin (%)", editable: true, show: true, userConfig: false, userSelected: false, editCell : TreeListTextEditor },
    //         { field: "actMargin", title: "AvgC. Margin (%)", show: true, userConfig: false, userSelected: false },
    //         { field: "frcMargin", title: "FC. Margin (%)", show: true, userConfig: false, userSelected: false },
    //         { field: "lcMargin", title: "LC. Margin (%)", show: true, userConfig: false, userSelected: false, format: "{0:n1}" },
    //         {
    //             field: "totalCost",
    //             title: "Total Cost",
    //             footerCell: TotalCostFooterCell,
    //             show: true,
    //             minWidth: 70,
    //             userConfig: false,
    //             userSelected: false
    //         },
    //         {
    //             field: "totalPrice",
    //             title: "Total Price",
    //             footerCell: TotalPriceFooterCell,
    //             show: true,
    //             minWidth: 70,
    //             userConfig: true,
    //             userSelected: true
    //         },
    //         {
    //             field: "hideRow",
    //             title: "Hide Row",
    //             show: true,
    //             cell: (props) => (
    //                 <HideRowCell
    //                     dataItem={props.dataItem}
    //                     selected={props.dataItem.hideRow}
    //                     onToggle={handleColumnToggle}
    //                 />
    //             ),
    //             userConfig: false,
    //             userSelected: false
    //         },
    //         {
    //             field: "hideQty",
    //             title: "Hide Qty",
    //             show: true,
    //             cell: (props) => (
    //                 <HideQtyCell
    //                     dataItem={props.dataItem}
    //                     selected={props.dataItem.hideQty}
    //                     onToggle={handleColumnToggle}
    //                 />
    //             ),
    //             userConfig: false,
    //             userSelected: false
    //         },
    //         {
    //             field: "hidePrice",
    //             title: "Hide Price",
    //             show: true,
    //             cell: (props) => (
    //                 <HidePriceCell
    //                     dataItem={props.dataItem}
    //                     selected={props.dataItem.hidePrice}
    //                     onToggle={handleColumnToggle}
    //                 />
    //             ),
    //             userConfig: false,
    //             userSelected: false
    //         },
    //         { field: "assemblyId", title: "assemblyId", editable: false, show: false, userConfig: false, userSelected: false }, // this will be used to specify group of Assembly/labour items
    //         { field: "oldStockCode", title: "oldStockCode", editable: false, show: false, userConfig: false, userSelected: false }, // cross reference-item
    //         { field: "stkWithDiffSPPrice", title: "stkWithDiffSPPrice", editable: false, show: false, userConfig: false, userSelected: false }, // used to identify item with diff stock price
    //         { field: "position", title: "position", editable: false, show: false, userConfig: false, userSelected: false }, // used to sort item
    //     ],

    //     [priceCategory, debtorType, mainCustomer, setpriceCategory, setDebtorType]
    // );

    const columns = useMemo(
        () => [
            { field: "groupName", title: "GroupHidden", show: false, userConfig: false, userSelected: false },
            { field: "subGroupName", title: "SubGroupHidden", show: false, userConfig: false, userSelected: false },
            { field: "rowCategory", title: "RowCategoryHidden", show: false, userConfig: false, userSelected: false },
            { field: "headerRow", title: " ", width: "25px", editable: false, show: true, userConfig: false, userSelected: false },
            { field: "selected", title: " ", width: "40px", cell: TreeListSelectionCell, headerCell: TreeListHeaderSelectionCell, editable: false, show: true, userConfig: false, userSelected: false },
            { field: "IconIndicator", title: "-", width: "30px", editable: false, show: true, userConfig: false, userSelected: false },
            { field: "groupLevelName", title: "Groups", width: "110px", editable: true, show: true, userConfig: true, userSelected: true, expandable: true },
            { field: "displayGroupName", title: "Groups", width: "110px", editable: false, show: false, userConfig: false, userSelected: false },
            { field: "displaySubGroupName", title: "Sub Groups", editable: false, show: false, userConfig: false, userSelected: false },
            { field: "assemblyGroupName", title: "AssemblyGroupName", editable: false, show: false, userConfig: false, userSelected: false },
            { field: "displayAssemblyGroupName", title: "Assembly", editable: false, show: true, userConfig: false, userSelected: false },
            { field: "stStockCode", title: "Part No", width: "110px", editable: true, show: true, userConfig: true, userSelected: true },
            { field: "description", title: "Description", width: "110px", editable: true, show: true, userConfig: true, userSelected: true, },
            { field: "type", title: "Type", show: true, userConfig: true, userSelected: true },
            { field: "rowType", title: "Row Type", show: false, userConfig: false, userSelected: false },
            { field: "dcCode", title: "Dc Code", show: true, userConfig: true, userSelected: true },
            { field: "dcRate", title: "Dc Rate(%)", show: true, userConfig: true, userSelected: true },
            { field: "listPrice", title: "List Price", show: true, userConfig: true, userSelected: true, format: "{0:n2}", },
            { field: "l60", title: "L60 Price", show: true, userConfig: true, userSelected: true, format: "{0:n2}", },
            { field: "l50", title: debtorType ? `L50 (${debtorType})` : "L50 Price", show: true, userConfig: true, userSelected: true, format: "{0:n2}", },
            //20/12/2023 changed by sachini -add L35 but same as L55 but this use for getting NettEA and changed order of price levels
            { field: "l55", title: "L35 Price", show: true, userConfig: true, userSelected: true, format: "{0:n2}", },
            { field: "l20", title: priceCategory ? `L20 (${priceCategory})` : "L20 Price", show: true, userConfig: true, userSelected: true, format: "{0:n2}", },
            { field: "stEecostRound", title: "CostEA", editable: true, show: true, userConfig: true, userSelected: false, format: "{0:n2}", },
            { field: "stEecost", title: "CostEA Exact", show: false, userConfig: false, userSelected: false },
            { field: "costEA", title: "CostEA old", show: false, userConfig: false, userSelected: false },
            { field: "actCost", title: "Avg. Cost", editable: false, show: true, userConfig: false, userSelected: false, format: "{0:n2}", },
            { field: "stFutureCostRound", title: "Fut. Cost", editable: false, show: true, userConfig: false, userSelected: false },
            { field: "stFutureCost", title: "Fut.Real Cost exact ", show: false, userConfig: false, userSelected: false },
            { field: "lastCost", title: "Last Cost", show: true, userConfig: false, userSelected: false, format: "{0:n2}", },
            { field: "costEAExact", title: "CostEAExact old", show: false, userConfig: false, userSelected: false },
            { field: "dcOnLp", title: "Dc on LP(%)", editable: true, show: true, userConfig: true, userSelected: true, format: "{0:n1}", },
            { field: "soh", title: "SOH", show: true, userConfig: true, userSelected: true },
            { field: "qty", title: "Qty", editable: true, show: true, userConfig: true, userSelected: true },
            { field: "nettEA", title: "NettEA", editable: true, show: true, userConfig: true, userSelected: true, format: "{0:n2}" },
            {
                field: "repDisc",
                title: "Rep Disc (%)",
                format: "{0:n1}",
                editable: true,
                footerCell: RepDiscFooterCell,
                show: true,
                userConfig: false,
                userSelected: false,
            },
            { field: "margin", title: "Margin (%)", editable: true, show: true, userConfig: false, userSelected: false },
            { field: "actMargin", title: "AvgC. Margin (%)", show: true, userConfig: false, userSelected: false },
            { field: "frcMargin", title: "FC. Margin (%)", show: true, userConfig: false, userSelected: false },
            { field: "lcMargin", title: "LC. Margin (%)", show: true, userConfig: false, userSelected: false, format: "{0:n1}" },
            {
                field: "totalCost",
                title: "Total Cost",
                footerCell: TotalCostFooterCell,
                format: "{0:n2}",
                show: true,
                minWidth: 70,
                userConfig: true,
                userSelected: false,
                align: "right",
            },
            {
                field: "totalPrice",
                title: "Total Price",
                footerCell: TotalPriceFooterCell,
                format: "{0:n2}",
                show: true,
                minWidth: 70,
                userConfig: true,
                userSelected: true
            },
            {
                field: "hideRow",
                title: "Hide Row",
                show: true,
                cell: (props) => (
                    <HideRowCell
                        dataItem={props.dataItem}
                        selected={props.dataItem.hideRow}
                        onToggle={handleColumnToggle}
                    />
                ),
                userConfig: false,
                userSelected: false
            },
            {
                field: "hideQty",
                title: "Hide Qty",
                show: true,
                cell: (props) => (
                    <HideQtyCell
                        dataItem={props.dataItem}
                        selected={props.dataItem.hideQty}
                        onToggle={handleColumnToggle}
                    />
                ),
                userConfig: false,
                userSelected: false
            },
            {
                field: "hidePrice",
                title: "Hide Price",
                show: true,
                cell: (props) => (
                    <HidePriceCell
                        dataItem={props.dataItem}
                        selected={props.dataItem.hidePrice}
                        onToggle={handleColumnToggle}
                    />
                ),
                userConfig: false,
                userSelected: false
            },
            { field: "assemblyId", title: "assemblyId", editable: false, show: false, userConfig: false, userSelected: false }, // this will be used to specify group of Assembly/labour items
            { field: "oldStockCode", title: "oldStockCode", editable: false, show: false, userConfig: false, userSelected: false }, // cross reference-item
            { field: "stkWithDiffSPPrice", title: "stkWithDiffSPPrice", editable: false, show: false, userConfig: false, userSelected: false }, // used to identify item with diff stock price
            { field: "position", title: "position", editable: false, show: false, userConfig: false, userSelected: false }, // used to sort item
        ],

        [priceCategory, debtorType, mainCustomer, setpriceCategory, setDebtorType]
    );

    // //When Base Customer changes, update the Item List
    useEffect(() => {
        const _loadStatus = LoadStatus;
        if (_loadStatus === true) {
            const selectedItemsStId = [];

            if (quoteDetails[id] && quoteDetails[id].selectedItems) {
                for (let i = 0; i < quoteDetails[id].selectedItems.length; i++) {
                    selectedItemsStId.push(quoteDetails[id].selectedItems[i].stStockCode);
                }

                const StIds = JSON.stringify(selectedItemsStId).replace("[", "").replace("]", "").replace(/"/g, "");

                async function fetchData() {
                    await getResponse("Stocks/getstockdetailsusingarray", {
                        stStockCodes: String(StIds),
                        CustomerId: mainCustomer,
                        UserId: user?.homeAccountId,
                    })
                        .then((response) => {
                            const stockDetails = response;
                            newCustomerStockDetails.current = stockDetails;
                            let retValue = {};
                            let parentGId = null;
                            let parentSGId = null;
                            let parentId = null;
                            let treeId = null;
                            const updatedItemList =
                                itemList.length > 0 &&
                                itemList.map((item) => {
                                    treeId = uuidv4();
                                    if (item.rowType === "G") parentGId = treeId;
                                    if (item.rowType === "SG") parentSGId = treeId;
                                    if (item.groupName.startsWith("Group") && item.rowType === "G") parentId = null;
                                    else if (item.subGroupName.startsWith("Sub") && item.rowType === "SG") parentId = parentGId;
                                    else parentId = item.subGroupId.length > 0 ? parentSGId : parentGId;

                                    const index = stockDetails.findIndex((stock) => {
                                        if (stock) {
                                            return stock.stStockCode === item.stStockCode;
                                        }
                                    });

                                    const itemListIndex = itemList.findIndex((stock) => {
                                        if (stock) {
                                            return stock.stStockCode === item.stStockCode;
                                        }
                                    });

                                    if (index !== -1) {
                                        const updatedNettEa = itemList[itemListIndex].nettEA
                                            ? itemList[itemListIndex].nettEA
                                            : 0;

                                        const updatedTotalPrice = parseFloat(
                                            parseFloat(
                                                itemList[itemListIndex].nettEA * itemList[itemListIndex].qty
                                            ).toFixed(2)
                                        );

                                        let updatedRepDisc =
                                            stockDetails[index].nettEA === 0
                                                ? ""
                                                : parseFloat(
                                                    100 -
                                                    (itemList[itemListIndex].nettEA /
                                                        stockDetails[index].nettEA) *
                                                    100
                                                ).toFixed(1);
                                        // Check if updatedRepDisc is less than or equal to 0
                                        if (updatedRepDisc <= 0) {
                                            updatedRepDisc = "";
                                        }

                                        const updatedDcOnLp = calculateItemDcOnLp(
                                            itemList[itemListIndex].nettEA,
                                            itemList[itemListIndex].listPrice
                                        );

                                        const updatedMargin = calculateItemMargin(
                                            itemList[itemListIndex].nettEA,
                                            itemList[itemListIndex].stEecost
                                        );

                                        const updatedActMargin = calculateItemActMargin(
                                            itemList[itemListIndex].nettEA,
                                            itemList[itemListIndex].actCostExact
                                        );

                                        const updatedFrcMargin = calculateItemFrcMargin(
                                            itemList[itemListIndex].nettEA,
                                            itemList[itemListIndex].stFutureCost
                                        );

                                        const updatedLcMargin = calculateItemLcMargin(
                                            itemList[itemListIndex].nettEA,
                                            itemList[itemListIndex].lastCostExact
                                        );

                                        const updatedQty = itemList[itemListIndex].qty;

                                        const updatedTotalCost = itemList[itemListIndex].totalCost;

                                        const listPrice = stockDetails[index].listPrice === 0 ? '' : stockDetails[index].listPrice;
                                        const l60 = stockDetails[index].l60 === 0 ? '' : stockDetails[index].l60;
                                        const l55 = stockDetails[index].l55 === 0 ? '' : stockDetails[index].l55;
                                        const l50 = stockDetails[index].l50 === 0 ? '' : stockDetails[index].l50;
                                        const l20 = stockDetails[index].l20 === 0 ? '' : stockDetails[index].l20;
                                        return {
                                            ...item,
                                            ...stockDetails[index],
                                            nettEA: updatedNettEa,
                                            totalPrice: updatedTotalPrice,
                                            repDisc: updatedRepDisc,
                                            dcOnLp: updatedDcOnLp,
                                            margin: updatedMargin,
                                            actMargin: updatedActMargin,
                                            frcMargin: updatedFrcMargin,
                                            lcMargin: updatedLcMargin,
                                            qty: updatedQty,
                                            totalCost: updatedTotalCost,
                                            stFutureCostRound: parseFloat(itemList[itemListIndex].stFutureCost).toFixed(
                                                2
                                            ),
                                            l60: l60,
                                            l55: l55,
                                            l50: l50,
                                            l20: l20,
                                            id: treeId,
                                            parentId: parentId,
                                            listPrice: listPrice
                                        };
                                    } else {
                                        return item;
                                    }
                                });

                            setItemList(updatedItemList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                            if (totalItemPrice <= 0) {
                                setComputeTotals(true);
                            }

                            // // footer calculation
                            // const {
                            //     totalPrice,
                            //     totalSteEeCostRound,
                            //     totalStFutureCost,
                            //     totalActCostExact,
                            //     totalListPrice,
                            //     totalNettEA,
                            //     totalCost,
                            //     totalMargin,
                            //     totalActMargin,
                            //     totalFrcMargin,
                            //     totalDcOnLp,
                            // } = footerCalculation(updatedItemList);

                            // setTotalItemPrice(totalPrice);
                            // setTotalCostAmt(totalCost);
                            // setTotalstEecostRound(totalSteEeCostRound);
                            // setTotalStFutureCost(totalStFutureCost);
                            // setTotalActCostEAExact(totalActCostExact);
                            // setTotalListPrice(totalListPrice);
                            // setTotalCost(totalCost);
                            // setTotalNettEA(totalNettEA);
                            // setMaxMargin(totalMargin);
                            // setActMargin(totalActMargin);
                            // setMaxFrcMargin(totalFrcMargin);
                            // setFooterDisc(totalDcOnLp);

                            // const totalPrice = calculateTotalPrice(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalItemPrice(totalPrice);

                            // const totalCost = calculateTotalCost(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalCost(totalCost);

                            // const totalneeta = calculateTotalNettEA(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalNettEA(totalneeta);

                            // const totalstEecostRound = calculateTotalstEecostRound(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalstEecostRound(totalstEecostRound);

                            // const totalStFutureCost = calculateTotalStFutureCost(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalStFutureCost(totalStFutureCost);

                            // const totalActCostEa = calculateTotalActCostEAExact(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalActCostEAExact(totalActCostEa);

                            // const totalListPrice = calculateTotalListPrice(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalListPrice(totalListPrice);
                        })
                        .catch((error) => {
                            console.error("API Error:", error);
                        });
                }
                if (itemList.length > 0) {
                    fetchData();
                }
            }

            previousMainCustomer.current = mainCustomer;
        }
        SetLoadStatus(false);

    }, [mainCustomer]);

    //When Quote Customer changes, update the Item List
    useEffect(() => {
        const _loadStatus = LoadStatus;
        if (_loadStatus === true) {
            const selectedItemsStId = [];

            if (quoteDetails[id] && quoteDetails[id].selectedItems) {
                for (let i = 0; i < quoteDetails[id].selectedItems.length; i++) {
                    selectedItemsStId.push(quoteDetails[id].selectedItems[i].stStockCode);
                }

                const StIds = JSON.stringify(selectedItemsStId).replace("[", "").replace("]", "").replace(/"/g, "");

                async function fetchData() {
                    await getResponse("Stocks/getstockdetailsusingarray", {
                        stStockCodes: String(StIds),
                        CustomerId: quoteCustomerId,
                        UserId: user?.homeAccountId,
                    })
                        .then((response) => {
                            const stockDetails = response;

                            const updatedItemList = itemList.map((item) => {
                                // const index = stockDetails.findIndex((stock) => stock.stStockCode === item.stStockCode);

                                // const itemListIndex = itemList.findIndex(
                                //     (stock) => stock.stStockCode === item.stStockCode
                                // );
                                const index = stockDetails.findIndex((stock) => {
                                    if (stock) {
                                        return stock.stStockCode === item.stStockCode;
                                    }
                                });

                                const itemListIndex = itemList.findIndex((stock) => {
                                    if (stock) {
                                        return stock.stStockCode === item.stStockCode;
                                    }
                                });

                                if (index !== -1) {
                                    const updatedNettEa = itemList[itemListIndex].nettEA
                                        ? parseFloat(itemList[itemListIndex].nettEA.toFixed(2))
                                        : 0.0;

                                    const updatedTotalPrice = parseFloat(
                                        parseFloat(
                                            itemList[itemListIndex].nettEA * itemList[itemListIndex].qty
                                        ).toFixed(2)
                                    );

                                    let updatedRepDisc = parseFloat(
                                        stockDetails[index].nettEA === 0
                                            ? 0
                                            : parseFloat(
                                                100 -
                                                (itemList[itemListIndex].nettEA / stockDetails[index].nettEA) *
                                                100
                                            )
                                    ).toFixed(1);

                                    // Check if updatedRepDisc is less than or equal to 0
                                    if (updatedRepDisc <= 0) {
                                        updatedRepDisc = Number(0);
                                    }

                                    // const updatedDcOnLp = parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));

                                    const updatedDcOnLp =
                                        typeof itemList[itemListIndex].dcOnLp === "string"
                                            ? parseFloat(itemList[itemListIndex].dcOnLp).toFixed(1)
                                            : parseFloat(itemList[itemListIndex].dcOnLp.toFixed(1));

                                    const updatedMargin =
                                        typeof itemList[itemListIndex].margin == "string"
                                            ? parseFloat(parseFloat(itemList[itemListIndex].margin).toFixed(1))
                                            : parseFloat(itemList[itemListIndex].margin.toFixed(1));

                                    const actMargin = parseFloat(
                                        parseFloat(itemList[itemListIndex].actMargin || 0).toFixed(1)
                                    );

                                    const frcMargin = parseFloat(
                                        parseFloat(itemList[itemListIndex].frcMargin || 0).toFixed(1)
                                    );

                                    const lcMargin = parseFloat(
                                        parseFloat(itemList[itemListIndex].lcMargin || 0).toFixed(1)
                                    );

                                    const l60 = isNaN(parseFloat(itemList[itemListIndex].l60)) ? '' : parseFloat(itemList[itemListIndex].l60);
                                    const l55 = isNaN(parseFloat(itemList[itemListIndex].l55)) ? '' : parseFloat(itemList[itemListIndex].l55);
                                    const l50 = isNaN(parseFloat(itemList[itemListIndex].l50)) ? '' : parseFloat(itemList[itemListIndex].l50);
                                    const l20 = isNaN(parseFloat(itemList[itemListIndex].l20)) ? '' : parseFloat(itemList[itemListIndex].l20);

                                    const updatedQty = itemList[itemListIndex].qty ? itemList[itemListIndex].qty : 1;

                                    return {
                                        ...item,
                                        ...stockDetails[index],
                                        nettEA: updatedNettEa,
                                        totalPrice: updatedTotalPrice,
                                        repDisc: updatedRepDisc,
                                        dcOnLp: updatedDcOnLp,
                                        margin: updatedMargin,
                                        l60: itemList[itemListIndex].l60 === "" ? "" : l60,
                                        l55: itemList[itemListIndex].l55 === "" ? "" : l55,
                                        l50: itemList[itemListIndex].l50 === "" ? "" : l50,
                                        l20: itemList[itemListIndex].l20 === "" ? "" : l20,
                                        qty: updatedQty,
                                        actMargin: actMargin,
                                        frcMargin: frcMargin,
                                        lcMargin: lcMargin,
                                        stFutureCostRound: parseFloat(itemList[itemListIndex].stFutureCost).toFixed(2),
                                    };
                                    // return {
                                    //     ...item,
                                    //     ...stockDetails[index],
                                    //     nettEA: itemList[itemListIndex].nettEA ? itemList[itemListIndex].nettEA : 0,
                                    //     totalPrice:
                                    //         stockDetails[index].qty * itemList[itemListIndex].nettEA
                                    //             ? itemList[itemListIndex].nettEA
                                    //             : 1,
                                    //     repDisc:
                                    //         100 - (itemList[itemListIndex].nettEA / stockDetails[index].nettEA) * 100,
                                    //     stEecost: itemList[itemListIndex].stEecost
                                    //         ? itemList[itemListIndex].stEecost
                                    //         : 150,
                                    // };
                                } else {
                                    return {
                                        ...item,
                                    };
                                }


                            });

                            // const totalPrice = calculateTotalPrice(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalItemPrice(totalPrice);
                            // const totalCost = calculateTotalCost(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalCost(totalCost);
                            // const totalneeta = calculateTotalNettEA(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalNettEA(totalneeta);
                            // const totalstEecostRound = calculateTotalstEecostRound(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalstEecostRound(totalstEecostRound);
                            // const totalStFutureCost = calculateTotalStFutureCost(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalStFutureCost(totalStFutureCost);
                            // const totalActCostEa = calculateTotalActCostEAExact(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalActCostEAExact(totalActCostEa);

                            // const totalListPrice = calculateTotalListPrice(
                            //     updatedItemList.filter((item) => item.stStockCode != " ")
                            // );
                            // setTotalListPrice(totalListPrice);

                            
                            if (totalItemPrice <= 0) {
                                // footer calculation
                                const {
                                    totalPrice,
                                    totalSteEeCostRound,
                                    totalStFutureCost,
                                    totalActCostExact,
                                    totalListPrice,
                                    totalNettEA,
                                    totalCost,
                                    totalMargin,
                                    totalActMargin,
                                    totalFrcMargin,
                                    totalDcOnLp,
                                } = footerCalculation(updatedItemList);

                                setTotalItemPrice(totalPrice);
                                setTotalCostAmt(totalCost);
                                setTotalstEecostRound(totalSteEeCostRound);
                                setTotalStFutureCost(totalStFutureCost);
                                setTotalActCostEAExact(totalActCostExact);
                                setTotalListPrice(totalListPrice);
                                setTotalCost(totalCost);
                                setTotalNettEA(totalNettEA);
                                setMaxMargin(totalMargin);
                                setActMargin(totalActMargin);
                                setMaxFrcMargin(totalFrcMargin);
                                setFooterDisc(totalDcOnLp);

    
                                setItemList(updatedItemList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                            } 
                        })
                        .catch((error) => {
                            console.error("API Error:", error);
                        });
                }

                if (itemList.length > 0) {
                    fetchData();
                }
            }

            previousMainCustomer.current = mainCustomer;
        }

        SetLoadStatus(false);
        setQuoteCustomerId("");
    }, [quoteCustomerId]);

    useEffect(() => {
        ///main loading of data
        const parts = quoteDetails[id]?.selectedItems;
        getDBExtraChargeFromSettings();
        let assemblyId = uuidv4();
        let assmGrpName = "ASSEMBLY " + groupAssembly;
        if (quoteDetails[id] && parts && parts.length > 0) {
            setOriginalQuoteId(quoteDetails[id].savedQuote?.originalQuoteId);

            const maxGroupCount = Math.max(
                ...parts.map((item) => {
                    if (item?.groupName) {
                        if (isNaN(item.groupName.split(" ")[1])) {
                            return 0;
                        } else {
                            return parseInt(item.groupName.split(" ")[1]);
                        }
                    }
                }),
                0
            );

            const groupedData = parts.reduce((groups, item) => {
                const { groupName } = item;

                if (!groups[groupName]) {
                    groups[groupName] = [];
                }

                groups[groupName].push(item);
                return groups;
            }, {});

            if (maxGroupCount > 0) {
                const lastItem = parts[parts.length - 1];
                const { groupName, groupId } = lastItem;

                if (groupName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                    setGroupId(groupId);
                }

                setGroupCount(maxGroupCount + 1);

                // this had to be changed to lastGroupId instead of maxGroupId because the maxGroupId is not always the last group id
                //const maxGroupId = parts.filter((item) => item.groupName === `Group ${maxGroupCount}`)[0].groupId;
                //setGroupId(maxGroupId);
            } else {
                setGroupCount(1);
            }

            const maxCountForEachGroup = getSubGroupWiseMaxGroupCount(groupedData);
            // const lastGroupMaxSubGroupCount = maxCountForEachGroup[Object.keys(maxCountForEachGroup).pop()];
            const lastGroupMaxSubGroupCount = Math.max(...Object.values(maxCountForEachGroup));
            const maxSubGroupIdFilter = parts.filter(
                (item) => item.subGroupName === `Sub Group ${lastGroupMaxSubGroupCount}`
                // && item.groupName === `Group ${maxGroupCount}`
            );

            if (
                lastGroupMaxSubGroupCount > 0 &&
                maxSubGroupIdFilter.length > 0
                // && -1 !== Object.values(subGroupId).findIndex((v) => v == null || v === "")
            ) {
                setSubGroupCount(lastGroupMaxSubGroupCount + 1);
                const maxSubGroupId = maxSubGroupIdFilter[0].subGroupId;
                setSubGroupId(maxSubGroupId);
            } else {
                setSubGroupCount(1);
            }
            let retValue = {};
            const itemRepos = itemPositioning(true, parts);
            const updateValues = itemRepos.map((item) => {
                if (item.rowType === "LN") {
                    /// this reset is for line note
                    retValue = {
                        ...item,
                        dcOnLp: "",
                        dcRate: "",
                        totalPrice: "",
                        stFutureCostRound: "",
                        frcMargin: "",
                        margin: "",
                        totalstEecostRound: "",
                        totalCost: "",
                        listPrice: "",
                        l60: "",
                        l55: "",
                        l50: "",
                        l20: "",
                        stEecostRound: "",
                        actCost: "",
                        actCostExact: "",
                        actMargin: "",
                        qty: "",
                        nettEA: "",
                        repDisc: "",
                        soh: "",
                        assemblyGroupName: "",
                        displayAssemblyGroupName: "",
                    };
                } else if (item.rowType === "A") {
                    ///reset assembly items
                    assemblyId = uuidv4();
                    assmGrpName = "ASSEMBLY " + groupAssembly;
                    setGroupAssembly((prev) => prev += 1);
                    retValue = {
                        ...item,
                        listPrice: "",
                        l60: "",
                        l55: "",
                        l50: "",
                        l20: "",
                        assemblyGroupName: assmGrpName,
                        displayAssemblyGroupName: assmGrpName,
                    };
                } else if (item.rowType === "AI") {
                    ///reset assembly items
                    retValue = {
                        ...item,
                        nettEA: "",
                        l60: "",
                        l55: "",
                        l50: "",
                        l20: "",
                        totalPrice: "",
                        margin: "",
                        actMargin: "",
                        frcMargin: "",
                        lcMargin: "",
                        dcOnLp: "",
                        listPrice: "",
                        lastCost: "",
                        assemblyGroupName: assmGrpName,
                        displayAssemblyGroupName: "",
                    };
                } else {
                    retValue = {
                        ...item,
                        assemblyGroupName: "",
                        displayAssemblyGroupName: "",
                    };
                }
                // parentId = item.rowType === "G" 
                //     ? treeId
                //     : item.rowType === "SG"
                //         ? parentId
                //         : !item.groupName.startsWith("Group")
                //             ? null
                //             : parentId;
                
                //#region added to fixed NaN issue
                for (let key in retValue){
                    if (Number.isNaN(retValue[key])) {
                        retValue[key] = "";
                    }
                }
                //#endregion
                return retValue;
            });
            
            if (totalItemPrice <= 0) {
                //footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updateValues);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(updateValues);
            } 
        }

        // setHasUngrouped(false);
        // LoadLabour();
        approvalListing();
        checkApprovalStatusState();
    }, [quoteDetails]);

    //settings useEffect
    useEffect(() => {
        if (settings !== null) {

            setComputeTotals(true);
            // const positions = getDbExtraPositionalDetails(itemList);
            // const dbExtraValue = parseInt(settings?.dbextraCharge);

            // if (positions.length > 0) {
            //     positions.forEach((position) => {
            //         if (position !== null) {
            //             const { index, isInAGroup, isInASubGroup, groupItemList, subGroupItemList, nonGroupItemList } =
            //                 position;

            //             //ref :the way conditions are arranged matters
            //             if (isInAGroup && isInASubGroup) {
            //                 const groupNettEA = calculateGroupWiseTotal(subGroupItemList, "nettEA");
            //                 const updatedDbExCharge = calculateDbExtra(dbExtraValue, groupNettEA);
            //                 const dbExtraRow = itemList[index];

            //                 const updatedDbExtraRow = {
            //                     ...dbExtraRow,
            //                     nettEA: updatedDbExCharge,
            //                     totalPrice: updatedDbExCharge,
            //                 };

            //                 itemList[index] = updatedDbExtraRow;

            //                 triggerComputeGrpTotals(
            //                     [updatedDbExtraRow],
            //                     itemList,
            //                     "Update",
            //                     updatedDbExtraRow.groupId, //newItems[0].groupId,
            //                     updatedDbExtraRow.subGroupId, //sbgrpId,
            //                     dbExtraValue
            //                 );
            //             } else if (isInAGroup) {
            //                 const groupNettEA = calculateGroupWiseTotal(groupItemList, "nettEA");
            //                 const updatedDbExCharge = calculateDbExtra(dbExtraValue, groupNettEA);
            //                 const dbExtraRow = itemList[index];

            //                 const updatedDbExtraRow = {
            //                     ...dbExtraRow,
            //                     nettEA: updatedDbExCharge,
            //                     totalPrice: updatedDbExCharge,
            //                 };

            //                 itemList[index] = updatedDbExtraRow;

            //                 const updatedItemList = resetGroupSubGroupRows(itemList);

            //                 //ref : full grid recalculations
            //                 const formattedList = gridCalculation(updatedItemList, dbExtraValue);

            //                 // footer calculation
            //                 const {
            //                     totalPrice,
            //                     totalSteEeCostRound,
            //                     totalStFutureCost,
            //                     totalActCostExact,
            //                     totalListPrice,
            //                     totalNettEA,
            //                     totalCost,
            //                     totalMargin,
            //                     totalActMargin,
            //                     totalFrcMargin,
            //                     totalDcOnLp,
            //                 } = footerCalculation(formattedList);

            //                 setTotalItemPrice(totalPrice);
            //                 setTotalstEecostRound(totalSteEeCostRound);
            //                 setTotalStFutureCost(totalStFutureCost);
            //                 setTotalActCostEAExact(totalActCostExact);
            //                 setTotalListPrice(totalListPrice);
            //                 setTotalCost(totalCost);
            //                 setTotalNettEA(totalNettEA);
            //                 setMaxMargin(totalMargin);
            //                 setActMargin(totalActMargin);
            //                 setMaxFrcMargin(totalFrcMargin);
            //                 setFooterDisc(totalDcOnLp);

            //                 setItemList(formattedList);
            //                 updateQuoteDetailsForId(id, { selectedItems: formattedList });
            //             } else {
            //                 const groupNettEA = calculateGroupWiseTotal(nonGroupItemList, "nettEA");
            //                 const updatedDbExCharge = calculateDbExtra(dbExtraValue, groupNettEA);
            //                 const dbExtraRow = itemList[index];

            //                 const updatedDbExtraRow = {
            //                     ...dbExtraRow,
            //                     nettEA: updatedDbExCharge,
            //                     totalPrice: updatedDbExCharge,
            //                 };

            //                 itemList[index] = updatedDbExtraRow;

            //                 // footer calculation
            //                 const {
            //                     totalPrice,
            //                     totalSteEeCostRound,
            //                     totalStFutureCost,
            //                     totalActCostExact,
            //                     totalListPrice,
            //                     totalNettEA,
            //                     totalCost,
            //                     totalMargin,
            //                     totalActMargin,
            //                     totalFrcMargin,
            //                     totalDcOnLp,
            //                 } = footerCalculation(itemList);

            //                 setTotalItemPrice(totalPrice);
            //                 setTotalstEecostRound(totalSteEeCostRound);
            //                 setTotalStFutureCost(totalStFutureCost);
            //                 setTotalActCostEAExact(totalActCostExact);
            //                 setTotalListPrice(totalListPrice);
            //                 setTotalCost(totalCost);
            //                 setTotalNettEA(totalNettEA);
            //                 setMaxMargin(totalMargin);
            //                 setActMargin(totalActMargin);
            //                 setMaxFrcMargin(totalFrcMargin);
            //                 setFooterDisc(totalDcOnLp);
            //                 setItemList(itemList);
            //                 updateQuoteDetailsForId(id, { selectedItems: itemList });
            //             }
            //         }
            //     });
            // }
        }
    }, [settings]);

    ////when margin changed, trigger the approval checking
    const {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        refreshStatus, setRefreshStatus,
        shareQuoteFlag, setShareQuoteFlag,
        triggerSaveAsNewFlag, setTriggerSaveAsNewFlag,
        triggerSaveRevision, setTriggerRevision,
        triggerSuffix, setTriggerSuffix,
        checkApprovalStateFor, setCheckApprovalStateFor,
        currAccessLevel, setCurrAccessLevel,
    } = useContext(QteApprovalNotificationContext);

    useEffect(() => {
        if (checkApprovalStateFor !== "" && checkApprovalStateFor !== null && typeof checkApprovalStateFor !== "undefined") {
            checkApprovalStatusState();

        }
    }, [checkApprovalStateFor]);

    const [previousState, setPreviousState] = useState(null);
    useEffect(() => {
        if (totalItemPrice <= 0) resetQteApprovalForm();
        if (previousState !== totalItemPrice) {
            checkApprovalStatusState();
            setPreviousState(totalItemPrice);
        } else {
            console.log(previousState);
        }
        if (refreshStatus) setRefreshStatus(false);
    }, [totalItemPrice, refreshStatus]);

    const getDbExtraPositionalDetails = (itemList) => {
        const positionalDetails = itemList.map((item) => {
            if (
                (item.groupDbExtraId !== undefined && item.groupDbExtraId !== "") ||
                (item.subGroupDbExtraId !== undefined && item.subGroupDbExtraId !== "") ||
                (item.nonGroupDbExtraId !== undefined && item.nonGroupDbExtraId !== "")
            ) {
                return {
                    index: itemList.indexOf(item),
                    isInAGroup: item.groupName !== GroupItemTypes.NON_GROUPED_ITEMS ? true : false,
                    isInASubGroup: item.subGroupId !== "" ? true : false,
                    isNotInAGroup: item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? true : false,
                    groupItemList: getGroupedItemsWithoutDBExtra(itemList, item),
                    subGroupItemList: getSubGroupedItemsWithoutDBExtra(itemList, item),
                    nonGroupItemList: getNonGroupedItemsWithoutDBExtra(itemList, item),
                };
            }

            return null;
        });

        return positionalDetails;
    };

    const getMaxSubGroupCount = (parts) => {
        const maxSubGroupCount = Math.max(
            ...parts.map((item) => {
                if (item.subGroupName && !item.subGroupName.includes(NonGroupTypes.NON_SUB_GROUP)) {
                    return item?.subGroupName == "" ? 0 : item.subGroupName.split(" ")[2];
                } else {
                    return 0;
                }
            })
        );

        return maxSubGroupCount;
    };

    const getSubGroupWiseMaxGroupCount = (groupedData) => {
        let maxCountForEachGroup = {};

        for (let group in groupedData) {
            const maxCount = getMaxSubGroupCount(groupedData[group]);
            maxCountForEachGroup[group] = maxCount;
        }

        return maxCountForEachGroup;
    };

    const calculateTotalPrice = (itemList, subGroupQty = 1, groupQty = 1) => {
        let itm = itemList.filter((f) => f.rowType !== "AI"); //// ignore assembly items and fetch the sub totals of it which is type = A
        return itm.reduce((totalPrice, item) => {
            if (item.stStockCode !== "" && item.totalPrice) {
                totalPrice += parseFloat(item.totalPrice * subGroupQty * groupQty);
            }
            return totalPrice;
        }, 0);
    };

    const calculateTotalstEecostRound = (itemList) => {
        let itm = itemList.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
        return itm.reduce((stEecost, item) => {
            if (item.stStockCode !== "" && item.stEecost) {
                stEecost += parseFloat(item.stEecost);
            }

            return stEecost;
        }, 0);
    };

    const calculateTotalStFutureCost = (itemList) => {
        let itm = itemList.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
        return itm.reduce((stFutureCost, item) => {
            if (item.stStockCode !== "" && item.stFutureCost) {
                stFutureCost += parseFloat(item.stFutureCost);
            }

            return stFutureCost;
        }, 0);
    };

    const calculateTotalActCostEAExact = (itemList) => {
        let itm = itemList.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
        return itm.reduce((actCostExact, item) => {
            if (item.stStockCode !== "" && item.actCostExact) {
                actCostExact += parseFloat(item.actCostExact);
            }
            return actCostExact;
        }, 0);
    };

    const calculateTotalListPrice = (itemList) => {
        let itm = itemList.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
        return itm.reduce((listPrice, item) => {
            if (item.stStockCode !== "" && item.listPrice) {
                listPrice += parseFloat(item.listPrice);
            }
            return listPrice;
        }, 0);
    };

    const calculateTotalNettEA = (itemList) => {
        const itemsWithNettEA = itemList.filter(
            (item) => item.stStockCode !== "" && item.rowType !== "AI" && item.rowType !== "DX"
        ); //// ignore assembly items and fetch the sub totals of it which is type = A

        const totalNettEA = itemsWithNettEA.reduce((total, item) => {
            const nettEA = parseFloat(item.nettEA);
            if (!isNaN(nettEA)) {
                return total + nettEA;
            }
            return total;
        }, 0);

        return totalNettEA;
    };

    // comment here - no longer needed for footer calculations
    // useEffect(() => {
    //     const newTotalNettEA = calculateTotalNettEA(itemList);
    //     setTotalNettEA(newTotalNettEA);

    //     //#region added to skip the assembly totals nettea for computation on the discount
    //     const groupTotalAssemblyNettEA = itemList.filter((f) => f.rowType === "A");
    //     const totalNettEAAssembly = groupTotalAssemblyNettEA.reduce((total, item) => total + item.nettEA, 0);
    //     // const totalQtyAssembly = groupTotalAssemblyNettEA.reduce((total, item) => total + item.qty, 0);
    //     // const computedNettEAToDeduct = totalNettEAAssembly * totalQtyAssembly;
    //     //#endregion
    //     const newTotalstEecostRound = calculateTotalstEecostRound(itemList);
    //     setTotalstEecostRound(newTotalstEecostRound);

    //     const newTotalStFutureCost = calculateTotalStFutureCost(itemList);
    //     setTotalStFutureCost(newTotalStFutureCost);

    //     const newTotalListPrice = calculateTotalListPrice(itemList);
    //     setTotalListPrice(newTotalListPrice);

    //     const newTotalActCostEAExact = calculateTotalActCostEAExact(itemList);
    //     setTotalActCostEAExact(newTotalActCostEAExact);

    //     let footermargin = 0;

    //     if (!isNaN(newTotalNettEA) && newTotalNettEA > 0) {
    //         footermargin = ((newTotalNettEA - newTotalstEecostRound) / newTotalNettEA) * 100;
    //     }
    //     setMaxMargin(footermargin);
    //     setMargin(footermargin);

    //     let footerFrcmargin = 0;
    //     if (!isNaN(newTotalNettEA) && newTotalNettEA > 0) {
    //         footerFrcmargin = ((newTotalNettEA - newTotalStFutureCost) / newTotalNettEA) * 100;
    //     }
    //     setMaxFrcMargin(footerFrcmargin);

    //     let footerActmargin = 0;
    //     if (!isNaN(newTotalNettEA) && newTotalNettEA > 0) {
    //         footerActmargin = ((newTotalNettEA - newTotalActCostEAExact) / newTotalNettEA) * 100;
    //     }
    //     setActMargin(footerActmargin);

    //     let footerDisc = 0;
    //     if (!isNaN(newTotalListPrice) && newTotalListPrice > 0) {
    //         footerDisc = ((newTotalListPrice - (newTotalNettEA - totalNettEAAssembly)) / newTotalListPrice) * 100;
    //     }
    //     setFooterDisc(footerDisc);

    //     const totalPSum = calculateTotalPrice(itemList);
    //     setTotalItemPrice(totalPSum);
    //     setTotal(totalPSum);

    //     const totalCostSum = calculateTotalCost(itemList);
    //     setTotalCost(totalCostSum);
    // }, [
    //     itemList,
    //     totalNettEA,
    //     totalstEecostRound,
    //     totalListPrice,
    //     totalActCostEAExact,
    //     maxMargin,
    //     maxActMargin,
    //     footerDisc,
    //     setMaxFrcMargin,
    //     maxFrcMargin,
    //     margin,
    //     totalStFutureCost,
    //     labourChanges,
    // ]);

    const calculateTotalCost = (itemList, subGroupQty = 1, groupQty = 1) => {
        let itm = itemList.filter((f) => f.rowType !== "AI" && f.rowType !== "DX"); //// ignore assembly items and fetch the sub totals of it which is type = A
        if (itm && itm.length > 0) {
            const itemsWithStockCode = itm.filter((item) => item.stStockCode);

            // const totalCosts = itemsWithStockCode.map((item) => {
            //     return { totalCost: parseFloat(item.totalCost) || 0, qty: item.qty || 1 };
            // });

            // const totalCostSum = parseFloat(
            //     totalCosts.reduce((accumulator, currentValue) => {
            //         {
            //             return parseFloat(
            //                 // accumulator + parseFloat(currentValue.totalCost * currentValue.qty * subGroupQty * groupQty)
            //                 accumulator + parseFloat(currentValue.totalCost * subGroupQty * groupQty)
            //             );
            //         }
            //     }, 0)
            // );

            // return totalCostSum;

            const totalCostSum = itemsWithStockCode.reduce((accumulator, currentValue) => {
                {
                    return (
                        accumulator +
                        calculateItemTotalCost(currentValue.qty, currentValue.stEecost) * subGroupQty * groupQty
                    );
                }
            }, 0);

            return totalCostSum;
        }
        return 0;
    };

    // useEffect(() => {
    //     const totalPSum = calculateTotalPrice(itemList);
    //     setTotalItemPrice(totalPSum);
    //     setTotal(totalPSum);
    //     const totalCostSum = calculateTotalCost(itemList);
    //     setTotalCost(totalCostSum);
    // }, [itemList, setTotalItemPrice, setTotalCost, setTotal, labourChanges]);

    useEffect(() => {
        // debugger;

        const totalPartNumbers = itemList.filter(
            (item) => item.stStockCode && item.rowType !== "AI" && item.rowType !== "LN"
        ).length; //// not include the Type A which is the total line for assembly
        setTotalPartLines(totalPartNumbers);
        setItemGridCount(totalPartNumbers);
    }, [itemList, setTotalPartLines, setItemGridCount]);


    const paginate = (items, pageNumber, pageSize) => {
        const startIndex = (pageNumber - 1) * pageSize;
        return items.slice(startIndex, startIndex + pageSize);
    };

    const onAdd = (e) => {
        const option = e?.itemIndex;

        if (option === 0) {
            insertItem();
        } else if (option === 1) {
            addDbExtra();
        } else if (option === 2) {
            insertDbExtra();
        }
    };

    const insertDbExtra = async () => {
        setIsQuoteSaved(false);
        try {
            if (selectedItems.length <= 0) {
                toast.error("Please select a row to insert DB Extra", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

            if (selectedItems.length > 1) {
                toast.error("Please select a single row to insert DB Extra", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

            let systemSettings = null;
            const dbExtra = await getResponse("DbExtra/getdbextra");

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            if (dbExtra) {
                const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
                const data = dbExtra[0];
                const consumableId = data.id;
                const itemIndex = itemList.findIndex((item) => item.rowId === selectedItems[0].rowId);
                const updatedItemList = [...itemList];
                let dbExtraRow = getDbExtraRow(data);
                //dbExtraRow = setPosition(dbExtraRow, itemList)// this is commented due to DBx not insert to the subgroup level
                const currentPosition = getRowPosition(updatedItemList, itemIndex);
                const {
                    onAGroup,
                    onASubGroup,
                    onAGroupRow,
                    onASubGroupRow,
                    isAGroupRowItem,
                    isASubGroupItem,
                    isNonGroupedItem,
                    groupDetails,
                } = currentPosition;
                const gId = groupDetails.prevGroupId;
                const gName = groupDetails.prevGroupName;
                const sgId = groupDetails.prevSubGroupId;
                const sgName = groupDetails.prevSubGroupName;

                dbExtraRow = {
                    ...dbExtraRow,
                    groupId: gId,
                    groupName: gName,
                    subGroupId: sgId,
                    subGroupName: sgName,
                };

                if (onAGroup) {
                    if (isAGroupRowItem) {
                        const existingDbExtra = getExistingDbExtraRows(
                            updatedItemList,
                            gId,
                            null,
                            consumableId,
                            GroupTypes.GROUP
                        );

                        if (existingDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        let groupItems = [];
                        let totalNettEA = -1;
                        let dbExtraValue = -1;

                        if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                            groupItems = getAllItemsInAGroup(updatedItemList, gId); //includes sub group items as well
                            groupItems = getQtyBasedValues(groupItems);
                            totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                            dbExtraRow = {
                                ...dbExtraRow,
                                nettEA: dbExtraValue,
                                totalPrice: dbExtraValue,
                                groupDbExtraId: consumableId,
                            };
                        } else {
                            groupItems = getAllItemsWithoutGroupSubGroupAndDbExtra(updatedItemList);
                            groupItems = getQtyBasedValues(groupItems);
                            totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                            dbExtraRow = {
                                ...dbExtraRow,
                                nettEA: dbExtraValue,
                                totalPrice: dbExtraValue,
                                nonGroupDbExtraId: consumableId,
                            };
                        }

                        updatedItemList.splice(itemIndex, 0, dbExtraRow);

                        let groupedItems = getAllExistingGroupedItems(updatedItemList, gId);
                        groupedItems = getQtyBasedValues(groupedItems);

                        const updatedGroupNettEA = calculateGroupWiseTotal(groupedItems, "nettEA");
                        const updatedGroupTotalPrice = calculateGroupWiseTotal(groupedItems, "totalPrice");

                        let groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        let groupRowIndex = -1;
                        let updatedGroupRow = null;

                        if (groupRow) {
                            groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                            updatedGroupRow = {
                                ...groupRow,
                                nettEA: updatedGroupNettEA,
                                totalPrice: updatedGroupTotalPrice,
                            };

                            updatedItemList[groupRowIndex] = updatedGroupRow;
                        }

                        //#region temporarily commented and applied the global computation
                        //// footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA: totalNettEAFooter,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(updatedItemList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEAFooter);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);
                        //#endregion
                        const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                        const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                        let formattedList = itemPositioning(true, updatedItems);
                        setItemList(formattedList);
                        updateQuoteDetailsForId(id, { selectedItems: formattedList });
                    } else if (isASubGroupItem) {
                        const existingDbExtra = getExistingDbExtraRows(
                            updatedItemList,
                            gId,
                            sgId,
                            consumableId,
                            GroupTypes.SUB_GROUP
                        );

                        if (existingDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given sub group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        let subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        subGroupItems = getQtyBasedValues(subGroupItems);
                        const totalNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            subGroupDbExtraId: consumableId,
                        };

                        updatedItemList.splice(itemIndex, 0, dbExtraRow);

                        let subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        subGroupedItems = getQtyBasedValues(subGroupedItems);
                        const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                        const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                        const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);

                        const updatedSubGroupRow = {
                            ...subGroupRow[0],
                            nettEA: updatedSubGroupNettEA,
                            totalPrice: updatedSubGroupTotalPrice,
                        };

                        updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                        //group related calculations
                        // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);
                        let groupRowItems = getGroupedRowItems(updatedItemList, gId);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        //     if (subGroupRow.qty > 1) {
                        //         return {
                        //             ...subGroupRow,
                        //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                        //             //   lcMargin: "",
                        //             // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                        //         };
                        //     }
                        //     return subGroupRow;
                        // });
                        // const updatedSubGroupRows = getListWithQtyBasedNettEA(subGroupRows);

                        // const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                        groupRowItems = getListWithQtyBasedNettEA(groupRowItems);
                        subGroupRows = getListWithQtyBasedNettEA(subGroupRows);

                        const groupItems = [...groupRowItems, ...subGroupRows];

                        const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: updatedGroupNettEA,
                            totalPrice: updatedGroupTotalPrice,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        //#region temporarily commented and applied the global computation
                        // // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA: totalNettEAFooter,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(updatedItemList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEAFooter);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);
                        //#endregion    
                        const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                        const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                        let formattedList = itemPositioning(true, updatedItems);
                        setItemList(formattedList);
                        updateQuoteDetailsForId(id, { selectedItems: formattedList });
                    } else {
                        const nonGroupId = nonGroupedId.current ? nonGroupedId.current : uuidv4();
                        nonGroupedId.current = nonGroupId;

                        let groupItems = getAllItemsWithoutGroupSubGroupAndDbExtra(updatedItemList);
                        groupItems = getQtyBasedValues(groupItems);

                        const totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            nonGroupDbExtraId: consumableId,
                            groupId: nonGroupId,
                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                        };

                        updatedItemList.splice(itemIndex, 0, dbExtraRow);
                        //#region temporarily commented and applied the global computation
                        // // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA: totalNettEAFooter,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(updatedItemList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEAFooter);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);
                        //#endregion
                        const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                        const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                        let formattedList = itemPositioning(true, updatedItems);
                        setItemList(formattedList);
                        updateQuoteDetailsForId(id, { selectedItems: formattedList });
                    }
                } else if (onASubGroup) {
                    if (isAGroupRowItem) {
                        const existingDbExtra = getExistingDbExtraRows(
                            updatedItemList,
                            gId,
                            null,
                            consumableId,
                            GroupTypes.GROUP
                        );

                        if (existingDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        // const groupItems1 = getAllItemsInAGroup(updatedItemList, gId); //includes sub group items as well -- lastGroupId

                        let groupRowItems = getGroupedRowItems(updatedItemList, gId);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);

                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const subGroupID = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, subGroupID);
                            const dbExtra = isDbExtraAdded(sGRowItems, gId, subGroupID, GroupTypes.SUB_GROUP);

                            if (dbExtra) {
                                let itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, subGroupID);
                                itemsWithoutDbExtra = getQtyBasedValues(itemsWithoutDbExtra);
                                const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                                return {
                                    ...subGroupRow,
                                    nettEA: nettEA * subGroupRow.qty,
                                };
                            }
                            return subGroupRow;
                        });

                        const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                        const totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            groupDbExtraId: consumableId,
                        };

                        updatedItemList.splice(itemIndex, 0, dbExtraRow);

                        // const groupedItems = getAllExistingGroupedItems(updatedItemList, gId);
                        let uGRowItems = getGroupedRowItems(updatedItemList, gId);
                        uGRowItems = getQtyBasedValues(uGRowItems);
                        const sgList = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        // const uSgList = sgList.map((subGroupRow) => {
                        //     if (subGroupRow.qty > 1) {
                        //         return {
                        //             ...subGroupRow,
                        //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                        //             //  lcMargin: "",
                        //         };
                        //     }
                        //     return subGroupRow;
                        // });
                        const uSgList = getListWithQtyBasedNettEA(sgList);
                        const uGItems = [...uGRowItems, ...uSgList];

                        const updatedGroupNettEA = calculateGroupWiseTotal(uGItems, "nettEA");
                        const updatedGroupTotalPrice = calculateGroupWiseTotal(uGItems, "totalPrice");
                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: updatedGroupNettEA,
                            totalPrice: updatedGroupTotalPrice,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                        //#region temporarily commented and applied the global computation
                        // // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA: totalNettEAFooter,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(updatedItemList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEAFooter);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);
                        //#endregion
                        const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                        const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                        let formattedList = itemPositioning(true, updatedItems);
                        setItemList(formattedList);
                        updateQuoteDetailsForId(id, { selectedItems: formattedList });
                    } else if (isASubGroupItem) {
                        const existingDbExtra = getExistingDbExtraRows(
                            updatedItemList,
                            gId,
                            sgId,
                            consumableId,
                            GroupTypes.SUB_GROUP
                        );

                        if (existingDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given sub group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        let subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        subGroupItems = getQtyBasedValues(subGroupItems);
                        const totalNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            subGroupDbExtraId: consumableId,
                        };

                        updatedItemList.splice(itemIndex, 0, dbExtraRow);

                        let subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        subGroupedItems = getQtyBasedValues(subGroupedItems);
                        const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                        const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                        const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);

                        const updatedSubGroupRow = {
                            ...subGroupRow[0],
                            nettEA: updatedSubGroupNettEA,
                            totalPrice: updatedSubGroupTotalPrice,
                        };

                        updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                        //group related calculations
                        // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);
                        let groupRowItems = getGroupedRowItems(updatedItemList, gId);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            if (subGroupRow.qty > 1) {
                                return {
                                    ...subGroupRow,
                                    nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                    // lcMargin: "",
                                    // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                };
                            }
                            return subGroupRow;
                        });

                        const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                        const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: updatedGroupNettEA,
                            totalPrice: updatedGroupTotalPrice,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;



                        const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                        const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                        let formattedList = itemPositioning(true, updatedItems);
                        setItemList(formattedList);
                        updateQuoteDetailsForId(id, { selectedItems: formattedList });

                        // const existingDbExtra = getExistingDbExtraRows(
                        //     updatedItemList,
                        //     gId,
                        //     sgId,
                        //     consumableId,
                        //     GroupTypes.SUB_GROUP
                        // );

                        // if (existingDbExtra.length > 0) {
                        //     toast.error("You have already added db extra to the given sub group!");
                        //     return;
                        // }

                        // const subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        // const totalNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        // const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                        // dbExtraRow = {
                        //     ...dbExtraRow,
                        //     nettEA: dbExtraValue,
                        //     totalPrice: dbExtraValue,
                        //     subGroupDbExtraId: consumableId,
                        // };

                        // updatedItemList.splice(itemIndex, 0, dbExtraRow);

                        // const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        // const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                        // const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");
                        // const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                        // const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);

                        // const updatedSubGroupRow = {
                        //     ...subGroupRow[0],
                        //     nettEA: updatedSubGroupNettEA,
                        //     totalPrice: updatedSubGroupTotalPrice,
                        // };

                        // updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                        // //group related calculations
                        // // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);
                        // const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                        // const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        //     if (subGroupRow.qty > 1) {
                        //         return {
                        //             ...subGroupRow,
                        //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                        //             totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                        //         };
                        //     }
                        //     return subGroupRow;
                        // });
                        // const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                        // const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        // const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        // const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        // const groupQty = groupRow.qty;
                        // const hasGroupQtyChanged = groupQty > 1;

                        // const updatedGroupRow = {
                        //     ...groupRow,
                        //     nettEA: updatedGroupNettEA,
                        //     totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                        // };

                        // updatedItemList[groupRowIndex] = updatedGroupRow;

                        // setItemList(updatedItemList);
                        // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    }
                } else if (onAGroupRow) {
                    const existingDbExtra = getExistingDbExtraRows(
                        updatedItemList,
                        gId,
                        null,
                        consumableId,
                        GroupTypes.GROUP
                    );

                    if (existingDbExtra.length > 0) {
                        toast.error("You have already added db extra to the given sub group!", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }

                    let groupItems = getAllItemsInAGroup(updatedItemList, gId);
                    groupItems = getQtyBasedValues(groupItems);
                    let totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    let dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                    if (sgId !== "") {
                        // this is to handle the case where a sub group is INSERTED to a INSERTED GROUP ROW. (INSERT GROUP ROW -> INSERT SUB GROUP ROW -> INSERT DB EXTRA)
                        const dbExtra = isDbExtraAdded(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                        if (dbExtra) {
                            toast.error("You have already added db extra to the given sub group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            subGroupDbExtraId: consumableId,
                        };
                    } else {
                        if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                            groupItems = getAllItemsWithoutGroupSubGroupAndDbExtra(updatedItemList);
                            groupItems = getQtyBasedValues(groupItems);
                            totalNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);
                        }

                        dbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                            groupDbExtraId: consumableId,
                        };
                    }

                    updatedItemList.splice(itemIndex, 0, dbExtraRow);

                    let groupedItems = getAllExistingGroupedItems(updatedItemList, gId);
                    groupedItems = getQtyBasedValues(groupedItems);
                    const updatedGroupNettEA = calculateGroupWiseTotal(groupedItems, "nettEA");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupedItems, "totalPrice");

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    let groupRowIndex = -1;
                    let updatedGroupRow = null;

                    if (groupRow) {
                        groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                        updatedGroupRow = {
                            ...groupRow,
                            nettEA: updatedGroupNettEA,
                            totalPrice: updatedGroupTotalPrice,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                    //#region temporarily commented and applied the global computation
                    // // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA: totalNettEAFooter,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(updatedItemList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEAFooter);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion
                    const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                    const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                    let formattedList = itemPositioning(true, updatedItems);
                    setItemList(formattedList);
                    updateQuoteDetailsForId(id, { selectedItems: formattedList });
                } else if (onASubGroupRow) {
                    const existingDbExtra = getExistingDbExtraRows(
                        updatedItemList,
                        gId,
                        sgId,
                        consumableId,
                        GroupTypes.SUB_GROUP
                    );

                    if (existingDbExtra.length > 0) {
                        toast.error("You have already added db extra to the given sub group!", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }

                    let subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    subGroupItems = getQtyBasedValues(subGroupItems);
                    const totalNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                    dbExtraRow = {
                        ...dbExtraRow,
                        nettEA: dbExtraValue,
                        totalPrice: dbExtraValue,
                        subGroupDbExtraId: consumableId,
                    };

                    updatedItemList.splice(itemIndex, 0, dbExtraRow);

                    let subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    subGroupedItems = getQtyBasedValues(subGroupedItems);
                    const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                    const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");
                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                    const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);

                    const updatedSubGroupRow = {
                        ...subGroupRow[0],
                        nettEA: updatedSubGroupNettEA,
                        totalPrice: updatedSubGroupTotalPrice,
                    };

                    updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                    //group related calculations
                    // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);
                    let groupRowItems = getGroupedRowItems(updatedItemList, gId);
                    let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                    //     if (subGroupRow.qty > 1) {
                    //         return {
                    //             ...subGroupRow,
                    //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                    //             //  lcMargin: "",
                    //             // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                    //         };
                    //     }
                    //     return subGroupRow;
                    // });

                    groupRowItems = getListWithQtyBasedNettEA(groupRowItems);
                    subGroupRows = getListWithQtyBasedNettEA(subGroupRows);

                    const groupItems = [...groupRowItems, ...subGroupRows];

                    const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, gId);

                    const updatedGroupRow = {
                        ...groupRow,
                        nettEA: updatedGroupNettEA,
                        totalPrice: updatedGroupTotalPrice,
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                    //#region temporarily commented and applied the global computation
                    // // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA: totalNettEAFooter,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(updatedItemList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEAFooter);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion
                    const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                    const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                    let formattedList = itemPositioning(true, updatedItems);
                    setItemList(formattedList);
                    updateQuoteDetailsForId(id, { selectedItems: formattedList });
                } else if (isNonGroupedItem) {
                    // non group related calculations
                    const existingDbExtra = getExistingDbExtraRows(
                        updatedItemList,
                        gId,
                        null,
                        consumableId,
                        GroupTypes.NON_GROUP
                    );

                    if (existingDbExtra.length > 0) {
                        toast.error("You have already added db extra to the current non group!", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }

                    let nonGroupItems = getAllItemsInAGroup(updatedItemList, gId);
                    nonGroupItems = getQtyBasedValues(nonGroupItems);
                    const totalNettEA = calculateGroupWiseTotal(nonGroupItems, "nettEA");
                    const dbExtraValue = calculateDbExtra(dbExtraCharge, totalNettEA);

                    dbExtraRow = {
                        ...dbExtraRow,
                        nettEA: dbExtraValue,
                        totalPrice: dbExtraValue,
                        nonGroupDbExtraId: consumableId,
                    };

                    updatedItemList.splice(itemIndex, 0, dbExtraRow);

                    const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
                    const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                    let formattedList = itemPositioning(true, updatedItems);
                    setItemList(formattedList);
                    updateQuoteDetailsForId(id, { selectedItems: formattedList });
                }
                // setComputeTotals(true);
            } else {
                toast.error("Something went wrong while loading db extra.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }
        } catch (error) {
            console.error("Error : ", error);
        }
    };

    const getRowPosition = (items, itemIndex) => {
        // Ensure itemIndex is within bounds

        if (itemIndex < 0 || itemIndex >= items.length) {
            throw new Error("itemIndex out of bounds");
        }

        const currentItem = items[itemIndex];

        // if (currentItem.groupName === "Non Grouped Items") {
        //     return {
        //         isNonGroupedItem: true,
        //     };
        // }

        if (currentItem.rowType === "G") {
            //currentItem.displayGroupName !== ""
            // this is a group row
            return {
                onAGroup: true,
                onASubGroup: false,
                onAGroupRow: false,
                onASubGroupRow: false,
                isNonGroupedItem: false,
                isAGroupRowItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId === "", //if clicked on a sub group row, then subGroupId will not be empty.
                isASubGroupItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId !== "",
                isNonGroupedItem: false,
                groupDetails: {
                    index: itemIndex,
                    prevGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].groupId ? items[itemIndex - 1].groupId : "",
                    prevGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].groupName ? items[itemIndex - 1].groupName : "",
                    prevSubGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupId ? items[itemIndex - 1].subGroupId : "",
                    prevSubGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupName
                            ? items[itemIndex - 1].subGroupName
                            : "",
                },
            }; //group row

            //if onAGroup, then check if there is a sub group above, then return subGroupId, subGroupName, groupId, groupName - same principle for all
        } else if (currentItem.rowType === "SG") {
            //currentItem.displaySubGroupName !== ""
            return {
                onAGroup: false,
                onASubGroup: true,
                onAGroupRow: false,
                onASubGroupRow: false,
                isNonGroupedItem: false,
                isAGroupRowItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId === "", //if clicked on a sub group row, then subGroupId will not be empty.
                isASubGroupItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId !== "",
                isNonGroupedItem: false,
                groupDetails: {
                    index: itemIndex,
                    prevGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].groupId ? items[itemIndex - 1].groupId : "",
                    prevGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].groupName ? items[itemIndex - 1].groupName : "",
                    prevSubGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupId ? items[itemIndex - 1].subGroupId : "",
                    prevSubGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupName
                            ? items[itemIndex - 1].subGroupName
                            : "",
                },
            }; //sub group row
        } else if (currentItem.groupName === GroupItemTypes.NON_GROUPED_ITEMS) {
            //currentItem.groupId !== "" && currentItem.subGroupId === ""
            // non grouped items
            return {
                onAGroup: false,
                onASubGroup: false,
                onAGroupRow: false,
                onASubGroupRow: false,
                isNonGroupedItem: true,
                isAGroupRowItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId === "", //if clicked on a sub group row, then subGroupId will not be empty.
                isASubGroupItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId !== "",
                groupDetails: {
                    index: itemIndex,
                    prevGroupId:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].groupId
                                ? items[itemIndex - 1].groupId
                                : ""
                            : items[itemIndex] && items[itemIndex].groupId
                                ? items[itemIndex].groupId
                                : "",
                    prevGroupName:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].groupName
                                ? items[itemIndex - 1].groupName
                                : ""
                            : items[itemIndex] && items[itemIndex].groupName
                                ? items[itemIndex].groupName
                                : "",
                    prevSubGroupId:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].subGroupId
                                ? items[itemIndex - 1].subGroupId
                                : ""
                            : items[itemIndex] && items[itemIndex].subGroupId
                                ? items[itemIndex].subGroupId
                                : "",
                    prevSubGroupName:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].subGroupName
                                ? items[itemIndex - 1].subGroupName
                                : ""
                            : items[itemIndex] && items[itemIndex].subGroupName
                                ? items[itemIndex].subGroupName
                                : "",
                },
            };
        } else if (currentItem.groupId !== "" && currentItem.subGroupId !== "") {
            return {
                onAGroup: false,
                onASubGroup: false,
                onAGroupRow: false,
                onASubGroupRow: true,
                isNonGroupedItem: false,
                isAGroupRowItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId === "", //if clicked on a sub group row, then subGroupId will not be empty.
                isASubGroupItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId !== "",
                isNonGroupedItem: false,
                groupDetails: {
                    index: itemIndex,
                    prevGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].groupId ? items[itemIndex - 1].groupId : "",
                    prevGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].groupName ? items[itemIndex - 1].groupName : "",
                    prevSubGroupId:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupId ? items[itemIndex - 1].subGroupId : "",
                    prevSubGroupName:
                        items[itemIndex - 1] && items[itemIndex - 1].subGroupName
                            ? items[itemIndex - 1].subGroupName
                            : "",
                },
            }; //sub grouped row
        } else {
            //grouped row
            return {
                onAGroup: false,
                onASubGroup: false,
                onAGroupRow: true,
                onASubGroupRow: false,
                isNonGroupedItem: false,
                isAGroupRowItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId === "", //if clicked on a sub group row, then subGroupId will not be empty.
                isASubGroupItem:
                    items[itemIndex - 1] &&
                    items[itemIndex - 1]?.groupId !== "" &&
                    items[itemIndex - 1]?.subGroupId !== "",
                groupDetails: {
                    index: itemIndex,
                    prevGroupId:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].groupId
                                ? items[itemIndex - 1].groupId
                                : ""
                            : items[itemIndex] && items[itemIndex].groupId
                                ? items[itemIndex].groupId
                                : "",
                    prevGroupName:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].groupName
                                ? items[itemIndex - 1].groupName
                                : ""
                            : items[itemIndex] && items[itemIndex].groupName
                                ? items[itemIndex].groupName
                                : "",
                    prevSubGroupId:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].subGroupId
                                ? items[itemIndex - 1].subGroupId
                                : ""
                            : items[itemIndex] && items[itemIndex].subGroupId
                                ? items[itemIndex].subGroupId
                                : "",
                    prevSubGroupName:
                        itemIndex - 1 >= 0
                            ? items[itemIndex - 1] && items[itemIndex - 1].subGroupName
                                ? items[itemIndex - 1].subGroupName
                                : ""
                            : items[itemIndex] && items[itemIndex].subGroupName
                                ? items[itemIndex].subGroupName
                                : "",
                },
            };
        }
    };

    const addItem = () => {
        setIsQuoteSaved(false);
        //// add item should be aded on the last row.
        ///// Insert Item should be triggered if the user clicks on the row. 
        setSelectedItems([]);
        // if (selectedItems.length == 0) {
        setDialogVisible(!dialogVisible);
        // } else insertItem();
        // else toast.error("Cannot add items! please insert an item for the selected Record", {
        //     position: "top-center",
        //     transition: Slide,
        // });
    };

    const insertItem = () => {
        setIsQuoteSaved(false);
        setDialogVisible(!dialogVisible);
    };

    const closeDialog = () => {
        setDialogVisible(false);
        setAddItems([]);
    };

    const handleSelectedItems = (items) => {
        setSelectedItems(items);

    };

    const onAddLabour = () => {
        setIsQuoteSaved(false);
        setLabourFormVisible(true);
    };

    const exportItems = () => {
        //  setConsolidateGrid(false);
        if (excelExportRef.current) {
            excelExportRef.current.save();
        }
    };


        const [isDropdownOpen, setIsDropdownOpen] = useState(false);
        const dropdownRef = useRef(null);

        const [scrollValue, setScrollValue] = useState(100);
        const showHideScroll = (percentScroll) => {
            setScrollValue(percentScroll.value.id);
            sessionStorage.setItem("scrollValId", percentScroll.value.id);
            sessionStorage.setItem("scrollValText", percentScroll.value.text);
    

            setIsDropdownOpen(!isDropdownOpen);
        };

    // document.querySelector('.grid-container').addEventListener('click', function() {
    //     console.log("Lebron");
    // });


    const ExportItemsConsolited = () => {
        //  setConsolidateGrid(true);
        if (excelExportPartConsolidateRef.current) {
            excelExportPartConsolidateRef.current.save();
        }
    };

    async function updateSoh() {
        setIsQuoteSaved(false);
        try {
            const userId = user?.homeAccountId;
            if (!userId) {
                throw new Error("User ID is undefined");
            }

            const updatedData = await Promise.all(
                itemList.map(async (item) => {
                    // If the item has a displayGroupName or displaySubGroupName, return it unchanged
                    if (item.displayGroupName || item.displaySubGroupName) {
                        return item;
                    }

                    // Otherwise, update the SOH for the item
                    if (item.stStockCode === undefined || item.stStockCode.trim() === "" || item.stStockCode === null || ["LN","DX","A","AI"].includes(item?.rowType)) {
                        return item;
                    }

                    const stockCode = item.stStockCode

                    const data = await getResponse("Stocks/getsoh", { userId: userId, stockCode: stockCode });

                    if (data === null || data === undefined) {
                        // Adjusted this line
                        throw new Error(`No data received for stock code: ${item.stStockCode}`);
                    }

                    // Clone the item and update the SOH, to avoid mutating the original item
                    const updatedItem = { ...item, soh: data };
                    return updatedItem;
                })
            );

            // Update your state with the updated data
            setItemList(updatedData);
            toast.success("SOH Updated Successfully", {
                autoClose: 2000,
                position: "top-center",
                transition: Slide,
            });
        } catch (error) {
            console.error("Error updating SOH:", error.message);
            toast.error(`Error updating SOH: ${error.message}`, {
                autoClose: 4000,
                position: "top-center",
                transition: Slide,
            });
        }
    }

    const onActionItems = (e) => {
        const option = e?.itemIndex;

        if (option === 0) {
            importItems();
        } else if (option === 1) {
            exportItems();
        } else if (option === 2) {
            ExportItemsConsolited();
        } else {
            updateSoh();
        }
    };

    const onActionLabour = (e) => {
        setIsQuoteSaved(false);
        const option = e?.itemIndex;

        if (option === 1) {
            editLabourHours();
        } else if (option === 2) setInsertLabourItems(true);
        else resetLabour();

        setLabourFormVisible(true);
    };
    // useEffect(() => {

    //     if (selectedItems.length > 0 && selectedItems[0] && selectedItems[0].stStockCode) {
    //         const fetchData = async () => {
    //             try {
    //                 const data = await getResponse("SOH/gettotalcomppartsbystockcode", { stStockCode: selectedItems[0].stStockCode });
    //                 setTotalCompParts(data);
    //             } catch (error) {
    //                 console.error("Error fetching data:", error);

    //             }
    //         };
    //         fetchData();
    //     }

    // }, [selectedItems]);

    const onClickBOM = async () => {
        setStockCode(selectedItems[0].stStockCode);
        setBOMPartSub(true);
        setTotalSoh(selectedItems[0].soh === '' ? '0' : selectedItems[0].soh);
        if (selectedItems[0].stStockCode === "" || selectedItems[0].stStockCode === undefined) {
            toast.error("Please Select Part No", {
                position: "top-center",
                transition: Slide,
            });
        } else {
            let totCompParts = 0;
            try {
                if (selectedItems.length > 0 && selectedItems[0] && selectedItems[0].stStockCode) {
                    const data = await getResponse("SOH/gettotalcomppartsbystockcode", { stStockCode: selectedItems[0].stStockCode });
                    totCompParts = data;
                }
            } catch (error) {
                console.error("Error fetching data SOH:", error);
            }
            
            if (totCompParts > 0) {
                setComponentPartsWhseDialogVisibleub(true);
            }
            else {
                toast.error("Sorry ! No Component part is found.", {
                    position: "top-center",
                    transition: Slide,
                });
            }
        }
    };
    const handleCopy = () => {
        setUnitCopy(false);
        setIsPriceListCopyed(false);
        navigator.clipboard.writeText('');

        let assemblyId = "";
        let assemblyGroupName = "";
        let displayAssemblyGroupName = "";

        const newItems = selectedItems.map((items) => {
            let dcOnLp = items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? '' : parseFloat(items.dcOnLp);
            let rowId = uuidv4();
            let dcRate = items.listPrice === "" && (items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" || items.rowType === "CI") ? items.listPrice : parseFloat(items.dcRate);
            let actCostExact = items.rowType === "DX" || items.rowType === "LN" ? '' : parseFloat(items.actCostExact);
            let nettEA = items.rowType === "AI" || items.rowType === "LN" ? '' : parseFloat(items.nettEA);
            let totalPrice = items.totalPrice === "" && items.rowType === "AI" || items.rowType === "LN" ? items.totalPrice : parseFloat(items.totalPrice);
            let repDisc = items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? '' : items.repDisc === "" ? "" : parseFloat(items.repDisc);
            let totalCost = items.rowType === "DX" || items.rowType === "LN" ? '' : parseFloat(items.totalCost);
            let lastCost = items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" || items.rowType === "G" || items.rowType === "SG" ? '' : parseFloat(items.lastCost);
            let lastCostExact = items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" || items.rowType === "G" || items.rowType === "SG" ? '' : parseFloat(items.lastCostExact);
            let qty = items.rowType === "LN" ? '' : parseFloat(items.qty);
            let margin = items.margin === "" && items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? '' : parseFloat(items.margin);
            let soh = items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? '' : parseFloat(items.soh);
            let actMargin = items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? items.actMargin : parseFloat(items.actMargin);
            let frcMargin = items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" ? items.actMargin : parseFloat(items.frcMargin);
            let listPrice = items.listPrice === "" && (items.rowType === "A" || items.rowType === "AI" || items.rowType === "DX" || items.rowType === "LN" || items.rowType === "CI") ? items.listPrice : parseFloat(items.listPrice);
            let l60 = items.l60 === 0 || items.rowType === "G" || items.rowType === "SG" ? '' : items.l60;
            let l55 = items.l55 === 0 || items.rowType === "G" || items.rowType === "SG" ? '' : items.l55;
            let l50 = items.l50 === 0 || items.rowType === "G" || items.rowType === "SG" ? '' : items.l50;
            let l20 = items.l20 === 0 || items.rowType === "G" || items.rowType === "SG" ? '' : items.l20;
            let groupDbExtraId = items.rowType === "DX" ? uuidv4() : "";
            assemblyId = items.rowType === "A" ? uuidv4() : assemblyId;
            let assemblyGroupName = items.rowType === "A" || items.rowType === "AI" ? ("ASSEMBLY " + (parseInt(items.assemblyGroupName.match(/(\d+)/)[0]) + 1)) : "";
            let displayAssemblyGroupName = items.rowType === "A" ? ("ASSEMBLY " + (parseInt(items.assemblyGroupName.match(/(\d+)/)[0]) + 1)) : "";
            let subGroupDbExtraId = "";
            let nonGroupDbExtraId = "";
            let subItems = items.rowType === "G" || items.rowType === "SG" || items.rowType === "A" ? null : items.subItems;
            const getPId = itemList.filter((item) => item.rowId === selectedItems[0].rowId).at(-1);
            const parentId = getPId
            ? (getPId.rowType === "G"
                ? getPId.id
                : getPId.rowType === "SG"
                    ? getPId.id
                    : getPId.parentId)
            : undefined; // or any default value you'd like to use
        
        return {
            ...items,
            soh,
            repDisc,
            actCostExact,
            lastCostExact,
            nettEA,
            dcRate,
            dcOnLp,
            lastCost,
            totalPrice,
            qty,
            margin,
            frcMargin,
            actMargin,
            totalCost,
            listPrice,
            l60: l60,
            l55: l55,
            l50: l50,
            l20: l20,
            rowId,
            groupDbExtraId,
            subGroupDbExtraId,
            nonGroupDbExtraId,
            id: uuidv4(),
            parentId: parentId,  // parentId will be undefined or your default value if getPId is not defined
            subItems: subItems,
            assemblyId: assemblyId,
            assemblyGroupName: assemblyGroupName,
            displayAssemblyGroupName: displayAssemblyGroupName
        };
        
        });

        const updatelist = [...newItems];
        setItemListNew(updatelist);
        setAllowCopy(true);
        //   setSelectedItems([]);

    };

    const selectedPasteItem = (selectedItems || []).length === 0 ? [] : selectedItems[0];

    setSelectedItemData(selectedItems[0]);
    const randomNumberInRange = () => {
        //get number between min (inclusive) and max (inclusive)
        return Math.floor(Math.random() * (50 - 1 + 1)) + 1;
    }
    const handlePasteUnits = async () => {
        setIsQuoteSaved(false);
        setQuoteUpdated(true);

        //added by Manoj on 2024-03-04

        const unitDetails = {
            lstStocks: [],
        };

        if (isPriceListCopyed) {
            let newItem = [];
            if (copiedPriceList.length > 0) {
                copiedPriceList.map((item) =>
                    newItem.push({
                        stStockCode: item.stStockCode,
                        qty: 1,

                    })
                )

                unitDetails.lstStocks = newItem;
            }
            else {
                toast.error("No items to paste", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

        }
        else if (unitCopy) {
            unitDetails.lstStocks = copiedUnits;
        }
        setAllowCopy(true);
        const UserId = user?.homeAccountId;
        let systemSettings = null;
        // const tblUnit = {
        //     lstStocks: addItems,
        // };

        //commented by Manoj on 2024-03-04
        // const unitDetails = {
        //     lstStocks: isPriceListCopyed ? copiedPriceList : copiedUnits,
        // };

        // setShowLoaderBtn(true);
        try {
            setIsLoading(true);
            // setImportShowProgress(true);
            var url = `Stocks/getstockdetailsusingunits?CustomerId=${customerId}&UserId=${UserId}`;
            //var url = "Stocks/getstockdetails";
            const responses = await postResponse(url, unitDetails);
            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);

            const newItems = responses.flat().map((response) => {
                let dcOnLp =
                    response.listPrice && response.nettEA
                        ? calculateItemDcOnLp(response.nettEA, response.listPrice)
                        : // parseFloat(
                        //       parseFloat(((response.listPrice - response.nettEA) / response.listPrice) * 100).toFixed(1)
                        //   )
                        0;
                let rowId = uuidv4();
                let totalPrice = response.repDisc
                    ? parseFloat(parseFloat(response.repDisc).toFixed(2))
                    : parseFloat(parseFloat(response.qty * response.nettEA).toFixed(2));
                let margin =
                    response?.nettEA === 0.0
                        ? 0
                        : parseFloat((((response.nettEA - response.stEecost) / response.nettEA) * 100).toFixed(1));
                let actMargin =
                    response?.nettEA === 0.0
                        ? 0
                        : parseFloat((((response.nettEA - response.actCostExact) / response.nettEA) * 100).toFixed(1));
                let frcMargin =
                    response?.nettEA === 0.0
                        ? 0
                        : parseFloat((((response.nettEA - response.stFutureCost) / response.nettEA) * 100).toFixed(1));
                let totalCost = response.qty * response.stEecostRound;
                let groupDbExtraId = "";
                let subGroupDbExtraId = "";
                let nonGroupDbExtraId = "";
                return {
                    ...response,
                    dcOnLp,
                    totalPrice,
                    margin,
                    frcMargin,
                    actMargin,
                    totalCost,
                    rowId,
                    groupDbExtraId,
                    subGroupDbExtraId,
                    nonGroupDbExtraId,
                    rowType: response.type,
                    groupName: "",
                    groupId: "",
                    subGroupName: "",
                    subGroupId: "",
                    displayGroupName: "",
                    displaySubGroupName: "",
                };
            });
            let itemListNew = [...newItems];
            const hasItem = itemListNew.some((item) => item.stStockCode !== "" && item.stStockCode !== null); if (itemListNew.length > 0 && itemListNew[itemListNew.length - 1].rowType === "") {
                itemListNew.pop(); // Remove the last item when last item is empty
            }
            if (!hasItem) {
                toast.error("You have no selected item(s) to paste.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;

            }
            else {
                const updatelist = [...itemList];
                let pasteIndex = -1;

                if (updatelist.length == 1) {

                    const hasSubGroupsWithoutGroups = itemListNew.some(item => item.rowType === 'SG') && itemListNew.every(item => item.rowType !== 'G');
                    const isNonGroupItemCopied = itemListNew.find((item) => item.rowType !== 'G' && item.rowType !== 'SG');
                    const isGroupItemCopied = itemListNew.find((item) => item.rowType === 'G');
                    const isSubGroupItemCopied = itemListNew.find((item) => item.rowType === 'SG');

                    //empty grid  paste sub groups
                    if (selectedItems[0] === undefined && selectedPasteItem === undefined && hasSubGroupsWithoutGroups) {

                        //     toast.error("Cannot Paste without group", {
                        //         position: "top-center",
                        //         transition: Slide,
                        //     });              
                        //    return;
                        let grpid = "";
                        let pid = "";
                        let gid = "";
                        let gdname = "";
                        let displayName = ";"
                        let rowtype = "";
                        let gCount = groupCount;
                        const list = [];
                        itemListNew.forEach((item) => {
                            grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                            gid = item.rowType === 'SG' ? uuidv4() : gid;
                            pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                            gdname = item.rowType === 'SG' ? item.subGroupName : "";
                            displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                            rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                            const group = createGroupRow(uuidv4(), groupCount);
                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupLevelName: gdname,
                                groupId: gid,
                                displayGroupName: displayName,
                                displaySubGroupName: "",
                                subGroupName: "",
                                subGroupId: "",
                                id: uuidv4(),
                                parentId: pid,
                                rowType: rowtype,
                                //     subItems: null,
                                columns: null
                            });
                        });
                        let updatedList = [];

                        updatedList = [...list, ...updatelist];


                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //  setSelectedItems([]);

                        //commented by sachini - add multiple subgroups this is incorrect
                        // let combinedItems = [];

                        // const group = createGroupRow(uuidv4(), groupCount);
                        // const subGroup = createSubGroupRow(group.groupId, groupCount, uuidv4(), subGroupCount,group.id);
                        // const subGrpHeader = itemListNew.filter((f)=> f.rowType === "SG").map((item) => {
                        //     return {
                        //         ...item,
                        //         groupId:group.groupId,
                        //         groupName :group.groupName,
                        //         displayGroupName:item.subGroupName,
                        //         subGroupId:""  ,
                        //         subGroupName:item.subGroupName ,
                        //         displaySubGroupName:"",
                        //         groupLevelName: item.subGroupName,
                        //         id: uuidv4(),
                        //         rowType:"G",
                        //         parentId: null,
                        //         subItems : null,
                        //         columns:null
                        //     }
                        // });
                        // const subGrpItems = itemListNew.filter((f)=> f.rowType != "SG" && f.rowType !== "G").map((item) => {
                        //     return {
                        //         ...item,
                        //         groupId:group.groupId,
                        //         groupName : group.groupName,
                        //         subGroupId:""  ,
                        //         subGroupName:item.subGroupName ,
                        //         displaySubGroupName:"",
                        //         id: uuidv4(),
                        //         parentId: subGrpHeader.id,
                        //         columns:null,
                        //         subItems : null,
                        //         // subGroupId: subGroup.groupId,
                        //         // subGroupName: subGroup.subGroupName,
                        //     }
                        // });

                        // combinedItems = [...subGrpHeader,...subGrpItems];
                        // let updatedList =[];
                        //   updatedList = [...combinedItems, ...updatelist];               

                        // const updatedList1 = itemPositioning(true,updatedList);
                        //  setItemList(updatedList1);
                        //  updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        //  setSelectedItems([]);
                    }

                    //paste only items in empty grid       
                    else if (isNonGroupItemCopied && !isGroupItemCopied && !isSubGroupItemCopied) {
                        // const updatedList = [...itemListNew];
                        let grpid = "";

                        const itemsWithNonEmptyStStockCode = itemListNew
                            .filter(item => item.stStockCode !== '')
                            .map(item => {
                                grpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : grpid;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    columns: null,
                                    // subItems : null,
                                };
                            });


                        let updatedList = [...itemsWithNonEmptyStStockCode]
                        console.log("before itemPositioning paste", updatedList);
                        updatedList = itemPositioning(true, updatedList);
                        console.log("itemPositioning paste", updatedList);
                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);
                        //  setSelectedItems([]);
                    }
                    else {
                        //empty grid select first row and paste
                        if (hasSubGroupsWithoutGroups) {
                            //empty grid selected row cannot paste sub groups
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = ";"
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //        subItems: null,
                                    columns: null
                                });
                            });
                            let updatedList = [];

                            updatedList = [...list];


                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            //     toast.error("Cannot Paste without group", {
                            //         position: "top-center",
                            //         transition: Slide,
                            //     });                          
                            // return;

                        } else {
                            //empty grid selected row  paste  groups , no group items
                            const itemListNew1 = itemListNew
                                .map((item) => ({
                                    ...item,
                                    columns: null,
                                    //   subItems : null,
                                }));
                            let updatedList = [...itemListNew1];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }
                }

                if (selectedItems[0] === undefined && updatelist.length > 1) {
                    ///check last item in a group or subgroup or nongroup
                    ///related to cannot paste multiple db extra 
                    const getLastItem = itemListNew.filter((item) => item.position == selectedIndex); //itemList[itemList.length - 2];          

                    //////end of db extra pasting validation
                    pasteIndex = updatelist.length - 1;
                    const copiedItemListFirstIndex = selectedIndex;
                    const copiedItemsListFirstItem = itemListNew.filter((item) => item.position == selectedIndex + 1);

                    const hasDXInCopiedDataList = itemListNew.some((item) => item.rowType === 'DX');
                    const listOfSubGroupItems = itemList.filter(item => item.subGroupId === copiedItemsListFirstItem[0]?.subGroupId && copiedItemsListFirstItem[0]?.subGroupId !== '');
                    const listOfGroupItems = itemList.filter(item => item.groupId === copiedItemsListFirstItem[0]?.groupId && item.subGroupId === '');

                    const lastItem = itemList[itemList.length - 1];
                    const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                    const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                    const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                    const subGroupNameContains = 'Non Sub Group';
                    const isLastItemNonSubGroup = lastItem && lastItem.subGroupName.includes(subGroupNameContains);


                    //////newly added by sachini 15/05/2024 because we has already selected index when coping items, therefore we no need to paste in last. Need to paste above of the selected index
                    const copiedItemListHasNonGroup = itemListNew.find((item) => item.groupName === 'Non Grouped Items');
                    const copiedItemListHasGroup = itemListNew.find((item) => item.rowType === 'G');
                    const copiedItemListHasSubGroup = itemListNew.find((item) => item.rowType === 'SG');
                    //Check if click Ungroup
                    if (isUngroup) {
                        //check last empty row nongroup or group (because we can click ungroup twice)
                        const isNonGroupedItem = lastItem && lastItem.groupName === 'Non Grouped Items';
                        //non group items copy
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                let grpid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.groupName === 'Non Grouped Items' ? uuidv4() : grpId;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = "";
                                const sgname = "";

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: lastItem.parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                        //GroupOnly
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                        //subGroupOnly
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                itemListNew.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                        //NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";
                                const parentId = lastItem.parentId;
                                const list = [];

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                        //NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {


                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    let pid = lastItem.parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(itemList.length - 1)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                    setIsUngroup(false);
                                }
                            }
                        }
                        //NonGroup + Group +SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'G' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            groupId: gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            subGroupId: sid,
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });


                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(itemList.length - 1)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                    setIsUngroup(false);

                                }

                            }
                            else {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                        //Group + SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isNonGroupedItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;

                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'G' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            groupId: gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            subGroupId: sid,
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });


                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,
                                        ...updatelist.slice(itemList.length - 1)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                    setIsUngroup(false);

                                }

                            }
                            else {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                        //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            if (isNonGroupedItem) {
                                let nongrpid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsUngroup(false);
                            }
                            else {
                                let gid = lastItem.groupId;
                                let gname = lastItem.groupName;
                                let pid = lastItem.parentId;
                                const list = [];
                                itemListNew.forEach((item) => {


                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: pid
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                    }
                    if (isGroup) {
                        const isInsideSubGroup = lastItem && lastItem.subGroupId !== '';
                        //non group items copy
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //items add to subgroup
                            if (isInsideSubGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = lastItem.subGroupId;
                                const sgname = lastItem.subGroupName;
                                const pid = lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsGroup(false);
                            }
                            //items add to group
                            else {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsGroup(false);
                            }

                        }
                        //GroupOnly
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsGroup(false);
                            }

                        }
                        //subGroupOnly
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {

                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    let pid = lastItem.parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,
                                        ...updatelist.slice(itemList.length - 1)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                    setIsGroup(false);

                                }
                            }

                        }
                        //NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";
                                const parentId = lastItem.parentId;
                                const list = [];

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsGroup(false);
                            }

                        }
                        //NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {


                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    let pid = lastItem.parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(itemList.length - 1)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                    setIsGroup(false);
                                }
                            }

                        }
                        //NonGroup + Group +SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }

                        }
                        //Group + SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (isInsideSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }

                        }
                        //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            if (isInsideSubGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = lastItem.subGroupId;
                                const sgname = lastItem.subGroupName;
                                const pid = lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsGroup(false);
                            }
                            else {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsGroup(false);
                            }

                        }

                    }

                    else {
                        //if arrow icon in last empty row (selectedIndex ===- -1)
                        //First check selectedItem is a last empty row
                        if (selectedIndex === -1) {
                            //Copied NonGroup Only
                            if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    const gid = lastItem.groupId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: null,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    const sid = lastItem.subGroupId;
                                    const sgname = lastItem.subGroupName;
                                    const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: pid,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: pid,
                                            position: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            //Copied Group Only
                            if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;
                                    let pid = "";
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowType = "";
                                    let glname = "";

                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                        sgid = item.rowType === 'G' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                        rowType = item.rowType === 'G' ? "SG" : item.rowType;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            displaySubGroupName: displaysgname,
                                            subGroupName: subGroup.subGroupName,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            rowType: rowType,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    const updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //    setSelectedItems([]);
                                }
                            }
                            //Copied SubGroup Only
                            if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let gCount = groupCount;
                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupLevelName: gdname,
                                            groupId: gid,
                                            displayGroupName: displayName,
                                            displaySubGroupName: "",
                                            subGroupName: "",
                                            subGroupId: "",
                                            id: uuidv4(),
                                            parentId: pid,
                                            rowType: rowtype,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            //Copied NonGroup + Group
                            if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let nongrpid = "";
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                        const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            groupLevelname: groupLevelname,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);

                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let glname = "";
                                    const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;

                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];


                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: lastItem.groupId,
                                                groupName: lastItem.groupName,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                            }
                            //Copied NonGroup + SubGroup
                            if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {

                                    let nongrpid = "";

                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let gCount = groupCount;
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupLevelName: gdname,
                                            groupId: gid,
                                            displayGroupName: displayName,
                                            displaySubGroupName: "",
                                            subGroupName: "",
                                            subGroupId: "",
                                            id: uuidv4(),
                                            parentId: pid,
                                            rowType: rowtype,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    let grpid = "";
                                    // let pid = "";    
                                    let sid = "";
                                    //  let sgname="";
                                    // let displaysgname="";
                                    let rowtype = "";
                                    let nongrpid = "";

                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];

                                    const gid = lastItem.groupId;
                                    const gname = lastItem.groupName;
                                    let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: lastItem.groupId,
                                                groupName: lastItem.groupName,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                            }
                            //Copied NonGroup+Group+SubGroup
                            if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let nongrpid = "";
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                        const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            groupLevelname: groupLevelname,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let nongrpid = "";
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                        const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            groupLevelname: groupLevelname,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    let grpid = "";
                                    let pid = "";
                                    let nongrpid = "";
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                        const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            groupLevelname: groupLevelname,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...NonGroupItems,
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                            }
                            //Copied Group+SubGroup
                            if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {

                                    let grpid = "";
                                    let pid = "";
                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                        const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            groupLevelName: groupLevelname,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });



                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);


                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;

                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                            }
                            //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                            if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                                //Last Item in NonGroup
                                if (lastItemInNonGroup) {
                                    let nongrpid = "";
                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null
                                        });
                                    });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);

                                }
                                //Last Item in SubGroup
                                if (lastItemInSubGroup) {
                                    const GrpId = lastItem.groupId;
                                    const GrpName = lastItem.groupName;
                                    const SubGrpId = lastItem.subGroupId;
                                    const SubGrpName = lastItem.subGroupName;
                                    const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                    const list = [];
                                    itemListNew.forEach((item) => {

                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: GrpId,
                                            groupName: GrpName,
                                            displayGroupName: "",
                                            subGroupId: SubGrpId,
                                            subGroupName: SubGrpName,
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentIdforNon
                                        });
                                    });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Last Item in Group
                                if (lastItemInGroup) {
                                    const GrpId = lastItem.groupId;
                                    const GrpName = lastItem.groupName;
                                    const SubGrpId = lastItem.subGroupId;
                                    const SubGrpName = lastItem.subGroupName;
                                    const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                    const list = [];
                                    itemListNew.forEach((item) => {

                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: GrpId,
                                            groupName: GrpName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentIdforNon
                                        });
                                    });

                                    let updatedList = [

                                        ...updatelist.slice(0, itemList.length - 1),
                                        ...list,


                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);

                                }
                            }
                        }
                        else {
                            //non group items copy and paste in last index
                            //  if (itemListNew.every(item => item.groupName === 'Non Grouped Items')) {
                            if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                                // if (isLastItemNonGroup) {
                                let nonGrpId = "";
                                const list = itemListNew.map((item) => {
                                    nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nonGrpId,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        //     subItems: null,
                                        columns: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),
                                ];

                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setSelectedIndex(copiedItemListFirstIndex);



                            }
                            //GroupOnly
                            //if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId === '' && item.groupName !== 'Non Grouped Items')){ 
                            if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //  subItems: null,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });

                                const updatedList1 = [
                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),
                                ];
                                const updatedList = itemPositioning(true, updatedList1);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);

                            }
                            //subGroupOnly
                            // if(itemListNew.every((item) => item.rowType !== 'G' && item.rowType === 'SG' && item.groupName !== 'Non Grouped Items')){
                            if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                //arrow showing last empty row   

                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of itemListNew) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }

                                if (uniqueGroupIdsCount > 1) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let prid = "";
                                    let displayName = "";
                                    let displayGName = "";
                                    const lastOne = itemList[itemList.length - 2];
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        prid = item.rowType === 'SG' ? copiedItemsListFirstItem[0]?.parentId : uuidv4();
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        displayGName = item.rowType === 'G' ? item.displaySubGroupName : "";
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: copiedItemsListFirstItem[0]?.groupId,
                                            displayGroupName: "",
                                            displaySubGroupName: displayName,
                                            id: uuidv4(),
                                            subGroupId: grpid,
                                            parentId: prid,
                                            //    subItems: null,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, copiedItemListFirstIndex),
                                        ...list,
                                        ...updatelist.slice(copiedItemListFirstIndex),];
                                    //  updatedList = [...updatelist.slice(0,itemList.length-1),...list];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);        
                                }

                            }
                            //NonGroup + Group
                            //if(itemListNew.every((item) => item.groupId !== '' && item.subGroupId === '' && item.groupName === 'Non Grouped Items')){
                            if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {

                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);


                            }
                            //NonGroup + SubGroup
                            //  if(itemListNew.every((item) => item.rowType !== 'G' || item.rowType === 'SG' || item.groupName === 'Non Grouped Items')){
                            if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {

                                if (copiedItemsListFirstItem[0]?.rowType === 'SG') {

                                    let Subgrpid = "";
                                    let pid = "";
                                    const GrpId = copiedItemsListFirstItem[0]?.groupId;
                                    const GrpName = copiedItemsListFirstItem[0]?.groupName;
                                    const parentIdforNon = copiedItemsListFirstItem[0]?.parentId;

                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    const groupIdSet = new Set();
                                    let uniqueGroupIdsCount = 0;

                                    for (const item of copiedlistWithoutNonGroup) {
                                        if (item.groupId && !groupIdSet.has(item.groupId)) {
                                            groupIdSet.add(item.groupId);
                                            uniqueGroupIdsCount++;
                                        }
                                    }

                                    if (uniqueGroupIdsCount > 1) {
                                        //selected multiple sub group in different groups
                                        toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                            position: "top-center",
                                            transition: Slide,
                                        });
                                        return;
                                    }
                                    else {
                                        const list = [];
                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            pid = item.rowType === 'SG' ? copiedItemsListFirstItem[0]?.parentId : pid;
                                            const displeSubGroupName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            Subgrpid = item.rowType === 'SG' ? uuidv4() : Subgrpid;

                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: item.groupId,
                                                displaySubGroupName: displeSubGroupName,
                                                subGroupId: Subgrpid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                columns: null
                                            });
                                        });

                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => ({
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: GrpId,
                                                groupName: GrpName,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: parentIdforNon

                                            }));

                                        let updatedList = [

                                            ...updatelist.slice(0, copiedItemListFirstIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(copiedItemListFirstIndex),

                                        ];
                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }


                                }
                                else if (copiedItemsListFirstItem[0]?.groupName === 'Non Grouped Items') {
                                    let grpid = "";
                                    let pid = "";
                                    let nonGrpId = "";

                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        pid = item.rowType === 'SG' ? null : uuidv4();
                                        const displeGroupName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const groupLevelname = item.rowType === 'SG' ? item.groupLevelName : "";
                                        const rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            displayGroupName: displeGroupName,
                                            //  groupLevelname:groupLevelname,
                                            displaySubGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            id: uuidv4(),
                                            rowType: rowtype,
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nonGrpId,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            };
                                        });

                                    let updatedList = [

                                        ...updatelist.slice(0, copiedItemListFirstIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(copiedItemListFirstIndex),

                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }


                            }
                            //NonGroup + Group +SubGroup
                            //if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId !== '' && item.groupName === 'Non Grouped Items')){
                            if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {

                                let grpid = "";
                                let pid = "";
                                let nonGrpId = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nonGrpId,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        };
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                            //Group + SubGroup
                            // if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId !== '' && item.groupName !== 'Non Grouped Items')){
                            if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {

                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //  subItems: null,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });

                                    const updatedList1 = [
                                        ...updatelist.slice(0, copiedItemListFirstIndex),
                                        ...list,
                                        ...updatelist.slice(copiedItemListFirstIndex),
                                    ];
                                    const updatedList = itemPositioning(true, updatedList1);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);

                                }
                            }
                            //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                            if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {

                                const GrpId = copiedItemsListFirstItem[0]?.groupId;
                                const GrpName = copiedItemsListFirstItem[0]?.groupName;
                                const SubGrpId = copiedItemsListFirstItem[0]?.subGroupId;
                                const SubGrpName = copiedItemsListFirstItem[0]?.subGroupName;
                                const parentIdforNon = copiedItemsListFirstItem[0]?.parentId;


                                // console.log("this is listOfSubGroupItems :",listOfSubGroupItems);
                                // console.log("this is listOfGroupItems :",listOfGroupItems);

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: GrpId,
                                        groupName: GrpName,
                                        displayGroupName: "",
                                        subGroupId: SubGrpId,
                                        subGroupName: SubGrpName,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: parentIdforNon,
                                        //     subItems: null,
                                        columns: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),
                                ];

                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                                setSelectedIndex(copiedItemListFirstIndex);


                            }
                        }


                    }
                }

                if (selectedItems[0] !== undefined && updatelist.length > 1) {

                    pasteIndex = updatelist.findIndex((item) => item.rowId === selectedPasteItem.rowId);
                    if (pasteIndex === -1) {
                        pasteIndex = updatelist.length - 1
                    }


                    //DB Extra Validation
                    const copiedItemListFirstIndex = selectedIndex;
                    const copiedItemsListFirstItem = itemListNew.filter((item) => item.position == selectedIndex + 1);

                    const hasDXInCopiedDataList = itemListNew.some((item) => item.rowType === 'DX');
                    const listOfNonGroupItems = itemList.filter(item => item.groupName === 'Non Grouped Items' && copiedItemsListFirstItem[0]?.subGroupId === '');
                    const listOfSubGroupItems = itemList.filter(item => item.subGroupId === copiedItemsListFirstItem[0]?.subGroupId && copiedItemsListFirstItem[0]?.subGroupId !== '');
                    const listOfGroupItems = itemList.filter(item => item.groupId === copiedItemsListFirstItem[0]?.groupId && item.subGroupId === '');


                    //newly added
                    const isSelectLastEmptyRow = itemList.length > 0 && itemList[itemList.length - 1].rowType === '';
                    //.stStockCode === '' && itemList[itemList.length - 1].rowType !== 'G' && itemList[itemList.length - 1].rowType !== 'SG' ;
                    const copiedItemListHasNonGroup = itemListNew.find((item) => item.groupName === 'Non Grouped Items');
                    const copiedItemListHasGroup = itemListNew.find((item) => item.rowType === 'G');
                    const copiedItemListHasSubGroup = itemListNew.find((item) => item.rowType === 'SG');

                    const lastItem = itemList[itemList.length - 2];
                    const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                    const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                    const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                    const subGroupNameContains = 'Non Sub Group';
                    const isLastItemNonSubGroup = lastItem && lastItem.subGroupName.includes(subGroupNameContains);



                    //First check selectedItem is a last empty row
                    if (selectedItems[0].rowType === '') {
                        //Copied NonGroup Only
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                const gid = lastItem.groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = lastItem.subGroupId;
                                const sgname = lastItem.subGroupName;
                                const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                                const list = [];

                                itemListNew.forEach((item) => {
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        //Copied Group Only
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;
                                let pid = "";
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowType = "";
                                let glname = "";

                                const list = [];
                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sgid = item.rowType === 'G' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                    rowType = item.rowType === 'G' ? "SG" : item.rowType;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        displaySubGroupName: displaysgname,
                                        subGroupName: subGroup.subGroupName,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowType,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1),
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //    setSelectedItems([]);
                            }
                        }
                        //Copied SubGroup Only
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                itemListNew.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let glname = "";
                                const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];


                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {

                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                // let pid = "";    
                                let sid = "";
                                //  let sgname="";
                                // let displaysgname="";
                                let rowtype = "";
                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];

                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup+Group+SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied Group+SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {

                                let grpid = "";
                                let pid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelName: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });



                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);


                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;

                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                        //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let nongrpid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);

                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                const GrpId = lastItem.groupId;
                                const GrpName = lastItem.groupName;
                                const SubGrpId = lastItem.subGroupId;
                                const SubGrpName = lastItem.subGroupName;
                                const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = [];
                                itemListNew.forEach((item) => {

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: GrpId,
                                        groupName: GrpName,
                                        displayGroupName: "",
                                        subGroupId: SubGrpId,
                                        subGroupName: SubGrpName,
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentIdforNon
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const GrpId = lastItem.groupId;
                                const GrpName = lastItem.groupName;
                                const SubGrpId = lastItem.subGroupId;
                                const SubGrpName = lastItem.subGroupName;
                                const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = [];
                                itemListNew.forEach((item) => {

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: GrpId,
                                        groupName: GrpName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentIdforNon
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                        }
                    }
                    //if select first value
                    if (selectedItems[0].rowType !== '' && selectedItems[0].position === 1) {
                        const selectNonGroupItem = selectedItems[0].groupName === 'Non Grouped Items';
                        const selectGroupHeader = selectedItems[0].rowType === 'G';

                        //Copied NonGroup Only
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {

                            let nongrpid = "";
                            const list = itemListNew.map((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);


                        }
                        //Copied Group Only
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            let grpid = "";
                            let pid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    columns: null,
                                    listPrice: item.listPrice,
                                    totalPrice: item.totalPrice
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);

                        }
                        //Copied SubGroup Only
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {

                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex)
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Copied NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                        //Copied NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let gCount = groupCount;
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });
                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });


                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                        //Copied NonGroup+Group+SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        // groupId:gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        // subGroupId:"",  
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        //Copied Group+SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;

                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        // groupId:gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        // subGroupId:"",  
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                        }
                        //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            let nongrpid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                    }
                    //selectedItem is not a last empty row
                    else {
                        const selectNonGroupItem = selectedItems[0].groupName === 'Non Grouped Items';
                        const selectGroupItem = selectedItems[0].groupId !== '' && selectedItems[0].subGroupId === '' && selectedItems[0].groupName !== 'Non Grouped Items' && selectedItems[0].rowType !== 'G';
                        const selectSubGroupItem = selectedItems[0].subGroupId !== '' && selectedItems[0].rowType !== 'SG';
                        const selectGroupHeader = selectedItems[0].rowType === 'G';
                        const selectSubGroupHeader = selectedItems[0].rowType === 'SG';

                        const beforeItem = itemList.filter((item) => item.position == pasteIndex);
                        const beforeItemNonGroupItem = beforeItem[0].groupName === 'Non Grouped Items';
                        const beforeItemGroupItem = beforeItem[0].groupId !== '' && beforeItem[0].subGroupId === '' && beforeItem[0].groupName !== 'Non Grouped Items' && beforeItem[0].rowType !== 'G';
                        const beforeItemSubGroupItem = beforeItem[0].subGroupId !== '' && beforeItem[0].rowType !== 'SG';
                        const beforeItemGroupHeader = beforeItem[0].rowType === 'G';
                        const beforeItemSubGroupHeader = beforeItem[0].rowType === 'SG';

                        //Copied NonGroup Only
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                const gid = selectedItems[0].groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectGroupHeader) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: null,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: uuidv4(),
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: null,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: uuidv4(),
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: null,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = beforeItem[0].subGroupId;
                                    const sgname = beforeItem[0].subGroupName;
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: pid,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectSubGroupHeader) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].id;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].id,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].id;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].id,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) { }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = "";
                                    const sgname = "";
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) { }
                            }
                            if (selectSubGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) { }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = beforeItem[0].subGroupId;
                                    const sgname = beforeItem[0].subGroupName;
                                    const pid = beforeItem[0].id;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].id,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) { }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    const sid = beforeItem[0].subGroupId;
                                    const sgname = beforeItem[0].subGroupName;
                                    const pid = beforeItem[0].parentId;
                                    const list = itemListNew.map((item) => {
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: sid,
                                            subGroupName: sgname,
                                            displaySubGroupName: "",
                                            id: uuidv4(),
                                            parentId: beforeItem[0].parentId,
                                            position: null
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                            }

                        }

                        //Copied Group Only
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectGroupHeader) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),
                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),
                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),
                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),
                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    let grpid = "";
                                    let pid = "";
                                    const list = itemListNew.map((item) => {
                                        grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                        pid = item.rowType === 'G' ? null : uuidv4();
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: grpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null,
                                            listPrice: item.listPrice,
                                            totalPrice: item.totalPrice
                                        };
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),
                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectSubGroupHeader) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {

                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {

                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) { }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    let pid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let rowtype = "";
                                    let glname = "";

                                    const list = [];

                                    itemListNew.forEach((item) => {
                                        const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                        sid = item.rowType === 'G' ? uuidv4() : sid;
                                        sgname = item.rowType === 'G' ? item.groupName : sgname;
                                        displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                        rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                        pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                        glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            groupLevelName: glname,
                                            subGroupId: sid,
                                            subGroupName: subGroup.subGroupName,
                                            displaySubGroupName: displaysgname,
                                            rowType: rowtype,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //    subItems: null,
                                            columns: null
                                        });
                                    });
                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex),

                                    ];
                                    updatedList = itemPositioning(true, updatedList);
                                    setItemList(updatedList);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) { }
                            }
                            if (selectSubGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) { }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) { }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                            }

                        }

                        //Copied SubGroup Only
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex)
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectGroupHeader) {
                                //However it pasting like as a group
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex)
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectSubGroupHeader) {

                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    //Before Item of selected index has NonGroupItem
                                    if (beforeItemNonGroupItem) { }
                                    //Before Item of selected index has GroupHeader
                                    if (beforeItemGroupHeader) {

                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        // setSelectedItems([]);
                                    }
                                    //Before Item of selected index has SubGroupHeader
                                    if (beforeItemSubGroupHeader) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Group Item
                                    if (beforeItemGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Sub Group Item
                                    if (beforeItemSubGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        // setSelectedItems([]);
                                    }
                                }

                            }
                            if (selectGroupItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    //Before Item of selected index has NonGroupItem
                                    if (beforeItemNonGroupItem) { }
                                    //Before Item of selected index has GroupHeader
                                    if (beforeItemGroupHeader) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);

                                    }
                                    //Before Item of selected index has SubGroupHeader
                                    if (beforeItemSubGroupHeader) { }
                                    //Before Item of selected index has Group Item
                                    if (beforeItemGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        itemListNew.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });

                                        let updatedList = [
                                            ...updatelist.slice(0, pasteIndex),
                                            ...list,
                                            ...updatelist.slice(pasteIndex)
                                        ];

                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //   setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Sub Group Item
                                    if (beforeItemSubGroupItem) { }

                                }

                            }
                            if (selectSubGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) { }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) { }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                            }
                        }

                        //Copied NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            if (selectGroupHeader) {
                                //However copied nongroups paste in as non group and other copied groups paste as group
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectSubGroupHeader) {
                                //however copied nongroup items paste into group and copied groups paste as subgroups

                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";
                                const parentId = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].rowType === 'SG' ? beforeItem[0].id : beforeItem[0].parentId;
                                const list = [];

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            if (selectGroupItem) {

                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";
                                const parentId = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].rowType === 'SG' ? beforeItem[0].id : beforeItem[0].parentId;
                                const list = [];

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: beforeItem[0].groupId,
                                            groupName: beforeItem[0].groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectSubGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) { }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) { }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                            }

                        }
                        //Copied NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectGroupHeader) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectSubGroupHeader) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    //Before Item of selected index has NonGroupItem
                                    if (beforeItemNonGroupItem) { }
                                    //Before Item of selected index has GroupHeader
                                    if (beforeItemGroupHeader) {

                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //   setSelectedItems([]);
                                    }
                                    //Before Item of selected index has SubGroupHeader
                                    if (beforeItemSubGroupHeader) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //   setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Group Item
                                    if (beforeItemGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Sub Group Item
                                    if (beforeItemSubGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }
                                }
                            }
                            if (selectGroupItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    //Before Item of selected index has NonGroupItem
                                    if (beforeItemNonGroupItem) { }
                                    //Before Item of selected index has GroupHeader
                                    if (beforeItemGroupHeader) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //   setSelectedItems([]);

                                    }
                                    //Before Item of selected index has SubGroupHeader
                                    if (beforeItemSubGroupHeader) { }
                                    //Before Item of selected index has Group Item
                                    if (beforeItemGroupItem) {
                                        const gid = beforeItem[0].groupId;
                                        const gname = beforeItem[0].groupName;
                                        let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                        let sgid = "";
                                        let sgname = "";
                                        let displaysgname = "";


                                        const list = [];
                                        const copiedlistWithoutNonGroup = itemListNew
                                            .filter((item) => item.groupName !== 'Non Grouped Items');

                                        copiedlistWithoutNonGroup.forEach((item) => {
                                            sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                            sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                            displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                            pid = item.rowType === 'SG' ? pid : uuidv4();
                                            list.push({
                                                ...item,
                                                rowId: uuidv4(),
                                                //   groupLevelName: gdname,
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                // groupLevelName:""
                                                displaySubGroupName: displaysgname,
                                                subGroupName: sgname,
                                                subGroupId: sgid,
                                                id: uuidv4(),
                                                parentId: pid,
                                                //     subItems: null,
                                                columns: null
                                            });
                                        });
                                        const NonGroupItems = itemListNew
                                            .filter((item) => item.groupName === 'Non Grouped Items')
                                            .map((item) => {
                                                //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                                return {
                                                    ...item,
                                                    rowId: uuidv4(),
                                                    groupId: gid,
                                                    groupName: gname,
                                                    displayGroupName: "",
                                                    subGroupId: "",
                                                    subGroupName: "",
                                                    displaySubGroupName: "",
                                                    columns: null,
                                                    //    subItems:null,
                                                    id: uuidv4(),
                                                    parentId: null

                                                }
                                            });
                                        let updatedList = [

                                            ...updatelist.slice(0, pasteIndex),
                                            ...NonGroupItems,
                                            ...list,
                                            ...updatelist.slice(pasteIndex)

                                        ];


                                        const updatedList1 = itemPositioning(true, updatedList);
                                        setItemList(updatedList1);
                                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                        setComputeTotals(true);
                                        //  setSelectedItems([]);
                                    }
                                    //Before Item of selected index has Sub Group Item
                                    if (beforeItemSubGroupItem) { }

                                }

                            }
                            if (selectSubGroupItem) {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) { }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) { }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                            }
                        }
                        //Copied NonGroup+Group+SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            // groupId:gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            // subGroupId:"",  
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });


                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);

                                }


                            }
                            if (selectGroupHeader) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            // groupId:gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            // subGroupId:"",  
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: nongrpid,
                                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });


                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectSubGroupHeader) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;

                            }
                            if (selectGroupItem) {

                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;


                            }
                            if (selectSubGroupItem) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }

                        }

                        //Copied Group+SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            if (selectNonGroupItem) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;

                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            // groupId:gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            // subGroupId:"",  
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });


                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);

                                }



                            }
                            if (selectGroupHeader) {
                                const filterSubGroupsIncopiedlist = itemListNew
                                    .filter((item) => item.subGroupId !== '');
                                const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of filterSubGroupsIncopiedlist) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }
                                if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    let grpid = "";
                                    let pid = "";
                                    let gid = "";
                                    let gdname = "";
                                    let displayName = "";
                                    let rowtype = "";
                                    let nongrpid = "";
                                    let sid = "";
                                    let sgname = "";
                                    let displaysgname = "";
                                    let gCount = groupCount;


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        sid = item.rowType === 'SG' ? uuidv4() : sid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        const group = createGroupRow(uuidv4(), groupCount);
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            // groupLevelName: gdname,
                                            // groupId:gid,
                                            // displayGroupName:displayName,
                                            // displaySubGroupName:"",
                                            // subGroupName:"",
                                            // subGroupId:"",  
                                            id: uuidv4(),
                                            // parentId: pid,                           
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });


                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                            if (selectSubGroupHeader) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;

                            }
                            if (selectGroupItem) {

                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;


                            }
                            if (selectSubGroupItem) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }

                        }
                        //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            if (selectNonGroupItem) {
                                let nongrpid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //   setSelectedItems([]);



                            }
                            if (selectGroupHeader) {
                                let nongrpid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            if (selectSubGroupHeader) {
                                let gid = beforeItem[0].groupId;
                                let gname = beforeItem[0].groupName;
                                let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                const list = [];
                                itemListNew.forEach((item) => {


                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: pid
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                            if (selectGroupItem) {

                                let gid = beforeItem[0].groupId;
                                let gname = beforeItem[0].groupName;
                                let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                const list = [];
                                itemListNew.forEach((item) => {


                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: pid
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);


                            }
                            if (selectSubGroupItem) {
                                let gid = beforeItem[0].groupId;
                                let gname = beforeItem[0].groupName;
                                let sid = beforeItem[0].subGroupId;
                                let sgname = beforeItem[0].subGroupName;
                                let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                const list = [];
                                itemListNew.forEach((item) => {


                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: pid
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }

                        }
                    }

                }


            }


            setShowLoaderBtn(false);
            closeDialog();
            setComputeTotals(true);
        } catch (error) {

            console.error("Error in handlePasteUnits:", error);
            toast.error("An error occurred while pasting.", {
                position: "top-center",
                transition: Slide,
            });
        }
        finally {
            setIsLoading(false);
            // setImportShowProgress(false);
            // setUnitCopy(false);
            setAllowCopy(false);
        }
    };

    const handleAddUnits = async () => {
        setIsQuoteSaved(false);
        setIsSelectedUnitAdded(true);
        if (unitSelected && selectedUnitData.length > 0) {
            const unitDetails = {
                lstStocks: selectedUnitData,
            };

            const UserId = user?.homeAccountId;
            let systemSettings = null;

            try {
                setIsLoading(true);
                // setImportShowProgress(true);
                var url = `Stocks/getstockdetailsusingunits?CustomerId=${customerId}&UserId=${UserId}`;
                //var url = "Stocks/getstockdetails";
                const responses = await postResponse(url, unitDetails);

                let itemListNewUnit;
                if (responses !== null && responses !== undefined) {
                    itemListNewUnit = responses.map((item) => ({
                        ...item,
                        itemId: item.itemId,
                        rowType: item.rowType === undefined ? item.type : item.rowType,
                        id: uuidv4(),
                        parentId: null
                    }));

                }

                const hasItem = itemListNewUnit.some((item) => item.stStockCode !== "" && item.stStockCode !== null);
                if (!hasItem) {
                    toast.error("You have no Unit item(s) to add.");
                    return;
                } else {
                    const updatelist = [...itemList];
                    let pasteIndex = -1;
                    //selectedPasteItem = selectedItems[0]
                    const selectedOnLastEmptyItem = selectedItems[0] !== undefined
                        ? selectedItems[0].stStockCode.length === 0 && selectedItems[0].rowId === updatelist.at(-1).rowId ? true : false
                        : false;

                    if (updatelist.length == 1) {

                        const newGrpId = uuidv4();
                        const group = createGroupRow(newGrpId, groupCount);
                        const modifiedGroup = {
                            ...group,
                            description: selectedUnitName,
                            //  subItems : null 
                        };
                        const itemsWithNonEmptyStStockCode = itemListNewUnit
                            .filter((item) => item.stStockCode !== '')
                            .map((item) => ({
                                ...item,
                                rowId: uuidv4(),
                                groupId: newGrpId,
                                groupName: group.groupName,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: modifiedGroup.id,
                                //subItems : null                    
                            }));

                        const updatedList = [modifiedGroup, ...itemsWithNonEmptyStStockCode]

                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList] });
                        setSelectedItems([]);
                        toast.success(`Sucessfully Unit '${selectedUnitName}' added `);

                    }
                    else if (updatelist.length > 1 && selectedItems[0] !== undefined && (selectedItems[0].rowType == "G" || selectedItems[0].groupName.includes(GroupItemTypes.NON_GROUPED_ITEMS) || selectedItems[0].rowType == "")) {
                        const selectedIndexInAGroup = selectedItems[0].subGroupId === '' && selectedItems[0].groupId !== '';
                        const selectedIndexInASubGroup = selectedItems[0].subGroupId !== '';
                        const selectedIndexInANonGroup = selectedItems[0].groupName.includes(GroupItemTypes.NON_GROUPED_ITEMS);


                        if (selectedOnLastEmptyItem) {
                            //select last empty row
                            const newGrpId = uuidv4();
                            const group = createGroupRow(newGrpId, groupCount);
                            const modifiedGroup = {
                                ...group,
                                description: selectedUnitName,
                                // subItems : null 
                            };
                            const itemsWithNonEmptyStStockCode = itemListNewUnit
                                .filter((item) => item.stStockCode !== '')
                                .map((item) => ({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: newGrpId,
                                    groupName: group.groupName,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: modifiedGroup.id,
                                    //subItems : null                    
                                }));

                            const updatedList = [...updatelist.slice(0, updatelist.length - 1), modifiedGroup, ...itemsWithNonEmptyStStockCode]
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList1] });
                            setSelectedItems([]);
                            toast.success(`Sucessfully Unit '${selectedUnitName}' added `);

                        }
                        else if (selectedIndexInAGroup && !selectedIndexInASubGroup && !selectedIndexInANonGroup) {
                            //select group and then add unit- added unit as subgroup
                            const nongroupItems = updatelist.filter(item => item.groupName.includes(GroupItemTypes.NON_GROUPED_ITEMS));

                            //  const selectGroupList = updatelist.filter(item => item.groupId == selectedItems[0].groupId);
                            const selectGroupList = updatelist.filter(item => item.groupId === selectedItems[0].groupId && item.rowType !== '');

                            const restOfItems = updatelist.filter(item => !selectGroupList.includes(item) && !nongroupItems.includes(item));
                            const subGroup = createSubGroupRow(selectedItems[0].groupId, groupCount, uuidv4(), subGroupCount, selectedItems[0].id);
                            const modifiedSubGroup = {
                                ...subGroup,
                                description: selectedUnitName,
                                //    subItems : null 
                            };
                            const itemsWithNonEmptyStStockCode = itemListNewUnit
                                .filter((item) => item.stStockCode !== '')
                                .map((item) => ({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: selectedItems[0].groupId,
                                    groupName: selectedItems[0].groupName,
                                    displayGroupName: "",
                                    subGroupId: subGroup.subGroupId,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: modifiedSubGroup.id,
                                    //subItems : null                    
                                }));
                            if (nongroupItems.length > 0) {
                                const updatedList = [...nongroupItems, ...selectGroupList, modifiedSubGroup, ...itemsWithNonEmptyStStockCode, ...restOfItems];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList1] });
                                setSelectedItems([]);
                            }
                            else {
                                const updatedList = [...selectGroupList, modifiedSubGroup, ...itemsWithNonEmptyStStockCode, ...restOfItems];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList1] });
                                setSelectedItems([]);
                            }

                        }

                        else if (selectedIndexInANonGroup) {
                            //select nongroup and then add unit- added unit as group
                            const nonGroupItems = updatelist.filter(item => item.groupName.includes(GroupItemTypes.NON_GROUPED_ITEMS));
                            const restOfItems = updatelist.filter(item => !nonGroupItems.includes(item));
                            const newGrpId = uuidv4();
                            const group = createGroupRow(newGrpId, groupCount);
                            const modifiedGroup = {
                                ...group,
                                description: selectedUnitName,
                                // subItems : null 
                            };
                            const itemsWithNonEmptyStStockCode = itemListNewUnit
                                .filter((item) => item.stStockCode !== '')
                                .map((item) => ({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: newGrpId,
                                    groupName: group.groupName,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: modifiedGroup.id,
                                    //subItems : null                    
                                }));

                            const updatedList = [...nonGroupItems.slice(0, updatelist.length - 1), modifiedGroup, ...itemsWithNonEmptyStStockCode, ...restOfItems]
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList1] });
                            setSelectedItems([]);
                            toast.success(`Sucessfully Unit '${selectedUnitName}' added `);
                        }

                    }
                    else if (updatelist.length > 1 && selectedItems[0] === undefined) {
                        const newGrpId = uuidv4();
                        const group = createGroupRow(newGrpId, groupCount);
                        const modifiedGroup = {
                            ...group,
                            description: selectedUnitName,
                            // subItems : null 
                        };
                        const itemsWithNonEmptyStStockCode = itemListNewUnit
                            .filter((item) => item.stStockCode !== '')
                            .map((item) => ({
                                ...item,
                                rowId: uuidv4(),
                                groupId: newGrpId,
                                groupName: group.groupName,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: modifiedGroup.id,
                                //subItems : null                    
                            }));

                        const updatedList = [...updatelist.slice(0, updatelist.length - 1), modifiedGroup, ...itemsWithNonEmptyStStockCode]

                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedList] });
                        setSelectedItems([]);
                        toast.success(`Sucessfully Unit '${selectedUnitName}' added `);
                    }


                }
                setComputeTotals(true);
                // toast.success(`Sucessfully Unit '${selectedUnitName}' added `);


            } catch (error) {

                console.error("Error in handlePasteUnits:", error);
                toast.error("An error occurred while adding units into item grid.");
            }
            setUnitSelected(false);
            setSelectedUnitData([])
            setSelectedSubUnitIndex("");
            setSelectedUnitIndex("");
            setIsLoading(false);
            // setImportShowProgress(false);
        }

    };
    const ifRowNotSelectedInCopyPaste = (newItems) => {
        if (groupId) {
            const groupedList = newItems.map((res) => {
                return {
                    ...res,
                    groupId: groupId,
                    groupName: `Group ${groupCount - 1}`,
                    displayGroupName: "",
                    subGroupId: "",
                    subGroupName: "",
                    displaySubGroupName: "",
                };
            });

            if (subGroupId) {
                const subGroupedList = groupedList.map((res) => {
                    return {
                        ...res,
                        subGroupId: subGroupId,
                        subGroupName: `Sub Group ${subGroupCount - 1}`,
                    };
                });

                const combinedList = [...itemList, ...subGroupedList];

                let subGroupLevelDbExtra = isDbExtraAdded(combinedList, groupId, subGroupId, GroupTypes.SUB_GROUP);

                let updatedNettEA = -1;
                let groupDbExtra = -1;

                //check if there is any dbExtra added for the sub group
                if (subGroupLevelDbExtra) {
                    const dbExtraRowIndexSB = getDbExtraRowIndex(
                        combinedList,
                        groupId,
                        subGroupId,
                        GroupTypes.SUB_GROUP
                    );
                    const dbExtraRowSB = combinedList[dbExtraRowIndexSB];
                    let listWithoutDbExtra = getItemsWithoutDBExtra(
                        combinedList,
                        GroupTypes.SUB_GROUP,
                        groupId,
                        subGroupId
                    );
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEASB = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEASB);

                    const subGroupDbExtraRow = {
                        ...dbExtraRowSB,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    combinedList[dbExtraRowIndexSB] = subGroupDbExtraRow;

                    //group db extra calculations
                    const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);
                    const groupItems = getAllItemsInAGroup(combinedList, groupId);
                    const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedGroupDbExtraRow = {
                        ...existingGroupWiseDbExtra[0],
                        nettEA: groupDbExtra,
                        totalPrice: groupDbExtra,
                    };

                    combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                } else {
                    //if there is no dbExtra added for the sub group

                    //check if there is any dbExtra added for the group
                    const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);

                    if (existingGroupWiseDbExtra.length > 0) {
                        const groupItems = getAllItemsInAGroup(combinedList, groupId);
                        const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupWiseDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }
                }

                //to persist the group values(list price, act Cost, nettEA etc) when the qty is changed
                const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                let subGroupItems = getAllExistingSubGroupedItems(combinedList, groupId, subGroupId);

                const hasQtyChangedInSubGroups = subGroupRows.some((item) => item.qty > 1);
                const hasQtyChangedInSGItems = subGroupItems.some((item) => item.qty > 1);

                let rowList = [];
                let qtyUnchangedSubGroups = [];
                let qtyUnchangedSubGroupItems = [];

                if (hasQtyChangedInSubGroups) {
                    qtyUnchangedSubGroups = subGroupRows.filter((item) => item.qty === 1);
                }

                if (hasQtyChangedInSGItems) {
                    qtyUnchangedSubGroupItems = subGroupItems.filter((item) => item.qty === 1);
                }

                if (hasQtyChangedInSubGroups) {
                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                    const subGroupRowIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);
                    const updatedQty = subGroupRow[0].qty;
                    let updatedSubGroupRow = null;

                    let updatedSGTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                    let updatedSGTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                    let updatedSGNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    let updatedSGActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                    let updatedSGstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                    let updatedSGStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                    let updatedSGListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");

                    updatedSubGroupRow = {
                        ...subGroupRow[0],
                        qty: updatedQty,
                        // totalCost: updatedSGTotalCost,
                        totalCost: calculateItemTotalCost(updatedQty, updatedSGstEecost),
                        totalPrice: updatedSGTotalPrice * updatedQty,
                        nettEA: updatedSGNettEA,
                        actCostExact: updatedSGActCostExact,
                        stEecost: updatedSGstEecost,
                        stFutureCost: updatedSGStFutureCost,
                        listPrice: updatedSGListPrice,
                        actCost: parseFloat(parseFloat(updatedSGActCostExact).toFixed(2)),
                        stEecostRound: parseFloat(parseFloat(updatedSGstEecost).toFixed(2)),
                        stFutureCostRound: parseFloat(parseFloat(updatedSGStFutureCost).toFixed(2)),
                        // lcMargin: "",
                    };

                    combinedList[subGroupRowIndex] = updatedSubGroupRow;

                    //group row calculations
                    let groupRow = getGroupRowByGroup(combinedList, groupId)[0];
                    let groupRowIndex = getGroupRowIndex(combinedList, groupId);
                    let subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                    let groupRowItems = getGroupedRowItems(combinedList, groupId);
                    let updatedGroupRow = null;
                    let subGroupsWithoutCurrent = subGroupRows.filter((item) => item.subGroupId !== subGroupId);
                    let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                        if (item.qty > 1) {
                            return {
                                ...item,
                                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                actCost: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(
                                        2
                                    )
                                ),
                                stEecostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                ),
                                stFutureCostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(
                                        2
                                    )
                                ),
                            };
                        }

                        return item;
                    });

                    const concatList = [...updatedSubGroupsWithoutCurrent, ...groupRowItems];

                    let gTotalCost = calculateGroupWiseTotal(concatList, "totalCost");
                    let gTotalPrice = calculateGroupWiseTotal(concatList, "totalPrice");
                    let gNettEA = calculateGroupWiseTotal(concatList, "nettEA");
                    let gActCostExact = calculateGroupWiseTotal(concatList, "actCostExact");
                    let gstEecost = calculateGroupWiseTotal(concatList, "stEecost");
                    let gStFutureCost = calculateGroupWiseTotal(concatList, "stFutureCost");
                    let gListPrice = calculateGroupWiseTotal(concatList, "listPrice");

                    //quantity changed group calculations
                    const hasGroupQtyChanged = groupRow.qty > 1;

                    updatedGroupRow = {
                        ...groupRow,
                        // totalCost: hasGroupQtyChanged
                        //     ? parseFloat(((gTotalCost + updatedSubGroupRow.totalCost) * groupRow.qty).toFixed(2))
                        //     : parseFloat((gTotalCost + updatedSubGroupRow.totalCost).toFixed(2)),
                        totalCost: hasGroupQtyChanged
                            ? parseFloat(
                                (
                                    (calculateItemTotalCost(groupRow.qty, gstEecost) +
                                        updatedSubGroupRow.totalCost) *
                                    groupRow.qty
                                ).toFixed(2)
                            )
                            : parseFloat(
                                (
                                    calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost
                                ).toFixed(2)
                            ),
                        totalPrice: hasGroupQtyChanged
                            ? parseFloat(((gTotalPrice + updatedSubGroupRow.totalPrice) * groupRow.qty).toFixed(2))
                            : parseFloat((gTotalPrice + updatedSubGroupRow.totalPrice).toFixed(2)),
                        nettEA: parseFloat((gNettEA + updatedSubGroupRow.nettEA * updatedQty).toFixed(2)),
                        actCostExact: gActCostExact + updatedSubGroupRow.actCostExact * updatedQty,
                        stEecost: gstEecost + updatedSubGroupRow.stEecost * updatedQty,
                        stFutureCost: gStFutureCost + updatedSubGroupRow.stFutureCost * updatedQty,
                        listPrice: parseFloat((gListPrice + updatedSubGroupRow.listPrice * updatedQty).toFixed(2)),
                        actCost: parseFloat(
                            parseFloat(gActCostExact + updatedSubGroupRow.actCostExact * updatedQty).toFixed(2)
                        ),
                        stEecostRound: parseFloat(
                            parseFloat(gstEecost + +(updatedSubGroupRow.stEecost * updatedQty)).toFixed(2)
                        ),
                        stFutureCostRound: parseFloat(
                            parseFloat(gStFutureCost + +(updatedSubGroupRow.stFutureCost * updatedQty)).toFixed(2)
                        ),
                    };

                    combinedList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(combinedList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(
                            combinedList,
                            nonGroupId,
                            null,
                            GroupTypes.NON_GROUP
                        );

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                combinedList,
                                dbExtraCharge
                            );
                            combinedList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                    const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                    setItemList(updatedItems);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItems });
                } else {
                    let subGroupMargin = -1;
                    let subGroupFrcMargin = -1;
                    let subGroupActMargin = -1;

                    const subGroupListPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "listPrice");
                    const subGroupNettEA = calculateSubGroupWiseTotal(subGroupId, combinedList, "nettEA");
                    const subGroupTotalPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalPrice");
                    const subGroupstEecost = calculateSubGroupWiseTotal(subGroupId, combinedList, "stEecost");
                    const subGroupStFutureCost = calculateSubGroupWiseTotal(
                        subGroupId,
                        combinedList,
                        "stFutureCost"
                    );
                    const subGroupTotalCost = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalCost");
                    const subGroupActCostExact = calculateSubGroupWiseTotal(
                        subGroupId,
                        combinedList,
                        "actCostExact"
                    );
                    // const totalDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                    const totalDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

                    if (subGroupLevelDbExtra) {
                        subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            subGroupNettEA - groupDbExtra,
                            subGroupstEecost
                        );
                        subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            subGroupNettEA - groupDbExtra,
                            subGroupStFutureCost
                        );
                        subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            subGroupNettEA - groupDbExtra,
                            subGroupActCostExact
                        );
                    } else {
                        subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                        subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            subGroupNettEA,
                            subGroupStFutureCost
                        );
                        subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            subGroupNettEA,
                            subGroupActCostExact
                        );
                    }

                    const emptySubGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                    const subGroupIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);

                    const updatedEmptySubGroupRow = {
                        ...emptySubGroupRow[0],
                        listPrice: subGroupListPrice,
                        nettEA: subGroupNettEA,
                        totalPrice: subGroupTotalPrice,
                        dcOnLp: totalDcOnLp,
                        stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                        stFutureCost: subGroupStFutureCost,
                        stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                        qty: emptySubGroupRow[0].qty ? emptySubGroupRow[0].qty : 1,
                        margin: subGroupMargin,
                        actMargin: subGroupActMargin,
                        frcMargin: subGroupFrcMargin,
                        // totalCost: subGroupTotalCost,
                        totalCost: calculateItemTotalCost(emptySubGroupRow[0].qty, subGroupstEecost),
                        stEecost: subGroupstEecost,
                        actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        actCostExact: subGroupActCostExact,
                        //  lcMargin: "",
                    };

                    //updates the sub group row
                    combinedList[subGroupIndex] = updatedEmptySubGroupRow;

                    //ref : for group calculations
                    const updatedSubGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId); // to get the sub groups after the above update
                    const groupRows = getGroupedRowItems(combinedList, groupId);
                    const emptyGroupRow = getGroupRowByGroup(combinedList, groupId);
                    const emptyGroupRowIndex = getGroupRowIndex(combinedList, groupId);

                    hasQtyChangedInSubGroups
                        ? (rowList = [...emptyGroupRow, ...qtyUnchangedSubGroups, ...groupRows])
                        : (rowList = [...updatedSubGroupRows, ...groupRows]);

                    //check for group level db extra
                    const groupLevelDbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);

                    let groupMargin = -1;
                    let groupFrcMargin = -1;
                    let groupActMargin = -1;

                    const groupListPrice = calculateGroupWiseTotal(rowList, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(rowList, "nettEA");
                    const groupTotalPrice = calculateGroupWiseTotal(rowList, "totalPrice");
                    const groupstEecost = calculateGroupWiseTotal(rowList, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(rowList, "stFutureCost");
                    const groupTotalCost = calculateGroupWiseTotal(rowList, "totalCost");
                    const groupActCostExact = calculateGroupWiseTotal(rowList, "actCostExact");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                    if (groupLevelDbExtra) {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupstEecost
                        );
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupActCostExact
                        );
                    } else {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA,
                            groupActCostExact
                        );
                    }

                    const hasGroupQtyChanged = emptyGroupRow[0].qty > 1;

                    const updatedEmptyGroupRow = {
                        ...emptyGroupRow[0],
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        totalPrice: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalPrice : groupTotalPrice,
                        dcOnLp: groupDcOnLp,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        qty: emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalCost : groupTotalCost,
                        totalCost: calculateItemTotalCost((emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1), groupstEecost),
                        stEecost: groupstEecost,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                    };

                    combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(combinedList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(
                            combinedList,
                            nonGroupId,
                            null,
                            GroupTypes.NON_GROUP
                        );

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                combinedList,
                                dbExtraCharge
                            );
                            combinedList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                    setItemList(updatedItems);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItems });
                }

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: combinedList[0],
                    });
                }
            } else {
                const combinedList = [...itemList, ...groupedList];

                const dbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);
                let updatedNettEA;

                if (dbExtra) {
                    const dbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                    let listWithoutDbExtra = getItemsWithoutDBExtra(combinedList, GroupTypes.GROUP, groupId);
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                    const dbExtraRow = combinedList[dbExtraIndex];

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    combinedList[dbExtraIndex] = updatedDbExtraRow;
                }

                const groupRows = combinedList.filter(
                    (item) =>
                        item.groupId === groupId &&
                        (item.subGroupId === "" || item.subGroupId === "00000000-0000-0000-0000-000000000000") &&
                        item.stStockCode !== ""
                );

                let groupMargin = -1;
                let groupFrcMargin = -1;
                let groupActMargin = -1;

                const groupListPrice = calculateGroupWiseTotal(groupRows, "listPrice");
                const groupNettEA = calculateGroupWiseTotal(groupRows, "nettEA");
                const groupTotalPrice = calculateGroupWiseTotal(groupRows, "totalPrice");
                const groupstEecost = calculateGroupWiseTotal(groupRows, "stEecost");
                const groupStFutureCost = calculateGroupWiseTotal(groupRows, "stFutureCost");
                const groupTotalCost = calculateGroupWiseTotal(groupRows, "totalCost");
                const groupActCostExact = calculateGroupWiseTotal(groupRows, "actCostExact");
                // const groupTotalDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                const groupTotalDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                if (dbExtra) {
                    groupMargin = calculateGroupWiseMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupstEecost
                    );
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupStFutureCost
                    );
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupActCostExact
                    );
                } else {
                    groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                }

                const emptyGroupRow = combinedList.filter(
                    (item) => item.displayGroupName !== "" && item.groupId === groupId
                );

                const emptyGroupRowIndex = combinedList.findIndex(
                    (item) => item.displayGroupName !== "" && item.groupId === groupId
                );

                const groupQty = emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1;
                const hasGroupQtyChanged = groupQty > 1;

                const updatedEmptyGroupRow = {
                    ...emptyGroupRow[0],
                    listPrice: groupListPrice,
                    nettEA: groupNettEA,
                    totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                    dcOnLp: groupTotalDcOnLp,
                    stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                    stFutureCost: groupStFutureCost,
                    stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                    qty: groupQty,
                    margin: groupMargin,
                    actMargin: groupActMargin,
                    frcMargin: groupFrcMargin,
                    // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                    totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                    stEecost: groupstEecost,
                    actCost: parseFloat(groupActCostExact.toFixed(2)),
                    actCostExact: groupActCostExact,
                    //lcMargin: "",
                };

                combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(combinedList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                        combinedList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                setItemList(updatedItems);
                updateQuoteDetailsForId(id, { selectedItems: updatedItems });
                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: combinedList[0],
                    });
                }
            }
        } else {
            const nonGroupId = uuidv4();
            if (!nonGroupedId.current) nonGroupedId.current = nonGroupId;

            const updatedNewItems = newItems.map((res) => {
                return {
                    ...res,
                    groupId: nonGroupedId.current ? nonGroupedId.current : nonGroupId,
                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                    displayGroupName: "",
                    subGroupId: "",
                    subGroupName: "",
                    displaySubGroupName: "",
                    margin: parseFloat(parseFloat(res.margin).toFixed(1)),
                    frcMargin: parseFloat(parseFloat(res.frcMargin).toFixed(1)),
                    actMargin: parseFloat(parseFloat(res.actMargin).toFixed(1)),
                    // lcMargin: "",
                };
            });

            const combinedList = [...itemList, ...updatedNewItems];
            const dbExtra = isDbExtraAdded(combinedList, nonGroupedId.current, null, GroupTypes.NON_GROUP);

            if (dbExtra) {
                let listWithoutDbExtra = getItemsWithoutDBExtra(
                    combinedList,
                    GroupTypes.NON_GROUP,
                    nonGroupedId.current,
                    null
                );
                listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                const updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                const dbExtraIndex = getDbExtraRowIndex(
                    combinedList,
                    nonGroupedId.current,
                    null,
                    GroupTypes.NON_GROUP
                );
                const dbExtraRow = combinedList[dbExtraIndex];

                const updatedDbExtraRow = {
                    ...dbExtraRow,
                    nettEA: updatedNettEA,
                    totalPrice: updatedNettEA,
                };

                combinedList[dbExtraIndex] = updatedDbExtraRow;
            }

            const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
            setItemList(updatedItems);
            updateQuoteDetailsForId(id, { selectedItems: updatedItems });

        }
    }
    const [clipBoardCopiedList, setClipBoardCopiedList] = useState([]);
    const [generateFromClipBoard, setGenerateFromClipBoard] = useState(false);

    useEffect(() => {
        if (clipBoardCopiedList.length > 0) {
            setItemListNew(clipBoardCopiedList);
            setGenerateFromClipBoard(true);
        }
    }, [clipBoardCopiedList]);
    useEffect(() => {
        if (generateFromClipBoard) {
            handlePaste();
        }
    }, [generateFromClipBoard]);

    const handlePasteFromClipboard = async (UserId, customerId) => {
        setIsQuoteSaved(false);
        setQuoteUpdated(true);
        //  const UserId = user?.homeAccountId;
        try {
            setIsLoading(true);
            const clipboardData = await navigator.clipboard.readText();
            const rows = clipboardData.split('\n');

            //#region 
            // let importItems = rows
            //     .filter(row => row.trim() !== '')
            //     .map(row => {
            //         const cells = row.split('\t');

            //         const tabsCount = cells.length - 1; 
            //         const spacesCount = row.split(' ').length - 1;                   
            //         let stStockCode ="";
            //         if(tabsCount === 21){
            //             stStockCode = cells[2];
            //             return { stStockCode, qty: 1 };
            //         }
            //         if(tabsCount === 19){
            //             stStockCode = cells[0];
            //             return { stStockCode, qty: 1 };
            //         }
            //         if(tabsCount === 16){
            //             stStockCode = cells[2];
            //             return { stStockCode, qty: 1 };
            //         }
            //         if(tabsCount === 14){
            //             stStockCode = cells[0];
            //             return { stStockCode, qty: 1 };
            //         }
            //     });    
            //#endregion   

            let importItems = rows
                .filter(row => row.trim() !== '')
                .flatMap(row => {
                    const cells = row.split('\t');
                    if (row.includes(',')) {
                        return row.split(',').map(value => {
                            return { stStockCode: value.trim(), qty: 1 };
                        });
                    }
                    const tabsCount = cells.length - 1;
                    console.log("hihi tabs :", tabsCount);
                    let stStockCode = "";
                    switch (tabsCount) {
                        case 21:
                        case 16:
                            stStockCode = cells[2];
                            break;
                        case 19:
                        case 14:
                        case 6:
                            stStockCode = cells[0];
                            break;
                        default:
                            stStockCode = row.trim();
                            break;
                    }
                    return { stStockCode, qty: 1 };
                });
            importItems = importItems.filter(item => item.stStockCode && item.stStockCode.trim() !== '');

            //&& !item.stStockCode.startsWith('ASSEMBLY') && !item.stStockCode.startsWith('Q_LABTIME') && !item.stStockCode.startsWith('Line Notes ...') && !item.stStockCode.startsWith('CONSUMABLES')
            const mapDataItem = importItems.filter((item) => item.stStockCode !== '').map((item) => ({
                stStockCode: item.stStockCode ? item.stStockCode : "",
                qty: item.qty ? item.qty : "",
            }));

            var url = `Stocks/getstockdetailsusingimport?CustomerId=${customerId}&UserId=${UserId}`;
            const response = await postResponse(url, mapDataItem);


            const updatedItemList1 = response.map(item => {
                const rowId = uuidv4();
                const groupDbExtraId = "";
                const subGroupDbExtraId = "";
                const nonGroupDbExtraId = "";
                const parentId = null;
                const groupId = uuidv4();
                const groupName = "Non Grouped Items";

                return {
                    ...item,
                    stId: item.stId,
                    actCost: parseFloat(item.actCost),
                    actCostExact: parseFloat((item.actCost).toFixed(2)),
                    costEA: parseFloat(item.costEA),
                    nettEA: parseFloat(item.nettEA),
                    costEAExact: parseFloat((item.costEA).toFixed(2)),
                    dcCode: item.type === "" ? "" : item.dcCode,
                    dcRate: item.type === "" ? 0 : item.dcRate,
                    dcOnLp: item.type !== "" && item.listPrice && item.nettEA
                        ? calculateItemDcOnLp(item.nettEA, item.listPrice)
                        : 0,
                    totalPrice: parseFloat(item.totalPrice),
                    l20: item.l20,
                    l50: item.l50,
                    l55: item.l55,
                    l60: item.l60,
                    lastCost: parseFloat(item.lastCost),
                    lastCostExact: parseFloat((item.lastCost).toFixed(2)),
                    lcMargin: item.type === "" ? 0 : calculateItemLcMargin(
                        item.nettEA,
                        item.lastCost
                    ),
                    qty: parseFloat(item.qty),
                    margin: item.type === "" || item.nettEA === 0.0
                        ? 0
                        : parseFloat((((item.nettEA - item.stEecost) / item.nettEA) * 100).toFixed(1)),

                    actMargin: item.type === "" || item.nettEA === 0.0
                        ? 0
                        : parseFloat((((item.nettEA - item.actCost) / item.nettEA) * 100).toFixed(1)),
                    frcMargin: item.type === "" ? 0 : calculateItemFrcMargin(
                        item.nettEA,
                        item.stFutureCost
                    ),
                    repDisc: item.type === "" || item.repDisc === null ? 0 : parseFloat(item.repDisc),
                    stEecost: item.type === "" ? 0 : parseFloat(item.stEecost),
                    stEecostRound: item.type === "" ? 0 : parseFloat((item.stEecost).toFixed(2)),
                    stFutureCost: item.type === "" ? 0 : parseFloat(item.stFutureCost),
                    stFutureCostRound: item.type === "" ? 0 : parseFloat((item.stFutureCost).toFixed(2)),
                    soh: item.soh,
                    stStatus: item.type,
                    rowType: item.type === "" ? "CI" : item.type,
                    totalCost: item.type === "" || item.totalCost === null ? 0 : parseFloat((item.qty * item.stEecost).toFixed(2)),
                    totalPrice: item.type === "" || item.totalPrice === null ? 0 : parseFloat((item.qty * item.nettEA).toFixed(2)),
                    listPrice: item.type === "" ? 0 : parseFloat(item.listPrice),
                    rowId,
                    groupDbExtraId,
                    subGroupDbExtraId,
                    nonGroupDbExtraId,
                    id: uuidv4(),
                    parentId,
                    groupId,
                    groupName,
                    displayAssemblyGroupName: "",
                    displayGroupName: "",
                    displaySubGroupName: "",
                    subGroupId: "",
                    subGroupName: "",
                    position: "",
                };
            });
            let excelCopiedItems = [...updatedItemList1];
            setClipBoardCopiedList(excelCopiedItems);
            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...updatedItemList1] });
            //setItemListNew(excelCopiedItems);

            //    if(itemList.length==1){
            //     const updatedItemList = [...updatedItemList1];
            //     const updatedList = itemPositioning(true, updatedItemList);
            //     setItemList(updatedList);
            //     updateQuoteDetailsForId(id, { selectedItems: updatedList });
            //     setComputeTotals(true);
            //     }  
            //     else{
            //     const updatedItemList = [...itemList.slice(0,itemList.length-1), ...updatedItemList1];
            //     const updatedList = itemPositioning(true, updatedItemList);
            //     setItemList(updatedList);
            //     updateQuoteDetailsForId(id, { selectedItems: updatedList });
            //     setComputeTotals(true);
            //     }            
            await navigator.clipboard.writeText('');
        } catch (error) {
            console.error('Error reading text from clipboard:', error);
        } finally {
            setIsLoading(false);
            // setCopyPasteItemShowProgress(false);
        }
    };

    const handlePastexx = async () => {
        setIsPastedUnits(true);
        setQuoteUpdated(true);

        try {
            setIsLoading(true);
            const UserId = user?.homeAccountId;
            await handlePasteFromClipboard(UserId, customerId);
        } catch (error) {
            console.error('Error handling paste:', error);
        } finally {
            setIsLoading(false);
        }

        const hasItem = itemListNew.some((item) => item.stStockCode !== "" && item.stStockCode !== null);

        if (itemListNew.length > 0 && itemListNew[itemListNew.length - 1].rowType === "") {
            itemListNew.pop(); // Remove the last item when last item is empty
        }

        let hasDXInItemList = "";
        let hasDXInCopiedData = "";
        let copiedDatas = itemListNew;
        let itemForPasting = [];

        if (itemList.length > 1 && selectedItems[0] !== undefined) {

            hasDXInItemList = (selectedItems[0].subGroupId || "").length >= 0
                ? itemList.some((f) => f.subGroupId === selectedItems[0].subGroupId && f.rowType === 'DX')
                : itemList.some((f) => f.groupId === selectedItems[0].groupId && f.rowType === 'DX');

            hasDXInCopiedData = copiedDatas.some((item) => item.rowType === 'DX');
        }

        const hasAssembly = copiedDatas.some(item => item.rowType === 'A');
        const hasAsemmblyItem = copiedDatas.some(item => item.rowType === 'AI');

        if (hasAsemmblyItem && !hasAssembly) {
            toast.error("Please include the Assembly Header in your selection.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        if (!hasItem) {
            toast.error("You have no selected item(s) to paste.", {
                position: "top-center",
                transition: Slide,
            });
            return;

        }

        else if (hasDXInItemList && hasDXInCopiedData) {
            toast.error("The DB Extra is already been added.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        const itemsWithNoAssembly = itemList.filter((item) => item.rowType !== 'A' && item.rowType !== 'AI');
        const selIdx = itemsWithNoAssembly.findIndex((item) => item.rowId === selectedPasteItem.rowId);
        const itemForRef = selIdx > 0
            ? itemsWithNoAssembly.length > 0
                ? itemsWithNoAssembly[selIdx - 1]
                : itemsWithNoAssembly[selIdx]
            : itemsWithNoAssembly[0];
        const refIndex = itemList.findIndex((item) => item.rowId === selectedPasteItem.rowId);

        let { newGroup, newGrpId, newGrpName = "", newSGroup, newSGroupId = "", newId, newGPid = null, newSGPid = null
            , newSubGrpName = "", rowtype = "", newAssemblyId = "", newAssemblyName = "", newGroupAssemblyId = "" } = {};

        const hasSubGroupInCopiedData = copiedDatas.some((item) => item.rowType === 'SG');
        const groupCopiedDataOnly = !copiedDatas.some((item) => item.rowType === 'SG' || item.groupName === "Non Grouped Items");
        const subGroupCopiedDataOnly = !copiedDatas.some((item) => item.rowType === 'G' || item.groupName === "Non Grouped Items");//copiedDatas.some((item) => item.rowType === 'SG' && (item.groupName !== "Non Grouped Items" || item.rowType !== "G"));
        const withGroupAndSubgroupCopiedData = (hasSubGroupInCopiedData && !subGroupCopiedDataOnly);
        const hasGrpLevel = copiedDatas.some((item) => item.rowType === 'G');
        const hasSubGrpLevel = copiedDatas.some((item) => item.rowType === 'SG');
        const hasNonGrpItems = copiedDatas.some((item) => item.groupName === "Non Grouped Items");

        const hasGrpWithNonGrpItemsOnly = hasGrpLevel && hasNonGrpItems && !hasSubGrpLevel;


        //#region do not allow to paste group level into sub group level
        if (itemForRef.rowType === 'SG' || itemForRef.subGroupId !== '') {
            const hasGroupInCopiedData = itemListNew.some((item) => item.rowType === 'G' || item.rowType === 'SG');
            if (hasGroupInCopiedData) {
                toast.error("Copied items in a group or sub-group level is not allowed inside a sub-group level.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

        }
        //#endregion

        //#region do not allow to paste group level with subgroup level into Group Level
        if (itemForRef.rowType === 'G' || (itemForRef.groupId !== '')) {
            if (hasSubGrpLevel && hasGrpLevel) {
                toast.error("Copied items in a group with sub-group level is not allowed inside a group level.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }

        }
        //#endregion

        //#region pasting inside a group level
        if (itemForRef.rowType === 'G' || (itemForRef.groupName !== "Non Grouped Items" && itemForRef.groupId !== '' && itemForRef.subGroupId === '')) {

            // no conversion of group to sub group if copied items has sub-group level. It will be pasted on the last row
            if (withGroupAndSubgroupCopiedData) {
                copiedDatas.forEach((item) => {
                    if (item.rowType === "G") newGroup = createGroupRow(uuidv4(), groupCount);
                    else if (item.rowType === "SG") newSGroup = createSubGroupRow(group.groupId, groupCount, uuidv4(), subGroupCount, newGPid);

                    newGPid = item.rowType === 'G' ? null : newGPid;
                    newSGPid = item.rowType === 'SG' ? null : newSGPid;
                    newId = uuidv4();

                    rowtype = item.rowType;

                    newSubGrpName = item.rowType === 'SG' || newSGroup ? newSGroup.subGroupName : newSubGrpName;
                    newSGroupId = item.rowType === 'SG' || newSGroup ? newSGroup.subGroupId : newSGroupId;

                    newAssemblyId = item.rowType === 'A' ? uuidv4() : newAssemblyId;
                    newAssemblyName = item.rowType === 'A' ? "ASSEMBLY " + (parseInt(item.assemblyGroupName.match(/(\d+)/)[0]) + 1) : newAssemblyName;


                    itemForPasting.push({
                        ...item,
                        rowId: uuidv4(),
                        groupLevelName: item.groupLevelName,
                        groupId: newGroup.groupId,
                        groupName: newGroup.groupName,
                        displayGroupName: item.displayGroupName,
                        displaySubGroupName: item.displaySubGroupName,
                        subGroupName: newSubGrpName,
                        subGroupId: newSGroupId,
                        id: newId,
                        parentId: newSGPid === null ? newGPid : newSGPid,
                        rowType: rowtype,
                        assemblyId: newAssemblyId,
                        assemblyName: newAssemblyName,
                        subItems: null,
                        columns: null,
                        position: null
                    });

                    newGPid = item.rowType === 'G' ? newId : newGPid;
                    newSGPid = item.rowType === 'SG' ? newId : newSGPid;


                });

                const hasSubGroup = copiedDatas.some((item) => item.rowType === 'SG');
                const hasGroup = copiedDatas.some((item) => item.rowType === 'G');
                let emptyRow = {};
                if (hasSubGroup) emptyRow = createEmptyRow(newSGroup.groupId, newSGroup.groupName, newSGroup.subGroupId, newSGroup.subGroupName, "", "", "", newSGPid);
                else if (hasGroup) emptyRow = createEmptyRow(newGroup.groupId, newGroup.groupName, newGroup.subGroupId, newGroup.subGroupName, "", "", "", newGPid);

                let updatedList = [
                    ...itemList.slice(0, itemList.length - 1),
                    ...itemForPasting,
                    emptyRow
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
            ///pasting a group and converting it to sub group
            else if (groupCopiedDataOnly) {
                newGroup = itemForRef;
                //#region update the group headers
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = uuidv4();
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.rowType === 'G') {
                        const subgrp = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGroup.id);

                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = subgrp.subGroupId;
                        itm.subGroupName = subgrp.subGroupName;
                        itm.displaySubGroupName = itm.displayGroupName;
                        itm.displayGroupName = "";
                        itm.rowType = "SG";
                        itm.id = subgrp.id;
                        itm.parentId = newGroup.id;
                        itm.position = null;

                        newSGroupId = itm.subGroupId;
                        newSubGrpName = itm.subGroupName;
                        itemParentId = itm.id;

                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }
                    else {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.position = null;
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;

                        return itm;
                    };

                });
                //#endregion    

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex)
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);

            }
            /// pasting a sub-group only in a group level   
            else if (subGroupCopiedDataOnly) {
                newGroup = itemForRef;
                //#region update the sub-group details
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = itemForRef.parentId !== null ? itemForRef.parentId : itemForRef.id;
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.rowType === 'SG') {
                        const subgrp = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGroup.id);

                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = subgrp.subGroupId;
                        itm.subGroupName = subgrp.subGroupName;
                        itm.displayGroupName = "";
                        itm.rowType = "SG";
                        itm.id = subgrp.id;
                        itm.parentId = newGroup.id;
                        itm.position = null;

                        newSGroupId = itm.subGroupId;
                        newSubGrpName = itm.subGroupName;
                        itemParentId = itm.id;

                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }
                    else {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.position = null;
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;

                        return itm;
                    };

                });
                //#endregion    

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex)
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
            //pasting an assorted items hasGrpWithNonGrpItemsOnly
            else if (hasGrpWithNonGrpItemsOnly) {
                newGroup = itemForRef;
                //#region update the group headers
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = itemForRef.parentId !== null ? itemForRef.parentId : itemForRef.id;
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.rowType === 'G') {
                        const subgrp = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGroup.id);

                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = subgrp.subGroupId;
                        itm.subGroupName = subgrp.subGroupName;
                        itm.displaySubGroupName = itm.displayGroupName;
                        itm.displayGroupName = "";
                        itm.rowType = "SG";
                        itm.id = subgrp.id;
                        itm.parentId = newGroup.id;
                        itm.position = null;

                        newSGroupId = itm.subGroupId;
                        newSubGrpName = itm.subGroupName;
                        itemParentId = itm.id;

                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }
                    else {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.position = null;
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;

                        return itm;
                    };

                });
                //#endregion    

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex)
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
            //pasting a non-group items into group level
            else if (hasNonGrpItems) {
                newGroup = itemForRef;
                //#region update the group headers
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = itemForRef.parentId !== null ? itemForRef.parentId : itemForRef.id;
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.rowType === 'G') {
                        const subgrp = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGroup.id);

                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = subgrp.subGroupId;
                        itm.subGroupName = subgrp.subGroupName;
                        itm.displaySubGroupName = itm.displayGroupName;
                        itm.displayGroupName = "";
                        itm.rowType = "SG";
                        itm.id = subgrp.id;
                        itm.parentId = newGroup.id;
                        itm.position = null;

                        newSGroupId = itm.subGroupId;
                        newSubGrpName = itm.subGroupName;
                        itemParentId = itm.id;

                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }
                    else {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.subGroupId = newSGroupId;
                        itm.subGroupName = newSubGrpName;
                        itm.position = null;
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;

                        return itm;
                    };

                });
                //#endregion    

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex)
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }

        }
        //#endregion
        //#region pasting sub-group or an item into a group level
        else if (itemForRef.rowType === 'SG' || itemForRef.subGroupId !== '') {
            newGroup = itemForRef;
            //#region update the sub-group values
            let updatedCopiedDatas = copiedDatas;
            let itemParentId = itemForRef.parentId !== null ? itemForRef.parentId : itemForRef.id;
            const updatedItemList = updatedCopiedDatas.map((item) => {

                let itm = item;
                if (itm.rowType === 'SG') {
                    const subgrp = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGroup.id);

                    itm.groupId = newGroup.groupId;
                    itm.groupName = newGroup.groupName;
                    itm.subGroupId = subgrp.subGroupId;
                    itm.subGroupName = subgrp.subGroupName;
                    itm.displayGroupName = "";
                    itm.rowType = "SG";
                    itm.id = subgrp.id;
                    itm.parentId = newGroup.id;
                    itm.position = null;

                    newSGroupId = itm.subGroupId;
                    newSubGrpName = itm.subGroupName;
                    itemParentId = itm.id;

                    return itm;

                }
                else if (itm.rowType === 'A') {
                    itm.groupId = newGroup.groupId;
                    itm.groupName = newGroup.groupName;
                    itm.subGroupId = newSGroupId;
                    itm.subGroupName = newSubGrpName;
                    itm.displaySubGroupName = "";
                    itm.displayGroupName = "";
                    itm.id = uuidv4();
                    itm.parentId = itemParentId;
                    itm.assemblyId = uuidv4();
                    itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                    itm.position = null;

                    newAssemblyId = itm.assemblyId;
                    newAssemblyName = itm.assemblyGroupName;
                    newGroupAssemblyId = itm.id;

                    return itm;
                }
                else if (itm.rowType === 'AI') {
                    itm.groupId = newGroup.groupId;
                    itm.groupName = newGroup.groupName;
                    itm.subGroupId = newSGroupId;
                    itm.subGroupName = newSubGrpName;
                    itm.displaySubGroupName = "";
                    itm.displayGroupName = "";
                    itm.id = uuidv4();
                    itm.parentId = newGroupAssemblyId;
                    itm.assemblyId = newAssemblyId;
                    itm.assemblyGroupName = newAssemblyName;
                    itm.position = null;

                    return itm;
                }
                else {
                    itm.groupId = newGroup.groupId;
                    itm.groupName = newGroup.groupName;
                    itm.displaySubGroupName = "";
                    itm.displayGroupName = "";
                    itm.subGroupId = newSGroupId;
                    itm.subGroupName = newSubGrpName;
                    itm.position = null;
                    itm.id = uuidv4();
                    itm.parentId = itemParentId;

                    return itm;
                };

            });
            //#endregion    

            let updatedList = [
                ...itemList.slice(0, refIndex),
                ...updatedItemList,
                ...itemList.slice(refIndex)
            ];



            const sortedItems = itemPositioning(true, updatedList);
            setItemList(sortedItems);
            updateQuoteDetailsForId(id, { selectedItems: sortedItems });
            setComputeTotals(true);
            setSelectedItems([]);
        }
        //#region pasting outside the group or sub group level
        else if (itemForRef.groupName === "Non Grouped Items") {
            //#region sub-group level to group level conversion
            if (subGroupCopiedDataOnly) {
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = uuidv4();
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.rowType === 'SG') {
                        newGroup = createGroupRow(uuidv4(), groupCount);

                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displayGroupName = itm.displaySubGroupName;
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.rowType = "G";
                        itm.id = newGroup.id;
                        itm.parentId = null;
                        itm.position = null;

                        newGrpId = itm.groupId;
                        newGrpName = itm.groupName;
                        itemParentId = itm.id;

                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }
                    else {
                        itm.groupId = newGroup.groupId;
                        itm.groupName = newGroup.groupName;
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = itemParentId;
                        itm.position = null;

                        return itm;
                    };

                });

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex),
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
            //#endregion
            //#region group level or group level with subgroup pasting within non-grouped items
            else if (groupCopiedDataOnly || withGroupAndSubgroupCopiedData) {
                copiedDatas.forEach((item) => {
                    if (item.rowType === "G") newGroup = createGroupRow(uuidv4(), groupCount);
                    else if (item.rowType === "SG") newSGroup = createSubGroupRow(newGroup.groupId, groupCount, uuidv4(), subGroupCount, newGPid);

                    newGPid = item.rowType === 'G' ? null : newGPid;
                    newSGPid = item.rowType === 'SG' ? null : newSGPid;
                    newId = uuidv4();

                    rowtype = item.rowType;

                    newSubGrpName = item.rowType === 'SG' || newSGroup ? newSGroup.subGroupName : newSubGrpName;
                    newSGroupId = item.rowType === 'SG' || newSGroup ? newSGroup.subGroupId : newSGroupId;

                    newAssemblyId = item.rowType === 'A' ? uuidv4() : newAssemblyId;
                    newAssemblyName = item.rowType === 'A' ? "ASSEMBLY " + (parseInt(item.assemblyGroupName.match(/(\d+)/)[0]) + 1) : newAssemblyName;


                    itemForPasting.push({
                        ...item,
                        rowId: uuidv4(),
                        groupLevelName: item.groupLevelName,
                        groupId: newGroup.groupId,
                        groupName: newGroup.groupName,
                        displayGroupName: item.displayGroupName,
                        displaySubGroupName: item.displaySubGroupName,
                        subGroupName: newSubGrpName,
                        subGroupId: newSGroupId,
                        id: newId,
                        parentId: newSGPid === null ? newGPid : newSGPid,
                        rowType: rowtype,
                        assemblyId: newAssemblyId,
                        assemblyName: newAssemblyName,
                        subItems: null,
                        columns: null,
                        position: null
                    });

                    newGPid = item.rowType === 'G' ? newId : newGPid;
                    newSGPid = item.rowType === 'SG' ? newId : newSGPid;


                });

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...itemForPasting,
                    ...itemList.slice(refIndex),
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
            //#endregion
            //#region pasting non-group items only
            else {
                let updatedCopiedDatas = copiedDatas;
                let itemParentId = uuidv4();
                const updatedItemList = updatedCopiedDatas.map((item) => {

                    let itm = item;
                    if (itm.groupName === 'Non Grouped Items') {
                        itm.groupId = uuidv4();
                        itm.displayGroupName = "";
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = null;
                        itm.position = null;
                        return itm;

                    }
                    else if (itm.rowType === 'A') {
                        itm.groupId = uuidv4();
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = null;
                        itm.assemblyId = uuidv4();
                        itm.assemblyGroupName = "ASSEMBLY " + (parseInt(itm.assemblyGroupName.match(/(\d+)/)[0]) + 1);
                        itm.position = null;

                        newAssemblyId = itm.assemblyId;
                        newAssemblyName = itm.assemblyGroupName;
                        newGroupAssemblyId = itm.id;

                        return itm;
                    }
                    else if (itm.rowType === 'AI') {
                        itm.groupId = uuidv4();
                        itm.subGroupId = "";
                        itm.subGroupName = "";
                        itm.displaySubGroupName = "";
                        itm.displayGroupName = "";
                        itm.id = uuidv4();
                        itm.parentId = newGroupAssemblyId;
                        itm.assemblyId = newAssemblyId;
                        itm.assemblyGroupName = newAssemblyName;
                        itm.position = null;

                        return itm;
                    }

                });

                let updatedList = [
                    ...itemList.slice(0, refIndex),
                    ...updatedItemList,
                    ...itemList.slice(refIndex),
                ];



                const sortedItems = itemPositioning(true, updatedList);
                setItemList(sortedItems);
                updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                setComputeTotals(true);
                setSelectedItems([]);
            }
        }
        //#endregion


    }
    const handlePasteValidation = async () => {
        try {
            setIsLoading(true);
            const UserId = user?.homeAccountId;
            const clipboardData = await navigator.clipboard.readText()
                .then((data) => {
                    if (data && !allowCopy) {
                        handlePasteFromClipboard(UserId, customerId);
                    }
                    else {
                        handlePaste();
                    }
                });
            
            
        } catch (error) {
            console.error('Error handling paste:', error);
        } finally {
            setIsLoading(false);
        }
    }

    const handlePaste = async () => {
        setIsPastedUnits(true);
        setQuoteUpdated(true);


        const hasItem = itemListNew.some((item) => item.stStockCode !== "" && item.stStockCode !== null);

        if (itemListNew.length > 0 && itemListNew[itemListNew.length - 1].rowType === "") {
            itemListNew.pop(); // Remove the last item when last item is empty
        }

        let hasDXInItemList = "";
        let hasDXInCopiedData = ""

        if (itemList.length > 1 && selectedItems[0] !== undefined) {

            hasDXInItemList = (selectedItems[0].subGroupId || "").length >= 0 ? itemList.some((f) => f.subGroupId === selectedItems[0].subGroupId && f.rowType === 'DX') : itemList.some((f) => f.groupId === selectedItems[0].groupId && f.rowType === 'DX');

            hasDXInCopiedData = itemListNew.some((item) => item.rowType === 'DX');

        }
        const hasAssembly = itemListNew.some(item => item.rowType === 'A');
        const hasAsemmblyItem = itemListNew.some(item => item.rowType === 'AI');

        const isAssemblyCopied = itemListNew.some((item) => item.rowType === 'A' || item.rowType === 'AI');
        const isAssemblyCopied1 = itemListNew.filter((item) => item.rowType === 'A' || item.rowType === 'AI');
        const isAssemblyCopied2 = itemListNew.find((item) => item.rowType === 'A' || item.rowType === 'AI');



        if (isAssemblyCopied) {
            let assemblyId = "";
            let assemblyGroupName = "";
            let displayAssemblyGroupName = "";
            const list = [];
            itemListNew.forEach((item) => {
                assemblyId = item.rowType === 'A' ? uuidv4() : assemblyId;  //: item.rowType !== 'A' || item.rowType !== 'AI' ? ""
                assemblyGroupName = item.rowType === 'A' ? ("ASSEMBLY " + (parseInt(item.assemblyGroupName.match(/(\d+)/)[0]) + 1)) : item.rowType !== 'A' && item.rowType !== 'AI' ? "" : assemblyGroupName;
                displayAssemblyGroupName = item.rowType === 'A' ? assemblyGroupName : item.rowType !== 'A' && item.rowType !== 'AI' ? "" : "";
                let subItems = item.rowType === "G" || item.rowType === "SG" || item.rowType === "AI" ? null : item.subItems;
                list.push({
                    ...item,
                    rowId: uuidv4(),
                    assemblyId: assemblyId,
                    assemblyGroupName: assemblyGroupName,
                    displayAssemblyGroupName: displayAssemblyGroupName,
                    subItems: null
                });
            });
            setItemListNew(list);
        }
        if (hasAsemmblyItem && !hasAssembly) {
            toast.error("You have no selected Assembly to paste. Only selected Assembly Item", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        if (!hasItem) {
            toast.error("You have no selected item(s) to paste.", {
                position: "top-center",
                transition: Slide,
            });
            return;

        }
        //#region 
        // else if(hasDXInItemList && hasDXInCopiedData ){  
        //     toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
        //         position: "top-center",
        //         transition: Slide,
        //     });                  
        //     return;
        // } 
        //#endregion

        else {
            const updatelist = [...itemList];
            let pasteIndex = -1;

            if (updatelist.length == 1) {

                const hasSubGroupsWithoutGroups = itemListNew.some(item => item.rowType === 'SG') && itemListNew.every(item => item.rowType !== 'G');
                const isNonGroupItemCopied = itemListNew.find((item) => item.rowType !== 'G' && item.rowType !== 'SG');
                const isGroupItemCopied = itemListNew.find((item) => item.rowType === 'G');
                const isSubGroupItemCopied = itemListNew.find((item) => item.rowType === 'SG');

                //empty grid  paste sub groups
                if (selectedItems[0] === undefined && selectedPasteItem === undefined && hasSubGroupsWithoutGroups) {
                    let grpid = "";
                    let pid = "";
                    let gid = "";
                    let gdname = "";
                    let displayName = ";"
                    let rowtype = "";
                    let gCount = groupCount;
                    const list = [];
                    itemListNew.forEach((item) => {
                        grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                        gid = item.rowType === 'SG' ? uuidv4() : gid;
                        pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                        gdname = item.rowType === 'SG' ? item.subGroupName : "";
                        displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                        rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                        const group = createGroupRow(uuidv4(), groupCount);
                        list.push({
                            ...item,
                            rowId: uuidv4(),
                            groupLevelName: gdname,
                            groupId: gid,
                            displayGroupName: displayName,
                            displaySubGroupName: "",
                            subGroupName: "",
                            subGroupId: "",
                            id: uuidv4(),
                            parentId: pid,
                            rowType: rowtype,
                            //     subItems: null,
                            columns: null
                        });
                    });
                    let updatedList = [];

                    updatedList = [...list, ...updatelist];


                    const updatedList1 = itemPositioning(true, updatedList);
                    setItemList(updatedList1);
                    // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                    setComputeTotals(true);
                    //  setSelectedItems([]);

                    //commented by sachini - add multiple subgroups this is incorrect
                    // let combinedItems = [];

                    // const group = createGroupRow(uuidv4(), groupCount);
                    // const subGroup = createSubGroupRow(group.groupId, groupCount, uuidv4(), subGroupCount,group.id);
                    // const subGrpHeader = itemListNew.filter((f)=> f.rowType === "SG").map((item) => {
                    //     return {
                    //         ...item,
                    //         groupId:group.groupId,
                    //         groupName :group.groupName,
                    //         displayGroupName:item.subGroupName,
                    //         subGroupId:""  ,
                    //         subGroupName:item.subGroupName ,
                    //         displaySubGroupName:"",
                    //         groupLevelName: item.subGroupName,
                    //         id: uuidv4(),
                    //         rowType:"G",
                    //         parentId: null,
                    //         subItems : null,
                    //         columns:null
                    //     }
                    // });
                    // const subGrpItems = itemListNew.filter((f)=> f.rowType != "SG" && f.rowType !== "G").map((item) => {
                    //     return {
                    //         ...item,
                    //         groupId:group.groupId,
                    //         groupName : group.groupName,
                    //         subGroupId:""  ,
                    //         subGroupName:item.subGroupName ,
                    //         displaySubGroupName:"",
                    //         id: uuidv4(),
                    //         parentId: subGrpHeader.id,
                    //         columns:null,
                    //         subItems : null,
                    //         // subGroupId: subGroup.groupId,
                    //         // subGroupName: subGroup.subGroupName,
                    //     }
                    // });

                    // combinedItems = [...subGrpHeader,...subGrpItems];
                    // let updatedList =[];
                    //   updatedList = [...combinedItems, ...updatelist];               

                    // const updatedList1 = itemPositioning(true,updatedList);
                    //  setItemList(updatedList1);
                    //  updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                    //  setSelectedItems([]);
                }

                //paste only items in empty grid       
                else if (isNonGroupItemCopied && !isGroupItemCopied && !isSubGroupItemCopied) {
                    // const updatedList = [...itemListNew];
                    let grpid = "";

                    const itemsWithNonEmptyStStockCode = itemListNew
                        .filter(item => item.stStockCode !== '')
                        .map(item => {
                            grpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : grpid;
                            return {
                                ...item,
                                rowId: uuidv4(),
                                groupId: grpid,
                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: null,
                                columns: null,
                                // subItems : null,
                            };
                        });


                    let updatedList = [...itemsWithNonEmptyStStockCode]
                    console.log("before itemPositioning paste", updatedList);
                    updatedList = itemPositioning(true, updatedList);
                    console.log("itemPositioning paste", updatedList);
                    setItemList(updatedList);
                    // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                    //  setSelectedItems([]);
                }
                else {
                    //empty grid select first row and paste
                    if (hasSubGroupsWithoutGroups) {
                        //empty grid selected row cannot paste sub groups
                        let grpid = "";
                        let pid = "";
                        let gid = "";
                        let gdname = "";
                        let displayName = ";"
                        let rowtype = "";
                        let gCount = groupCount;
                        const list = [];
                        itemListNew.forEach((item) => {
                            grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                            gid = item.rowType === 'SG' ? uuidv4() : gid;
                            pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                            gdname = item.rowType === 'SG' ? item.subGroupName : "";
                            displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                            rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                            const group = createGroupRow(uuidv4(), groupCount);
                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupLevelName: gdname,
                                groupId: gid,
                                displayGroupName: displayName,
                                displaySubGroupName: "",
                                subGroupName: "",
                                subGroupId: "",
                                id: uuidv4(),
                                parentId: pid,
                                rowType: rowtype,
                                //        subItems: null,
                                columns: null
                            });
                        });
                        let updatedList = [];

                        updatedList = [...list];


                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //   setSelectedItems([]);
                        //     toast.error("Cannot Paste without group", {
                        //         position: "top-center",
                        //         transition: Slide,
                        //     });                          
                        // return;

                    } else {
                        //empty grid selected row  paste  groups , no group items
                        const itemListNew1 = itemListNew
                            .map((item) => ({
                                ...item,
                                columns: null,
                                //   subItems : null,
                            }));
                        let updatedList = [...itemListNew1];
                        updatedList = itemPositioning(true, updatedList);
                        setItemList(updatedList);
                        // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);
                        //  setSelectedItems([]);
                    }
                }
            }

            if (selectedItems[0] === undefined && updatelist.length > 1) {
                ///check last item in a group or subgroup or nongroup
                ///related to cannot paste multiple db extra 
                const getLastItem = itemListNew.filter((item) => item.position == selectedIndex); //itemList[itemList.length - 2];          
                if (getLastItem && getLastItem.groupId !== '' && getLastItem.subGroupId === '' && getLastItem.groupName !== 'Non Grouped Items') {
                    const lastList = itemList.filter(item => item.groupId == getLastItem.groupId);
                    const hasDXItem = lastList.some(item => item.rowType === 'DX');
                    hasDXInCopiedData = itemListNew.some((item) => item.rowType === 'DX');
                    if (hasDXItem && hasDXInCopiedData) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                if (getLastItem && getLastItem.groupId !== '' && getLastItem.subGroupId !== '' && getLastItem.groupName !== 'Non Grouped Items') {
                    const lastList = itemList.filter(item => item.subGroupId == getLastItem.subGroupId);
                    const hasDXItem = lastList.some(item => item.rowType === 'DX');
                    hasDXInCopiedData = itemListNew.some((item) => item.rowType === 'DX');
                    if (hasDXItem && hasDXInCopiedData) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                if (getLastItem && getLastItem.groupName === 'Non Grouped Items') {
                    const lastList = itemList.filter(item => item.groupName === 'Non Grouped Items');
                    const hasDXItem = lastList.some(item => item.rowType === 'DX');
                    hasDXInCopiedData = itemListNew.some((item) => item.rowType === 'DX');
                    if (hasDXItem && hasDXInCopiedData) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                //end of db extra pasting validation
                pasteIndex = updatelist.length - 1;
                const copiedItemListFirstIndex = selectedIndex;
                const copiedItemsListFirstItem = itemListNew.filter((item) => item.position == selectedIndex + 1);

                const hasDXInCopiedDataList = itemListNew.some((item) => item.rowType === 'DX');
                const listOfSubGroupItems = itemList.filter(item => item.subGroupId === copiedItemsListFirstItem[0]?.subGroupId && copiedItemsListFirstItem[0]?.subGroupId !== '');
                const listOfGroupItems = itemList.filter(item => item.groupId === copiedItemsListFirstItem[0]?.groupId && item.subGroupId === '');
                //subgroup item
                if (listOfSubGroupItems.length > 0 && !itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                    if (listOfSubGroupItems.filter(item => item.rowType === 'DX').length > 0 && hasDXInCopiedDataList) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                //group item
                if (listOfGroupItems.length > 0 && !itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                    if (listOfGroupItems.filter(item => item.rowType === 'DX').length > 0 && hasDXInCopiedDataList) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }



                const lastItem = itemList[itemList.length - 1];
                const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                const subGroupNameContains = 'Non Sub Group';
                const isLastItemNonSubGroup = lastItem && lastItem.subGroupName.includes(subGroupNameContains);


                //////newly added by sachini 15/05/2024 because we has already selected index when coping items, therefore we no need to paste in last. Need to paste above of the selected index
                const copiedItemListHasNonGroup = itemListNew.find((item) => item.groupName === 'Non Grouped Items');
                const copiedItemListHasGroup = itemListNew.find((item) => item.rowType === 'G');
                const copiedItemListHasSubGroup = itemListNew.find((item) => item.rowType === 'SG');
                console.log("isungroup:", isUngroup);
                //Check if click Ungroup
                if (isUngroup) {
                    //check last empty row nongroup or group (because we can click ungroup twice)
                    const isNonGroupedItem = lastItem && lastItem.groupName === 'Non Grouped Items';
                    //non group items copy
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            let grpid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.groupName === 'Non Grouped Items' ? uuidv4() : grpId;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = "";
                            const sgname = "";

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: lastItem.parentId,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setIsUngroup(false);
                        }
                    }
                    //GroupOnly
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            let grpid = "";
                            let pid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    columns: null,
                                    listPrice: item.listPrice,
                                    totalPrice: item.totalPrice
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {
                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";

                            const list = [];

                            itemListNew.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: lastItem.groupId,
                                    groupName: lastItem.groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setIsUngroup(false);
                        }
                    }
                    //subGroupOnly
                    if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1)
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {

                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            let pid = lastItem.parentId;
                            let sgid = "";
                            let sgname = "";
                            let displaysgname = "";


                            const list = [];
                            itemListNew.forEach((item) => {
                                sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                pid = item.rowType === 'SG' ? pid : uuidv4();
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    //   groupLevelName: gdname,
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    // groupLevelName:""
                                    displaySubGroupName: displaysgname,
                                    subGroupName: sgname,
                                    subGroupId: sgid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1)
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setIsUngroup(false);
                        }
                    }
                    //NonGroup + Group
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(itemList.length - 1)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {
                            let nongrpid = "";

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";
                            const parentId = lastItem.parentId;
                            const list = [];

                            copiedlistWithoutNonGroup.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: lastItem.groupId,
                                    groupName: lastItem.groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(itemList.length - 1)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsUngroup(false);
                        }
                    }
                    //NonGroup + SubGroup
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let gCount = groupCount;
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });
                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });


                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(itemList.length - 1)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {


                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });
                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];


                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsUngroup(false);
                            }
                        }
                    }
                    //NonGroup + Group +SubGroup
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'G' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        groupId: gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        subGroupId: sid,
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsUngroup(false);

                            }

                        }
                        else {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                    }
                    //Group + SubGroup
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isNonGroupedItem) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;

                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'G' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        groupId: gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        subGroupId: sid,
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });


                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                                setIsUngroup(false);

                            }

                        }
                        else {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                    }
                    //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                    if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                        if (isNonGroupedItem) {
                            let nongrpid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsUngroup(false);
                        }
                        else {
                            let gid = lastItem.groupId;
                            let gname = lastItem.groupName;
                            let pid = lastItem.parentId;
                            const list = [];
                            itemListNew.forEach((item) => {


                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: pid
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsUngroup(false);
                        }
                    }
                }
                else if (isGroup) {
                    const isInsideSubGroup = lastItem && lastItem.subGroupId !== '';
                    //non group items copy
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        //items add to subgroup
                        if (isInsideSubGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = lastItem.subGroupId;
                            const sgname = lastItem.subGroupName;
                            const pid = lastItem.parentId;

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsGroup(false);
                        }
                        //items add to group
                        else {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = "";
                            const sgname = "";
                            const pid = lastItem.parentId;

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsGroup(false);
                        }

                    }
                    //GroupOnly
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";

                            const list = [];

                            itemListNew.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: lastItem.groupId,
                                    groupName: lastItem.groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsGroup(false);
                        }

                    }
                    //subGroupOnly
                    if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {

                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                itemListNew.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)
                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsGroup(false);

                            }
                        }

                    }
                    //NonGroup + Group
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            let nongrpid = "";

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";
                            const parentId = lastItem.parentId;
                            const list = [];

                            copiedlistWithoutNonGroup.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: lastItem.groupId,
                                    groupName: lastItem.groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(itemList.length - 1)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setIsGroup(false);
                        }

                    }
                    //NonGroup + SubGroup
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {


                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });
                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(itemList.length - 1)

                                ];


                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                                setIsGroup(false);
                            }
                        }

                    }
                    //NonGroup + Group +SubGroup
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                    }
                    //Group + SubGroup
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (isInsideSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                    }
                    //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                    if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                        if (isInsideSubGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = lastItem.subGroupId;
                            const sgname = lastItem.subGroupName;
                            const pid = lastItem.parentId;

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsGroup(false);
                        }
                        else {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = "";
                            const sgname = "";
                            const pid = lastItem.parentId;

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                            setIsGroup(false);
                        }

                    }

                }

                else {
                    //if arrow icon in last empty row (selectedIndex ===- -1)
                    //First check selectedItem is a last empty row
                    if (selectedIndex === -1) {
                        //Copied NonGroup Only
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                const gid = lastItem.groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const sid = lastItem.subGroupId;
                                const sgname = lastItem.subGroupName;
                                const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                                const list = [];

                                itemListNew.forEach((item) => {
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        //Copied Group Only
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;
                                let pid = "";
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowType = "";
                                let glname = "";

                                const list = [];
                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sgid = item.rowType === 'G' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                    rowType = item.rowType === 'G' ? "SG" : item.rowType;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        displaySubGroupName: displaysgname,
                                        subGroupName: subGroup.subGroupName,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowType,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //    setSelectedItems([]);
                            }
                        }
                        //Copied SubGroup Only
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";


                                const list = [];
                                itemListNew.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                let updatedList = [
                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup + Group
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let glname = "";
                                const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];


                                copiedlistWithoutNonGroup.forEach((item) => {
                                    const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup + SubGroup
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {

                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let gCount = groupCount;
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        displayGroupName: displayName,
                                        displaySubGroupName: "",
                                        subGroupName: "",
                                        subGroupId: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        rowType: rowtype,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                // let pid = "";    
                                let sid = "";
                                //  let sgname="";
                                // let displaysgname="";
                                let rowtype = "";
                                let nongrpid = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];

                                const gid = lastItem.groupId;
                                const gname = lastItem.groupName;
                                let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                                let sgid = "";
                                let sgname = "";
                                let displaysgname = "";

                                copiedlistWithoutNonGroup.forEach((item) => {
                                    sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    pid = item.rowType === 'SG' ? pid : uuidv4();
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        //   groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        // groupLevelName:""
                                        displaySubGroupName: displaysgname,
                                        subGroupName: sgname,
                                        subGroupId: sgid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: lastItem.groupId,
                                            groupName: lastItem.groupName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied NonGroup+Group+SubGroup
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                let grpid = "";
                                let pid = "";
                                let nongrpid = "";
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelname: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...NonGroupItems,
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                        //Copied Group+SubGroup
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {

                                let grpid = "";
                                let pid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                    const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        groupLevelName: groupLevelname,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });



                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);


                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;

                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                        //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                            //Last Item in NonGroup
                            if (lastItemInNonGroup) {
                                let nongrpid = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);

                            }
                            //Last Item in SubGroup
                            if (lastItemInSubGroup) {
                                const GrpId = lastItem.groupId;
                                const GrpName = lastItem.groupName;
                                const SubGrpId = lastItem.subGroupId;
                                const SubGrpName = lastItem.subGroupName;
                                const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = [];
                                itemListNew.forEach((item) => {

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: GrpId,
                                        groupName: GrpName,
                                        displayGroupName: "",
                                        subGroupId: SubGrpId,
                                        subGroupName: SubGrpName,
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentIdforNon
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Last Item in Group
                            if (lastItemInGroup) {
                                const GrpId = lastItem.groupId;
                                const GrpName = lastItem.groupName;
                                const SubGrpId = lastItem.subGroupId;
                                const SubGrpName = lastItem.subGroupName;
                                const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                                const list = [];
                                itemListNew.forEach((item) => {

                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: GrpId,
                                        groupName: GrpName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentIdforNon
                                    });
                                });

                                let updatedList = [

                                    ...updatelist.slice(0, itemList.length - 1),
                                    ...list,


                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                        }
                    }
                    else {
                        //non group items copy and paste in last index
                        //  if (itemListNew.every(item => item.groupName === 'Non Grouped Items')) {
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            // if (isLastItemNonGroup) {
                            let nonGrpId = "";
                            const list = itemListNew.map((item) => {
                                nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nonGrpId,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    //     subItems: null,
                                    columns: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, copiedItemListFirstIndex),
                                ...list,
                                ...updatelist.slice(copiedItemListFirstIndex),
                            ];

                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                            setSelectedIndex(copiedItemListFirstIndex);

                            //}
                            //group only
                            // else if(isLastItemHasOnlyGroup){
                            //     const list = itemListNew.map((item) => {
                            //         return {
                            //             ...item,
                            //             rowId: uuidv4(),
                            //             groupId: lastItem.groupId,
                            //             groupName: "",
                            //             displayGroupName: "",
                            //             subGroupId: "",
                            //             subGroupName: "",
                            //             displaySubGroupName: "", 
                            //             id:uuidv4(),
                            //             parentId :lastItem.groupId,
                            //        //     subItems: null,
                            //             columns: null
                            //         };
                            //     });
                            //     let updatedList = [
                            //         // ...updatelist.slice(0,itemList.length-1),
                            //         // ...list
                            //         ...updatelist.slice(0,copiedItemListFirstIndex),
                            //         ...list,
                            //         ...updatelist.slice(copiedItemListFirstIndex),
                            //     ];
                            //     updatedList = itemPositioning(true,updatedList);
                            //     setItemList(updatedList);
                            //     updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            //     setComputeTotals(true);
                            //     setSelectedItems([]);

                            // }
                            // else if(isLastItemSubGroup){
                            //     const list = itemListNew.map((item) => {
                            //         return {
                            //             ...item,
                            //             rowId: uuidv4(),
                            //             groupId: lastItem.groupId,
                            //             groupName: lastItem.groupName,
                            //             displayGroupName: "",
                            //             subGroupId: lastItem.subGroupId,
                            //             subGroupName: lastItem.subGroupName,
                            //             displaySubGroupName: "", 
                            //             id:uuidv4(),
                            //             parentId :lastItem.subGroupId,
                            //          //   subItems: null,
                            //             columns: null
                            //         };
                            //     });
                            //     let updatedList = [
                            //         // ...updatelist.slice(0,itemList.length-1),
                            //         // ...list
                            //         ...updatelist.slice(0,copiedItemListFirstIndex),
                            //         ...list,
                            //         ...updatelist.slice(copiedItemListFirstIndex),
                            //     ];
                            //     updatedList = itemPositioning(true,updatedList);
                            //     setItemList(updatedList);
                            //     updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            //     setComputeTotals(true);
                            //     setSelectedItems([]);

                            // }
                            // else if(isLastItemNonSubGroup){
                            //     const list = itemListNew.map((item) => {
                            //         return {
                            //             ...item,
                            //             rowId: uuidv4(),
                            //             groupId: "",
                            //             groupName:"",
                            //             displayGroupName: "",
                            //             subGroupId: "",
                            //             subGroupName: "",
                            //             displaySubGroupName: "", 
                            //             id:uuidv4(),
                            //             parentId:null,
                            //         //    subItems: null,
                            //             columns: null
                            //         };
                            //     });
                            //     //.slice(0,updatelist.length)
                            //     let updatedList = [
                            //         ...updatelist.slice(0,copiedItemListFirstIndex),
                            //         ...list,
                            //         ...updatelist.slice(copiedItemListFirstIndex),
                            //         // ...updatelist.slice(0,itemList.length-1),
                            //         // ...list
                            //     ];
                            //     updatedList = itemPositioning(true,updatedList);
                            //     setItemList(updatedList);
                            //     updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            //     setComputeTotals(true);
                            //     setSelectedItems([]);

                            // }
                            // else{
                            //     toast.error("Try again!", {
                            //         position: "top-center",
                            //         transition: Slide,
                            //     });        
                            //     return;
                            // }
                        }
                        //GroupOnly
                        //if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId === '' && item.groupName !== 'Non Grouped Items')){ 
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                            let grpid = "";
                            let pid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //  subItems: null,
                                    columns: null,
                                    listPrice: item.listPrice,
                                    totalPrice: item.totalPrice
                                };
                            });

                            const updatedList1 = [
                                ...updatelist.slice(0, copiedItemListFirstIndex),
                                ...list,
                                ...updatelist.slice(copiedItemListFirstIndex),
                            ];
                            const updatedList = itemPositioning(true, updatedList1);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            // setSelectedItems([]);

                        }
                        //subGroupOnly
                        // if(itemListNew.every((item) => item.rowType !== 'G' && item.rowType === 'SG' && item.groupName !== 'Non Grouped Items')){
                        if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            //arrow showing last empty row   

                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of itemListNew) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }

                            if (uniqueGroupIdsCount > 1) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {

                                let grpid = "";
                                let prid = "";
                                let displayName = "";
                                let displayGName = "";
                                const lastOne = itemList[itemList.length - 2];
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    prid = item.rowType === 'SG' ? copiedItemsListFirstItem[0]?.parentId : uuidv4();
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    displayGName = item.rowType === 'G' ? item.displaySubGroupName : "";
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: copiedItemsListFirstItem[0]?.groupId,
                                        displayGroupName: "",
                                        displaySubGroupName: displayName,
                                        id: uuidv4(),
                                        subGroupId: grpid,
                                        parentId: prid,
                                        //    subItems: null,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),];
                                //  updatedList = [...updatelist.slice(0,itemList.length-1),...list];


                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);        
                            }

                        }
                        //NonGroup + Group
                        //if(itemListNew.every((item) => item.groupId !== '' && item.subGroupId === '' && item.groupName === 'Non Grouped Items')){
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {

                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, copiedItemListFirstIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(copiedItemListFirstIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);


                        }
                        //NonGroup + SubGroup
                        //  if(itemListNew.every((item) => item.rowType !== 'G' || item.rowType === 'SG' || item.groupName === 'Non Grouped Items')){
                        if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {

                            if (copiedItemsListFirstItem[0]?.rowType === 'SG') {

                                let Subgrpid = "";
                                let pid = "";
                                const GrpId = copiedItemsListFirstItem[0]?.groupId;
                                const GrpName = copiedItemsListFirstItem[0]?.groupName;
                                const parentIdforNon = copiedItemsListFirstItem[0]?.parentId;

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const groupIdSet = new Set();
                                let uniqueGroupIdsCount = 0;

                                for (const item of copiedlistWithoutNonGroup) {
                                    if (item.groupId && !groupIdSet.has(item.groupId)) {
                                        groupIdSet.add(item.groupId);
                                        uniqueGroupIdsCount++;
                                    }
                                }

                                if (uniqueGroupIdsCount > 1) {
                                    //selected multiple sub group in different groups
                                    toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    return;
                                }
                                else {
                                    const list = [];
                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        pid = item.rowType === 'SG' ? copiedItemsListFirstItem[0]?.parentId : pid;
                                        const displeSubGroupName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        Subgrpid = item.rowType === 'SG' ? uuidv4() : Subgrpid;

                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: item.groupId,
                                            displaySubGroupName: displeSubGroupName,
                                            subGroupId: Subgrpid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            columns: null
                                        });
                                    });

                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => ({
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: GrpId,
                                            groupName: GrpName,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: parentIdforNon

                                        }));

                                    let updatedList = [

                                        ...updatelist.slice(0, copiedItemListFirstIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(copiedItemListFirstIndex),

                                    ];
                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }


                            }
                            else if (copiedItemsListFirstItem[0]?.groupName === 'Non Grouped Items') {
                                let grpid = "";
                                let pid = "";
                                let nonGrpId = "";

                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');
                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    pid = item.rowType === 'SG' ? null : uuidv4();
                                    const displeGroupName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const groupLevelname = item.rowType === 'SG' ? item.groupLevelName : "";
                                    const rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        displayGroupName: displeGroupName,
                                        //  groupLevelname:groupLevelname,
                                        displaySubGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        id: uuidv4(),
                                        rowType: rowtype,
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });

                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nonGrpId,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        };
                                    });

                                let updatedList = [

                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),

                                ];
                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }


                        }
                        //NonGroup + Group +SubGroup
                        //if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId !== '' && item.groupName === 'Non Grouped Items')){
                        if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {

                            let grpid = "";
                            let pid = "";
                            let nonGrpId = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nonGrpId,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    };
                                });

                            let updatedList = [

                                ...updatelist.slice(0, copiedItemListFirstIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(copiedItemListFirstIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                        //Group + SubGroup
                        // if(itemListNew.find((item) => item.groupId !== '' && item.subGroupId !== '' && item.groupName !== 'Non Grouped Items')){
                        if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {

                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //  subItems: null,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });

                                const updatedList1 = [
                                    ...updatelist.slice(0, copiedItemListFirstIndex),
                                    ...list,
                                    ...updatelist.slice(copiedItemListFirstIndex),
                                ];
                                const updatedList = itemPositioning(true, updatedList1);
                                setItemList(updatedList);
                                // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }
                        }
                        //nongroupitem+SubGroupItem+GroupItem (without group level or subgroup level)
                        if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {

                            const GrpId = copiedItemsListFirstItem[0]?.groupId;
                            const GrpName = copiedItemsListFirstItem[0]?.groupName;
                            const SubGrpId = copiedItemsListFirstItem[0]?.subGroupId;
                            const SubGrpName = copiedItemsListFirstItem[0]?.subGroupName;
                            const parentIdforNon = copiedItemsListFirstItem[0]?.parentId;


                            // console.log("this is listOfSubGroupItems :",listOfSubGroupItems);
                            // console.log("this is listOfGroupItems :",listOfGroupItems);

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: GrpId,
                                    groupName: GrpName,
                                    displayGroupName: "",
                                    subGroupId: SubGrpId,
                                    subGroupName: SubGrpName,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: parentIdforNon,
                                    //     subItems: null,
                                    columns: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, copiedItemListFirstIndex),
                                ...list,
                                ...updatelist.slice(copiedItemListFirstIndex),
                            ];

                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                            setSelectedIndex(copiedItemListFirstIndex);


                        }
                    }


                }
            }

            if (selectedItems[0] !== undefined && updatelist.length > 1) {

                pasteIndex = updatelist.findIndex((item) => item.rowId === selectedPasteItem.rowId);
                if (pasteIndex === -1) {
                    pasteIndex = updatelist.length - 1
                }


                //DB Extra Validation
                const copiedItemListFirstIndex = selectedIndex;
                const copiedItemsListFirstItem = itemListNew.filter((item) => item.position == selectedIndex + 1);

                const hasDXInCopiedDataList = itemListNew.some((item) => item.rowType === 'DX');
                const listOfNonGroupItems = itemList.filter(item => item.groupName === 'Non Grouped Items' && copiedItemsListFirstItem[0]?.subGroupId === '');
                const listOfSubGroupItems = itemList.filter(item => item.subGroupId === copiedItemsListFirstItem[0]?.subGroupId && copiedItemsListFirstItem[0]?.subGroupId !== '');
                const listOfGroupItems = itemList.filter(item => item.groupId === copiedItemsListFirstItem[0]?.groupId && item.subGroupId === '');
                //nongroup items
                if (listOfNonGroupItems.length > 0 && !itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                    if (listOfNonGroupItems.filter(item => item.rowType === 'DX').length > 0 && hasDXInCopiedDataList) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                //subgroup item
                if (listOfSubGroupItems.length > 0 && !itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                    if (listOfSubGroupItems.filter(item => item.rowType === 'DX').length > 0 && hasDXInCopiedDataList) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
                //group item
                if (listOfGroupItems.length > 0 && !itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                    if (listOfGroupItems.filter(item => item.rowType === 'DX').length > 0 && hasDXInCopiedDataList) {
                        toast.error("Already has a DB Extra. Cannot Paste . Please Try again !.", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }

                //newly added
                const isSelectLastEmptyRow = itemList.length > 0 && itemList[itemList.length - 1].rowType === '';
                //.stStockCode === '' && itemList[itemList.length - 1].rowType !== 'G' && itemList[itemList.length - 1].rowType !== 'SG' ;
                const copiedItemListHasNonGroup = itemListNew.find((item) => item.groupName === 'Non Grouped Items');
                const copiedItemListHasGroup = itemListNew.find((item) => item.rowType === 'G');
                const copiedItemListHasSubGroup = itemListNew.find((item) => item.rowType === 'SG');

                const lastItem = itemList[itemList.length - 2];
                const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                const subGroupNameContains = 'Non Sub Group';
                const isLastItemNonSubGroup = lastItem && lastItem.subGroupName.includes(subGroupNameContains);



                //First check selectedItem is a last empty row
                if (selectedItems[0].rowType === '') {
                    //Copied NonGroup Only
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            const gid = lastItem.groupId;
                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = lastItem.subGroupId;
                            const sgname = lastItem.subGroupName;
                            const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                            const list = [];

                            itemListNew.forEach((item) => {
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                });
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }
                    //Copied Group Only
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            let grpid = "";
                            let pid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    columns: null,
                                    listPrice: item.listPrice,
                                    totalPrice: item.totalPrice
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;
                            let pid = "";
                            let sgid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowType = "";
                            let glname = "";

                            const list = [];
                            itemListNew.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sgid = item.rowType === 'G' ? uuidv4() : sgid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                rowType = item.rowType === 'G' ? "SG" : item.rowType;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    displaySubGroupName: displaysgname,
                                    subGroupName: subGroup.subGroupName,
                                    subGroupId: sgid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowType,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            const updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,
                                ...updatelist.slice(itemList.length - 1),
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //    setSelectedItems([]);
                        }
                    }
                    //Copied SubGroup Only
                    if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                            let sgid = "";
                            let sgname = "";
                            let displaysgname = "";


                            const list = [];
                            itemListNew.forEach((item) => {
                                sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                pid = item.rowType === 'SG' ? pid : uuidv4();
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    //   groupLevelName: gdname,
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    // groupLevelName:""
                                    displaySubGroupName: displaysgname,
                                    subGroupName: sgname,
                                    subGroupId: sgid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }
                    //Copied NonGroup + Group
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            let grpid = "";
                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let glname = "";
                            const parent = lastItem.parentId === null ? lastItem.id : lastItem.parentId;

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];


                            copiedlistWithoutNonGroup.forEach((item) => {
                                const subGroup = createSubGroupRow(lastItem.groupId, groupCount, uuidv4(), subGroupCount, lastItem.id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? parent : uuidv4();
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: lastItem.groupId,
                                    groupName: lastItem.groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                    }
                    //Copied NonGroup + SubGroup
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {

                            let nongrpid = "";

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            let grpid = "";
                            // let pid = "";    
                            let sid = "";
                            //  let sgname="";
                            // let displaysgname="";
                            let rowtype = "";
                            let nongrpid = "";

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];

                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            let pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;
                            let sgid = "";
                            let sgname = "";
                            let displaysgname = "";

                            copiedlistWithoutNonGroup.forEach((item) => {
                                sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                pid = item.rowType === 'SG' ? pid : uuidv4();
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    //   groupLevelName: gdname,
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    // groupLevelName:""
                                    displaySubGroupName: displaysgname,
                                    subGroupName: sgname,
                                    subGroupId: sgid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: lastItem.groupId,
                                        groupName: lastItem.groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: lastItem.parentId === null ? lastItem.id : lastItem.parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                    }
                    //Copied NonGroup+Group+SubGroup
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...NonGroupItems,
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                    }
                    //Copied Group+SubGroup
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {

                            let grpid = "";
                            let pid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelName: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });



                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);


                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;

                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                    }
                    //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                    if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            let nongrpid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);

                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            const GrpId = lastItem.groupId;
                            const GrpName = lastItem.groupName;
                            const SubGrpId = lastItem.subGroupId;
                            const SubGrpName = lastItem.subGroupName;
                            const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                            const list = [];
                            itemListNew.forEach((item) => {

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: GrpId,
                                    groupName: GrpName,
                                    displayGroupName: "",
                                    subGroupId: SubGrpId,
                                    subGroupName: SubGrpName,
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: parentIdforNon
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const GrpId = lastItem.groupId;
                            const GrpName = lastItem.groupName;
                            const SubGrpId = lastItem.subGroupId;
                            const SubGrpName = lastItem.subGroupName;
                            const parentIdforNon = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                            const list = [];
                            itemListNew.forEach((item) => {

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: GrpId,
                                    groupName: GrpName,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: parentIdforNon
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,


                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                    }
                }
                //if select first value
                if (selectedItems[0].rowType !== '' && selectedItems[0].position === 1) {
                    const selectNonGroupItem = selectedItems[0].groupName === 'Non Grouped Items';
                    const selectGroupHeader = selectedItems[0].rowType === 'G';

                    //Copied NonGroup Only
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {

                        let nongrpid = "";
                        const list = itemListNew.map((item) => {
                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                            return {
                                ...item,
                                rowId: uuidv4(),
                                groupId: nongrpid,
                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: null,
                                position: null
                            };
                        });
                        let updatedList = [
                            ...updatelist.slice(0, pasteIndex),
                            ...list,
                            ...updatelist.slice(pasteIndex),

                        ];
                        updatedList = itemPositioning(true, updatedList);
                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);
                        //  setSelectedItems([]);


                    }
                    //Copied Group Only
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        let grpid = "";
                        let pid = "";
                        const list = itemListNew.map((item) => {
                            grpid = item.rowType === 'G' ? uuidv4() : grpid;
                            pid = item.rowType === 'G' ? null : uuidv4();
                            return {
                                ...item,
                                rowId: uuidv4(),
                                groupId: grpid,
                                id: uuidv4(),
                                parentId: pid,
                                columns: null,
                                listPrice: item.listPrice,
                                totalPrice: item.totalPrice
                            };
                        });
                        let updatedList = [
                            ...updatelist.slice(0, pasteIndex),
                            ...list,
                            ...updatelist.slice(pasteIndex),
                        ];
                        updatedList = itemPositioning(true, updatedList);
                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);
                        //   setSelectedItems([]);

                    }
                    //Copied SubGroup Only
                    if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {

                        let grpid = "";
                        let pid = "";
                        let gid = "";
                        let gdname = "";
                        let displayName = "";
                        let rowtype = "";
                        let gCount = groupCount;
                        const list = [];
                        itemListNew.forEach((item) => {
                            grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                            gid = item.rowType === 'SG' ? uuidv4() : gid;
                            pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                            gdname = item.rowType === 'SG' ? item.subGroupName : "";
                            displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                            rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                            const group = createGroupRow(uuidv4(), groupCount);
                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupLevelName: gdname,
                                groupId: gid,
                                displayGroupName: displayName,
                                displaySubGroupName: "",
                                subGroupName: "",
                                subGroupId: "",
                                id: uuidv4(),
                                parentId: pid,
                                rowType: rowtype,
                                //     subItems: null,
                                columns: null
                            });
                        });

                        let updatedList = [
                            ...updatelist.slice(0, pasteIndex),
                            ...list,
                            ...updatelist.slice(pasteIndex)
                        ];

                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //  setSelectedItems([]);
                    }
                    //Copied NonGroup + Group
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        let grpid = "";
                        let pid = "";
                        let nongrpid = "";
                        const copiedlistWithoutNonGroup = itemListNew
                            .filter((item) => item.groupName !== 'Non Grouped Items');
                        const list = [];
                        copiedlistWithoutNonGroup.forEach((item) => {
                            grpid = item.rowType === 'G' ? uuidv4() : grpid;
                            pid = item.rowType === 'G' ? null : uuidv4();
                            const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                            const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupId: grpid,
                                displayGroupName: displeGroupName,
                                groupLevelname: groupLevelname,
                                id: uuidv4(),
                                parentId: pid,
                                //    subItems: null,
                                columns: null
                            });
                        });

                        const NonGroupItems = itemListNew
                            .filter((item) => item.groupName === 'Non Grouped Items')
                            .map((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null

                                }
                            });

                        let updatedList = [

                            ...updatelist.slice(0, pasteIndex),
                            ...NonGroupItems,
                            ...list,
                            ...updatelist.slice(pasteIndex)

                        ];
                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //  setSelectedItems([]);

                    }
                    //Copied NonGroup + SubGroup
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        let grpid = "";
                        let pid = "";
                        let gid = "";
                        let gdname = "";
                        let displayName = "";
                        let rowtype = "";
                        let nongrpid = "";
                        let gCount = groupCount;
                        const copiedlistWithoutNonGroup = itemListNew
                            .filter((item) => item.groupName !== 'Non Grouped Items');

                        const list = [];
                        copiedlistWithoutNonGroup.forEach((item) => {
                            grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                            gid = item.rowType === 'SG' ? uuidv4() : gid;
                            pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                            gdname = item.rowType === 'SG' ? item.subGroupName : "";
                            displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                            rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                            const group = createGroupRow(uuidv4(), groupCount);
                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupLevelName: gdname,
                                groupId: gid,
                                displayGroupName: displayName,
                                displaySubGroupName: "",
                                subGroupName: "",
                                subGroupId: "",
                                id: uuidv4(),
                                parentId: pid,
                                rowType: rowtype,
                                //     subItems: null,
                                columns: null
                            });
                        });
                        const NonGroupItems = itemListNew
                            .filter((item) => item.groupName === 'Non Grouped Items')
                            .map((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null

                                }
                            });


                        let updatedList = [

                            ...updatelist.slice(0, pasteIndex),
                            ...NonGroupItems,
                            ...list,
                            ...updatelist.slice(pasteIndex)

                        ];

                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //  setSelectedItems([]);

                    }
                    //Copied NonGroup+Group+SubGroup
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        const filterSubGroupsIncopiedlist = itemListNew
                            .filter((item) => item.subGroupId !== '');
                        const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                        const groupIdSet = new Set();
                        let uniqueGroupIdsCount = 0;

                        for (const item of filterSubGroupsIncopiedlist) {
                            if (item.groupId && !groupIdSet.has(item.groupId)) {
                                groupIdSet.add(item.groupId);
                                uniqueGroupIdsCount++;
                            }
                        }
                        if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                            //selected multiple sub group in different groups
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let gCount = groupCount;
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                sid = item.rowType === 'SG' ? uuidv4() : sid;
                                sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    // groupLevelName: gdname,
                                    // groupId:gid,
                                    // displayGroupName:displayName,
                                    // displaySubGroupName:"",
                                    // subGroupName:"",
                                    // subGroupId:"",  
                                    id: uuidv4(),
                                    // parentId: pid,                           
                                    //     subItems: null,
                                    columns: null
                                });
                            });
                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });


                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }
                    //Copied Group+SubGroup
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        const filterSubGroupsIncopiedlist = itemListNew
                            .filter((item) => item.subGroupId !== '');
                        const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                        const groupIdSet = new Set();
                        let uniqueGroupIdsCount = 0;

                        for (const item of filterSubGroupsIncopiedlist) {
                            if (item.groupId && !groupIdSet.has(item.groupId)) {
                                groupIdSet.add(item.groupId);
                                uniqueGroupIdsCount++;
                            }
                        }
                        if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                            //selected multiple sub group in different groups
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }
                        else {

                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let sid = "";
                            let sgname = "";
                            let displayGName = "";
                            let gCount = groupCount;
                            let gname = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'G' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : item.rowType === 'G' ? item.groupName : "";
                                displayGName = item.rowType === 'G' ? item.displayGroupName : "";
                                sid = item.rowType === 'SG' ? uuidv4() : sid;
                                sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                gname = item.rowType === 'G' ? item.groupName : gname;
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: displayGName,
                                    displaySubGroupName: displayName,
                                    subGroupName: sgname,
                                    subGroupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //     subItems: null,
                                    columns: null
                                });
                            });


                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                    }
                    //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                    if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                        let nongrpid = "";
                        const list = [];
                        itemListNew.forEach((item) => {
                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                            list.push({
                                ...item,
                                rowId: uuidv4(),
                                groupId: nongrpid,
                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                columns: null,
                                //    subItems:null,
                                id: uuidv4(),
                                parentId: null
                            });
                        });

                        let updatedList = [

                            ...updatelist.slice(0, pasteIndex),
                            ...list,
                            ...updatelist.slice(pasteIndex),

                        ];
                        const updatedList1 = itemPositioning(true, updatedList);
                        setItemList(updatedList1);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                        setComputeTotals(true);
                        //  setSelectedItems([]);

                    }
                }
                //selectedItem is not a last empty row
                else {
                    const selectNonGroupItem = selectedItems[0].groupName === 'Non Grouped Items';
                    const selectGroupItem = selectedItems[0].groupId !== '' && selectedItems[0].subGroupId === '' && selectedItems[0].groupName !== 'Non Grouped Items' && selectedItems[0].rowType !== 'G';
                    const selectSubGroupItem = selectedItems[0].subGroupId !== '' && selectedItems[0].rowType !== 'SG';
                    const selectGroupHeader = selectedItems[0].rowType === 'G';
                    const selectSubGroupHeader = selectedItems[0].rowType === 'SG';

                    const beforeItem = itemList.filter((item) => item.position == pasteIndex);
                    const beforeItemNonGroupItem = beforeItem[0].groupName === 'Non Grouped Items';
                    const beforeItemGroupItem = beforeItem[0].groupId !== '' && beforeItem[0].subGroupId === '' && beforeItem[0].groupName !== 'Non Grouped Items' && beforeItem[0].rowType !== 'G';
                    const beforeItemSubGroupItem = beforeItem[0].subGroupId !== '' && beforeItem[0].rowType !== 'SG';
                    const beforeItemGroupHeader = beforeItem[0].rowType === 'G';
                    const beforeItemSubGroupHeader = beforeItem[0].rowType === 'SG';

                    //Copied NonGroup Only
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            const gid = selectedItems[0].groupId;
                            const list = itemListNew.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            console.log("select  group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: uuidv4(),
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: uuidv4(),
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectSubGroupHeader) {
                            console.log("select sub group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].id;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectGroupItem) {
                            console.log("select  group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].id;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) { }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) { }
                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].id;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].parentId;
                                const list = itemListNew.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }

                    }

                    //Copied Group Only
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            let grpid = "";
                            let pid = "";
                            const list = itemListNew.map((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    id: uuidv4(),
                                    parentId: pid,
                                    columns: null,
                                    listPrice: item.listPrice,
                                    totalPrice: item.totalPrice
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),
                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            console.log("select  group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                let grpid = "";
                                let pid = "";
                                const list = itemListNew.map((item) => {
                                    grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                    pid = item.rowType === 'G' ? null : uuidv4();
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        columns: null,
                                        listPrice: item.listPrice,
                                        totalPrice: item.totalPrice
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),
                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectSubGroupHeader) {
                            console.log("select sub group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectGroupItem) {
                            console.log("select  group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {

                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) { }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                let pid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let rowtype = "";
                                let glname = "";

                                const list = [];

                                itemListNew.forEach((item) => {
                                    const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                    sid = item.rowType === 'G' ? uuidv4() : sid;
                                    sgname = item.rowType === 'G' ? item.groupName : sgname;
                                    displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                    rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                    pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                    glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        groupLevelName: glname,
                                        subGroupId: sid,
                                        subGroupName: subGroup.subGroupName,
                                        displaySubGroupName: displaysgname,
                                        rowType: rowtype,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //    subItems: null,
                                        columns: null
                                    });
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) { }
                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }

                    }

                    //Copied SubGroup Only
                    if (!copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex)
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            console.log("select  group header item")
                            //However it pasting like as a group
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let gCount = groupCount;
                            const list = [];
                            itemListNew.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });

                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex)
                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectSubGroupHeader) {
                            console.log("select sub group header item")

                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {

                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    // setSelectedItems([]);
                                }
                            }

                        }
                        if (selectGroupItem) {
                            console.log("select  group item")
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);

                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) { }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    itemListNew.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });

                                    let updatedList = [
                                        ...updatelist.slice(0, pasteIndex),
                                        ...list,
                                        ...updatelist.slice(pasteIndex)
                                    ];

                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) { }

                            }

                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                    }

                    //Copied NonGroup + Group
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && !copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            console.log("select  group header item")
                            //However copied nongroups paste in as non group and other copied groups paste as group
                            let grpid = "";
                            let pid = "";
                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');
                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'G' ? uuidv4() : grpid;
                                pid = item.rowType === 'G' ? null : uuidv4();
                                const displeGroupName = item.rowType === 'G' ? item.displayGroupName : "";
                                const groupLevelname = item.rowType === 'G' ? item.groupLevelName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: grpid,
                                    displayGroupName: displeGroupName,
                                    groupLevelname: groupLevelname,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectSubGroupHeader) {
                            console.log("select sub group header item")
                            //however copied nongroup items paste into group and copied groups paste as subgroups

                            let nongrpid = "";

                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";
                            const parentId = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].rowType === 'SG' ? beforeItem[0].id : beforeItem[0].parentId;
                            const list = [];

                            copiedlistWithoutNonGroup.forEach((item) => {
                                const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: beforeItem[0].groupId,
                                    groupName: beforeItem[0].groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            // setSelectedItems([]);
                        }
                        if (selectGroupItem) {
                            console.log("select  group item")

                            let nongrpid = "";
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            let pid = "";
                            let sid = "";
                            let sgname = "";
                            let displaysgname = "";
                            let rowtype = "";
                            let glname = "";
                            const parentId = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].rowType === 'SG' ? beforeItem[0].id : beforeItem[0].parentId;
                            const list = [];

                            copiedlistWithoutNonGroup.forEach((item) => {
                                const subGroup = createSubGroupRow(beforeItem[0].groupId, groupCount, uuidv4(), subGroupCount, beforeItem[0].id);
                                sid = item.rowType === 'G' ? uuidv4() : sid;
                                sgname = item.rowType === 'G' ? item.groupName : sgname;
                                displaysgname = item.rowType === 'G' ? item.displayGroupName : "";
                                rowtype = item.rowType === 'G' ? 'SG' : item.rowType;
                                pid = item.rowType === 'G' || item.rowType === 'SG' ? uuidv4() : pid;
                                glname = item.rowType === 'G' || item.rowType === 'SG' ? subGroup.subGroupName : "";
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: beforeItem[0].groupId,
                                    groupName: beforeItem[0].groupName,
                                    displayGroupName: "",
                                    groupLevelName: glname,
                                    subGroupId: sid,
                                    subGroupName: subGroup.subGroupName,
                                    displaySubGroupName: displaysgname,
                                    rowType: rowtype,
                                    id: uuidv4(),
                                    parentId: pid,
                                    //    subItems: null,
                                    columns: null
                                });
                            });

                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: beforeItem[0].groupId,
                                        groupName: beforeItem[0].groupName,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: parentId

                                    }
                                });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }

                    }
                    //Copied NonGroup + SubGroup
                    if (copiedItemListHasNonGroup && !copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let gCount = groupCount;
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });
                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });


                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            let grpid = "";
                            let pid = "";
                            let gid = "";
                            let gdname = "";
                            let displayName = "";
                            let rowtype = "";
                            let nongrpid = "";
                            let gCount = groupCount;
                            const copiedlistWithoutNonGroup = itemListNew
                                .filter((item) => item.groupName !== 'Non Grouped Items');

                            const list = [];
                            copiedlistWithoutNonGroup.forEach((item) => {
                                grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                gid = item.rowType === 'SG' ? uuidv4() : gid;
                                pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                rowtype = item.rowType === 'SG' ? "G" : item.rowType;
                                const group = createGroupRow(uuidv4(), groupCount);
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupLevelName: gdname,
                                    groupId: gid,
                                    displayGroupName: displayName,
                                    displaySubGroupName: "",
                                    subGroupName: "",
                                    subGroupId: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    rowType: rowtype,
                                    //     subItems: null,
                                    columns: null
                                });
                            });
                            const NonGroupItems = itemListNew
                                .filter((item) => item.groupName === 'Non Grouped Items')
                                .map((item) => {
                                    nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: nongrpid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        columns: null,
                                        //    subItems:null,
                                        id: uuidv4(),
                                        parentId: null

                                    }
                                });


                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...NonGroupItems,
                                ...list,
                                ...updatelist.slice(pasteIndex)

                            ];

                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectSubGroupHeader) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {

                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);
                                }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                            }
                        }
                        if (selectGroupItem) {
                            console.log("select  group item")
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                //Before Item of selected index has NonGroupItem
                                if (beforeItemNonGroupItem) { }
                                //Before Item of selected index has GroupHeader
                                if (beforeItemGroupHeader) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //   setSelectedItems([]);

                                }
                                //Before Item of selected index has SubGroupHeader
                                if (beforeItemSubGroupHeader) { }
                                //Before Item of selected index has Group Item
                                if (beforeItemGroupItem) {
                                    const gid = beforeItem[0].groupId;
                                    const gname = beforeItem[0].groupName;
                                    let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                                    let sgid = "";
                                    let sgname = "";
                                    let displaysgname = "";


                                    const list = [];
                                    const copiedlistWithoutNonGroup = itemListNew
                                        .filter((item) => item.groupName !== 'Non Grouped Items');

                                    copiedlistWithoutNonGroup.forEach((item) => {
                                        sgid = item.rowType === 'SG' ? uuidv4() : sgid;
                                        sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                        displaysgname = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                        pid = item.rowType === 'SG' ? pid : uuidv4();
                                        list.push({
                                            ...item,
                                            rowId: uuidv4(),
                                            //   groupLevelName: gdname,
                                            groupId: gid,
                                            groupName: gname,
                                            displayGroupName: "",
                                            // groupLevelName:""
                                            displaySubGroupName: displaysgname,
                                            subGroupName: sgname,
                                            subGroupId: sgid,
                                            id: uuidv4(),
                                            parentId: pid,
                                            //     subItems: null,
                                            columns: null
                                        });
                                    });
                                    const NonGroupItems = itemListNew
                                        .filter((item) => item.groupName === 'Non Grouped Items')
                                        .map((item) => {
                                            //   nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                            return {
                                                ...item,
                                                rowId: uuidv4(),
                                                groupId: gid,
                                                groupName: gname,
                                                displayGroupName: "",
                                                subGroupId: "",
                                                subGroupName: "",
                                                displaySubGroupName: "",
                                                columns: null,
                                                //    subItems:null,
                                                id: uuidv4(),
                                                parentId: null

                                            }
                                        });
                                    let updatedList = [

                                        ...updatelist.slice(0, pasteIndex),
                                        ...NonGroupItems,
                                        ...list,
                                        ...updatelist.slice(pasteIndex)

                                    ];


                                    const updatedList1 = itemPositioning(true, updatedList);
                                    setItemList(updatedList1);
                                    updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                    setComputeTotals(true);
                                    //  setSelectedItems([]);
                                }
                                //Before Item of selected index has Sub Group Item
                                if (beforeItemSubGroupItem) { }

                            }

                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                toast.error("Sorry! The items copied have groups or sub-groups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                        }
                    }
                    //Copied NonGroup+Group+SubGroup
                    if (copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        // groupId:gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        // subGroupId:"",  
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);

                            }






                            console.log("select non group item");

                        }
                        if (selectGroupHeader) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displaysgname = "";
                                let gCount = groupCount;
                                const copiedlistWithoutNonGroup = itemListNew
                                    .filter((item) => item.groupName !== 'Non Grouped Items');

                                const list = [];
                                copiedlistWithoutNonGroup.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'SG' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : "";
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        // groupLevelName: gdname,
                                        // groupId:gid,
                                        // displayGroupName:displayName,
                                        // displaySubGroupName:"",
                                        // subGroupName:"",
                                        // subGroupId:"",  
                                        id: uuidv4(),
                                        // parentId: pid,                           
                                        //     subItems: null,
                                        columns: null
                                    });
                                });
                                const NonGroupItems = itemListNew
                                    .filter((item) => item.groupName === 'Non Grouped Items')
                                    .map((item) => {
                                        nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                                        return {
                                            ...item,
                                            rowId: uuidv4(),
                                            groupId: nongrpid,
                                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                            displayGroupName: "",
                                            subGroupId: "",
                                            subGroupName: "",
                                            displaySubGroupName: "",
                                            columns: null,
                                            //    subItems:null,
                                            id: uuidv4(),
                                            parentId: null

                                        }
                                    });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...NonGroupItems,
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectSubGroupHeader) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;

                        }
                        if (selectGroupItem) {

                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;


                        }
                        if (selectSubGroupItem) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                    }

                    //Copied Group+SubGroup
                    if (!copiedItemListHasNonGroup && copiedItemListHasGroup && copiedItemListHasSubGroup) {
                        if (selectNonGroupItem) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displayGName = "";
                                let gCount = groupCount;
                                let gname = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'G' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : item.rowType === 'G' ? item.groupName : "";
                                    displayGName = item.rowType === 'G' ? item.displayGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    gname = item.rowType === 'G' ? item.groupName : gname;
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: displayGName,
                                        displaySubGroupName: displayName,
                                        subGroupName: sgname,
                                        subGroupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                // setSelectedItems([]);

                            }






                            console.log("select non group item");

                        }
                        if (selectGroupHeader) {
                            const filterSubGroupsIncopiedlist = itemListNew
                                .filter((item) => item.subGroupId !== '');
                            const rowCountOfTypeG = itemListNew.filter(item => item.rowType === 'G').length;
                            const groupIdSet = new Set();
                            let uniqueGroupIdsCount = 0;

                            for (const item of filterSubGroupsIncopiedlist) {
                                if (item.groupId && !groupIdSet.has(item.groupId)) {
                                    groupIdSet.add(item.groupId);
                                    uniqueGroupIdsCount++;
                                }
                            }
                            if (uniqueGroupIdsCount > 1 && rowCountOfTypeG < 2) {
                                //selected multiple sub group in different groups
                                toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                                return;
                            }
                            else {
                                let grpid = "";
                                let pid = "";
                                let gid = "";
                                let gdname = "";
                                let displayName = "";
                                let rowtype = "";
                                let nongrpid = "";
                                let sid = "";
                                let sgname = "";
                                let displayGName = "";
                                let gCount = groupCount;
                                let gname = "";
                                const list = [];
                                itemListNew.forEach((item) => {
                                    grpid = item.rowType === 'SG' ? uuidv4() : grpid;
                                    gid = item.rowType === 'G' ? uuidv4() : gid;
                                    pid = item.rowType === 'G' ? null : item.rowType === 'SG' ? null : uuidv4();
                                    gdname = item.rowType === 'SG' ? item.subGroupName : item.rowType === 'G' ? item.groupName : "";
                                    displayGName = item.rowType === 'G' ? item.displayGroupName : "";
                                    sid = item.rowType === 'SG' ? uuidv4() : sid;
                                    sgname = item.rowType === 'SG' ? item.subGroupName : sgname;
                                    gname = item.rowType === 'G' ? item.groupName : gname;
                                    displayName = item.rowType === 'SG' ? item.displaySubGroupName : "";
                                    const group = createGroupRow(uuidv4(), groupCount);
                                    list.push({
                                        ...item,
                                        rowId: uuidv4(),
                                        groupLevelName: gdname,
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: displayGName,
                                        displaySubGroupName: displayName,
                                        subGroupName: sgname,
                                        subGroupId: grpid,
                                        id: uuidv4(),
                                        parentId: pid,
                                        //     subItems: null,
                                        columns: null
                                    });
                                });


                                let updatedList = [

                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex)

                                ];

                                const updatedList1 = itemPositioning(true, updatedList);
                                setItemList(updatedList1);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectSubGroupHeader) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;

                        }
                        if (selectGroupItem) {

                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;


                        }
                        if (selectSubGroupItem) {
                            toast.error("Sorry ! The items copied have groups or subGroups cannot fit to the current row", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                    }
                    //Copied NonGroupItem+GroupItem+SubGroupItem (without headers)
                    if (!itemListNew.some(item => ['G', 'SG'].includes(item.rowType))) {
                        if (selectNonGroupItem) {
                            let nongrpid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //   setSelectedItems([]);



                        }
                        if (selectGroupHeader) {
                            let nongrpid = "";
                            const list = [];
                            itemListNew.forEach((item) => {
                                nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;

                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: nongrpid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: null
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectSubGroupHeader) {
                            let gid = beforeItem[0].groupId;
                            let gname = beforeItem[0].groupName;
                            let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                            const list = [];
                            itemListNew.forEach((item) => {


                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: pid
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);

                        }
                        if (selectGroupItem) {

                            let gid = beforeItem[0].groupId;
                            let gname = beforeItem[0].groupName;
                            let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                            const list = [];
                            itemListNew.forEach((item) => {


                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: pid
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);


                        }
                        if (selectSubGroupItem) {
                            let gid = beforeItem[0].groupId;
                            let gname = beforeItem[0].groupName;
                            let sid = beforeItem[0].subGroupId;
                            let sgname = beforeItem[0].subGroupName;
                            let pid = beforeItem[0].rowType === 'G' ? beforeItem[0].id : beforeItem[0].parentId;
                            const list = [];
                            itemListNew.forEach((item) => {


                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    columns: null,
                                    //    subItems:null,
                                    id: uuidv4(),
                                    parentId: pid
                                });
                            });

                            let updatedList = [

                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            const updatedList1 = itemPositioning(true, updatedList);
                            setItemList(updatedList1);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList1 });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }

                    }
                }
            }
        }

        const itemsListValue = itemListNew;
        //ADD THE PROGRESS BAR


        setAllowCopy(false);
    };

    const handleAddParts = async () => {
        setIsQuoteSaved(false);
        setQuoteUpdated(true);
        const UserId = user?.homeAccountId;
        let systemSettings = null;
        //  console.log("Add Itemsss :",addItems)
        const stockItems = {
            lstStocks: addItems,
        };
        // if(selectedComponents.length>0){
        //     stockItems = {
        //         lstStocks: selectedComponents,
        //     };
        //     console.log("stockItems component :",stockItems)

        // }else {console.log("error components")}
        // setSelectedComponents([])
        setShowLoaderBtn(true);
        var url = `Stocks/getstockdetails?CustomerId=${customerId}&UserId=${UserId}`;
        //var url = "Stocks/getstockdetails";
        const responses = await postResponse(url, stockItems);
        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);

        const newItems = responses.flat().map((response) => {
            let dcOnLp =
                response.listPrice && response.nettEA
                    ? calculateItemDcOnLp(response.nettEA, response.listPrice)
                    : // parseFloat(
                    //       parseFloat(((response.listPrice - response.nettEA) / response.listPrice) * 100).toFixed(1)
                    //   )
                    0;
            let rowId = uuidv4();
            let totalPrice = response.repDisc
                ? parseFloat(parseFloat(response.repDisc).toFixed(2))
                : parseFloat(parseFloat(response.qty * response.nettEA).toFixed(2));
            let margin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.stEecost) / response.nettEA) * 100).toFixed(1));
            let actMargin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.actCostExact) / response.nettEA) * 100).toFixed(1));
            let frcMargin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.stFutureCost) / response.nettEA) * 100).toFixed(1));
            let totalCost = response.qty * response.stEecostRound;
            let groupDbExtraId = "";
            let subGroupDbExtraId = "";
            let nonGroupDbExtraId = "";
            const listPrice = response.listPrice === 0 ? '' : response.listPrice;
            const l60 = response.l60 === 0 ? '' : response.l60;
            const l55 = response.l55 === 0 ? '' : response.l55;
            const l50 = response.l50 === 0 ? '' : response.l50;
            const l20 = response.l20 === 0 ? '' : response.l20;
            return {
                ...response,
                dcOnLp,
                totalPrice,
                margin,
                frcMargin,
                actMargin,
                totalCost,
                rowId,
                groupDbExtraId,
                subGroupDbExtraId,
                nonGroupDbExtraId,
                rowType: response.type,
                groupName: "",
                groupId: "",
                subGroupName: "",
                subGroupId: "",
                displayGroupName: "",
                displaySubGroupName: "",
                displayAssemblyGroupName: "",
                assemblyGroupName: "",
                id: uuidv4(),
                parentId: null,
                listPrice: listPrice,
                l60: l60,
                l55: l55,
                l50: l50,
                l20: l20

            };
        });

        updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });

        //here
        if (selectedItems.length > 0) {
            let updatedItemList = [...itemList];
            const itemIndex = updatedItemList.findIndex((item) => item.rowId === selectedItems[0].rowId);
            const position = getRowPosition(updatedItemList, itemIndex);
            const {
                onAGroup,
                onASubGroup,
                onAGroupRow,
                onASubGroupRow,
                isAGroupRowItem,
                isASubGroupItem,
                isNonGroupedItem,
                groupDetails,
            } = position;
            const gId = groupDetails.prevGroupId;
            const sgId = groupDetails.prevSubGroupId;
            const gName = groupDetails.prevGroupName;
            const sgName = groupDetails.prevSubGroupName;
            const getPId = updatedItemList.filter((item) => item.rowId === selectedItems[0].rowId).at(-1);
            const parentId = getPId.rowType == "G"
                ? getPId.id
                : getPId.rowType == "SG"
                    ? getPId.id
                    : getPId.parentId
            if (onAGroup) {
                if (isAGroupRowItem) {
                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: parentId
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    let existingDbExtra = [];
                    let groupItems = [];
                    let groupDbExtraIndex = -1;
                    let groupDbExtra = -1;
                    let groupNettEA = -1;

                    if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                        existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                    } else {
                        existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    }

                    if (existingDbExtra.length > 0) {
                        // groupItems = getAllItemsInAGroup(updatedItemList, gId);
                        // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        // const updatedGroupDbExtraRow = {
                        //     ...existingDbExtra[0],
                        //     nettEA: groupDbExtra,
                        //     totalPrice: groupDbExtra,
                        // };

                        // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                        let groupListPrice = -1;
                        let groupstEecost = -1;
                        let groupStFutureCost = -1;
                        let groupActCostExact = -1;
                        let groupMargin = -1;
                        let groupFrcMargin = -1;
                        let groupActMargin = -1;
                        let groupTotalCost = -1;
                        let groupTotalPrice = -1;

                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        groupItems = getGroupedRowItems(updatedItemList, gId);

                        if (subGroupRows.length > 0) {
                            const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                                if (subGroupRow.qty > 1) {
                                    return {
                                        ...subGroupRow,
                                        nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                        // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                        listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                        stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                        stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                        actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                        stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                        stFutureCostRound: parseFloat(
                                            (subGroupRow.stFutureCostRound * subGroupRow.qty).toFixed(2)
                                        ),
                                        actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                        // lcMargin: "",
                                    };
                                }
                                return subGroupRow;
                            });

                            groupItems = [...groupItems, ...updatedSubGroupRows];
                        }

                        groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                        groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                        groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                        groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                        if (existingDbExtra.length > 0) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        const groupQty = groupRow.qty;
                        const hasGroupQtyChanged = groupQty > 1;

                        const updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            actCostExact: groupActCostExact,
                            stEecost: groupstEecost,
                            //  lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA: totalNettEAFooter,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEAFooter);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);


                    const lastItem = itemList.at(-1);
                    let updatedItemListing = [];
                    if (lastItem.stStockCode.length === 0 && (lastItem.rowType !== "A" && lastItem.rowType !== "G" && lastItem.rowType !== "SG")) {
                        updatedItemListing = updatedItemList.filter((item) => item.rowId !== lastItem.rowId);
                        updatedItemListing = itemPositioning(true, updatedItemListing);
                    } else updatedItemListing = itemPositioning(true, updatedItemList);

                    setItemList(updatedItemListing);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemListing });
                } else if (isASubGroupItem) {
                    const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                    let dbExtraValueSb = -1;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: parentId
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    if (existingDbExtra.length > 0) {
                        const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                        const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                        const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                        const updatedSubGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: dbExtraValueSb,
                            totalPrice: dbExtraValueSb,
                        };

                        updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                    }

                    //ref : sub group row calculation
                    let updatedSubGroupMargin = -1;
                    let updatedSubGroupFrcMargin = -1;
                    let updatedSubGroupActMargin = -1;

                    const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                    const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                    const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                    const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                    const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                    // const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                    const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupActCostExact
                        );
                    } else {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupActCostExact
                        );
                    }

                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                    const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                    const subGroupQty = subGroupRow.qty;

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        listPrice: updatedSubGroupListPrice,
                        stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                        stEecost: updatedSubGroupstEecost,
                        stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedSubGroupStFutureCost,
                        actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                        actCostExact: updatedSubGroupActCostExact,
                        nettEA: updatedSubGroupNettEA,
                        margin: updatedSubGroupMargin,
                        frcMargin: updatedSubGroupFrcMargin,
                        actMargin: updatedSubGroupActMargin,
                        // totalCost: updatedTotalCost * subGroupQty,
                        totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                        totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                        // lcMargin: "",
                    };

                    updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                    const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    let groupDbExtra = -1;

                    // if (existingGroupDbExtra.length > 0) {
                    //     const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    //     groupNettEA = calculateGroupWiseTotal(gItms, "nettEA");
                    //     groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    //     const updatedGroupDbExtraRow = {
                    //         ...existingGroupDbExtra[0],
                    //         nettEA: groupDbExtra,
                    //         totalPrice: groupDbExtra,
                    //     };

                    //     updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    // }

                    if (existingGroupDbExtra.length > 0) {
                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    //ref : group row calculation
                    let updatedGroupMargin = -1;
                    let updatedGroupFrcMargin = -1;
                    let updatedGroupActMargin = -1;

                    const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                    const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        if (subGroupRow.qty > 1) {
                            return {
                                ...subGroupRow,
                                nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                                actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                //  lcMargin: "",
                            };
                        }
                        return subGroupRow;
                    });

                    const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                    const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (groupLevelDbExtra.length > 0) {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupActCostExact
                        );
                    } else {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupActCostExact
                        );
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                    const groupQty = groupRow.qty;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: updatedGroupListPrice,
                        stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedGroupStFutureCost,
                        actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                        stEecost: updatedGroupstEecost,
                        actCostExact: updatedGroupActCostExact,
                        nettEA: updatedGroupNettEA,
                        margin: updatedGroupMargin,
                        actMargin: updatedGroupActMargin,
                        frcMargin: updatedGroupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                        totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                        // lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA: totalNettEAFooter,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEAFooter);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else {
                    const nonGroupId = nonGroupedId.current ? nonGroupedId.current : uuidv4();
                    nonGroupedId.current = nonGroupId;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: nonGroupId,
                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                            displayGroupName: "",
                            subGroupId: "",
                            subGroupName: "",
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: parentId
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    const existingNonGroupDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);

                    if (existingNonGroupDbExtra.length > 0) {
                        const nonGroupItems = getAllItemsInAGroup(updatedItemList, nonGroupId);
                        const nettEANG = calculateGroupWiseTotal(nonGroupItems, "nettEA");
                        const dbExtraValueNG = calculateDbExtra(dbExtraCharge, nettEANG);
                        const dBExtraIndexNG = getDbExtraRowIndex(updatedItemList, null, null, GroupTypes.NON_GROUP);

                        const updatedNonGroupDbExtraRow = {
                            ...existingNonGroupDbExtra[0],
                            nettEA: dbExtraValueNG,
                            totalPrice: dbExtraValueNG,
                        };

                        updatedItemList[dBExtraIndexNG] = updatedNonGroupDbExtraRow;
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA: totalNettEAFooter,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEAFooter);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    const lastItem = itemList.at(-1);
                    if (lastItem.stStockCode.length === 0 && (lastItem.row && lastItem.rowType !== "G" && lastItem.rowType !== "SG")) {
                        updatedItemList = updatedItemList.filter((item) => item.rowId !== lastItem.rowId);
                        updatedItemList = itemPositioning(true, updatedItemList);
                    } else updatedItemList = itemPositioning(true, updatedItemList);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                }
            } else if (onASubGroup) {
                if (isAGroupRowItem) {
                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: parentId
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    let existingDbExtra = [];
                    let groupItems = [];
                    let groupDbExtraIndex = -1;
                    let groupDbExtra = -1;
                    let groupNettEA = -1;

                    if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                        existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                    } else {
                        existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    }

                    if (existingDbExtra.length > 0) {
                        // const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        // const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        //     if (subGroupRow.qty > 1) {
                        //         return {
                        //             ...subGroupRow,
                        //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                        //         };
                        //     }
                        //     return subGroupRow;
                        // });
                        // groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        // const updatedGroupDbExtraRow = {
                        //     ...existingDbExtra[0],
                        //     nettEA: groupDbExtra,
                        //     totalPrice: groupDbExtra,
                        // };

                        // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                        let groupListPrice = -1;
                        let groupstEecost = -1;
                        let groupStFutureCost = -1;
                        let groupActCostExact = -1;
                        let groupMargin = -1;
                        let groupActMargin = -1;
                        let groupFrcMargin = -1;
                        let groupTotalCost = -1;
                        let groupTotalPrice = -1;

                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        groupItems = getGroupedRowItems(updatedItemList, gId);

                        if (subGroupRows.length > 0) {
                            const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                                if (subGroupRow.qty > 1) {
                                    return {
                                        ...subGroupRow,
                                        nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                        // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                        listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                        stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                        stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                        actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                        stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                        stFutureCostRound: parseFloat(
                                            (subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)
                                        ),
                                        actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                        //  lcMargin: "",
                                    };
                                }
                                return subGroupRow;
                            });

                            groupItems = [...groupItems, ...updatedSubGroupRows];
                        }

                        groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                        groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                        groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                        groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                        if (existingDbExtra.length > 0) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        const hasGroupQtyChanged = groupRow.qty > 1;
                        const groupQty = groupRow.qty;

                        const updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            // lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA: totalNettEAFooter,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEAFooter);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else if (isASubGroupItem) {
                    const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                    let dbExtraValueSb = -1;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    if (existingDbExtra.length > 0) {
                        const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                        const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                        const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                        const updatedSubGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: dbExtraValueSb,
                            totalPrice: dbExtraValueSb,
                        };

                        updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                    }

                    //ref : sub group row calculation
                    let updatedSubGroupMargin = -1;
                    let updatedSubGroupFrcMargin = -1;
                    let updatedSubGroupActMargin = -1;

                    const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                    const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                    const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                    const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                    const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                    const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                    const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupActCostExact
                        );
                    } else {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupActCostExact
                        );
                    }

                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                    const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                    const subGroupQty = subGroupRow.qty;

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        listPrice: updatedSubGroupListPrice,
                        stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                        stEecost: updatedSubGroupstEecost,
                        stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedSubGroupStFutureCost,
                        actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                        actCostExact: updatedSubGroupActCostExact,
                        nettEA: updatedSubGroupNettEA,
                        margin: updatedSubGroupMargin,
                        actMargin: updatedSubGroupActMargin,
                        frcMargin: updatedSubGroupFrcMargin,
                        // totalCost: updatedTotalCost * subGroupQty,
                        totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                        totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                        //  lcMargin: "",
                    };

                    updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                    const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    let groupDbExtra = -1;

                    if (existingGroupDbExtra.length > 0) {
                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    //ref : group row calculation
                    let updatedGroupMargin = -1;
                    let updatedGroupFrcMargin = -1;
                    let updatedGroupActMargin = -1;

                    const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                    const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        if (subGroupRow.qty > 1) {
                            return {
                                ...subGroupRow,
                                nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                                actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                //  lcMargin: "",
                            };
                        }
                        return subGroupRow;
                    });

                    const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                    const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (groupLevelDbExtra.length > 0) {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupActCostExact
                        );
                    } else {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupActCostExact
                        );
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                    const groupQty = groupRow.qty;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: updatedGroupListPrice,
                        stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedGroupStFutureCost,
                        actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                        stEecost: updatedGroupstEecost,
                        actCostExact: updatedGroupActCostExact,
                        nettEA: updatedGroupNettEA,
                        margin: updatedGroupMargin,
                        frcMargin: updatedGroupFrcMargin,
                        actMargin: updatedGroupActMargin,
                        // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                        totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                        //  lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA: totalNettEAFooter,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEAFooter);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                }
            } else if (onAGroupRow) {
                let rowItems = newItems.map((item) => {
                    return {
                        ...item,
                        groupId: gId,
                        groupName: gName,
                        displayGroupName: "",
                        subGroupId: sgId,
                        subGroupName: sgName,
                        displaySubGroupName: "",
                        id: uuidv4(),
                        parentId: parentId
                    };
                });

                if (itemIndex !== itemList.length - 1) {
                    updatedItemList = [...updatedItemList.slice(0, itemIndex), ...rowItems, ...itemList.slice(itemIndex)];
                } else {
                    updatedItemList.splice(itemIndex, 0, ...rowItems);
                }
                let existingDbExtra = [];
                let groupItems = [];
                let groupDbExtraIndex = -1;
                let groupDbExtra = -1;
                let groupNettEA = -1;

                if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                    existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                    groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                } else {
                    existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                }

                if (existingDbExtra.length > 0) {
                    // groupItems = getAllItemsInAGroup(updatedItemList, gId);
                    // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    // const updatedGroupDbExtraRow = {
                    //     ...existingDbExtra[0],
                    //     nettEA: groupDbExtra,
                    //     totalPrice: groupDbExtra,
                    // };

                    // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                    const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        const sbId = subGroupRow.subGroupId;
                        const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                        const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                        const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                        return {
                            ...subGroupRow,
                            nettEA: nettEA * parseInt(subGroupRow.qty),
                        };
                    });

                    const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                    const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedGroupDbExtraRow = {
                        ...existingDbExtra[0],
                        nettEA: groupDbExtra,
                        totalPrice: groupDbExtra,
                    };

                    updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                }

                if (gName === GroupItemTypes.SUB_GROUPED_ITEMS) {
                    let groupListPrice = -1;
                    let groupstEecost = -1;
                    let groupStFutureCost = -1;
                    let groupActCostExact = -1;
                    let groupMargin = -1;
                    let groupFrcMargin = -1;
                    let groupActMargin = -1;
                    let groupTotalCost = -1;
                    let groupTotalPrice = -1;
                    let subGroupRows = [];

                    subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    groupItems = getGroupedRowItems(updatedItemList, gId);

                    if (subGroupRows.length > 0) {
                        let subGroupListPrice = -1;
                        let subGroupMargin = -1;
                        let subGroupstEecost = -1;
                        let subGroupActCostExact = -1;
                        let subGroupNettEA = -1;
                        let subGroupTotalCost = -1;
                        let subGroupTotalPrice = -1;
                        let subGroupDbExtraValue = -1;
                        let subGroupStFutureCost = -1;
                        let subGroupFrcMargin = -1;
                        let subGroupActMargin = -1;
                        let subGroupItems = [];

                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                        const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                        subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        const sgDBExtra = getExistingSubGroupWiseDbExtraRow(subGroupItems, sgId);

                        if (sgDBExtra.length > 0) {
                            const sgDBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);
                            subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                            subGroupDbExtraValue = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                            const updatedSubGroupDbExtraRow = {
                                ...sgDBExtra[0],
                                nettEA: subGroupDbExtraValue,
                                totalPrice: subGroupDbExtraValue,
                            };

                            updatedItemList[sgDBExtraIndex] = updatedSubGroupDbExtraRow;
                            subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        }

                        subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                        subGroupstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        subGroupTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                        subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");

                        if (sgDBExtra.length > 0) {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupstEecost
                            );
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupActCostExact
                            );
                        } else {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        }

                        const updatedSubGroupRow = {
                            ...subGroupRow[0],
                            listPrice: subGroupListPrice,
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            stEecost: subGroupstEecost,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            nettEA: subGroupNettEA,
                            margin: subGroupMargin,
                            actMargin: subGroupActMargin,
                            // totalCost: subGroupTotalCost,
                            totalCost: calculateItemTotalCost(subGroupRow[0].qty, subGroupstEecost),
                            totalPrice: subGroupTotalPrice,
                            stFutureCost: subGroupStFutureCost,
                            frcMargin: subGroupFrcMargin,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            stFutureCost: subGroupStFutureCost,
                            //  lcMargin: "",
                        };

                        updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                        subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            if (subGroupRow.qty > 1) {
                                return {
                                    ...subGroupRow,
                                    nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                    // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                    listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                    stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                    stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                    actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                    stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                    stFutureCostRound: parseFloat(
                                        (subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)
                                    ),
                                    actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                    //    lcMargin: "",
                                };
                            }
                            return subGroupRow;
                        });

                        groupItems = [...getGroupedRowItems(updatedItemList, gId), ...updatedSubGroupRows]; // to get the updated sub group row
                    }

                    groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupstEecost
                        );
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupActCostExact
                        );
                    } else {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    let groupRowIndex = -1;
                    let groupQty = -1;
                    let hasGroupQtyChanged = false;
                    let updatedGroupRow = null;

                    if (groupRow) {
                        groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        groupQty = groupRow.qty;
                        hasGroupQtyChanged = groupQty > 1;

                        updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stEecost: groupstEecost,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                            nettEA: groupNettEA,
                            actMargin: groupActMargin,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            //   lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                }

                //#region temporarily commented and replace the with accurate computation for totals
                // // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA: totalNettEAFooter,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEAFooter);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
                //#endregion


                // const updatedItems = (updatedItemList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                const updatedItems = itemPositioning(true, updatedItemList);
                setItemList(updatedItems);
                updateQuoteDetailsForId(id, { selectedItems: updatedItems });
            } else if (onASubGroupRow) {
                const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                let dbExtraValueSb = -1;

                let rowItems = newItems.map((item) => {
                    return {
                        ...item,
                        groupId: gId,
                        groupName: gName,
                        displayGroupName: "",
                        subGroupId: sgId,
                        subGroupName: sgName,
                        displaySubGroupName: "",
                        id: uuidv4(),
                        parentId: parentId
                    };
                });

                updatedItemList.splice(itemIndex, 0, ...rowItems);

                if (existingDbExtra.length > 0) {
                    const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                    const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                    const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                    const updatedSubGroupDbExtraRow = {
                        ...existingDbExtra[0],
                        nettEA: dbExtraValueSb,
                        totalPrice: dbExtraValueSb,
                    };

                    updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                }

                //ref : sub group row calculation
                let updatedSubGroupMargin = -1;
                let updatedSubGroupFrcMargin = -1;
                let updatedSubGroupActMargin = -1;

                const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                if (existingDbExtra.length > 0) {
                    updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupstEecost
                    );
                    updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupStFutureCost
                    );
                    updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupActCostExact
                    );
                } else {
                    updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupstEecost
                    );
                    updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupStFutureCost
                    );
                    updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupActCostExact
                    );
                }

                const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                const subGroupQty = subGroupRow.qty;

                const updatedSubGroupRow = {
                    ...subGroupRow,
                    listPrice: updatedSubGroupListPrice,
                    stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                    stEecost: updatedSubGroupstEecost,
                    stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                    stFutureCost: updatedSubGroupStFutureCost,
                    actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                    actCostExact: updatedSubGroupActCostExact,
                    nettEA: updatedSubGroupNettEA,
                    margin: updatedSubGroupMargin,
                    actMargin: updatedSubGroupActMargin,
                    frcMargin: updatedSubGroupFrcMargin,
                    // totalCost: updatedTotalCost * subGroupQty,
                    totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                    totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                    //   lcMargin: "",
                };

                updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                let groupDbExtra = -1;

                // if (existingGroupDbExtra.length > 0) {
                //     const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                //     groupNettEA = calculateGroupWiseTotal(gItms, "nettEA");
                //     groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                //     const updatedGroupDbExtraRow = {
                //         ...existingGroupDbExtra[0],
                //         nettEA: groupDbExtra,
                //         totalPrice: groupDbExtra,
                //     };

                //     updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                // }

                if (existingGroupDbExtra.length > 0) {
                    const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        const sbId = subGroupRow.subGroupId;
                        const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                        const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                        const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                        return {
                            ...subGroupRow,
                            nettEA: nettEA * parseInt(subGroupRow.qty),
                        };
                    });

                    const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                    const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedGroupDbExtraRow = {
                        ...existingGroupDbExtra[0],
                        nettEA: groupDbExtra,
                        totalPrice: groupDbExtra,
                    };

                    updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                }

                //ref : group row calculation
                let updatedGroupMargin = -1;
                let updatedGroupFrcMargin = -1;
                let updatedGroupActMargin = -1;

                const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                    if (subGroupRow.qty > 1) {
                        return {
                            ...subGroupRow,
                            nettEA: subGroupRow.nettEA * subGroupRow.qty,
                            // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                            listPrice: subGroupRow.listPrice * subGroupRow.qty,
                            stEecost: subGroupRow.stEecost * subGroupRow.qty,
                            stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                            actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                            stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                            stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                            actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                            //lcMargin: "",
                        };
                    }
                    return subGroupRow;
                });

                const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                if (groupLevelDbExtra.length > 0) {
                    updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupstEecost
                    );
                    updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupStFutureCost
                    );
                    updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupActCostExact
                    );
                } else {
                    updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupstEecost
                    );
                    updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupStFutureCost
                    );
                    updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupActCostExact
                    );
                }

                const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                const groupQty = groupRow.qty;
                const hasGroupQtyChanged = groupQty > 1;

                const updatedGroupRow = {
                    ...groupRow,
                    listPrice: updatedGroupListPrice,
                    stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                    stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                    stFutureCost: updatedGroupStFutureCost,
                    actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                    stEecost: updatedGroupstEecost,
                    actCostExact: updatedGroupActCostExact,
                    nettEA: updatedGroupNettEA,
                    margin: updatedGroupMargin,
                    actMargin: updatedGroupActMargin,
                    frcMargin: updatedGroupFrcMargin,
                    // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                    totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                    totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                    //  lcMargin: "",
                };

                updatedItemList[groupRowIndex] = updatedGroupRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                //#region temporarily commented and replace the with accurate computation for totals
                // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA: totalNettEAFooter,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEAFooter);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
                //#endregion

                const updatedItems = (updatedItemList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                setItemList(updatedItems);
                updateQuoteDetailsForId(id, { selectedItems: updatedItems });
            } else if (isNonGroupedItem) {
                let rowItems = newItems.map((item) => {
                    return {
                        ...item,
                        groupId: gId,
                        groupName: gName,
                        displayGroupName: "",
                        subGroupId: sgId,
                        subGroupName: sgName,
                        displaySubGroupName: "",
                        id: uuidv4(),
                        parentId: parentId
                    };
                });

                updatedItemList.splice(itemIndex, 0, ...rowItems);

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                //#region temporarily commented and replace the with accurate computation for totals
                // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA: totalNettEAFooter,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEAFooter);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
                //#endregion

                const updatedItems = (updatedItemList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
                setItemList(updatedItems);
                updateQuoteDetailsForId(id, { selectedItems: updatedItems });
            }
        } else {
            const lastRow = itemList.at(-1);
            const underAnyGroupLevel = lastRow.groupName.startsWith("Group") || lastRow.subGroupName.startsWith("Sub Group");

            if (underAnyGroupLevel) {
                const groupedList = newItems.map((res) => {
                    return {
                        ...res,
                        groupId: groupId,
                        groupName: `Group ${groupCount - 1}`,
                        displayGroupName: "",
                        subGroupId: "",
                        subGroupName: "",
                        displaySubGroupName: "",
                        parentId: lastRow.parentId,
                    };
                });
                const isSubGrp = lastRow.subGroupName.startsWith("Sub Group");
                if (isSubGrp) {
                    const subGroupedList = groupedList.map((res) => {
                        return {
                            ...res,
                            subGroupId: lastRow.subGroupId,
                            subGroupName: `Sub Group ${subGroupCount - 1}`,
                        };
                    });

                    const filteredList = itemList.filter((item) => item.rowId !== lastRow.rowId);
                    const combinedList = [...filteredList, ...subGroupedList];

                    let subGroupLevelDbExtra = isDbExtraAdded(combinedList, groupId, subGroupId, GroupTypes.SUB_GROUP);

                    let updatedNettEA = -1;
                    let groupDbExtra = -1;

                    //check if there is any dbExtra added for the sub group
                    if (subGroupLevelDbExtra) {
                        const dbExtraRowIndexSB = getDbExtraRowIndex(
                            combinedList,
                            groupId,
                            subGroupId,
                            GroupTypes.SUB_GROUP
                        );
                        const dbExtraRowSB = combinedList[dbExtraRowIndexSB];
                        let listWithoutDbExtra = getItemsWithoutDBExtra(
                            combinedList,
                            GroupTypes.SUB_GROUP,
                            groupId,
                            subGroupId
                        );
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const totalNettEASB = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEASB);

                        const subGroupDbExtraRow = {
                            ...dbExtraRowSB,
                            nettEA: updatedNettEA,
                            totalPrice: updatedNettEA,
                        };

                        combinedList[dbExtraRowIndexSB] = subGroupDbExtraRow;

                        //group db extra calculations
                        const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);
                        const groupItems = getAllItemsInAGroup(combinedList, groupId);
                        const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupWiseDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    } else {
                        //if there is no dbExtra added for the sub group

                        //check if there is any dbExtra added for the group
                        const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);

                        if (existingGroupWiseDbExtra.length > 0) {
                            const groupItems = getAllItemsInAGroup(combinedList, groupId);
                            const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                            const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                            const updatedGroupDbExtraRow = {
                                ...existingGroupWiseDbExtra[0],
                                nettEA: groupDbExtra,
                                totalPrice: groupDbExtra,
                            };

                            combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                        }
                    }

                    //to persist the group values(list price, act Cost, nettEA etc) when the qty is changed
                    const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                    let subGroupItems = getAllExistingSubGroupedItems(combinedList, groupId, subGroupId);

                    const hasQtyChangedInSubGroups = subGroupRows.some((item) => item.qty > 1);
                    const hasQtyChangedInSGItems = subGroupItems.some((item) => item.qty > 1);

                    let rowList = [];
                    let qtyUnchangedSubGroups = [];
                    let qtyUnchangedSubGroupItems = [];

                    if (hasQtyChangedInSubGroups) {
                        qtyUnchangedSubGroups = subGroupRows.filter((item) => item.qty === 1);
                    }

                    if (hasQtyChangedInSGItems) {
                        qtyUnchangedSubGroupItems = subGroupItems.filter((item) => item.qty === 1);
                    }

                    if (hasQtyChangedInSubGroups) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                        const subGroupRowIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);
                        const updatedQty = subGroupRow[0].qty;
                        let updatedSubGroupRow = null;

                        let updatedSGTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                        let updatedSGTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                        let updatedSGNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        let updatedSGActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        let updatedSGstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        let updatedSGStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        let updatedSGListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");

                        updatedSubGroupRow = {
                            ...subGroupRow[0],
                            qty: updatedQty,
                            // totalCost: updatedSGTotalCost,
                            totalCost: calculateItemTotalCost(updatedQty, updatedSGstEecost),
                            totalPrice: updatedSGTotalPrice * updatedQty,
                            nettEA: updatedSGNettEA,
                            actCostExact: updatedSGActCostExact,
                            stEecost: updatedSGstEecost,
                            stFutureCost: updatedSGStFutureCost,
                            listPrice: updatedSGListPrice,
                            actCost: parseFloat(parseFloat(updatedSGActCostExact).toFixed(2)),
                            stEecostRound: parseFloat(parseFloat(updatedSGstEecost).toFixed(2)),
                            stFutureCostRound: parseFloat(parseFloat(updatedSGStFutureCost).toFixed(2)),
                            // lcMargin: "",
                        };

                        combinedList[subGroupRowIndex] = updatedSubGroupRow;

                        //group row calculations
                        let groupRow = getGroupRowByGroup(combinedList, groupId)[0];
                        let groupRowIndex = getGroupRowIndex(combinedList, groupId);
                        let subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                        let groupRowItems = getGroupedRowItems(combinedList, groupId);
                        let updatedGroupRow = null;
                        let subGroupsWithoutCurrent = subGroupRows.filter((item) => item.subGroupId !== subGroupId);
                        let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                            if (item.qty > 1) {
                                return {
                                    ...item,
                                    listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                    actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                    nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                    stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                    stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                    actCost: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(
                                            2
                                        )
                                    ),
                                    stEecostRound: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(
                                            2
                                        )
                                    ),
                                };
                            }

                            return item;
                        });

                        const concatList = [...updatedSubGroupsWithoutCurrent, ...groupRowItems];

                        let gTotalCost = calculateGroupWiseTotal(concatList, "totalCost");
                        let gTotalPrice = calculateGroupWiseTotal(concatList, "totalPrice");
                        let gNettEA = calculateGroupWiseTotal(concatList, "nettEA");
                        let gActCostExact = calculateGroupWiseTotal(concatList, "actCostExact");
                        let gstEecost = calculateGroupWiseTotal(concatList, "stEecost");
                        let gStFutureCost = calculateGroupWiseTotal(concatList, "stFutureCost");
                        let gListPrice = calculateGroupWiseTotal(concatList, "listPrice");

                        //quantity changed group calculations
                        const hasGroupQtyChanged = groupRow.qty > 1;

                        updatedGroupRow = {
                            ...groupRow,
                            // totalCost: hasGroupQtyChanged
                            //     ? parseFloat(((gTotalCost + updatedSubGroupRow.totalCost) * groupRow.qty).toFixed(2))
                            //     : parseFloat((gTotalCost + updatedSubGroupRow.totalCost).toFixed(2)),
                            totalCost: hasGroupQtyChanged
                                ? parseFloat(
                                    (
                                        (calculateItemTotalCost(groupRow.qty, gstEecost) +
                                            updatedSubGroupRow.totalCost) *
                                        groupRow.qty
                                    ).toFixed(2)
                                )
                                : parseFloat(
                                    (
                                        calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost
                                    ).toFixed(2)
                                ),
                            totalPrice: hasGroupQtyChanged
                                ? parseFloat(((gTotalPrice + updatedSubGroupRow.totalPrice) * groupRow.qty).toFixed(2))
                                : parseFloat((gTotalPrice + updatedSubGroupRow.totalPrice).toFixed(2)),
                            nettEA: parseFloat((gNettEA + updatedSubGroupRow.nettEA * updatedQty).toFixed(2)),
                            actCostExact: gActCostExact + updatedSubGroupRow.actCostExact * updatedQty,
                            stEecost: gstEecost + updatedSubGroupRow.stEecost * updatedQty,
                            stFutureCost: gStFutureCost + updatedSubGroupRow.stFutureCost * updatedQty,
                            listPrice: parseFloat((gListPrice + updatedSubGroupRow.listPrice * updatedQty).toFixed(2)),
                            actCost: parseFloat(
                                parseFloat(gActCostExact + updatedSubGroupRow.actCostExact * updatedQty).toFixed(2)
                            ),
                            stEecostRound: parseFloat(
                                parseFloat(gstEecost + +(updatedSubGroupRow.stEecost * updatedQty)).toFixed(2)
                            ),
                            stFutureCostRound: parseFloat(
                                parseFloat(gStFutureCost + +(updatedSubGroupRow.stFutureCost * updatedQty)).toFixed(2)
                            ),
                        };

                        combinedList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(combinedList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                combinedList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    combinedList,
                                    dbExtraCharge
                                );
                                combinedList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                        const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                        setItemList(updatedItems);
                        updateQuoteDetailsForId(id, { selectedItems: updatedItems });
                    } else {
                        let subGroupMargin = -1;
                        let subGroupFrcMargin = -1;
                        let subGroupActMargin = -1;

                        const subGroupListPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "listPrice");
                        const subGroupNettEA = calculateSubGroupWiseTotal(subGroupId, combinedList, "nettEA");
                        const subGroupTotalPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalPrice");
                        const subGroupstEecost = calculateSubGroupWiseTotal(subGroupId, combinedList, "stEecost");
                        const subGroupStFutureCost = calculateSubGroupWiseTotal(
                            subGroupId,
                            combinedList,
                            "stFutureCost"
                        );
                        const subGroupTotalCost = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalCost");
                        const subGroupActCostExact = calculateSubGroupWiseTotal(
                            subGroupId,
                            combinedList,
                            "actCostExact"
                        );
                        // const totalDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                        const totalDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

                        if (subGroupLevelDbExtra) {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupstEecost
                            );
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupActCostExact
                            );
                        } else {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        }

                        const emptySubGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                        const subGroupIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);

                        const updatedEmptySubGroupRow = {
                            ...emptySubGroupRow[0],
                            listPrice: subGroupListPrice,
                            nettEA: subGroupNettEA,
                            totalPrice: subGroupTotalPrice,
                            dcOnLp: totalDcOnLp,
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            stFutureCost: subGroupStFutureCost,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            qty: emptySubGroupRow[0].qty ? emptySubGroupRow[0].qty : 1,
                            margin: subGroupMargin,
                            actMargin: subGroupActMargin,
                            frcMargin: subGroupFrcMargin,
                            // totalCost: subGroupTotalCost,
                            totalCost: calculateItemTotalCost(emptySubGroupRow[0].qty, subGroupstEecost),
                            stEecost: subGroupstEecost,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            //  lcMargin: "",
                        };

                        //updates the sub group row
                        combinedList[subGroupIndex] = updatedEmptySubGroupRow;

                        //ref : for group calculations
                        const updatedSubGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId); // to get the sub groups after the above update
                        const groupRows = getGroupedRowItems(combinedList, groupId);
                        const emptyGroupRow = getGroupRowByGroup(combinedList, groupId);
                        const emptyGroupRowIndex = getGroupRowIndex(combinedList, groupId);

                        hasQtyChangedInSubGroups
                            ? (rowList = [...emptyGroupRow, ...qtyUnchangedSubGroups, ...groupRows])
                            : (rowList = [...updatedSubGroupRows, ...groupRows]);

                        //check for group level db extra
                        const groupLevelDbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);

                        let groupMargin = -1;
                        let groupFrcMargin = -1;
                        let groupActMargin = -1;

                        const groupListPrice = calculateGroupWiseTotal(rowList, "listPrice");
                        const groupNettEA = calculateGroupWiseTotal(rowList, "nettEA");
                        const groupTotalPrice = calculateGroupWiseTotal(rowList, "totalPrice");
                        const groupstEecost = calculateGroupWiseTotal(rowList, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(rowList, "stFutureCost");
                        const groupTotalCost = calculateGroupWiseTotal(rowList, "totalCost");
                        const groupActCostExact = calculateGroupWiseTotal(rowList, "actCostExact");
                        // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                        const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                        if (groupLevelDbExtra) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const hasGroupQtyChanged = emptyGroupRow[0].qty > 1;

                        const updatedEmptyGroupRow = {
                            ...emptyGroupRow[0],
                            listPrice: groupListPrice,
                            nettEA: groupNettEA,
                            totalPrice: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalPrice : groupTotalPrice,
                            dcOnLp: groupDcOnLp,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            qty: emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1,
                            margin: groupMargin,
                            actMargin: groupActMargin,
                            frcMargin: groupFrcMargin,
                            // totalCost: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalCost : groupTotalCost,
                            totalCost: calculateItemTotalCost((emptyGroupRow.length ? emptyGroupRow[0].qty : 1), groupstEecost),
                            stEecost: groupstEecost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                        };

                        combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(combinedList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                combinedList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    combinedList,
                                    dbExtraCharge
                                );
                                combinedList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        const updatedItems = (combinedList.some((f) => f.rowType === "A")) ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                        setItemList(updatedItems);
                        updateQuoteDetailsForId(id, { selectedItems: updatedItems });
                    }
                    //#region temporarily commented and replace the with accurate computation for totals
                    // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(combinedList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEA);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion
                    //#region 
                    // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);
                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);
                    //#endregion

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: combinedList[0],
                        });
                    }
                } else {
                    const combinedList = [...itemList, ...groupedList];

                    const dbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);
                    let updatedNettEA;

                    if (dbExtra) {
                        const dbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                        let listWithoutDbExtra = getItemsWithoutDBExtra(combinedList, GroupTypes.GROUP, groupId);
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                        const dbExtraRow = combinedList[dbExtraIndex];

                        const updatedDbExtraRow = {
                            ...dbExtraRow,
                            nettEA: updatedNettEA,
                            totalPrice: updatedNettEA,
                        };

                        combinedList[dbExtraIndex] = updatedDbExtraRow;
                    }

                    const groupRows = combinedList.filter(
                        (item) =>
                            item.groupId === groupId &&
                            (item.subGroupId === "" || item.subGroupId === "00000000-0000-0000-0000-000000000000") &&
                            item.stStockCode !== ""
                    );

                    let groupMargin = -1;
                    let groupFrcMargin = -1;
                    let groupActMargin = -1;

                    const groupListPrice = calculateGroupWiseTotal(groupRows, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupRows, "nettEA");
                    const groupTotalPrice = calculateGroupWiseTotal(groupRows, "totalPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupRows, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupRows, "stFutureCost");
                    const groupTotalCost = calculateGroupWiseTotal(groupRows, "totalCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupRows, "actCostExact");
                    // const groupTotalDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupTotalDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                    if (dbExtra) {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupstEecost
                        );
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupActCostExact
                        );
                    } else {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                    }

                    const emptyGroupRow = combinedList.filter(
                        (item) => item.displayGroupName !== "" && item.groupId === groupId
                    );

                    const emptyGroupRowIndex = combinedList.findIndex(
                        (item) => item.displayGroupName !== "" && item.groupId === groupId
                    );

                    const groupQty = emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedEmptyGroupRow = {
                        ...emptyGroupRow[0],
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                        dcOnLp: groupTotalDcOnLp,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        qty: groupQty,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                        stEecost: groupstEecost,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        //lcMargin: "",
                    };

                    combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(combinedList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                            combinedList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    let updatedItemList = [];
                    const lastItem = itemList.at(-1);
                    if (lastItem.stStockCode.length === 0 && (lastItem.rowType !== "A" && lastItem.rowType !== "G" && lastItem.rowType !== "SG")) {
                        const filteredItems = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                        updatedItemList = [...filteredItems, lastItem];
                        updatedItemList = itemPositioning(true, updatedItemList);
                    } else {
                        const emptyRow = createEmptyRow(lastItem.groupId, lastItem.groupName, lastItem.subGroupId, lastItem.subGroupName, "", "", "", lastItem.parentId);
                        updatedItemList = [...combinedList, emptyRow];
                        updatedItemList = itemPositioning(true, updatedItemList);
                    }
                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    //#region temporarily commented and replace with accurate computation for totals
                    // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(combinedList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEA);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion
                    //#region 
                    // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );

                    // setTotalActCostEAExact(totalActCostEa);
                    // const totalListPrice = calculateTotalListPrice(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );

                    // setTotalListPrice(totalListPrice);
                    //#endregion

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: updatedItemList[0],
                        });
                    }
                }
            } else {
                const nonGroupId = uuidv4();
                if (!nonGroupedId.current) nonGroupedId.current = nonGroupId;

                const updatedNewItems = newItems.map((res) => {
                    return {
                        ...res,
                        groupId: nonGroupedId.current ? nonGroupedId.current : nonGroupId,
                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                        displayGroupName: "",
                        subGroupId: "",
                        subGroupName: "",
                        displaySubGroupName: "",
                        margin: parseFloat(parseFloat(res.margin).toFixed(1)),
                        frcMargin: parseFloat(parseFloat(res.frcMargin).toFixed(1)),
                        actMargin: parseFloat(parseFloat(res.actMargin).toFixed(1)),
                        // lcMargin: "",
                    };
                });

                const combinedList = [...itemList, ...updatedNewItems];
                const dbExtra = isDbExtraAdded(combinedList, nonGroupedId.current, null, GroupTypes.NON_GROUP);

                if (dbExtra) {
                    let listWithoutDbExtra = getItemsWithoutDBExtra(
                        combinedList,
                        GroupTypes.NON_GROUP,
                        nonGroupedId.current,
                        null
                    );
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    const updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                    const dbExtraIndex = getDbExtraRowIndex(
                        combinedList,
                        nonGroupedId.current,
                        null,
                        GroupTypes.NON_GROUP
                    );
                    const dbExtraRow = combinedList[dbExtraIndex];

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                        id: uuidv4(),
                        parentId: null
                    };

                    combinedList[dbExtraIndex] = updatedDbExtraRow;
                }

                let updatedItemList = [];
                const lastItem = itemList.at(-1);
                if (lastItem.stStockCode.length === 0 && (lastItem.rowType !== "A" && lastItem.rowType !== "G" && lastItem.rowType !== "SG")) {
                    const filteredItem = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                    updatedItemList = [...filteredItem, lastItem];
                    updatedItemList = itemPositioning(true, updatedItemList);
                } else {
                    const emptyRow = createEmptyRow(lastItem.groupId, lastItem.groupName, lastItem.subGroupId, lastItem.subGroupName, "", "", "", lastItem.parentId);
                    updatedItemList = [...combinedList, emptyRow];
                    updatedItemList = itemPositioning(true, updatedItemList);
                }
                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                //#region temporarily commented and replace with accurate computation for totals
                // // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(combinedList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEA);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
                //#endregion
                // const totalPrice = calculateTotalPrice(combinedList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(combinedList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(combinedList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(combinedList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(combinedList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(combinedList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(combinedList);
                // setTotalListPrice(totalListPrice);
            }
        }
        setShowLoaderBtn(false);
        closeDialog();
        setComputeTotals(true);
    };

    const handleAddComponentParts = async () => {
        const UserId = user?.homeAccountId;
        let systemSettings = null;
        if (selectedComponents.length > 0) {
            const compcodeList = {
                lstStocks: selectedComponents,
            };
        } else {
            console.log("error components");
        }
        //   setSelectedComponents([])
        var url = `Stocks/getstockdetailsusingcompcode?CustomerId=${customerId}&bomisChecked=${bomisChecked}`;
        //var url = "Stocks/getstockdetails";
        const responses = await postResponse(url, selectedComponents);

        setSelectedComponents([]);

        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);

        const newItems = responses.flat().map((response) => {
            let dcOnLp =
                response.listPrice && response.nettEA
                    ? calculateItemDcOnLp(response.nettEA, response.listPrice)
                    : // parseFloat(
                    //       parseFloat(((response.listPrice - response.nettEA) / response.listPrice) * 100).toFixed(1)
                    //   )
                    0;
            let rowId = uuidv4();
            let totalPrice = response.repDisc
                ? parseFloat(parseFloat(response.repDisc).toFixed(2))
                : parseFloat(parseFloat(response.qty * response.nettEA).toFixed(2));
            let margin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.stEecost) / response.nettEA) * 100).toFixed(1));
            let actMargin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.actCostExact) / response.nettEA) * 100).toFixed(1));
            let frcMargin =
                response?.nettEA === 0.0
                    ? 0
                    : parseFloat((((response.nettEA - response.stFutureCost) / response.nettEA) * 100).toFixed(1));
            let totalCost = response.qty * response.stEecostRound;
            let groupDbExtraId = "";
            let subGroupDbExtraId = "";
            let nonGroupDbExtraId = "";
            return {
                ...response,
                dcOnLp,
                totalPrice,
                margin,
                frcMargin,
                actMargin,
                totalCost,
                rowId,
                groupDbExtraId,
                subGroupDbExtraId,
                nonGroupDbExtraId,
            };
        });

        updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });

        //here
        if (selectedItems.length > 0) {
            const updatedItemList = [...itemList];
            const itemIndex = updatedItemList.findIndex((item) => item.rowId === selectedItems[0].rowId);
            const position = getRowPosition(updatedItemList, itemIndex);
            const {
                onAGroup,
                onASubGroup,
                onAGroupRow,
                onASubGroupRow,
                isAGroupRowItem,
                isASubGroupItem,
                groupDetails,
            } = position;
            const gId = groupDetails.prevGroupId;
            const sgId = groupDetails.prevSubGroupId;
            const gName = groupDetails.prevGroupName;
            const sgName = groupDetails.prevSubGroupName;

            if (onAGroup) {
                if (isAGroupRowItem) {
                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    let existingDbExtra = [];
                    let groupItems = [];
                    let groupDbExtraIndex = -1;
                    let groupDbExtra = -1;
                    let groupNettEA = -1;

                    if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                        existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                    } else {
                        existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    }

                    if (existingDbExtra.length > 0) {
                        // groupItems = getAllItemsInAGroup(updatedItemList, gId);
                        // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        // const updatedGroupDbExtraRow = {
                        //     ...existingDbExtra[0],
                        //     nettEA: groupDbExtra,
                        //     totalPrice: groupDbExtra,
                        // };

                        // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                        let groupListPrice = -1;
                        let groupstEecost = -1;
                        let groupStFutureCost = -1;
                        let groupActCostExact = -1;
                        let groupMargin = -1;
                        let groupFrcMargin = -1;
                        let groupActMargin = -1;
                        let groupTotalCost = -1;
                        let groupTotalPrice = -1;

                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        groupItems = getGroupedRowItems(updatedItemList, gId);

                        if (subGroupRows.length > 0) {
                            const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                                if (subGroupRow.qty > 1) {
                                    return {
                                        ...subGroupRow,
                                        nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                        // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                        listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                        stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                        stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                        actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                        stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                        stFutureCostRound: parseFloat(
                                            (subGroupRow.stFutureCostRound * subGroupRow.qty).toFixed(2)
                                        ),
                                        actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                        // lcMargin: "",
                                    };
                                }
                                return subGroupRow;
                            });

                            groupItems = [...groupItems, ...updatedSubGroupRows];
                        }

                        groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                        groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                        groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                        groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                        if (existingDbExtra.length > 0) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        const groupQty = groupRow.qty;
                        const hasGroupQtyChanged = groupQty > 1;

                        const updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            actCostExact: groupActCostExact,
                            stEecost: groupstEecost,
                            //  lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else if (isASubGroupItem) {
                    const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                    let dbExtraValueSb = -1;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    if (existingDbExtra.length > 0) {
                        const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                        const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                        const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                        const updatedSubGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: dbExtraValueSb,
                            totalPrice: dbExtraValueSb,
                        };

                        updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                    }

                    //ref : sub group row calculation
                    let updatedSubGroupMargin = -1;
                    let updatedSubGroupFrcMargin = -1;
                    let updatedSubGroupActMargin = -1;

                    const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                    const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                    const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                    const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                    const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                    // const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                    const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupActCostExact
                        );
                    } else {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupActCostExact
                        );
                    }

                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                    const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                    const subGroupQty = subGroupRow.qty;

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        listPrice: updatedSubGroupListPrice,
                        stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                        stEecost: updatedSubGroupstEecost,
                        stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedSubGroupStFutureCost,
                        actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                        actCostExact: updatedSubGroupActCostExact,
                        nettEA: updatedSubGroupNettEA,
                        margin: updatedSubGroupMargin,
                        frcMargin: updatedSubGroupFrcMargin,
                        actMargin: updatedSubGroupActMargin,
                        // totalCost: updatedTotalCost * subGroupQty,
                        totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                        totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                        // lcMargin: "",
                    };

                    updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                    const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    let groupDbExtra = -1;

                    // if (existingGroupDbExtra.length > 0) {
                    //     const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    //     groupNettEA = calculateGroupWiseTotal(gItms, "nettEA");
                    //     groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    //     const updatedGroupDbExtraRow = {
                    //         ...existingGroupDbExtra[0],
                    //         nettEA: groupDbExtra,
                    //         totalPrice: groupDbExtra,
                    //     };

                    //     updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    // }

                    if (existingGroupDbExtra.length > 0) {
                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    //ref : group row calculation
                    let updatedGroupMargin = -1;
                    let updatedGroupFrcMargin = -1;
                    let updatedGroupActMargin = -1;

                    const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                    const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        if (subGroupRow.qty > 1) {
                            return {
                                ...subGroupRow,
                                nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                                actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                //  lcMargin: "",
                            };
                        }
                        return subGroupRow;
                    });

                    const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                    const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (groupLevelDbExtra.length > 0) {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupActCostExact
                        );
                    } else {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupActCostExact
                        );
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                    const groupQty = groupRow.qty;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: updatedGroupListPrice,
                        stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedGroupStFutureCost,
                        actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                        stEecost: updatedGroupstEecost,
                        actCostExact: updatedGroupActCostExact,
                        nettEA: updatedGroupNettEA,
                        margin: updatedGroupMargin,
                        actMargin: updatedGroupActMargin,
                        frcMargin: updatedGroupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                        totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                        // lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else {
                    const nonGroupId = nonGroupedId.current ? nonGroupedId.current : uuidv4();
                    nonGroupedId.current = nonGroupId;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: nonGroupId,
                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                            displayGroupName: "",
                            subGroupId: "",
                            subGroupName: "",
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    const existingNonGroupDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);

                    if (existingNonGroupDbExtra.length > 0) {
                        const nonGroupItems = getAllItemsInAGroup(updatedItemList, nonGroupId);
                        const nettEANG = calculateGroupWiseTotal(nonGroupItems, "nettEA");
                        const dbExtraValueNG = calculateDbExtra(dbExtraCharge, nettEANG);
                        const dBExtraIndexNG = getDbExtraRowIndex(updatedItemList, null, null, GroupTypes.NON_GROUP);

                        const updatedNonGroupDbExtraRow = {
                            ...existingNonGroupDbExtra[0],
                            nettEA: dbExtraValueNG,
                            totalPrice: dbExtraValueNG,
                        };

                        updatedItemList[dBExtraIndexNG] = updatedNonGroupDbExtraRow;
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                }
            } else if (onASubGroup) {
                if (isAGroupRowItem) {
                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    let existingDbExtra = [];
                    let groupItems = [];
                    let groupDbExtraIndex = -1;
                    let groupDbExtra = -1;
                    let groupNettEA = -1;

                    if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                        existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                    } else {
                        existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                        groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    }

                    if (existingDbExtra.length > 0) {
                        // const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        // const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        //     if (subGroupRow.qty > 1) {
                        //         return {
                        //             ...subGroupRow,
                        //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                        //         };
                        //     }
                        //     return subGroupRow;
                        // });
                        // groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        // const updatedGroupDbExtraRow = {
                        //     ...existingDbExtra[0],
                        //     nettEA: groupDbExtra,
                        //     totalPrice: groupDbExtra,
                        // };

                        // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                        let groupListPrice = -1;
                        let groupstEecost = -1;
                        let groupStFutureCost = -1;
                        let groupActCostExact = -1;
                        let groupMargin = -1;
                        let groupActMargin = -1;
                        let groupFrcMargin = -1;
                        let groupTotalCost = -1;
                        let groupTotalPrice = -1;

                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        groupItems = getGroupedRowItems(updatedItemList, gId);

                        if (subGroupRows.length > 0) {
                            const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                                if (subGroupRow.qty > 1) {
                                    return {
                                        ...subGroupRow,
                                        nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                        // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                        listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                        stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                        stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                        actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                        stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                        stFutureCostRound: parseFloat(
                                            (subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)
                                        ),
                                        actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                        //  lcMargin: "",
                                    };
                                }
                                return subGroupRow;
                            });

                            groupItems = [...groupItems, ...updatedSubGroupRows];
                        }

                        groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                        groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                        groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                        groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                        if (existingDbExtra.length > 0) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        const hasGroupQtyChanged = groupRow.qty > 1;
                        const groupQty = groupRow.qty;

                        const updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            // lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else if (isASubGroupItem) {
                    const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                    let dbExtraValueSb = -1;

                    let rowItems = newItems.map((item) => {
                        return {
                            ...item,
                            groupId: gId,
                            groupName: gName,
                            displayGroupName: "",
                            subGroupId: sgId,
                            subGroupName: sgName,
                            displaySubGroupName: "",
                        };
                    });

                    updatedItemList.splice(itemIndex, 0, ...rowItems);

                    if (existingDbExtra.length > 0) {
                        const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                        const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                        const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                        const updatedSubGroupDbExtraRow = {
                            ...existingDbExtra[0],
                            nettEA: dbExtraValueSb,
                            totalPrice: dbExtraValueSb,
                        };

                        updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                    }

                    //ref : sub group row calculation
                    let updatedSubGroupMargin = -1;
                    let updatedSubGroupFrcMargin = -1;
                    let updatedSubGroupActMargin = -1;

                    const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                    const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                    const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                    const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                    const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                    const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                    const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                    const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA - dbExtraValueSb,
                            updatedSubGroupActCostExact
                        );
                    } else {
                        updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupstEecost
                        );
                        updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupStFutureCost
                        );
                        updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedSubGroupNettEA,
                            updatedSubGroupActCostExact
                        );
                    }

                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                    const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                    const subGroupQty = subGroupRow.qty;

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        listPrice: updatedSubGroupListPrice,
                        stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                        stEecost: updatedSubGroupstEecost,
                        stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedSubGroupStFutureCost,
                        actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                        actCostExact: updatedSubGroupActCostExact,
                        nettEA: updatedSubGroupNettEA,
                        margin: updatedSubGroupMargin,
                        actMargin: updatedSubGroupActMargin,
                        frcMargin: updatedSubGroupFrcMargin,
                        // totalCost: updatedTotalCost * subGroupQty,
                        totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                        totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                        //  lcMargin: "",
                    };

                    updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                    const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    let groupDbExtra = -1;

                    if (existingGroupDbExtra.length > 0) {
                        const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            const sbId = subGroupRow.subGroupId;
                            const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                            const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                            const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                            return {
                                ...subGroupRow,
                                nettEA: nettEA * parseInt(subGroupRow.qty),
                            };
                        });

                        const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                        const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    //ref : group row calculation
                    let updatedGroupMargin = -1;
                    let updatedGroupFrcMargin = -1;
                    let updatedGroupActMargin = -1;

                    const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                    const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        if (subGroupRow.qty > 1) {
                            return {
                                ...subGroupRow,
                                nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                                actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                //  lcMargin: "",
                            };
                        }
                        return subGroupRow;
                    });

                    const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                    const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (groupLevelDbExtra.length > 0) {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA - groupDbExtra,
                            updatedGroupActCostExact
                        );
                    } else {
                        updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupstEecost
                        );
                        updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupStFutureCost
                        );
                        updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            updatedGroupNettEA,
                            updatedGroupActCostExact
                        );
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                    const groupQty = groupRow.qty;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: updatedGroupListPrice,
                        stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                        stFutureCost: updatedGroupStFutureCost,
                        actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                        stEecost: updatedGroupstEecost,
                        actCostExact: updatedGroupActCostExact,
                        nettEA: updatedGroupNettEA,
                        margin: updatedGroupMargin,
                        frcMargin: updatedGroupFrcMargin,
                        actMargin: updatedGroupActMargin,
                        // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                        totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                        //  lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                }
            } else if (onAGroupRow) {
                let rowItems = newItems.map((item) => {
                    return {
                        ...item,
                        groupId: gId,
                        groupName: gName,
                        displayGroupName: "",
                        subGroupId: sgId,
                        subGroupName: sgName,
                        displaySubGroupName: "",
                    };
                });

                updatedItemList.splice(itemIndex, 0, ...rowItems);

                let existingDbExtra = [];
                let groupItems = [];
                let groupDbExtraIndex = -1;
                let groupDbExtra = -1;
                let groupNettEA = -1;

                if (gName === GroupItemTypes.NON_GROUPED_ITEMS) {
                    existingDbExtra = getExistingNonGroupWiseDbExtraRow(updatedItemList);
                    groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.NON_GROUP);
                } else {
                    existingDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                    groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                }

                if (existingDbExtra.length > 0) {
                    // groupItems = getAllItemsInAGroup(updatedItemList, gId);
                    // groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    // groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    // const updatedGroupDbExtraRow = {
                    //     ...existingDbExtra[0],
                    //     nettEA: groupDbExtra,
                    //     totalPrice: groupDbExtra,
                    // };

                    // updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;

                    const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        const sbId = subGroupRow.subGroupId;
                        const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                        const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                        const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                        return {
                            ...subGroupRow,
                            nettEA: nettEA * parseInt(subGroupRow.qty),
                        };
                    });

                    const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                    const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedGroupDbExtraRow = {
                        ...existingDbExtra[0],
                        nettEA: groupDbExtra,
                        totalPrice: groupDbExtra,
                    };

                    updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                }

                if (gName !== GroupItemTypes.NON_GROUPED_ITEMS) {
                    let groupListPrice = -1;
                    let groupstEecost = -1;
                    let groupStFutureCost = -1;
                    let groupActCostExact = -1;
                    let groupMargin = -1;
                    let groupFrcMargin = -1;
                    let groupActMargin = -1;
                    let groupTotalCost = -1;
                    let groupTotalPrice = -1;
                    let subGroupRows = [];

                    subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    groupItems = getGroupedRowItems(updatedItemList, gId);

                    if (subGroupRows.length > 0) {
                        let subGroupListPrice = -1;
                        let subGroupMargin = -1;
                        let subGroupstEecost = -1;
                        let subGroupActCostExact = -1;
                        let subGroupNettEA = -1;
                        let subGroupTotalCost = -1;
                        let subGroupTotalPrice = -1;
                        let subGroupDbExtraValue = -1;
                        let subGroupStFutureCost = -1;
                        let subGroupFrcMargin = -1;
                        let subGroupActMargin = -1;
                        let subGroupItems = [];

                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                        const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                        subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        const sgDBExtra = getExistingSubGroupWiseDbExtraRow(subGroupItems, sgId);

                        if (sgDBExtra.length > 0) {
                            const sgDBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);
                            subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                            subGroupDbExtraValue = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                            const updatedSubGroupDbExtraRow = {
                                ...sgDBExtra[0],
                                nettEA: subGroupDbExtraValue,
                                totalPrice: subGroupDbExtraValue,
                            };

                            updatedItemList[sgDBExtraIndex] = updatedSubGroupDbExtraRow;
                            subGroupItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                        }

                        subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                        subGroupstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        subGroupTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                        subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");

                        if (sgDBExtra.length > 0) {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupstEecost
                            );
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA - subGroupDbExtraValue,
                                subGroupActCostExact
                            );
                        } else {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        }

                        const updatedSubGroupRow = {
                            ...subGroupRow[0],
                            listPrice: subGroupListPrice,
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            stEecost: subGroupstEecost,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            nettEA: subGroupNettEA,
                            margin: subGroupMargin,
                            actMargin: subGroupActMargin,
                            // totalCost: subGroupTotalCost,
                            totalCost: calculateItemTotalCost(subGroupRow[0].qty, subGroupstEecost),
                            totalPrice: subGroupTotalPrice,
                            stFutureCost: subGroupStFutureCost,
                            frcMargin: subGroupFrcMargin,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            stFutureCost: subGroupStFutureCost,
                            //  lcMargin: "",
                        };

                        updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                        subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                        const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                            if (subGroupRow.qty > 1) {
                                return {
                                    ...subGroupRow,
                                    nettEA: subGroupRow.nettEA * subGroupRow.qty,
                                    // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                                    listPrice: subGroupRow.listPrice * subGroupRow.qty,
                                    stEecost: subGroupRow.stEecost * subGroupRow.qty,
                                    stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                                    actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                                    stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                                    stFutureCostRound: parseFloat(
                                        (subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)
                                    ),
                                    actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                                    //    lcMargin: "",
                                };
                            }
                            return subGroupRow;
                        });

                        groupItems = [...getGroupedRowItems(updatedItemList, gId), ...updatedSubGroupRows]; // to get the updated sub group row
                    }

                    groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    // groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                    groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                    if (existingDbExtra.length > 0) {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupstEecost
                        );
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA - groupDbExtra,
                            groupActCostExact
                        );
                    } else {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                    let groupRowIndex = -1;
                    let groupQty = -1;
                    let hasGroupQtyChanged = false;
                    let updatedGroupRow = null;

                    if (groupRow) {
                        groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                        groupQty = groupRow.qty;
                        hasGroupQtyChanged = groupQty > 1;

                        updatedGroupRow = {
                            ...groupRow,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stEecost: groupstEecost,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                            nettEA: groupNettEA,
                            actMargin: groupActMargin,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                            totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                            totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                            //   lcMargin: "",
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                }

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            } else if (onASubGroupRow) {
                // const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                // let dbExtraValueSb = -1;

                // let rowItems = newItems.map((item) => {
                //     return {
                //         ...item,
                //         groupId: gId,
                //         groupName: gName,
                //         displayGroupName: "",
                //         subGroupId: sgId,
                //         subGroupName: sgName,
                //         displaySubGroupName: "",
                //     };
                // });

                // updatedItemList.splice(itemIndex, 0, ...rowItems);

                // if (existingDbExtra.length > 0) {
                //     const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                //     const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                //     dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                //     const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                //     const updatedSubGroupDbExtraRow = {
                //         ...existingDbExtra[0],
                //         nettEA: dbExtraValueSb,
                //         totalPrice: dbExtraValueSb,
                //     };

                //     updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                // }

                // //ref : sub group row calculation
                // let updatedSubGroupMargin = -1;
                // let updatedSubGroupFrcMargin = -1;

                // const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                // const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                // const updatedSubGroupCostEAExact = calculateGroupWiseTotal(subGroupedItems, "costEAExact");
                // const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                // const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                // const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                // const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                // const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                // if (existingDbExtra.length > 0) {
                //     updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                //         updatedSubGroupNettEA - dbExtraValueSb,
                //         updatedSubGroupCostEAExact
                //     );
                //     updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                //         updatedSubGroupNettEA - dbExtraValueSb,
                //         updatedSubGroupStFutureCost
                //     );
                // } else {
                //     updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                //         updatedSubGroupNettEA,
                //         updatedSubGroupCostEAExact
                //     );
                //     updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                //         updatedSubGroupNettEA,
                //         updatedSubGroupStFutureCost
                //     );
                // }

                // const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId);
                // const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);

                // const updatedSubGroupRow = {
                //     ...subGroupRow[0],
                //     listPrice: updatedSubGroupListPrice,
                //     costEA: parseFloat(updatedSubGroupCostEAExact.toFixed(2)),
                //     costEAExact: updatedSubGroupCostEAExact,
                //     stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                //     stFutureCost: updatedSubGroupStFutureCost,
                //     actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                //     actCostExact: updatedSubGroupActCostExact,
                //     nettEA: updatedSubGroupNettEA,
                //     margin: updatedSubGroupMargin,
                //     frcMargin: updatedSubGroupFrcMargin,
                //     totalCost: updatedTotalCost,
                //     totalPrice: updatedSubGroupTotalPrice,
                // };

                // updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                // const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                // let groupDbExtra = -1;

                // if (existingGroupDbExtra.length > 0) {
                //     const groupItems = getAllItemsInAGroup(updatedItemList, gId);
                //     const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                //     const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                //     groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                //     const updatedGroupDbExtraRow = {
                //         ...existingGroupDbExtra[0],
                //         nettEA: groupDbExtra,
                //         totalPrice: groupDbExtra,
                //     };

                //     updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                // }

                // //ref : group row calculation
                // let updatedGroupMargin = -1;
                // let updatedGroupFrcMargin = -1;

                // const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                // // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                // const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                // const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                // const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                //     if (subGroupRow.qty > 1) {
                //         return {
                //             ...subGroupRow,
                //             nettEA: subGroupRow.nettEA * subGroupRow.qty,
                //             // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                //             listPrice: subGroupRow.listPrice * subGroupRow.qty,
                //             costEAExact: subGroupRow.costEAExact * subGroupRow.qty,
                //             actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                //             costEA: parseFloat((subGroupRow.costEAExact * subGroupRow.qty).toFixed(2)),
                //             actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                //         };
                //     }
                //     return subGroupRow;
                // });

                // const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                // const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                // const updatedGroupCostEAExact = calculateGroupWiseTotal(groupItems, "costEAExact");
                // const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                // const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                // const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                // const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                // const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                // if (groupLevelDbExtra.length > 0) {
                //     updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                //         updatedGroupNettEA - groupDbExtra,
                //         updatedGroupCostEAExact
                //     );
                //     updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                //         updatedGroupNettEA - groupDbExtra,
                //         updatedGroupStFutureCost
                //     );
                // } else {
                //     updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                //         updatedGroupNettEA,
                //         updatedGroupCostEAExact
                //     );
                //     updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                //         updatedGroupNettEA,
                //         updatedGroupStFutureCost
                //     );
                // }

                // const groupRow = getGroupRowByGroup(updatedItemList, gId);
                // const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                // const groupQty = groupRow.qty;
                // const hasGroupQtyChanged = groupQty > 1;

                // const updatedGroupRow = {
                //     ...groupRow[0],
                //     listPrice: updatedGroupListPrice,
                //     costEA: parseFloat(updatedGroupCostEAExact.toFixed(2)),
                //     stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                //     stFutureCost: updatedGroupStFutureCost,
                //     actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                //     costEAExact: updatedGroupCostEAExact,
                //     actCostExact: updatedGroupActCostExact,
                //     nettEA: updatedGroupNettEA,
                //     margin: updatedGroupMargin,
                //     frcMargin: updatedGroupFrcMargin,
                //     totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                //     totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                // };

                // updatedItemList[groupRowIndex] = updatedGroupRow;

                // setItemList(updatedItemList);
                // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                const existingDbExtra = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId);
                let dbExtraValueSb = -1;

                let rowItems = newItems.map((item) => {
                    return {
                        ...item,
                        groupId: gId,
                        groupName: gName,
                        displayGroupName: "",
                        subGroupId: sgId,
                        subGroupName: sgName,
                        displaySubGroupName: "",
                    };
                });

                updatedItemList.splice(itemIndex, 0, ...rowItems);

                if (existingDbExtra.length > 0) {
                    const subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, gId, sgId);
                    const nettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    dbExtraValueSb = calculateDbExtra(dbExtraCharge, nettEA);
                    const dBExtraIndex = getDbExtraRowIndex(updatedItemList, gId, sgId, GroupTypes.SUB_GROUP);

                    const updatedSubGroupDbExtraRow = {
                        ...existingDbExtra[0],
                        nettEA: dbExtraValueSb,
                        totalPrice: dbExtraValueSb,
                    };

                    updatedItemList[dBExtraIndex] = updatedSubGroupDbExtraRow;
                }

                //ref : sub group row calculation
                let updatedSubGroupMargin = -1;
                let updatedSubGroupFrcMargin = -1;
                let updatedSubGroupActMargin = -1;

                const subGroupedItems = getAllExistingSubGroupedItems(updatedItemList, gId, sgId);
                const updatedSubGroupListPrice = calculateGroupWiseTotal(subGroupedItems, "listPrice");
                const updatedSubGroupstEecost = calculateGroupWiseTotal(subGroupedItems, "stEecost");
                const updatedSubGroupStFutureCost = calculateGroupWiseTotal(subGroupedItems, "stFutureCost");
                const updatedSubGroupActCostExact = calculateGroupWiseTotal(subGroupedItems, "actCostExact");
                const updatedSubGroupNettEA = calculateGroupWiseTotal(subGroupedItems, "nettEA");
                const updatedTotalCost = calculateGroupWiseTotal(subGroupedItems, "totalCost");
                const updatedSubGroupTotalPrice = calculateGroupWiseTotal(subGroupedItems, "totalPrice");

                if (existingDbExtra.length > 0) {
                    updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupstEecost
                    );
                    updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupStFutureCost
                    );
                    updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedSubGroupNettEA - dbExtraValueSb,
                        updatedSubGroupActCostExact
                    );
                } else {
                    updatedSubGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupstEecost
                    );
                    updatedSubGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupStFutureCost
                    );
                    updatedSubGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedSubGroupNettEA,
                        updatedSubGroupActCostExact
                    );
                }

                const subGroupRow = getSubGroupRowByGroupAndSubGroup(updatedItemList, gId, sgId)[0];
                const subGroupRowIndex = getSubGroupRowIndex(updatedItemList, gId, sgId);
                const subGroupQty = subGroupRow.qty;

                const updatedSubGroupRow = {
                    ...subGroupRow,
                    listPrice: updatedSubGroupListPrice,
                    stEecostRound: parseFloat(updatedSubGroupstEecost.toFixed(2)),
                    stEecost: updatedSubGroupstEecost,
                    stFutureCostRound: parseFloat(updatedSubGroupStFutureCost.toFixed(2)),
                    stFutureCost: updatedSubGroupStFutureCost,
                    actCost: parseFloat(updatedSubGroupActCostExact.toFixed(2)),
                    actCostExact: updatedSubGroupActCostExact,
                    nettEA: updatedSubGroupNettEA,
                    margin: updatedSubGroupMargin,
                    actMargin: updatedSubGroupActMargin,
                    frcMargin: updatedSubGroupFrcMargin,
                    // totalCost: updatedTotalCost * subGroupQty,
                    totalCost: calculateItemTotalCost(subGroupQty, updatedSubGroupstEecost),
                    totalPrice: updatedSubGroupTotalPrice * subGroupQty,
                    //   lcMargin: "",
                };

                updatedItemList[subGroupRowIndex] = updatedSubGroupRow;

                const existingGroupDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                let groupDbExtra = -1;

                // if (existingGroupDbExtra.length > 0) {
                //     const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                //     groupNettEA = calculateGroupWiseTotal(gItms, "nettEA");
                //     groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                //     const updatedGroupDbExtraRow = {
                //         ...existingGroupDbExtra[0],
                //         nettEA: groupDbExtra,
                //         totalPrice: groupDbExtra,
                //     };

                //     updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                // }

                if (existingGroupDbExtra.length > 0) {
                    const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(updatedItemList, gId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                    const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                        const sbId = subGroupRow.subGroupId;
                        const sGRowItems = getAllExistingSubGroupedItems(updatedItemList, gId, sbId);
                        const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(sGRowItems, gId, sbId);
                        const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                        return {
                            ...subGroupRow,
                            nettEA: nettEA * parseInt(subGroupRow.qty),
                        };
                    });

                    const groupItems = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                    const groupDbExtraIndex = getDbExtraRowIndex(updatedItemList, gId, null, GroupTypes.GROUP);
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedGroupDbExtraRow = {
                        ...existingGroupDbExtra[0],
                        nettEA: groupDbExtra,
                        totalPrice: groupDbExtra,
                    };

                    updatedItemList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                }

                //ref : group row calculation
                let updatedGroupMargin = -1;
                let updatedGroupFrcMargin = -1;
                let updatedGroupActMargin = -1;

                const groupLevelDbExtra = getExistingGroupWiseDbExtraRow(updatedItemList, gId);
                // const groupItems = getAllExistingGroupedItems(updatedItemList, gId);

                const groupRowItems = getGroupedRowItems(updatedItemList, gId);
                const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, gId);
                const updatedSubGroupRows = subGroupRows.map((subGroupRow) => {
                    if (subGroupRow.qty > 1) {
                        return {
                            ...subGroupRow,
                            nettEA: subGroupRow.nettEA * subGroupRow.qty,
                            // totalPrice: subGroupRow.totalPrice * subGroupRow.qty,
                            listPrice: subGroupRow.listPrice * subGroupRow.qty,
                            stEecost: subGroupRow.stEecost * subGroupRow.qty,
                            stFutureCost: subGroupRow.stFutureCost * subGroupRow.qty,
                            actCostExact: subGroupRow.actCostExact * subGroupRow.qty,
                            stEecostRound: parseFloat((subGroupRow.stEecost * subGroupRow.qty).toFixed(2)),
                            stFutureCostRound: parseFloat((subGroupRow.stFutureCost * subGroupRow.qty).toFixed(2)),
                            actCost: parseFloat((subGroupRow.actCostExact * subGroupRow.qty).toFixed(2)),
                            //lcMargin: "",
                        };
                    }
                    return subGroupRow;
                });

                const groupItems = [...groupRowItems, ...updatedSubGroupRows];

                const updatedGroupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                const updatedGroupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                const updatedGroupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                const updatedGroupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                const updatedGroupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                const updatedGroupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                const updatedGroupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                if (groupLevelDbExtra.length > 0) {
                    updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupstEecost
                    );
                    updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupStFutureCost
                    );
                    updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedGroupNettEA - groupDbExtra,
                        updatedGroupActCostExact
                    );
                } else {
                    updatedGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupstEecost
                    );
                    updatedGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupStFutureCost
                    );
                    updatedGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        updatedGroupNettEA,
                        updatedGroupActCostExact
                    );
                }

                const groupRow = getGroupRowByGroup(updatedItemList, gId)[0];
                const groupRowIndex = getGroupRowIndex(updatedItemList, gId);
                const groupQty = groupRow.qty;
                const hasGroupQtyChanged = groupQty > 1;

                const updatedGroupRow = {
                    ...groupRow,
                    listPrice: updatedGroupListPrice,
                    stEecostRound: parseFloat(updatedGroupstEecost.toFixed(2)),
                    stFutureCostRound: parseFloat(updatedGroupStFutureCost.toFixed(2)),
                    stFutureCost: updatedGroupStFutureCost,
                    actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                    stEecost: updatedGroupstEecost,
                    actCostExact: updatedGroupActCostExact,
                    nettEA: updatedGroupNettEA,
                    margin: updatedGroupMargin,
                    actMargin: updatedGroupActMargin,
                    frcMargin: updatedGroupFrcMargin,
                    // totalCost: hasGroupQtyChanged ? updatedGroupTotalCost * groupQty : updatedGroupTotalCost,
                    totalCost: calculateItemTotalCost(groupQty, updatedGroupstEecost),
                    totalPrice: hasGroupQtyChanged ? updatedGroupTotalPrice * groupQty : updatedGroupTotalPrice,
                    //  lcMargin: "",
                };

                updatedItemList[groupRowIndex] = updatedGroupRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            }
        } else {
            if (groupId) {
                const groupedList = newItems.map((res) => {
                    return {
                        ...res,
                        groupId: groupId,
                        groupName: `Group ${groupCount - 1}`,
                        displayGroupName: "",
                        subGroupId: "",
                        subGroupName: "",
                        displaySubGroupName: "",
                    };
                });

                if (subGroupId) {
                    const subGroupedList = groupedList.map((res) => {
                        return {
                            ...res,
                            subGroupId: subGroupId,
                            subGroupName: `Sub Group ${subGroupCount - 1}`,
                        };
                    });

                    const combinedList = [...itemList, ...subGroupedList];

                    let subGroupLevelDbExtra = isDbExtraAdded(combinedList, groupId, subGroupId, GroupTypes.SUB_GROUP);

                    let updatedNettEA = -1;
                    let groupDbExtra = -1;

                    //check if there is any dbExtra added for the sub group
                    if (subGroupLevelDbExtra) {
                        const dbExtraRowIndexSB = getDbExtraRowIndex(
                            combinedList,
                            groupId,
                            subGroupId,
                            GroupTypes.SUB_GROUP
                        );
                        const dbExtraRowSB = combinedList[dbExtraRowIndexSB];
                        let listWithoutDbExtra = getItemsWithoutDBExtra(
                            combinedList,
                            GroupTypes.SUB_GROUP,
                            groupId,
                            subGroupId
                        );
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const totalNettEASB = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEASB);

                        const subGroupDbExtraRow = {
                            ...dbExtraRowSB,
                            nettEA: updatedNettEA,
                            totalPrice: updatedNettEA,
                        };

                        combinedList[dbExtraRowIndexSB] = subGroupDbExtraRow;

                        //group db extra calculations
                        const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);
                        const groupItems = getAllItemsInAGroup(combinedList, groupId);
                        const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                        const updatedGroupDbExtraRow = {
                            ...existingGroupWiseDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    } else {
                        //if there is no dbExtra added for the sub group

                        //check if there is any dbExtra added for the group
                        const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, groupId);

                        if (existingGroupWiseDbExtra.length > 0) {
                            const groupItems = getAllItemsInAGroup(combinedList, groupId);
                            const groupDbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                            const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);

                            const updatedGroupDbExtraRow = {
                                ...existingGroupWiseDbExtra[0],
                                nettEA: groupDbExtra,
                                totalPrice: groupDbExtra,
                            };

                            combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                        }
                    }

                    //to persist the group values(list price, act Cost, nettEA etc) when the qty is changed
                    const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                    const subGroupItems = getAllExistingSubGroupedItems(combinedList, groupId, subGroupId);

                    const hasQtyChangedInSubGroups = subGroupRows.some((item) => item.qty > 1);
                    const hasQtyChangedInSGItems = subGroupItems.some((item) => item.qty > 1);

                    let rowList = [];
                    let qtyUnchangedSubGroups = [];
                    let qtyUnchangedSubGroupItems = [];

                    if (hasQtyChangedInSubGroups) {
                        qtyUnchangedSubGroups = subGroupRows.filter((item) => item.qty === 1);
                    }

                    if (hasQtyChangedInSGItems) {
                        qtyUnchangedSubGroupItems = subGroupItems.filter((item) => item.qty === 1);
                    }

                    if (hasQtyChangedInSubGroups) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                        const subGroupRowIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);
                        const updatedQty = subGroupRow[0].qty;
                        let updatedSubGroupRow = null;

                        let updatedSGTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                        let updatedSGTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                        let updatedSGNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        let updatedSGActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        let updatedSGstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        let updatedSGStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        let updatedSGListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");

                        updatedSubGroupRow = {
                            ...subGroupRow[0],
                            qty: updatedQty,
                            // totalCost: updatedSGTotalCost,
                            totalCost: calculateItemTotalCost(updatedQty, updatedSGstEecost),
                            totalPrice: updatedSGTotalPrice * updatedQty,
                            nettEA: updatedSGNettEA,
                            actCostExact: updatedSGActCostExact,
                            stEecost: updatedSGstEecost,
                            stFutureCost: updatedSGStFutureCost,
                            listPrice: updatedSGListPrice,
                            actCost: parseFloat(parseFloat(updatedSGActCostExact).toFixed(2)),
                            stEecostRound: parseFloat(parseFloat(updatedSGstEecost).toFixed(2)),
                            stFutureCostRound: parseFloat(parseFloat(updatedSGStFutureCost).toFixed(2)),
                            // lcMargin: "",
                        };

                        combinedList[subGroupRowIndex] = updatedSubGroupRow;

                        //group row calculations
                        let groupRow = getGroupRowByGroup(combinedList, groupId)[0];
                        let groupRowIndex = getGroupRowIndex(combinedList, groupId);
                        let subGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId);
                        let groupRowItems = getGroupedRowItems(combinedList, groupId);
                        let updatedGroupRow = null;
                        let subGroupsWithoutCurrent = subGroupRows.filter((item) => item.subGroupId !== subGroupId);
                        let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                            if (item.qty > 1) {
                                return {
                                    ...item,
                                    listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                    actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                    nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                    stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                    stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                    actCost: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(
                                            2
                                        )
                                    ),
                                    stEecostRound: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(
                                            2
                                        )
                                    ),
                                };
                            }

                            return item;
                        });

                        const concatList = [...updatedSubGroupsWithoutCurrent, ...groupRowItems];

                        let gTotalCost = calculateGroupWiseTotal(concatList, "totalCost");
                        let gTotalPrice = calculateGroupWiseTotal(concatList, "totalPrice");
                        let gNettEA = calculateGroupWiseTotal(concatList, "nettEA");
                        let gActCostExact = calculateGroupWiseTotal(concatList, "actCostExact");
                        let gstEecost = calculateGroupWiseTotal(concatList, "stEecost");
                        let gStFutureCost = calculateGroupWiseTotal(concatList, "stFutureCost");
                        let gListPrice = calculateGroupWiseTotal(concatList, "listPrice");

                        //quantity changed group calculations
                        const hasGroupQtyChanged = groupRow.qty > 1;

                        updatedGroupRow = {
                            ...groupRow,
                            // totalCost: hasGroupQtyChanged
                            //     ? parseFloat(((gTotalCost + updatedSubGroupRow.totalCost) * groupRow.qty).toFixed(2))
                            //     : parseFloat((gTotalCost + updatedSubGroupRow.totalCost).toFixed(2)),
                            totalCost: hasGroupQtyChanged
                                ? parseFloat(
                                    (
                                        (calculateItemTotalCost(groupRow.qty, gstEecost) +
                                            updatedSubGroupRow.totalCost) *
                                        groupRow.qty
                                    ).toFixed(2)
                                )
                                : parseFloat(
                                    (
                                        calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost
                                    ).toFixed(2)
                                ),
                            totalPrice: hasGroupQtyChanged
                                ? parseFloat(((gTotalPrice + updatedSubGroupRow.totalPrice) * groupRow.qty).toFixed(2))
                                : parseFloat((gTotalPrice + updatedSubGroupRow.totalPrice).toFixed(2)),
                            nettEA: parseFloat((gNettEA + updatedSubGroupRow.nettEA * updatedQty).toFixed(2)),
                            actCostExact: gActCostExact + updatedSubGroupRow.actCostExact * updatedQty,
                            stEecost: gstEecost + updatedSubGroupRow.stEecost * updatedQty,
                            stFutureCost: gStFutureCost + updatedSubGroupRow.stFutureCost * updatedQty,
                            listPrice: parseFloat((gListPrice + updatedSubGroupRow.listPrice * updatedQty).toFixed(2)),
                            actCost: parseFloat(
                                parseFloat(gActCostExact + updatedSubGroupRow.actCostExact * updatedQty).toFixed(2)
                            ),
                            stEecostRound: parseFloat(
                                parseFloat(gstEecost + +(updatedSubGroupRow.stEecost * updatedQty)).toFixed(2)
                            ),
                            stFutureCostRound: parseFloat(
                                parseFloat(gStFutureCost + +(updatedSubGroupRow.stFutureCost * updatedQty)).toFixed(2)
                            ),
                        };

                        combinedList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(combinedList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                combinedList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    combinedList,
                                    dbExtraCharge
                                );
                                combinedList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        // footer calculation
                        const {
                            totalPrice,
                            totalSteEeCostRound,
                            totalStFutureCost,
                            totalActCostExact,
                            totalListPrice,
                            totalNettEA,
                            totalCost,
                            totalMargin,
                            totalActMargin,
                            totalFrcMargin,
                            totalDcOnLp,
                        } = footerCalculation(combinedList);

                        setTotalItemPrice(totalPrice);
                        setTotalCostAmt(totalCost);
                        setTotalstEecostRound(totalSteEeCostRound);
                        setTotalStFutureCost(totalStFutureCost);
                        setTotalActCostEAExact(totalActCostExact);
                        setTotalListPrice(totalListPrice);
                        setTotalCost(totalCost);
                        setTotalNettEA(totalNettEA);
                        setMaxMargin(totalMargin);
                        setActMargin(totalActMargin);
                        setMaxFrcMargin(totalFrcMargin);
                        setFooterDisc(totalDcOnLp);

                        setItemList(combinedList);
                        updateQuoteDetailsForId(id, { selectedItems: combinedList });
                    } else {
                        let subGroupMargin = -1;
                        let subGroupFrcMargin = -1;
                        let subGroupActMargin = -1;

                        const subGroupListPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "listPrice");
                        const subGroupNettEA = calculateSubGroupWiseTotal(subGroupId, combinedList, "nettEA");
                        const subGroupTotalPrice = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalPrice");
                        const subGroupstEecost = calculateSubGroupWiseTotal(subGroupId, combinedList, "stEecost");
                        const subGroupStFutureCost = calculateSubGroupWiseTotal(
                            subGroupId,
                            combinedList,
                            "stFutureCost"
                        );
                        const subGroupTotalCost = calculateSubGroupWiseTotal(subGroupId, combinedList, "totalCost");
                        const subGroupActCostExact = calculateSubGroupWiseTotal(
                            subGroupId,
                            combinedList,
                            "actCostExact"
                        );
                        // const totalDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                        const totalDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

                        if (subGroupLevelDbExtra) {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupstEecost
                            );
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA - groupDbExtra,
                                subGroupActCostExact
                            );
                        } else {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        }

                        const emptySubGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, groupId, subGroupId);
                        const subGroupIndex = getSubGroupRowIndex(combinedList, groupId, subGroupId);

                        const updatedEmptySubGroupRow = {
                            ...emptySubGroupRow[0],
                            listPrice: subGroupListPrice,
                            nettEA: subGroupNettEA,
                            totalPrice: subGroupTotalPrice,
                            dcOnLp: totalDcOnLp,
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            stFutureCost: subGroupStFutureCost,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            qty: emptySubGroupRow[0].qty ? emptySubGroupRow[0].qty : 1,
                            margin: subGroupMargin,
                            actMargin: subGroupActMargin,
                            frcMargin: subGroupFrcMargin,
                            // totalCost: subGroupTotalCost,
                            totalCost: calculateItemTotalCost(emptySubGroupRow[0].qty, subGroupstEecost),
                            stEecost: subGroupstEecost,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            //  lcMargin: "",
                        };

                        //updates the sub group row
                        combinedList[subGroupIndex] = updatedEmptySubGroupRow;

                        //ref : for group calculations
                        const updatedSubGroupRows = getAllExistingSubGroupsByGroup(combinedList, groupId); // to get the sub groups after the above update
                        const groupRows = getGroupedRowItems(combinedList, groupId);
                        const emptyGroupRow = getGroupRowByGroup(combinedList, groupId);
                        const emptyGroupRowIndex = getGroupRowIndex(combinedList, groupId);

                        hasQtyChangedInSubGroups
                            ? (rowList = [...emptyGroupRow, ...qtyUnchangedSubGroups, ...groupRows])
                            : (rowList = [...updatedSubGroupRows, ...groupRows]);

                        //check for group level db extra
                        const groupLevelDbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);

                        let groupMargin = -1;
                        let groupFrcMargin = -1;
                        let groupActMargin = -1;

                        const groupListPrice = calculateGroupWiseTotal(rowList, "listPrice");
                        const groupNettEA = calculateGroupWiseTotal(rowList, "nettEA");
                        const groupTotalPrice = calculateGroupWiseTotal(rowList, "totalPrice");
                        const groupstEecost = calculateGroupWiseTotal(rowList, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(rowList, "stFutureCost");
                        const groupTotalCost = calculateGroupWiseTotal(rowList, "totalCost");
                        const groupActCostExact = calculateGroupWiseTotal(rowList, "actCostExact");
                        // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                        const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                        if (groupLevelDbExtra) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const hasGroupQtyChanged = emptyGroupRow[0].qty > 1;

                        const updatedEmptyGroupRow = {
                            ...emptyGroupRow[0],
                            listPrice: groupListPrice,
                            nettEA: groupNettEA,
                            totalPrice: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalPrice : groupTotalPrice,
                            dcOnLp: groupDcOnLp,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            qty: emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1 ,
                            margin: groupMargin,
                            actMargin: groupActMargin,
                            frcMargin: groupFrcMargin,
                            // totalCost: hasGroupQtyChanged ? emptyGroupRow[0].qty * groupTotalCost : groupTotalCost,
                            totalCost: calculateItemTotalCost(emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1, groupstEecost),
                            stEecost: groupstEecost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                        };

                        combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(combinedList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                combinedList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    combinedList,
                                    dbExtraCharge
                                );
                                combinedList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        updateQuoteDetailsForId(id, { selectedItems: combinedList });
                        setItemList(combinedList);
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(combinedList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);
                    // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);
                    // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);
                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: combinedList[0],
                        });
                    }
                } else {
                    const combinedList = [...itemList, ...groupedList];

                    const dbExtra = isDbExtraAdded(combinedList, groupId, null, GroupTypes.GROUP);
                    let updatedNettEA;

                    if (dbExtra) {
                        const dbExtraIndex = getDbExtraRowIndex(combinedList, groupId, null, GroupTypes.GROUP);
                        let listWithoutDbExtra = getItemsWithoutDBExtra(combinedList, GroupTypes.GROUP, groupId);
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                        const dbExtraRow = combinedList[dbExtraIndex];

                        const updatedDbExtraRow = {
                            ...dbExtraRow,
                            nettEA: updatedNettEA,
                            totalPrice: updatedNettEA,
                        };

                        combinedList[dbExtraIndex] = updatedDbExtraRow;
                    }

                    const groupRows = combinedList.filter(
                        (item) =>
                            item.groupId === groupId &&
                            (item.subGroupId === "" || item.subGroupId === "00000000-0000-0000-0000-000000000000") &&
                            item.stStockCode !== ""
                    );

                    let groupMargin = -1;
                    let groupFrcMargin = -1;
                    let groupActMargin = -1;

                    const groupListPrice = calculateGroupWiseTotal(groupRows, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupRows, "nettEA");
                    const groupTotalPrice = calculateGroupWiseTotal(groupRows, "totalPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupRows, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupRows, "stFutureCost");
                    const groupTotalCost = calculateGroupWiseTotal(groupRows, "totalCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupRows, "actCostExact");
                    // const groupTotalDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupTotalDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                    if (dbExtra) {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupstEecost
                        );
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupStFutureCost
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEA - updatedNettEA,
                            groupActCostExact
                        );
                    } else {
                        groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                    }

                    const emptyGroupRow = combinedList.filter(
                        (item) => item.displayGroupName !== "" && item.groupId === groupId
                    );

                    const emptyGroupRowIndex = combinedList.findIndex(
                        (item) => item.displayGroupName !== "" && item.groupId === groupId
                    );

                    const groupQty = emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1;
                    const hasGroupQtyChanged = groupQty > 1;

                    const updatedEmptyGroupRow = {
                        ...emptyGroupRow[0],
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                        dcOnLp: groupTotalDcOnLp,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        qty: groupQty,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                        stEecost: groupstEecost,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        //lcMargin: "",
                    };

                    combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(combinedList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                            combinedList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    updateQuoteDetailsForId(id, { selectedItems: combinedList });
                    setItemList(combinedList);

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(combinedList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );

                    // setTotalActCostEAExact(totalActCostEa);
                    // const totalListPrice = calculateTotalListPrice(
                    //     combinedList.filter((item) => item.stStockCode != " ")
                    // );

                    // setTotalListPrice(totalListPrice);

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: combinedList[0],
                        });
                    }
                }
            } else {
                const nonGroupId = uuidv4();
                if (!nonGroupedId.current) nonGroupedId.current = nonGroupId;

                const updatedNewItems = newItems.map((res) => {
                    return {
                        ...res,
                        groupId: nonGroupedId.current ? nonGroupedId.current : nonGroupId,
                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                        displayGroupName: "",
                        subGroupId: "",
                        subGroupName: "",
                        displaySubGroupName: "",
                        margin: parseFloat(parseFloat(res.margin).toFixed(1)),
                        frcMargin: parseFloat(parseFloat(res.frcMargin).toFixed(1)),
                        actMargin: parseFloat(parseFloat(res.actMargin).toFixed(1)),
                        // lcMargin: "",
                    };
                });

                const combinedList = [...itemList, ...updatedNewItems];
                const dbExtra = isDbExtraAdded(combinedList, nonGroupedId.current, null, GroupTypes.NON_GROUP);

                if (dbExtra) {
                    let listWithoutDbExtra = getItemsWithoutDBExtra(
                        combinedList,
                        GroupTypes.NON_GROUP,
                        nonGroupedId.current,
                        null
                    );
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    const updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                    const dbExtraIndex = getDbExtraRowIndex(
                        combinedList,
                        nonGroupedId.current,
                        null,
                        GroupTypes.NON_GROUP
                    );
                    const dbExtraRow = combinedList[dbExtraIndex];

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    combinedList[dbExtraIndex] = updatedDbExtraRow;
                }

                setItemList(combinedList);
                updateQuoteDetailsForId(id, { selectedItems: combinedList });

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(combinedList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                // const totalPrice = calculateTotalPrice(combinedList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(combinedList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(combinedList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(combinedList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(combinedList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(combinedList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(combinedList);
                // setTotalListPrice(totalListPrice);
            }
        }

        closeDialog();
        setVisiblePasteOption(false);
        setBomIsChecked(true);
    };
    const calculateGroupDcOnLp = (listPrice, nettEA) => {
        return parseFloat(parseFloat(listPrice).toFixed(2)) == 0.0
            ? 0.0
            : parseFloat((((listPrice - nettEA) / listPrice) * 100).toFixed(2));
        // if (parseFloat(currentListPrice) === 0.0) return 0.0;
        // const dcOnLp = parseFloat((((currentListPrice - newNettEa) / currentListPrice) * 100).toFixed(1));
        // return dcOnLp > 0 ? dcOnLp : 0.0;
    };

    // Qty Change Related Functions

    const calculateNettEAForQtyChange = (itemList) => {
        const totalNettEA = itemList.reduce((total, item) => {
            const nettEA = parseFloat(item.nettEA);
            const qty = parseInt(item.qty);

            if (!isNaN(nettEA)) {
                const t = total + nettEA * qty;
                return t;
            }

            return total;
        }, 0);

        return parseFloat(parseFloat(totalNettEA).toFixed(2));
    };

    const calculateListPriceForQtyChange = (itemList) => {
        const totalListPrice = itemList.reduce((total, item) => {
            const listPrice = parseFloat(item.listPrice);
            const qty = parseInt(item.qty);

            if (!isNaN(listPrice)) {
                const t = total + listPrice * qty;
                return t;
            }

            return total;
        }, 0);

        return parseFloat(parseFloat(totalListPrice).toFixed(2));
    };

    const calculatestEecostForQtyChange = (itemList) => {
        const totalstEecost = itemList.reduce((total, item) => {
            const stEecost = parseFloat(item.stEecost);
            const qty = parseInt(item.qty);

            if (!isNaN(stEecost)) {
                return total + stEecost * qty;
            }

            return total;
        }, 0);

        return parseFloat(parseFloat(totalstEecost).toFixed(2));
    };
    const calculateStFutureCostForQtyChange = (itemList) => {
        const totalStFutureCost = itemList.reduce((total, item) => {
            const stFutureCost = parseFloat(item.stFutureCost);
            const qty = parseInt(item.qty);

            if (!isNaN(stFutureCost)) {
                return total + stFutureCost * qty;
            }

            return total;
        }, 0);

        return parseFloat(parseFloat(totalStFutureCost).toFixed(2));
    };
    const calculateActCostExactForQtyChange = (itemList) => {
        const totalActCostExact = itemList.reduce((total, item) => {
            const actCostExact = parseFloat(item.actCostExact);
            const qty = parseInt(item.qty);

            if (!isNaN(actCostExact)) {
                return total + actCostExact * qty;
            }

            return total;
        }, 0);

        return parseFloat(parseFloat(totalActCostExact).toFixed(2));
    };

    //Stock Number Change Related Functions
    const calculateGroupWiseMarginForSNumberChange = (groupWiseNettEa, groupWisestEecost) => {
        return parseFloat(groupWiseNettEa) === 0.0
            ? 0.0
            : parseFloat(
                (
                    ((parseFloat(groupWiseNettEa) - parseFloat(groupWisestEecost)) / parseFloat(groupWiseNettEa)) *
                    100
                ).toFixed(1)
            );
    };
    const calculateGroupWiseFrcMarginForSNumberChange = (groupWiseNettEa, groupWiseStFutureCost) => {
        return parseFloat(groupWiseNettEa) === 0.0
            ? 0.0
            : parseFloat(
                (
                    ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseStFutureCost)) /
                        parseFloat(groupWiseNettEa)) *
                    100
                ).toFixed(1)
            );
    };
    const calculateGroupWiseActMarginForSNumberChange = (groupWiseNettEa, groupWiseActCostExact) => {
        return parseFloat(groupWiseNettEa) === 0.0
            ? 0.0
            : parseFloat(
                (
                    ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseActCostExact)) /
                        parseFloat(groupWiseNettEa)) *
                    100
                ).toFixed(1)
            );
    };
    //repDisc Change Related Functions
    const calculateNettEaForRepDiscChangeAfterCustomerChange = (stockNumber, newRepDisc) => {
        const stock = newCustomerStockDetails.current.filter((item) => {
            if (item) {
                return item.stStockCode === stockNumber;
            }
        });
        const newCustomerNettEa = stock[0]?.nettEA;
        return newCustomerNettEa !== 0 ? parseFloat((((100 - newRepDisc) / 100) * newCustomerNettEa).toFixed(2)) : 0;
    };

    const [updatedNettEA, setUpdatedNettEA] = useState(0);
    useEffect(() => { }, [updatedNettEA]);
    const [isNettEAChanged, setIsNettEAChanged] = useState(false);
    useEffect(() => { }, [isNettEAChanged]);
    // const nettEAChangedIndexs = [];
    const [nettEAChangedIndexs, setNettEAChangedIndexs] = useState([]);

    const triggerComputeGrpTotals = (newItems, updatedItemList, itemAction, grpId, subGrpId, dbExtraCharge) => {
        let updatedItems = [];
        if (grpId) {
            const groupedList = newItems.map((res) => {
                if (itemAction === "Add" && (newItems[0].groupId === "" || newItems[0].groupName === "")) {
                    /// seperated returns due to during updating items, there are some cases that we need the actual groupId. This below code are suited only for adding new items on the grid
                    return {
                        ...res,
                        groupId: grpId,
                        groupName: `Group ${groupCount - 1}`,
                        displayGroupName: "",
                        subGroupId: "",
                        subGroupName: "",
                        displaySubGroupName: "",
                    };
                } else {
                    return res;
                }
            });

            if (subGrpId) {
                const subGroupedList = groupedList.map((res) => {
                    return {
                        ...res,
                        subGroupId: subGrpId,
                    };
                });

                let combinedList = [];
                combinedList = itemAction == "Add" ? [...updatedItemList, ...subGroupedList] : [...updatedItemList];
                let subGroupLevelDbExtra = isDbExtraAdded(combinedList, grpId, subGrpId, GroupTypes.SUB_GROUP);

                let updatedNettEA = -1;
                let groupDbExtra = 0;

                //check if there is any dbExtra added for the sub group
                if (subGroupLevelDbExtra) {
                    const dbExtraRowIndexSB = getDbExtraRowIndex(combinedList, grpId, subGrpId, GroupTypes.SUB_GROUP);
                    const dbExtraRowSB = combinedList[dbExtraRowIndexSB];

                    let listWithoutDbExtra = getItemsWithoutDBExtra(
                        combinedList,
                        GroupTypes.SUB_GROUP,
                        grpId,
                        subGrpId
                    );
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEASB = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEASB);

                    const subGroupDbExtraRow = {
                        ...dbExtraRowSB,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    combinedList[dbExtraRowIndexSB] = subGroupDbExtraRow;

                    //group db extra calculations
                    const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, grpId);
                    const groupItems = getAllItemsInAGroup(combinedList, grpId);
                    const groupDbExtraIndex = getDbExtraRowIndex(combinedList, grpId, null, GroupTypes.GROUP);
                    let grpListWithoutDbExtra = getItemsWithoutDBExtra(combinedList, GroupTypes.GROUP, grpId);
                    grpListWithoutDbExtra = getListWithQtyBasedNettEA(grpListWithoutDbExtra);
                    const groupNettEA = calculateGroupWiseTotal(grpListWithoutDbExtra, "nettEA");
                    // const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const hasGroupLevelDbExtra = isDbExtraAdded(combinedList, grpId, null, GroupTypes.GROUP);
                    groupDbExtra = hasGroupLevelDbExtra ? calculateDbExtra(dbExtraCharge, groupNettEA) : 0;

                    if (hasGroupLevelDbExtra) {
                        const updatedGroupDbExtraRow = {
                            ...existingGroupWiseDbExtra[0],
                            nettEA: groupDbExtra,
                            totalPrice: groupDbExtra,
                        };

                        combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                    }

                    updatedItems = [...combinedList];
                } else {
                    //if there is no dbExtra added for the sub group
                    //check if there is any dbExtra added for the group
                    const existingGroupWiseDbExtra = getExistingGroupWiseDbExtraRow(combinedList, grpId);

                    if (existingGroupWiseDbExtra.length > 0) {
                        const groupItems = getAllItemsInAGroup(combinedList, grpId);
                        const groupDbExtraIndex = getDbExtraRowIndex(combinedList, grpId, null, GroupTypes.GROUP);
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const hasGroupLevelDbExtra = isDbExtraAdded(combinedList, grpId);
                        groupDbExtra = hasGroupLevelDbExtra ? calculateDbExtra(dbExtraCharge, groupNettEA) : 0;

                        if (hasGroupLevelDbExtra) {
                            const updatedGroupDbExtraRow = {
                                ...existingGroupWiseDbExtra[0],
                                nettEA: groupDbExtra,
                                totalPrice: groupDbExtra,
                            };

                            combinedList[groupDbExtraIndex] = updatedGroupDbExtraRow;
                        }
                        updatedItems = [...combinedList];
                    }
                }

                //to persist the group values(list price, act Cost, nettEA etc) when the qty is changed
                const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, grpId);
                const subgrpItemsWithoutAI = combinedList.filter((f) => f.rowType !== "AI");
                const exludedCIWithZeroQty = subgrpItemsWithoutAI.filter((f) => f.rowType === "CI" &&  f.qty <= 0 || f.nettEA <= 0);
                const subGroupItems = getAllExistingSubGroupedItems(subgrpItemsWithoutAI.filter((f)=> !exludedCIWithZeroQty.some((r)=> r.rowId === f.rowId)), grpId, subGrpId);

                const hasQtyChangedInSubGroups = subGroupRows.some((item) => item.qty > 1);
                const hasQtyChangedInSGItems = subGroupItems.some((item) => item.qty > 1);
                // const hasQtyChangedInSubGroups = true;
                // const hasQtyChangedInSGItems = true;

                let rowList = [];
                let qtyUnchangedSubGroups = [];
                let qtyUnchangedSubGroupItems = [];

                if (hasQtyChangedInSubGroups) {
                    qtyUnchangedSubGroups = subGroupRows.filter((item) => item.qty === 1);
                }

                if (hasQtyChangedInSGItems) {
                    qtyUnchangedSubGroupItems = subGroupItems.filter((item) => item.qty === 1);
                }

                //#region commented and apply minor logic for qty subgroup changes
                if (hasQtyChangedInSubGroups) {
                    // sub group quantity changed
                    const subGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, grpId, subGrpId);
                    let subGroupRowIndex = -1;
                    let updatedQty = 1;
                    let updatedSubGroupRow = null;

                    if (subGroupRow.length > 0) {
                        subGroupRowIndex = getSubGroupRowIndex(combinedList, grpId, subGrpId);
                        updatedQty = subGroupRow[0].qty;
                    }

                    let updatedSGTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                    let updatedSGTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                    let updatedSGNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    let updatedSGActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                    let updatedSGstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                    let updatedSGStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                    let updatedSGListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");

                    updatedSubGroupRow = {
                        ...subGroupRow[0],
                        qty: updatedQty,
                        // totalCost: updatedSGTotalCost * updatedQty,
                        totalCost: calculateItemTotalCost(updatedQty, updatedSGstEecost),
                        totalPrice: updatedSGTotalPrice * updatedQty,
                        nettEA: updatedSGNettEA,
                        actCostExact: updatedSGActCostExact,
                        stEecost: updatedSGstEecost,
                        stFutureCost: updatedSGStFutureCost,
                        listPrice: updatedSGListPrice,
                        actCost: parseFloat(parseFloat(updatedSGActCostExact).toFixed(2)),
                        stEecostRound: parseFloat(parseFloat(updatedSGstEecost).toFixed(2)),
                        stFutureCostRound: parseFloat(parseFloat(updatedSGStFutureCost).toFixed(2)),
                    };

                    combinedList[subGroupRowIndex] = updatedSubGroupRow;

                    //group row calculations
                    let groupRow = getGroupRowByGroup(combinedList, grpId)[0];
                    let groupRowIndex = getGroupRowIndex(combinedList, grpId);
                    let subGroupRows = getAllExistingSubGroupsByGroup(combinedList, grpId);
                    let groupRowItems = getGroupedRowItems(combinedList, grpId);

                    let updatedGroupRow = null;
                    let subGroupsWithoutCurrent = subGroupRows.filter((item) => item.subGroupId !== subGrpId);
                    let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                        if (item.qty > 1) {
                            return {
                                ...item,
                                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                actCost: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)
                                ),
                                stEecostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                ),
                                stFutureCostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound)).toFixed(
                                        2
                                    )
                                ),
                                //    lcMargin: "",
                            };
                        }
                        return item;
                    });

                    const concatList = [...updatedSubGroupsWithoutCurrent, ...groupRowItems];

                    let gTotalCost = calculateGroupWiseTotal(concatList, "totalCost");
                    let gTotalPrice = calculateGroupWiseTotal(concatList, "totalPrice");
                    let gNettEA = calculateGroupWiseTotal(concatList, "nettEA");
                    let gActCostExact = calculateGroupWiseTotal(concatList, "actCostExact");
                    let gstEecost = calculateGroupWiseTotal(concatList, "stEecost");
                    let gStFutureCost = calculateGroupWiseTotal(concatList, "stFutureCost");
                    let gListPrice = calculateGroupWiseTotal(concatList, "listPrice");

                    //quantity changed group calculations
                    const hasGroupQtyChanged = true; //make it always to true to capture changes from the subgroup //groupRow.qty > 1;

                    let updtNettEA = (gNettEA + subGroupRow[0].nettEA) * updatedQty;
                    let updtstEecost = (gstEecost + subGroupRow[0].stEecost) * updatedQty;
                    let updtstFutureCost = (gStFutureCost + subGroupRow[0].stFutureCost) * updatedQty;
                    let updtactCostExact = (gActCostExact + subGroupRow[0].actCostExact) * updatedQty;

                    updatedGroupRow = {
                        ...groupRow,
                        // totalCost: hasGroupQtyChanged
                        //     ? parseFloat(((gTotalCost + updatedSubGroupRow.totalCost) * groupRow.qty).toFixed(2))
                        //     : parseFloat((gTotalCost + updatedSubGroupRow.totalCost).toFixed(2)),
                        // totalCost: hasGroupQtyChanged
                        //     ? parseFloat(
                        //           (
                        //               (calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost) *
                        //               groupRow.qty
                        //           ).toFixed(2)
                        //       )
                        //     : parseFloat(
                        //           (
                        //               calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost
                        //           ).toFixed(2)
                        //       ),
                        // totalPrice: hasGroupQtyChanged
                        //     ? parseFloat(((gTotalPrice + updatedSubGroupRow.totalPrice) * groupRow.qty).toFixed(2))
                        //     : parseFloat((gTotalPrice + updatedSubGroupRow.totalPrice).toFixed(2)),
                        totalCost: parseFloat(updtstEecost.toFixed(2)),
                        totalPrice: parseFloat(updtNettEA.toFixed(2)),
                        nettEA: parseFloat(updtNettEA.toFixed(2)),//parseFloat((gNettEA + updatedSubGroupRow.nettEA * updatedQty).toFixed(2)),
                        actCostExact: updtactCostExact,//gActCostExact + updatedSubGroupRow.actCostExact * updatedQty,
                        stEecost: updtstEecost,//gstEecost + updatedSubGroupRow.stEecost * updatedQty,
                        stFutureCost: updtstFutureCost,//gStFutureCost + updatedSubGroupRow.stFutureCost * updatedQty,
                        listPrice: parseFloat((gListPrice + updatedSubGroupRow.listPrice * updatedQty).toFixed(2)),
                        actCost: parseFloat(updtactCostExact.toFixed(2)),
                        stEecostRound: parseFloat(updtstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(updtstFutureCost.toFixed(2)),
                        // actCost: parseFloat(
                        //     parseFloat(gActCostExact + updatedSubGroupRow.actCostExact * updatedQty).toFixed(2)
                        // ),
                        // stEecostRound: parseFloat(
                        //     parseFloat(gstEecost + +(updatedSubGroupRow.stEecost * updatedQty)).toFixed(2)
                        // ),
                        // stFutureCostRound: parseFloat(
                        //     parseFloat(gStFutureCost + +(updatedSubGroupRow.stFutureCost * updatedQty)).toFixed(2)
                        // ),
                        margin: calculateGroupWiseMarginForSNumberChange(updtNettEA, updtstEecost),
                        frcMargin: calculateGroupWiseFrcMarginForSNumberChange(updtNettEA, updtstFutureCost),
                        actMargin: calculateGroupWiseActMarginForSNumberChange(updtNettEA, updtactCostExact),
                        //   lcMargin: "",
                    };

                    combinedList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(combinedList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                            combinedList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // setItemList(combinedList);
                    // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                    // updatedItems = [...combinedList];
                    const updatedcombinedList = combinedList.map((response) => {
                        return {
                            ...response,
                            listPrice: response.listPrice === 0 ? '' : response.listPrice,
                            l60: response.l60 === 0 ? '' : response.l60,
                            l55: response.l55 === 0 ? '' : response.l55,
                            l50: response.l50 === 0 ? '' : response.l50,
                            l20: response.l20 === 0 ? '' : response.l20
                        };
                    });
                    const updatelist1 = [...updatedcombinedList]
                    setItemList(updatelist1);
                    updateQuoteDetailsForId(id, { selectedItems: updatelist1 });
                    updatedItems = [...updatelist1];
                }
                // } else {
                //#endregion
                let subGroupMargin = -1;
                let subGroupFrcMargin = -1;
                let subGroupActMargin = -1;
                let subGroupDbExtraValue = 0;

                // let subGroupItems = getAllExistingSubGroupedItems(subgrpItemsWithoutAI, grpId, subGrpId);
                // subGroupItems = getListWithQtyBasedNettEA(subGroupItems);

                let subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                let subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                let subGroupNettEANonAI = calculateGroupWiseTotal(
                    subGroupItems.filter((f) => f.rowType !== "A" && f.rowType !== "AI" &&  f.rowType !== "DX"),
                    "nettEA"
                );
                const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                let subGroupstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                let subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                let subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                const subGroupTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                // const totalDcOnLp = calculateItemDcOnLp(subGroupNettEANonAI, subGroupListPrice);
                let totalDcOnLp = isDconLpChanged ? subGroupItems[0].dcOnLp : calculateItemDcOnLp(subGroupNettEANonAI, subGroupListPrice);
                subGroupDbExtraValue = subGroupLevelDbExtra ? calculateDbExtra(dbExtraCharge, subGroupNettEA) : 0;

                if (subGroupLevelDbExtra) {
                    const nonAIAndNonDXItems = subGroupItems.filter(
                        (i) => i.rowType !== "AI" && i.rowType !== "DX"
                    );
                    subGroupNettEA = calculateGroupWiseTotal(nonAIAndNonDXItems, "nettEA");

                    subGroupMargin = isMarginChanged ?  subGroupItems[0].margin : calculateGroupWiseMarginForSNumberChange(
                        // subGroupNettEA - groupDbExtra,
                        subGroupNettEA,
                        subGroupstEecost
                    );
                    subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        // subGroupNettEA - groupDbExtra,
                        subGroupNettEA,
                        subGroupStFutureCost
                    );
                    subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        // subGroupNettEA - groupDbExtra,
                        subGroupNettEA,
                        subGroupActCostExact
                    );
                } else {
                    subGroupMargin = isMarginChanged ?  subGroupItems[0].margin : calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                    subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupStFutureCost
                    );
                    subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupActCostExact
                    );
                }

                // //#region Added by John: added to modify the computation during summarization if quotation has an assembly item. Also the value used for the computation of margin is not coming from this output.

                const subgrpItemsCostUpdate1 = subGroupItems.map((item) => {
                    if (item.rowType === "A" || item.rowType === "S") {
                        return {
                            ...item,
                            listPrice: item.listPrice * item.qty,
                            stEecostRound: item.stEecostRound * item.qty,
                            actCost: item.actCost * item.qty,
                            actCostExact: item.actCostExact * item.qty,
                            stFutureCostRound: item.stFutureCostRound * item.qty,
                            stEecost: item.stEecost * item.qty,
                            stFutureCost: item.stFutureCost * item.qty,
                            nettEA: item.nettEA * item.qty,
                        };
                    } else return item;
                    // return item
                });

                subGroupListPrice = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "listPrice");
                subGroupstEecost = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "stEecost");
                subGroupStFutureCost = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "stFutureCost");
                subGroupActCostExact = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "actCostExact");
                subGroupNettEA = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "nettEA");
                //#endregion

                const emptySubGroupRow = getSubGroupRowByGroupAndSubGroup(combinedList, grpId, subGrpId);
                const subGroupIndex = getSubGroupRowIndex(combinedList, grpId, subGrpId);
                let updatedEmptySubGroupRow = null;

                if (emptySubGroupRow.length > 0) {
                    updatedEmptySubGroupRow = {
                        ...emptySubGroupRow[0],
                        listPrice: subGroupListPrice,
                        nettEA: subGroupNettEA,
                        totalPrice: subGroupTotalPrice * emptySubGroupRow[0].qty,
                        dcOnLp: totalDcOnLp,
                        stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                        stFutureCost: subGroupStFutureCost,
                        stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                        qty: emptySubGroupRow[0].qty !== "" ? emptySubGroupRow[0].qty : 0,
                        margin: subGroupMargin,
                        frcMargin: subGroupFrcMargin,
                        actMargin: subGroupActMargin,
                        // totalCost: subGroupTotalCost,
                        totalCost: calculateItemTotalCost(emptySubGroupRow[0].qty, subGroupstEecost),
                        stEecost: subGroupstEecost,
                        actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        actCostExact: subGroupActCostExact,
                        //  lcMargin: "",
                    };

                    //updates the sub group row
                    combinedList[subGroupIndex] = updatedEmptySubGroupRow;
                    updatedItemList[subGroupIndex] = updatedEmptySubGroupRow
                }

                //ref : for group calculations
                const updatedSubGroupRows = getAllExistingSubGroupsByGroup(combinedList, grpId); // to get the sub groups after the above update
                const groupRows = getGroupedRowItems(combinedList, grpId);

                // const updatedGroupRows = groupRows.map((item) => {
                //     if (item.qty > 1) {
                //         return {
                //             ...item,
                //             listPrice : calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                //             nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                //             stEecost : calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                //             actCostExact : calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                //             stFutureCost : calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                //             actCost : parseFloat(parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)),
                //             stEecostRound : parseFloat(parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)),
                //             stFutureCostRound : parseFloat(parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound)).toFixed(2)),
                //         }
                //     }
                //     return item;
                // });

                const emptyGroupRow = getGroupRowByGroup(combinedList, grpId);
                const emptyGroupRowIndex = getGroupRowIndex(combinedList, grpId);
                //#region  Added by john 11/13/2023: To include non sub group item for computation
                const nonsubGroupItems = getAllExistingNonSubGroupedItems(subGroupItems, grpId, subGrpId);
                const subGrpItemsCombined = [...groupRows, ...nonsubGroupItems];
                //#endregion
                hasQtyChangedInSubGroups
                    ? (rowList = [...emptyGroupRow, ...qtyUnchangedSubGroups, ...subGrpItemsCombined])
                    : (rowList = [...updatedSubGroupRows, ...subGrpItemsCombined]);

                //check for group level db extra
                const groupLevelDbExtra = isDbExtraAdded(combinedList, grpId, null, GroupTypes.GROUP);

                let groupMargin = -1;
                let groupFrcMargin = -1;
                let groupActMargin = -1;

                const groupTotalAssemblyNettEA = combinedList.filter(
                    (f) => f.groupId === grpId && f.rowType === "A"
                );
                const totalNettEAAssembly = groupTotalAssemblyNettEA.reduce(
                    (total, item) => total + item.nettEA,
                    0
                );
                const totalQtyAssembly = groupTotalAssemblyNettEA.reduce((total, item) => total + item.qty, 0);
                const groupListPrice = calculateGroupWiseTotal(rowList, "listPrice");
                const groupNettEA = calculateGroupWiseTotal(rowList, "nettEA");
                const groupTotalPrice = calculateGroupWiseTotal(rowList, "totalPrice");
                let groupstEecost = calculateGroupWiseTotal(rowList, "stEecost");
                let groupStFutureCost = calculateGroupWiseTotal(rowList, "stFutureCost");
                let groupActCostExact = calculateGroupWiseTotal(rowList, "actCostExact");
                const groupTotalCost = calculateGroupWiseTotal(rowList, "totalCost");
                // const groupDcOnLp = calculateGroupDcOnLp(
                //     groupListPrice,
                //     parseFloat(groupNettEA) - parseFloat(totalNettEAAssembly) * totalQtyAssembly
                // );
                const groupDcOnLp = isDconLpChanged ? subGroupItems[0].dcOnLp : calculateItemDcOnLp(
                    parseFloat(groupNettEA) - parseFloat(totalNettEAAssembly) * totalQtyAssembly,
                    groupListPrice
                );

                if (groupLevelDbExtra) {
                    groupMargin = isMarginChanged ?  subGroupItems[0].margin : calculateGroupWiseMarginForSNumberChange(
                        groupNettEA - groupDbExtra,
                        groupstEecost
                    );
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        groupNettEA - groupDbExtra,
                        groupStFutureCost
                    );
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        groupNettEA - groupDbExtra,
                        groupActCostExact
                    );
                }
                else {
                    groupMargin = isMarginChanged ?  subGroupItems[0].margin : calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                }

                const hasGroupQtyChanged =
                    emptySubGroupRow.length > 0
                        ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty > 1
                            ? true
                            : false
                        : false;

                const hasEmptyGroupRow =
                    emptyGroupRow.length > 0
                    ? emptyGroupRow.length > 0
                        ? true
                        : false
                    : false;

                const updatedEmptyGroupRow = {
                    ...emptyGroupRow[0],
                    listPrice: groupListPrice,
                    nettEA: groupNettEA,
                    totalPrice: hasEmptyGroupRow ? emptyGroupRow[0]?.qty * groupTotalPrice : 0,
                    dcOnLp: groupDcOnLp,
                    stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                    stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                    stFutureCost: groupStFutureCost,
                    // qty: emptySubGroupRow.length > 0 ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty : 1,
                    qty: emptyGroupRow[0]?.qty !== "" ? emptyGroupRow[0]?.qty : 0,
                    margin: groupMargin,
                    actMargin: groupActMargin,
                    frcMargin: groupFrcMargin,
                    // totalCost: hasGroupQtyChanged
                    //     ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty * groupTotalCost
                    //     : groupTotalCost,
                    totalCost:
                        emptyGroupRow.length > 0 && calculateItemTotalCost((emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1), groupstEecost),
                    stEecost: groupstEecost,
                    actCost: parseFloat(groupActCostExact.toFixed(2)),
                    actCostExact: groupActCostExact,
                    //  lcMargin: "",
                };

                combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;
                updatedItemList[emptyGroupRowIndex] = updatedEmptyGroupRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(combinedList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                        combinedList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                //#region Added by John 11/14/2023: Update on the last part for the totals with assembly applied computation
                // commented for testing
                // const itemWOAI = combinedList.filter((f) => f.rowType !== "AI");
                // const subGrpForUpdate = getAllExistingSubGroupedItems(itemWOAI, grpId, subGrpId);
                // const subgrpItemsCostUpdate = subGrpForUpdate.map((item) => {
                //     if (item.rowType === "A") {
                //         return {
                //             ...item,
                //             listPrice: item.listPrice,
                //             nettEA: item.nettEA,
                //             totalPrice: item.totalPrice,
                //             dcOnLp: item.dcOnLp,
                //             stEecostRound: item.stEecostRound * item.qty,
                //             stFutureCost: item.stFutureCost * item.qty,
                //             stFutureCostRound: item.stFutureCostRound * item.qty,
                //             qty: item.qty,
                //             margin: item.margin,
                //             frcMargin: item.frcMargin,
                //             actMargin: item.actMargin,
                //             totalCost: item.totalCost,
                //             stEecost: item.stEecost * item.qty,
                //             actCost: item.actCost * item.qty,
                //             actCostExact: item.actCostExact * item.qty,
                //         };
                //     } else return item;
                // });

                // groupstEecost = calculateGroupWiseTotal(subgrpItemsCostUpdate, "stEecost");
                // groupStFutureCost = calculateGroupWiseTotal(subgrpItemsCostUpdate, "stFutureCost");
                // groupActCostExact = calculateGroupWiseTotal(subgrpItemsCostUpdate, "actCostExact");

                // const emptySubGroupRowUpdate = getSubGroupRowByGroupAndSubGroup(combinedList, grpId, subGrpId);
                // const subGroupIndexUpdate = getSubGroupRowIndex(combinedList, grpId, subGrpId);
                // let emptySubGroupRowUpdated = null;

                // if (emptySubGroupRowUpdate.length > 0) {
                //     emptySubGroupRowUpdated = {
                //         ...emptySubGroupRowUpdate[0],
                //         listPrice: emptySubGroupRowUpdate[0].listPrice,
                //         nettEA: emptySubGroupRowUpdate[0].nettEA,
                //         totalPrice: emptySubGroupRowUpdate[0].totalPrice,
                //         dcOnLp: emptySubGroupRowUpdate[0].dcOnLp,
                //         stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                //         stFutureCost: emptySubGroupRowUpdate[0].stFutureCost,
                //         stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                //         qty: emptySubGroupRowUpdate[0].qty,
                //         margin: emptySubGroupRowUpdate[0].margin,
                //         frcMargin: emptySubGroupRowUpdate[0].frcMargin,
                //         actMargin: emptySubGroupRowUpdate[0].actMargin,
                //         totalCost: emptySubGroupRowUpdate[0].totalCost,
                //         stEecost: emptySubGroupRowUpdate[0].stEecost,
                //         actCost: parseFloat(groupActCostExact.toFixed(2)),
                //         actCostExact: emptySubGroupRowUpdate[0].actCostExact,
                //     };

                //     //updates the sub group row
                //     combinedList[subGroupIndexUpdate] = emptySubGroupRowUpdated;
                // }
                // commented for testing

                //update group totals
                // const groupItmStat = newItems.filter((f) => f.groupName.includes("Non Grouped"));

                // if (groupItmStat.length === 0) {
                //     const emptyGroupRowUpdated = getGroupRowByGroup(combinedList, grpId);

                //     if (emptyGroupRowUpdated.length > 0) {
                //         const emptyGroupRowItemUpdated = getGroupedRowItems(combinedList, grpId);
                //         let grpItemsCostUpdate = [];

                //         if (emptyGroupRowItemUpdated.length > 0) {
                //             grpItemsCostUpdate = emptyGroupRowUpdated.map((item) => {
                //                 if (item.rowType === "A") {
                //                     return {
                //                         ...item,
                //                         listPrice: item.listPrice,
                //                         nettEA: item.nettEA,
                //                         totalPrice: item.totalPrice,
                //                         dcOnLp: item.dcOnLp,
                //                         stEecostRound: item.stEecostRound * item.qty,
                //                         stFutureCost: item.stFutureCost * item.qty,
                //                         stFutureCostRound: item.stFutureCostRound * item.qty,
                //                         qty: item.qty,
                //                         margin: item.margin,
                //                         frcMargin: item.frcMargin,
                //                         actMargin: item.actMargin,
                //                         totalCost: item.totalCost,
                //                         stEecost: item.stEecost * item.qty,
                //                         actCost: item.actCost * item.qty,
                //                         actCostExact: item.actCostExact * item.qty,
                //                     };
                //                 } else return item;
                //             });
                //         } else {
                //         }

                //         let groupstEecostUpdated = 0;
                //         let groupStFutureCostUpdated = 0;
                //         let groupActCostExactUpdated = 0;

                //         if (emptyGroupRowItemUpdated.length > 0) {
                //             groupstEecostUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "stEecost");
                //             groupStFutureCostUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "stFutureCost");
                //             groupActCostExactUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "actCostExact");

                //             // groupstEecostUpdated += groupstEecost;
                //             // groupStFutureCostUpdated += groupStFutureCost;
                //             // groupActCostExactUpdated += groupActCostExact;

                //             const emptyGroupRowUpdate = {
                //                 ...emptyGroupRowUpdated[0],
                //                 listPrice: emptyGroupRowUpdated[0].listPrice,
                //                 nettEA: emptyGroupRowUpdated[0].nettEA,
                //                 totalPrice: emptyGroupRowUpdated[0].totalPrice,
                //                 dcOnLp: emptyGroupRowUpdated[0].dcOnLp,
                //                 stEecostRound: parseFloat(groupstEecostUpdated.toFixed(2)),
                //                 stFutureCostRound: parseFloat(groupStFutureCostUpdated.toFixed(2)),
                //                 stFutureCost: groupStFutureCost,
                //                 qty: emptyGroupRowUpdated[0].qty,
                //                 margin: emptyGroupRowUpdated[0].margin,
                //                 actMargin: emptyGroupRowUpdated[0].actMargin,
                //                 frcMargin: emptyGroupRowUpdated[0].frcMargin,
                //                 totalCost: emptyGroupRowUpdated[0].totalCost,
                //                 stEecost: groupStFutureCostUpdated,
                //                 actCost: parseFloat(groupActCostExactUpdated.toFixed(2)),
                //                 actCostExact: groupActCostExactUpdated,
                //             };

                //             combinedList[emptyGroupRowIndex] = emptyGroupRowUpdate;
                //         }
                //     }
                // }
                //#endregion

                const groupItmStat = newItems.filter((f) => f.groupName.includes("Non Grouped"));
                if (groupItmStat.length === 0) {
                    const groupRow = getGroupRowByGroup(combinedList, grpId);
                    const groupRowItems = getGroupedRowItems(combinedList, grpId);
                    const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, grpId);
                    const nonGroupRowItems = getAllExistingNonSubGroupedItems(combinedList, grpId, subGrpId);
                    const updatedGroupRowItems = getQtyBasedValues(groupRowItems);

                    //#region 
                    // const updatedGroupRowItems = groupRowItems.map((item) => {
                    //     if (item.rowType === "A") {
                    //         return {
                    //             ...item,
                    //             listPrice: item.listPrice,
                    //             nettEA: item.nettEA * item.qty,
                    //             totalPrice: item.totalPrice,
                    //             dcOnLp: item.dcOnLp,
                    //             stEecostRound: item.stEecostRound * item.qty,
                    //             stFutureCost: item.stFutureCost * item.qty,
                    //             stFutureCostRound: item.stFutureCostRound * item.qty,
                    //             qty: item.qty,
                    //             margin: item.margin,
                    //             frcMargin: item.frcMargin,
                    //             actMargin: item.actMargin,
                    //             totalCost: item.totalCost,
                    //             stEecost: item.stEecost * item.qty,
                    //             actCost: item.actCost * item.qty,
                    //             actCostExact: item.actCostExact * item.qty,
                    //         };
                    //     }
                    //     return item;
                    // });
                    //#endregion

                    const aIRemovedGRList = updatedGroupRowItems.filter((f) => f.rowType !== "AI");
                    // const groupItems = [...subGroupRows ,...aIRemovedGRList];
                    const groupItems = [...subGroupRows, ...nonGroupRowItems, ...aIRemovedGRList]; // includes the non sub grouped items

                    // group re-calculation
                    let groupstEecostUpdated = calculateGroupWiseTotal(groupItems, "stEecost");
                    let groupStFutureCostUpdated = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    let groupActCostExactUpdated = calculateGroupWiseTotal(groupItems, "actCostExact");
                    let groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    let groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");

                    // let groupItems2 = [...subGroupRows, ...updatedGroupRowItems];
                    let groupItems2 = [...subGroupRows, ...nonGroupRowItems, ...updatedGroupRowItems]; // includes the non sub grouped items

                    let groupNettEA = calculateGroupWiseTotal(groupItems2, "nettEA");
                    let groupListPrice = calculateGroupWiseTotal(groupItems2, "listPrice");
                    let groupMargin = 0;
                    let groupFrcMargin = 0;
                    let groupActMargin = 0;

                    if (subGroupRows.length > 0) {
                        //Maduka modified to reduce group and subGroup DX from netEA
                        groupMargin = isMarginChanged ? subGroupRows[0].margin : calculateGroupWiseMarginForSNumberChange(
                            // groupNettEA, 
                            groupNettEA - (groupDbExtra + subGroupDbExtraValue),
                            groupstEecostUpdated);
                        groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            // groupNettEA,
                            groupNettEA - (groupDbExtra + subGroupDbExtraValue),
                            groupStFutureCostUpdated
                        );
                        groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            // groupNettEA,
                            groupNettEA - (groupDbExtra + subGroupDbExtraValue),
                            groupActCostExactUpdated
                        );
                    }
                    // else {
                    //     groupMargin = subGroupRows[0].margin;
                    //     groupFrcMargin = subGroupRows[0].frcMargin;
                    //     groupActMargin = subGroupRows[0].actMargin;
                    // }

                    // let groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecostUpdated);
                    // let groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                    //     groupNettEA,
                    //     groupStFutureCostUpdated
                    // );
                    // let groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                    //     groupNettEA,
                    //     groupActCostExactUpdated
                    // );

                    //#region check if sub group header Qty is greater than 1
                    let addListPrice = 0, addCostEA = 0, addAvgCost = 0, addFutCost = 0,
                        addNettEA = 0, addMargin = 0, addAvgCMargin = 0, addFCMargin = 0, addTotalCost = 0;
                    if (hasQtyChangedInSubGroups) {

                        const subGrpHeaderList = subGroupRows.filter((f) => f.qty > 1);
                        if (subGrpHeaderList.length > 0) {
                            subGrpHeaderList.map((item) => {
                                addListPrice += item.listPrice * (item.qty - 1);
                                addCostEA += item.stEecost * (item.qty - 1);
                                addAvgCost += item.actCostExact * (item.qty - 1);
                                addFutCost += item.stFutureCost * (item.qty - 1);
                                addNettEA += item.nettEA * (item.qty - 1);
                                addTotalCost += addCostEA * (item.qty - 1);
                            });

                            const paramNettEA = groupNettEA + addNettEA;
                            const paramCostEA = groupstEecostUpdated + addCostEA;
                            const paramFutCost = groupStFutureCostUpdated + addFutCost;
                            const paramCostExact = groupActCostExactUpdated + addAvgCost;

                            groupMargin = calculateGroupWiseMarginForSNumberChange(paramNettEA, paramCostEA);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(paramNettEA, paramFutCost);
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(paramNettEA, paramCostExact);
                        }

                    }
                    //#endregion

                    const updatedGroupRow = {
                        ...groupRow[0],
                        stEecost: groupstEecostUpdated + addCostEA,
                        stEecostRound: parseFloat((groupstEecostUpdated + addCostEA).toFixed(2)),
                        stFutureCost: groupStFutureCostUpdated + addFutCost,
                        stFutureCostRound: parseFloat((groupStFutureCostUpdated + addFutCost).toFixed(2)),
                        actCostExact: groupActCostExactUpdated + addAvgCost,
                        actCost: parseFloat((groupActCostExactUpdated + addAvgCost).toFixed(2)),
                        nettEA: (groupNettEA + addNettEA).toFixed(2),
                        totalPrice: (groupTotalPrice * (groupRow.length > 0 ? groupRow[0].qty : 0)),
                        // totalCost: groupTotalCost,
                        totalCost: parseFloat(((groupRow.length > 0 ? groupRow[0].qty : 0) * (groupstEecostUpdated + addCostEA)).toFixed(2)),
                        //totalCost: calculateItemTotalCost(groupRow[0].qty, groupstEecostUpdated) + addTotalCost, //commented by sachini bcz caculation incorrect when changing sub group level qty
                        margin: groupMargin,
                        frcMargin: groupFrcMargin,
                        actMargin: groupActMargin,
                        listPrice: groupListPrice + addListPrice,
                    };

                    combinedList[emptyGroupRowIndex] = updatedGroupRow;
                    updatedItemList[emptyGroupRowIndex] = updatedGroupRow;
                }
                // }
                const updatedcombinedList = combinedList.map((response) => {
                    return {
                        ...response,
                        listPrice: response.listPrice === 0 ? '' : response.listPrice,
                        l60: response.l60 === 0 ? '' : response.l60,
                        l55: response.l55 === 0 ? '' : response.l55,
                        l50: response.l50 === 0 ? '' : response.l50,
                        l20: response.l20 === 0 ? '' : response.l20
                    };
                });
                const updatelist1 = [...updatedcombinedList]
                setItemList(updatelist1);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                // setItemList(combinedList);

                //#region 
                // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(combinedList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEA);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);

                // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalstEecostRound(totalstEecostRound);
                // const totalStFutureCost = calculateTotalStFutureCost(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalListPrice(totalListPrice);
                //#endregion

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: combinedList[0],
                    });
                }

                updatedItems = [...combinedList];
            } else {
                const combinedList = itemAction === "Add" ? [...updatedItemList, ...groupedList] : [...updatedItemList];
                const groupDbExtra = isDbExtraAdded(combinedList, grpId, null, GroupTypes.GROUP);

                let updatedNettEA;

                if (groupDbExtra) {
                    const dbExtraIndex = getDbExtraRowIndex(combinedList, grpId, null, GroupTypes.GROUP);
                    let listWithoutDbExtra = getItemsWithoutDBExtra(combinedList, GroupTypes.GROUP, grpId);
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const listWithoutDbExtraNonAI = listWithoutDbExtra.filter((f) => f.rowType !== "AI");
                    const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtraNonAI, "nettEA");
                    updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                    const dbExtraRow = combinedList[dbExtraIndex];

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    combinedList[dbExtraIndex] = updatedDbExtraRow;
                }

                const groupRows = combinedList.filter(
                    (item) =>
                        item.groupId === grpId &&
                        //(item.subGroupId === "" || item.subGroupId === "00000000-0000-0000-0000-000000000000") &&
                        item.stStockCode !== ""
                );

                let groupMargin = -1;
                let groupFrcMargin = -1;
                let groupActMargin = -1;

                //#region  Added by john 11/13/2023: To included non sub group item for computation
                let groupRowsUpdated = groupRows.filter((f) => f.rowType !== "AI");
                groupRowsUpdated = groupRowsUpdated.map((item) => {
                    if (item.qty > 1) {
                        return {
                            ...item,
                            listPrice: parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.listPrice)),
                            stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                            actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                            stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                            actCost: parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact).toFixed(2)),
                            stEecostRound: parseFloat(
                                calculateValuesOnSBLQtyChange(item.qty, item.stEecost).toFixed(2)
                            ),
                            stFutureCostRound: parseFloat(
                                calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound).toFixed(2)
                            ),
                            nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                        };
                    }
                    return item;
                });
                const nonsubGroupItems = getAllExistingNonSubGroupedItems(groupRowsUpdated, grpId);
                const exludedCIWithZeroQty = groupRowsUpdated.filter((f) => f.rowType === "CI" &&  f.qty <= 0 || f.nettEA <= 0);
                const grpItemsCombined = [...groupRowsUpdated.filter((f)=> { return !exludedCIWithZeroQty.some((r)=> r.rowId === f.rowId)}), ...nonsubGroupItems];
                //#endregion
                const groupListPrice = calculateGroupWiseTotal(grpItemsCombined, "listPrice");
                const groupNettEANonAI = calculateGroupWiseTotal(
                    grpItemsCombined.filter((f) => f.rowType !== "A" && f.rowType !== "AI" && f.rowType !== "DX"),
                    "nettEA"
                );

                const emptyGroupRow = combinedList.filter(
                    (item) => item.displayGroupName !== "" && item.groupId.toString() === grpId.toString()
                );

                const emptyGroupRowIndex = combinedList.findIndex(
                    (item) => item.displayGroupName !== "" && item.groupId.toString() === grpId.toString()
                );

                const groupQty = emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 1;
                const hasGroupQtyChanged = groupQty > 1;

                const groupTotalDcOnLp = isDconLpChanged ? grpItemsCombined[0].dcOnLp : calculateItemDcOnLp(groupNettEANonAI, groupListPrice);
                const groupstEecost = calculateGroupWiseTotal(grpItemsCombined, "stEecost");
                const groupNettEA =  calculateGroupWiseTotal(grpItemsCombined, "nettEA");

                const groupTotalPrice = calculateGroupWiseTotal(grpItemsCombined, "totalPrice");
                
                const groupStFutureCost = calculateGroupWiseTotal(grpItemsCombined, "stFutureCost");
                const groupActCostExact = calculateGroupWiseTotal(grpItemsCombined, "actCostExact");
                const groupTotalCost = calculateGroupWiseTotal(grpItemsCombined, "totalCost");
                // const groupTotalDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEANonAI);
                //const groupTotalDcOnLp = calculateItemDcOnLp(groupNettEANonAI, groupListPrice);



                if (groupDbExtra) {
                    groupMargin = isMarginChanged ?  grpItemsCombined[0].margin : calculateGroupWiseMarginForSNumberChange(groupNettEA - updatedNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupStFutureCost
                    );
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupActCostExact
                    );
                } else {
                    groupMargin = isMarginChanged ?  grpItemsCombined[0].margin : calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                }

               

                if (emptyGroupRow.length > 0) {
                    const updatedEmptyGroupRow = {
                        ...emptyGroupRow[0],
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        // totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                        totalPrice: groupNettEA * groupQty,
                        dcOnLp: groupTotalDcOnLp,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        // qty: groupQty,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        // totalCost: hasGroupQtyChanged ? groupTotalCost * groupQty : groupTotalCost,
                        totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                        stEecost: groupstEecost,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        //   lcMargin: "",
                        costEA: 0,
                        costEAExact: 0,
                        l20: 0,
                        l50: 0,
                        l55: 0,
                        l60: 0,
                        lastCost: 0,
                        lastCostExact: 0,

                    };

                    combinedList[emptyGroupRowIndex] = updatedEmptyGroupRow;
                    updatedItemList = combinedList;
                }

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(combinedList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                        combinedList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                // setItemList(combinedList);
                const updatedcombinedList = combinedList.map((response) => {
                    return {
                        ...response,
                        listPrice: response.listPrice === 0 ? '' : response.listPrice,
                        l60: response.l60 === 0 ? '' : response.l60,
                        l55: response.l55 === 0 ? '' : response.l55,
                        l50: response.l50 === 0 ? '' : response.l50,
                        l20: response.l20 === 0 ? '' : response.l20
                    };
                });
                const updatelist1 = [...updatedcombinedList]
                setItemList(updatelist1);
                updateQuoteDetailsForId(id, { selectedItems: updatelist1 });

                //#region  modified by john 11/14/2023: exlude assembly items for the computation. Only the header will be summarized
                //update group totals
                const groupItmStat = newItems.filter((f) => f.groupName.includes("Non Grouped"));
                if (groupItmStat.length === 0) {
                    const emptyGroupRowUpdated = getGroupRowByGroup(combinedList, grpId);
                    if (emptyGroupRowUpdated.length > 0) {
                        let emptyGroupRowItemUpdated = getGroupedRowItems(combinedList, grpId);
                        //added by tishan -> if the qty is greater than 1, then the values should be multiplied by the qty
                        emptyGroupRowItemUpdated = emptyGroupRowItemUpdated.map((item) => {
                            if (item.qty > 1) {
                                return {
                                    ...item,
                                    listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                    stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                    actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                    stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                    actCost: parseFloat(
                                        calculateValuesOnSBLQtyChange(item.qty, item.actCostExact).toFixed(2)
                                    ),
                                    stEecostRound: parseFloat(
                                        calculateValuesOnSBLQtyChange(item.qty, item.stEecost).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound).toFixed(2)
                                    ),
                                    nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                };
                            }
                            return item;
                        });

                        const subGroupRows = getAllExistingSubGroupsByGroup(combinedList, grpId);
                        let grpItemsWithoutAI = emptyGroupRowItemUpdated.filter((f) => f.rowType !== "AI");
                        let grpItmToProcess = [...subGroupRows, ...grpItemsWithoutAI];
                        let grpItemsCostUpdate = [];
                        if (grpItmToProcess.length > 0) {
                            //#region 
                            // commented by tishan - every item has to be multiplied by the qty
                            // grpItemsCostUpdate = grpItmToProcess.map((item) => {
                            //     if (item.rowType === "A") {
                            //         return {
                            //             ...item,
                            //             listPrice: item.listPrice,
                            //             // nettEA: item.nettEA,
                            //             nettEA: item.nettEA * item.qty,
                            //             totalPrice: item.totalPrice,
                            //             dcOnLp: item.dcOnLp,
                            //             stEecostRound: item.stEecostRound * item.qty,
                            //             stFutureCost: item.stFutureCost * item.qty,
                            //             stFutureCostRound: item.stFutureCostRound * item.qty,
                            //             margin: item.margin,
                            //             frcMargin: item.frcMargin,
                            //             actMargin: item.actMargin,
                            //             totalCost: item.totalCost,
                            //             stEecost: item.stEecost * item.qty,
                            //             actCost: item.actCost * item.qty,
                            //             actCostExact: item.actCostExact * item.qty,
                            //         };
                            //     } else return item;
                            // });
                            //#endregion
                            grpItemsCostUpdate = grpItmToProcess;
                        }

                        let groupstEecostUpdated = 0;
                        let groupStFutureCostUpdated = 0;
                        let groupActCostExactUpdated = 0;
                        let groupNettEAUpdated = 0;

                        if (emptyGroupRowItemUpdated.length > 0) {
                            groupstEecostUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "stEecost");
                            groupStFutureCostUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "stFutureCost");
                            groupActCostExactUpdated = calculateGroupWiseTotal(grpItemsCostUpdate, "actCostExact");
                            groupNettEAUpdated =  calculateGroupWiseTotal(grpItemsCostUpdate, "nettEA");

                            const emptyGroupRowUpdate = {
                                ...emptyGroupRowUpdated[0],
                                listPrice: emptyGroupRowUpdated[0].listPrice,
                                // nettEA: emptyGroupRowUpdated[0].nettEA,
                                nettEA: groupNettEAUpdated,
                                totalPrice: emptyGroupRowUpdated[0].totalPrice,
                                dcOnLp: emptyGroupRowUpdated[0].dcOnLp,
                                stEecostRound: parseFloat(groupstEecostUpdated.toFixed(2)),
                                stFutureCostRound: parseFloat(groupStFutureCostUpdated.toFixed(2)),
                                stFutureCost: groupStFutureCost,
                                margin: emptyGroupRowUpdated[0].margin,
                                actMargin: emptyGroupRowUpdated[0].actMargin,
                                frcMargin: emptyGroupRowUpdated[0].frcMargin,
                                totalCost: emptyGroupRowUpdated[0].totalCost,
                                stEecost: groupstEecostUpdated,
                                actCost: parseFloat(groupActCostExactUpdated.toFixed(2)),
                                actCostExact: groupActCostExactUpdated,
                            };

                            combinedList[emptyGroupRowIndex] = emptyGroupRowUpdate;
                        }
                    }
                }
                //#endregion

                //#region 
                // const totalPrice = calculateTotalPrice(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(
                //     combinedList.filter((item) => item.stStockCode != " ")
                // );
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(combinedList.filter((item) => item.stStockCode != " "));
                // setTotalListPrice(totalListPrice);
                //#endregion

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: combinedList[0],
                    });
                }

                updatedItems = [...combinedList];
            }
        } else {
            const nonGroupId = uuidv4();
            if (!nonGroupedId.current) nonGroupedId.current = nonGroupId;

            const updatedNewItems = newItems.map((res) => {
                return {
                    ...res,
                    groupId: nonGroupedId.current ? nonGroupedId.current : nonGroupId,
                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                    displayGroupName: "",
                    subGroupId: "",
                    subGroupName: "",
                    displaySubGroupName: "",
                    margin: parseFloat(res.margin).toFixed(2),
                    frcMargin: parseFloat(res.frcMargin).toFixed(2),
                    //    lcMargin: "",
                };
            });

            const combinedList = itemAction === "Add" ? [...updatedItemList, ...updatedNewItems] : [...updatedItemList];
            updatedItems = [...combinedList];
            //#region 
            // const dbExtra = isDbExtraAdded(combinedList, nonGroupedId.current, null, GroupTypes.NON_GROUP);

            // if (dbExtra) {
            //     let listWithoutDbExtra = getItemsWithoutDBExtra(
            //         combinedList,
            //         GroupTypes.NON_GROUP,
            //         nonGroupedId.current,
            //         null
            //     );
            //     listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
            //     const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
            //     const updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
            //     const dbExtraIndex = getDbExtraRowIndex(combinedList, nonGroupedId.current, null, GroupTypes.NON_GROUP);
            //     const dbExtraRow = combinedList[dbExtraIndex];

            //     const updatedDbExtraRow = {
            //         ...dbExtraRow,
            //         nettEA: updatedNettEA,
            //         totalPrice: updatedNettEA,
            //     };

            //     combinedList[dbExtraIndex] = updatedDbExtraRow;
            //     updatedItems = [...combinedList];
            // }
            //#endregion

            // non group db extra calculation
            const nonGroupItems = getExistingNonGroupedItems(combinedList);

            if (nonGroupItems.length > 0) {
                const nonGroupId = nonGroupItems[0].groupId;
                const nonGroupDbExtra = isDbExtraAdded(combinedList, nonGroupId, null, GroupTypes.NON_GROUP);

                if (nonGroupDbExtra) {
                    const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(combinedList, dbExtraCharge);
                    combinedList[dbExtraRowIndex] = dbExtraRow;
                }
            }

            // updatedItems = [...combinedList];

            // setItemList(updatedItems);
            // updateQuoteDetailsForId(id, { selectedItems: updatedItems });
            const updatedcombinedList = combinedList.map((response) => {
                return {
                    ...response,
                    listPrice: response.listPrice === 0 ? '' : response.listPrice,
                    l60: response.l60 === 0 ? '' : response.l60,
                    l55: response.l55 === 0 ? '' : response.l55,
                    l50: response.l50 === 0 ? '' : response.l50,
                    l20: response.l20 === 0 ? '' : response.l20
                };
            });
            const updatelist1 = [...updatedcombinedList]
            setItemList(updatelist1);
            updateQuoteDetailsForId(id, { selectedItems: updatelist1 });

            //#region 
            // const totalPrice = calculateTotalPrice(updatedItems);
            // setTotalItemPrice(totalPrice);

            // const totalCost = calculateTotalCost(updatedItems);
            // setTotalCost(totalCost);

            // const totalneeta = calculateTotalNettEA(updatedItems);
            // setTotalNettEA(totalneeta);

            // const totalstEecostRound = calculateTotalstEecostRound(updatedItems);
            // setTotalstEecostRound(totalstEecostRound);

            // const totalStFutureCost = calculateTotalStFutureCost(updatedItems);
            // setTotalStFutureCost(totalStFutureCost);

            // const totalActCostEa = calculateTotalActCostEAExact(updatedItems);
            // setTotalActCostEAExact(totalActCostEa);

            // const totalListPrice = calculateTotalListPrice(updatedItems);
            // setTotalListPrice(totalListPrice);
            //#endregion

            if (firstCustomer[id]?.customer?.customerId === customerId) {
                updateFirstCustomerForId(id, {
                    ...firstCustomer[id],
                    item: updatedItems[0],
                });
            }
        }

        setIsDconLpChanged(false);
        setIsMarginChanged(false);

        // footer calculation
        const {
            totalPrice,
            totalSteEeCostRound,
            totalStFutureCost,
            totalActCostExact,
            totalListPrice,
            totalNettEA,
            totalCost,
            totalMargin,
            totalActMargin,
            totalFrcMargin,
            totalDcOnLp,
        } = footerCalculation(updatedItems);
        setTotalItemPrice(totalPrice);
        setTotalCostAmt(totalCost);
        setTotalstEecostRound(totalSteEeCostRound);
        setTotalStFutureCost(totalStFutureCost);
        setTotalActCostEAExact(totalActCostExact);
        setTotalListPrice(totalListPrice);
        setTotalCost(totalCost);
        setTotalNettEA(totalNettEA);
        setMaxMargin(totalMargin);
        setActMargin(totalActMargin);
        setMaxFrcMargin(totalFrcMargin);
        setFooterDisc(totalDcOnLp);

        //override the totalItemPrice based on the setPrice
        if (totalPrice != parseFloat(newPrice) && parseFloat(newPrice) > 0) {
            //get the last item in the list
            setTotalItemPrice(parseFloat(newPrice));
            setNewPrice(0);

        }

        // const totalPrice = calculateTotalPrice(updatedItems);
        // setTotalItemPrice(totalPrice);

        // const totalCost = calculateTotalCost(updatedItems);
        // setTotalCost(totalCost);

        // const totalneeta = calculateTotalNettEA(updatedItems);
        // setTotalNettEA(totalneeta);

        // const totalstEecostRound = calculateTotalstEecostRound(updatedItems);
        // setTotalstEecostRound(totalstEecostRound);

        // const totalStFutureCost = calculateTotalStFutureCost(updatedItems);
        // setTotalStFutureCost(totalStFutureCost);

        // const totalActCostEa = calculateTotalActCostEAExact(updatedItems);
        // setTotalActCostEAExact(totalActCostEa);

        // const totalListPrice = calculateTotalListPrice(updatedItems);
        // setTotalListPrice(totalListPrice);

        if (firstCustomer[id]?.customer?.customerId === customerId) {
            updateFirstCustomerForId(id, {
                ...firstCustomer[id],
                item: updatedItems[0],
            });
        }

        return updatedItems;
    };

    const getOrgiNettEA = (itemRow) => {
        let origNettEA = itemRow.listPrice;
        for (var l = 0; l < 3; l++) {
            if (Number(itemRow.l60) > 0) {
                origNettEA = itemRow.l60;
                break;
            }
            if (Number(itemRow.l55) > 0) {
                origNettEA = itemRow.l55;
                break;
            }
            if (Number(itemRow.l50) > 0) {
                origNettEA = itemRow.l50;
                break;
            }
            if (Number(itemRow.l20) > 0) {
                origNettEA = itemRow.l20;
                break;
            }
        }

        return origNettEA;
    }

    const handleItemChange = async (event) => {
        setIsQuoteSaved(false);
        setQuoteUpdated(true);
        
        const { field, dataItem } = event;
        const value = event.value;
        let systemSettings = null;
        
        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
        const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);

        if (field === "stStockCode") {
            //Added by Manoj on 2024-03-13
            setShouldAddEmptyRow(true);

            const copyOfItemList = itemList;
            const emptyRowList = copyOfItemList.filter((f) => f.rowType === '');
            if (emptyRowList !== undefined && emptyRowList.length > 0) {

                for (let row of emptyRowList) {

                    if (row.position === copyOfItemList[copyOfItemList.length - 1]) {
                        setShouldAddEmptyRow(false);
                    }

                }

            }

            //Ended by Manoj on 2024-03-13




            const UserId = user?.homeAccountId;
            const itemRow = itemList[itemIndex];
            let curGrpId = "";
            let curSubGrpId = "";

            if (itemRow !== undefined) {
                curGrpId = itemRow.groupId;
                curSubGrpId = itemRow.subGroupId;
            } else if (itemIndex < 0 && itemList.length > 0) {
                curGrpId = itemList[itemList.length - 1].groupId;
                curSubGrpId = itemList[itemList.length - 1].subGroupId;
            } else {
                curGrpId = groupId;
                curSubGrpId = subGroupId;
            }

            if (dataItem.rowType === "A" || dataItem.rowType === "LN") {
                let updatedItem = {
                    ...itemRow,
                    stStockCode: value,
                };
                itemList[itemIndex] = updatedItem;

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(itemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });
            } else {
                if (!value) {
                    toast.error("Please enter a stock number", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }

                let responses = await getResponse("Stocks/getstockdetailsusingpartno", {
                    CustomerId: customerId,
                    partno: value,
                    UserId: UserId,
                });

                checkRepDiscLimit(responses);

                //#region  update the rowType for newly added item
                if (responses !== null && responses !== undefined) {
                    responses = [responses].map((item) => {
                        if (item.rowType === undefined) {
                            return {
                                rowType: item.type === null ? "CI" : item.type,
                                displayAssemblyGroupName: "",
                                assemblyGroupName: "",
                                ...item,
                                l60: item.l60 === 0 ? "" : item.l60,
                                l50: item.l50 === 0 ? "" : item.l50,
                                l55: item.l55 === 0 ? "" : item.l55,
                                l20: item.l20 === 0 ? "" : item.l20,
                                totalCost: item.totalCost === 0 ? 0.0 : item.totalCost,
                                totalPrice: item.totalPrice === 0 ? 0.0 : item.totalPrice
                            };
                        } else return item
                    })[0];
                }
                //#endregion

                if (responses !== null && responses !== undefined) {
                    let updatedItemList = [];
                    let sbgrpId = "";
                    const status = responses.type;
                    if (status === "M" || status === "K") {
                        setBOMPart(true);
                    } else {
                        setBOMPart(false);
                    }
                    if (dataItem.stStockCode != undefined && dataItem.stStockCode !== "" && !responses.description.toLowerCase().includes('obsolete', 'not exist')) {
                        let newItems = [];
                        const checkItemExist = itemList.filter((f) => f.stStockCode === value.toUpperCase());

                        //do not apply qty breakdown for existing item
                        let ignoreBreakDown = false;
                        if (checkItemExist.length > 0) {
                            checkItemExist.map((item) => {
                                if (responses.l20 === item.l20 
                                    && responses.l50 === item.l50 
                                    && responses.l55 === item.l55 
                                    && responses.l60 === item.l60) ignoreBreakDown = false;
                                else ignoreBreakDown = true;
                            });
                        }
                        //

                        // there is already a stockNumber in the grid. we are changing it to a new stock number

                        if (responses.stStockCode !== null && responses.stStockCode !== undefined) {
                            if (checkItemExist.length > 0 && !ignoreBreakDown) {
                                newItems = checkItemExist.slice(checkItemExist.length - 1).map((item) => {
                                    let totalCost = 1 * item.stEecostRound;
                                    let totalPrice = 1 * item.nettEA;
                                    return {
                                        ...item,
                                        qty: 1,
                                        stStockCode: item.stStockCode,
                                        rowId: dataItem.rowId,
                                        stId: item.stId,
                                        groupName: dataItem.groupName ? dataItem.groupName : "",
                                        groupId: dataItem.groupId ? dataItem.groupId : "",
                                        subGroupName: dataItem.subGroupName ? dataItem.subGroupName : "",
                                        subGroupId: dataItem.subGroupId ? dataItem.subGroupId : "",
                                        displaySubGroupName: "",
                                        displayGroupName: "",
                                        totalCost: totalCost,
                                        totalPrice: totalPrice,
                                        position: dataItem.position,
                                        id: dataItem.id,
                                        parentId: dataItem.parentId,
                                        oldStockCode: value

                                    };
                                });
                            } else {
                                newItems = [responses].map((response) => {
                                    let dcOnLp = parseFloat(response?.dconLp.toFixed(1));
                                    let totalPrice = response.repDisc
                                        ? response.repDisc
                                        : response.qty * response.nettEA;
                                    let margin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.stEecost) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    let frcMargin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.stFutureCost) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    let actMargin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.actCostExact) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    let totalCost = response.qty * response.stEecostRound;
                                    let groupDbExtraId = "";
                                    let subGroupDbExtraId = "";
                                    let nonGroupDbExtraId = "";

                                    return {
                                        ...response,
                                        dcOnLp,
                                        totalPrice,
                                        margin,
                                        frcMargin,
                                        actMargin,
                                        totalCost,
                                        groupName: dataItem.groupName ? dataItem.groupName : "",
                                        groupId: dataItem.groupId ? dataItem.groupId : "",
                                        displayGroupName: dataItem.displayGroupName ? dataItem.displayGroupName : "",
                                        subGroupName: dataItem.subGroupName ? dataItem.subGroupName : "",
                                        subGroupId: dataItem.subGroupId ? dataItem.subGroupId : "",
                                        displaySubGroupName: dataItem.displaySubGroupName
                                            ? dataItem.displaySubGroupName
                                            : "",
                                        rowId: dataItem.rowId,
                                        groupDbExtraId,
                                        subGroupDbExtraId,
                                        nonGroupDbExtraId,
                                        position: dataItem.position,
                                        id: dataItem.id,
                                        parentId: dataItem.parentId,
                                        oldStockCode: value
                                        //  lcMargin: "",
                                    };
                                });
                            }

                            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });
                            itemList[itemIndex] = newItems[0];
                            updatedItemList = [...itemList];
                            updatedItemList = itemPositioning(true, updatedItemList);
                            setItemList(itemList);
                            setComputeTotals(true) // add by sachini
                            if (newItems[0].subGroupId.length > 0) sbgrpId = newItems[0].subGroupId;
                            //triggerComputeGrpTotals(newItems, updatedItemList,"Update",newItems[0].groupId);
                        } else {
                            //capture the added dbextra here to ignore changing the values into unknown parts
                            if (dataItem.rowType === "DX") {
                                let updatedItem = {
                                    ...itemRow,
                                    stStockCode: value,
                                };
                                itemList[itemIndex] = updatedItem;

                                // footer calculation
                                const {
                                    totalPrice,
                                    totalSteEeCostRound,
                                    totalStFutureCost,
                                    totalActCostExact,
                                    totalListPrice,
                                    totalNettEA,
                                    totalCost,
                                    totalMargin,
                                    totalActMargin,
                                    totalFrcMargin,
                                    totalDcOnLp,
                                } = footerCalculation(itemList);

                                setTotalItemPrice(totalPrice);
                                setTotalCostAmt(totalCost);
                                setTotalstEecostRound(totalSteEeCostRound);
                                setTotalStFutureCost(totalStFutureCost);
                                setTotalActCostEAExact(totalActCostExact);
                                setTotalListPrice(totalListPrice);
                                setTotalCost(totalCost);
                                setTotalNettEA(totalNettEA);
                                setMaxMargin(totalMargin);
                                setActMargin(totalActMargin);
                                setMaxFrcMargin(totalFrcMargin);
                                setFooterDisc(totalDcOnLp);

                                setItemList(itemList);
                                updateQuoteDetailsForId(id, { selectedItems: itemList });
                                setComputeTotals(true) // add by sachini

                            } else {
                                // entered stock number does not exist in the database


                                newItems = [responses].map((response) => {
                                    return {
                                        ...response,
                                        stStockCode: value,
                                        actMargin: 0,
                                        dcCode: "",
                                        dcOnLp: 0,
                                        dconLp: 0,
                                        frcMargin: 0,
                                        lcMargin: 0,
                                        actMargin: 0,
                                        actCost: 0,
                                        actCostExact: 0,
                                        margin: 0,
                                        repDisc: 0,
                                        soh: 0,
                                        costEA: "",
                                        costEAExact: "",
                                        lastCost: "",
                                        lastCostExact: "",
                                        stEecost: 0,
                                        stEecostRound: 0,
                                        stFutureCost: 0,
                                        stFutureCostRound: 0,
                                        totalCost: 0,
                                        totalPrice: 0,
                                        type: "CI",
                                        rowType: "CI",
                                        groupName: dataItem.groupName ? dataItem.groupName : "",
                                        groupId: dataItem.groupId ? dataItem.groupId : "",
                                        displayGroupName: dataItem.displayGroupName ? dataItem.displayGroupName : "",
                                        subGroupName: dataItem.subGroupName ? dataItem.subGroupName : "",
                                        subGroupId: dataItem.subGroupId ? dataItem.subGroupId : "",
                                        displaySubGroupName: dataItem.displaySubGroupName
                                            ? dataItem.displaySubGroupName
                                            : "",
                                        rowId: dataItem.rowId,
                                        //commented by Manoj on 2023-09-11
                                        //stId: uuidv4(),
                                        groupDbExtraId: "",
                                        subGroupDbExtraId: "",
                                        nonGroupDbExtraId: "",
                                        position: event.dataItem.position,
                                        id: uuidv4(),
                                        parentId: event.dataItem.parentId,
                                        inEdit: event.dataItem.inEdit,
                                    };
                                });

                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });
                                itemList[itemIndex] = newItems[0];
                                updatedItemList = [...itemList];
                                updatedItemList = itemPositioning(true, updatedItemList);
                                setItemList(itemList);
                                setComputeTotals(true) // add by sachini
                                if (newItems[0].subGroupId.length > 0) sbgrpId = newItems[0].subGroupId;
                                setComputeTotals(true) // add by sachini


                                // footer calculation
                                const {
                                    totalPrice,
                                    totalSteEeCostRound,
                                    totalStFutureCost,
                                    totalActCostExact,
                                    totalListPrice,
                                    totalNettEA,
                                    totalCost,
                                    totalMargin,
                                    totalActMargin,
                                    totalFrcMargin,
                                    totalDcOnLp,
                                } = footerCalculation(updatedItemList);

                                setTotalItemPrice(totalPrice);
                                setTotalCostAmt(totalCost);
                                setTotalstEecostRound(totalSteEeCostRound);
                                setTotalStFutureCost(totalStFutureCost);
                                setTotalActCostEAExact(totalActCostExact);
                                setTotalListPrice(totalListPrice);
                                setTotalCost(totalCost);
                                setTotalNettEA(totalNettEA);
                                setMaxMargin(totalMargin);
                                setActMargin(totalActMargin);
                                setMaxFrcMargin(totalFrcMargin);
                                setFooterDisc(totalDcOnLp);
                            }
                        }
                        if (dataItem.rowType === "DX") return;
                        if (newItems[0].groupName === GroupItemTypes.NON_GROUPED_ITEMS) {
                            triggerComputeGrpTotals(
                                newItems,
                                updatedItemList,
                                "Update",
                                null, //newItems[0].groupId,
                                null, //sbgrpId,
                                dbExtraCharge
                            );
                        } else {
                            triggerComputeGrpTotals(
                                newItems,
                                updatedItemList,
                                "Update",
                                curGrpId, //newItems[0].groupId,
                                curSubGrpId, //sbgrpId,
                                dbExtraCharge
                            );
                        }
                    } else {
                        let newItems = [];

                        if (responses.stStockCode !== null && responses.stStockCode !== undefined && !responses.description.toLowerCase().includes('obsolete', 'not exist')) {
                            const checkItemExist = itemList.filter(
                                (f) => f.stStockCode == value.toUpperCase() && f.stStatus === "X"
                            );
                            if (checkItemExist.length > 0) {
                                newItems = checkItemExist.slice(checkItemExist.length - 1).map((item) => {
                                    console.log("item 2:", item);
                                    // let nettEA = item.stStatus === "X" ? 0 : item.nettEA;
                                    // let qty = item.stStatus === "X" ? 0 : item.qty;
                                    let nettEA = item.stStatus === "X" ? 0 : Number(item?.nettEA.toFixed(2));
                                    let qty = item.stStatus === "X" ? 0 : Number(item?.qty.toFixed(2));

                                    let totalCost = qty * item.stEecostRound;
                                    let totalPrice = qty * nettEA;
                                    let margin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat((((nettEA - item.stEecost) / item.nettEA) * 100).toFixed(1));
                                    let frcMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.stFutureCost) / item.nettEA) * 100).toFixed(1)
                                            );
                                    let actMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.actCostExact) / item.nettEA) * 100).toFixed(1)
                                            );
                                    let lcMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.lastCostExact) / item.nettEA) * 100).toFixed(1)
                                            );
                                    return {
                                        ...item,
                                        qty: qty,
                                        stStockCode: item.stStockCode,
                                        rowType: item.stStatus === "X" ? "CI" : item.type,
                                        rowId: dataItem.rowId,
                                        nettEA: nettEA,
                                        frcMargin: frcMargin,
                                        actMargin: actMargin,
                                        lcMargin: lcMargin,
                                        margin: 0,
                                        //commented by Manoj on 2023-09-11
                                        //stId: uuidv4(),
                                        groupName:
                                            itemIndex > -1
                                                ? dataItem.groupName
                                                : itemList[itemList.length - 1].groupName,
                                        groupId:
                                            itemIndex > -1 ? dataItem.groupId : itemList[itemList.length - 1].groupId,
                                        subGroupName:
                                            itemIndex > -1
                                                ? dataItem.subGroupName
                                                : itemList[itemList.length - 1].subGroupName,
                                        subGroupId:
                                            itemIndex > -1
                                                ? dataItem.subGroupId
                                                : itemList[itemList.length - 1].subGroupId,
                                        displaySubGroupName: "",
                                        displayGroupName: "",
                                        totalCost: totalCost,
                                        totalPrice: totalPrice,
                                    };
                                });
                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });
                                itemList.length === 0 || itemIndex < 0
                                    ? setItemList(newItems)
                                    : (itemList[itemIndex] = newItems[0]);
                                if (newItems[0].subGroupId.length > 0) sbgrpId = newItems[0].subGroupId;
                                updatedItemList = [...itemList];
                                setComputeTotals(true) // add by sachini

                                // triggerComputeGrpTotals(
                                //     newItems,
                                //     updatedItemList,
                                //     itemIndex < 0 ? "Add" : "Update",
                                //     curGrpId, // newItems[0].groupId,
                                //     curSubGrpId, // sbgrpId,
                                //     dbExtraCharge
                                // );
                            } else {
                                newItems = [responses].map((response) => {
                                    //#region check if item exist on the list to capture the last NETTEA
                                    if (response.stStockCode !== null && response.stStockCode !== undefined) {


                                        const checkStkCodeExist = itemList.some((f) => f.stStockCode == value.toUpperCase());

                                        if (checkStkCodeExist) {
                                            const lastNettEA = itemList.filter((i) => i.stStockCode === value.toUpperCase() && i.nettEA != '').at(-1);
                                            if ([lastNettEA].length > 0) {
                                                response.nettEA = lastNettEA?.nettEA;
                                                response.dconLp = lastNettEA?.dconLp;
                                                response.margin = lastNettEA?.margin;
                                                response.frcMargin = lastNettEA?.frcMargin;
                                                response.actMargin = lastNettEA?.actMargin;
                                                response.repDisc = lastNettEA?.repDisc;
                                            }
                                        }
                                    }
                                    //#endregion
                                    let stEecostRound = parseFloat(response?.stEecost.toFixed(2));
                                    let dcOnLp = calculateItemDcOnLp(response?.nettEA, response?.listPrice).toFixed(1);
                                    let totalPrice = response.repDisc
                                        ? response.repDisc
                                        : response.qty * response.nettEA;
                                    let margin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.stEecost) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    let frcMargin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.stFutureCost) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    let actMargin =
                                        response?.nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (
                                                    ((response.nettEA - response.actCostExact) / response.nettEA) *
                                                    100
                                                ).toFixed(1)
                                            );
                                    // let totalCost = response.qty * response.stEecostRound;
                                    let totalCost = response.qty * stEecostRound;
                                    let groupDbExtraId = "";
                                    let subGroupDbExtraId = "";
                                    let nonGroupDbExtraId = "";
                                    let idxOf = itemIndex < 0 || itemList.length === 1 ? 0 : itemIndex - 1;


                                    //Added by Manoj on 2024-03-13
                                    const itemListLastRowDetails = itemList[itemList.length - 1] !== undefined && itemList[itemList.length - 1].length > 0 ? itemList[itemList.length - 1]
                                        : dataItem;

                                    const updatedItemListLastRowDetails = originalItems[id]?.items.length > 0 ? originalItems[id]?.items[originalItems[id]?.items.length - 1] : dataItem;
                                    let _subGroupID = "";
                                    let _subGroupName = "";

                                    if (itemList !== undefined && itemList.length > 0) {
                                        if (itemList.length === 1) {
                                            const lastRow = itemList[0];
                                            if (lastRow.rowType === "S") {

                                                _subGroupID = "";
                                                _subGroupName = "";

                                            } else if (lastRow.rowType === "G") {
                                                _subGroupID = "";
                                                _subGroupName = "";
                                            }
                                            else if (lastRow.rowType === "SG") {

                                            }

                                        }
                                        else if (itemList.length > 1) {
                                            const lastRow = itemList[itemList.length - 1];

                                            if (lastRow.rowType === "S") {


                                                //filter from the originalItems
                                                _subGroupID = lastRow.subGroupId !== '' ? lastRow.subGroupId : updatedItemListLastRowDetails.subGroupId;
                                                _subGroupName = lastRow.subGroupName !== '' ? lastRow.subGroupName : updatedItemListLastRowDetails.subGroupName;

                                            } else if (lastRow.rowType === "G") {
                                                _subGroupID = lastRow.subGroupId;
                                                _subGroupName = lastRow.subGroupName;
                                            }
                                            else if (lastRow.rowType === "SG") {
                                                _subGroupID = lastRow.subGroupId;
                                                _subGroupName = lastRow.subGroupName;
                                            }
                                            else if (lastRow.rowType === "CI") {

                                                _subGroupID = lastRow.subGroupId !== '' ? lastRow.subGroupId : updatedItemListLastRowDetails.subGroupId;
                                                _subGroupName = lastRow.subGroupName !== '' ? lastRow.subGroupName : updatedItemListLastRowDetails.subGroupName;
                                            }
                                            else if (lastRow.rowType === "") {
                                                const lastRow = itemList[itemList.length - 2];
                                                _subGroupID = lastRow.subGroupId;
                                                _subGroupName = lastRow.subGroupName;
                                            }
                                            else {
                                                _subGroupID = lastRow.subGroupId;
                                                _subGroupName = lastRow.subGroupName;

                                            }
                                        }
                                    }
                                    else {
                                        _subGroupID = "";
                                        _subGroupName = "";
                                    }


                                    return {
                                        ...response,
                                        stEecostRound,
                                        dcOnLp,
                                        totalPrice,
                                        margin,
                                        frcMargin,
                                        actMargin,
                                        totalCost,
                                        groupName:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? GroupItemTypes.NON_GROUPED_ITEMS
                                                    : itemList[itemList.length - 1]?.groupName
                                                : dataItem.groupName !== undefined
                                                    ? dataItem.groupName
                                                    : itemList[itemList.length - 1]?.groupName,
                                        groupId:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? uuidv4()
                                                    : itemList[itemList.length - 1]?.groupId
                                                : dataItem.groupId !== undefined
                                                    ? dataItem.groupId
                                                    : itemList[itemList.length - 1]?.groupId,

                                        //// commented by Manoj on 2024-03-13 for fix the subgroup name and id issue
                                        // // subGroupName:
                                        // //     itemIndex < 0
                                        // //         ? itemList.length === 0
                                        // //             ? ""
                                        // //             : itemList[itemList.length - 1]?.subGroupName
                                        // //         : dataItem.subGroupName !== undefined
                                        // //             ? dataItem.subGroupName
                                        // //             : itemList[itemList.length - 1]?.subGroupName,

                                        // // subGroupId:
                                        // // itemIndex < 0
                                        // //     ? itemList.length === 0
                                        // //         ? ""
                                        // //         : itemList[itemList.length - 1]?.subGroupId
                                        // //     : dataItem.subGroupId !== undefined
                                        // //         ? dataItem.subGroupId
                                        // //         : itemList[itemList.length - 1]?.subGroupId,

                                        //Start - added by Manoj on 2024-03-13 for testing- 

                                        subGroupName:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? ""
                                                    //: itemList[itemList.length - 1]?.subGroupName !== '' ? itemList[itemList.length - 1]?.subGroupName : originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ? originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupName : ""
                                                    : _subGroupName
                                                : dataItem.subGroupName !== undefined
                                                    ? dataItem.subGroupName
                                                    : itemList[itemList.length - 1]?.subGroupName !== '' ? itemList[itemList.length - 1]?.subGroupName : originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ? originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupName : "",

                                        subGroupId:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? ""
                                                    // : itemList[itemList.length - 1]?.subGroupId !== '' ? itemList[itemList.length - 1]?.subGroupId : originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ? originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupId : ""
                                                    : _subGroupID
                                                : dataItem.subGroupId !== undefined
                                                    ? dataItem.subGroupId
                                                    : itemList[itemList.length - 1]?.subGroupId !== '' ? itemList[itemList.length - 1]?.subGroupId : originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ? originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupId : "",


                                        // subGroupName: originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ?
                                        //     itemIndex < 0
                                        //         ? originalItems[id]?.items.length === 0
                                        //             ? ""
                                        //             : originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupName
                                        //         : dataItem.subGroupName !== undefined
                                        //             ? dataItem.subGroupName
                                        //             : originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupName

                                        //             :  itemIndex < 0
                                        //                 ? itemList.length === 0
                                        //                     ? ""
                                        //                     : itemList[itemList.length - 1]?.subGroupId
                                        //                 : dataItem.subGroupId !== undefined
                                        //                     ? dataItem.subGroupId
                                        //                     : itemList[itemList.length - 1]?.subGroupId,
                                        // subGroupId: originalItems[id]?.items.length !== undefined && originalItems[id]?.items.length > 0 ?
                                        // itemIndex < 0
                                        // ? originalItems[id]?.items.length === 0
                                        //     ? ""
                                        //     : originalItems[id]?.items[itemList.length - 1]?.subGroupId
                                        // : dataItem.subGroupId !== undefined
                                        //     ? dataItem.subGroupId
                                        //     : originalItems[id]?.items[originalItems[id]?.items.length - 1]?.subGroupId
                                        // :  itemIndex < 0
                                        //     ? itemList.length === 0
                                        //         ? ""
                                        //         : itemList[itemList.length - 1]?.subGroupId
                                        //     : dataItem.subGroupId !== undefined
                                        //         ? dataItem.subGroupId
                                        //         : itemList[itemList.length - 1]?.subGroupId
                                        //    ,
                                        //End -added by Manoj on 2024-03-13 for testing- 
                                        displaySubGroupName: "",
                                        displayGroupName: "",
                                        rowId: dataItem.rowId,
                                        groupDbExtraId,
                                        subGroupDbExtraId,
                                        nonGroupDbExtraId,
                                    };
                                });

                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });
                                setItemList([...(originalItems[id]?.items || []), ...newItems]); //add by sachini
                                setComputeTotals(true) // add by sachini

                                //added by Manoj on 2024-03-13 for testing- 
                                //originalItems[id]?.items

                                //end - 

                                itemList.length === 0 || itemIndex < 0
                                    ? setItemList(newItems)
                                    : (itemList[itemIndex] = newItems[0]);

                                if (newItems[0]?.subGroupId?.length > 0) sbgrpId = newItems[0].subGroupId;
                                updatedItemList = [...itemList];

                                if (itemIndex < 0 || dataItem.stStockCode === undefined) {
                                    triggerComputeGrpTotals(
                                        newItems,
                                        updatedItemList,
                                        "Add",
                                        curGrpId, // newItems[0].groupId,
                                        curSubGrpId, // sbgrpId,
                                        dbExtraCharge
                                    );
                                } else if (dataItem.stStockCode || dataItem.stStockCode === "") {
                                    triggerComputeGrpTotals(
                                        newItems,
                                        updatedItemList,
                                        "Update",
                                        curGrpId, // newItems[0].groupId,
                                        curSubGrpId, // sbgrpId,
                                        dbExtraCharge
                                    );
                                }
                            }
                        } else {
                            const checkItemExist = itemList.filter((f) => f.stStockCode == value);
                            const isEmptyItem = checkItemExist.some((f) => f.rowType === "");
                            if (checkItemExist.length > 0 && !isEmptyItem && !responses.description.toLowerCase().includes('obsolete', 'not exist')) {
                                newItems = checkItemExist.slice(checkItemExist.length - 1).map((item) => {
                                    // let nettEA = item.stStatus === null ? 0 : item.nettEA;
                                    // let qty = item.stStatus === null ? 0 : item.qty;
                                    let nettEA = item.rowType === "" ? 0 : Number((item.nettEA || 0).toFixed(2));
                                    let qty = item.rowType === "" ? 0 : Number((item.qty || 0).toFixed(2));

                                    let totalCost = qty * item.stEecostRound;
                                    let totalPrice = qty * nettEA;
                                    let margin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat((((nettEA - item.stEecost) / item.nettEA) * 100).toFixed(1));
                                    let frcMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.stFutureCost) / item.nettEA) * 100).toFixed(1)
                                            );
                                    let actMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.actCostExact) / item.nettEA) * 100).toFixed(1)
                                            );
                                    let lcMargin =
                                        nettEA === 0.0
                                            ? 0.0
                                            : parseFloat(
                                                (((nettEA - item.lastCostExact) / item.nettEA) * 100).toFixed(1)
                                            );
                                    return {
                                        ...item,
                                        qty: qty,
                                        stStockCode: item.stStockCode,
                                        rowType: item.stStatus === null ? "CI" : item.type,
                                        rowId: dataItem.rowId,
                                        nettEA: nettEA,
                                        frcMargin: frcMargin,
                                        actMargin: actMargin,
                                        lcMargin: lcMargin,
                                        margin: 0,
                                        dcCode: "",
                                        dcRate: 0,
                                        dcOnLp: 0,
                                        nettEA: nettEA,
                                        repDisc: 0,
                                        soh: 0,
                                        totalCost: totalCost,
                                        totalPrice: totalPrice,
                                        type: "",
                                        rowType: "CI",
                                        //commented by Manoj on 2023-09-11
                                        //stId: uuidv4(),
                                        groupName:
                                            itemIndex > -1
                                                ? dataItem.groupName
                                                : itemList[itemList.length - 1].groupName,
                                        groupId:
                                            itemIndex > -1 ? dataItem.groupId : itemList[itemList.length - 1].groupId,
                                        subGroupName:
                                            itemIndex > -1
                                                ? dataItem.subGroupName
                                                : itemList[itemList.length - 1].subGroupName,
                                        subGroupId:
                                            itemIndex > -1
                                                ? dataItem.subGroupId
                                                : itemList[itemList.length - 1].subGroupId,
                                        displaySubGroupName: "",
                                        displayGroupName: "",
                                        // totalCost: totalCost,
                                        // totalPrice: totalPrice,
                                        // rowType: "CI",
                                    };
                                });
                                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });

                                itemList.length === 0 || itemIndex < 0
                                    ? setItemList(newItems)
                                    : (itemList[itemIndex] = newItems[0]);
                                if (newItems[0].subGroupId.length > 0) sbgrpId = newItems[0].subGroupId;
                                updatedItemList = [...itemList];
                                setComputeTotals(true) // add by sachini
                                // triggerComputeGrpTotals(
                                //     newItems,
                                //     updatedItemList,
                                //     itemIndex < 0 ? "Add" : "Update",
                                //     curGrpId, // newItems[0].groupId,
                                //     curSubGrpId, // sbgrpId,
                                //     dbExtraCharge
                                // );
                            } else {
                                newItems = [responses].map((response) => {
                                    return {
                                        ...response,
                                        stStockCode: value,
                                        actMargin: 0,
                                        dcCode: "",
                                        dcRate: 0,
                                        dcOnLp: 0,
                                        dconLp: 0,
                                        listPrice: "",
                                        l20: "",
                                        l60: "",
                                        l55: "",
                                        l50: "",
                                        nettEA: 0,
                                        frcMargin: 0,
                                        lcMargin: 0,
                                        actCost: 0,
                                        actCostExact: 0,
                                        margin: 0,
                                        repDisc: 0,
                                        soh: 0,
                                        stEecost: 0,
                                        stEecostRound: 0,
                                        stFutureCost: 0,
                                        stFutureCostRound: 0,
                                        totalCost: 0,
                                        totalPrice: 0,
                                        type: "",
                                        rowType: "CI",
                                        groupName:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? GroupItemTypes.NON_GROUPED_ITEMS
                                                    : itemList[itemList.length - 1]?.groupName
                                                : dataItem.groupName !== undefined
                                                    ? dataItem.groupName
                                                    : itemList[itemList.length - 1]?.groupName,
                                        groupId:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? uuidv4()
                                                    : itemList[itemList.length - 1]?.groupId
                                                : dataItem.groupId !== undefined
                                                    ? dataItem.groupId
                                                    : itemList[itemList.length - 1]?.groupId,
                                        subGroupName:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? ""
                                                    : itemList[itemList.length - 1]?.subGroupName
                                                : dataItem.subGroupName !== undefined
                                                    ? dataItem.subGroupName
                                                    : itemList[itemList.length - 1]?.subGroupName,
                                        subGroupId:
                                            itemIndex < 0
                                                ? itemList.length === 0
                                                    ? ""
                                                    : itemList[itemList.length - 1]?.subGroupId
                                                : dataItem.subGroupId !== undefined
                                                    ? dataItem.subGroupId
                                                    : itemList[itemList.length - 1]?.subGroupId,
                                        displayGroupName: "",
                                        displaySubGroupName: "",
                                        rowId: dataItem.rowId,
                                        groupDbExtraId: "",
                                        subGroupDbExtraId: "",
                                        nonGroupDbExtraId: "",
                                    };
                                });
                            }
                            // updateItemListForId(id,{items:newItems});
                            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...newItems] });

                            if (newItems[0].subGroupId.length > 0) sbgrpId = newItems[0].subGroupId;

                            itemList.length === 0 || itemIndex < 0
                                ? setItemList(newItems)
                                : (itemList[itemIndex] = newItems[0]);
                            updatedItemList = [...itemList];

                            if (itemIndex < 0)
                                triggerComputeGrpTotals(
                                    newItems,
                                    updatedItemList,
                                    "Add",
                                    curGrpId, // newItems[0].groupId,
                                    curSubGrpId, // sbgrpId,
                                    dbExtraCharge
                                );
                            else
                                triggerComputeGrpTotals(
                                    newItems,
                                    updatedItemList,
                                    "Update",
                                    curGrpId, // newItems[0].groupId,
                                    curSubGrpId, // sbgrpId,
                                    dbExtraCharge
                                );
                        }
                    }
                } else {
                    toast.error("Connection error", {
                        autoClose: 2000,
                        closeOnClick: true,
                        position: "top-center",
                        transition: Slide,
                    });
                }


            }

            setSelectedItems([]);
            setSelectedIndex(-1);
        }

        //Start - Added by Manoj on 2024-03-17

        else if (field === "margin") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = itemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
            let updatedMargin = -1;
            let updatedItem = null;
            let currentValue = value.replace("%", "");
            if (!isNaN(parseFloat(currentValue))) {
                updatedMargin = parseFloat(parseFloat(currentValue).toFixed(1));
            } else {
                updatedMargin = itemRow.margin;
                toast.error("Please enter a valid number");
                return;
            }

            if (dataItem.rowType === "A") {
                ///Computation applies to Assembly
                const netEA = parseFloat(itemRow.stEecost / ((100 - currentValue) / 100)).toFixed(2);
                const margin = parseFloat(currentValue).toFixed(1) + "%";
                updatedItem = {
                    ...itemRow,
                    nettEA: netEA,
                    totalPrice: parseFloat(Number(itemRow.qty) * netEA).toFixed(2),
                    margin: margin,
                    actMargin: margin,
                    frcMargin: margin,
                };

                itemList[itemIndex] = updatedItem;
                setItemList(itemList);
            } else {
                const updatedNettEA = parseFloat(
                    (parseFloat(100 * itemRow.stEecost).toFixed(2) / (100 - updatedMargin)).toFixed(2)
                );
                let updatedRepDisc = 0;
                // let ogNettEA =
                //     originalItems[id]?.items?.find((item) => item.stStockCode === itemRow.stStockCode)?.nettEA ||
                //     originalItems[originalQuoteId]?.items?.find((item) => item.stStockCode === itemRow.stStockCode)
                //         ?.nettEA;

                let ogNettEA = getOrgiNettEA(itemRow);

                if (rowMarginData[id] && rowMarginData[id][itemRow.rowId]) {
                    // updatedRepDisc = calculateItemRepDisc(rowMarginData[id][itemRow.rowId]?.nettEA, updatedNettEA);
                    updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
                } else {
                    updateMarginRowForId(id, { [itemRow.rowId]: itemRow });

                    if (rowNettEaData[id] && rowNettEaData[id][itemRow.rowId]) {
                        // const ogNettEA = rowNettEaData[id][itemRow.rowId]?.nettEA;

                        if (ogNettEA !== itemRow.nettEA) {
                            updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
                        }
                    } else {
                        updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
                        // updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
                    }
                }

                updatedItem = {
                    ...itemRow,
                    margin: updatedMargin,
                    nettEA: updatedNettEA,
                    dcOnLp:
                        calculateItemDcOnLp(updatedNettEA, itemRow.listPrice) > 0
                            ? calculateItemDcOnLp(updatedNettEA, itemRow.listPrice)
                            : 0,
                    totalPrice: parseFloat((itemRow.qty * updatedNettEA).toFixed(2)),
                    repDisc: updatedRepDisc < 0 ? 0.0 : updatedRepDisc,
                    actMargin: parseFloat((((updatedNettEA - itemRow.actCostExact) / updatedNettEA) * 100).toFixed(1)),
                    frcMargin: parseFloat((((updatedNettEA - itemRow.stFutureCost) / updatedNettEA) * 100).toFixed(1)),
                    lcMargin: parseFloat((((updatedNettEA - itemRow.lastCostExact) / updatedNettEA) * 100).toFixed(1)),
                    //   lcMargin: "",
                };

                itemList[itemIndex] = updatedItem;
            }

            if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                if (updatingItemSubGroup && !isASubGroupRow) {
                    let updatedItemList = [...itemList];

                    //sub group level db extra calculations
                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            dbExtraCharge,
                            GroupTypes.SUB_GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    //sub group level calculations
                    const intialSubGroupRows = getAllExistingSubGroupedItems(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );
                    const subGroupFilteredItems = intialSubGroupRows.filter((f) => f.rowType !== "AI");
                    const subGroupRows = subGroupFilteredItems.map((s) => {
                        return {
                            ...s,
                            totalPrice: parseFloat(parseFloat(s.totalPrice).toFixed(2)),
                            nettEA: parseFloat(parseFloat(s.nettEA).toFixed(2)),
                            margin: parseFloat(s.margin.toString().replace('%', '')),
                            actMargin: parseFloat(s.actMargin.toString().replace('%', '')),
                            frcMargin: parseFloat(s.frcMargin.toString().replace('%', ''))
                        };
                    });

                    const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
                    const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
                    const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
                    const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
                    const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
                    const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
                    // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                    const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
                    const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                    const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupStFutureCost
                    );
                    const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupActCostExact
                    );

                    const subGroup = getSubGroupRowByGroupAndSubGroup(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    )[0];
                    const subGroupIndex = updatedItemList.indexOf(subGroup);

                    const updatedSubGroup = {
                        ...subGroup,
                        totalPrice: subGroupTotalPrice * subGroup.qty,
                        nettEA: subGroupNettEA,
                        dcOnLp: subGroupDcOnLp,
                        margin: subGroupMargin,
                        frcMargin: subGroupFrcMargin,
                        actMargin: subGroupActMargin,
                        actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        actCostExact: subGroupActCostExact,
                        stFutureCost: subGroupStFutureCost,
                        stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                        stEecost: subGroupstEecost,
                        stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                        //   lcMargin: "",
                    };

                    updatedItemList[subGroupIndex] = updatedSubGroup;

                    //group level db extra calculations
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            null,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    //group level calculations
                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const subGroups = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    const combinedListFiltered = [...groupRowList, ...subGroups].filter((f) => f.rowType !== "AI");
                    const combinedListFormatted = combinedListFiltered.map((s) => {
                        return {
                            ...s,
                            totalPrice: parseFloat(parseFloat(s.totalPrice).toFixed(2)),
                            nettEA: parseFloat(parseFloat(s.nettEA).toFixed(2)),
                            margin: parseFloat(s.margin.toString().replace('%', '')),
                            actMargin: parseFloat(s.actMargin.toString().replace('%', '')),
                            frcMargin: parseFloat(s.frcMargin.toString().replace('%', ''))
                        };
                    });
                    const combinedList = combinedListFormatted;

                    const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
                    const groupListPrice = calculateGroupWiseTotal(combinedList, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
                    const groupstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow,
                        totalPrice: groupTotalPrice * groupRow.qty,
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        //  lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: itemList[0],
                        });
                    }
                } else if (updatingItemSubGroup && isASubGroupRow) {
                    let subGroupItems = [];
                    const updatedItemList = [...itemList];
                    subGroupItems = getItemsWithoutDBExtra(
                        updatedItemList,
                        GroupTypes.SUB_GROUP,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );

                    const updatedSubGroupItems = subGroupItems.map((s) => {
                        const originalNettEA = originalItems[id]
                            ? originalItems[id]?.items?.find((i) => i.stStockCode === s.stStockCode)?.nettEA
                            : originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === s.stStockCode)
                                ?.nettEA;

                        const itemNettEa = calculateItemNettEAfromMargin(s.stEecost, updatedMargin);
                        // const itemRepDisc = calculateItemRepDisc(s.nettEA, itemNettEa);
                        const itemRepDisc = calculateItemRepDisc(originalNettEA, itemNettEa);
                        const itemDcOnLp = calculateItemDcOnLp(itemNettEa, s.listPrice);
                        const itemTotalPrice = calculateItemTotalPrice(s.qty, itemNettEa);
                        const itemActMargin = calculateItemActMargin(itemNettEa, s.actCostExact);
                        const itemFrcMargin = calculateItemFrcMargin(itemNettEa, s.stFutureCost);
                        const itemLcMargin = calculateItemLcMargin(itemNettEa, s.lastCostExact);

                        return {
                            ...s,
                            margin: updatedMargin,
                            nettEA: itemNettEa,
                            repDisc: itemRepDisc > 0 ? itemRepDisc : 0.0,
                            dcOnLp: itemDcOnLp,
                            totalPrice: itemTotalPrice,
                            actMargin: itemActMargin,
                            frcMargin: itemFrcMargin,
                            lcMargin: itemLcMargin,
                        };
                    });

                    updatedItemList.splice(itemIndex + 1, updatedSubGroupItems.length, ...updatedSubGroupItems);

                    //sub group level db extra calculation
                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, updatingItemSubGroup)[0];
                        const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
                        let listWithoutDbExtra = getItemsWithoutDBExtra(
                            updatedItemList,
                            GroupTypes.SUB_GROUP,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const subGroupNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        const updatedSubGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                        const updatedDbExtraRow = {
                            ...dbExtraRow,
                            nettEA: updatedSubGroupDbExtraNettEA,
                            totalPrice: updatedSubGroupDbExtraNettEA,
                        };

                        updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                    }

                    //sub group level calculations
                    const subGroupRow = updatedItemList[itemIndex];
                    subGroupItems = getAllExistingSubGroupedItems(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );
                    subGroupItems = getQtyBasedValues(subGroupItems);

                    const updatedNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    const updatedTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                    const stEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                    const updatedListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                    const updatedGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                    const updatedStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                    const calcNettEA = calculateGroupLevelNettEAFromMargin(stEecost, updatedMargin);
                    const updatedDcOnLp = calculateItemDcOnLp(calcNettEA, updatedListPrice);
                    const updatedActMargin = calculateItemActMargin(calcNettEA, updatedGroupActCostExact);

                    // remove db extra for margin calculations
                    subGroupItems = getItemsWithoutAIandDX(subGroupItems);
                    subGroupItems = getQtyBasedValues(subGroupItems);

                    const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        subGroupNettEANonDX,
                        updatedStFutureCost
                    );
                    const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        subGroupNettEANonDX,
                        updatedGroupActCostExact
                    );

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        margin: updatedMargin,
                        stEecost: stEecost,
                        stEecostRound: parseFloat(stEecost.toFixed(2)),
                        listPrice: updatedListPrice,
                        actCostExact: updatedGroupActCostExact,
                        actCost: parseFloat(updatedGroupActCostExact.toFixed(2)),
                        nettEA: updatedNettEA,
                        totalPrice: updatedTotalPrice * subGroupRow.qty,
                        dcOnLp: updatedDcOnLp,
                        actMargin: updatedActMargin,
                        totalCost: calculateItemTotalCost(subGroupRow.qty, stEecost),
                        repDisc: 0.0,
                        frcMargin: subGroupFrcMargin,
                        actMargin: subGroupActMargin,
                        lcMargin: 0,
                    };

                    updatedItemList[itemIndex] = updatedSubGroupRow;

                    //group level db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getQtyBasedGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            dbExtraCharge
                        );
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    //group level calculations
                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                    groupRowItems = getQtyBasedValues(groupRowItems);
                    subGroupRows = getQtyBasedValuesWithGroups(subGroupRows);

                    let groupItems = [...groupRowItems, ...subGroupRows];

                    const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    const groupStEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                    //remove db extra for margin calculations
                    groupRowItems = getItemsWithoutAIandDX(groupRowItems);
                    subGroupRows = getSubGroupsWithoutDX(subGroupRows, updatedItemList);
                    groupRowItems = getQtyBasedValues(groupRowItems);
                    subGroupRows = getQtyBasedValuesWithGroups(subGroupRows);

                    groupItems = [...groupRowItems, ...subGroupRows];

                    const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupStEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        groupNettEANonDX,
                        groupStFutureCost
                    );
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        groupNettEANonDX,
                        groupActCostExact
                    );

                    // old implementation
                    // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupStEecost);

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        totalPrice: groupTotalPrice * groupRow.qty,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        stEecost: groupStEecost,
                        stEecostRound: parseFloat(groupStEecost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        totalCost: calculateItemTotalCost(groupRow.qty, groupStEecost),
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        repDisc: 0.0,
                        frcMargin: groupFrcMargin,
                        actMargin: groupActMargin,
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                } else {
                    let updatedItemList = [...itemList];

                    //group level db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            null,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const updatedGroupRowList = groupRowList.map((s) => {
                        if (s.qty > 1) {
                            return {
                                ...s,
                                stEecost: calculateValuesOnSBLQtyChange(s.qty, s.stEecost),
                                stEecostRound: calculateValuesOnSBLQtyChange(s.qty, s.stEecostRound),
                                stFutureCost: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCost),
                                stFutureCostRound: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCostRound),
                                actCostExact: calculateValuesOnSBLQtyChange(s.qty, s.actCostExact),
                                actCost: calculateValuesOnSBLQtyChange(s.qty, s.actCost),
                                nettEA: calculateValuesOnSBLQtyChange(s.qty, s.nettEA),
                            };
                        }
                        return s;
                    });
                    const subGroups = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    const groupFilteredItems = [...subGroups.filter((f) => f.rowType !== "AI"), ...updatedGroupRowList.filter((f) => f.rowType !== "AI")];
                    const groupItems = groupFilteredItems.map((s) => {
                        return {
                            ...s,
                            totalPrice: parseFloat(parseFloat(s.totalPrice).toFixed(2)),
                            nettEA: parseFloat(parseFloat(s.nettEA).toFixed(2)),
                            margin: parseFloat(s.margin.toString().replace('%', '')),
                            actMargin: parseFloat(s.actMargin.toString().replace('%', '')),
                            frcMargin: parseFloat(s.frcMargin.toString().replace('%', ''))
                        };
                    });


                    const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow,
                        totalPrice: groupTotalPrice * groupRow.qty,
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        //   lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    //#region 
                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);
                    //#endregion

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: itemList[0],
                        });
                    }
                }
            } else if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && isAGroupRow) {
                const updatedItemList = [...itemList];
                const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                let groupOriginalNettEA = 0;

                if (rowMarginData[id] && rowMarginData[id][itemRow.rowId]) {
                    groupOriginalNettEA = rowMarginData[id][itemRow.rowId]?.nettEA;
                } else {
                    groupOriginalNettEA = itemRow?.nettEA;
                }

                subGroupRows.forEach((sg) => {
                    let subGroupItems = [];
                    let updatedSubGroupRow = null;

                    subGroupItems = getSubGroupItemsWithoutDBExtra(updatedItemList, updatingItemGroup, sg.subGroupId);

                    subGroupItems.forEach((s) => {
                        const originalNettEA =
                            originalItems[id]?.items?.find((i) => i.stStockCode === s.stStockCode)?.nettEA ||
                            originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === s.stStockCode)?.nettEA;
                        const idx = updatedItemList.indexOf(s);

                        const itemNettEa = calculateItemNettEAfromMargin(s.stEecost, updatedMargin);
                        const itemRepDisc = calculateItemRepDisc(originalNettEA, itemNettEa);
                        const itemDcOnLp = calculateItemDcOnLp(itemNettEa, s.listPrice);
                        const itemTotalPrice = calculateItemTotalPrice(s.qty, itemNettEa);
                        const itemActMargin = calculateItemActMargin(itemNettEa, s.actCostExact);
                        const itemFrcMargin = calculateItemFrcMargin(itemNettEa, s.stFutureCost);
                        const itemLcMargin = calculateItemLcMargin(itemNettEa, s.lastCostExact);

                        const item = {
                            ...s,
                            margin: updatedMargin,
                            nettEA: itemNettEa,
                            repDisc: itemRepDisc > 0 ? itemRepDisc : 0.0,
                            dcOnLp: itemDcOnLp,
                            totalPrice: itemTotalPrice,
                            actMargin: itemActMargin,
                            frcMargin: itemFrcMargin,
                            lcMargin: itemLcMargin,
                        };

                        updatedItemList[idx] = item;
                    });

                    //sub group level db extra calculation
                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        sg.subGroupId,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, sg.subGroupId)[0];
                        const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
                        let listWithoutDbExtra = getItemsWithoutDBExtra(
                            updatedItemList,
                            GroupTypes.SUB_GROUP,
                            updatingItemGroup,
                            sg.subGroupId
                        );
                        listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        const subGroupNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        const updatedSubGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                        const updatedDbExtraRow = {
                            ...dbExtraRow,
                            nettEA: updatedSubGroupDbExtraNettEA,
                            totalPrice: updatedSubGroupDbExtraNettEA,
                        };

                        updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                    }

                    // sub group level calculations
                    subGroupItems = getAllExistingSubGroupedItems(updatedItemList, updatingItemGroup, sg.subGroupId);
                    const sgIdx = updatedItemList.indexOf(sg);
                    const sgStEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                    const sgListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                    const sgGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                    const calcNettEA = calculateGroupLevelNettEAFromMargin(sgStEecost, updatedMargin);
                    const sgDcOnLp = calculateItemDcOnLp(calcNettEA, sgListPrice);
                    const sgActMargin = calculateItemActMargin(calcNettEA, sgGroupActCostExact);
                    const sgNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                    const sgTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                    const sgTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
                    const sgFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                    const sgFrcMargin = calculateItemFrcMargin(sgNettEA, sgFutureCost);

                    updatedSubGroupRow = {
                        ...sg,
                        margin: updatedMargin,
                        stEecost: sgStEecost,
                        stEecostRound: parseFloat(sgStEecost.toFixed(2)),
                        listPrice: sgListPrice,
                        actCostExact: sgGroupActCostExact,
                        actCost: parseFloat(sgGroupActCostExact.toFixed(2)),
                        nettEA: sgNettEA,
                        totalPrice: sgTotalPrice * sg.qty,
                        dcOnLp: sgDcOnLp,
                        actMargin: sgActMargin,
                        // totalCost: sgTotalCost * sg.qty,
                        totalCost: calculateItemTotalCost(sg.qty, sgStEecost),
                        repDisc: 0.0,
                        stFutureCost: sgFutureCost,
                        frcMargin: sgFrcMargin,
                    };

                    updatedItemList[sgIdx] = updatedSubGroupRow;
                });

                //group level margin changes
                const groupRowItems = getGroupedRowItemsWithoutDBExtra(updatedItemList, updatingItemGroup);

                groupRowItems.forEach((g) => {
                    const originalNettEA =
                        originalItems[id]?.items?.find((i) => i.stStockCode === g.stStockCode)?.nettEA ||
                        originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === g.stStockCode)?.nettEA;
                    let itm = null;
                    const idx = updatedItemList.indexOf(g);
                    const itemNettEa = calculateItemNettEAfromMargin(g.stEecost, updatedMargin);
                    const itemRepDisc = calculateItemRepDisc(originalNettEA, itemNettEa);
                    const itemDcOnLp = calculateItemDcOnLp(itemNettEa, g.listPrice);
                    const itemTotalPrice = calculateItemTotalPrice(g.qty, itemNettEa);
                    const itemActMargin = calculateItemActMargin(itemNettEa, g.actCostExact);
                    const itemFrcMargin = calculateItemFrcMargin(itemNettEa, g.stFutureCost);
                    const itemLcMargin = calculateItemLcMargin(itemNettEa, g.lastCostExact);

                    itm = {
                        ...g,
                        margin: updatedMargin,
                        nettEA: itemNettEa,
                        repDisc: itemRepDisc > 0 ? itemRepDisc : 0.0,
                        dcOnLp: itemDcOnLp,
                        totalPrice: itemTotalPrice,
                        actMargin: itemActMargin,
                        frcMargin: itemFrcMargin,
                        lcMargin: itemLcMargin,
                    };

                    updatedItemList[idx] = itm;
                });

                //group level db extra calculation
                const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                if (groupDbExtra) {
                    const dbExtraRow = getExistingGroupWiseDbExtraRow(updatedItemList, updatingItemGroup)[0];
                    const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
                    let listWithoutDbExtra = getItemsWithoutDBExtra(
                        updatedItemList,
                        GroupTypes.GROUP,
                        updatingItemGroup,
                        null
                    );
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const groupNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    const updatedGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, groupNettEA);

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedGroupDbExtraNettEA,
                        totalPrice: updatedGroupDbExtraNettEA,
                    };

                    updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                }

                // group level calculations
                const gRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                let uGroupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                uGroupRowItems = uGroupRowItems.map((i) => {
                    if (i.qty > 1) {
                        return {
                            ...i,
                            stEecost: calculateValuesOnSBLQtyChange(i.qty, i.stEecost),
                            stEecostRound: calculateValuesOnSBLQtyChange(i.qty, i.stEecostRound),
                            stFutureCost: calculateValuesOnSBLQtyChange(i.qty, i.stFutureCost),
                            stFutureCostRound: calculateValuesOnSBLQtyChange(i.qty, i.stFutureCostRound),
                            actCostExact: calculateValuesOnSBLQtyChange(i.qty, i.actCostExact),
                            actCost: calculateValuesOnSBLQtyChange(i.qty, i.actCost),
                            nettEA: calculateValuesOnSBLQtyChange(i.qty, i.nettEA),
                        };
                    }
                    return i;
                });
                const uSubGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                const groupItems = [...uGroupRowItems, ...uSubGroupRows];

                const gstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                const ugListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                const ugActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                const gCalcNettEA = calculateGroupLevelNettEAFromMargin(gstEecost, updatedMargin);
                const gDcOnLp = calculateItemDcOnLp(gCalcNettEA, ugListPrice);
                const ugActMargin = calculateItemActMargin(gCalcNettEA, ugActCostExact);
                const ugNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                const ugTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                const ugStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");

                const uGRow = {
                    ...gRow,
                    margin: updatedMargin,
                    listPrice: ugListPrice,
                    nettEA: ugNettEA,
                    totalPrice: ugTotalPrice * gRow.qty,
                    dcOnLp: gDcOnLp,
                    stEecost: gstEecost,
                    stEecostRound: parseFloat(gstEecost.toFixed(2)),
                    stFutureCost: ugStFutureCost,
                    stFutureCostRound: parseFloat(ugStFutureCost.toFixed(2)),
                    // totalCost: ugTotalCost * gRow.qty,
                    totalCost: calculateItemTotalCost(gRow.qty, gstEecost),
                    actCost: parseFloat(ugActCostExact.toFixed(2)),
                    actCostExact: ugActCostExact,
                    actMargin: ugActMargin,
                    repDisc: 0,
                };

                updatedItemList[itemIndex] = uGRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            } else {
                let updatedItemList = [...itemList];
                const nonGroupId = updatedItemList.length > 0 && updatedItemList[0].groupId;
                const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                if (nonGroupDbExtra) {
                    // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
                    const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                    updatedItemList[dbExtraRowIndex] = dbExtraRow;
                }

                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setItemList(updatedItemList);

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                // const totalPrice = calculateTotalPrice(updatedItemList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(updatedItemList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(updatedItemList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(updatedItemList);
                // setTotalListPrice(totalListPrice);

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: updatedItemList[0],
                    });
                }
            }
        } else if (field === "qty") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = itemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

            let updatedQty = -1;
            let updatedItem = null;
            updatedQty = Number(value);

            if (!isNaN(updatedQty)) {
                if (updatedQty < 0) {
                    updatedQty = itemRow.qty;
                    toast.error("Please enter a valid number");
                    return;
                } else updatedQty = Number(value);
            }
            else if (isNaN(updatedQty) && value === '-') {
                updatedQty = selectedItems[0]?.qty;
            }
            else {
                updatedQty = itemRow.qty;
                toast.error("Please enter a valid number");
                return;
            }

            if (dataItem.rowType === "AI") {
                const AssemblyIndex = itemList.findIndex(
                    (item) => item.groupId === dataItem.groupId && item.rowType === "A"
                );
                const itemRowAssembyHdr = itemList[AssemblyIndex];

                ///Computation applies to Assembly
                updatedItem = {
                    ...itemRow,
                    qty: parseInt(value),
                    totalCost: parseFloat((itemRow.stEecostRound * parseInt(value)).toFixed(2)),
                    nettEA: "",
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    totalPrice: "",
                    margin: "",
                    actMargin: "",
                    frcMargin: "",
                    lcMargin: "",
                    dcOnLp: "",
                };
                itemList[itemIndex] = updatedItem;
                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItem });
                //calculateLabourtotal(updatedItem, itemRowAssembyHdr, AssemblyIndex);
                computeAssemblytotalByGroup(itemList);

                triggerComputeGrpTotals(
                    [itemRowAssembyHdr],
                    itemList,
                    "update",
                    itemRow.groupId, //newItems[0].groupId,
                    itemRow.subGroupId, //sbgrpId,
                    dbExtraCharge
                );
                setLabourChanges(!labourChanges);
            } else {
                //Manoj - started coding here on 18/10/2023
                const prevNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
                let newNettEa = 0.0;
                let oItemNettEA = 0.0;
                let levelPricing = {
                    "l60": "",
                    "l55": "",
                    "l50": "",
                    "l20": ""
                }

                if (itemRow.rowType !== "G" && itemRow.rowType !== "SG") {
                    const oItem = originalItems[id]?.items?.filter((i) => i.stStockCode === itemRow.stStockCode)[0];
                    oItemNettEA = oItem?.nettEA;
                } else {
                    oItemNettEA = itemRow.nettEA;
                }

                const originalItem = originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode);
                const originalMargin = originalItem ? originalItem.margin : null;
                const originalNettEA = originalItem ? originalItem.nettEA : null;


                let hasNettEAChanged = ![itemRow].some((f)=> 
                    parseFloat(f.listPrice).toFixed(2) === parseFloat(f.nettEA).toFixed(2) 
                    || parseFloat(f.l20).toFixed(2) === parseFloat(f.nettEA).toFixed(2) 
                    || parseFloat(f.l50).toFixed(2) === parseFloat(f.nettEA).toFixed(2) 
                    || parseFloat(f.l55).toFixed(2) === parseFloat(f.nettEA).toFixed(2) 
                    || parseFloat(f.l60).toFixed(2) === parseFloat(f.nettEA).toFixed(2));  //originalNettEA !== itemRow.nettEA;
                let hasMarginChanged = originalMargin !== itemRow.margin;
                const isACustomItem = itemRow.rowType === "CI";
                let hasRepDiscChanged = false;

                if (itemRow.rowType !== "G" && itemRow.rowType !== "SG") {
                    try {
                        hasRepDiscChanged = originalItems[id]
                            ? originalItems[id]?.items?.find((i) => i.stStockCode === itemRow.stStockCode).repDisc !==
                            itemRow.repDisc
                            : originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)
                                .repDisc !== itemRow.repDisc;
                    } catch (error) {
                        console.log("error", error);
                    }

                }

                if (itemRow.displayGroupName === "" && itemRow.displaySubGroupName === "" && itemRow.rowType !== "AI" && itemRow.rowType !== "CI") {
                    //// itemRow.rowType !== "AI" added by john 10/26/2023 - skip the item for assembly
                    //to allow updating the qty in group/sub group rows
                    let val = await getResponse("Stocks/qtybreaknettea", {
                        CustomerId: itemRow.customerId,
                        partno: itemRow.stStockCode,
                        qty: updatedQty,
                    });

                    const itemIdxAvl = nettEAChangedIndexs.find((i) => i === itemIndex);


                    if (val && val.length > 0) {
                        //check if any of the level pricing is equal to the current nettEA
                        // const nettEANotChanged = parseFloat(itemRow.nettEA) === parseFloat(val[0].data); 
                        const currentLvlPricing = (itemRow.l60 === itemRow.nettEA || itemRow.l55 === itemRow.nettEA
                            || itemRow.l50 === itemRow.nettEA || itemRow.l20 === itemRow.nettEA);


                        if (hasNettEAChanged && !currentLvlPricing) {
                            newNettEa = itemRow.nettEA;
                            oItemNettEA = parseFloat(parseFloat(val[0].data).toFixed(2));
                        } else if (val[0].level === "0") { // indicates that this is stockpricing only
                            newNettEa = parseFloat(parseFloat(val[0].data).toFixed(2))
                            hasNettEAChanged = false;
                            hasMarginChanged = false;
                        } else {
                            newNettEa = parseFloat(parseFloat(val[0].data).toFixed(2)); //get always the first level as priority.
                            hasNettEAChanged = false;
                            hasMarginChanged = false;
                        }
                        for (var l = 0; l < val.length; l++) {
                            if (val[l].level === "L60") levelPricing.l60 = val[l].data;
                            if (val[l].level === "L55") levelPricing.l55 = val[l].data;
                            if (val[l].level === "L50") levelPricing.l50 = val[l].data;
                            if (val[l].level === "L20") levelPricing.l20 = val[l].data;
                        }

                    } else if ((hasMarginChanged || hasNettEAChanged || hasRepDiscChanged)) {
                        newNettEa = itemRow.nettEA;
                    } else if (itemIdxAvl !== undefined) {
                        newNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
                    }



                    // if (itemIdxAvl !== undefined) {
                    //     newNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
                    // }
                    // if (hasMarginChanged || hasNettEAChanged || hasRepDiscChanged) {
                    //     newNettEa = itemRow.nettEA;
                    // }
                    // if (val && val.statusCode === true) {
                    //     newNettEa = parseFloat(parseFloat(val.data).toFixed(2));
                    // }
                } else {
                    newNettEa = itemRow.nettEA;
                }

                //Manoj - ended coding here on 18/10/2023

                if (
                    (rowQtyData && !rowQtyData[id]) ||
                    (rowQtyData && rowQtyData[id] && !rowQtyData[id][itemRow.rowId])
                ) {
                    updateQtyRowForId(id, { [itemRow.rowId]: itemRow });

                    const updatedNettEa = newNettEa > 0
                                ? parseFloat(parseFloat(newNettEa).toFixed(2))
                                : parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
                    const updatedDcOnLp = parseFloat(parseFloat(calculateItemDcOnLp(updatedNettEa, itemRow.listPrice)).toFixed(1));

                    updatedItem = {
                        ...itemRow,
                        //manoj added on 18/10/2021
                        nettEA: updatedNettEa,
                        l60: levelPricing.l60 = "" ? itemRow.l60 : levelPricing.l60,
                        l55: levelPricing.l55 = "" ? itemRow.l55 : levelPricing.l55,
                        l50: levelPricing.l50 = "" ? itemRow.l50 : levelPricing.l50,
                        l20: levelPricing.l20 = "" ? itemRow.l20 : levelPricing.l20,
                        //end
                        qty: updatedQty,
                        totalPrice: parseFloat(updatedQty) === 0 || itemRow.rowType === ''
                            ? 0 : calculateItemTotalPrice(updatedQty, newNettEa),
                        totalCost: parseFloat(updatedQty) === 0 || itemRow.rowType === ''
                            ? 0 : calculateItemTotalCost(updatedQty, itemRow?.stEecost),
                        margin: parseFloat(parseFloat(itemRow?.margin).toFixed(1)),
                        actMargin: parseFloat(parseFloat(itemRow?.actMargin).toFixed(1)),
                        frcMargin: parseFloat(parseFloat(itemRow?.frcMargin).toFixed(1)),
                        lcMargin: parseFloat(parseFloat(itemRow?.lcMargin).toFixed(1)),
                        // dcOnLp: parseFloat(parseFloat(itemRow?.dcOnLp).toFixed(1)),
                        dcOnLp: updatedDcOnLp,
                        repDisc: !isACustomItem && (hasMarginChanged || hasNettEAChanged)
                            ? calculateItemRepDisc(oItemNettEA, newNettEa) > 0
                                ? calculateItemRepDisc(oItemNettEA, newNettEa)
                                : 0.0
                            : itemRow.repDisc,
                    };
                } else {
                    const qtyRow = rowQtyData[id][itemRow.rowId];

                    const updatedNettEa = newNettEa > 0
                                ? parseFloat(parseFloat(newNettEa).toFixed(2))
                                : parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
                    
                    const updatedDcOnLp = parseFloat(parseFloat(calculateItemDcOnLp(updatedNettEa, itemRow.listPrice)).toFixed(1));

                    updatedItem = {
                        ...qtyRow,
                        //manoj added on 18/10/2021
                        nettEA:
                            newNettEa > 0
                                ? parseFloat(parseFloat(newNettEa).toFixed(2))
                                : parseFloat(parseFloat(qtyRow?.nettEA).toFixed(2)),

                        l60: levelPricing.l60 = "" ? itemRow.l60 : levelPricing.l60,
                        l55: levelPricing.l55 = "" ? itemRow.l55 : levelPricing.l55,
                        l50: levelPricing.l50 = "" ? itemRow.l50 : levelPricing.l50,
                        l20: levelPricing.l20 = "" ? itemRow.l20 : levelPricing.l20,
                        // end
                        qty: updatedQty,
                        totalPrice: itemRow.rowType === '' ? 0 : calculateItemTotalPrice(updatedQty, newNettEa),
                        totalCost: itemRow.rowType === '' ? 0 : calculateItemTotalCost(updatedQty, qtyRow?.stEecost),
                        margin: parseFloat(parseFloat(itemRow?.margin ? itemRow?.margin : qtyRow.margin).toFixed(1)),
                        actMargin: parseFloat(parseFloat(qtyRow?.actMargin).toFixed(1)),
                        frcMargin: parseFloat(parseFloat(qtyRow?.frcMargin).toFixed(1)),
                        lcMargin: parseFloat(parseFloat(qtyRow?.lcMargin).toFixed(1)),
                        dcOnLp: updatedDcOnLp,
                        // repDisc:
                        //     (hasMarginChanged || hasNettEAChanged) && !isACustomItem
                        //         ? calculateItemRepDisc(oItemNettEA, newNettEa) > 0
                        //             ? calculateItemRepDisc(oItemNettEA, newNettEa)
                        //             : 0.0
                        //         : itemRow.repDisc
                        //         ? itemRow.repDisc
                        //         : 0.0,
                        repDisc: !isACustomItem && (hasMarginChanged || hasNettEAChanged)
                            ? calculateItemRepDisc(oItemNettEA, newNettEa) > 0
                                ? calculateItemRepDisc(oItemNettEA, newNettEa)
                                : 0.0
                            : itemRow.repDisc,
                        labCost: "",
                        //Start - manoj commented this on 18/10/2021 to add the 4302
                        //nettEA: itemRow?.nettEA ? itemRow?.nettEA : 0.0,
                        //Start - manoj commented this on 18/10/2021
                    };
                }
                itemList[itemIndex] = updatedItem;
                // updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...itemList] });
                triggerComputeGrpTotals(
                    [updatedItem],
                    itemList,
                    "update",
                    itemRow.groupId, //newItems[0].groupId,
                    itemRow.subGroupId, //sbgrpId,
                    dbExtraCharge
                );
            }
            //added by sachini - when changing qty, last empty row total cost and total price does not need to view
            const updatedItemListArray = itemList.map((item) => {
                return {
                    ...item,
                    totalCost: item.rowType === '' ? '' : item.totalCost,
                    totalPrice: item.rowType === '' ? '' : item.totalPrice
                }
            });
            //#region the checking of type !=="AI" was added to skip running for Assembly because the computation where already triggered by the function "triggerComputeGrpTotals". Perhaps this will also need to migrate to that function other process per column will also need to migrate specially with computation.
            let updatedItemList = [...updatedItemListArray];
            if (dataItem.rowType !== "AI") {
                if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                    if (updatingItemSubGroup && !isASubGroupRow) {
                        // let updatedItemList = [...itemList];

                        //sub group level db extra calculation
                        const subGroupDbExtra = isDbExtraAdded(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            GroupTypes.SUB_GROUP
                        );

                        if (subGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                                updatedItemList,
                                updatingItemGroup,
                                updatingItemSubGroup,
                                dbExtraCharge,
                                GroupTypes.SUB_GROUP
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }

                        let subGroupItems = getAllExistingSubGroupedItems(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );
                        subGroupItems = getQtyBasedValues(subGroupItems);

                        const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                        const subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                        const subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        const subGroupstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        const subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        const subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

                        // remove db extra for margin calculations
                        subGroupItems = getItemsWithoutAIandDX(subGroupItems);
                        const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupstEecost
                        );
                        const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupStFutureCost
                        );
                        const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupActCostExact
                        );

                        // old implementation
                        // const subGroupNettEA = calculateNettEAForQtyChange(subGroupItems);
                        // const subGroupListPrice = calculateListPriceForQtyChange(subGroupItems);
                        // const subGroupstEecost = calculatestEecostForQtyChange(subGroupItems);
                        // const subGroupStFutureCost = calculateStFutureCostForQtyChange(subGroupItems);
                        // const subGroupActCostExact = calculateActCostExactForQtyChange(subGroupItems);
                        // const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                        //     subGroupNettEA,
                        //     subGroupstEecost
                        // );
                        // const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        //     subGroupNettEA,
                        //     subGroupStFutureCost
                        // );
                        // const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        //     subGroupNettEA,
                        //     subGroupActCostExact
                        // );

                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        )[0];
                        const subGroupIndex = getSubGroupRowIndex(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );

                        const updatedSubGroup = {
                            ...subGroupRow,
                            qty: subGroupRow.qty,
                            totalPrice: calculateItemTotalPrice(subGroupRow.qty, subGroupTotalPrice),
                            margin: subGroupMargin,
                            frcMargin: subGroupFrcMargin,
                            actMargin: subGroupActMargin,
                            totalCost: calculateItemTotalCost(subGroupRow.qty, subGroupstEecost),
                            nettEA: subGroupNettEA,
                            listPrice: subGroupListPrice,
                            stFutureCost: subGroupStFutureCost,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            stEecost: subGroupstEecost,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            dcOnLp: subGroupDcOnLp,
                        };

                        updatedItemList[subGroupIndex] = updatedSubGroup;

                        //group level db extra calculation
                        const groupDbExtra = isDbExtraAdded(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            GroupTypes.GROUP
                        );

                        if (groupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                                updatedItemList,
                                updatingItemGroup,
                                null,
                                dbExtraCharge,
                                GroupTypes.GROUP
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
                        let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        let subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        subGroupRowList = getQtyBasedValues(subGroupRowList);

                        let combinedList = [...groupRowItems, ...subGroupRowList];

                        const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
                        const groupListPrice = calculateGroupWiseTotal(combinedList, "listPrice");
                        const groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
                        const groupstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
                        const groupActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
                        const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

                        // remove db extra for margin calculations
                        groupRowItems = getItemsWithoutAIandDX(groupRowItems);
                        subGroupRowList = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);

                        combinedList = [...groupRowItems, ...subGroupRowList];

                        const groupNettEANonDX = calculateGroupWiseTotal(combinedList, "nettEA");
                        const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );
                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );

                        // old implementation
                        // const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
                        // const groupTotalCost = calculateGroupWiseTotal(combinedList, "totalCost");
                        // const groupNettEA = calculateNettEAForQtyChange(combinedList);
                        // const groupListPrice = calculateListPriceForQtyChange(combinedList);
                        // const groupstEecost = calculatestEecostForQtyChange(combinedList);
                        // const groupStFutureCost = calculateStFutureCostForQtyChange(combinedList);
                        // const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
                        // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        //     groupNettEA,
                        //     groupStFutureCost
                        // );
                        // const groupActCostExact = calculateActCostExactForQtyChange(combinedList);
                        // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        //     groupNettEA,
                        //     groupActCostExact
                        // );

                        const updatedGroupRow = {
                            ...groupRow,
                            totalPrice: calculateValuesOnSBLQtyChange(groupRow.qty, groupTotalPrice),
                            nettEA: groupNettEA,
                            dcOnLp: groupDcOnLp,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            qty: groupRow.qty,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            listPrice: groupListPrice,
                            stEecost: groupstEecost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        // setItemList(updatedItemList);
                        // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                        // const totalPrice = calculateTotalPrice(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalItemPrice(totalPrice);

                        // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                        // setTotalCost(totalCost);

                        // const totalneeta = calculateTotalNettEA(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalNettEA(totalneeta);

                        // const totalstEecostRound = calculateTotalstEecostRound(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalstEecostRound(totalstEecostRound);

                        // const totalStFutureCost = calculateTotalStFutureCost(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalStFutureCost(totalStFutureCost);

                        // const totalActCostEa = calculateTotalActCostEAExact(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalActCostEAExact(totalActCostEa);

                        // const totalListPrice = calculateTotalListPrice(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalListPrice(totalListPrice);
                    } else if (updatingItemSubGroup && isASubGroupRow) {
                        const subGroupRow = updatedItem;
                        // let updatedItemList = [...itemList];
                        let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
                        let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        const subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                        //sub group level calculations
                        const sGItems = getAllExistingSubGroupedItems(
                            itemList,
                            updatingItemGroup,
                            subGroupRow.subGroupId
                        );
                        const subGroupTotalPrice = calculateGroupWiseTotal(sGItems, "totalPrice");
                        const subGroupTotalCost = calculateGroupWiseTotal(sGItems, "totalCost");
                        const subGroupstEecost = calculateGroupWiseTotal(sGItems, "stEecost");
                        const subGroupNettEA = calculateNettEAForQtyChange(sGItems);

                        const updatedSubGroupRow = {
                            ...subGroupRow,
                            nettEA: subGroupNettEA,
                            totalPrice: calculateValuesOnSBLQtyChange(subGroupRow.qty, subGroupTotalPrice),
                            // totalCost: calculateValuesOnSBLQtyChange(
                            //     subGroupRow.qty,
                            //     calculateItemTotalCost(subGroupRow.qty, subGroupstEecost)
                            // ),
                            totalCost: calculateItemTotalCost(subGroupRow.qty, subGroupstEecost),
                            lcMargin: 0.0,
                        };

                        updatedItemList[itemIndex] = updatedSubGroupRow;

                        //group level calculations
                        let groupTotalPrice = -1;
                        let groupTotalCost = -1;
                        let groupNettEA = -1;
                        let groupActCostExact = -1;
                        let groupstEecost = -1;
                        let groupStFutureCost = -1;
                        let groupListPrice = -1;
                        let updatedGroupRow = null;

                        if (subGroupRowList.length > 0) {
                            const subGroupsWithoutCurrent = subGroupRowList.filter(
                                (item) => item.subGroupId !== itemRow.subGroupId
                            );

                            let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                                if (item.qty > 1) {
                                    return {
                                        ...item,
                                        listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                        actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                        nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                        stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                        stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                        actCost: parseFloat(
                                            parseFloat(
                                                calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)
                                            ).toFixed(2)
                                        ),
                                        stEecostRound: parseFloat(
                                            parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(
                                                2
                                            )
                                        ),
                                        stFutureCostRound: parseFloat(
                                            parseFloat(
                                                calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)
                                            ).toFixed(2)
                                        ),
                                        //   lcMargin: "",
                                    };
                                }

                                return item;
                            });

                            //check for dbExtra here
                            const groupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                updatingItemGroup,
                                null,
                                GroupTypes.GROUP
                            );

                            if (groupDbExtra) {
                                const dbExtraRow = getExistingGroupWiseDbExtraRow(
                                    updatedItemList,
                                    updatingItemGroup
                                )[0];
                                const dbExtraRowIndex = getDbExtraRowIndex(
                                    updatedItemList,
                                    updatingItemGroup,
                                    null,
                                    GroupTypes.GROUP
                                );

                                const gRItms = getGroupedRowItemsWithoutDBExtra(updatedItemList, updatingItemGroup);
                                const sgRws = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                                const updatedSubGroupRows = sgRws.map((subGroupRow) => {
                                    const sbId = subGroupRow.subGroupId;
                                    const sGRowItems = getAllExistingSubGroupedItems(
                                        updatedItemList,
                                        updatingItemGroup,
                                        sbId
                                    );

                                    const itemsWithoutDbExtra = getSubGroupItemsWithoutDBExtra(
                                        sGRowItems,
                                        updatingItemGroup,
                                        sbId
                                    );
                                    const nettEA = calculateGroupWiseTotal(itemsWithoutDbExtra, "nettEA");

                                    return {
                                        ...subGroupRow,
                                        nettEA: nettEA * parseInt(subGroupRow.qty),
                                    };
                                });

                                const groupItems = [...gRItms, ...updatedSubGroupRows];
                                const nettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                                const dbExtraValue = calculateDbExtra(dbExtraCharge, nettEA);

                                const updatedDbExtraRow = {
                                    ...dbExtraRow,
                                    nettEA: dbExtraValue,
                                    totalPrice: dbExtraValue,
                                };

                                updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                            }

                            let combinedList = [];
                            let hasQtyChangedInGroup = groupRow.qty > 1;

                            groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);

                            combinedList = [...groupRowItems, ...updatedSubGroupsWithoutCurrent];

                            let gTprice = calculateGroupWiseTotal(combinedList, "totalPrice");
                            let gTcost = calculateGroupWiseTotal(combinedList, "totalCost");
                            let gNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
                            let gActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
                            let gstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
                            let gStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
                            let gListPrice = calculateGroupWiseTotal(combinedList, "listPrice");

                            hasQtyChangedInGroup
                                ? (updatedGroupRow = {
                                    ...groupRow,
                                    totalPrice: parseFloat(
                                        ((gTprice + subGroupRow.totalPrice) * groupRow.qty).toFixed(2)
                                    ),
                                    totalCost: parseFloat((groupRow.stEecost * groupRow.qty).toFixed(2)),

                                    //commented by sachini 20/03/2024 when changing subgroup level qty, group level cost is incorrect
                                    //   totalCost:
                                    //   (calculateItemTotalCost(groupRow.qty, gstEecost) + subGroupRow.totalCost) *
                                    //   groupRow.qty,

                                    nettEA: parseFloat((gTprice + subGroupRow.nettEA * updatedQty).toFixed(2)),  //changed by sachini -when changing subgroup qty, group level nettea incorrect
                                    actCostExact: gActCostExact + subGroupRow.actCostExact * updatedQty,
                                    stEecost: gstEecost + subGroupRow.stEecost * updatedQty,
                                    stFutureCost: gStFutureCost + subGroupRow.stFutureCost * updatedQty,
                                    listPrice: parseFloat(
                                        (gListPrice + subGroupRow.listPrice * updatedQty).toFixed(2)
                                    ),
                                    actCost: parseFloat(
                                        (gActCostExact + subGroupRow.actCostExact * updatedQty).toFixed(2)
                                    ),
                                    stEecostRound: parseFloat(
                                        (gstEecost + subGroupRow.stEecost * updatedQty).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        (gStFutureCost + subGroupRow.stFutureCost * updatedQty).toFixed(2)
                                    ),
                                })
                                : (updatedGroupRow = {
                                    ...groupRow,
                                    totalPrice: parseFloat((gTprice + subGroupRow.totalPrice).toFixed(2)),
                                    //   totalCost: gTcost + subGroupRow.totalCost,
                                    //  totalCost: calculateItemTotalCost(updatedQty, gstEecost) + subGroupRow.totalCost,
                                    totalCost: parseFloat((groupRow.stEecost * groupRow.qty).toFixed(2)),
                                    nettEA: parseFloat((gNettEA + subGroupRow.nettEA * updatedQty).toFixed(2)),
                                    actCostExact: gActCostExact + subGroupRow.actCostExact * updatedQty,
                                    stEecost: gstEecost + subGroupRow.stEecost * updatedQty,
                                    stFutureCost: gStFutureCost + subGroupRow.stFutureCost * updatedQty,
                                    listPrice: parseFloat(
                                        (gListPrice + subGroupRow.listPrice * updatedQty).toFixed(2)
                                    ),
                                    actCost: parseFloat(
                                        (gActCostExact + subGroupRow.actCostExact * updatedQty).toFixed(2)
                                    ),
                                    stEecostRound: parseFloat(
                                        (gstEecost + subGroupRow.stEecost * updatedQty).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        (gStFutureCost + subGroupRow.stFutureCost * updatedQty).toFixed(2)
                                    ),
                                });

                            updatedItemList[groupRowIndex] = updatedGroupRow;

                            // non group db extra calculation
                            const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                            if (nonGroupItems.length > 0) {
                                const nonGroupId = nonGroupItems[0].groupId;
                                const nonGroupDbExtra = isDbExtraAdded(
                                    updatedItemList,
                                    nonGroupId,
                                    null,
                                    GroupTypes.NON_GROUP
                                );

                                if (nonGroupDbExtra) {
                                    const { dbExtraRow, dbExtraRowIndex } = getQtyBasedNonGroupDbExtraRow(
                                        updatedItemList,
                                        nonGroupId,
                                        dbExtraCharge
                                    );
                                    updatedItemList[dbExtraRowIndex] = dbExtraRow;
                                }
                            }

                            // setItemList(updatedItemList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                        } else {
                            // let updatedItemList = [...itemList];

                            const subGroupRow = getSubGroupRowByGroupAndSubGroup(
                                updatedItemList,
                                updatingItemGroup,
                                updatingItemSubGroup
                            )[0];

                            const subGroupsWithoutCurrent = subGroupRowList.filter(
                                (item) => item.groupId === updatingItemGroup && item.subGroupId !== updatingItemSubGroup
                            );

                            let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                                if (item.qty > 1) {
                                    return {
                                        ...item,
                                        listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                        actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                        nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                        stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                        stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                        // totalPrice: calculateValuesOnSBLQtyChange(item.qty, item.totalPrice),
                                        // totalCost: calculateValuesOnSBLQtyChange(item.qty, item.totalCost),
                                        actCost: parseFloat(
                                            parseFloat(
                                                calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)
                                            ).toFixed(2)
                                        ),
                                        stEecostRound: parseFloat(
                                            parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(
                                                2
                                            )
                                        ),
                                        stFutureCostRound: parseFloat(
                                            parseFloat(
                                                calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)
                                            ).toFixed(2)
                                        ),
                                        //    lcMargin: "",
                                    };
                                }

                                return item;
                            });

                            const combinedList = [...groupRowItems, ...updatedSubGroupsWithoutCurrent];

                            groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
                            groupTotalCost = calculateGroupWiseTotal(combinedList, "totalCost");
                            groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
                            groupActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
                            groupstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
                            groupStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
                            groupListPrice = calculateGroupWiseTotal(combinedList, "listPrice");

                            updatedGroupRow = {
                                ...groupRow,
                                listPrice: parseFloat((groupListPrice + subGroupRow.listPrice * updatedQty).toFixed(2)),
                                actCostExact: groupActCostExact + subGroupRow.actCostExact * updatedQty,
                                stEecost: groupstEecost + subGroupRow.stEecost * updatedQty,
                                stEecostRound: parseFloat(
                                    (groupstEecost + subGroupRow.stEecost * updatedQty).toFixed(2)
                                ),
                                stFutureCost: groupStFutureCost + subGroupRow.stFutureCost * updatedQty,
                                stFutureCostRound: parseFloat(
                                    (groupStFutureCost + subGroupRow.stFutureCost * updatedQty).toFixed(2)
                                ),
                                actCost: parseFloat(
                                    (groupActCostExact + subGroupRow.actCostExact * updatedQty).toFixed(2)
                                ),
                                nettEA: parseFloat((groupNettEA + subGroupRow.nettEA * updatedQty).toFixed(2)),
                                totalPrice: parseFloat((groupTotalPrice + subGroupRow.totalPrice).toFixed(2)),
                                // totalCost: parseFloat((groupTotalCost + subGroupRow.totalCost).toFixed(2)),
                                totalCost: parseFloat(
                                    (calculateItemTotalCost(updatedQty, groupstEecost) + subGroupRow.totalCost).toFixed(
                                        2
                                    )
                                ),
                                //  lcMargin: "",
                            };

                            updatedItemList[groupRowIndex] = updatedGroupRow;

                            // non group db extra calculation
                            const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                            if (nonGroupItems.length > 0) {
                                const nonGroupId = nonGroupItems[0].groupId;
                                const nonGroupDbExtra = isDbExtraAdded(
                                    updatedItemList,
                                    nonGroupId,
                                    null,
                                    GroupTypes.NON_GROUP
                                );

                                if (nonGroupDbExtra) {
                                    const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                        updatedItemList,
                                        dbExtraCharge
                                    );
                                    updatedItemList[dbExtraRowIndex] = dbExtraRow;
                                }
                            }

                            // footer calculation
                            // const {
                            //     totalPrice,
                            //     totalSteEeCostRound,
                            //     totalStFutureCost,
                            //     totalActCostExact,
                            //     totalListPrice,
                            //     totalNettEA,
                            //     totalCost,
                            //     totalMargin,
                            //     totalActMargin,
                            //     totalFrcMargin,
                            //     totalDcOnLp,
                            // } = footerCalculation(updatedItemList);

                            // setTotalItemPrice(totalPrice);
                            // setTotalstEecostRound(totalSteEeCostRound);
                            // setTotalStFutureCost(totalStFutureCost);
                            // setTotalActCostEAExact(totalActCostExact);
                            // setTotalListPrice(totalListPrice);
                            // setTotalCost(totalCost);
                            // setTotalNettEA(totalNettEA);
                            // setMaxMargin(totalMargin);
                            // setActMargin(totalActMargin);
                            // setMaxFrcMargin(totalFrcMargin);
                            // setFooterDisc(totalDcOnLp);

                            // setItemList(updatedItemList);
                            // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                        }
                    } else {
                        // let updatedItemList = [...itemList];

                        //group level db extra calculation
                        const groupDbExtra = isDbExtraAdded(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            GroupTypes.GROUP
                        );

                        if (groupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                                updatedItemList,
                                updatingItemGroup,
                                null,
                                dbExtraCharge,
                                GroupTypes.GROUP
                            );

                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }

                        const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
                        let groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        groupRowList = getQtyBasedValues(groupRowList);
                        subGroupRows = getQtyBasedValues(subGroupRows);

                        let groupItems = [...groupRowList, ...subGroupRows];

                        const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        const groupTotalCost = calculateGroupWiseTotal(groupItems, "totalCost");
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                        const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                        const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);

                        //remove db extra for margin related calculations
                        const groupItemsNonDX = getItemsWithoutAIandDX(groupRowList);
                        const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRows, updatedItemList);

                        groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );
                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );

                        // const groupNettEA = calculateNettEAForQtyChange(groupItems);
                        // const groupListPrice = calculateListPriceForQtyChange(groupItems);
                        // const groupstEecost = calculatestEecostForQtyChange(groupItems);
                        // const groupStFutureCost = calculateStFutureCostForQtyChange(groupItems);
                        // const groupActCostExact = calculateActCostExactForQtyChange(groupItems);
                        // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                        // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                        // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        //     groupNettEA,
                        //     groupStFutureCost
                        // );
                        // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        //     groupNettEA,
                        //     groupActCostExact
                        // );

                        const updatedGroupRow = {
                            ...groupRow,
                            totalPrice: groupTotalPrice * groupRow.qty,
                            nettEA: groupNettEA,
                            listPrice: groupListPrice,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            qty: groupRow?.qty,
                            margin: groupMargin,
                            frcMargin: groupFrcMargin,
                            actMargin: groupActMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            actCostExact: groupActCostExact,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            dcOnLp: groupDcOnLp,
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(
                                updatedItemList,
                                nonGroupId,
                                null,
                                GroupTypes.NON_GROUP
                            );

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                    updatedItemList,
                                    dbExtraCharge
                                );
                                updatedItemList[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        // const totalPrice = calculateTotalPrice(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalItemPrice(totalPrice);

                        // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                        // setTotalCost(totalCost);

                        // const totalneeta = calculateTotalNettEA(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalNettEA(totalneeta);

                        // const totalstEecostRound = calculateTotalstEecostRound(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalstEecostRound(totalstEecostRound);

                        // const totalActCostEa = calculateTotalActCostEAExact(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalActCostEAExact(totalActCostEa);

                        // const totalStFutureCost = calculateTotalStFutureCost(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalStFutureCost(totalStFutureCost);

                        // const totalListPrice = calculateTotalListPrice(
                        //     updatedItemList.filter((item) => item.stStockCode != " ")
                        // );
                        // setTotalListPrice(totalListPrice);

                        // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(updatedItemList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEA);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);

                        // setItemList(updatedItemList);
                        // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    }
                } else if (
                    updatingItemGroup &&
                    updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS &&
                    isAGroupRow
                ) {
                    // let updatedItemList = [...itemList];
                    const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    let updatedGroupRowItems = groupRowItems.map((item) => {
                        if (item.qty > 1) {
                            return {
                                ...item,
                                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                actCost: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)
                                ),
                                stEecostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                ),
                                stFutureCostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(2)
                                ),
                            };
                        }
                        return item;
                    });

                    const subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    let updatedSubGroups = subGroupRowList.map((item) => {
                        if (item.qty > 1) {
                            return {
                                ...item,
                                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                actCost: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)
                                ),
                                stEecostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                ),
                                stFutureCostRound: parseFloat(
                                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(2)
                                ),
                                //   lcMargin: "",
                            };
                        }

                        return item;
                    });

                    const groupItems = [...updatedGroupRowItems, ...updatedSubGroups];

                    const listPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const stEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const stFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const actCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const nettEA = calculateGroupWiseTotal(groupItems, "nettEA");

                    const updatedGroupRow = {
                        ...updatedItem,
                        // totalPrice : "already updated at the top level",
                        // totalCost : "already updated at the top level",
                        listPrice: listPrice,
                        nettEA: parseFloat(nettEA.toFixed(2)),
                        actCostExact: actCostExact,
                        stEecost: stEecost,
                        stEecostRound: parseFloat(stEecost.toFixed(2)),
                        stFutureCost: stFutureCost,
                        stFutureCostRound: parseFloat(stFutureCost.toFixed(2)),
                        actCost: parseFloat(actCostExact.toFixed(2)),
                        totalCost: calculateItemTotalCost(updatedItem.qty, stEecost),
                        //   lcMargin: "",
                    };

                    updatedItemList[itemIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getQtyBasedNonGroupDbExtraRow(
                                updatedItemList,
                                nonGroupId,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    // setItemList(updatedItemList);
                    // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                    // const totalPrice = calculateTotalPrice(updatedItemList, 1, updatedQty);
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList, 1, updatedQty);
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList);
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(updatedItemList);
                    // setTotalListPrice(totalListPrice);
                } else {
                    // let updatedItemList = [...itemList];

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    const uItem = {
                        ...updatedItem,
                        margin: calculateItemMargin(updatedItem.nettEA, updatedItem.stEecost),
                        actMargin: calculateItemMargin(updatedItem.nettEA, updatedItem.actCostExact),
                        frcMargin: calculateItemMargin(updatedItem.nettEA, updatedItem.stFutureCost),
                        lcMargin: calculateItemMargin(updatedItem.nettEA, updatedItem.lastCostExact),
                    };

                    updatedItemList[itemIndex] = uItem;
                    updateItemListForId(id, {
                        items: [...updatedItemList.filter(f => f.rowType !== "G" && f.rowType !== "SG"
                            && f.rowType !== "A" && f.rowType !== "AI" && f.rowType !== "DX" && f.rowType !== "LN" && f.rowType !== "")]
                    });
                    // updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    // setItemList(updatedItemList);

                    // const totalPrice = calculateTotalPrice(updatedItemList);
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList, null, updatedQty);
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList);
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(updatedItemList);
                    // setTotalListPrice(totalListPrice);
                }

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setComputeTotals(true);
                // footer calculation //commented by sachini 20/03/2024 add setComputeTotals(true)
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEA);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
            }
        } else if (field === "nettEA") {
            setIsNettEAChanged(false);
            const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = itemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
            let updatedNettEA = -1;
            let newRepDisc = -1;
            let updatedItem = null;
            let nettEAGrpLvlChanged = false;


            if (itemRow.stStatus === "X" && itemRow.stEecostRound === 0) {
                itemList[itemIndex] = itemRow;
                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });
                toast.error("CostEA should be added before NettEA");
                return;
            }

            if (!isNaN(parseFloat(value))) {
                updatedNettEA = parseFloat(value);
                // if (updatedNettEA === parseFloat(itemRow.nettEA)) return; /// not to changed.
                setNettEAChangedIndexs([...nettEAChangedIndexs, itemIndex]);
                setIsNettEAChanged(true);
            } else {
                updatedNettEA = itemRow.nettEA;
                toast.error("Please enter a valid number");
                return false;
            }

            if (dataItem.rowType === "A") {
                ///Computation applies to Assembly
                updatedItem = {
                    ...itemRow,
                    nettEA: parseFloat(value).toFixed(2),
                    totalPrice: parseFloat(value * itemRow.qty).toFixed(2),
                    margin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                    actMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                    frcMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                };
                itemList[itemIndex] = updatedItem;
                setItemList(itemList);
            } else {
                let originalItemNettEA = 0;
                const originalItemList = originalItems[id];

                // if (originalItemList) {
                //     // originalItemNettEA = originalItems[id]?.items?.find(
                //     //     (i) => i.stStockCode === itemRow.stStockCode
                //     // )?.nettEA;
                //     originalItemNettEA = originalItems[id]?.items?.find(
                //         (i) => i.stStockCode === itemRow.stStockCode && i.qty === itemRow.qty
                //     )?.nettEA
                // } else {
                //     originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
                //         (i) => i.stStockCode === itemRow.stStockCode
                //     )?.nettEA;
                // }

                // if (rowNettEaData && rowNettEaData[id] && rowNettEaData[id][itemRow.rowId]) {
                //     // newRepDisc = calculateItemRepDisc(rowNettEaData[id][itemRow.rowId]?.nettEA, updatedNettEA);
                //     newRepDisc = calculateItemRepDisc(originalItemNettEA, updatedNettEA);
                // } else {
                //     // newRepDisc = calculateItemRepDisc(itemRow?.nettEA, updatedNettEA); --> should always be the original value, therefore will not affect
                //     newRepDisc = calculateItemRepDisc(originalItemNettEA, updatedNettEA);
                //     updateNettEaRowForId(id, { [itemRow.rowId]: itemRow });
                // }

                //#region maintain the checking of level pricing to check the original nettEA
                let orignettEA = getOrgiNettEA(itemRow);
                newRepDisc = calculateItemRepDisc(orignettEA, updatedNettEA);
                //#endregion

                if (isASubGroupRow) {
                    updateSGNettEAStatusForId(id, { [itemRow.rowId]: { hasNettEAChanged: true } });
                }

                const newDcOnLp = calculateItemDcOnLp(updatedNettEA, itemRow.listPrice);

                updatedItem = {
                    ...itemRow,
                    nettEA: value == "0" ? value : updatedNettEA,
                    dcOnLp: newDcOnLp,
                    totalPrice: parseFloat((itemRow.qty * updatedNettEA).toFixed(2)),
                    repDisc: newRepDisc > 0 ? newRepDisc : 0,
                    margin: parseFloat(value) === 0.0 ? 0.0 : calculateItemMargin(updatedNettEA, itemRow.stEecost),
                    actMargin:
                        parseFloat(value) === 0.0 ? 0.0 : calculateItemActMargin(updatedNettEA, itemRow.actCostExact),
                    frcMargin:
                        parseFloat(value) === 0.0 ? 0.0 : calculateItemFrcMargin(updatedNettEA, itemRow.stFutureCost),
                    lcMargin:
                        parseFloat(value) === 0.0 ? 0.0 : calculateItemLcMargin(updatedNettEA, itemRow.lastCostExact),
                    //  lcMargin: "",
                };


                itemList[itemIndex] = updatedItem;
                updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...itemList] });
                if (newRepDisc > 0) {
                    checkRepDiscLimit(updatedItem);
                }
            }

            if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                if (updatingItemSubGroup && !isASubGroupRow) {
                    let updatedItemList = [...itemList];
                    //db extra calculation
                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            dbExtraCharge,
                            GroupTypes.SUB_GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    let subGroupRows = getAllExistingSubGroupedItems(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );
                    subGroupRows = getListWithQtyBasedNettEA(subGroupRows);

                    const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
                    const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
                    const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
                    const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
                    const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
                    const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
                    // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                    const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
                    const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                    const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupStFutureCost
                    );
                    const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupActCostExact
                    );

                    const subGroup = getSubGroupRowByGroupAndSubGroup(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    )[0];
                    const subGroupIndex = updatedItemList.indexOf(subGroup);

                    const updatedSubGroup = {
                        ...subGroup,
                        totalPrice: subGroupTotalPrice,
                        nettEA: subGroupNettEA,
                        dcOnLp: subGroupDcOnLp,
                        margin: subGroupMargin,
                        actMargin: subGroupActMargin,
                        frcMargin: subGroupFrcMargin,
                        stFutureCost: subGroupStFutureCost,
                        stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                        actCostExact: subGroupActCostExact,
                        actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        stEecost: subGroupstEecost,
                        stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                        //lcMargin: "",
                    };

                    updatedItemList[subGroupIndex] = updatedSubGroup;

                    // group db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            null,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup);
                    const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
                    const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const uSubGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    const combinedList = [...groupRowList, ...uSubGroupRows];

                    const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
                    const groupListPrice = calculateGroupWiseTotal(combinedList, "listPrice");
                    const groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
                    const groupstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow[0],
                        totalPrice: groupTotalPrice,
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        actCostExact: groupActCostExact,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        // lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    setComputeTotals(true);
                    //#region 
                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);


                    //commented by sachini 20/03/2024
                    // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(updatedItemList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEA);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: updatedItemList[0],
                        });
                    }
                } else if (updatingItemSubGroup && isASubGroupRow) {
                    const updatedItemList = [...itemList];
                    const prevSubGroupRow = itemRow;
                    const subGroupRow = updatedItem;
                    let dbExtraValue = 0;
                    let uNettEA = 0;
                    let pNettEA = 0;

                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, updatingItemSubGroup)[0];
                        const dbExtraRowIndex = getDbExtraRowIndex(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            GroupTypes.SUB_GROUP
                        );
                        // dbExtraValue = dbExtraRow.nettEA;
                        // dbExtraValue = Math.ceil(updatedNettEA / 2);
                        dbExtraValue = calculateDbExtra(dbExtraCharge, updatedNettEA);
                        uNettEA = updatedNettEA - dbExtraValue;
                        pNettEA = prevSubGroupRow.nettEA - dbExtraRow.nettEA;

                        const updatedDbExtraRow = {
                            ...dbExtraRow,
                            nettEA: dbExtraValue,
                            totalPrice: dbExtraValue,
                        };

                        updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                    } else {
                        uNettEA = updatedNettEA;
                        pNettEA = prevSubGroupRow.nettEA;
                    }

                    const subGroupItems = getSubGroupItemsWithoutDBExtra(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );

                    const updatedSubGroupItems = subGroupItems.map((item) => {
                        let propotionalValue = -1;
                        let propotionalNettEa = -1;
                        let uRepDisc = -1;
                        let uMargin = -1;
                        let uFrcMargin = -1;
                        let uDcOnLp = -1;
                        let uTotalPrice = -1;
                        let uActMargin = -1;

                        propotionalValue = calculateProportion(item.nettEA, pNettEA);
                        propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);

                        if (sgList && sgList[id] && sgList[id][updatingItemSubGroup]) {
                            let iList = sgList[id][updatingItemSubGroup];

                            if (subGroupItems.length > iList.length) {
                                iList = [...iList, ...subGroupItems.slice(iList.length, subGroupItems.length)];
                            }

                            const currentItem = iList.filter((i) => i.rowId === item.rowId)[0];
                            // const originalItem = originalItems[id]?.items?.find(
                            //     (i) => i.stStockCode === currentItem.stStockCode
                            // );

                            let originalItemNettEA = 0;
                            const originalItemList = originalItems[id];

                            if (originalItemList) {
                                originalItemNettEA = originalItems[id]?.items?.find(
                                    (i) => i.stStockCode === currentItem.stStockCode
                                )?.nettEA;
                            } else {
                                originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
                                    (i) => i.stStockCode === currentItem.stStockCode
                                )?.nettEA;
                            }

                            if (
                                currentItem.groupDbExtraId === "" &&
                                currentItem.subGroupDbExtraId === "" &&
                                currentItem.nonGroupDbExtraId === ""
                            ) {
                                // uRepDisc = calculateItemRepDisc(currentItem.nettEA, propotionalNettEa);
                                uRepDisc = calculateItemRepDisc(originalItemNettEA, propotionalNettEa);
                                uMargin = calculateItemMargin(propotionalNettEa, currentItem.stEecost);
                                uFrcMargin = calculateItemFrcMargin(propotionalNettEa, currentItem.stFutureCost);
                                uDcOnLp = calculateItemDcOnLp(propotionalNettEa, currentItem.listPrice);
                                uTotalPrice = calculateItemTotalPrice(currentItem.qty, propotionalNettEa);
                                uActMargin = calculateItemMargin(propotionalNettEa, currentItem.actCostExact);
                            } else {
                                uTotalPrice = item.totalPrice;
                                uActMargin = "";
                                uMargin = "";
                                uFrcMargin = "";
                                propotionalNettEa = currentItem.nettEA;
                            }
                        } else {
                            if (
                                item.groupDbExtraId === "" &&
                                item.subGroupDbExtraId === "" &&
                                item.nonGroupDbExtraId === ""
                            ) {
                                // const originalItem = originalItems[id]?.items?.find(
                                //     (i) => i.stStockCode === item.stStockCode
                                // );

                                let originalItemNettEA = 0;
                                const originalItemList = originalItems[id];

                                if (originalItemList) {
                                    originalItemNettEA = originalItems[id]?.items?.find(
                                        (i) => i.stStockCode === item.stStockCode
                                    )?.nettEA;
                                } else {
                                    originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
                                        (i) => i.stStockCode === item.stStockCode
                                    )?.nettEA;
                                }

                                // uRepDisc = calculateItemRepDisc(item.nettEA, propotionalNettEa);
                                uRepDisc = calculateItemRepDisc(originalItemNettEA, propotionalNettEa);
                                uMargin = calculateItemMargin(propotionalNettEa, item.stEecost);
                                uDcOnLp = calculateItemDcOnLp(propotionalNettEa, item.listPrice);
                                uTotalPrice = calculateItemTotalPrice(item.qty, propotionalNettEa);
                                uActMargin = calculateItemMargin(propotionalNettEa, item.actCostExact);
                                uFrcMargin = calculateItemFrcMargin(propotionalNettEa, item.stFutureCost);
                            } else {
                                uTotalPrice = item.totalPrice;
                                uActMargin = "";
                                uMargin = "";
                                uFrcMargin = "";
                                propotionalNettEa = item.nettEA;
                            }

                            updateSubGroupListForId(id, { [updatingItemSubGroup]: subGroupItems });
                        }

                        return {
                            ...item,
                            nettEA: propotionalNettEa,
                            repDisc: uRepDisc > 0 ? uRepDisc : 0,
                            margin: uMargin,
                            frcMargin: uFrcMargin,
                            dcOnLp: uDcOnLp,
                            totalPrice: uTotalPrice,
                            actMargin: uActMargin,
                        };
                    });

                    updatedItemList.splice(itemIndex + 1, updatedSubGroupItems.length, ...updatedSubGroupItems);

                    // new implementation
                    let sgItems = getSubGroupedRowItems(updatedItemList, updatingItemGroup, updatingItemSubGroup);
                    // sgItems = getListWithQtyBasedNettEA(sgItems);
                    sgItems = getQtyBasedValues(sgItems);

                    const sgTotalPrice = calculateGroupWiseTotal(sgItems, "totalPrice");
                    const sgListPrice = calculateGroupWiseTotal(sgItems, "listPrice");
                    const sgStEecost = calculateGroupWiseTotal(sgItems, "stEecost");
                    const sgActCostExact = calculateGroupWiseTotal(sgItems, "actCostExact");
                    const sgNettEA = calculateGroupWiseTotal(sgItems, "nettEA");
                    const sgFutureCost = calculateGroupWiseTotal(sgItems, "stFutureCost");
                    const sgDcOnLp = calculateItemDcOnLp(sgNettEA, sgListPrice);

                    //remove db extra for margin calculations
                    sgItems = getItemsWithoutAIandDX(sgItems);
                    let sgNettEANonDX = calculateGroupWiseTotal(sgItems, "nettEA");
                    const sgMargin = calculateGroupWiseMarginForSNumberChange(sgNettEANonDX, sgStEecost);
                    const sgActMargin = calculateGroupWiseActMarginForSNumberChange(sgNettEANonDX, sgActCostExact);
                    const sgFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(sgNettEANonDX, sgFutureCost);

                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        listPrice: sgListPrice,
                        stEecost: sgStEecost,
                        actCostExact: sgActCostExact,
                        nettEA: sgNettEA,
                        dcOnLp: sgDcOnLp,
                        margin: sgMargin,
                        frcMargin: sgFrcMargin,
                        actMargin: sgActMargin,
                        actCost: parseFloat(sgActCostExact.toFixed(2)),
                        stEecostRound: parseFloat(sgStEecost.toFixed(2)),
                        repDisc: 0,
                        stFutureCost: sgFutureCost,
                        stFutureCostRound: parseFloat(sgFutureCost.toFixed(2)),
                    };

                    updatedItemList[itemIndex] = updatedSubGroupRow;

                    // ref: group db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        // const dbExtraRow = getExistingGroupWiseDbExtraRow(updatedItemList, updatingItemGroup)[0];
                        // const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
                        // let listWithoutDbExtra = getItemsWithoutDBExtra(
                        //     updatedItemList,
                        //     GroupTypes.GROUP,
                        //     updatingItemGroup,
                        //     null
                        // );
                        // listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                        // const groupNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                        // const updatedGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, groupNettEA);

                        // const updatedDbExtraRow = {
                        //     ...dbExtraRow,
                        //     nettEA: updatedGroupDbExtraNettEA,
                        //     totalPrice: updatedGroupDbExtraNettEA,
                        // };

                        const { dbExtraRow, dbExtraRowIndex } = getQtyBasedGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            dbExtraCharge
                        );
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    //new implementation
                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    let subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                    groupRowItems = getQtyBasedValues(groupRowItems);
                    // subGroupRowList = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);
                    // subGroupRowList = getQtyBasedValues(subGroupRowList);
                    subGroupRowList = getQtyBasedValuesWithGroups(subGroupRowList);

                    let groupItems = [...groupRowItems, ...subGroupRowList];

                    let gTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    let gNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    let gListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    let gstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    let gActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    let gStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    let gDcOnLp = calculateItemDcOnLp(gNettEA, gListPrice);

                    //remove db extra for margin related calculations
                    let groupItemsNonDX = getItemsWithoutAIandDX(groupRowItems);
                    let subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);
                    groupItemsNonDX = getQtyBasedValues(groupItemsNonDX);
                    subGroupRowsNonDX = getQtyBasedValuesWithGroups(subGroupRowsNonDX);

                    groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

                    const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, gstEecost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEANonDX, gActCostExact);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEANonDX, gStFutureCost);

                    const updatedGroupRow = {
                        ...groupRow,
                        totalPrice: gTotalPrice * groupRow.qty,
                        totalCost: calculateItemTotalCost(groupRow.qty, gstEecost),
                        nettEA: gNettEA,
                        listPrice: gListPrice,
                        actCostExact: gActCostExact,
                        stEecost: gstEecost,
                        stEecostRound: parseFloat(gstEecost.toFixed(2)),
                        actCost: parseFloat(gActCostExact.toFixed(2)),
                        stFutureCost: gStFutureCost,
                        stFutureCostRound: parseFloat(gStFutureCost.toFixed(2)),
                        dcOnLp: gDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        lcMargin: 0.0,
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }
                    //#region 
                    //commented by sachini 20/03/2024 add setComputeTotals(true)
                    // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(updatedItemList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEA);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    setComputeTotals(true);

                } else {
                    let updatedItemList = [...itemList];
                    // has a group id but no sub group id
                    //db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
                        const dbExtraRowIndex = getDbExtraRowIndex(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            GroupTypes.GROUP
                        );
                        const updatedDbExtraRow = calcNettEAChangeDbExtraRow(
                            qtyBasedList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    console.log("group qty :", groupRow.qty)
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const updatedGroupRowList = groupRowList.map((item) => {
                        if (item.qty > 1) {
                            return {
                                ...item,
                                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                            };
                        }
                        return item;
                    });
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    const groupItems = [...updatedGroupRowList, ...subGroupRows];

                    const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    // const groupListPrice = calculateGroupWiseTotal(updatedGroupRowList, "listPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupRow.listPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupRow.listPrice);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow,
                        totalPrice: parseFloat((groupTotalPrice * groupRow.qty).toFixed(2)),  //added by sachini 20/3/2024 - add qty to multiply bcz does not getting group qty for get total price
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        actCostExact: groupActCostExact,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        // lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                    setComputeTotals(true);
                    //#region 
                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);


                    //commented by sachini 20/03/2024
                    // footer calculation
                    // const {
                    //     totalPrice,
                    //     totalSteEeCostRound,
                    //     totalStFutureCost,
                    //     totalActCostExact,
                    //     totalListPrice,
                    //     totalNettEA,
                    //     totalCost,
                    //     totalMargin,
                    //     totalActMargin,
                    //     totalFrcMargin,
                    //     totalDcOnLp,
                    // } = footerCalculation(updatedItemList);

                    // setTotalItemPrice(totalPrice);
                    // setTotalstEecostRound(totalSteEeCostRound);
                    // setTotalStFutureCost(totalStFutureCost);
                    // setTotalActCostEAExact(totalActCostExact);
                    // setTotalListPrice(totalListPrice);
                    // setTotalCost(totalCost);
                    // setTotalNettEA(totalNettEA);
                    // setMaxMargin(totalMargin);
                    // setActMargin(totalActMargin);
                    // setMaxFrcMargin(totalFrcMargin);
                    // setFooterDisc(totalDcOnLp);
                    //#endregion

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: updatedItemList[0],
                        });
                    }
                }
            } else if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && isAGroupRow) {
                const prevGroupRow = itemRow;
                let updatedItemList = [...itemList];
                let uNettEA = 0;
                let pNettEA = 0;
                let dbExtraValue = 0;

                const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                //GROUP LEVEL DB EXTRA CALCULATION
                if (groupDbExtra) {
                    const dbExtraRow = getExistingGroupWiseDbExtraRow(updatedItemList, updatingItemGroup)[0];
                    const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
                    // dbExtraValue = dbExtraRow.nettEA;
                    // uNettEA = updatedNettEA - dbExtraValue;
                    // dbExtraValue = Math.ceil(updatedNettEA / 2);
                    dbExtraValue = calculateDbExtra(dbExtraCharge, updatedNettEA);
                    uNettEA = updatedNettEA - dbExtraValue;
                    pNettEA = prevGroupRow.nettEA - dbExtraRow.nettEA;

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: dbExtraValue,
                        totalPrice: dbExtraValue,
                    };

                    updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                } else {
                    uNettEA = updatedNettEA;
                    pNettEA = prevGroupRow.nettEA;
                }

                const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(
                    updatedItemList,
                    updatingItemGroup
                );
                const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                const updatedSubGroupRows = subGroupRows.map((item) => {
                    const sgId = item.subGroupId;
                    const sgItems = getSubGroupItemsWithoutDBExtra(updatedItemList, updatingItemGroup, sgId);
                    const sgNettEA = calculateGroupWiseTotal(sgItems, "nettEA");
                    const sgTotalPrice = calculateGroupWiseTotal(sgItems, "totalPrice");

                    return {
                        ...item,
                        nettEA: sgNettEA,
                        totalPrice: sgTotalPrice,
                    };
                });

                const combinedList = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

                const uItemList = combinedList.map((item) => {
                    //let propotionalValue = calculateProportion(item.nettEA, itemRow.nettEA - dbExtraValue);
                    // const originalQuoteId = quoteDetails[id]?.savedQuote?.originalQuoteId;
                    let propotionalValue = calculateProportion(item.nettEA, pNettEA);
                    let propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);
                    let originalItemNettEA = 0;
                    let originalItemList = originalItems[id];

                    if (originalItemList) {
                        originalItemNettEA = originalItems[id]?.items?.find(
                            (i) => i.stStockCode === item.stStockCode
                        )?.nettEA;
                    } else {
                        originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
                            (i) => i.stStockCode === item.stStockCode
                        )?.nettEA;
                    }

                    return {
                        ...item,
                        nettEA: propotionalNettEa,
                        totalPrice: propotionalNettEa,
                        margin: calculateItemMargin(propotionalNettEa, item.stEecost),
                        repDisc:
                            calculateItemRepDisc(originalItemNettEA, propotionalNettEa) > 0
                                ? calculateItemRepDisc(originalItemNettEA, propotionalNettEa)
                                : 0,
                        lcMargin: calculateItemLcMargin(propotionalNettEa, item.lastCostExact),
                        frcMargin: calculateItemFrcMargin(propotionalNettEa, item.stFutureCost),
                        actMargin: calculateItemMargin(propotionalNettEa, item.actCostExact),
                        dcOnLp: calculateItemDcOnLp(propotionalNettEa, item.listPrice),
                    };
                });

                uItemList.forEach((item) => {
                    const itemIndex = updatedItemList.findIndex((i) => i.rowId === item.rowId);
                    updatedItemList[itemIndex] = item;
                });

                if (subGroupRows.length > 0) {
                    let uSgItemList = [];

                    subGroupRows.forEach((subGroupRow) => {
                        let sgId = subGroupRow.subGroupId;
                        let sgNettEA = 0;
                        let sgDbExtraValue = 0;
                        let dbExtraRow = null;
                        let dbExtraRowIndex = -1;

                        const hasDbExtraAdded = isDbExtraAdded(
                            updatedItemList,
                            updatingItemGroup,
                            sgId,
                            GroupTypes.SUB_GROUP
                        );

                        if (hasDbExtraAdded) {
                            dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId)[0];
                            dbExtraRowIndex = getDbExtraRowIndex(
                                updatedItemList,
                                updatingItemGroup,
                                sgId,
                                GroupTypes.SUB_GROUP
                            );
                            sgDbExtraValue = dbExtraRow.nettEA;
                            sgNettEA = subGroupRow.nettEA - sgDbExtraValue;
                        } else {
                            sgNettEA = subGroupRow.nettEA;
                        }

                        const sgItems = getSubGroupItemsWithoutDBExtra(updatedItemList, updatingItemGroup, sgId);
                        const uNettEA = updatedItemList.filter((i) => i.subGroupId === sgId)[0]?.nettEA;

                        uSgItemList = sgItems.map((s) => {
                            let propotionalValue = -1;
                            let propotionalNettEa = -1;
                            let uRepDisc = -1;
                            let uMargin = -1;
                            let uFrcMargin = -1;
                            let uDcOnLp = -1;
                            let uTotalPrice = -1;
                            let uActMargin = -1;
                            let originalNettEA = -1;

                            propotionalValue = calculateProportion(s.nettEA, sgNettEA);
                            propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);
                            originalNettEA = originalItems[id]
                                ? originalItems[id]?.items?.find((i) => i.rowId === s.rowId)?.nettEA
                                : originalItems[originalQuoteId]?.items?.find((i) => i.rowId === s.rowId)?.nettEA;

                            if (s.groupDbExtraId === "" && s.subGroupDbExtraId === "" && s.nonGroupDbExtraId === "") {
                                // identifies a item row
                                // uRepDisc = calculateItemRepDisc(s.nettEA, propotionalNettEa);
                                uRepDisc = calculateItemRepDisc(originalNettEA, propotionalNettEa);
                                uMargin = calculateItemMargin(propotionalNettEa, s.stEecost);
                                uFrcMargin = calculateItemFrcMargin(propotionalNettEa, s.stFutureCost);
                                uDcOnLp = calculateItemDcOnLp(propotionalNettEa, s.listPrice);
                                uTotalPrice = calculateItemTotalPrice(s.qty, propotionalNettEa);
                                uActMargin = calculateItemMargin(propotionalNettEa, s.actCostExact);
                            } else {
                                // identifies db extra row
                                uTotalPrice = s.totalPrice;
                                uActMargin = "";
                                uMargin = "";
                                uFrcMargin = "";
                                propotionalNettEa = s.nettEA;
                            }

                            return {
                                ...s,
                                nettEA: propotionalNettEa,
                                repDisc: uRepDisc > 0 ? uRepDisc : 0,
                                margin: uMargin,
                                frcMargin: uFrcMargin,
                                dcOnLp: uDcOnLp,
                                totalPrice: uTotalPrice,
                                actMargin: uActMargin,
                                //   lcMargin: "",
                            };
                        });

                        uSgItemList.forEach((item) => {
                            const itemIndex = updatedItemList.findIndex((i) => i.rowId === item.rowId);
                            updatedItemList[itemIndex] = item;
                        });

                        if (hasDbExtraAdded) {
                            const updatedNettEA = calculateGroupWiseTotal(uSgItemList, "nettEA");
                            const dbExtra = calculateDbExtra(dbExtraCharge, updatedNettEA);

                            dbExtraRow = {
                                ...dbExtraRow,
                                nettEA: dbExtra,
                                totalPrice: dbExtra,
                            };

                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }

                        //ONLY UPDATE THE SUB GROUP ROWS. UPDATING THE GROUP ROW WILL AFFECT GROUP LEVEL CALCULATION
                        const sgItms = getAllExistingSubGroupedItems(updatedItemList, updatingItemGroup, sgId);
                        const nettEAAU = calculateGroupWiseTotal(sgItms, "nettEA");
                        const listPriceAU = calculateGroupWiseTotal(sgItms, "listPrice");
                        const stEecostAU = calculateGroupWiseTotal(sgItms, "stEecost");
                        const stFutureCostAU = calculateGroupWiseTotal(sgItms, "stFutureCost");
                        const actCostExactAU = calculateGroupWiseTotal(sgItms, "actCostExact");
                        const totalPrice = calculateGroupWiseTotal(sgItms, "totalPrice");
                        const totalCost = calculateGroupWiseTotal(sgItms, "totalCost");
                        // const dcOnLpAU = calculateGroupDcOnLp(listPriceAU, nettEAAU);
                        const dcOnLpAU = calculateItemDcOnLp(nettEAAU, listPriceAU);
                        const marginAU = calculateGroupWiseMarginForSNumberChange(nettEAAU, stEecostAU);
                        const frcMarginAU = calculateGroupWiseFrcMarginForSNumberChange(nettEAAU, stFutureCostAU);
                        const actMarginAU = calculateGroupWiseActMarginForSNumberChange(nettEAAU, actCostExactAU);

                        const updatedSubGroupRow = {
                            ...subGroupRow,
                            nettEA: nettEAAU,
                            listPrice: listPriceAU,
                            stEecost: stEecostAU,
                            stEecostRound: parseFloat(stEecostAU.toFixed(2)),
                            stFutureCost: stFutureCostAU,
                            stFutureCostRound: parseFloat(stFutureCostAU.toFixed(2)),
                            actCostExact: actCostExactAU,
                            actCost: parseFloat(actCostExactAU.toFixed(2)),
                            dcOnLp: dcOnLpAU,
                            margin: marginAU,
                            frcMargin: frcMarginAU,
                            actMargin: actMarginAU,
                            totalPrice: totalPrice * subGroupRow.qty,
                            // totalCost: totalCost * subGroupRow.qty,
                            totalCost: calculateItemTotalCost(subGroupRow.qty, stEecostAU),
                            //lcMargin: "",
                        };

                        const subGroupRowIndex = updatedItemList.findIndex((s) => s.rowId === subGroupRow.rowId);
                        updatedItemList[subGroupRowIndex] = updatedSubGroupRow;
                    });
                }

                //GROUP ROW CALCULATIONS WITHOUT VERTICALs
                const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                const updatedGroupRowItems = groupRowItems.map((item) => {
                    if (item.qty > 1) {
                        return {
                            ...item,
                            stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                        };
                    }
                    return item;
                });

                const subGroups = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                const groupItems = [...updatedGroupRowItems, ...subGroups];

                const groupStEecost = calculateGroupWiseTotal(groupItems, "stEecost");

                const updatedGroupRow = {
                    ...updatedItem,
                    repDisc: 0,
                    lcMargin: 0,
                    margin: calculateGroupWiseMarginForSNumberChange(updatedItem.nettEA, groupStEecost),
                    // dcOnLp:
                    //     calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA) > 0
                    //         ? calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA)
                    //         : 0,
                    dcOnLp:
                        calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA) > 0
                            ? calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA)
                            : 0,
                };

                updatedItemList[itemIndex] = updatedGroupRow;

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                //#region 
                // setComputeTotals(true);

                //commented by sachini 20/03/2024 add setComputeTotals(true)
                // footer calculation
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEA);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);

                // const totalPrice = calculateTotalPrice(updatedItemList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(updatedItemList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(updatedItemList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(updatedItemList);
                // setTotalListPrice(totalListPrice);
                //#endregion
            } else {
                let updatedItemList = [...itemList];
                //#region 
                //db extra calculation
                // const nonGroupDbExtra = isDbExtraAdded(itemList, updatingItemGroup, null, GroupTypes.NON_GROUP);

                // if (nonGroupDbExtra) {
                //     const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
                //     const dbExtraRowIndex = getDbExtraRowIndex(
                //         updatedItemList,
                //         updatingItemGroup,
                //         updatingItemSubGroup,
                //         GroupTypes.NON_GROUP
                //     );
                //     const updatedDbExtraRow = calcNettEAChangeDbExtraRow(
                //         qtyBasedList,
                //         updatingItemGroup,
                //         updatingItemSubGroup,
                //         dbExtraCharge,
                //         GroupTypes.NON_GROUP
                //     );
                //     updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
                // }
                //#endregion

                const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setItemList(updatedItemList);
                setComputeTotals(true);
                //#region 
                // const totalPrice = calculateTotalPrice(updatedItemList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(updatedItemList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(updatedItemList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(updatedItemList);
                // setTotalListPrice(totalListPrice);

                // footer calculation //commented by sachini 20/03/2024 add setComputeTotals(true)
                // const {
                //     totalPrice,
                //     totalSteEeCostRound,
                //     totalStFutureCost,
                //     totalActCostExact,
                //     totalListPrice,
                //     totalNettEA,
                //     totalCost,
                //     totalMargin,
                //     totalActMargin,
                //     totalFrcMargin,
                //     totalDcOnLp,
                // } = footerCalculation(updatedItemList);

                // setTotalItemPrice(totalPrice);
                // setTotalstEecostRound(totalSteEeCostRound);
                // setTotalStFutureCost(totalStFutureCost);
                // setTotalActCostEAExact(totalActCostExact);
                // setTotalListPrice(totalListPrice);
                // setTotalCost(totalCost);
                // setTotalNettEA(totalNettEA);
                // setMaxMargin(totalMargin);
                // setActMargin(totalActMargin);
                // setMaxFrcMargin(totalFrcMargin);
                // setFooterDisc(totalDcOnLp);
                //#endregion

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: updatedItemList[0],
                    });
                }
            }
        } else if (field === "dcOnLp") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = itemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

            let updatedDcOnLp = -1;
            let prevNettEA =
                originalItems[id]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)?.nettEA ||
                originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)?.nettEA;
            let newNettEa = -1;
            let newRepDisc = -1;

            if (!isNaN(parseFloat(value))) {
                updatedDcOnLp = parseFloat(value);
            } else {
                updatedDcOnLp = itemRow.dcOnLp;
                toast.error("Please enter a valid number");
                return;
            }
            

            //#region secure that the previously nettea will be based on the default
             let val = await getResponse("Stocks/qtybreaknettea", {
                CustomerId: itemRow.customerId,
                partno: itemRow.stStockCode,
                qty: itemRow.qty,
            });
            prevNettEA = (val !== null ? val[0].data : prevNettEA = itemRow?.nettEA);
            //#endregion

            if (rowDconlpData[id] && rowDconlpData[id][itemRow.rowId]) {
                const prevRow = rowDconlpData[id][itemRow.rowId];
                // prevNettEA = itemRow?.nettEA;
                newNettEa = calculateNettEaForDConLP(prevRow.listPrice, updatedDcOnLp);
                newRepDisc = calculateItemRepDisc(prevNettEA, newNettEa);
            } else {
                updateDconlpRowForId(id, { [itemRow.rowId]: itemRow });
                // prevNettEA = itemRow?.nettEA;
                newNettEa = calculateNettEaForDConLP(typeof itemRow.listPrice === 'string' && itemRow.listPrice === '' ? 0 : itemRow.listPrice, updatedDcOnLp);
                newRepDisc = calculateItemRepDisc(prevNettEA, newNettEa);
            }

            const updatedItem = {
                ...itemRow,
                dcOnLp: parseFloat(updatedDcOnLp.toFixed(1)),
                nettEA: newNettEa,
                totalPrice: newNettEa * itemRow.qty,
                repDisc: newRepDisc > 0 ? newRepDisc : "",
                totalCost: parseFloat(itemRow.qty * itemRow.stEecostRound),
                margin:
                    newNettEa <= 0.0
                        ? 0.0
                        : parseFloat((((newNettEa - itemRow?.stEecost) / newNettEa) * 100).toFixed(1)),
                actMargin: newNettEa <= 0.0 ? 0.0 : calculateItemActMargin(newNettEa, itemRow?.actCostExact),
                frcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemFrcMargin(newNettEa, itemRow?.stFutureCost),
                lcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemLcMargin(newNettEa, itemRow?.lastCostExact),
                //  lcMargin: "",
            };

            itemList[itemIndex] = updatedItem;

            if (newRepDisc > 0) {
                checkRepDiscLimit(updatedItem);
            }

            if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                if (updatingItemSubGroup && !isASubGroupRow) {
                    let updatedItemList = [...itemList];

                    //sub group level db extra calculations
                    const subGroupDbExtra = isDbExtraAdded(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup,
                        GroupTypes.SUB_GROUP
                    );

                    if (subGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup,
                            dbExtraCharge,
                            GroupTypes.SUB_GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    const subGroupRows = getAllExistingSubGroupedItems(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    );

                    const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
                    const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
                    const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
                    const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
                    const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
                    const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
                    // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
                    const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
                    const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                    const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupStFutureCost
                    );
                    const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        subGroupNettEA,
                        subGroupActCostExact
                    );

                    const subGroup = getSubGroupRowByGroupAndSubGroup(
                        updatedItemList,
                        updatingItemGroup,
                        updatingItemSubGroup
                    )[0];

                    const subGroupIndex = getSubGroupRowIndex(updatedItemList, updatingItemGroup, updatingItemSubGroup);

                    const updatedSubGroup = {
                        ...subGroup,
                        totalPrice: subGroupTotalPrice,
                        nettEA: subGroupNettEA,
                        dcOnLp: subGroupDcOnLp,
                        margin: subGroupMargin,
                        actMargin: subGroupActMargin,
                        frcMargin: subGroupFrcMargin,
                        stFutureCost: subGroupStFutureCost,
                        stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                        stEecost: subGroupstEecost,
                        stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                        actCostExact: subGroupActCostExact,
                        actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        // lcMargin: "",
                    };

                    updatedItemList[subGroupIndex] = updatedSubGroup;

                    //group level db extra calculations
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            null,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
                    const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

                    const groupRowList = [...groupRowItems, ...subGroupRowList];

                    const groupTotalPrice = calculateGroupWiseTotal(groupRowList, "totalPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupRowList, "nettEA");
                    const groupListPrice = calculateGroupWiseTotal(groupRowList, "listPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupRowList, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupRowList, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupRowList, "actCostExact");
                    // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow,
                        totalPrice: groupTotalPrice,
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        actCostExact: groupActCostExact,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        // lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: updatedItemList[0],
                        });
                    }
                } else {
                    const updatedItemList = [...itemList];

                    //group level db extra calculation
                    const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

                    if (groupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
                            updatedItemList,
                            updatingItemGroup,
                            null,
                            dbExtraCharge,
                            GroupTypes.GROUP
                        );

                        updatedItemList[dbExtraRowIndex] = dbExtraRow;
                    }

                    const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                    const groupRowIndex = updatedItemList.indexOf(groupRow);
                    const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
                    const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                    const updatedGroupRowList = groupRowList.map((s) => {
                        if (s.qty > 1) {
                            return {
                                ...s,
                                listPrice: calculateValuesOnSBLQtyChange(s.qty, s.listPrice),
                                stEecost: calculateValuesOnSBLQtyChange(s.qty, s.stEecost),
                                stEecostRound: calculateValuesOnSBLQtyChange(s.qty, s.stEecostRound),
                                stFutureCost: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCost),
                                stFutureCostRound: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCostRound),
                                actCostExact: calculateValuesOnSBLQtyChange(s.qty, s.actCostExact),
                                actCost: calculateValuesOnSBLQtyChange(s.qty, s.actCost),
                                nettEA: calculateValuesOnSBLQtyChange(s.qty, s.nettEA),
                            };
                        }
                        return s;
                    });

                    const groupItems = [...updatedGroupRowList, ...subGroupRows];

                    const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                    const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                    const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
                    const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                    const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                    const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
                    const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
                    const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

                    const updatedGroupRow = {
                        ...groupRow,
                        listPrice: groupListPrice,
                        totalPrice: calculateValuesOnSBLQtyChange(groupRow.qty, groupTotalPrice),
                        nettEA: groupNettEA,
                        dcOnLp: groupDcOnLp,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        stFutureCost: groupStFutureCost,
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stEecost: groupstEecost,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        actCostExact: groupActCostExact,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        //  lcMargin: "",
                    };

                    updatedItemList[groupRowIndex] = updatedGroupRow;

                    // non group db extra calculation
                    const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

                    if (nonGroupItems.length > 0) {
                        const nonGroupId = nonGroupItems[0].groupId;
                        const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                        if (nonGroupDbExtra) {
                            const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
                                updatedItemList,
                                dbExtraCharge
                            );
                            updatedItemList[dbExtraRowIndex] = dbExtraRow;
                        }
                    }

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalItemPrice(totalPrice);

                    // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalCost(totalCost);

                    // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
                    // setTotalNettEA(totalneeta);

                    // const totalstEecostRound = calculateTotalstEecostRound(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalstEecostRound(totalstEecostRound);

                    // const totalStFutureCost = calculateTotalStFutureCost(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalStFutureCost(totalStFutureCost);

                    // const totalActCostEa = calculateTotalActCostEAExact(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalActCostEAExact(totalActCostEa);

                    // const totalListPrice = calculateTotalListPrice(
                    //     updatedItemList.filter((item) => item.stStockCode != " ")
                    // );
                    // setTotalListPrice(totalListPrice);

                    if (firstCustomer[id]?.customer?.customerId === customerId) {
                        updateFirstCustomerForId(id, {
                            ...firstCustomer[id],
                            item: updatedItemList[0],
                        });
                    }
                }
            } else {
                let updatedItemList = [...itemList];
                const nonGroupId = updatedItemList.length > 0 && updatedItemList[0].groupId;
                const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

                if (nonGroupDbExtra) {
                    const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);

                    updatedItemList[dbExtraRowIndex] = dbExtraRow;
                }

                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                setItemList(updatedItemList);

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                // const totalPrice = calculateTotalPrice(updatedItemList);
                // setTotalItemPrice(totalPrice);

                // const totalCost = calculateTotalCost(updatedItemList);
                // setTotalCost(totalCost);

                // const totalneeta = calculateTotalNettEA(updatedItemList);
                // setTotalNettEA(totalneeta);

                // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
                // setTotalstEecostRound(totalstEecostRound);

                // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
                // setTotalStFutureCost(totalStFutureCost);

                // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
                // setTotalActCostEAExact(totalActCostEa);

                // const totalListPrice = calculateTotalListPrice(updatedItemList);
                // setTotalListPrice(totalListPrice);

                if (firstCustomer[id]?.customer?.customerId === customerId) {
                    updateFirstCustomerForId(id, {
                        ...firstCustomer[id],
                        item: updatedItemList[0],
                    });
                }
            }
        } else if (field === "repDisc") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = itemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

            let updatedRepDiscount = -1;

            if (!isNaN(parseFloat(value))) {
                updatedRepDiscount = parseFloat(parseFloat(value).toFixed(2));
            } else {
                updatedRepDiscount = itemRow.repDisc;
                toast.error("Please enter a valid number");
                return;
            }

            const updatedRepDisc = parseFloat(updatedRepDiscount.toFixed(1));
            let newNettEa = -1;
            let updatedItem = null;

            if (previousMainCustomer.current !== mainCustomer || !newCustomerStockDetails.current) {
                //get the original nettEA to be used on the computation
                // const getNettEA = originalItems[id]?.items.filter((f) => f.rowId === itemRow.rowId);

                const ogList = originalItems[id];
                let getNettEA = 0;

                // if (ogList) {
                //     getNettEA = ogList?.items.filter((f) => f.stStockCode === itemRow.stStockCode);

                //     //#region added to cater the original nettea based on qty
                //     const val = await getResponse("Stocks/qtybreaknettea", {
                //         CustomerId: itemRow.customerId,
                //         partno: itemRow.stStockCode,
                //         qty: itemRow.qty,
                //     });
                //     if (val !== null) {
                //         if (val.length > 0) {
                //             origNettEA = val[0].data;
                //         } else origNettEA = getNettEA?.length > 0 ? parseFloat(getNettEA.at(-1).nettEA) : itemRow?.nettEA;
                //     } else origNettEA = getNettEA?.length > 0 ? parseFloat(getNettEA.at(-1).nettEA) : itemRow?.nettEA;
                //     //#endregion
                // } else {
                //     const originalQuoteId = quoteDetails[id]?.savedQuote?.originalQuoteId;
                //      getNettEA = quoteDetails[id]?.savedQuote?.tblQuoteItems.filter(
                //          (f) => f.stStockCode === itemRow.stStockCode
                //      );
                //     origNettEA = getNettEA?.length > 0 ? parseFloat(getNettEA[0].nettEA) : itemRow?.nettEA;
                // }

                
                let origNettEA = getOrgiNettEA(itemRow);

                if (rowData && rowData[id] && rowData[id][itemRow.rowId]) {
                    newNettEa = calculateNettEaForRepDiscChange(origNettEA, updatedRepDisc);
                } else {
                    updateRowForId(id, { [itemRow.rowId]: itemRow });
                    newNettEa = calculateNettEaForRepDiscChange(origNettEA, updatedRepDisc);
                }
            } else {
                updateRowForId(id, { [itemRow.rowId]: itemRow });
                newNettEa = calculateNettEaForRepDiscChangeAfterCustomerChange(itemRow?.stStockCode, updatedRepDisc);
            }

            if (updatedRepDisc === 0.0 || updatedRepDisc > 0.0) {
                updatedItem = {
                    ...itemRow,
                    repDisc: updatedRepDisc,
                    totalCost: parseFloat(itemRow.qty * itemRow.stEecostRound),
                    nettEA: newNettEa,
                    totalPrice: parseFloat((itemRow?.qty * newNettEa).toFixed(2)),
                    // dcOnLp: parseFloat((((itemRow?.listPrice - newNettEa) / itemRow?.listPrice) * 100).toFixed(1)),
                    dcOnLp: calculateItemDcOnLp(newNettEa, itemRow?.listPrice),
                    margin:
                        newNettEa <= 0.0
                            ? 0.0
                            : parseFloat((((newNettEa - itemRow?.stEecost) / newNettEa) * 100).toFixed(1)),
                    actMargin: newNettEa <= 0.0 ? 0.0 : calculateItemActMargin(newNettEa, itemRow?.actCostExact),
                    frcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemFrcMargin(newNettEa, itemRow?.stFutureCost),
                    lcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemLcMargin(newNettEa, itemRow?.lastCostExact),
                    //  lcMargin: "",
                };
                itemList[itemIndex] = updatedItem;

                checkRepDiscLimit(updatedItem);
            } else {
                toast.error("Please enter a valid number");
                return;
            }

            setItemList(itemList);
            updateQuoteDetailsForId(id, { selectedItems: itemList });
            setComputeTotals(true);
            // if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
            //     if (updatingItemSubGroup && !isASubGroupRow) {
            //         let updatedItemList = [...itemList];

            //         //sub group level db extra calculations
            //         const subGroupDbExtra = isDbExtraAdded(
            //             updatedItemList,
            //             updatingItemGroup,
            //             updatingItemSubGroup,
            //             GroupTypes.SUB_GROUP
            //         );

            //         if (subGroupDbExtra) {
            //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
            //                 updatedItemList,
            //                 updatingItemGroup,
            //                 updatingItemSubGroup,
            //                 dbExtraCharge,
            //                 GroupTypes.SUB_GROUP
            //             );

            //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //         }

            //         let subGroupRows = getAllExistingSubGroupedItems(
            //             updatedItemList,
            //             updatingItemGroup,
            //             updatingItemSubGroup
            //         );
            //         subGroupRows = getQtyBasedValues(subGroupRows);

            //         const filteredItems = updatedItemList.filter(item => 
            //             item.subGroupId === updatingItemSubGroup && item.stStockCode === "" && item.rowType === "SG"
            //         );

            //         //const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
            //         const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
            //         const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
            //         const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
            //         const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
            //         const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
            //         // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
            //         const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

            //         // remove db extra for margin calculations
            //         subGroupRows = getItemsWithoutAIandDX(subGroupRows);
            //         const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupRows, "nettEA");
            //         // const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                    
            //         const subTotalPrice = filteredItems[0].qty * subGroupNettEANonDX;
            //         filteredItems[0].totalPrice = parseFloat(subTotalPrice.toFixed(2));

            //         const subGroupTotalPrice = calculateGroupWiseTotal(filteredItems, "totalPrice");
                    
            //         const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
            //             subGroupNettEANonDX,
            //             subGroupstEecost
            //         );
            //         // const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
            //         //     subGroupNettEA,
            //         //     subGroupStFutureCost
            //         // );
            //         const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
            //             subGroupNettEANonDX,
            //             subGroupStFutureCost
            //         );
            //         // const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
            //         //     subGroupNettEA,
            //         //     subGroupActCostExact
            //         // );
            //         const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
            //             subGroupNettEANonDX,
            //             subGroupActCostExact
            //         );

            //         const subGroup = getSubGroupRowByGroupAndSubGroup(
            //             updatedItemList,
            //             updatingItemGroup,
            //             updatingItemSubGroup
            //         );

            //         const subGroupIndex = getSubGroupRowIndex(updatedItemList, updatingItemGroup, updatingItemSubGroup);

            //         const updatedSubGroup = {
            //             ...subGroup[0],
            //             totalPrice: subGroupTotalPrice,
            //             nettEA: subGroupNettEA,
            //             dcOnLp: subGroupDcOnLp,
            //             margin: subGroupMargin,
            //             actMargin: subGroupActMargin,
            //             frcMargin: subGroupFrcMargin,
            //             stFutureCost: subGroupStFutureCost,
            //             stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
            //             stEecost: subGroupstEecost,
            //             stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
            //             actCostExact: subGroupActCostExact,
            //             actCost: parseFloat(subGroupActCostExact.toFixed(2)),
            //             //lcMargin: "",
            //         };

            //         updatedItemList[subGroupIndex] = updatedSubGroup;

            //         //group level db extra calculations
            //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

            //         if (groupDbExtra) {
            //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
            //                 updatedItemList,
            //                 updatingItemGroup,
            //                 null,
            //                 dbExtraCharge,
            //                 GroupTypes.GROUP
            //             );

            //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //         }

            //         //total price in group level
            //         const filteredGroupItems = updatedItemList.filter(item => 
            //             item.groupId === updatingItemGroup && item.stStockCode === "" && item.rowType === "G"
            //         );

            //         //group level calculations
            //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup);
            //         const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
            //         let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
            //         let subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
            //         groupRowItems = getQtyBasedValues(groupRowItems);
            //         subGroupRowList = getQtyBasedValues(subGroupRowList);

            //         let groupRowList = [...groupRowItems, ...subGroupRowList];

            //         //const groupTotalPrice = calculateGroupWiseTotal(groupRowList, "totalPrice");
            //         const groupListPrice = calculateGroupWiseTotal(groupRowList, "listPrice");
            //         const groupNettEA = calculateGroupWiseTotal(groupRowList, "nettEA");
            //         const groupstEecost = calculateGroupWiseTotal(groupRowList, "stEecost");
            //         const groupStFutureCost = calculateGroupWiseTotal(groupRowList, "stFutureCost");
            //         const groupActCostExact = calculateGroupWiseTotal(groupRowList, "actCostExact");
            //         // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
            //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

            //         //remove db extra for margin calculations
            //         groupRowItems = getItemsWithoutAIandDX(groupRowItems);
            //         subGroupRowList = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);

            //         groupRowList = [...groupRowItems, ...subGroupRowList];

            //         const TotalPriceValue = groupRow[0].qty * groupNettEA;
            //         filteredItems[0].totalPrice = parseFloat(TotalPriceValue.toFixed(2));

            //         const groupTotalPrice = calculateGroupWiseTotal(filteredItems, "totalPrice");

            //         const groupNettEANonDX = calculateGroupWiseTotal(groupRowList, "nettEA");
            //         // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
            //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
            //         // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
            //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
            //             groupNettEANonDX,
            //             groupStFutureCost
            //         );
            //         // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
            //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
            //             groupNettEANonDX,
            //             groupActCostExact
            //         );

            //         const updatedGroupRow = {
            //             ...groupRow[0],
            //             totalPrice: groupTotalPrice,
            //             nettEA: groupNettEA,
            //             dcOnLp: groupDcOnLp,
            //             margin: groupMargin,
            //             actMargin: groupActMargin,
            //             frcMargin: groupFrcMargin,
            //             stFutureCost: groupStFutureCost,
            //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
            //             stEecost: groupstEecost,
            //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
            //             actCostExact: groupActCostExact,
            //             actCost: parseFloat(groupActCostExact.toFixed(2)),
            //             // lcMargin: "",
            //         };

            //         updatedItemList[groupRowIndex] = updatedGroupRow;

            //         // non group db extra calculation
            //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

            //         if (nonGroupItems.length > 0) {
            //             const nonGroupId = nonGroupItems[0].groupId;
            //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

            //             if (nonGroupDbExtra) {
            //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
            //                     updatedItemList,
            //                     dbExtraCharge
            //                 );
            //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //             }
            //         }

            //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalItemPrice(totalPrice);

            //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalCost(totalCost);

            //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalNettEA(totalneeta);

            //         // const totalstEecostRound = calculateTotalstEecostRound(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalstEecostRound(totalstEecostRound);

            //         // const totalStFutureCost = calculateTotalStFutureCost(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalStFutureCost(totalStFutureCost);

            //         // const totalActCostEa = calculateTotalActCostEAExact(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalActCostEAExact(totalActCostEa);

            //         // const totalListPrice = calculateTotalListPrice(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalListPrice(totalListPrice);

            //         // footer calculation
            //         const {
            //             totalPrice,
            //             totalSteEeCostRound,
            //             totalStFutureCost,
            //             totalActCostExact,
            //             totalListPrice,
            //             totalNettEA,
            //             totalCost,
            //             totalMargin,
            //             totalActMargin,
            //             totalFrcMargin,
            //             totalDcOnLp,
            //         } = footerCalculation(updatedItemList);

            //         setTotalItemPrice(totalPrice);
            //         setTotalCostAmt(totalCost);
            //         setTotalstEecostRound(totalSteEeCostRound);
            //         setTotalStFutureCost(totalStFutureCost);
            //         setTotalActCostEAExact(totalActCostExact);
            //         setTotalListPrice(totalListPrice);
            //         setTotalCost(totalCost);
            //         setTotalNettEA(totalNettEA);
            //         setMaxMargin(totalMargin);
            //         setActMargin(totalActMargin);
            //         setMaxFrcMargin(totalFrcMargin);
            //         setFooterDisc(totalDcOnLp);

            //         setItemList(updatedItemList);
            //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

            //         if (firstCustomer[id]?.customer?.customerId === customerId) {
            //             updateFirstCustomerForId(id, {
            //                 ...firstCustomer[id],
            //                 item: updatedItemList[0],
            //             });
            //         }
            //     } else {
            //         let updatedItemList = [...itemList];

            //         //group db extra calculation
            //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

            //         if (groupDbExtra) {
            //             // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
            //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
            //                 updatedItemList,
            //                 updatingItemGroup,
            //                 null,
            //                 dbExtraCharge,
            //                 GroupTypes.GROUP
            //             );
            //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //         }

            //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
            //         const groupRowIndex = updatedItemList.indexOf(groupRow);
            //         const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
            //         // const updatedGroupRowItems = groupRowItems.map((item) => {
            //         //     if (item.qty > 1) {
            //         //         return {
            //         //             ...item,
            //         //             nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
            //         //             stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
            //         //             stEecostRound: calculateValuesOnSBLQtyChange(item.qty, item.stEecostRound),
            //         //             stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
            //         //             stFutureCostRound: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound),
            //         //             actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
            //         //             actCost: calculateValuesOnSBLQtyChange(item.qty, item.actCost),
            //         //         };
            //         //     }
            //         //     return item;
            //         // });
            //         const updatedGroupRowItems = getQtyBasedValues(groupRowItems);
            //         const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

            //         let groupItems = [...updatedGroupRowItems, ...subGroupRows];

            //         const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
            //         const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
            //         const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
            //         //  const groupNettEA = calculateNettEaForRepDiscChange(origNettEA,updatedRepDisc);
            //         const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
            //         const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
            //         const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
            //         // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
            //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

            //         //remove db extra for margin calculations
            //         // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
            //         // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
            //         // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

            //         const groupRowItemsNonDX = getItemsWithoutAIandDX(updatedGroupRowItems);
            //         const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRows, updatedItemList);

            //         groupItems = [...groupRowItemsNonDX, ...subGroupRowsNonDX];

            //         const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

            //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
            //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
            //             groupNettEANonDX,
            //             groupStFutureCost
            //         );
            //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
            //             groupNettEANonDX,
            //             groupActCostExact
            //         );

            //         const updatedGroupRow = {
            //             ...groupRow,
            //             totalPrice: calculateValuesOnSBLQtyChange(groupRow.qty, groupTotalPrice),
            //             totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
            //             nettEA: groupNettEA,
            //             dcOnLp: groupDcOnLp,
            //             margin: groupMargin,
            //             actMargin: groupActMargin,
            //             frcMargin: groupFrcMargin,
            //             stFutureCost: groupStFutureCost,
            //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
            //             stEecost: groupstEecost,
            //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
            //             actCostExact: groupActCostExact,
            //             actCost: parseFloat(groupActCostExact.toFixed(2)),
            //             //  lcMargin: "",
            //         };

            //         updatedItemList[groupRowIndex] = updatedGroupRow;

            //         // non group db extra calculation
            //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

            //         if (nonGroupItems.length > 0) {
            //             const nonGroupId = nonGroupItems[0].groupId;
            //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

            //             if (nonGroupDbExtra) {
            //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
            //                     updatedItemList,
            //                     dbExtraCharge
            //                 );
            //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //             }
            //         }

            //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalItemPrice(totalPrice);

            //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalCost(totalCost);

            //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
            //         // setTotalNettEA(totalneeta);

            //         // const totalstEecostRound = calculateTotalstEecostRound(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalstEecostRound(totalstEecostRound);

            //         // const totalStFutureCost = calculateTotalStFutureCost(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalStFutureCost(totalStFutureCost);

            //         // const totalActCostEa = calculateTotalActCostEAExact(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalActCostEAExact(totalActCostEa);

            //         // const totalListPrice = calculateTotalListPrice(
            //         //     updatedItemList.filter((item) => item.stStockCode != " ")
            //         // );
            //         // setTotalListPrice(totalListPrice);

            //         // footer calculation
            //         const {
            //             totalPrice,
            //             totalSteEeCostRound,
            //             totalStFutureCost,
            //             totalActCostExact,
            //             totalListPrice,
            //             totalNettEA,
            //             totalCost,
            //             totalMargin,
            //             totalActMargin,
            //             totalFrcMargin,
            //             totalDcOnLp,
            //         } = footerCalculation(updatedItemList);

            //         setTotalItemPrice(totalPrice);
            //         setTotalCostAmt(totalCost);
            //         setTotalstEecostRound(totalSteEeCostRound);
            //         setTotalStFutureCost(totalStFutureCost);
            //         setTotalActCostEAExact(totalActCostExact);
            //         setTotalListPrice(totalListPrice);
            //         setTotalCost(totalCost);
            //         setTotalNettEA(totalNettEA);
            //         setMaxMargin(totalMargin);
            //         setActMargin(totalActMargin);
            //         setMaxFrcMargin(totalFrcMargin);
            //         setFooterDisc(totalDcOnLp);

            //         setItemList(updatedItemList);
            //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

            //         if (firstCustomer[id]?.customer?.customerId === customerId) {
            //             updateFirstCustomerForId(id, {
            //                 ...firstCustomer[id],
            //                 item: updatedItemList[0],
            //             });
            //         }
            //     }
            // } else {
            //     let updatedItemList = [...itemList];

            //     // non group db extra calculation
            //     const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

            //     if (nonGroupItems.length > 0) {
            //         const nonGroupId = nonGroupItems[0].groupId;
            //         const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

            //         if (nonGroupDbExtra) {
            //             // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
            //             const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
            //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
            //         }
            //     }

            //     // footer calculation
            //     const {
            //         totalPrice,
            //         totalSteEeCostRound,
            //         totalStFutureCost,
            //         totalActCostExact,
            //         totalListPrice,
            //         totalNettEA,
            //         totalCost,
            //         totalMargin,
            //         totalActMargin,
            //         totalFrcMargin,
            //         totalDcOnLp,
            //     } = footerCalculation(updatedItemList);

            //     setTotalItemPrice(totalPrice);
            //     setTotalCostAmt(totalCost);
            //     setTotalstEecostRound(totalSteEeCostRound);
            //     setTotalStFutureCost(totalStFutureCost);
            //     setTotalActCostEAExact(totalActCostExact);
            //     setTotalListPrice(totalListPrice);
            //     setTotalCost(totalCost);
            //     setTotalNettEA(totalNettEA);
            //     setMaxMargin(totalMargin);
            //     setActMargin(totalActMargin);
            //     setMaxFrcMargin(totalFrcMargin);
            //     setFooterDisc(totalDcOnLp);

            //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            //     setItemList(updatedItemList);

            //     // const totalPrice = calculateTotalPrice(updatedItemList);
            //     // setTotalItemPrice(totalPrice);

            //     // const totalCost = calculateTotalCost(updatedItemList);
            //     // setTotalCost(totalCost);

            //     // const totalneeta = calculateTotalNettEA(updatedItemList);
            //     // setTotalNettEA(totalneeta);

            //     // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
            //     // setTotalstEecostRound(totalstEecostRound);

            //     // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
            //     // setTotalStFutureCost(totalStFutureCost);

            //     // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
            //     // setTotalActCostEAExact(totalActCostEa);

            //     // const totalListPrice = calculateTotalListPrice(updatedItemList);
            //     // setTotalListPrice(totalListPrice);

            //     if (firstCustomer[id]?.customer?.customerId === customerId) {
            //         updateFirstCustomerForId(id, {
            //             ...firstCustomer[id],
            //             item: updatedItemList[0],
            //         });
            //     }
            // }
        } else if (field === "displayGroupName") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const groupRow = itemList[itemIndex];

            if (
                groupRow.displaySubGroupName === "" ||
                groupRow.displaySubGroupName === undefined ||
                groupRow.displaySubGroupName === null
            ) {
                if (value === "" || value === undefined || value === null) {
                    const updatedGroupRow = {
                        ...groupRow,
                        displayGroupName: groupRow.groupName,
                    };

                    itemList[itemIndex] = updatedGroupRow;

                    setItemList(itemList);
                    updateQuoteDetailsForId(id, { selectedItems: itemList });

                    toast.error("Please enter a valid group name");
                    return;
                }

                const updatedGroupRow = {
                    ...groupRow,
                    displayGroupName: value,
                    groupLevelName: value
                };

                itemList[itemIndex] = updatedGroupRow;

                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });
            } else {
                const updatedGroupRow = {
                    ...groupRow,
                    displayGroupName: "",
                };

                itemList[itemIndex] = updatedGroupRow;

                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });

                toast.error("Editing the group name in a sub group row is forbidden.");
                return;
            }
        } else if (field === "displaySubGroupName") {
            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            const subGroupRow = itemList[itemIndex];

            if (
                subGroupRow.displayGroupName === "" ||
                subGroupRow.displayGroupName === undefined ||
                subGroupRow.displayGroupName === null
            ) {
                if (value === "" || value === undefined || value === null) {
                    const updatedSubGroupRow = {
                        ...subGroupRow,
                        displaySubGroupName: subGroupRow.subGroupName,
                        groupLevelName: subGroupRow.subGroupName,
                    };

                    itemList[itemIndex] = updatedSubGroupRow;

                    setItemList(itemList);
                    updateQuoteDetailsForId(id, { selectedItems: itemList });

                    toast.error("Please enter a valid sub group name");
                    return;
                }

                const updatedSubGroupRow = {
                    ...subGroupRow,
                    displaySubGroupName: value,
                    groupLevelName: value
                };

                itemList[itemIndex] = updatedSubGroupRow;

                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });
            } else {
                const updatedSubGroupRow = {
                    ...subGroupRow,
                    displaySubGroupName: "",
                };

                itemList[itemIndex] = updatedSubGroupRow;

                setItemList(itemList);
                updateQuoteDetailsForId(id, { selectedItems: itemList });

                toast.error("Editing the sub group name in a group row is forbidden.");
                return;
            }
        } else if (field === "description") {
            const itemRow = itemList[itemIndex];

            let updatedItem = {
                ...itemRow,
                description: value,
            };

            itemList[itemIndex] = updatedItem;

            setItemList(itemList);
            updateQuoteDetailsForId(id, { selectedItems: itemList });
        } else if (field === "stEecostRound") {
            let updatedItemList = [...itemList];
            const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = updatedItemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
            let updatedStEeCostRound = 0;

            if (!isNaN(parseFloat(value))) {
                updatedStEeCostRound = parseFloat(parseFloat(value).toFixed(2));
            } else {
                updatedStEeCostRound = itemRow.stEecostRound;
                toast.error("Please enter a valid number");
                return;
            }

            const updatedItem = {
                ...itemRow,
                stEecostRound: updatedStEeCostRound,
                stEecost: updatedStEeCostRound,
                margin: (parseFloat(updatedStEeCostRound) === 0.0 || parseFloat(itemRow.nettEA) === 0.0) ? parseFloat(itemRow.margin) : calculateItemMargin(itemRow.nettEA,updatedStEeCostRound),
                lcMargin: 0,
                totalCost: calculateItemTotalCost(itemRow.qty, updatedStEeCostRound),
            };

            updatedItemList[itemIndex] = updatedItem;

            if (itemRow.qty > 0) {
                if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                    if (updatingItemSubGroup) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        )[0];
                        const subGroupIndex = updatedItemList.indexOf(subGroupRow);
                        const subGroupItems = getAllExistingSubGroupedItems(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );
                        const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

                        const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItemsQtfied, "totalPrice");
                        const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
                        const subGroupstEecost = calculateGroupWiseTotal(subGroupItemsQtfied, "stEecost");
                        const subGroupStFutureCost = calculateGroupWiseTotal(subGroupItemsQtfied, "stFutureCost");
                        const subGroupActCostExact = calculateGroupWiseTotal(subGroupItemsQtfied, "actCostExact");

                        // for margin calculations
                        const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
                        const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
                        const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupstEecost
                        );
                        const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupActCostExact
                        );
                        const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupStFutureCost
                        );

                        const updatedSubGroupRow = {
                            ...subGroupRow,
                            totalPrice: subGroupTotalPrice,
                            totalCost: calculateItemTotalCost(subGroupRow.qty, subGroupstEecost),
                            nettEA: subGroupNettEA,
                            margin: subGroupMargin,
                            stEecost: subGroupstEecost,
                            stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                            actMargin: subGroupActMargin,
                            frcMargin: subGroupFrcMargin,
                            stFutureCost: subGroupStFutureCost,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            actCostExact: subGroupActCostExact,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        };

                        updatedItemList[subGroupIndex] = updatedSubGroupRow;

                        //group level calculations
                        const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = updatedItemList.indexOf(groupRow);
                        const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
                        const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

                        let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

                        const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

                        // remove db extra for margin calculation
                        const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
                        const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

                        groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );
                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            totalPrice: groupTotalPrice,
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            stEecost: groupstEecost,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            actMargin: groupActMargin,
                            frcMargin: groupFrcMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            actCostExact: groupActCostExact,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    } else {
                        let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        let groupRowIndex = updatedItemList.indexOf(groupRow);
                        let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        subGroupRows = getQtyBasedValues(subGroupRows);

                        subGroupRows = getItemsWithoutAIandDX(subGroupRows);

                        let groupItems = [...groupRowItems, ...subGroupRows];

                        const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
                        const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
                        const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

                        // remove db extra for margin calculation
                        groupRowItems = getItemsWithoutAIandDX(groupRowItems);
                        groupItems = [...groupRowItems, ...subGroupRows];
                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

                        const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );
                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            totalPrice: groupTotalPrice,
                            totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
                            nettEA: groupNettEA,
                            margin: groupMargin,
                            stEecost: groupstEecost,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            actMargin: groupActMargin,
                            frcMargin: groupFrcMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            actCostExact: groupActCostExact,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }
                } else {
                    // nothing to handle for non group items so far
                }
            }

            // footer calculation
            const {
                totalPrice,
                totalSteEeCostRound,
                totalStFutureCost,
                totalActCostExact,
                totalListPrice,
                totalNettEA,
                totalCost,
                totalMargin,
                totalActMargin,
                totalFrcMargin,
                totalDcOnLp,
            } = footerCalculation(updatedItemList);

            setTotalItemPrice(totalPrice);
            setTotalCostAmt(totalCost);
            setTotalstEecostRound(totalSteEeCostRound);
            setTotalStFutureCost(totalStFutureCost);
            setTotalActCostEAExact(totalActCostExact);
            setTotalListPrice(totalListPrice);
            setTotalCost(totalCost);
            setTotalNettEA(totalNettEA);
            setMaxMargin(totalMargin);
            setActMargin(totalActMargin);
            setMaxFrcMargin(totalFrcMargin);
            setFooterDisc(totalDcOnLp);

            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        } else if (field === "actCost") {
            let updatedItemList = [...itemList];
            const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = updatedItemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
            let updatedActCost = 0;

            if (!isNaN(parseFloat(value))) {
                updatedActCost = parseFloat(parseFloat(value).toFixed(2));
            } else {
                updatedActCost = itemRow.actCost;
                toast.error("Please enter a valid number");
                return;
            }

            if (itemRow.qty > 0) {
                const updatedItem = {
                    ...itemRow,
                    actCost: updatedActCost,
                    actCostExact: updatedActCost,
                    actMargin: calculateItemActMargin(itemRow.nettEA, updatedActCost),
                };

                updatedItemList[itemIndex] = updatedItem;

                if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                    if (updatingItemSubGroup) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        )[0];
                        const subGroupIndex = updatedItemList.indexOf(subGroupRow);
                        const subGroupItems = getAllExistingSubGroupedItems(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );
                        const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

                        const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItemsQtfied, "totalPrice");
                        const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
                        const subGroupActCostExact = calculateGroupWiseTotal(subGroupItemsQtfied, "actCostExact");

                        // for margin calculations
                        const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
                        const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
                        const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupActCostExact
                        );

                        const updatedSubGroupRow = {
                            ...subGroupRow,
                            totalPrice: subGroupTotalPrice,
                            nettEA: subGroupNettEA,
                            actMargin: subGroupActMargin,
                            actCostExact: subGroupActCostExact,
                            actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                        };

                        updatedItemList[subGroupIndex] = updatedSubGroupRow;

                        //group level calculations
                        const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = updatedItemList.indexOf(groupRow);
                        const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
                        const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

                        let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

                        // remove db extra for margin calculation
                        const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
                        const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

                        groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: groupNettEA,
                            actMargin: groupActMargin,
                            actCostExact: groupActCostExact,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    } else {
                        let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        let groupRowIndex = updatedItemList.indexOf(groupRow);
                        let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        subGroupRows = getQtyBasedValues(subGroupRows);

                        subGroupRows = getItemsWithoutAIandDX(subGroupRows);

                        let groupItems = [...groupRowItems, ...subGroupRows];

                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

                        // remove db extra for margin calculation
                        groupRowItems = getItemsWithoutAIandDX(groupRowItems);
                        groupItems = [...groupRowItems, ...subGroupRows];
                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

                        const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                            groupNettEANonDX,
                            groupActCostExact
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: groupNettEA,
                            actMargin: groupActMargin,
                            actCostExact: groupActCostExact,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }
                } else {
                    // nothing to handle for non group items so far
                }
            }

            // footer calculation
            const {
                totalPrice,
                totalSteEeCostRound,
                totalStFutureCost,
                totalActCostExact,
                totalListPrice,
                totalNettEA,
                totalCost,
                totalMargin,
                totalActMargin,
                totalFrcMargin,
                totalDcOnLp,
            } = footerCalculation(updatedItemList);

            setTotalItemPrice(totalPrice);
            setTotalCostAmt(totalCost);
            setTotalstEecostRound(totalSteEeCostRound);
            setTotalStFutureCost(totalStFutureCost);
            setTotalActCostEAExact(totalActCostExact);
            setTotalListPrice(totalListPrice);
            setTotalCost(totalCost);
            setTotalNettEA(totalNettEA);
            setMaxMargin(totalMargin);
            setActMargin(totalActMargin);
            setMaxFrcMargin(totalFrcMargin);
            setFooterDisc(totalDcOnLp);

            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        } else if (field === "stFutureCostRound") {
            let updatedItemList = [...itemList];
            const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
            const itemRow = updatedItemList[itemIndex];
            const updatingItemGroup = itemRow.groupId;
            const updatingItemGroupName = itemRow.groupName;
            const updatingItemSubGroup = itemRow.subGroupId;
            const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
            const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
            let updatedStFutureCostRound = 0;

            if (!isNaN(parseFloat(value))) {
                updatedStFutureCostRound = parseFloat(parseFloat(value).toFixed(2));
            } else {
                updatedStFutureCostRound = itemRow.stFutureCostRound;
                toast.error("Please enter a valid number");
                return;
            }

            const updatedItem = {
                ...itemRow,
                stFutureCost: updatedStFutureCostRound,
                stFutureCostRound: updatedStFutureCostRound,
                frcMargin: calculateItemFrcMargin(itemRow.nettEA, updatedStFutureCostRound),
                lcMargin: 0,
            };

            updatedItemList[itemIndex] = updatedItem;

            if (itemRow.qty > 0) {
                if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
                    if (updatingItemSubGroup) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        )[0];
                        const subGroupIndex = updatedItemList.indexOf(subGroupRow);
                        const subGroupItems = getAllExistingSubGroupedItems(
                            updatedItemList,
                            updatingItemGroup,
                            updatingItemSubGroup
                        );
                        const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

                        const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
                        const subGroupStFutureCost = calculateGroupWiseTotal(subGroupItemsQtfied, "stFutureCost");

                        // for margin calculations
                        const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
                        const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
                        const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            subGroupNettEANonDX,
                            subGroupStFutureCost
                        );

                        const updatedSubGroupRow = {
                            ...subGroupRow,
                            nettEA: subGroupNettEA,
                            stFutureCost: subGroupStFutureCost,
                            stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                            frcMargin: subGroupFrcMargin,
                        };

                        updatedItemList[subGroupIndex] = updatedSubGroupRow;

                        //group level calculations
                        const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        const groupRowIndex = updatedItemList.indexOf(groupRow);
                        const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
                        const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

                        let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");

                        // remove db extra for margin calculation
                        const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
                        const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

                        groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: groupNettEA,
                            frcMargin: groupFrcMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    } else {
                        let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
                        let groupRowIndex = updatedItemList.indexOf(groupRow);
                        let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
                        let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
                        groupRowItems = getQtyBasedValues(groupRowItems);
                        subGroupRows = getQtyBasedValues(subGroupRows);

                        subGroupRows = getItemsWithoutAIandDX(subGroupRows);

                        let groupItems = [...groupRowItems, ...subGroupRows];

                        const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                        const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");

                        // remove db extra for margin calculation
                        groupRowItems = getItemsWithoutAIandDX(groupRowItems);
                        groupItems = [...groupRowItems, ...subGroupRows];
                        const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

                        const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                            groupNettEANonDX,
                            groupStFutureCost
                        );

                        const updatedGroupRow = {
                            ...groupRow,
                            nettEA: groupNettEA,
                            frcMargin: groupFrcMargin,
                            stFutureCost: groupStFutureCost,
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        };

                        updatedItemList[groupRowIndex] = updatedGroupRow;
                    }
                } else {
                }
            }

            // footer calculation
            const {
                totalPrice,
                totalSteEeCostRound,
                totalStFutureCost,
                totalActCostExact,
                totalListPrice,
                totalNettEA,
                totalCost,
                totalMargin,
                totalActMargin,
                totalFrcMargin,
                totalDcOnLp,
            } = footerCalculation(updatedItemList);

            setTotalItemPrice(totalPrice);
            setTotalCostAmt(totalCost);
            setTotalstEecostRound(totalSteEeCostRound);
            setTotalStFutureCost(totalStFutureCost);
            setTotalActCostEAExact(totalActCostExact);
            setTotalListPrice(totalListPrice);
            setTotalCost(totalCost);
            setTotalNettEA(totalNettEA);
            setMaxMargin(totalMargin);
            setActMargin(totalActMargin);
            setMaxFrcMargin(totalFrcMargin);
            setFooterDisc(totalDcOnLp);

            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        } else if (field === "groupLevelName") {
           const itemRow = itemList[itemIndex];
            let itemListUpdate = itemList;
            let updatedItem = {
                ...itemRow,
                groupLevelName: value,
                displayGroupName: itemRow.rowType === "G" ? value : itemRow.displayGroupName,
                displaySubGroupName: itemRow.rowType === "SG" ? value : itemRow.displaySubGroupName,
                displayAssemblyGroupName: itemRow.rowType === "A" ? value : itemRow.displayAssemblyGroupName,
                assemblyGroupName: itemRow.rowType === "A" ? value : itemRow.assemblyGroupName,
            };

            itemListUpdate[itemIndex] = updatedItem;

            setItemList(itemListUpdate);
            updateQuoteDetailsForId(id, { selectedItems: itemListUpdate }); 
            updateItemListForId(id, { items: [...(originalItems[id]?.items || []), ...itemListUpdate] });
        }

        //End - Added by Manoj on 2024-03-17
        //#region 
        // start - commented by Manoj on 2024-03-17
        // // else if (field === "margin") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = itemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        // //     let updatedMargin = -1;
        // //     let updatedItem = null;
        // //     let updateItemList = [];
        // //     updateItemList = [...itemList];
        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedMargin = parseFloat(parseFloat(value).toFixed(1));
        // //     } else {
        // //         updatedMargin = itemRow.margin;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (dataItem.rowType === "A") {
        // //         ///Computation applies to Assembly
        // //         const netEA = parseFloat(itemRow.stEecost / ((100 - value) / 100)).toFixed(2);
        // //         const margin = parseFloat(value).toFixed(1) + "%";
        // //         updatedItem = {
        // //             ...itemRow,
        // //             nettEA: netEA,
        // //             totalPrice: parseFloat(Number(itemRow.qty) * netEA).toFixed(2),
        // //             margin: margin,
        // //             actMargin: margin,
        // //             frcMargin: margin,
        // //         };

        // //         updateItemList[itemIndex] = updatedItem;
        // //     } else {
        // //         let marginGrpLvlChanged = false;
        // //         let itemsToProcess = [];
        // //         if (itemRow.rowType === "G") {
        // //             itemsToProcess = updateItemList.filter(
        // //                 (i) => i.groupId === itemRow.groupId && i.rowType !== "G"
        // //             );
        // //             marginGrpLvlChanged = true;
        // //         }
        // //         else if (itemRow.rowType === "SG") {
        // //             itemsToProcess = updateItemList.filter(
        // //                 (i) => i.subGroupId === itemRow.subGroupId && i.rowType !== "G" && i.rowType !== "SG"
        // //             );
        // //             marginGrpLvlChanged = true;
        // //         } else {
        // //             itemsToProcess = itemRow;
        // //         }

        // //         const updatedNettEA = parseFloat(
        // //             (parseFloat(100 * itemsToProcess.stEecost).toFixed(2) / (100 - updatedMargin)).toFixed(2)
        // //         );

        // //         if (marginGrpLvlChanged) {
        // //             itemsToProcess.forEach((item) => {
        // //                 const itmActualIdx = updateItemList.findIndex((i) => i.rowId === item.rowId);
        // //                 if (itmActualIdx > -1) {
        // //                     const updatedNettEA = parseFloat(
        // //                         (parseFloat(100 * item.stEecost).toFixed(2) / (100 - updatedMargin)).toFixed(2)
        // //                     );
        // //                     const updatedRepDisc = calculateItemRepDisc(item.nettEA, updatedNettEA);
        // //                     updatedItem = {
        // //                         ...item,
        // //                         margin: updatedMargin,
        // //                         nettEA: updatedNettEA,
        // //                         dcOnLp:
        // //                             calculateItemDcOnLp(updatedNettEA, item.listPrice) > 0
        // //                                 ? calculateItemDcOnLp(updatedNettEA, item.listPrice)
        // //                                 : 0,
        // //                         totalPrice: parseFloat((item.qty * updatedNettEA).toFixed(2)),
        // //                         repDisc: updatedRepDisc < 0 ? 0.0 : updatedRepDisc,
        // //                         actMargin: item.rowType === "DX" ? "" : parseFloat((((updatedNettEA - item.actCostExact) / updatedNettEA) * 100).toFixed(1)),
        // //                         frcMargin: item.rowType === "DX" ? "" : parseFloat((((updatedNettEA - item.stFutureCost) / updatedNettEA) * 100).toFixed(1)),
        // //                         lcMargin: item.rowType === "DX" ? "" : parseFloat((((updatedNettEA - item.lastCostExact) / updatedNettEA) * 100).toFixed(1)),
        // //                         lcMargin: "",
        // //                     };

        // //                     updateItemList[itmActualIdx] = updatedItem;
        // //                 }
        // //             });
        // //         }
        // //         else {

        // //             let updatedRepDisc = 0;
        // //             let ogNettEA =
        // //                 originalItems[id]?.items?.find((item) => item.stStockCode === itemsToProcess.stStockCode)?.nettEA ||
        // //                 originalItems[originalQuoteId]?.items?.find((item) => item.stStockCode === itemsToProcess.stStockCode)
        // //                     ?.nettEA;

        // //             if (rowMarginData[id] && rowMarginData[id][itemsToProcess.rowId]) {
        // //                 // updatedRepDisc = calculateItemRepDisc(rowMarginData[id][itemRow.rowId]?.nettEA, updatedNettEA);
        // //                 updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
        // //             } else {
        // //                 updateMarginRowForId(id, { [itemsToProcess.rowId]: itemsToProcess });

        // //                 if (rowNettEaData[id] && rowNettEaData[id][itemsToProcess.rowId]) {
        // //                     // const ogNettEA = rowNettEaData[id][itemRow.rowId]?.nettEA;

        // //                     if (ogNettEA !== itemsToProcess.nettEA) {
        // //                         updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
        // //                     }
        // //                 } else {
        // //                     updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
        // //                     // updatedRepDisc = calculateItemRepDisc(ogNettEA, updatedNettEA);
        // //                 }
        // //             }

        // //             updatedItem = {
        // //                 ...itemsToProcess,
        // //                 margin: updatedMargin,
        // //                 nettEA: updatedNettEA,
        // //                 dcOnLp:
        // //                     calculateItemDcOnLp(updatedNettEA, itemsToProcess.listPrice) > 0
        // //                         ? calculateItemDcOnLp(updatedNettEA, itemsToProcess.listPrice)
        // //                         : 0,
        // //                 totalPrice: parseFloat((itemsToProcess.qty * updatedNettEA).toFixed(2)),
        // //                 repDisc: updatedRepDisc < 0 ? 0.0 : updatedRepDisc,
        // //                 actMargin: parseFloat((((updatedNettEA - itemsToProcess.actCostExact) / updatedNettEA) * 100).toFixed(1)),
        // //                 frcMargin: parseFloat((((updatedNettEA - itemsToProcess.stFutureCost) / updatedNettEA) * 100).toFixed(1)),
        // //                 lcMargin: parseFloat((((updatedNettEA - itemsToProcess.lastCostExact) / updatedNettEA) * 100).toFixed(1)),
        // //                 //   lcMargin: "",
        // //             };
        // //             const itmActualIdx = itemList.findIndex((i) => i.rowId === itemsToProcess.rowId);
        // //             itemList[itmActualIdx] = updatedItem;
        // //         }
        // //     }

        // //     setItemList(updateItemList);
        // //     updateQuoteDetailsForId(id, { selectedItems: updateItemList });
        // //     setComputeTotals(true);
        // // } else if (field === "qty") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = itemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

        // //     let updatedQty = -1;
        // //     let updatedItem = null;
        // //     updatedQty = parseInt(value);

        // //     if (!isNaN(updatedQty)) {
        // //         if (updatedQty < 0) {
        // //             updatedQty = itemRow.qty;
        // //             toast.error("Please enter a valid number", {
        // //                 position: "top-center",
        // //                 transition: Slide,
        // //             });            
        // //             return;
        // //         } else updatedQty = parseInt(value);
        // //     } else {
        // //         updatedQty = itemRow.qty;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (dataItem.rowType === "AI") {
        // //         const AssemblyIndex = itemList.findIndex(
        // //             (item) => item.groupId === dataItem.groupId && item.rowType === "A"
        // //         );
        // //         const itemRowAssembyHdr = itemList[AssemblyIndex];

        // //         ///Computation applies to Assembly
        // //         updatedItem = {
        // //             ...itemRow,
        // //             qty: parseInt(value),
        // //             totalCost: parseFloat((itemRow.stEecostRound * parseInt(value)).toFixed(2)),
        // //             nettEA: "",
        // //             l60: "",
        // //             l55: "",
        // //             l50: "",
        // //             l20: "",
        // //             totalPrice: "",
        // //             margin: "",
        // //             actMargin: "",
        // //             frcMargin: "",
        // //             lcMargin: "",
        // //             dcOnLp: "",
        // //         };
        // //         itemList[itemIndex] = updatedItem;
        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: updatedItem });
        // //         //calculateLabourtotal(updatedItem, itemRowAssembyHdr, AssemblyIndex);
        // //         computeAssemblytotalByGroup(itemList);

        // //         triggerComputeGrpTotals(
        // //             [itemRowAssembyHdr],
        // //             itemList,
        // //             "update",
        // //             itemRow.groupId, //newItems[0].groupId,
        // //             itemRow.subGroupId, //sbgrpId,
        // //             dbExtraCharge
        // //         );
        // //         setLabourChanges(!labourChanges);

        // //     } else if (isAGroupRow || isASubGroupRow) {
        // //         updatedItem = {
        // //             ...itemRow,
        // //             qty: updatedQty,
        // //         };
        // //         itemList[itemIndex] = updatedItem;
        // //         const hasAssembly = itemList.some((item) => item.rowType === "A");
        // //         const updatedItems = hasAssembly ? computeAssemblytotalByGroup(itemList, true) : itemList;
        // //         setItemList(updatedItems);
        // //         updateQuoteDetailsForId(id, { selectedItems: updatedItems });
        // //         setComputeTotals(true);
        // //     } else {
        // //         //Manoj - started coding here on 18/10/2023
        // //         const prevNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
        // //         let newNettEa = 0.0;
        // //         let oItemNettEA = 0.0;

        // //         if (itemRow.rowType !== "G" && itemRow.rowType !== "SG") {
        // //             const oItem = originalItems[id]?.items?.filter((i) => i.stStockCode === itemRow.stStockCode)[0];
        // //             oItemNettEA = oItem?.nettEA;
        // //         } else {
        // //             oItemNettEA = itemRow.nettEA;
        // //         }

        // //         // const hasMarginChanged =
        // //         //     (rowMarginData[id] && rowMarginData[id][itemRow.rowId]?.margin !== itemRow.margin) ||
        // //         //     (originalItems[originalQuoteId] &&
        // //         //         originalItems[originalQuoteId]?.items &&
        // //         //         originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode)
        // //         //             .margin !== itemRow.nettEA);

        // //         // // const hasNettEAChanged =
        // //         //     (rowNettEaData[id] &&
        // //         //         rowNettEaData[id][itemRow.rowId] &&
        // //         //         rowNettEaData[id][itemRow.rowId]?.nettEA !== itemRow.nettEA) ||
        // //         //     (originalItems[originalQuoteId] &&
        // //         //         originalItems[originalQuoteId]?.items &&
        // //         //         originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode)
        // //         //             .nettEA !== itemRow.nettEA);

        // //         const originalMargin = originalItems[id]
        // //             ? originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode).margin
        // //             : originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode).margin;

        // //         const originalNettEA = originalItems[id]
        // //             ? originalItems[id]?.items.find((i) => i.stStockCode === itemRow.stStockCode).nettEA
        // //             : originalItems[originalQuoteId]?.items.find((i) => i.stStockCode === itemRow.stStockCode).nettEA;

        // //         const hasNettEAChanged = originalNettEA !== itemRow.nettEA;
        // //         const hasMarginChanged = originalMargin !== itemRow.margin;
        // //         const isACustomItem = itemRow.rowType === "CI";
        // //         let hasRepDiscChanged = false;

        // //         if (itemRow.rowType !== "G" && itemRow.rowType !== "SG") {
        // //             hasRepDiscChanged = originalItems[id]
        // //                 ? originalItems[id]?.items?.find((i) => i.stStockCode === itemRow.stStockCode).repDisc !==
        // //                 itemRow.repDisc
        // //                 : originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)
        // //                     .repDisc !== itemRow.repDisc;
        // //         }

        // //         if (itemRow.displayGroupName === "" && itemRow.displaySubGroupName === "" && itemRow.rowType !== "AI") {
        // //             //// itemRow.rowType !== "AI" added by john 10/26/2023 - skip the item for assembly
        // //             //to allow updating the qty in group/sub group rows
        // //             const val = await getResponse("Stocks/qtybreaknettea", {
        // //                 CustomerId: itemRow.customerId,
        // //                 partno: itemRow.stStockCode,
        // //                 qty: updatedQty,
        // //             });

        // //             const itemIdxAvl = nettEAChangedIndexs.find((i) => i === itemIndex);

        // //             if (hasMarginChanged || hasNettEAChanged || hasRepDiscChanged) {
        // //                 newNettEa = itemRow.nettEA;
        // //             } else if (val && val.statusCode === true) {
        // //                 newNettEa = parseFloat(parseFloat(val.data).toFixed(2));
        // //             } else if (itemIdxAvl !== undefined) {
        // //                 newNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
        // //             }

        // //             // if (itemIdxAvl !== undefined) {
        // //             //     newNettEa = parseFloat(parseFloat(itemRow.nettEA).toFixed(2));
        // //             // }
        // //             // if (hasMarginChanged || hasNettEAChanged || hasRepDiscChanged) {
        // //             //     newNettEa = itemRow.nettEA;
        // //             // }
        // //             // if (val && val.statusCode === true) {
        // //             //     newNettEa = parseFloat(parseFloat(val.data).toFixed(2));
        // //             // }
        // //         } else {
        // //             newNettEa = itemRow.nettEA;
        // //         }

        // //         //Manoj - ended coding here on 18/10/2023

        // //         if (
        // //             (rowQtyData && !rowQtyData[id]) ||
        // //             (rowQtyData && rowQtyData[id] && !rowQtyData[id][itemRow.rowId])
        // //         ) {
        // //             updateQtyRowForId(id, { [itemRow.rowId]: itemRow });

        // //             updatedItem = {
        // //                 ...itemRow,
        // //                 //manoj added on 18/10/2021
        // //                 nettEA:
        // //                     newNettEa > 0
        // //                         ? parseFloat(parseFloat(newNettEa).toFixed(2))
        // //                         : parseFloat(parseFloat(itemRow.nettEA).toFixed(2)),
        // //                 l60: itemRow.l60 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l60,
        // //                 l55: itemRow.l55 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l55,
        // //                 l50: itemRow.l50 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l50,
        // //                 l20: itemRow.l20 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l20,
        // //                 //end
        // //                 qty: updatedQty,
        // //                 totalPrice: calculateItemTotalPrice(updatedQty, newNettEa),
        // //                 totalCost: calculateItemTotalCost(updatedQty, itemRow?.stEecost),
        // //                 margin: parseFloat(parseFloat(itemRow?.margin).toFixed(1)),
        // //                 actMargin: parseFloat(parseFloat(itemRow?.actMargin).toFixed(1)),
        // //                 frcMargin: parseFloat(parseFloat(itemRow?.frcMargin).toFixed(1)),
        // //                 lcMargin: parseFloat(parseFloat(itemRow?.lcMargin === '' ? 0 : itemRow?.lcMargin).toFixed(1)),
        // //                 dcOnLp: parseFloat(parseFloat(itemRow?.dcOnLp).toFixed(1)),
        // //                 // repDisc:
        // //                 //     hasMarginChanged || hasNettEAChanged
        // //                 //         ? calculateItemRepDisc(oItem.nettEA, newNettEa) > 0
        // //                 //             ? calculateItemRepDisc(oItem.nettEA, newNettEa)
        // //                 //             : 0.0
        // //                 //         : itemRow.repDisc
        // //                 //         ? itemRow.repDisc
        // //                 //         : 0.0,
        // //                 repDisc:
        // //                     (hasMarginChanged || hasNettEAChanged) && !isACustomItem
        // //                         ? calculateItemRepDisc(oItemNettEA, newNettEa) > 0
        // //                             ? calculateItemRepDisc(oItemNettEA, newNettEa)
        // //                             : 0.0
        // //                         : itemRow.repDisc
        // //                             ? itemRow.repDisc
        // //                             : 0.0,
        // //             };
        // //         } else {
        // //             const qtyRow = rowQtyData[id][itemRow.rowId];

        // //             updatedItem = {
        // //                 ...qtyRow,
        // //                 //manoj added on 18/10/2021
        // //                 nettEA:
        // //                     newNettEa > 0
        // //                         ? parseFloat(parseFloat(newNettEa).toFixed(2))
        // //                         : parseFloat(parseFloat(qtyRow?.nettEA).toFixed(2)),

        // //                 l60: itemRow.l60 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l60,
        // //                 l55: itemRow.l55 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l55,
        // //                 l50: itemRow.l50 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l50,
        // //                 l20: itemRow.l20 === prevNettEa ? parseFloat(newNettEa).toFixed(2) : itemRow.l20,
        // //                 // end
        // //                 qty: updatedQty,
        // //                 totalPrice: calculateItemTotalPrice(updatedQty, newNettEa),
        // //                 totalCost: calculateItemTotalCost(updatedQty, qtyRow?.stEecost),
        // //                 margin: parseFloat(parseFloat(itemRow?.margin ? itemRow?.margin : qtyRow.margin).toFixed(1)),
        // //                 actMargin: parseFloat(parseFloat(qtyRow?.actMargin).toFixed(1)),
        // //                 frcMargin: parseFloat(parseFloat(qtyRow?.frcMargin).toFixed(1)),
        // //                 lcMargin: parseFloat(parseFloat(qtyRow?.lcMargin === '' ? 0 : qtyRow?.lcMargin).toFixed(1)),
        // //                 dcOnLp: parseFloat(parseFloat(itemRow?.dcOnLp ? itemRow?.dcOnLp : qtyRow?.dcOnLp).toFixed(1)),
        // //                 repDisc:
        // //                     (hasMarginChanged || hasNettEAChanged) && !isACustomItem
        // //                         ? calculateItemRepDisc(oItemNettEA, newNettEa) > 0
        // //                             ? calculateItemRepDisc(oItemNettEA, newNettEa)
        // //                             : 0.0
        // //                         : itemRow.repDisc
        // //                             ? itemRow.repDisc
        // //                             : 0.0,
        // //                 labCost: "",
        // //                 //Start - manoj commented this on 18/10/2021 to add the 4302
        // //                 //nettEA: itemRow?.nettEA ? itemRow?.nettEA : 0.0,
        // //                 //Start - manoj commented this on 18/10/2021
        // //             };
        // //         }
        // //         itemList[itemIndex] = updatedItem;

        // //         // triggerComputeGrpTotals(
        // //         //     [updatedItem],
        // //         //     itemList,
        // //         //     "update",
        // //         //     itemRow.groupId, //newItems[0].groupId,
        // //         //     itemRow.subGroupId, //sbgrpId,
        // //         //     dbExtraCharge
        // //         // );


        // //     }

        // //     //#region the checking of type !=="AI" was added to skip running for Assembly because the computation where already triggered by the function "triggerComputeGrpTotals". Perhaps this will also need to migrate to that function other process per column will also need to migrate specially with computation.
        // //     let updatedItemList = [...itemList];

        // //     const updatedItems = (dataItem.rowType === "A") ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
        // //     setItemList(updatedItems);
        // //     updateQuoteDetailsForId(id, { selectedItems: updatedItems });
        // //     setComputeTotals(true);

        // // } else if (field === "nettEA") {
        // //     setIsNettEAChanged(false);
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = itemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        // //     let updatedNettEA = -1;
        // //     let newRepDisc = -1;
        // //     let updatedItem = null;

        // //     if (itemRow.rowType === "CI" && itemRow.stEecostRound === 0) {
        // //         itemList[itemIndex] = itemRow;
        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //         toast.error("Please enter a costEA value before changing the nettEA value", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (itemRow.stStatus === null && itemRow.stEecostRound === 0) {
        // //         itemList[itemIndex] = itemRow;
        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //         toast.error("Please enter a costEA value before changing the nettEA value", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (itemRow.stStatus === "X" && itemRow.stEecostRound === 0) {
        // //         itemList[itemIndex] = itemRow;
        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //         toast.error("Please enter a costEA value before changing the nettEA value", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedNettEA = parseFloat(value);
        // //         setNettEAChangedIndexs([...nettEAChangedIndexs, itemIndex]);
        // //         setIsNettEAChanged(true);
        // //     } else {
        // //         updatedNettEA = itemRow.nettEA;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (dataItem.rowType === "A") {
        // //         ///Computation applies to Assembly
        // //         updatedItem = {
        // //             ...itemRow,
        // //             nettEA: parseFloat(value).toFixed(2),
        // //             totalPrice: parseFloat(value * itemRow.qty).toFixed(2),
        // //             margin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
        // //             actMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
        // //             frcMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
        // //             lcMargin: "",
        // //         };
        // //         itemList[itemIndex] = updatedItem;
        // //         setItemList(itemList);
        // //     } else {
        // //         let originalItemNettEA = 0;
        // //         //const originalItemList = originalItems[id];
        // //         const originalItemList = itemList;

        // //         if (originalItemList) {
        // //             // originalItemNettEA = originalItems[id]?.items?.filter(
        // //             //     (i) => i.stStockCode === itemRow.stStockCode
        // //             // );
        // //             originalItemNettEA = originalItemList?.filter(
        // //                 (i) => i.stStockCode === itemRow.stStockCode
        // //             );
        // //         } else {
        // //             // originalItemNettEA = originalItems[originalQuoteId]?.items?.filter(
        // //             //     (i) => i.stStockCode === itemRow.stStockCode
        // //             // );
        // //             originalItemNettEA = originalItemList?.filter(
        // //                 (i) => i.stStockCode === itemRow.stStockCode
        // //             );
        // //         }

        // //         let nettEAGrpLvlChanged = false;
        // //         if (typeof originalItemNettEA === "undefined") { //expected to be changing the group header then retrieve the items and update accordingly
        // //             if (itemRow.rowType === "G") {
        // //                 // originalItemNettEA = originalItems[id]?.items?.filter(
        // //                 //     (i) => i.groupId === itemRow.groupId
        // //                 // );
        // //                 originalItemNettEA = originalItemList?.filter(
        // //                     (i) => i.groupId === itemRow.groupId
        // //                 );
        // //                 nettEAGrpLvlChanged = true;
        // //             }
        // //             else if (itemRow.rowType === "SG") {
        // //                 // originalItemNettEA = originalItems[id]?.items?.filter(
        // //                 //     (i) => i.subGroupId === itemRow.subGroupId
        // //                 // );
        // //                 originalItemNettEA = originalItemList?.filter(
        // //                     (i) => i.subGroupId === itemRow.subGroupId
        // //                 );
        // //                 nettEAGrpLvlChanged = true;
        // //             }


        // //         }

        // //         originalItemNettEA.map((item) => {
        // //             item.nettEA = updatedNettEA;
        // //             let getNettEA = 0;
        // //             let listPrice = 0;
        // //             if (nettEAGrpLvlChanged) {
        // //                 getNettEA = parseFloat(((updatedNettEA * ((item.nettEA / itemRow.nettEA) * 100)) / 100).toFixed(2));
        // //                 newRepDisc = parseFloat((((item.nettEA - getNettEA) / item.nettEA) * 100).toFixed(1));
        // //             }
        // //             else {
        // //                 getNettEA = item.nettEA;
        // //                 if (rowNettEaData && rowNettEaData[id] && rowNettEaData[id][itemRow.rowId]) {
        // //                     // newRepDisc = calculateItemRepDisc(rowNettEaData[id][itemRow.rowId]?.nettEA, updatedNettEA);
        // //                     newRepDisc = calculateItemRepDisc(getNettEA, updatedNettEA);
        // //                 } else {
        // //                     // newRepDisc = calculateItemRepDisc(itemRow?.nettEA, updatedNettEA); --> should always be the original value, therefore will not affect
        // //                     newRepDisc = calculateItemRepDisc(getNettEA, updatedNettEA);
        // //                     updateNettEaRowForId(id, { [itemRow.rowId]: item });
        // //                 }
        // //             }


        // //             if (isASubGroupRow) {
        // //                 updateSGNettEAStatusForId(id, { [item.rowId]: { hasNettEAChanged: true } });
        // //             }

        // //             const newDcOnLp = calculateItemDcOnLp(getNettEA, item.listPrice);

        // //             updatedItem = {
        // //                 ...item,
        // //                 nettEA: value == "0" ? value : getNettEA,
        // //                 dcOnLp: newDcOnLp,
        // //                 totalPrice: parseFloat((item.qty * getNettEA).toFixed(2)),
        // //                 repDisc: newRepDisc > 0 ? newRepDisc : 0,
        // //                 margin: parseFloat(value) === 0.0 ? 0.0 : calculateItemMargin(getNettEA, item.stEecost),
        // //                 actMargin:
        // //                     parseFloat(value) === 0.0 ? 0.0 : calculateItemActMargin(getNettEA, item.actCostExact),
        // //                 frcMargin:
        // //                     parseFloat(value) === 0.0 ? 0.0 : calculateItemFrcMargin(getNettEA, item.stFutureCost),
        // //                 lcMargin:
        // //                     parseFloat(value) === 0.0 ? 0.0 : item.rowType === "CI" ? "" : calculateItemLcMargin(getNettEA, item.lastCostExact),
        // //                 //  lcMargin: "",
        // //             };
        // //             const itmActualIdx = itemList.findIndex((i) => i.rowId === item.rowId);
        // //             itemList[itmActualIdx] = updatedItem;
        // //         });
        // //     }

        // //     const updatedItems = (dataItem.rowType === "A") ? computeAssemblytotalByGroup(itemList, true) : itemList;
        // //     setItemList(updatedItems);
        // //     updateQuoteDetailsForId(id, { selectedItems: updatedItems });
        // //     setComputeTotals(true);

        // //     // if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //     //     if (updatingItemSubGroup && !isASubGroupRow) {
        // //     //         let updatedItemList = [...itemList];
        // //     //         //db extra calculation
        // //     //         const subGroupDbExtra = isDbExtraAdded(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup,
        // //     //             GroupTypes.SUB_GROUP
        // //     //         );

        // //     //         if (subGroupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 updatingItemSubGroup,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.SUB_GROUP
        // //     //             );

        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         let subGroupRows = getAllExistingSubGroupedItems(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup
        // //     //         );
        // //     //         subGroupRows = getListWithQtyBasedNettEA(subGroupRows);

        // //     //         const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
        // //     //         const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
        // //     //         const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
        // //     //         const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
        // //     //         const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
        // //     //         const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
        // //     //         // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
        // //     //         const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
        // //     //         const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
        // //     //         const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //     //             subGroupNettEA,
        // //     //             subGroupStFutureCost
        // //     //         );
        // //     //         const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //     //             subGroupNettEA,
        // //     //             subGroupActCostExact
        // //     //         );

        // //     //         const subGroup = getSubGroupRowByGroupAndSubGroup(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup
        // //     //         )[0];
        // //     //         const subGroupIndex = updatedItemList.indexOf(subGroup);

        // //     //         const updatedSubGroup = {
        // //     //             ...subGroup,
        // //     //             totalPrice: subGroupTotalPrice,
        // //     //             nettEA: subGroupNettEA,
        // //     //             dcOnLp: subGroupDcOnLp,
        // //     //             margin: subGroupMargin,
        // //     //             actMargin: subGroupActMargin,
        // //     //             frcMargin: subGroupFrcMargin,
        // //     //             stFutureCost: subGroupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
        // //     //             actCostExact: subGroupActCostExact,
        // //     //             actCost: parseFloat(subGroupActCostExact.toFixed(2)),
        // //     //             stEecost: subGroupstEecost,
        // //     //             stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
        // //     //             //lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[subGroupIndex] = updatedSubGroup;

        // //     //         // group db extra calculation
        // //     //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //         if (groupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 null,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.GROUP
        // //     //             );
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup);
        // //     //         const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
        // //     //         const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //         const uSubGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

        // //     //         const combinedList = [...groupRowList, ...uSubGroupRows];

        // //     //         const groupTotalPrice = calculateGroupWiseTotal(combinedList, "totalPrice");
        // //     //         const groupListPrice = calculateGroupWiseTotal(combinedList, "listPrice");
        // //     //         const groupNettEA = calculateGroupWiseTotal(combinedList, "nettEA");
        // //     //         const groupstEecost = calculateGroupWiseTotal(combinedList, "stEecost");
        // //     //         const groupStFutureCost = calculateGroupWiseTotal(combinedList, "stFutureCost");
        // //     //         const groupActCostExact = calculateGroupWiseTotal(combinedList, "actCostExact");
        // //     //         // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
        // //     //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
        // //     //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //     //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //     //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

        // //     //         const updatedGroupRow = {
        // //     //             ...groupRow[0],
        // //     //             totalPrice: groupTotalPrice,
        // //     //             nettEA: groupNettEA,
        // //     //             dcOnLp: groupDcOnLp,
        // //     //             margin: groupMargin,
        // //     //             actMargin: groupActMargin,
        // //     //             frcMargin: groupFrcMargin,
        // //     //             stFutureCost: groupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //     //             stEecost: groupstEecost,
        // //     //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //     //             actCostExact: groupActCostExact,
        // //     //             actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //     //             // lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[groupRowIndex] = updatedGroupRow;

        // //     //         // non group db extra calculation
        // //     //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //         if (nonGroupItems.length > 0) {
        // //     //             const nonGroupId = nonGroupItems[0].groupId;
        // //     //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //             if (nonGroupDbExtra) {
        // //     //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //     //                     updatedItemList,
        // //     //                     dbExtraCharge
        // //     //                 );
        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }
        // //     //         }

        // //     //         setItemList(updatedItemList);
        // //     //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //     //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalItemPrice(totalPrice);

        // //     //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalCost(totalCost);

        // //     //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalNettEA(totalneeta);

        // //     //         // const totalstEecostRound = calculateTotalstEecostRound(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalstEecostRound(totalstEecostRound);

        // //     //         // const totalStFutureCost = calculateTotalStFutureCost(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalStFutureCost(totalStFutureCost);

        // //     //         // const totalActCostEa = calculateTotalActCostEAExact(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalActCostEAExact(totalActCostEa);

        // //     //         // const totalListPrice = calculateTotalListPrice(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalListPrice(totalListPrice);

        // //     //         // footer calculation
        // //     //         const {
        // //     //             totalPrice,
        // //     //             totalSteEeCostRound,
        // //     //             totalStFutureCost,
        // //     //             totalActCostExact,
        // //     //             totalListPrice,
        // //     //             totalNettEA,
        // //     //             totalCost,
        // //     //             totalMargin,
        // //     //             totalActMargin,
        // //     //             totalFrcMargin,
        // //     //             totalDcOnLp,
        // //     //         } = footerCalculation(updatedItemList);

        // //     //         setTotalItemPrice(totalPrice);
        // //     //         setTotalstEecostRound(totalSteEeCostRound);
        // //     //         setTotalStFutureCost(totalStFutureCost);
        // //     //         setTotalActCostEAExact(totalActCostExact);
        // //     //         setTotalListPrice(totalListPrice);
        // //     //         setTotalCost(totalCost);
        // //     //         setTotalNettEA(totalNettEA);
        // //     //         setMaxMargin(totalMargin);
        // //     //         setActMargin(totalActMargin);
        // //     //         setMaxFrcMargin(totalFrcMargin);
        // //     //         setFooterDisc(totalDcOnLp);

        // //     //         if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //             updateFirstCustomerForId(id, {
        // //     //                 ...firstCustomer[id],
        // //     //                 item: updatedItemList[0],
        // //     //             });
        // //     //         }
        // //     //     } else if (updatingItemSubGroup && isASubGroupRow) {
        // //     //         const updatedItemList = [...itemList];
        // //     //         const prevSubGroupRow = itemRow;
        // //     //         const subGroupRow = updatedItem;
        // //     //         let dbExtraValue = 0;
        // //     //         let uNettEA = 0;
        // //     //         let pNettEA = 0;

        // //     //         const subGroupDbExtra = isDbExtraAdded(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup,
        // //     //             GroupTypes.SUB_GROUP
        // //     //         );

        // //     //         if (subGroupDbExtra) {
        // //     //             const dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, updatingItemSubGroup)[0];
        // //     //             const dbExtraRowIndex = getDbExtraRowIndex(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 updatingItemSubGroup,
        // //     //                 GroupTypes.SUB_GROUP
        // //     //             );
        // //     //             // dbExtraValue = dbExtraRow.nettEA;
        // //     //             dbExtraValue = Math.ceil(updatedNettEA / 2);
        // //     //             uNettEA = updatedNettEA - dbExtraValue;
        // //     //             pNettEA = prevSubGroupRow.nettEA - dbExtraRow.nettEA;

        // //     //             const updatedDbExtraRow = {
        // //     //                 ...dbExtraRow,
        // //     //                 nettEA: dbExtraValue,
        // //     //                 totalPrice: dbExtraValue,
        // //     //             };

        // //     //             updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
        // //     //         } else {
        // //     //             uNettEA = updatedNettEA;
        // //     //             pNettEA = prevSubGroupRow.nettEA;
        // //     //         }

        // //     //         const subGroupItems = getSubGroupItemsWithoutDBExtra(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup
        // //     //         );

        // //     //         const updatedSubGroupItems = subGroupItems.map((item) => {
        // //     //             let propotionalValue = -1;
        // //     //             let propotionalNettEa = -1;
        // //     //             let uRepDisc = -1;
        // //     //             let uMargin = -1;
        // //     //             let uFrcMargin = -1;
        // //     //             let uDcOnLp = -1;
        // //     //             let uTotalPrice = -1;
        // //     //             let uActMargin = -1;

        // //     //             propotionalValue = calculateProportion(item.nettEA, pNettEA);
        // //     //             propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);

        // //     //             if (sgList && sgList[id] && sgList[id][updatingItemSubGroup]) {
        // //     //                 let iList = sgList[id][updatingItemSubGroup];

        // //     //                 if (subGroupItems.length > iList.length) {
        // //     //                     iList = [...iList, ...subGroupItems.slice(iList.length, subGroupItems.length)];
        // //     //                 }

        // //     //                 const currentItem = iList.filter((i) => i.rowId === item.rowId)[0];
        // //     //                 // const originalItem = originalItems[id]?.items?.find(
        // //     //                 //     (i) => i.stStockCode === currentItem.stStockCode
        // //     //                 // );

        // //     //                 let originalItemNettEA = 0;
        // //     //                 const originalItemList = originalItems[id];

        // //     //                 if (originalItemList) {
        // //     //                     originalItemNettEA = originalItems[id]?.items?.find(
        // //     //                         (i) => i.stStockCode === currentItem.stStockCode
        // //     //                     )?.nettEA;
        // //     //                 } else {
        // //     //                     originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
        // //     //                         (i) => i.stStockCode === currentItem.stStockCode
        // //     //                     )?.nettEA;
        // //     //                 }

        // //     //                 if (
        // //     //                     currentItem.groupDbExtraId === "" &&
        // //     //                     currentItem.subGroupDbExtraId === "" &&
        // //     //                     currentItem.nonGroupDbExtraId === ""
        // //     //                 ) {
        // //     //                     // uRepDisc = calculateItemRepDisc(currentItem.nettEA, propotionalNettEa);
        // //     //                     uRepDisc = calculateItemRepDisc(originalItemNettEA, propotionalNettEa);
        // //     //                     uMargin = calculateItemMargin(propotionalNettEa, currentItem.stEecost);
        // //     //                     uFrcMargin = calculateItemFrcMargin(propotionalNettEa, currentItem.stFutureCost);
        // //     //                     uDcOnLp = calculateItemDcOnLp(propotionalNettEa, currentItem.listPrice);
        // //     //                     uTotalPrice = calculateItemTotalPrice(currentItem.qty, propotionalNettEa);
        // //     //                     uActMargin = calculateItemMargin(propotionalNettEa, currentItem.actCostExact);
        // //     //                 } else {
        // //     //                     uTotalPrice = item.totalPrice;
        // //     //                     uActMargin = "";
        // //     //                     uMargin = "";
        // //     //                     uFrcMargin = "";
        // //     //                     propotionalNettEa = currentItem.nettEA;
        // //     //                 }
        // //     //             } else {
        // //     //                 if (
        // //     //                     item.groupDbExtraId === "" &&
        // //     //                     item.subGroupDbExtraId === "" &&
        // //     //                     item.nonGroupDbExtraId === ""
        // //     //                 ) {
        // //     //                     // const originalItem = originalItems[id]?.items?.find(
        // //     //                     //     (i) => i.stStockCode === item.stStockCode
        // //     //                     // );

        // //     //                     let originalItemNettEA = 0;
        // //     //                     const originalItemList = originalItems[id];

        // //     //                     if (originalItemList) {
        // //     //                         originalItemNettEA = originalItems[id]?.items?.find(
        // //     //                             (i) => i.stStockCode === item.stStockCode
        // //     //                         )?.nettEA;
        // //     //                     } else {
        // //     //                         originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
        // //     //                             (i) => i.stStockCode === item.stStockCode
        // //     //                         )?.nettEA;
        // //     //                     }

        // //     //                     // uRepDisc = calculateItemRepDisc(item.nettEA, propotionalNettEa);
        // //     //                     uRepDisc = calculateItemRepDisc(originalItemNettEA, propotionalNettEa);
        // //     //                     uMargin = calculateItemMargin(propotionalNettEa, item.stEecost);
        // //     //                     uDcOnLp = calculateItemDcOnLp(propotionalNettEa, item.listPrice);
        // //     //                     uTotalPrice = calculateItemTotalPrice(item.qty, propotionalNettEa);
        // //     //                     uActMargin = calculateItemMargin(propotionalNettEa, item.actCostExact);
        // //     //                     uFrcMargin = calculateItemFrcMargin(propotionalNettEa, item.stFutureCost);
        // //     //                 } else {
        // //     //                     uTotalPrice = item.totalPrice;
        // //     //                     uActMargin = "";
        // //     //                     uMargin = "";
        // //     //                     uFrcMargin = "";
        // //     //                     propotionalNettEa = item.nettEA;
        // //     //                 }

        // //     //                 updateSubGroupListForId(id, { [updatingItemSubGroup]: subGroupItems });
        // //     //             }

        // //     //             return {
        // //     //                 ...item,
        // //     //                 nettEA: propotionalNettEa,
        // //     //                 repDisc: uRepDisc > 0 ? uRepDisc : 0,
        // //     //                 margin: uMargin,
        // //     //                 frcMargin: uFrcMargin,
        // //     //                 dcOnLp: uDcOnLp,
        // //     //                 totalPrice: uTotalPrice,
        // //     //                 actMargin: uActMargin,
        // //     //             };
        // //     //         });

        // //     //         updatedItemList.splice(itemIndex + 1, updatedSubGroupItems.length, ...updatedSubGroupItems);

        // //     //         // new implementation
        // //     //         let sgItems = getSubGroupedRowItems(updatedItemList, updatingItemGroup, updatingItemSubGroup);
        // //     //         // sgItems = getListWithQtyBasedNettEA(sgItems);
        // //     //         sgItems = getQtyBasedValues(sgItems);

        // //     //         const sgTotalPrice = calculateGroupWiseTotal(sgItems, "totalPrice");
        // //     //         const sgListPrice = calculateGroupWiseTotal(sgItems, "listPrice");
        // //     //         const sgStEecost = calculateGroupWiseTotal(sgItems, "stEecost");
        // //     //         const sgActCostExact = calculateGroupWiseTotal(sgItems, "actCostExact");
        // //     //         const sgNettEA = calculateGroupWiseTotal(sgItems, "nettEA");
        // //     //         const sgFutureCost = calculateGroupWiseTotal(sgItems, "stFutureCost");
        // //     //         const sgDcOnLp = calculateItemDcOnLp(sgNettEA, sgListPrice);

        // //     //         //remove db extra for margin calculations
        // //     //         sgItems = getItemsWithoutAIandDX(sgItems);
        // //     //         let sgNettEANonDX = calculateGroupWiseTotal(sgItems, "nettEA");
        // //     //         const sgMargin = calculateGroupWiseMarginForSNumberChange(sgNettEANonDX, sgStEecost);
        // //     //         const sgActMargin = calculateGroupWiseActMarginForSNumberChange(sgNettEANonDX, sgActCostExact);
        // //     //         const sgFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(sgNettEANonDX, sgFutureCost);

        // //     //         const updatedSubGroupRow = {
        // //     //             ...subGroupRow,
        // //     //             listPrice: sgListPrice,
        // //     //             stEecost: sgStEecost,
        // //     //             actCostExact: sgActCostExact,
        // //     //             nettEA: sgNettEA,
        // //     //             dcOnLp: sgDcOnLp,
        // //     //             margin: sgMargin,
        // //     //             frcMargin: sgFrcMargin,
        // //     //             actMargin: sgActMargin,
        // //     //             actCost: parseFloat(sgActCostExact.toFixed(2)),
        // //     //             stEecostRound: parseFloat(sgStEecost.toFixed(2)),
        // //     //             repDisc: 0,
        // //     //             stFutureCost: sgFutureCost,
        // //     //             stFutureCostRound: parseFloat(sgFutureCost.toFixed(2)),
        // //     //         };

        // //     //         updatedItemList[itemIndex] = updatedSubGroupRow;

        // //     //         // ref: group db extra calculation
        // //     //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //         if (groupDbExtra) {
        // //     //             // const dbExtraRow = getExistingGroupWiseDbExtraRow(updatedItemList, updatingItemGroup)[0];
        // //     //             // const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
        // //     //             // let listWithoutDbExtra = getItemsWithoutDBExtra(
        // //     //             //     updatedItemList,
        // //     //             //     GroupTypes.GROUP,
        // //     //             //     updatingItemGroup,
        // //     //             //     null
        // //     //             // );
        // //     //             // listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
        // //     //             // const groupNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
        // //     //             // const updatedGroupDbExtraNettEA = calculateDbExtra(dbExtraCharge, groupNettEA);

        // //     //             // const updatedDbExtraRow = {
        // //     //             //     ...dbExtraRow,
        // //     //             //     nettEA: updatedGroupDbExtraNettEA,
        // //     //             //     totalPrice: updatedGroupDbExtraNettEA,
        // //     //             // };

        // //     //             const { dbExtraRow, dbExtraRowIndex } = getQtyBasedGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 dbExtraCharge
        // //     //             );
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         //new implementation
        // //     //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //     //         const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //     //         let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //         let subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //     //         groupRowItems = getQtyBasedValues(groupRowItems);
        // //     //         // subGroupRowList = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);
        // //     //         // subGroupRowList = getQtyBasedValues(subGroupRowList);
        // //     //         subGroupRowList = getQtyBasedValuesWithGroups(subGroupRowList);

        // //     //         let groupItems = [...groupRowItems, ...subGroupRowList];

        // //     //         let gTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //     //         let gNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //     //         let gListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
        // //     //         let gstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //     //         let gActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
        // //     //         let gStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //     //         let gDcOnLp = calculateItemDcOnLp(gNettEA, gListPrice);

        // //     //         //remove db extra for margin related calculations
        // //     //         let groupItemsNonDX = getItemsWithoutAIandDX(groupRowItems);
        // //     //         let subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);
        // //     //         groupItemsNonDX = getQtyBasedValues(groupItemsNonDX);
        // //     //         subGroupRowsNonDX = getQtyBasedValuesWithGroups(subGroupRowsNonDX);

        // //     //         groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

        // //     //         const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
        // //     //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, gstEecost);
        // //     //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEANonDX, gActCostExact);
        // //     //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEANonDX, gStFutureCost);

        // //     //         const updatedGroupRow = {
        // //     //             ...groupRow,
        // //     //             totalPrice: gTotalPrice * groupRow.qty,
        // //     //             totalCost: calculateItemTotalCost(groupRow.qty, gstEecost),
        // //     //             nettEA: gNettEA,
        // //     //             listPrice: gListPrice,
        // //     //             actCostExact: gActCostExact,
        // //     //             stEecost: gstEecost,
        // //     //             stEecostRound: parseFloat(gstEecost.toFixed(2)),
        // //     //             actCost: parseFloat(gActCostExact.toFixed(2)),
        // //     //             stFutureCost: gStFutureCost,
        // //     //             stFutureCostRound: parseFloat(gStFutureCost.toFixed(2)),
        // //     //             dcOnLp: gDcOnLp,
        // //     //             margin: groupMargin,
        // //     //             actMargin: groupActMargin,
        // //     //             frcMargin: groupFrcMargin,
        // //     //             lcMargin: 0.0,
        // //     //         };

        // //     //         updatedItemList[groupRowIndex] = updatedGroupRow;

        // //     //         // non group db extra calculation
        // //     //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //         if (nonGroupItems.length > 0) {
        // //     //             const nonGroupId = nonGroupItems[0].groupId;
        // //     //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //             if (nonGroupDbExtra) {
        // //     //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //     //                     updatedItemList,
        // //     //                     dbExtraCharge
        // //     //                 );
        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }
        // //     //         }

        // //     //         // footer calculation
        // //     //         const {
        // //     //             totalPrice,
        // //     //             totalSteEeCostRound,
        // //     //             totalStFutureCost,
        // //     //             totalActCostExact,
        // //     //             totalListPrice,
        // //     //             totalNettEA,
        // //     //             totalCost,
        // //     //             totalMargin,
        // //     //             totalActMargin,
        // //     //             totalFrcMargin,
        // //     //             totalDcOnLp,
        // //     //         } = footerCalculation(updatedItemList);

        // //     //         setTotalItemPrice(totalPrice);
        // //     //         setTotalstEecostRound(totalSteEeCostRound);
        // //     //         setTotalStFutureCost(totalStFutureCost);
        // //     //         setTotalActCostEAExact(totalActCostExact);
        // //     //         setTotalListPrice(totalListPrice);
        // //     //         setTotalCost(totalCost);
        // //     //         setTotalNettEA(totalNettEA);
        // //     //         setMaxMargin(totalMargin);
        // //     //         setActMargin(totalActMargin);
        // //     //         setMaxFrcMargin(totalFrcMargin);
        // //     //         setFooterDisc(totalDcOnLp);

        // //     //         setItemList(updatedItemList);
        // //     //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // //     //     } else {
        // //     //         let updatedItemList = [...itemList];
        // //     //         // has a group id but no sub group id
        // //     //         //db extra calculation
        // //     //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //         if (groupDbExtra) {
        // //     //             const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
        // //     //             const dbExtraRowIndex = getDbExtraRowIndex(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 updatingItemSubGroup,
        // //     //                 GroupTypes.GROUP
        // //     //             );
        // //     //             const updatedDbExtraRow = calcNettEAChangeDbExtraRow(
        // //     //                 qtyBasedList,
        // //     //                 updatingItemGroup,
        // //     //                 updatingItemSubGroup,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.GROUP
        // //     //             );

        // //     //             updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
        // //     //         }

        // //     //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //     //         const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //     //         const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //         const updatedGroupRowList = groupRowList.map((item) => {
        // //     //             if (item.qty > 1) {
        // //     //                 return {
        // //     //                     ...item,
        // //     //                     nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
        // //     //                     stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
        // //     //                     stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
        // //     //                     actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
        // //     //                 };
        // //     //             }
        // //     //             return item;
        // //     //         });
        // //     //         const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

        // //     //         const groupItems = [...updatedGroupRowList, ...subGroupRows];

        // //     //         const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //     //         const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //     //         // const groupListPrice = calculateGroupWiseTotal(updatedGroupRowList, "listPrice");
        // //     //         const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //     //         const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //     //         const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
        // //     //         // const groupDcOnLp = calculateGroupDcOnLp(groupRow.listPrice, groupNettEA);
        // //     //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupRow.listPrice);
        // //     //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //     //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //     //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

        // //     //         const updatedGroupRow = {
        // //     //             ...groupRow,
        // //     //             totalPrice: groupTotalPrice,
        // //     //             nettEA: groupNettEA,
        // //     //             dcOnLp: groupDcOnLp,
        // //     //             margin: groupMargin,
        // //     //             actMargin: groupActMargin,
        // //     //             frcMargin: groupFrcMargin,
        // //     //             stFutureCost: groupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //     //             stEecost: groupstEecost,
        // //     //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //     //             actCostExact: groupActCostExact,
        // //     //             actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //     //             // lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[groupRowIndex] = updatedGroupRow;

        // //     //         // non group db extra calculation
        // //     //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //         if (nonGroupItems.length > 0) {
        // //     //             const nonGroupId = nonGroupItems[0].groupId;
        // //     //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //             if (nonGroupDbExtra) {
        // //     //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //     //                     updatedItemList,
        // //     //                     dbExtraCharge
        // //     //                 );
        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }
        // //     //         }

        // //     //         setItemList(updatedItemList);
        // //     //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //     //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalItemPrice(totalPrice);

        // //     //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalCost(totalCost);

        // //     //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalNettEA(totalneeta);

        // //     //         // const totalstEecostRound = calculateTotalstEecostRound(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalstEecostRound(totalstEecostRound);

        // //     //         // const totalStFutureCost = calculateTotalStFutureCost(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalStFutureCost(totalStFutureCost);

        // //     //         // const totalActCostEa = calculateTotalActCostEAExact(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalActCostEAExact(totalActCostEa);

        // //     //         // const totalListPrice = calculateTotalListPrice(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalListPrice(totalListPrice);

        // //     //         // footer calculation
        // //     //         const {
        // //     //             totalPrice,
        // //     //             totalSteEeCostRound,
        // //     //             totalStFutureCost,
        // //     //             totalActCostExact,
        // //     //             totalListPrice,
        // //     //             totalNettEA,
        // //     //             totalCost,
        // //     //             totalMargin,
        // //     //             totalActMargin,
        // //     //             totalFrcMargin,
        // //     //             totalDcOnLp,
        // //     //         } = footerCalculation(updatedItemList);

        // //     //         setTotalItemPrice(totalPrice);
        // //     //         setTotalstEecostRound(totalSteEeCostRound);
        // //     //         setTotalStFutureCost(totalStFutureCost);
        // //     //         setTotalActCostEAExact(totalActCostExact);
        // //     //         setTotalListPrice(totalListPrice);
        // //     //         setTotalCost(totalCost);
        // //     //         setTotalNettEA(totalNettEA);
        // //     //         setMaxMargin(totalMargin);
        // //     //         setActMargin(totalActMargin);
        // //     //         setMaxFrcMargin(totalFrcMargin);
        // //     //         setFooterDisc(totalDcOnLp);

        // //     //         if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //             updateFirstCustomerForId(id, {
        // //     //                 ...firstCustomer[id],
        // //     //                 item: updatedItemList[0],
        // //     //             });
        // //     //         }
        // //     //     }
        // //     // } else if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && isAGroupRow) {
        // //     //     const prevGroupRow = itemRow;
        // //     //     let updatedItemList = [...itemList];
        // //     //     let uNettEA = 0;
        // //     //     let pNettEA = 0;
        // //     //     let dbExtraValue = 0;

        // //     //     const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //     //GROUP LEVEL DB EXTRA CALCULATION
        // //     //     if (groupDbExtra) {
        // //     //         const dbExtraRow = getExistingGroupWiseDbExtraRow(updatedItemList, updatingItemGroup)[0];
        // //     //         const dbExtraRowIndex = updatedItemList.indexOf(dbExtraRow);
        // //     //         // dbExtraValue = dbExtraRow.nettEA;
        // //     //         // uNettEA = updatedNettEA - dbExtraValue;
        // //     //         dbExtraValue = Math.ceil(updatedNettEA / 2);
        // //     //         uNettEA = updatedNettEA - dbExtraValue;
        // //     //         pNettEA = prevGroupRow.nettEA - dbExtraRow.nettEA;

        // //     //         const updatedDbExtraRow = {
        // //     //             ...dbExtraRow,
        // //     //             nettEA: dbExtraValue,
        // //     //             totalPrice: dbExtraValue,
        // //     //         };

        // //     //         updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
        // //     //     } else {
        // //     //         uNettEA = updatedNettEA;
        // //     //         pNettEA = prevGroupRow.nettEA;
        // //     //     }

        // //     //     const groupRowItemsWithoutDbExtra = getGroupedRowItemsWithoutDBExtra(
        // //     //         updatedItemList,
        // //     //         updatingItemGroup
        // //     //     );
        // //     //     const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

        // //     //     const updatedSubGroupRows = subGroupRows.map((item) => {
        // //     //         const sgId = item.subGroupId;
        // //     //         const sgItems = getSubGroupItemsWithoutDBExtra(updatedItemList, updatingItemGroup, sgId);
        // //     //         const sgNettEA = calculateGroupWiseTotal(sgItems, "nettEA");
        // //     //         const sgTotalPrice = calculateGroupWiseTotal(sgItems, "totalPrice");

        // //     //         return {
        // //     //             ...item,
        // //     //             nettEA: sgNettEA,
        // //     //             totalPrice: sgTotalPrice,
        // //     //         };
        // //     //     });

        // //     //     const combinedList = [...groupRowItemsWithoutDbExtra, ...updatedSubGroupRows];

        // //     //     const uItemList = combinedList.map((item) => {
        // //     //         //let propotionalValue = calculateProportion(item.nettEA, itemRow.nettEA - dbExtraValue);
        // //     //         // const originalQuoteId = quoteDetails[id]?.savedQuote?.originalQuoteId;
        // //     //         let propotionalValue = calculateProportion(item.nettEA, pNettEA);
        // //     //         let propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);
        // //     //         let originalItemNettEA = 0;
        // //     //         let originalItemList = originalItems[id];

        // //     //         if (originalItemList) {
        // //     //             originalItemNettEA = originalItems[id]?.items?.find(
        // //     //                 (i) => i.stStockCode === item.stStockCode
        // //     //             )?.nettEA;
        // //     //         } else {
        // //     //             originalItemNettEA = originalItems[originalQuoteId]?.items?.find(
        // //     //                 (i) => i.stStockCode === item.stStockCode
        // //     //             )?.nettEA;
        // //     //         }

        // //     //         return {
        // //     //             ...item,
        // //     //             nettEA: propotionalNettEa,
        // //     //             totalPrice: propotionalNettEa,
        // //     //             margin: calculateItemMargin(propotionalNettEa, item.stEecost),
        // //     //             repDisc:
        // //     //                 calculateItemRepDisc(originalItemNettEA, propotionalNettEa) > 0
        // //     //                     ? calculateItemRepDisc(originalItemNettEA, propotionalNettEa)
        // //     //                     : 0,
        // //     //             lcMargin: calculateItemLcMargin(propotionalNettEa, item.lastCostExact),
        // //     //             frcMargin: calculateItemFrcMargin(propotionalNettEa, item.stFutureCost),
        // //     //             actMargin: calculateItemMargin(propotionalNettEa, item.actCostExact),
        // //     //             dcOnLp: calculateItemDcOnLp(propotionalNettEa, item.listPrice),
        // //     //         };
        // //     //     });

        // //     //     uItemList.forEach((item) => {
        // //     //         const itemIndex = updatedItemList.findIndex((i) => i.rowId === item.rowId);
        // //     //         updatedItemList[itemIndex] = item;
        // //     //     });

        // //     //     if (subGroupRows.length > 0) {
        // //     //         let uSgItemList = [];

        // //     //         subGroupRows.forEach((subGroupRow) => {
        // //     //             let sgId = subGroupRow.subGroupId;
        // //     //             let sgNettEA = 0;
        // //     //             let sgDbExtraValue = 0;
        // //     //             let dbExtraRow = null;
        // //     //             let dbExtraRowIndex = -1;

        // //     //             const hasDbExtraAdded = isDbExtraAdded(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 sgId,
        // //     //                 GroupTypes.SUB_GROUP
        // //     //             );

        // //     //             if (hasDbExtraAdded) {
        // //     //                 dbExtraRow = getExistingSubGroupWiseDbExtraRow(updatedItemList, sgId)[0];
        // //     //                 dbExtraRowIndex = getDbExtraRowIndex(
        // //     //                     updatedItemList,
        // //     //                     updatingItemGroup,
        // //     //                     sgId,
        // //     //                     GroupTypes.SUB_GROUP
        // //     //                 );
        // //     //                 sgDbExtraValue = dbExtraRow.nettEA;
        // //     //                 sgNettEA = subGroupRow.nettEA - sgDbExtraValue;
        // //     //             } else {
        // //     //                 sgNettEA = subGroupRow.nettEA;
        // //     //             }

        // //     //             const sgItems = getSubGroupItemsWithoutDBExtra(updatedItemList, updatingItemGroup, sgId);
        // //     //             const uNettEA = updatedItemList.filter((i) => i.subGroupId === sgId)[0]?.nettEA;

        // //     //             uSgItemList = sgItems.map((s) => {
        // //     //                 let propotionalValue = -1;
        // //     //                 let propotionalNettEa = -1;
        // //     //                 let uRepDisc = -1;
        // //     //                 let uMargin = -1;
        // //     //                 let uFrcMargin = -1;
        // //     //                 let uDcOnLp = -1;
        // //     //                 let uTotalPrice = -1;
        // //     //                 let uActMargin = -1;
        // //     //                 let originalNettEA = -1;

        // //     //                 propotionalValue = calculateProportion(s.nettEA, sgNettEA);
        // //     //                 propotionalNettEa = calculatePropotionalNettEA(propotionalValue, uNettEA);
        // //     //                 originalNettEA = originalItems[id]
        // //     //                     ? originalItems[id]?.items?.find((i) => i.rowId === s.rowId)?.nettEA
        // //     //                     : originalItems[originalQuoteId]?.items?.find((i) => i.rowId === s.rowId)?.nettEA;

        // //     //                 if (s.groupDbExtraId === "" && s.subGroupDbExtraId === "" && s.nonGroupDbExtraId === "") {
        // //     //                     // identifies a item row
        // //     //                     // uRepDisc = calculateItemRepDisc(s.nettEA, propotionalNettEa);
        // //     //                     uRepDisc = calculateItemRepDisc(originalNettEA, propotionalNettEa);
        // //     //                     uMargin = calculateItemMargin(propotionalNettEa, s.stEecost);
        // //     //                     uFrcMargin = calculateItemFrcMargin(propotionalNettEa, s.stFutureCost);
        // //     //                     uDcOnLp = calculateItemDcOnLp(propotionalNettEa, s.listPrice);
        // //     //                     uTotalPrice = calculateItemTotalPrice(s.qty, propotionalNettEa);
        // //     //                     uActMargin = calculateItemMargin(propotionalNettEa, s.actCostExact);
        // //     //                 } else {
        // //     //                     // identifies db extra row
        // //     //                     uTotalPrice = s.totalPrice;
        // //     //                     uActMargin = "";
        // //     //                     uMargin = "";
        // //     //                     uFrcMargin = "";
        // //     //                     propotionalNettEa = s.nettEA;
        // //     //                 }

        // //     //                 return {
        // //     //                     ...s,
        // //     //                     nettEA: propotionalNettEa,
        // //     //                     repDisc: uRepDisc > 0 ? uRepDisc : 0,
        // //     //                     margin: uMargin,
        // //     //                     frcMargin: uFrcMargin,
        // //     //                     dcOnLp: uDcOnLp,
        // //     //                     totalPrice: uTotalPrice,
        // //     //                     actMargin: uActMargin,
        // //     //                     //   lcMargin: "",
        // //     //                 };
        // //     //             });

        // //     //             uSgItemList.forEach((item) => {
        // //     //                 const itemIndex = updatedItemList.findIndex((i) => i.rowId === item.rowId);
        // //     //                 updatedItemList[itemIndex] = item;
        // //     //             });

        // //     //             if (hasDbExtraAdded) {
        // //     //                 const updatedNettEA = calculateGroupWiseTotal(uSgItemList, "nettEA");
        // //     //                 const dbExtra = calculateDbExtra(dbExtraCharge, updatedNettEA);

        // //     //                 dbExtraRow = {
        // //     //                     ...dbExtraRow,
        // //     //                     nettEA: dbExtra,
        // //     //                     totalPrice: dbExtra,
        // //     //                 };

        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }

        // //     //             //ONLY UPDATE THE SUB GROUP ROWS. UPDATING THE GROUP ROW WILL AFFECT GROUP LEVEL CALCULATION
        // //     //             const sgItms = getAllExistingSubGroupedItems(updatedItemList, updatingItemGroup, sgId);
        // //     //             const nettEAAU = calculateGroupWiseTotal(sgItms, "nettEA");
        // //     //             const listPriceAU = calculateGroupWiseTotal(sgItms, "listPrice");
        // //     //             const stEecostAU = calculateGroupWiseTotal(sgItms, "stEecost");
        // //     //             const stFutureCostAU = calculateGroupWiseTotal(sgItms, "stFutureCost");
        // //     //             const actCostExactAU = calculateGroupWiseTotal(sgItms, "actCostExact");
        // //     //             const totalPrice = calculateGroupWiseTotal(sgItms, "totalPrice");
        // //     //             const totalCost = calculateGroupWiseTotal(sgItms, "totalCost");
        // //     //             // const dcOnLpAU = calculateGroupDcOnLp(listPriceAU, nettEAAU);
        // //     //             const dcOnLpAU = calculateItemDcOnLp(nettEAAU, listPriceAU);
        // //     //             const marginAU = calculateGroupWiseMarginForSNumberChange(nettEAAU, stEecostAU);
        // //     //             const frcMarginAU = calculateGroupWiseFrcMarginForSNumberChange(nettEAAU, stFutureCostAU);
        // //     //             const actMarginAU = calculateGroupWiseActMarginForSNumberChange(nettEAAU, actCostExactAU);

        // //     //             const updatedSubGroupRow = {
        // //     //                 ...subGroupRow,
        // //     //                 nettEA: nettEAAU,
        // //     //                 listPrice: listPriceAU,
        // //     //                 stEecost: stEecostAU,
        // //     //                 stEecostRound: parseFloat(stEecostAU.toFixed(2)),
        // //     //                 stFutureCost: stFutureCostAU,
        // //     //                 stFutureCostRound: parseFloat(stFutureCostAU.toFixed(2)),
        // //     //                 actCostExact: actCostExactAU,
        // //     //                 actCost: parseFloat(actCostExactAU.toFixed(2)),
        // //     //                 dcOnLp: dcOnLpAU,
        // //     //                 margin: marginAU,
        // //     //                 frcMargin: frcMarginAU,
        // //     //                 actMargin: actMarginAU,
        // //     //                 totalPrice: totalPrice * subGroupRow.qty,
        // //     //                 // totalCost: totalCost * subGroupRow.qty,
        // //     //                 totalCost: calculateItemTotalCost(subGroupRow.qty, stEecostAU),
        // //     //                 //lcMargin: "",
        // //     //             };

        // //     //             const subGroupRowIndex = updatedItemList.findIndex((s) => s.rowId === subGroupRow.rowId);
        // //     //             updatedItemList[subGroupRowIndex] = updatedSubGroupRow;
        // //     //         });
        // //     //     }

        // //     //     //GROUP ROW CALCULATIONS WITHOUT VERTICALs
        // //     //     const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //     const updatedGroupRowItems = groupRowItems.map((item) => {
        // //     //         if (item.qty > 1) {
        // //     //             return {
        // //     //                 ...item,
        // //     //                 stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
        // //     //             };
        // //     //         }
        // //     //         return item;
        // //     //     });
        // //     //     const subGroups = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //     //     const groupItems = [...updatedGroupRowItems, ...subGroups];

        // //     //     const groupStEecost = calculateGroupWiseTotal(groupItems, "stEecost");

        // //     //     const updatedGroupRow = {
        // //     //         ...updatedItem,
        // //     //         repDisc: 0,
        // //     //         lcMargin: 0,
        // //     //         margin: calculateGroupWiseMarginForSNumberChange(updatedItem.nettEA, groupStEecost),
        // //     //         // dcOnLp:
        // //     //         //     calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA) > 0
        // //     //         //         ? calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA)
        // //     //         //         : 0,
        // //     //         dcOnLp:
        // //     //             calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA) > 0
        // //     //                 ? calculateGroupDcOnLp(updatedItem.listPrice, updatedItem.nettEA)
        // //     //                 : 0,
        // //     //     };

        // //     //     updatedItemList[itemIndex] = updatedGroupRow;

        // //     //     // non group db extra calculation
        // //     //     const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //     if (nonGroupItems.length > 0) {
        // //     //         const nonGroupId = nonGroupItems[0].groupId;
        // //     //         const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //         if (nonGroupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }
        // //     //     }

        // //     //     setItemList(updatedItemList);
        // //     //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //     //     // footer calculation
        // //     //     const {
        // //     //         totalPrice,
        // //     //         totalSteEeCostRound,
        // //     //         totalStFutureCost,
        // //     //         totalActCostExact,
        // //     //         totalListPrice,
        // //     //         totalNettEA,
        // //     //         totalCost,
        // //     //         totalMargin,
        // //     //         totalActMargin,
        // //     //         totalFrcMargin,
        // //     //         totalDcOnLp,
        // //     //     } = footerCalculation(updatedItemList);

        // //     //     setTotalItemPrice(totalPrice);
        // //     //     setTotalstEecostRound(totalSteEeCostRound);
        // //     //     setTotalStFutureCost(totalStFutureCost);
        // //     //     setTotalActCostEAExact(totalActCostExact);
        // //     //     setTotalListPrice(totalListPrice);
        // //     //     setTotalCost(totalCost);
        // //     //     setTotalNettEA(totalNettEA);
        // //     //     setMaxMargin(totalMargin);
        // //     //     setActMargin(totalActMargin);
        // //     //     setMaxFrcMargin(totalFrcMargin);
        // //     //     setFooterDisc(totalDcOnLp);

        // //     //     // const totalPrice = calculateTotalPrice(updatedItemList);
        // //     //     // setTotalItemPrice(totalPrice);

        // //     //     // const totalCost = calculateTotalCost(updatedItemList);
        // //     //     // setTotalCost(totalCost);

        // //     //     // const totalneeta = calculateTotalNettEA(updatedItemList);
        // //     //     // setTotalNettEA(totalneeta);

        // //     //     // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
        // //     //     // setTotalstEecostRound(totalstEecostRound);

        // //     //     // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
        // //     //     // setTotalStFutureCost(totalStFutureCost);

        // //     //     // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
        // //     //     // setTotalActCostEAExact(totalActCostEa);

        // //     //     // const totalListPrice = calculateTotalListPrice(updatedItemList);
        // //     //     // setTotalListPrice(totalListPrice);
        // //     // } else {
        // //     //     let updatedItemList = [...itemList];
        // //     //     //db extra calculation
        // //     //     // const nonGroupDbExtra = isDbExtraAdded(itemList, updatingItemGroup, null, GroupTypes.NON_GROUP);

        // //     //     // if (nonGroupDbExtra) {
        // //     //     //     const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
        // //     //     //     const dbExtraRowIndex = getDbExtraRowIndex(
        // //     //     //         updatedItemList,
        // //     //     //         updatingItemGroup,
        // //     //     //         updatingItemSubGroup,
        // //     //     //         GroupTypes.NON_GROUP
        // //     //     //     );
        // //     //     //     const updatedDbExtraRow = calcNettEAChangeDbExtraRow(
        // //     //     //         qtyBasedList,
        // //     //     //         updatingItemGroup,
        // //     //     //         updatingItemSubGroup,
        // //     //     //         dbExtraCharge,
        // //     //     //         GroupTypes.NON_GROUP
        // //     //     //     );
        // //     //     //     updatedItemList[dbExtraRowIndex] = updatedDbExtraRow;
        // //     //     // }

        // //     //     const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //     if (nonGroupItems.length > 0) {
        // //     //         const nonGroupId = nonGroupItems[0].groupId;
        // //     //         const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //         if (nonGroupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }
        // //     //     }

        // //     //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // //     //     setItemList(updatedItemList);

        // //     //     // const totalPrice = calculateTotalPrice(updatedItemList);
        // //     //     // setTotalItemPrice(totalPrice);

        // //     //     // const totalCost = calculateTotalCost(updatedItemList);
        // //     //     // setTotalCost(totalCost);

        // //     //     // const totalneeta = calculateTotalNettEA(updatedItemList);
        // //     //     // setTotalNettEA(totalneeta);

        // //     //     // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
        // //     //     // setTotalstEecostRound(totalstEecostRound);

        // //     //     // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
        // //     //     // setTotalStFutureCost(totalStFutureCost);

        // //     //     // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
        // //     //     // setTotalActCostEAExact(totalActCostEa);

        // //     //     // const totalListPrice = calculateTotalListPrice(updatedItemList);
        // //     //     // setTotalListPrice(totalListPrice);

        // //     //     // footer calculation
        // //     //     const {
        // //     //         totalPrice,
        // //     //         totalSteEeCostRound,
        // //     //         totalStFutureCost,
        // //     //         totalActCostExact,
        // //     //         totalListPrice,
        // //     //         totalNettEA,
        // //     //         totalCost,
        // //     //         totalMargin,
        // //     //         totalActMargin,
        // //     //         totalFrcMargin,
        // //     //         totalDcOnLp,
        // //     //     } = footerCalculation(updatedItemList);

        // //     //     setTotalItemPrice(totalPrice);
        // //     //     setTotalstEecostRound(totalSteEeCostRound);
        // //     //     setTotalStFutureCost(totalStFutureCost);
        // //     //     setTotalActCostEAExact(totalActCostExact);
        // //     //     setTotalListPrice(totalListPrice);
        // //     //     setTotalCost(totalCost);
        // //     //     setTotalNettEA(totalNettEA);
        // //     //     setMaxMargin(totalMargin);
        // //     //     setActMargin(totalActMargin);
        // //     //     setMaxFrcMargin(totalFrcMargin);
        // //     //     setFooterDisc(totalDcOnLp);

        // //     //     if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //         updateFirstCustomerForId(id, {
        // //     //             ...firstCustomer[id],
        // //     //             item: updatedItemList[0],
        // //     //         });
        // //     //     }
        // //     // }
        // //     //#endregion
        // // } else if (field === "dcOnLp") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = itemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

        // //     let updatedDcOnLp = -1;
        // //     let prevNettEA =
        // //         originalItems[id]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)?.nettEA ||
        // //         originalItems[originalQuoteId]?.items?.find((i) => i.stStockCode === itemRow.stStockCode)?.nettEA;
        // //     let newNettEa = -1;
        // //     let newRepDisc = -1;

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedDcOnLp = parseFloat(value);
        // //     } else {
        // //         updatedDcOnLp = itemRow.dcOnLp;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (rowDconlpData[id] && rowDconlpData[id][itemRow.rowId]) {
        // //         const prevRow = rowDconlpData[id][itemRow.rowId];
        // //         // prevNettEA = prevRow?.nettEA;
        // //         newNettEa = calculateNettEaForDConLP(prevRow.listPrice, updatedDcOnLp);
        // //         newRepDisc = calculateItemRepDisc(prevNettEA, newNettEa);
        // //     } else {
        // //         updateDconlpRowForId(id, { [itemRow.rowId]: itemRow });
        // //         // prevNettEA = itemRow?.nettEA;
        // //         newNettEa = calculateNettEaForDConLP(itemRow.listPrice, updatedDcOnLp);
        // //         newRepDisc = calculateItemRepDisc(prevNettEA, newNettEa);
        // //     }

        // //     const updatedItem = {
        // //         ...itemRow,
        // //         dcOnLp: parseFloat(updatedDcOnLp.toFixed(1)),
        // //         nettEA: newNettEa,
        // //         totalPrice: newNettEa * itemRow.qty,
        // //         repDisc: newRepDisc > 0 ? newRepDisc : 0,
        // //         totalCost: parseFloat(itemRow.qty * itemRow.stEecostRound),
        // //         margin:
        // //             newNettEa <= 0.0
        // //                 ? 0.0
        // //                 : parseFloat((((newNettEa - itemRow?.stEecost) / newNettEa) * 100).toFixed(1)),
        // //         actMargin: newNettEa <= 0.0 ? 0.0 : calculateItemActMargin(newNettEa, itemRow?.actCostExact),
        // //         frcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemFrcMargin(newNettEa, itemRow?.stFutureCost),
        // //         lcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemLcMargin(newNettEa, itemRow?.lastCostExact),
        // //         //  lcMargin: "",
        // //     };

        // //     itemList[itemIndex] = updatedItem;

        // //     if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //         if (updatingItemSubGroup && !isASubGroupRow) {
        // //             let updatedItemList = [...itemList];

        // //             //sub group level db extra calculations
        // //             const subGroupDbExtra = isDbExtraAdded(
        // //                 updatedItemList,
        // //                 updatingItemGroup,
        // //                 updatingItemSubGroup,
        // //                 GroupTypes.SUB_GROUP
        // //             );

        // //             if (subGroupDbExtra) {
        // //                 const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup,
        // //                     dbExtraCharge,
        // //                     GroupTypes.SUB_GROUP
        // //                 );

        // //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //             }

        // //             const subGroupRows = getAllExistingSubGroupedItems(
        // //                 updatedItemList,
        // //                 updatingItemGroup,
        // //                 updatingItemSubGroup
        // //             );

        // //             const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
        // //             const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
        // //             const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
        // //             const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
        // //             const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
        // //             const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
        // //             // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
        // //             const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
        // //             const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
        // //             const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                 subGroupNettEA,
        // //                 subGroupStFutureCost
        // //             );
        // //             const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                 subGroupNettEA,
        // //                 subGroupActCostExact
        // //             );

        // //             const subGroup = getSubGroupRowByGroupAndSubGroup(
        // //                 updatedItemList,
        // //                 updatingItemGroup,
        // //                 updatingItemSubGroup
        // //             )[0];

        // //             const subGroupIndex = getSubGroupRowIndex(updatedItemList, updatingItemGroup, updatingItemSubGroup);

        // //             const updatedSubGroup = {
        // //                 ...subGroup,
        // //                 totalPrice: subGroupTotalPrice,
        // //                 nettEA: subGroupNettEA,
        // //                 dcOnLp: subGroupDcOnLp,
        // //                 margin: subGroupMargin,
        // //                 actMargin: subGroupActMargin,
        // //                 frcMargin: subGroupFrcMargin,
        // //                 stFutureCost: subGroupStFutureCost,
        // //                 stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
        // //                 stEecost: subGroupstEecost,
        // //                 stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
        // //                 actCostExact: subGroupActCostExact,
        // //                 actCost: parseFloat(subGroupActCostExact.toFixed(2)),
        // //                 // lcMargin: "",
        // //             };

        // //             updatedItemList[subGroupIndex] = updatedSubGroup;

        // //             //group level db extra calculations
        // //             const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //             if (groupDbExtra) {
        // //                 const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     null,
        // //                     dbExtraCharge,
        // //                     GroupTypes.GROUP
        // //                 );

        // //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //             }

        // //             const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //             const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
        // //             const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //             const subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

        // //             const groupRowList = [...groupRowItems, ...subGroupRowList];

        // //             const groupTotalPrice = calculateGroupWiseTotal(groupRowList, "totalPrice");
        // //             const groupNettEA = calculateGroupWiseTotal(groupRowList, "nettEA");
        // //             const groupListPrice = calculateGroupWiseTotal(groupRowList, "listPrice");
        // //             const groupstEecost = calculateGroupWiseTotal(groupRowList, "stEecost");
        // //             const groupStFutureCost = calculateGroupWiseTotal(groupRowList, "stFutureCost");
        // //             const groupActCostExact = calculateGroupWiseTotal(groupRowList, "actCostExact");
        // //             // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
        // //             const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);
        // //             const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //             const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //             const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

        // //             const updatedGroupRow = {
        // //                 ...groupRow,
        // //                 totalPrice: groupTotalPrice,
        // //                 nettEA: groupNettEA,
        // //                 dcOnLp: groupDcOnLp,
        // //                 margin: groupMargin,
        // //                 actMargin: groupActMargin,
        // //                 frcMargin: groupFrcMargin,
        // //                 stFutureCost: groupStFutureCost,
        // //                 stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                 stEecost: groupstEecost,
        // //                 stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //                 actCostExact: groupActCostExact,
        // //                 actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 // lcMargin: "",
        // //             };

        // //             updatedItemList[groupRowIndex] = updatedGroupRow;

        // //             // non group db extra calculation
        // //             const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //             if (nonGroupItems.length > 0) {
        // //                 const nonGroupId = nonGroupItems[0].groupId;
        // //                 const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //                 if (nonGroupDbExtra) {
        // //                     const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //                         updatedItemList,
        // //                         dbExtraCharge
        // //                     );
        // //                     updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //                 }
        // //             }

        // //             setItemList(updatedItemList);
        // //             updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //             // footer calculation
        // //             const {
        // //                 totalPrice,
        // //                 totalSteEeCostRound,
        // //                 totalStFutureCost,
        // //                 totalActCostExact,
        // //                 totalListPrice,
        // //                 totalNettEA,
        // //                 totalCost,
        // //                 totalMargin,
        // //                 totalActMargin,
        // //                 totalFrcMargin,
        // //                 totalDcOnLp,
        // //             } = footerCalculation(updatedItemList);

        // //             setTotalItemPrice(totalPrice);
        // //             setTotalstEecostRound(totalSteEeCostRound);
        // //             setTotalStFutureCost(totalStFutureCost);
        // //             setTotalActCostEAExact(totalActCostExact);
        // //             setTotalListPrice(totalListPrice);
        // //             setTotalCost(totalCost);
        // //             setTotalNettEA(totalNettEA);
        // //             setMaxMargin(totalMargin);
        // //             setActMargin(totalActMargin);
        // //             setMaxFrcMargin(totalFrcMargin);
        // //             setFooterDisc(totalDcOnLp);

        // //             // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalItemPrice(totalPrice);

        // //             // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalCost(totalCost);

        // //             // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalNettEA(totalneeta);

        // //             // const totalstEecostRound = calculateTotalstEecostRound(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalstEecostRound(totalstEecostRound);

        // //             // const totalStFutureCost = calculateTotalStFutureCost(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalStFutureCost(totalStFutureCost);

        // //             // const totalActCostEa = calculateTotalActCostEAExact(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalActCostEAExact(totalActCostEa);

        // //             // const totalListPrice = calculateTotalListPrice(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalListPrice(totalListPrice);

        // //             if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //                 updateFirstCustomerForId(id, {
        // //                     ...firstCustomer[id],
        // //                     item: updatedItemList[0],
        // //                 });
        // //             }
        // //         } else {
        // //             const updatedItemList = [...itemList];

        // //             //group level db extra calculation
        // //             const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //             if (groupDbExtra) {
        // //                 const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     null,
        // //                     dbExtraCharge,
        // //                     GroupTypes.GROUP
        // //                 );

        // //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //             }

        // //             const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //             const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //             const groupRowList = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //             const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //             const updatedGroupRowList = groupRowList.map((s) => {
        // //                 if (s.qty > 1) {
        // //                     return {
        // //                         ...s,
        // //                         listPrice: calculateValuesOnSBLQtyChange(s.qty, s.listPrice),
        // //                         stEecost: calculateValuesOnSBLQtyChange(s.qty, s.stEecost),
        // //                         stEecostRound: calculateValuesOnSBLQtyChange(s.qty, s.stEecostRound),
        // //                         stFutureCost: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCost),
        // //                         stFutureCostRound: calculateValuesOnSBLQtyChange(s.qty, s.stFutureCostRound),
        // //                         actCostExact: calculateValuesOnSBLQtyChange(s.qty, s.actCostExact),
        // //                         actCost: calculateValuesOnSBLQtyChange(s.qty, s.actCost),
        // //                         nettEA: calculateValuesOnSBLQtyChange(s.qty, s.nettEA),
        // //                     };
        // //                 }
        // //                 return s;
        // //             });

        // //             const groupItems = [...updatedGroupRowList, ...subGroupRows];

        // //             const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //             const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //             const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
        // //             const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //             const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //             const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
        // //             const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
        // //             const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //             const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //             const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

        // //             const updatedGroupRow = {
        // //                 ...groupRow,
        // //                 listPrice: groupListPrice,
        // //                 totalPrice: calculateValuesOnSBLQtyChange(groupRow.qty, groupTotalPrice),
        // //                 nettEA: groupNettEA,
        // //                 dcOnLp: groupDcOnLp,
        // //                 margin: groupMargin,
        // //                 actMargin: groupActMargin,
        // //                 frcMargin: groupFrcMargin,
        // //                 stFutureCost: groupStFutureCost,
        // //                 stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                 stEecost: groupstEecost,
        // //                 stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //                 actCostExact: groupActCostExact,
        // //                 actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 //  lcMargin: "",
        // //             };

        // //             updatedItemList[groupRowIndex] = updatedGroupRow;

        // //             // non group db extra calculation
        // //             const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //             if (nonGroupItems.length > 0) {
        // //                 const nonGroupId = nonGroupItems[0].groupId;
        // //                 const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //                 if (nonGroupDbExtra) {
        // //                     const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //                         updatedItemList,
        // //                         dbExtraCharge
        // //                     );
        // //                     updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //                 }
        // //             }

        // //             setItemList(updatedItemList);
        // //             updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //             // footer calculation
        // //             const {
        // //                 totalPrice,
        // //                 totalSteEeCostRound,
        // //                 totalStFutureCost,
        // //                 totalActCostExact,
        // //                 totalListPrice,
        // //                 totalNettEA,
        // //                 totalCost,
        // //                 totalMargin,
        // //                 totalActMargin,
        // //                 totalFrcMargin,
        // //                 totalDcOnLp,
        // //             } = footerCalculation(updatedItemList);

        // //             setTotalItemPrice(totalPrice);
        // //             setTotalstEecostRound(totalSteEeCostRound);
        // //             setTotalStFutureCost(totalStFutureCost);
        // //             setTotalActCostEAExact(totalActCostExact);
        // //             setTotalListPrice(totalListPrice);
        // //             setTotalCost(totalCost);
        // //             setTotalNettEA(totalNettEA);
        // //             setMaxMargin(totalMargin);
        // //             setActMargin(totalActMargin);
        // //             setMaxFrcMargin(totalFrcMargin);
        // //             setFooterDisc(totalDcOnLp);

        // //             // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalItemPrice(totalPrice);

        // //             // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalCost(totalCost);

        // //             // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //             // setTotalNettEA(totalneeta);

        // //             // const totalstEecostRound = calculateTotalstEecostRound(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalstEecostRound(totalstEecostRound);

        // //             // const totalStFutureCost = calculateTotalStFutureCost(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalStFutureCost(totalStFutureCost);

        // //             // const totalActCostEa = calculateTotalActCostEAExact(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalActCostEAExact(totalActCostEa);

        // //             // const totalListPrice = calculateTotalListPrice(
        // //             //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //             // );
        // //             // setTotalListPrice(totalListPrice);

        // //             if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //                 updateFirstCustomerForId(id, {
        // //                     ...firstCustomer[id],
        // //                     item: updatedItemList[0],
        // //                 });
        // //             }
        // //         }
        // //     } else {
        // //         let updatedItemList = [...itemList];
        // //         const nonGroupId = updatedItemList.length > 0 && updatedItemList[0].groupId;
        // //         const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //         if (nonGroupDbExtra) {
        // //             const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);

        // //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //         }

        // //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // //         setItemList(updatedItemList);

        // //         // footer calculation
        // //         const {
        // //             totalPrice,
        // //             totalSteEeCostRound,
        // //             totalStFutureCost,
        // //             totalActCostExact,
        // //             totalListPrice,
        // //             totalNettEA,
        // //             totalCost,
        // //             totalMargin,
        // //             totalActMargin,
        // //             totalFrcMargin,
        // //             totalDcOnLp,
        // //         } = footerCalculation(updatedItemList);

        // //         setTotalItemPrice(totalPrice);
        // //         setTotalstEecostRound(totalSteEeCostRound);
        // //         setTotalStFutureCost(totalStFutureCost);
        // //         setTotalActCostEAExact(totalActCostExact);
        // //         setTotalListPrice(totalListPrice);
        // //         setTotalCost(totalCost);
        // //         setTotalNettEA(totalNettEA);
        // //         setMaxMargin(totalMargin);
        // //         setActMargin(totalActMargin);
        // //         setMaxFrcMargin(totalFrcMargin);
        // //         setFooterDisc(totalDcOnLp);

        // //         // const totalPrice = calculateTotalPrice(updatedItemList);
        // //         // setTotalItemPrice(totalPrice);

        // //         // const totalCost = calculateTotalCost(updatedItemList);
        // //         // setTotalCost(totalCost);

        // //         // const totalneeta = calculateTotalNettEA(updatedItemList);
        // //         // setTotalNettEA(totalneeta);

        // //         // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
        // //         // setTotalstEecostRound(totalstEecostRound);

        // //         // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
        // //         // setTotalStFutureCost(totalStFutureCost);

        // //         // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
        // //         // setTotalActCostEAExact(totalActCostEa);

        // //         // const totalListPrice = calculateTotalListPrice(updatedItemList);
        // //         // setTotalListPrice(totalListPrice);

        // //         if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //             updateFirstCustomerForId(id, {
        // //                 ...firstCustomer[id],
        // //                 item: updatedItemList[0],
        // //             });
        // //         }
        // //     }
        // // } else if (field === "repDisc") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = itemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";

        // //     let updatedRepDiscount = -1;

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedRepDiscount = parseFloat(parseFloat(value).toFixed(2));
        // //     } else {
        // //         updatedRepDiscount = itemRow.repDisc;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     const updatedRepDisc = parseFloat(updatedRepDiscount.toFixed(1));
        // //     let newNettEa = -1;
        // //     let updatedItem = null;

        // //     if (previousMainCustomer.current !== mainCustomer || !newCustomerStockDetails.current) {
        // //         //get the original nettEA to be used on the computation
        // //         // const getNettEA = originalItems[id]?.items.filter((f) => f.rowId === itemRow.rowId);

        // //         const ogList = originalItems[id];
        // //         let getNettEA = 0;
        // //         let origNettEA = 0;

        // //         if (ogList) {
        // //             getNettEA = ogList?.items.filter((f) => f.stStockCode === itemRow.stStockCode);
        // //             origNettEA = getNettEA?.length > 0 ? parseFloat(getNettEA[0].nettEA) : itemRow?.nettEA;
        // //         } else {
        // //             const originalQuoteId = quoteDetails[id]?.savedQuote?.originalQuoteId;
        // //             getNettEA = originalItems[originalQuoteId]?.items.filter(
        // //                 (f) => f.stStockCode === itemRow.stStockCode
        // //             );
        // //             origNettEA = getNettEA?.length > 0 ? parseFloat(getNettEA[0].nettEA) : itemRow?.nettEA;
        // //         }

        // //         if (rowData && rowData[id] && rowData[id][itemRow.rowId]) {
        // //             newNettEa = calculateNettEaForRepDiscChange(origNettEA, updatedRepDisc);
        // //         } else {
        // //             updateRowForId(id, { [itemRow.rowId]: itemRow });
        // //             newNettEa = calculateNettEaForRepDiscChange(origNettEA, updatedRepDisc);
        // //         }
        // //     } else {
        // //         updateRowForId(id, { [itemRow.rowId]: itemRow });
        // //         newNettEa = calculateNettEaForRepDiscChangeAfterCustomerChange(itemRow?.stStockCode, updatedRepDisc);
        // //     }

        // //     if (updatedRepDisc === 0.0 || updatedRepDisc > 0.0) {
        // //         updatedItem = {
        // //             ...itemRow,
        // //             repDisc: updatedRepDisc,
        // //             totalCost: parseFloat(itemRow.qty * itemRow.stEecostRound),
        // //             nettEA: newNettEa,
        // //             totalPrice: parseFloat((itemRow?.qty * newNettEa).toFixed(2)),
        // //             // dcOnLp: parseFloat((((itemRow?.listPrice - newNettEa) / itemRow?.listPrice) * 100).toFixed(1)),
        // //             dcOnLp: calculateItemDcOnLp(newNettEa, itemRow?.listPrice),
        // //             margin:
        // //                 newNettEa <= 0.0
        // //                     ? 0.0
        // //                     : parseFloat((((newNettEa - itemRow?.stEecost) / newNettEa) * 100).toFixed(1)),
        // //             actMargin: newNettEa <= 0.0 ? 0.0 : calculateItemActMargin(newNettEa, itemRow?.actCostExact),
        // //             frcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemFrcMargin(newNettEa, itemRow?.stFutureCost),
        // //             lcMargin: newNettEa <= 0.0 ? 0.0 : calculateItemLcMargin(newNettEa, itemRow?.lastCostExact),
        // //             //  lcMargin: "",
        // //         };
        // //         itemList[itemIndex] = updatedItem;
        // //     } else {
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     //Temporarily commented to avoid the wrong calculation
        // //     // if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //     //     if (updatingItemSubGroup && !isASubGroupRow) {
        // //     //         let updatedItemList = [...itemList];

        // //     //         //sub group level db extra calculations
        // //     //         const subGroupDbExtra = isDbExtraAdded(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup,
        // //     //             GroupTypes.SUB_GROUP
        // //     //         );

        // //     //         if (subGroupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 updatingItemSubGroup,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.SUB_GROUP
        // //     //             );

        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         let subGroupRows = getAllExistingSubGroupedItems(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup
        // //     //         );
        // //     //         subGroupRows = getQtyBasedValues(subGroupRows);

        // //     //         const subGroupTotalPrice = calculateGroupWiseTotal(subGroupRows, "totalPrice");
        // //     //         const subGroupListPrice = calculateGroupWiseTotal(subGroupRows, "listPrice");
        // //     //         const subGroupNettEA = calculateGroupWiseTotal(subGroupRows, "nettEA");
        // //     //         const subGroupstEecost = calculateGroupWiseTotal(subGroupRows, "stEecost");
        // //     //         const subGroupStFutureCost = calculateGroupWiseTotal(subGroupRows, "stFutureCost");
        // //     //         const subGroupActCostExact = calculateGroupWiseTotal(subGroupRows, "actCostExact");
        // //     //         // const subGroupDcOnLp = calculateGroupDcOnLp(subGroupListPrice, subGroupNettEA);
        // //     //         const subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);

        // //     //         // remove db extra for margin calculations
        // //     //         subGroupRows = getItemsWithoutAIandDX(subGroupRows);
        // //     //         const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupRows, "nettEA");
        // //     //         // const subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
        // //     //         const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
        // //     //             subGroupNettEANonDX,
        // //     //             subGroupstEecost
        // //     //         );
        // //     //         // const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //     //         //     subGroupNettEA,
        // //     //         //     subGroupStFutureCost
        // //     //         // );
        // //     //         const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //     //             subGroupNettEANonDX,
        // //     //             subGroupStFutureCost
        // //     //         );
        // //     //         // const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //     //         //     subGroupNettEA,
        // //     //         //     subGroupActCostExact
        // //     //         // );
        // //     //         const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //     //             subGroupNettEANonDX,
        // //     //             subGroupActCostExact
        // //     //         );

        // //     //         const subGroup = getSubGroupRowByGroupAndSubGroup(
        // //     //             updatedItemList,
        // //     //             updatingItemGroup,
        // //     //             updatingItemSubGroup
        // //     //         );

        // //     //         const subGroupIndex = getSubGroupRowIndex(updatedItemList, updatingItemGroup, updatingItemSubGroup);

        // //     //         const updatedSubGroup = {
        // //     //             ...subGroup[0],
        // //     //             totalPrice: subGroupTotalPrice,
        // //     //             nettEA: subGroupNettEA,
        // //     //             dcOnLp: subGroupDcOnLp,
        // //     //             margin: subGroupMargin,
        // //     //             actMargin: subGroupActMargin,
        // //     //             frcMargin: subGroupFrcMargin,
        // //     //             stFutureCost: subGroupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
        // //     //             stEecost: subGroupstEecost,
        // //     //             stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
        // //     //             actCostExact: subGroupActCostExact,
        // //     //             actCost: parseFloat(subGroupActCostExact.toFixed(2)),
        // //     //             //lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[subGroupIndex] = updatedSubGroup;

        // //     //         //group level db extra calculations
        // //     //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //         if (groupDbExtra) {
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 null,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.GROUP
        // //     //             );

        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         //group level calculations
        // //     //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup);
        // //     //         const groupRowIndex = getGroupRowIndex(updatedItemList, updatingItemGroup);
        // //     //         let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //         let subGroupRowList = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //     //         groupRowItems = getQtyBasedValues(groupRowItems);
        // //     //         subGroupRowList = getQtyBasedValues(subGroupRowList);

        // //     //         let groupRowList = [...groupRowItems, ...subGroupRowList];

        // //     //         const groupTotalPrice = calculateGroupWiseTotal(groupRowList, "totalPrice");
        // //     //         const groupListPrice = calculateGroupWiseTotal(groupRowList, "listPrice");
        // //     //         const groupNettEA = calculateGroupWiseTotal(groupRowList, "nettEA");
        // //     //         const groupstEecost = calculateGroupWiseTotal(groupRowList, "stEecost");
        // //     //         const groupStFutureCost = calculateGroupWiseTotal(groupRowList, "stFutureCost");
        // //     //         const groupActCostExact = calculateGroupWiseTotal(groupRowList, "actCostExact");
        // //     //         // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
        // //     //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

        // //     //         //remove db extra for margin calculations
        // //     //         groupRowItems = getItemsWithoutAIandDX(groupRowItems);
        // //     //         subGroupRowList = getSubGroupsWithoutDX(subGroupRowList, updatedItemList);

        // //     //         groupRowList = [...groupRowItems, ...subGroupRowList];

        // //     //         const groupNettEANonDX = calculateGroupWiseTotal(groupRowList, "nettEA");
        // //     //         // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //     //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
        // //     //         // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //     //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //     //             groupNettEANonDX,
        // //     //             groupStFutureCost
        // //     //         );
        // //     //         // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
        // //     //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //     //             groupNettEANonDX,
        // //     //             groupActCostExact
        // //     //         );

        // //     //         const updatedGroupRow = {
        // //     //             ...groupRow[0],
        // //     //             totalPrice: groupTotalPrice,
        // //     //             nettEA: groupNettEA,
        // //     //             dcOnLp: groupDcOnLp,
        // //     //             margin: groupMargin,
        // //     //             actMargin: groupActMargin,
        // //     //             frcMargin: groupFrcMargin,
        // //     //             stFutureCost: groupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //     //             stEecost: groupstEecost,
        // //     //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //     //             actCostExact: groupActCostExact,
        // //     //             actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //     //             // lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[groupRowIndex] = updatedGroupRow;

        // //     //         // non group db extra calculation
        // //     //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //         if (nonGroupItems.length > 0) {
        // //     //             const nonGroupId = nonGroupItems[0].groupId;
        // //     //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //             if (nonGroupDbExtra) {
        // //     //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //     //                     updatedItemList,
        // //     //                     dbExtraCharge
        // //     //                 );
        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }
        // //     //         }

        // //     //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalItemPrice(totalPrice);

        // //     //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalCost(totalCost);

        // //     //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalNettEA(totalneeta);

        // //     //         // const totalstEecostRound = calculateTotalstEecostRound(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalstEecostRound(totalstEecostRound);

        // //     //         // const totalStFutureCost = calculateTotalStFutureCost(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalStFutureCost(totalStFutureCost);

        // //     //         // const totalActCostEa = calculateTotalActCostEAExact(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalActCostEAExact(totalActCostEa);

        // //     //         // const totalListPrice = calculateTotalListPrice(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalListPrice(totalListPrice);

        // //     //         // footer calculation
        // //     //         const {
        // //     //             totalPrice,
        // //     //             totalSteEeCostRound,
        // //     //             totalStFutureCost,
        // //     //             totalActCostExact,
        // //     //             totalListPrice,
        // //     //             totalNettEA,
        // //     //             totalCost,
        // //     //             totalMargin,
        // //     //             totalActMargin,
        // //     //             totalFrcMargin,
        // //     //             totalDcOnLp,
        // //     //         } = footerCalculation(updatedItemList);

        // //     //         setTotalItemPrice(totalPrice);
        // //     //         setTotalstEecostRound(totalSteEeCostRound);
        // //     //         setTotalStFutureCost(totalStFutureCost);
        // //     //         setTotalActCostEAExact(totalActCostExact);
        // //     //         setTotalListPrice(totalListPrice);
        // //     //         setTotalCost(totalCost);
        // //     //         setTotalNettEA(totalNettEA);
        // //     //         setMaxMargin(totalMargin);
        // //     //         setActMargin(totalActMargin);
        // //     //         setMaxFrcMargin(totalFrcMargin);
        // //     //         setFooterDisc(totalDcOnLp);

        // //     //         setItemList(updatedItemList);
        // //     //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //     //         if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //             updateFirstCustomerForId(id, {
        // //     //                 ...firstCustomer[id],
        // //     //                 item: updatedItemList[0],
        // //     //             });
        // //     //         }
        // //     //     } else {
        // //     //         let updatedItemList = [...itemList];

        // //     //         //group db extra calculation
        // //     //         const groupDbExtra = isDbExtraAdded(updatedItemList, updatingItemGroup, null, GroupTypes.GROUP);

        // //     //         if (groupDbExtra) {
        // //     //             // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getUpdatedGroupSubGroupDbExtraRow(
        // //     //                 updatedItemList,
        // //     //                 updatingItemGroup,
        // //     //                 null,
        // //     //                 dbExtraCharge,
        // //     //                 GroupTypes.GROUP
        // //     //             );
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }

        // //     //         const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //     //         const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //     //         const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //     //         // const updatedGroupRowItems = groupRowItems.map((item) => {
        // //     //         //     if (item.qty > 1) {
        // //     //         //         return {
        // //     //         //             ...item,
        // //     //         //             nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
        // //     //         //             stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
        // //     //         //             stEecostRound: calculateValuesOnSBLQtyChange(item.qty, item.stEecostRound),
        // //     //         //             stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
        // //     //         //             stFutureCostRound: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound),
        // //     //         //             actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
        // //     //         //             actCost: calculateValuesOnSBLQtyChange(item.qty, item.actCost),
        // //     //         //         };
        // //     //         //     }
        // //     //         //     return item;
        // //     //         // });
        // //     //         const updatedGroupRowItems = getQtyBasedValues(groupRowItems);
        // //     //         const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);

        // //     //         let groupItems = [...updatedGroupRowItems, ...subGroupRows];

        // //     //         const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //     //         const groupListPrice = calculateGroupWiseTotal(groupItems, "listPrice");
        // //     //         const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //     //         //  const groupNettEA = calculateNettEaForRepDiscChange(origNettEA,updatedRepDisc);
        // //     //         const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //     //         const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //     //         const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");
        // //     //         // const groupDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEA);
        // //     //         const groupDcOnLp = calculateItemDcOnLp(groupNettEA, groupListPrice);

        // //     //         //remove db extra for margin calculations
        // //     //         // const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
        // //     //         // const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
        // //     //         // const groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);

        // //     //         const groupRowItemsNonDX = getItemsWithoutAIandDX(updatedGroupRowItems);
        // //     //         const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRows, updatedItemList);

        // //     //         groupItems = [...groupRowItemsNonDX, ...subGroupRowsNonDX];

        // //     //         const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

        // //     //         const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
        // //     //         const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //     //             groupNettEANonDX,
        // //     //             groupStFutureCost
        // //     //         );
        // //     //         const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //     //             groupNettEANonDX,
        // //     //             groupActCostExact
        // //     //         );

        // //     //         const updatedGroupRow = {
        // //     //             ...groupRow,
        // //     //             totalPrice: calculateValuesOnSBLQtyChange(groupRow.qty, groupTotalPrice),
        // //     //             totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
        // //     //             nettEA: groupNettEA,
        // //     //             dcOnLp: groupDcOnLp,
        // //     //             margin: groupMargin,
        // //     //             actMargin: groupActMargin,
        // //     //             frcMargin: groupFrcMargin,
        // //     //             stFutureCost: groupStFutureCost,
        // //     //             stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //     //             stEecost: groupstEecost,
        // //     //             stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //     //             actCostExact: groupActCostExact,
        // //     //             actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //     //             //  lcMargin: "",
        // //     //         };

        // //     //         updatedItemList[groupRowIndex] = updatedGroupRow;

        // //     //         // non group db extra calculation
        // //     //         const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //         if (nonGroupItems.length > 0) {
        // //     //             const nonGroupId = nonGroupItems[0].groupId;
        // //     //             const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //             if (nonGroupDbExtra) {
        // //     //                 const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(
        // //     //                     updatedItemList,
        // //     //                     dbExtraCharge
        // //     //                 );
        // //     //                 updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //             }
        // //     //         }

        // //     //         // const totalPrice = calculateTotalPrice(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalItemPrice(totalPrice);

        // //     //         // const totalCost = calculateTotalCost(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalCost(totalCost);

        // //     //         // const totalneeta = calculateTotalNettEA(updatedItemList.filter((item) => item.stStockCode != " "));
        // //     //         // setTotalNettEA(totalneeta);

        // //     //         // const totalstEecostRound = calculateTotalstEecostRound(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalstEecostRound(totalstEecostRound);

        // //     //         // const totalStFutureCost = calculateTotalStFutureCost(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalStFutureCost(totalStFutureCost);

        // //     //         // const totalActCostEa = calculateTotalActCostEAExact(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalActCostEAExact(totalActCostEa);

        // //     //         // const totalListPrice = calculateTotalListPrice(
        // //     //         //     updatedItemList.filter((item) => item.stStockCode != " ")
        // //     //         // );
        // //     //         // setTotalListPrice(totalListPrice);

        // //     //         // footer calculation
        // //     //         const {
        // //     //             totalPrice,
        // //     //             totalSteEeCostRound,
        // //     //             totalStFutureCost,
        // //     //             totalActCostExact,
        // //     //             totalListPrice,
        // //     //             totalNettEA,
        // //     //             totalCost,
        // //     //             totalMargin,
        // //     //             totalActMargin,
        // //     //             totalFrcMargin,
        // //     //             totalDcOnLp,
        // //     //         } = footerCalculation(updatedItemList);

        // //     //         setTotalItemPrice(totalPrice);
        // //     //         setTotalstEecostRound(totalSteEeCostRound);
        // //     //         setTotalStFutureCost(totalStFutureCost);
        // //     //         setTotalActCostEAExact(totalActCostExact);
        // //     //         setTotalListPrice(totalListPrice);
        // //     //         setTotalCost(totalCost);
        // //     //         setTotalNettEA(totalNettEA);
        // //     //         setMaxMargin(totalMargin);
        // //     //         setActMargin(totalActMargin);
        // //     //         setMaxFrcMargin(totalFrcMargin);
        // //     //         setFooterDisc(totalDcOnLp);

        // //     //         setItemList(updatedItemList);
        // //     //         updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

        // //     //         if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //             updateFirstCustomerForId(id, {
        // //     //                 ...firstCustomer[id],
        // //     //                 item: updatedItemList[0],
        // //     //             });
        // //     //         }
        // //     //     }
        // //     // } else {
        // //     //     let updatedItemList = [...itemList];

        // //     //     // non group db extra calculation
        // //     //     const nonGroupItems = getExistingNonGroupedItems(updatedItemList);

        // //     //     if (nonGroupItems.length > 0) {
        // //     //         const nonGroupId = nonGroupItems[0].groupId;
        // //     //         const nonGroupDbExtra = isDbExtraAdded(updatedItemList, nonGroupId, null, GroupTypes.NON_GROUP);

        // //     //         if (nonGroupDbExtra) {
        // //     //             // const qtyBasedList = getListWithQtyBasedNettEA(updatedItemList);
        // //     //             const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(updatedItemList, dbExtraCharge);
        // //     //             updatedItemList[dbExtraRowIndex] = dbExtraRow;
        // //     //         }
        // //     //     }

        // //     //     // footer calculation
        // //     //     const {
        // //     //         totalPrice,
        // //     //         totalSteEeCostRound,
        // //     //         totalStFutureCost,
        // //     //         totalActCostExact,
        // //     //         totalListPrice,
        // //     //         totalNettEA,
        // //     //         totalCost,
        // //     //         totalMargin,
        // //     //         totalActMargin,
        // //     //         totalFrcMargin,
        // //     //         totalDcOnLp,
        // //     //     } = footerCalculation(updatedItemList);

        // //     //     setTotalItemPrice(totalPrice);
        // //     //     setTotalstEecostRound(totalSteEeCostRound);
        // //     //     setTotalStFutureCost(totalStFutureCost);
        // //     //     setTotalActCostEAExact(totalActCostExact);
        // //     //     setTotalListPrice(totalListPrice);
        // //     //     setTotalCost(totalCost);
        // //     //     setTotalNettEA(totalNettEA);
        // //     //     setMaxMargin(totalMargin);
        // //     //     setActMargin(totalActMargin);
        // //     //     setMaxFrcMargin(totalFrcMargin);
        // //     //     setFooterDisc(totalDcOnLp);

        // //     //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // //     //     setItemList(updatedItemList);

        // //     //     // const totalPrice = calculateTotalPrice(updatedItemList);
        // //     //     // setTotalItemPrice(totalPrice);

        // //     //     // const totalCost = calculateTotalCost(updatedItemList);
        // //     //     // setTotalCost(totalCost);

        // //     //     // const totalneeta = calculateTotalNettEA(updatedItemList);
        // //     //     // setTotalNettEA(totalneeta);

        // //     //     // const totalstEecostRound = calculateTotalstEecostRound(updatedItemList);
        // //     //     // setTotalstEecostRound(totalstEecostRound);

        // //     //     // const totalStFutureCost = calculateTotalStFutureCost(updatedItemList);
        // //     //     // setTotalStFutureCost(totalStFutureCost);

        // //     //     // const totalActCostEa = calculateTotalActCostEAExact(updatedItemList);
        // //     //     // setTotalActCostEAExact(totalActCostEa);

        // //     //     // const totalListPrice = calculateTotalListPrice(updatedItemList);
        // //     //     // setTotalListPrice(totalListPrice);

        // //     //     if (firstCustomer[id]?.customer?.customerId === customerId) {
        // //     //         updateFirstCustomerForId(id, {
        // //     //             ...firstCustomer[id],
        // //     //             item: updatedItemList[0],
        // //     //         });
        // //     //     }
        // //     // }
        // //     //#endregion
        // //     checkRepDiscLimit(updatedItem);

        // //     setItemList(itemList);
        // //     updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //     setComputeTotals(true);

        // // } else if (field === "displayGroupName") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const groupRow = itemList[itemIndex];

        // //     if (
        // //         groupRow.displaySubGroupName === "" ||
        // //         groupRow.displaySubGroupName === undefined ||
        // //         groupRow.displaySubGroupName === null
        // //     ) {
        // //         if (value === "" || value === undefined || value === null) {
        // //             const updatedGroupRow = {
        // //                 ...groupRow,
        // //                 displayGroupName: groupRow.groupName,
        // //             };

        // //             itemList[itemIndex] = updatedGroupRow;

        // //             setItemList(itemList);
        // //             updateQuoteDetailsForId(id, { selectedItems: itemList });

        // //             toast.error("Please enter a valid group name", {
        // //                 position: "top-center",
        // //                 transition: Slide,
        // //             });            
        // //             return;
        // //         }

        // //         const updatedGroupRow = {
        // //             ...groupRow,
        // //             displayGroupName: value,
        // //         };

        // //         itemList[itemIndex] = updatedGroupRow;

        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //     } else {
        // //         const updatedGroupRow = {
        // //             ...groupRow,
        // //             displayGroupName: "",
        // //         };

        // //         itemList[itemIndex] = updatedGroupRow;

        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });

        // //         toast.error("Editing the group name in a sub group row is forbidden.", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }
        // // } else if (field === "displaySubGroupName") {
        // //     const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const subGroupRow = itemList[itemIndex];

        // //     if (
        // //         subGroupRow.displayGroupName === "" ||
        // //         subGroupRow.displayGroupName === undefined ||
        // //         subGroupRow.displayGroupName === null
        // //     ) {
        // //         if (value === "" || value === undefined || value === null) {
        // //             const updatedSubGroupRow = {
        // //                 ...subGroupRow,
        // //                 displaySubGroupName: subGroupRow.subGroupName,
        // //             };

        // //             itemList[itemIndex] = updatedSubGroupRow;

        // //             setItemList(itemList);
        // //             updateQuoteDetailsForId(id, { selectedItems: itemList });

        // //             toast.error("Please enter a valid sub group name", {
        // //                 position: "top-center",
        // //                 transition: Slide,
        // //             });            
        // //             return;
        // //         }

        // //         const updatedSubGroupRow = {
        // //             ...subGroupRow,
        // //             displaySubGroupName: value,
        // //         };

        // //         itemList[itemIndex] = updatedSubGroupRow;

        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });
        // //     } else {
        // //         const updatedSubGroupRow = {
        // //             ...subGroupRow,
        // //             displaySubGroupName: "",
        // //         };

        // //         itemList[itemIndex] = updatedSubGroupRow;

        // //         setItemList(itemList);
        // //         updateQuoteDetailsForId(id, { selectedItems: itemList });

        // //         toast.error("Editing the sub group name in a group row is forbidden.", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }
        // // } else if (field === "description") {
        // //     const itemRow = itemList[itemIndex];

        // //     let updatedItem = {
        // //         ...itemRow,
        // //         description: value,
        // //     };

        // //     itemList[itemIndex] = updatedItem;

        // //     setItemList(itemList);
        // //     updateQuoteDetailsForId(id, { selectedItems: itemList });
        // // } else if (field === "stEecostRound") {
        // //     let updatedItemList = [...itemList];
        // //     const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = updatedItemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        // //     let updatedStEeCostRound = 0;

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedStEeCostRound = parseFloat(parseFloat(value).toFixed(2));
        // //     } else {
        // //         updatedStEeCostRound = itemRow.stEecostRound;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     const updatedItem = {
        // //         ...itemRow,
        // //         stEecostRound: updatedStEeCostRound,
        // //         stEecost: updatedStEeCostRound,
        // //         margin: parseFloat(itemRow.margin) === 0 ? 0.0 : calculateItemMargin(itemRow.nettEA, updatedStEeCostRound),
        // //         lcMargin: 0,
        // //         totalCost: calculateItemTotalCost(itemRow.qty, updatedStEeCostRound),
        // //     };

        // //     updatedItemList[itemIndex] = updatedItem;

        // //     if (itemRow.qty > 0) {
        // //         if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //             if (updatingItemSubGroup) {
        // //                 const subGroupRow = getSubGroupRowByGroupAndSubGroup(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 )[0];
        // //                 const subGroupIndex = updatedItemList.indexOf(subGroupRow);
        // //                 const subGroupItems = getAllExistingSubGroupedItems(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 );
        // //                 const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

        // //                 const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItemsQtfied, "totalPrice");
        // //                 const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
        // //                 const subGroupstEecost = calculateGroupWiseTotal(subGroupItemsQtfied, "stEecost");
        // //                 const subGroupStFutureCost = calculateGroupWiseTotal(subGroupItemsQtfied, "stFutureCost");
        // //                 const subGroupActCostExact = calculateGroupWiseTotal(subGroupItemsQtfied, "actCostExact");

        // //                 // for margin calculations
        // //                 const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
        // //                 const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
        // //                 const subGroupMargin = calculateGroupWiseMarginForSNumberChange(
        // //                     subGroupNettEANonDX,
        // //                     subGroupstEecost
        // //                 );
        // //                 const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     subGroupNettEANonDX,
        // //                     subGroupActCostExact
        // //                 );
        // //                 const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     subGroupNettEANonDX,
        // //                     subGroupStFutureCost
        // //                 );

        // //                 const updatedSubGroupRow = {
        // //                     ...subGroupRow,
        // //                     totalPrice: subGroupTotalPrice,
        // //                     totalCost: calculateItemTotalCost(subGroupRow.qty, subGroupstEecost),
        // //                     nettEA: subGroupNettEA,
        // //                     margin: subGroupMargin,
        // //                     stEecost: subGroupstEecost,
        // //                     stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
        // //                     actMargin: subGroupActMargin,
        // //                     frcMargin: subGroupFrcMargin,
        // //                     stFutureCost: subGroupStFutureCost,
        // //                     stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
        // //                     actCostExact: subGroupActCostExact,
        // //                     actCost: parseFloat(subGroupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[subGroupIndex] = updatedSubGroupRow;

        // //                 //group level calculations
        // //                 const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
        // //                 const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

        // //                 let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

        // //                 const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //                 const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //                 const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

        // //                 // remove db extra for margin calculation
        // //                 const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
        // //                 const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

        // //                 groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
        // //                 const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupActCostExact
        // //                 );
        // //                 const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupStFutureCost
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     totalPrice: groupTotalPrice,
        // //                     totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
        // //                     nettEA: groupNettEA,
        // //                     margin: groupMargin,
        // //                     stEecost: groupstEecost,
        // //                     stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //                     actMargin: groupActMargin,
        // //                     frcMargin: groupFrcMargin,
        // //                     stFutureCost: groupStFutureCost,
        // //                     stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                     actCostExact: groupActCostExact,
        // //                     actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             } else {
        // //                 let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 let groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 groupRowItems = getQtyBasedValues(groupRowItems);
        // //                 subGroupRows = getQtyBasedValues(subGroupRows);

        // //                 subGroupRows = getItemsWithoutAIandDX(subGroupRows);

        // //                 let groupItems = [...groupRowItems, ...subGroupRows];

        // //                 const groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");
        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupstEecost = calculateGroupWiseTotal(groupItems, "stEecost");
        // //                 const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");
        // //                 const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

        // //                 // remove db extra for margin calculation
        // //                 groupRowItems = getItemsWithoutAIandDX(groupRowItems);
        // //                 groupItems = [...groupRowItems, ...subGroupRows];
        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

        // //                 const groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEANonDX, groupstEecost);
        // //                 const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupActCostExact
        // //                 );
        // //                 const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupStFutureCost
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     totalPrice: groupTotalPrice,
        // //                     totalCost: calculateItemTotalCost(groupRow.qty, groupstEecost),
        // //                     nettEA: groupNettEA,
        // //                     margin: groupMargin,
        // //                     stEecost: groupstEecost,
        // //                     stEecostRound: parseFloat(groupstEecost.toFixed(2)),
        // //                     actMargin: groupActMargin,
        // //                     frcMargin: groupFrcMargin,
        // //                     stFutureCost: groupStFutureCost,
        // //                     stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                     actCostExact: groupActCostExact,
        // //                     actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             }
        // //         } else {
        // //             // nothing to handle for non group items so far
        // //         }
        // //     }
        // //     //#region temporary commented and replace global computation
        // //     // // footer calculation
        // //     // const {
        // //     //     totalPrice,
        // //     //     totalSteEeCostRound,
        // //     //     totalStFutureCost,
        // //     //     totalActCostExact,
        // //     //     totalListPrice,
        // //     //     totalNettEA,
        // //     //     totalCost,
        // //     //     totalMargin,
        // //     //     totalActMargin,
        // //     //     totalFrcMargin,
        // //     //     totalDcOnLp,
        // //     // } = footerCalculation(updatedItemList);

        // //     // setTotalItemPrice(totalPrice);
        // //     // setTotalstEecostRound(totalSteEeCostRound);
        // //     // setTotalStFutureCost(totalStFutureCost);
        // //     // setTotalActCostEAExact(totalActCostExact);
        // //     // setTotalListPrice(totalListPrice);
        // //     // setTotalCost(totalCost);
        // //     // setTotalNettEA(totalNettEA);
        // //     // setMaxMargin(totalMargin);
        // //     // setActMargin(totalActMargin);
        // //     // setMaxFrcMargin(totalFrcMargin);
        // //     // setFooterDisc(totalDcOnLp);

        // //     const hasAssembly = updatedItemList.some((item) => item.rowType === "A");
        // //     const updatedItems = hasAssembly ? computeAssemblytotalByGroup(updatedItemList, true) : updatedItemList;
        // //     setItemList(updatedItems);
        // //     updateQuoteDetailsForId(id, { selectedItems: updatedItems });
        // //     setComputeTotals(true);
        // // } else if (field === "actCost") {
        // //     let updatedItemList = [...itemList];
        // //     const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = updatedItemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        // //     let updatedActCost = 0;

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedActCost = parseFloat(parseFloat(value).toFixed(2));
        // //     } else {
        // //         updatedActCost = itemRow.actCost;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     if (itemRow.qty > 0) {
        // //         const updatedItem = {
        // //             ...itemRow,
        // //             actCost: updatedActCost,
        // //             actCostExact: updatedActCost,
        // //             actMargin: calculateItemActMargin(itemRow.nettEA, updatedActCost),
        // //         };

        // //         updatedItemList[itemIndex] = updatedItem;

        // //         if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //             if (updatingItemSubGroup) {
        // //                 const subGroupRow = getSubGroupRowByGroupAndSubGroup(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 )[0];
        // //                 const subGroupIndex = updatedItemList.indexOf(subGroupRow);
        // //                 const subGroupItems = getAllExistingSubGroupedItems(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 );
        // //                 const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

        // //                 const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItemsQtfied, "totalPrice");
        // //                 const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
        // //                 const subGroupActCostExact = calculateGroupWiseTotal(subGroupItemsQtfied, "actCostExact");

        // //                 // for margin calculations
        // //                 const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
        // //                 const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
        // //                 const subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     subGroupNettEANonDX,
        // //                     subGroupActCostExact
        // //                 );

        // //                 const updatedSubGroupRow = {
        // //                     ...subGroupRow,
        // //                     totalPrice: subGroupTotalPrice,
        // //                     nettEA: subGroupNettEA,
        // //                     actMargin: subGroupActMargin,
        // //                     actCostExact: subGroupActCostExact,
        // //                     actCost: parseFloat(subGroupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[subGroupIndex] = updatedSubGroupRow;

        // //                 //group level calculations
        // //                 const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
        // //                 const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

        // //                 let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

        // //                 // remove db extra for margin calculation
        // //                 const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
        // //                 const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

        // //                 groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupActCostExact
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     nettEA: groupNettEA,
        // //                     actMargin: groupActMargin,
        // //                     actCostExact: groupActCostExact,
        // //                     actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             } else {
        // //                 let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 let groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 groupRowItems = getQtyBasedValues(groupRowItems);
        // //                 subGroupRows = getQtyBasedValues(subGroupRows);

        // //                 subGroupRows = getItemsWithoutAIandDX(subGroupRows);

        // //                 let groupItems = [...groupRowItems, ...subGroupRows];

        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupActCostExact = calculateGroupWiseTotal(groupItems, "actCostExact");

        // //                 // remove db extra for margin calculation
        // //                 groupRowItems = getItemsWithoutAIandDX(groupRowItems);
        // //                 groupItems = [...groupRowItems, ...subGroupRows];
        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

        // //                 const groupActMargin = calculateGroupWiseActMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupActCostExact
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     nettEA: groupNettEA,
        // //                     actMargin: groupActMargin,
        // //                     actCostExact: groupActCostExact,
        // //                     actCost: parseFloat(groupActCostExact.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             }
        // //         } else {
        // //             // nothing to handle for non group items so far
        // //         }
        // //     }

        // //     // footer calculation
        // //     const {
        // //         totalPrice,
        // //         totalSteEeCostRound,
        // //         totalStFutureCost,
        // //         totalActCostExact,
        // //         totalListPrice,
        // //         totalNettEA,
        // //         totalCost,
        // //         totalMargin,
        // //         totalActMargin,
        // //         totalFrcMargin,
        // //         totalDcOnLp,
        // //     } = footerCalculation(updatedItemList);

        // //     setTotalItemPrice(totalPrice);
        // //     setTotalstEecostRound(totalSteEeCostRound);
        // //     setTotalStFutureCost(totalStFutureCost);
        // //     setTotalActCostEAExact(totalActCostExact);
        // //     setTotalListPrice(totalListPrice);
        // //     setTotalCost(totalCost);
        // //     setTotalNettEA(totalNettEA);
        // //     setMaxMargin(totalMargin);
        // //     setActMargin(totalActMargin);
        // //     setMaxFrcMargin(totalFrcMargin);
        // //     setFooterDisc(totalDcOnLp);

        // //     setItemList(updatedItemList);
        // //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // // } else if (field === "stFutureCostRound") {
        // //     let updatedItemList = [...itemList];
        // //     const itemIndex = updatedItemList.findIndex((item) => item.rowId === dataItem.rowId);
        // //     const itemRow = updatedItemList[itemIndex];
        // //     const updatingItemGroup = itemRow.groupId;
        // //     const updatingItemGroupName = itemRow.groupName;
        // //     const updatingItemSubGroup = itemRow.subGroupId;
        // //     const isAGroupRow = itemRow.displayGroupName !== "" && itemRow.stStockCode === "";
        // //     const isASubGroupRow = itemRow.displaySubGroupName !== "" && itemRow.stStockCode === "";
        // //     let updatedStFutureCostRound = 0;

        // //     if (!isNaN(parseFloat(value))) {
        // //         updatedStFutureCostRound = parseFloat(parseFloat(value).toFixed(2));
        // //     } else {
        // //         updatedStFutureCostRound = itemRow.stFutureCostRound;
        // //         toast.error("Please enter a valid number", {
        // //             position: "top-center",
        // //             transition: Slide,
        // //         });        
        // //         return;
        // //     }

        // //     const updatedItem = {
        // //         ...itemRow,
        // //         stFutureCost: updatedStFutureCostRound,
        // //         stFutureCostRound: updatedStFutureCostRound,
        // //         frcMargin: calculateItemFrcMargin(itemRow.nettEA, updatedStFutureCostRound),
        // //         lcMargin: 0,
        // //     };

        // //     updatedItemList[itemIndex] = updatedItem;

        // //     if (itemRow.qty > 0) {
        // //         if (updatingItemGroup && updatingItemGroupName !== GroupItemTypes.NON_GROUPED_ITEMS && !isAGroupRow) {
        // //             if (updatingItemSubGroup) {
        // //                 const subGroupRow = getSubGroupRowByGroupAndSubGroup(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 )[0];
        // //                 const subGroupIndex = updatedItemList.indexOf(subGroupRow);
        // //                 const subGroupItems = getAllExistingSubGroupedItems(
        // //                     updatedItemList,
        // //                     updatingItemGroup,
        // //                     updatingItemSubGroup
        // //                 );
        // //                 const subGroupItemsQtfied = getQtyBasedValues(subGroupItems);

        // //                 const subGroupNettEA = calculateGroupWiseTotal(subGroupItemsQtfied, "nettEA");
        // //                 const subGroupStFutureCost = calculateGroupWiseTotal(subGroupItemsQtfied, "stFutureCost");

        // //                 // for margin calculations
        // //                 const subGroupItemsNonDx = getItemsWithoutAIandDX(subGroupItemsQtfied);
        // //                 const subGroupNettEANonDX = calculateGroupWiseTotal(subGroupItemsNonDx, "nettEA");
        // //                 const subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     subGroupNettEANonDX,
        // //                     subGroupStFutureCost
        // //                 );

        // //                 const updatedSubGroupRow = {
        // //                     ...subGroupRow,
        // //                     nettEA: subGroupNettEA,
        // //                     stFutureCost: subGroupStFutureCost,
        // //                     stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
        // //                     frcMargin: subGroupFrcMargin,
        // //                 };

        // //                 updatedItemList[subGroupIndex] = updatedSubGroupRow;

        // //                 //group level calculations
        // //                 const groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 const groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 const groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 const subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 const groupRowItemsQtfied = getQtyBasedValues(groupRowItems);
        // //                 const subGroupRowsQtfied = getQtyBasedValues(subGroupRows);

        // //                 let groupItems = [...groupRowItemsQtfied, ...subGroupRowsQtfied];

        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");

        // //                 // remove db extra for margin calculation
        // //                 const groupItemsNonDX = getItemsWithoutAIandDX(groupRowItemsQtfied);
        // //                 const subGroupRowsNonDX = getSubGroupsWithoutDX(subGroupRowsQtfied, updatedItemList);

        // //                 groupItems = [...groupItemsNonDX, ...subGroupRowsNonDX];

        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupStFutureCost
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     nettEA: groupNettEA,
        // //                     frcMargin: groupFrcMargin,
        // //                     stFutureCost: groupStFutureCost,
        // //                     stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             } else {
        // //                 let groupRow = getGroupRowByGroup(updatedItemList, updatingItemGroup)[0];
        // //                 let groupRowIndex = updatedItemList.indexOf(groupRow);
        // //                 let groupRowItems = getGroupedRowItems(updatedItemList, updatingItemGroup);
        // //                 let subGroupRows = getAllExistingSubGroupsByGroup(updatedItemList, updatingItemGroup);
        // //                 groupRowItems = getQtyBasedValues(groupRowItems);
        // //                 subGroupRows = getQtyBasedValues(subGroupRows);

        // //                 subGroupRows = getItemsWithoutAIandDX(subGroupRows);

        // //                 let groupItems = [...groupRowItems, ...subGroupRows];

        // //                 const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
        // //                 const groupStFutureCost = calculateGroupWiseTotal(groupItems, "stFutureCost");

        // //                 // remove db extra for margin calculation
        // //                 groupRowItems = getItemsWithoutAIandDX(groupRowItems);
        // //                 groupItems = [...groupRowItems, ...subGroupRows];
        // //                 const groupNettEANonDX = calculateGroupWiseTotal(groupItems, "nettEA");

        // //                 const groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
        // //                     groupNettEANonDX,
        // //                     groupStFutureCost
        // //                 );

        // //                 const updatedGroupRow = {
        // //                     ...groupRow,
        // //                     nettEA: groupNettEA,
        // //                     frcMargin: groupFrcMargin,
        // //                     stFutureCost: groupStFutureCost,
        // //                     stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
        // //                 };

        // //                 updatedItemList[groupRowIndex] = updatedGroupRow;
        // //             }
        // //         } else {
        // //         }
        // //     }

        // //     // footer calculation
        // //     const {
        // //         totalPrice,
        // //         totalSteEeCostRound,
        // //         totalStFutureCost,
        // //         totalActCostExact,
        // //         totalListPrice,
        // //         totalNettEA,
        // //         totalCost,
        // //         totalMargin,
        // //         totalActMargin,
        // //         totalFrcMargin,
        // //         totalDcOnLp,
        // //     } = footerCalculation(updatedItemList);

        // //     setTotalItemPrice(totalPrice);
        // //     setTotalstEecostRound(totalSteEeCostRound);
        // //     setTotalStFutureCost(totalStFutureCost);
        // //     setTotalActCostEAExact(totalActCostExact);
        // //     setTotalListPrice(totalListPrice);
        // //     setTotalCost(totalCost);
        // //     setTotalNettEA(totalNettEA);
        // //     setMaxMargin(totalMargin);
        // //     setActMargin(totalActMargin);
        // //     setMaxFrcMargin(totalFrcMargin);
        // //     setFooterDisc(totalDcOnLp);

        // //     setItemList(updatedItemList);
        // //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // // }
        //end - Commented by Manoj on 2024-03-17
        //#endregion

        if (itemList.length > 0 && itemIndex === itemList.length - 1 && dataItem) {
            let emptyRow = createEmptyRow(dataItem.groupId, dataItem.groupName, dataItem.subGroupId, dataItem.subGroupName, "", "", "", dataItem.parentId);
            emptyRow.position = undefined;
            let updatedItemList = [...itemList, emptyRow];
            updatedItemList = itemPositioning(true, updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            setItemList(updatedItemList);
        }
        SetLoadStatus(true);
        let rId = dataItem.rowId

        setHiddenStatus(prevStatus => {
            const newStatus = { ...prevStatus };
            if (!newStatus[rId]) {
                newStatus[rId] = { hideRow: false, hideQty: false, hidePrice: false };
            }

            if (value === "ENGINEERING") {
                newStatus[rId].hideRow = true;
            } else {
                newStatus[rId].hideRow = false;
            }

            return newStatus;
        });
    

    };

    const handleLabourChange = (event) => {
        const { field, dataItem } = event;
        const value = event.value;

        const itemIndex = itemList.findIndex((item) => item.stId === dataItem.stId);
        const itemRow = itemList[itemIndex];
        let updatedItem = null;

        if (field === "stStockCode") {
            updatedItem = {
                ...itemRow,
                stStockCode: value,
            };
            itemList[itemIndex] = updatedItem;
            setItemList(itemList);
        } else if (field === "description") {
            updatedItem = {
                ...itemRow,
                description: value,
            };
            itemList[itemIndex] = updatedItem;
            setItemList(itemList);
        } else if (field === "nettEA") {
            if (dataItem.rowType === "A") {
                ///Computation applies to Assembly
                updatedItem = {
                    ...itemRow,
                    nettEA: parseFloat(value).toFixed(2),
                    totalPrice: parseFloat(value * itemRow.qty).toFixed(2),
                    margin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                    actMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                    frcMargin: (((value - dataItem.stEecostRound) / value) * 100).toFixed(2) + "%",
                };
            }
            itemList[itemIndex] = updatedItem;
            setItemList(itemList);
        } else if (field === "margin") {
            if (dataItem.rowType === "A") {
                ///Computation applies to Assembly
                const netEA = parseFloat(itemRow.stEecostRound / ((100 - value) / 100)).toFixed(2);
                const margin = parseFloat(value).toFixed(1) + "%";
                updatedItem = {
                    ...itemRow,
                    nettEA: netEA,
                    totalPrice: parseFloat(Number(itemRow.qty) * netEA).toFixed(2),
                    margin: margin,
                    actMargin: margin,
                    frcMargin: margin,
                };
            }
            itemList[itemIndex] = updatedItem;
            setItemList(itemList);
        } else if (field === "qty") {
            const AssemblyIndex = itemList.findIndex(
                (item) => item.groupId === dataItem.groupId && item.rowType === "A"
            );
            const itemRowAssembyHdr = itemList[AssemblyIndex];
            if (dataItem.rowType === "AI") {
                ///Computation applies to Assembly
                updatedItem = {
                    ...itemRow,
                    qty: parseInt(value),
                    totalCost: (parseFloat(itemRow.stEecostRound) * parseInt(value)).toFixed(2),
                    nettEA: "",
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    totalPrice: "",
                    margin: "",
                    actMargin: "",
                    frcMargin: "",
                    lcMargin: "",
                    dcOnLp: "",
                };
                itemList[itemIndex] = updatedItem;
                setItemList(itemList);

                calculateLabourtotal(updatedItem, itemRowAssembyHdr, AssemblyIndex);
            }
        }

        updateQuoteDetailsForId(id, { selectedItems: updatedItem });
        setLabourChanges(!labourChanges);
    };

    const calculateLabourtotal = (updatedItem, itemRowAssembyHdr, AssemblyIndex) => {
        let assemblyTotals = {};
        let updatedLabourGrp = {};

        assemblyTotals = calcGrpLabourTotal(updatedItem);
        updatedLabourGrp = {
            ...itemRowAssembyHdr,
            totalCost: parseFloat(assemblyTotals.totCost).toFixed(2),
            totalPrice: assemblyTotals.totPrice,
            qty: parseFloat(assemblyTotals.totalHr).toFixed(2),
            nettEA: assemblyTotals.nettEA,
        };
        itemList[AssemblyIndex] = updatedLabourGrp;
        setItemList(itemList);
    };

    // const calculateDbExtra = (dbExtraCharge, groupWiseTotalNettEA) => {
    //     const dbExtraChargeValue = typeof dbExtraCharge === "string" ? 0 : parseFloat(dbExtraCharge);
    //     const groupWiseTotalNettEAValue =
    //         typeof groupWiseTotalNettEA === "string" ? 0 : parseFloat(groupWiseTotalNettEA);
    //     return Math.ceil(parseFloat(groupWiseTotalNettEAValue * (dbExtraChargeValue / 100).toFixed(2)));
    // };

    const addDbExtra = async () => {
        setIsQuoteSaved(false);
        try {
            let systemSettings = null;
            const dbExtra = await getResponse("DbExtra/getdbextra");

            if (settings === null) {
                const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                    userId: user?.homeAccountId,
                });
                systemSettings = data[0];
            } else {
                systemSettings = settings;
            }

            if (dbExtra) {
                const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
                const data = dbExtra[0];
                const consumableId = data.id;
                const groupId = itemList.at(-1).groupId;
                let dbExtraRow = getDbExtraRow(data);
                const grpType = (groupId ? itemList.filter((item) => item.groupId === groupId && item.stStockCode === "")[0].rowType : "");
                if (groupId && ((typeof grpType !== "undefined") && grpType !== "") && (grpType !== "S" || grpType !== "A")) {
                    const existingSubGroups = itemList.filter((item) => {
                        return item.groupId === groupId && item.displaySubGroupName !== "";
                    });

                    //ref : assumes that the dbExtra only gets added to the last sub group always.
                    if (existingSubGroups.length > 0) {
                        const lastSubGroup = existingSubGroups[existingSubGroups.length - 1];
                        const lastGroupId = lastSubGroup.groupId;
                        const lastSubGroupId = lastSubGroup.subGroupId;
                        const lastSubGroupCount = parseInt(lastSubGroup.subGroupName.split(" ")[2]);
                        const lastGroupCount = parseInt(lastSubGroup.groupName.split(" ")[1]);

                        const existingSubGroupDbExtra = itemList.filter((item) => {
                            return (
                                item.groupId === lastGroupId &&
                                item.subGroupId === lastSubGroupId &&
                                item.subGroupDbExtraId === consumableId
                            );
                        });

                        if (existingSubGroupDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given sub group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        // const subGroupItems = itemList.filter((item) => {
                        //     return (
                        //         item.groupId === lastGroupId &&
                        //         item.subGroupId === lastSubGroupId &&
                        //         item.stStockCode !== ""
                        //     );
                        // });

                        let subGroupItems = getAllExistingSubGroupedItems(itemList, lastGroupId, lastSubGroupId);
                        subGroupItems = getQtyBasedValues(subGroupItems);

                        if (subGroupItems.length > 0) {
                            const subGroupTotalNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                            const dbExtraNettEA = calculateDbExtra(dbExtraCharge, subGroupTotalNettEA);

                            dbExtraRow = {
                                ...dbExtraRow,
                                groupId: lastGroupId,
                                groupName: `Group ${lastGroupCount}`,
                                subGroupId: lastSubGroupId,
                                subGroupName: `Sub Group ${lastSubGroupCount}`,
                                groupDbExtraId: "",
                                subGroupDbExtraId: consumableId,
                                nettEA: dbExtraNettEA,
                                totalPrice: dbExtraNettEA,
                                id: uuidv4(),
                                parentId: subGroupItems.parentId
                            };

                            const listWithDbExtra = [...subGroupItems, dbExtraRow];

                            const updatedSubGroupNettEA = calculateGroupWiseTotal(listWithDbExtra, "nettEA");
                            const updatedSubGroupTotalPrice = calculateGroupWiseTotal(listWithDbExtra, "totalPrice");

                            const updatedSubGroup = {
                                ...lastSubGroup,
                                nettEA: updatedSubGroupNettEA,
                                //totalPrice: updatedSubGroupTotalPrice,
                                totalPrice: calculateValuesOnSBLQtyChange(lastSubGroup.qty, updatedSubGroupTotalPrice),
                            };

                            const lastSubGroupIndex = getSubGroupRowIndex(itemList, lastGroupId, lastSubGroupId);

                            itemList[lastSubGroupIndex] = updatedSubGroup;
                            const combinedList = [...itemList, dbExtraRow];
                            //ref : updating group row after adding db extra to sub group
                            const updatedSubGroups = getAllExistingSubGroupsByGroup(combinedList, lastGroupId);

                            const qtyUpdatedSubGroups = updatedSubGroups.map((item) => {
                                if (item.qty > 1) {
                                    return {
                                        ...item,
                                        nettEA: item.nettEA * item.qty,
                                        // totalPrice: item.totalPrice * item.qty,
                                    };
                                }
                                return item;
                            });

                            const groupRowList = getGroupedRowItems(combinedList, lastGroupId);
                            const concatenatedList = [...groupRowList, ...qtyUpdatedSubGroups];

                            const updatedGroupNettEA = calculateGroupWiseTotal(concatenatedList, "nettEA");
                            const updatedGroupTotalPrice = calculateGroupWiseTotal(concatenatedList, "totalPrice");

                            const groupRow = getGroupRowByGroup(combinedList, lastGroupId)[0];
                            const groupRowIndex = getGroupRowIndex(combinedList, lastGroupId);
                            const groupQty = groupRow.qty;
                            const hasGroupQtyChanged = groupQty > 1;

                            const updatedGroupRow = {
                                ...groupRow,
                                nettEA: updatedGroupNettEA,
                                totalPrice: hasGroupQtyChanged
                                    ? updatedGroupTotalPrice * groupQty
                                    : updatedGroupTotalPrice,
                            };

                            combinedList[groupRowIndex] = updatedGroupRow;
                            // const hasAssembly = combinedList.some((item) => item.rowType === "A");
                            // const updatedItems = hasAssembly ? computeAssemblytotalByGroup(combinedList, true) : combinedList;
                            const lastItem = itemList.at(-1);
                            let updatedItemListing = [];
                            let sortedItems = [];

                            if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                                updatedItemListing = itemList.filter((item) => item.rowId !== lastItem.rowId);
                                updatedItemListing = [...updatedItemListing, dbExtraRow, lastItem];
                                sortedItems = itemPositioning(true, updatedItemListing);
                            } else {
                                updatedItemListing = [...itemList, dbExtraRow];
                                sortedItems = itemPositioning(true, updatedItemList);
                            }

                            setItemList(sortedItems);
                            updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                            setComputeTotals(true);

                        } else {
                            dbExtraRow = {
                                ...dbExtraRow,
                                groupId: lastGroupId,
                                groupName: `Group ${lastGroupCount}`, //`Group ${lastGroupCount - 1}`,
                                subGroupId: lastSubGroupId,
                                subGroupName: `Sub Group ${lastSubGroupCount}`,
                                groupDbExtraId: "",
                                subGroupDbExtraId: consumableId,
                                nettEA: 0.0,
                                totalPrice: 0.0,
                                id: uuidv4(),
                                parentId: lastSubGroup.parentId
                            };

                            const combinedList = [...itemList, dbExtraRow];

                            const lastItem = itemList.at(-1);
                            let updatedItemListing = [];
                            let sortedItems = [];

                            if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                                updatedItemListing = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                                const combinedItems = [...updatedItemListing, lastItem];
                                sortedItems = itemPositioning(true, combinedItems);
                            } else {
                                updatedItemListing = [...combinedList, dbExtraRow];
                                sortedItems = itemPositioning(true, updatedItemList);
                            }

                            setItemList(sortedItems);
                            updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                            setComputeTotals(true);
                            // setItemList(combinedList);
                            // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                        }


                    } else {
                        const existingGroupDbExtra = itemList.filter((item) => {
                            return item.groupId === groupId && item.groupDbExtraId === consumableId;
                        });

                        if (existingGroupDbExtra.length > 0) {
                            toast.error("You have already added db extra to the given group!", {
                                position: "top-center",
                                transition: Slide,
                            });
                            return;
                        }

                        let existingGroupItems = itemList.filter((item) => {
                            return item.groupId === groupId && item.stStockCode !== "";
                        });
                        existingGroupItems = getQtyBasedValues(existingGroupItems);
                        if (existingGroupItems.length > 0) {
                            const groupItemsTotalNettEA = calculateGroupWiseTotal(existingGroupItems, "nettEA");
                            const dbExtraNettEA = calculateDbExtra(dbExtraCharge, groupItemsTotalNettEA);

                            dbExtraRow = {
                                ...dbExtraRow,
                                groupId: groupId,
                                groupName: `Group ${groupCount - 1}`, // because group count is already incremented when adding db extra if a groupId exists
                                groupDbExtraId: consumableId,
                                nettEA: dbExtraNettEA,
                                totalPrice: dbExtraNettEA,
                                id: uuidv4(),
                                parentId: existingGroupItems.at(-1).parentId
                            };

                            const listWithDbExtra = [...existingGroupItems, dbExtraRow];
                            const updatedGroupNettEA = calculateGroupWiseTotal(listWithDbExtra, "nettEA");
                            const updatedGroupTotalPrice = calculateGroupWiseTotal(listWithDbExtra, "totalPrice");

                            const combinedList = [...itemList, dbExtraRow];

                            const groupRow = getGroupRowByGroup(combinedList, groupId)[0];
                            let groupRowIndex = getGroupRowIndex(combinedList, groupId);
                            let groupQty = 1;
                            let hasGroupQtyChanged = false;
                            let updatedGroupRow = null;

                            if (groupRow) {
                                groupQty = groupRow.qty;
                                hasGroupQtyChanged = groupQty > 1;

                                updatedGroupRow = {
                                    ...groupRow,
                                    nettEA: updatedGroupNettEA,
                                    totalPrice: hasGroupQtyChanged
                                        ? updatedGroupTotalPrice * groupQty
                                        : updatedGroupTotalPrice,
                                };

                                combinedList[groupRowIndex] = updatedGroupRow;
                            }

                            const lastItem = itemList.at(-1);
                            let updatedItemListing = [];
                            let sortedItems = [];

                            if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                                updatedItemListing = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                                const combinedItems = [...updatedItemListing, lastItem];
                                sortedItems = itemPositioning(true, combinedItems);
                            } else {
                                updatedItemListing = [...combinedList, dbExtraRow];
                                sortedItems = itemPositioning(true, updatedItemList);
                            }

                            setItemList(sortedItems);
                            updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                            setComputeTotals(true);
                        } else {
                            const lastItem = itemList.at(-1);

                            dbExtraRow = {
                                ...dbExtraRow,
                                groupId: groupId,
                                groupName: `Group ${groupCount - 1}`,
                                groupDbExtraId: consumableId,
                                nettEA: 0.0,
                                id: uuidv4(),
                                parentId: lastItem.parentId
                            };


                            let updatedItemListing = [];
                            let sortedItems = [];
                            if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                                updatedItemListing = itemList.filter((item) => item.rowId !== lastItem.rowId);
                                const mergeItems = [...updatedItemListing, dbExtraRow, lastItem];
                                sortedItems = itemPositioning(true, mergeItems);
                            } else {
                                const mergeItems = [...itemList, dbExtraRow];
                                sortedItems = itemPositioning(true, mergeItems);
                            }

                            setItemList(sortedItems);
                            updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                            setComputeTotals(true);
                        }
                    }
                } else {
                    const nonGroupId = uuidv4();
                    if (!nonGroupedId.current) nonGroupedId.current = (!groupId) ? nonGroupId : groupId;
                    const grpName = (!groupId)
                        ? "Non Grouped Items"
                        : itemList.length > 0
                            ? itemList.filter((item) => item.groupId === groupId)[0].groupName
                            : "Non Grouped Items";
                    const existingDbExtra = itemList.filter((item) => {
                        return item.nonGroupDbExtraId === consumableId;
                    });

                    if (existingDbExtra.length > 0) {
                        toast.error("You have already added db extra!", {
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }

                    let existingNonGroupItems = itemList.filter((item) => {
                        return item.stStockCode !== "";
                    });
                    existingNonGroupItems = getQtyBasedValues(existingNonGroupItems);

                    if (existingNonGroupItems.length > 0) {
                        const nonGroupItemsTotalNettEA = calculateGroupWiseTotal(existingNonGroupItems, "nettEA");
                        const dbExtraNettEA = calculateDbExtra(dbExtraCharge, nonGroupItemsTotalNettEA);

                        dbExtraRow = {
                            ...dbExtraRow,
                            groupId: nonGroupedId.current,
                            groupName: grpName,
                            groupDbExtraId: "",
                            nonGroupDbExtraId: consumableId,
                            nettEA: dbExtraNettEA,
                            totalPrice: dbExtraNettEA,
                            rowType: "DX",
                            id: uuidv4(),
                            parentId: null
                        };

                        const combinedList = [...itemList];
                        // const hasAssembly = combinedList.some((item) => item.rowType === "A");
                        // const updatedItems = hasAssembly ? computeAssemblytotalByGroup(combinedList, true) : combinedList;

                        let updatedItemList = [];
                        const lastItem = itemList.at(-1);
                        let sortedItems = [];
                        if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                            updatedItemList = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                            const combinedItems = [...updatedItemList, dbExtraRow, lastItem];
                            sortedItems = itemPositioning(true, combinedItems);
                        } else {
                            updatedItemList = [...combinedList, dbExtraRow];
                            sortedItems = itemPositioning(true, updatedItemList);
                        }

                        setItemList(sortedItems);
                        updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                        setComputeTotals(true);
                        //#region temporarily commented and applied the global computation
                        // // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(combinedList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEA);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);

                        // setItemList(combinedList);
                        // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                        //#endregion
                    } else {
                        dbExtraRow = {
                            ...dbExtraRow,
                            groupId: nonGroupedId.current,
                            groupName: `Non Grouped Items`,
                            groupDbExtraId: "",
                            nonGroupDbExtraId: consumableId,
                            nettEA: 0.0,
                            totalPrice: 0.0,
                            rowType: "DX",
                            id: uuidv4(),
                            parentId: null
                        };

                        // const combinedList = [...itemList, dbExtraRow];
                        //#region temporarily commented and applied the global computation
                        // // footer calculation
                        // const {
                        //     totalPrice,
                        //     totalSteEeCostRound,
                        //     totalStFutureCost,
                        //     totalActCostExact,
                        //     totalListPrice,
                        //     totalNettEA,
                        //     totalCost,
                        //     totalMargin,
                        //     totalActMargin,
                        //     totalFrcMargin,
                        //     totalDcOnLp,
                        // } = footerCalculation(combinedList);

                        // setTotalItemPrice(totalPrice);
                        // setTotalstEecostRound(totalSteEeCostRound);
                        // setTotalStFutureCost(totalStFutureCost);
                        // setTotalActCostEAExact(totalActCostExact);
                        // setTotalListPrice(totalListPrice);
                        // setTotalCost(totalCost);
                        // setTotalNettEA(totalNettEA);
                        // setMaxMargin(totalMargin);
                        // setActMargin(totalActMargin);
                        // setMaxFrcMargin(totalFrcMargin);
                        // setFooterDisc(totalDcOnLp);

                        // setItemList(combinedList);
                        // updateQuoteDetailsForId(id, { selectedItems: combinedList });
                        //#endregion
                        let updatedItemList = [];
                        const lastItem = itemList.at(-1);
                        let sortedItems = [];
                        if (lastItem.stStockCode.length === 0 && lastItem.rowType === "") {
                            updatedItemList = combinedList.filter((item) => item.rowId !== lastItem.rowId);
                            const combinedItems = [...updatedItemList, lastItem];
                            sortedItems = itemPositioning(true, combinedItems);
                        } else {
                            updatedItemList = [...combinedList, dbExtraRow];
                            sortedItems = itemPositioning(true, updatedItemList);
                        }
                        setItemList(sortedItems);
                        updateQuoteDetailsForId(id, { selectedItems: sortedItems });
                        setComputeTotals(true);
                    }


                }
            }
        } catch (error) {
            console.error("Connection Error: ", error);
        }
    };

    const validateDeletion = () => {
        const type = selectedItems.filter((f) => f.rowType === "A");
        const selLen = selectedItems.length;
        if (type[0]?.rowType === "A" && selLen === 1) {
            toast.error("Deleting assembly header is not allowed", {
                autoClose: 5000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return false;
        } else return true;
    };

    const [computeTotals, setComputeTotals] = useState(false);
    const removeSelectedItems = async () => {
        setIsQuoteSaved(false);
        let systemSettings = null;

        if (validateDeletion() === false) return;

        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);

        if (selectedItems.length > 0) {
            // if (selectedItems[0].stStockCode == null || selectedItems[0].stStockCode == undefined) {
            // toast.error("Please select atleast one Item!", {
            //     autoClose: 1000,
            //     closeOnClick: true,
            // });
            if (selectedItems.length === 1) {
                let checkIfHassubLines = [];
                if (selectedItems[0].displaySubGroupName !== "") {
                    checkIfHassubLines = itemList.filter((f) => f.subGroupId === selectedItems[0].subGroupId);
                } else if (selectedItems[0].displayGroupName !== "") {
                    checkIfHassubLines = itemList.filter((f) => f.groupId === selectedItems[0].groupId);
                }

                if (checkIfHassubLines.length > 1) {
                    toast.error("You are not allowed to delete this line item without its sub items", {
                        autoClose: 3000,
                        closeOnClick: true,
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }
            }

            let filteredItemList = [];
            let remItem = [];
            let allGrpItem = [];
            confirmAlert({
                title: "Delete Confirmation",
                message: "Are you sure you want to delete the item(s)?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            filteredItemList = itemList.filter((item) => {
                                return !selectedItems.some((selectedItem) => selectedItem.rowId === item.rowId);
                            });
                            setItemList(filteredItemList);
                            updateQuoteDetailsForId(id, { selectedItems: filteredItemList });
                            setGroupId(undefined);
                            setSubGroupId(undefined);
                            setComputeTotals(true);
                            setSelectedItems([]);
                            setAddItems([]);
                        },
                    },
                    {
                        label: "No",
                        onClick: () => {
                            return;
                        },
                    },
                ],
            });

            //}
        } else {
            toast.error("Please select atleast one item!", {
                autoClose: 2000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
        }
    };

    // const sortItemByPosition = (item) => {
    //     return item.sort((a, b) => {if(a.position < b.position) return -1; if(a.position > b.position) return 1;});
    // }


    const itemPositioning = (rePosition = false, itemListToUpdate = []) => {
        if (rePosition && itemListToUpdate.length === 0) itemListToUpdate;
        let lnNum = 1;

        const checkNewItem = rePosition && itemListToUpdate.length > 0
            ? itemListToUpdate
            : itemList.filter((f) => typeof f.position === 'undefined');

        if (checkNewItem.length === 0) {
            const returnValue = rePosition && itemListToUpdate.length > 0
                ? itemListToUpdate
                : itemList
            return returnValue;
        };

        let itemRow = {};
        const sortedItems = rePosition && itemListToUpdate.length > 0 ? itemListToUpdate : sortItemListByPosition(itemList);
        let parentGId = null;
        let parentSGId = null;
        let parentId = null;
        let assemblyTreeId = null;
        let treeId = null;
        let grpLevel = "";
        //create an empty row when it is not exist
        const lastItem = sortedItems.at(-1);
        const parentEmptyId = lastItem.rowType === "SG"
            ? lastItem.id
            : lastItem.rowType === "G"
                ? lastItem.id
                : lastItem.parentId;
        if (lastItem.rowType !== "") {
            sortedItems.push(
                createEmptyRow(lastItem.groupId,
                    lastItem.groupName,
                    lastItem.subGroupId,
                    lastItem.subGroupName, "", "", "",
                    parentEmptyId)
            );
        }
        sortedItems.map((item, index) => {
            treeId = uuidv4();
            if (item.rowType === "G") parentGId = treeId;
            if (item.rowType === "SG") parentSGId = treeId;
            if (item.rowType === "A") assemblyTreeId = treeId;
            // if (item.groupName.startsWith("Group") && item.rowType === "G") parentId = null;
            // else if (item.subGroupName.startsWith("Sub") && item.rowType === "SG") parentId = parentGId;
            // else if (item.subGroupName.startsWith("Sub") && item.rowType === "A") parentId = parentSGId;
            // else if (item.groupName.startsWith("Group") && item.rowType === "A") parentId = parentGId;
            // else if (item.rowType === "AI") parentId = assemblyTreeId;
            // else parentId = item.subGroupId ? parentSGId : parentGId;
            if ((item.groupName?.startsWith("Group") ?? false) && item.rowType === "G") {
                parentId = null;
            } else if ((item.subGroupName?.startsWith("Sub") ?? false) && item.rowType === "SG") {
                parentId = parentGId;
            } else if ((item.subGroupName?.startsWith("Sub") ?? false) && item.rowType === "A") {
                parentId = parentSGId;
            } else if ((item.groupName?.startsWith("Group") ?? false) && item.rowType === "A") {
                parentId = parentGId;
            } else if (item.rowType === "AI") {
                parentId = assemblyTreeId;
            } else {
                parentId = item.subGroupId ? parentSGId : parentGId;
            }
            grpLevel = item.rowType === "G" ? item.displayGroupName : item.rowType === "SG" ? item.displaySubGroupName : item.rowType === "A" ? "ASSEMBLY" : "";
            if (typeof item.position === 'undefined') {
                itemRow = {
                    position: lnNum,
                    id: treeId,
                    parentId: item.rowType === "AI"
                        ? assemblyTreeId
                        : item.groupName?.includes(GroupItemTypes.NON_GROUPED_ITEMS)
                            ? null
                            : parentId,
                    groupLevelName: grpLevel,
                    iconIndicator: item.iconIndicator === undefined ? "" : item.iconIndicator,
                    ...item
                };
                if (isNaN(itemRow.position)) itemRow.position = lnNum;
            } else {
                item.groupLevelName = grpLevel;
                item.position = lnNum;
                item.id = treeId;
                item.parentId = item.rowType === "AI"
                    ? assemblyTreeId
                    : item.groupName?.includes(GroupItemTypes.NON_GROUPED_ITEMS)
                        ? null
                        : parentId,
                    item.iconIndicator = item.iconIndicator === undefined ? "" : item.iconIndicator,
                    itemRow = item;
            }
            sortedItems[index] = itemRow;
            lnNum += 1;
        });

        let retValue = sortItemListByPosition(sortedItems);
        return (retValue ? retValue : sortItemListByPosition(itemList));


    };

    // this useEffect is used to recompute the group totals after deleting an item
    useEffect(() => {
        if (computeTotals) {
            if (itemList.length > 0) {
                const hasAssembly = itemList.some((item) => item.rowType === "A");
                const updatedItem = hasAssembly ? computeAssemblytotalByGroup(itemList, true) : itemList;
                let sortedItems = [];
                if (updatedItem.some((p=>p.position === undefined))) {
                    sortedItems = itemPositioning(true, updatedItem);
                } else sortedItems = updatedItem;
                
                let updatedItemList = reComputeGroupTotals(sortedItems);
                setItemList(updatedItemList);
            }
            else {
                // reset footer
                setTotalItemPrice(0);
                setTotalstEecostRound(0);
                setTotalStFutureCost(0);
                setTotalActCostEAExact(0);
                setTotalListPrice(0);
                setTotalCost(0);
                setTotalNettEA(0);
                setMaxMargin(0);
                setActMargin(0);
                setMaxFrcMargin(0);
                setFooterDisc(0);
            }
            setComputeTotals(false);
        }
    }, [computeTotals]);

    const handlePageChange = ({ skip, take }) => {
        setitemsPageNumber(skip / take + 1);
        setitemsPageSize(take);
    };


    const createGroup = () => {
        if (isEnabledAddGroup === false) return;
        isEnabledAddGroup = false;
        let groupId, groupName, subGroupId, subGroupName, parentGroupId, item, newGroup;
        sessionStorage.setItem("stateUpdate", false);
        item = createGroupRow(uuidv4(), groupCount);

        item.l60 = item.l60 === 0 ? "" : item.l60;
        item.l55 = item.l55 === 0 ? "" : item.l55;
        item.l50 = item.l50 === 0 ? "" : item.l50;
        item.l20 = item.l20 === 0 ? "" : item.l20;

        newGroup = item;
        groupId = item.groupId;
        subGroupId = item.subGroupId;
        groupName = item.groupName;
        subGroupName = item.subGroupName;
        parentGroupId = item.id;
        let emptyRow = {};
        let updatedItemList = [];
        if (itemList.at(-1).stStockCode.length === 0 && itemList.at(-1).rowType !== "G" && itemList.at(-1).rowType !== "SG" && itemList.at(-1).rowType === "") {
            emptyRow = itemList.at(-1);
            emptyRow.groupId = groupId;
            emptyRow.subGroupId = subGroupId;
            emptyRow.groupName = groupName;
            emptyRow.subGroupName = subGroupName;
            emptyRow.parentId = parentGroupId;
            emptyRow.position = undefined
            itemList[itemList.length - 1] = emptyRow;

            if (itemList.length > 1) {
                updatedItemList = [...itemList.slice(0, -1), newGroup, ...itemList.slice(-1)];
            } else if (itemList.length === 1) {
                updatedItemList = [newGroup, ...itemList];
            }


        } else {
            emptyRow = createEmptyRow(groupId, groupName, subGroupId, subGroupName, "", "", "", parentGroupId);
            updatedItemList = [...itemList, newGroup, emptyRow];
        }
        // let updatedItemList = [...itemList.slice(0, curRow), subGroup, emptyRow, ...itemList.slice(curRow)];

        const updateditem = itemPositioning(true, updatedItemList);
        setSubGroupCount((prev) => prev + 1);
        setItemList(updateditem);
        updateQuoteDetailsForId(id, { selectedItems: updateditem });
    };

    const addGroup = (e) => {
        setIsQuoteSaved(false);
        createGroup(e);
        setAddButtonClick(true);
    };

    const [isLastRowVisible, setIsLastRowVisible] = useState(true);

    const createSubGroup = () => {

        let groupId, groupName, subGroupId, subGroupName, parentGroupId, parentSubGroupId, item;

        if (typeof itemList.at(-1) === "undefined") {
            toast.error("Please create a group first.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        if (selectedItems.length === 0) {
            toast.error("Please select a group first.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        if (selectedItems.length > 0) {
            if (selectedItems[0].rowType !== "G") {
                toast.error("Please select a group header row.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }
            item = selectedItems[0];
        } else {
            item = itemList.at(-1);
        }
        groupId = item.groupId;
        groupName = item.groupName;
        parentGroupId = item.id;
        const newSubGroupId = uuidv4();
        const subGroup = createSubGroupRow(groupId, groupCount, newSubGroupId, subGroupCount, parentGroupId);
        subGroup.l60 = subGroup.l60 === 0 ? "" : subGroup.l60;
        subGroup.l55 = subGroup.l55 === 0 ? "" : subGroup.l55;
        subGroup.l50 = subGroup.l50 === 0 ? "" : subGroup.l50;
        subGroup.l20 = subGroup.l20 === 0 ? "" : subGroup.l20;
        parentSubGroupId = subGroup.id;
        subGroupId = subGroup.subGroupId;
        let emptyRow = {};
        subGroup.groupName = groupName;
        subGroupName = subGroup.subGroupName;
        let withActualEmptyRow = false;
        // const emptyRow = createEmptyRow(groupId, subGroup.groupName, subGroup.subGroupId, subGroup.subGroupName, "","","",parentSubGroupId);
        let updatedItemList = [];
        if (itemList.at(-1).stStockCode.length === 0 && itemList.at(-1).rowType !== "G"
            && itemList.at(-1).rowType !== "SG" && itemList.at(-1).rowType === "") {

            const lastRow = itemList.at(-1);
            if (lastRow.groupId === item.groupId) {

                emptyRow = itemList.at(-1);
                emptyRow.groupId = groupId;
                emptyRow.subGroupId = subGroupId;
                emptyRow.groupName = groupName;
                emptyRow.subGroupName = subGroupName;
                emptyRow.parentId = parentSubGroupId;
                emptyRow.position = undefined;
                itemList[itemList.length - 1] = emptyRow;

            } else {

                emptyRow = createEmptyRow(subGroup.groupId, subGroup.groupName, subGroup.subGroupId, subGroup.subGroupName, "", "", "", parentSubGroupId);
                emptyRow.position = undefined;
                withActualEmptyRow = true;
            }

            if (itemList.length > 1) {
                let idx= item.position > 0 ? item.position - 1 : 0;
                if (item.rowType === "G") {
                    const itm = itemList.filter((i) => i.groupId === item.groupId);
                    idx = itm.sort((a, b) => a.position - b.position).at(-1).position;
                } else {
                    idx = itemList.findIndex((i) => i.rowId === item.rowId) + 1;
                }
                withActualEmptyRow
                    ? updatedItemList = [...itemList.slice(0, idx), subGroup, emptyRow, ...itemList.slice(idx)]
                    : updatedItemList = [...itemList.slice(0, -1), subGroup, ...itemList.slice(-1)];
            } else if (itemList.length === 1) {
                updatedItemList = [subGroup, ...itemList];
            }

        } else {
            emptyRow = createEmptyRow(groupId, groupName, subGroupId, subGroupName, "", "", "", parentGroupId);
            updatedItemList = [...itemList, subGroup, emptyRow];
        }

        // let updatedItemList = [...itemList.slice(0, curRow), subGroup, emptyRow, ...itemList.slice(curRow)];
        const updateditem = itemPositioning(true, updatedItemList);
        setSubGroupCount((prev) => prev + 1);
        setItemList(updateditem);
        updateQuoteDetailsForId(id, { selectedItems: updateditem });
    };

    const insertGroup = () => {
        if (selectedItems.length <= 0) {
            toast.error("Please select a row to insert a group", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        if (selectedItems.length > 1) {
            toast.error("Please select a single row to insert a group", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        let groupId, groupName, subGroupId, subGroupName, parentGroupId, newGroup, item;
        const itemIndex = itemList.findIndex((item) => item.rowId === selectedItems[0].rowId);
        // let updatedItemList = [...itemList];

        if (selectedItems.length > 0) {
            if (selectedItems[0].rowType !== "G" && !selectedItems[0].groupName.startsWith(GroupItemTypes.NON_GROUPED_ITEMS)) {
                toast.error("Please select a group header row.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            }
        }
        item = createGroupRow(uuidv4(), groupCount);
        newGroup = item;
        groupId = item.groupId;
        subGroupId = item.subGroupId;
        groupName = item.groupName;
        subGroupName = item.subGroupName;
        parentGroupId = item.id;
        // const emptyRow = createEmptyRow(groupId, groupName, subGroupId, subGroupName, "","","",parentGroupId);
        let updatedItemList = [];
        if (itemIndex === 0) {
            updatedItemList = [newGroup, ...itemList];
        } else if (itemIndex > 0) {
            updatedItemList = [...itemList.slice(0, itemIndex), newGroup, ...itemList.slice(itemIndex)];
        }

        // let updatedItemList = [...itemList, newGroup, emptyRow];
        const updateditem = itemPositioning(true, updatedItemList);
        setSubGroupCount((prev) => prev + 1);
        setItemList(updateditem);
        updateQuoteDetailsForId(id, { selectedItems: updateditem });
    };

    const insertSubGroup = () => {


        let groupId, groupName, subGroupId, subGroupName, parentGroupId, parentSubGroupId, item;
        const itemIndex = itemList.findIndex((item) => item.rowId === selectedItems[0].rowId);

        if (selectedItems.length > 0) {
            // //     if (selectedItems[0].rowType !== "SG") {
            // //         toast.error("Please select a sub group header row.", {
            // //             position: "top-center",
            // //             transition: Slide,
            // //         }); 
            // //         return;
            // //     }
            item = selectedItems[0];
        }

        groupId = item.groupId;
        subGroupId = item.subGroupId;
        groupName = item.groupName;
        subGroupName = item.subGroupName;
        parentGroupId = item.parentId;

        const newSubGroupId = uuidv4();
        const subGroup = createSubGroupRow(groupId, groupCount, newSubGroupId, subGroupCount, parentGroupId);
        parentSubGroupId = subGroup.id;
        let emptyRow = {};
        let updatedItemList = [];
        if (itemIndex !== itemList.length - 1) {
            emptyRow = createEmptyRow(subGroup.groupId, subGroup.groupName, subGroup.subGroupId, subGroup.subGroupName, "", "", "", parentSubGroupId);
            updatedItemList = [...itemList.slice(0, itemIndex), subGroup, emptyRow, ...itemList.slice(itemIndex)];
        } else {
            updatedItemList = [...itemList.slice(0, itemIndex), subGroup, ...itemList.slice(itemIndex)];
        }

        setSubGroupCount((prev) => prev + 1);
        const sortedItems = itemPositioning(true, updatedItemList);
        setItemList(sortedItems);
        updateQuoteDetailsForId(id, { selectedItems: sortedItems });
    };

    const [isCollapseAll, setIsCollapseAll] = useState(null);
    const [isExpandCollapseTriggered, setIsExpandCollapseTriggered] = useState(null);
    const randNum = () => { return Math.floor(Math.random() * 10) };
    const handleAddGroup = (e, index = undefined) => {
        const option = index !== undefined ? index : e?.itemIndex;

        switch (option) {
            case 0:
                createSubGroup();
                setAddButtonClick(true);
                break;
            case 1:
                insertGroup();
                setAddButtonClick(true);
                break;
            case 2:
                insertSubGroup();
                setAddButtonClick(true);

                break;
            case 3:
                setIsCollapseAll(false);
                setIsExpandCollapseTriggered(randNum());
                break;
            case 4:
                setIsCollapseAll(true);
                setIsExpandCollapseTriggered(randNum());
                break;
            default:
                break;
        }
    };

    const calculateSubGroupWiseTotal = (uniqueId, itemList, field) => {
        const subGroupItems = itemList.filter((item) => item.subGroupId === uniqueId && item.stStockCode !== "");
        const subGroupTotal = subGroupItems.reduce(
            (total, item) => total + (typeof item[field] !== "string" ? item[field] : 0),
            0
        );
        const total = subGroupTotal.toFixed(2);
        if (field === "stEecost") {
            return parseFloat(total);
        } else if (field === "stFutureCost") {
            return parseFloat(total);
        } else if (field === "actCostExact") {
            return parseFloat(total);
        } else {
            return parseFloat(parseFloat(total).toFixed(2));
        }
    };

    // const calculateGroupWiseTotal = (itemList, field) => {
    //     const groupTotal = itemList.reduce(
    //         (total, item) =>
    //             total +
    //             (typeof item[field] !== "string"
    //                 ? item[field]
    //                 : typeof item[field] == "string" && item[field].includes("%")
    //                 ? parseFloat(item[field].replace("%", ""))
    //                 : 0),
    //         0
    //     );
    //     if (field === "stEecost" || field === "stFutureCost" || field === "actCostExact") {
    //         return parseFloat(groupTotal);
    //     } else {
    //         return parseFloat(parseFloat(groupTotal).toFixed(2));
    //     }
    // };

    // const calcGrpLabourTotal = (itmToCompute) => {
    //     let totalHr = 0;
    //     let totCost = 0;
    //     let totPrice = 0;
    //     let labTime = 0;
    //     let adjQty = 0;
    //     let labCost = 0;

    //     let returnValue = {
    //         totalHr: 0,
    //         totCost: 0,
    //         totPrice: 0,
    //         netEA: 0,
    //     };

    //     const subGroupItems = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "AI");
    //     const assemblyHeader = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "A");
    //     subGroupItems.map((grpItem) => {
    //         let labItem = loadItem.filter((labourItem) => labourItem.labStockCode == grpItem.stStockCode);

    //         if (labItem.length > 0) {
    //             labTime = labItem[0].labTime;
    //             adjQty = grpItem.qty;
    //             labCost = labItem[0].labCost;
    //             totalHr = totalHr + Number(labTime) * Number(adjQty);
    //             // totCost = totCost + Math.round(Number((labCost / labTime) * 60) * adjQty);
    //             totCost = totCost + Number(labCost * adjQty);
    //             return;
    //         }
    //     });

    //     totalHr = totalHr == 0 ? totalHr : Number(totalHr / 60); ////totalHr < 60 ? 1 : Math.ceil(totalHr / 60);

    //     totCost = totCost.toFixed(2);
    //     // totPrice = (totCost / ((100 - parseInt(currentMargin[0])) / 100)).toFixed(2);
    //     const netEAcomputed = parseFloat(
    //         assemblyHeader[0].stEecostRound / ((100 - Number(assemblyHeader[0].margin)) / 100)
    //     ).toFixed(2);
    //     returnValue.totalHr = totalHr;
    //     returnValue.totCost = totCost;
    //     returnValue.totPrice = parseFloat(Number(totalHr) * netEAcomputed).toFixed(2);
    //     returnValue.qty = totalHr;
    //     returnValue.netEA = parseFloat(netEAcomputed).toFixed(2);

    //     return returnValue;
    // };

    // const calcAllGrpLabourTotal = () => {
    //     let totalHr = 0;
    //     let totCost = 0;
    //     let totPrice = 0;
    //     let labTime = 0;
    //     let adjQty = 0;
    //     let labCost = 0;
    //     let netEA = 0;

    //     let returnValue = {
    //         totalHr: 0,
    //         totCost: 0,
    //         totPrice: 0,
    //         netEA: 0,
    //     };

    //     itemList.map((mainItm) => {});

    //     const subGroupItems = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "AI");
    //     const assemblyHeader = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "A");
    //     subGroupItems.map((grpItem) => {
    //         let labItem = loadItem.filter((labourItem) => labourItem.labStockCode == grpItem.stStockCode);

    //         if (labItem.length > 0) {
    //             labTime = labItem[0].labTime;
    //             adjQty = grpItem.qty;
    //             labCost = labItem[0].labCost;
    //             totalHr = totalHr + Number(labTime) * Number(adjQty);
    //             // totCost = totCost + Math.round(Number((labCost / labTime) * 60) * adjQty);
    //             totCost = totCost + Number(labCost * adjQty);
    //             return;
    //         }
    //     });

    //     totalHr = totalHr == 0 ? totalHr : Number(totalHr / 60); ////totalHr < 60 ? 1 : Math.ceil(totalHr / 60);

    //     totCost = totCost.toFixed(2);
    //     // totPrice = (totCost / ((100 - parseInt(currentMargin[0])) / 100)).toFixed(2);
    //     const netEAcomputed = parseFloat(
    //         assemblyHeader[0].stEecostRound / ((100 - Number(assemblyHeader[0].margin)) / 100)
    //     ).toFixed(2);
    //     returnValue.totalHr = totalHr;
    //     returnValue.totCost = totCost;
    //     returnValue.totPrice = parseFloat(Number(totalHr) * netEAcomputed).toFixed(2);
    //     returnValue.qty = totalHr;
    //     returnValue.netEA = parseFloat(netEAcomputed).toFixed(2);

    //     return returnValue;
    // };

    const calcGrpLabourTotal = (itmToCompute) => {
        let totalHr = 0;
        let totCost = 0;
        let labTime = 0;
        let adjQty = 0;
        let labCost = 0;

        let returnValue = {
            totalHr: 0,
            totCost: 0,
            totPrice: 0,
            nettEA: 0,
        };

        const subGroupItems = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "AI");
        const assemblyHeader = itemList.filter((item) => item.groupId === itmToCompute.groupId && item.rowType === "A");
        subGroupItems.map((grpItem) => {
            let labItem = loadItem.filter((labourItem) => labourItem.labStockCode == grpItem.stStockCode);

            if (labItem.length > 0) {
                labTime = labItem[0].labTime;
                adjQty = grpItem.qty;
                labCost = labItem[0].labCost;
                totalHr = totalHr + Number(labTime) * Number(adjQty);
                // totCost = totCost + Math.round(Number((labCost / labTime) * 60) * adjQty);
                totCost = totCost + Number(labCost * adjQty);
                return;
            }
        });

        totalHr = totalHr == 0 ? totalHr : Number(totalHr / 60); ////totalHr < 60 ? 1 : Math.ceil(totalHr / 60);

        totCost = totCost.toFixed(2);
        // totPrice = (totCost / ((100 - parseInt(currentMargin[0])) / 100)).toFixed(2);
        const nettEAcomputed = parseFloat(
            assemblyHeader[0].stEecostRound / ((100 - Number(assemblyHeader[0].margin)) / 100)
        ).toFixed(2);
        returnValue.totalHr = totalHr;
        returnValue.totCost = totCost;
        returnValue.totPrice = parseFloat(Number(totalHr) * nettEAcomputed).toFixed(2);
        returnValue.qty = totalHr;
        returnValue.nettEA = parseFloat(nettEAcomputed).toFixed(2);

        return returnValue;
    };

    const handleChangeView = (event) => {

        handleItemGrid(event.target.index, event.target.value.text);
        setDropdownValue(event.target.value.text);
        setQteCurGridViewType(event.target.value.text);
        setIsQuoteSaved(false);
        setSelectedViewType(event.target.value.text);
        setSelectedView(event.target.value);
        // sessionStorage.setItem("selectedView", event.target.value.text);
    };
    const setSelectedViewType = async (selectedViewType) => {
        // Retrieve existing viewTypes from sessionStorage if available
        let viewTypes = JSON.parse(sessionStorage.getItem('selectedView')) || [];

        // Get sessionId from sessionStorage or default to '0'
        let sessionId = (sessionStorage.getItem('id') && sessionStorage.getItem('id').trim() !== '') ? sessionStorage.getItem('id') : '0';

        selectedViewType = (selectedViewType === null || selectedViewType === "") ? viewOptions[0].text : selectedViewType;

        if (selectedViewType) {
            // Set default sessionViewType if null or empty
            if (typeof selectedViewType === 'string' && isValidJSON(selectedViewType)) {
                selectedViewType = JSON.parse(selectedViewType);
            }

            // Check if sessionId already exists in viewTypes
            const existingIndex = viewTypes.findIndex(item => Object.keys(item)[0] === sessionId);

            if (existingIndex === -1) {
                // sessionId does not exist, add new entry
                viewTypes.push({ [sessionId]: selectedViewType });
            } else {
                // sessionId already exists, update existing entry
                viewTypes[existingIndex] = { [sessionId]: selectedViewType };
            }

            // Store updated viewTypes back to sessionStorage
            sessionStorage.setItem('selectedView', JSON.stringify(viewTypes));
            const selectedSession = viewOptions.find(x => x.text === selectedViewType);
            const findIndex = viewOptions.findIndex(x => x.text === selectedViewType);
            handleItemGrid(findIndex, selectedSession.text);
            setDropdownValue(selectedSession.text);
            setQteCurGridViewType(selectedSession.text);
            setIsQuoteSaved(false);
            setSelectedView(selectedSession);
        }
    };


    function isValidJSON(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        LoadLabour();
    }, []);

    useEffect(() => {
        onlabourHours();
    }, [itemAssemblyList]);

    useEffect(() => {
        // if(selectedIndex > 0) {
        //     if (itemList[selectedIndex-1]?.rowType === "A") {
        //         setSelectedItems([...itemList.filter((f)=>f.assemblyId === itemList[selectedIndex-1].assemblyId)]);
        //         setCurentselected([...itemList.filter((f)=>f.assemblyId === itemList[selectedIndex-1].assemblyId)]);
        //     }
        // }
    }, [selectedIndex]);

    const LoadLabour = async () => {
        const response = await getResponse("LabourHours/GetLabourItems", {});
        //// Loop on the resuit of Labour items
        setLoadItem([]);

        //// Set the item values
        response.map((value) => {
            setGridData((gridData) => {
                var newgridData = gridData;
                newgridData.push({
                    labId: value["labId"],
                    labStockCode: value["labStockCode"],
                    labPos: value["labPos"],
                    labourId: value["labourId"],
                    labourHour: value["labourHour"],
                    labDescription: value["labDescription"],
                    labTime: value["labTime"],
                    labCost: value["labCost"],
                    defQty: value["defQty"],
                    adjQty: value["adjQty"],
                    totalCost: value["totalCost"].toFixed(2),
                    totalTime: value["totalTime"],
                    appliedMargin: currentMargin[0],
                    netEA: 0,
                    //commented by Manoj on 2023-09-11
                    //stId: "",
                    rowId: "",
                });
                return newgridData;
            });
            setLoadItem(gridData);
        });
    };

    //// useComputedNetEA is added for parts
    ////every changes on the assembly call this method and call the triggerCompute after this to apply also the changes on the group level if grouping is applied
    const computeAssemblytotalByGroup = (itemData, useComputedNetEA = false) => {
        let hasLabourItem = false;
        let assemblyHasNoItem = false;
        const updatedData = itemData.map((item) => {
            assemblyHasNoItem = false;
            if (item.rowType === "A") {
                hasLabourItem = true;
                let qtyforCompute = itemData.filter((f) => f.assemblyId === item.assemblyId && f.rowType === "AI");
                let totalHrs = 0;
                let totCost = 0;
                let totLabCost = 0;
                let totalLabCost = 0;
                let labTime = 0;
                let totalLabTime = 0;
                let totalCost = 0;
                let totalFCost = 0;
                let hrs = 0;
                item.margin = Object(item.margin).toString().includes("%") ? Object(item.margin).replace("%", "") : item.margin;
                qtyforCompute.map((m) => {
                    let itm = loadItem.filter((f) => f.labStockCode === m.stStockCode);
                    hrs = 0;
                    totCost = 0;
                    totLabCost = 0;
                    labTime = 0;
                    if (itm.length > 0) {
                        hrs = Number(itm[0].labTime) * Number(m.qty);
                        totCost = Number(itm[0].labCost) * Number(m.qty);
                        labTime = Number(itm[0].labTime);
                        totLabCost = Number(itm[0].labCost);
                    }
                    totalHrs += hrs;
                    totalCost += totCost;
                    totalLabTime += labTime;
                    totalLabCost += totLabCost;
                });

                totalHrs = totalHrs == 0 ? totalHrs : totalHrs / 60;
                totalFCost = totalLabCost == 0 ? 0 : (Number(totalLabCost) / Number(totalLabTime)) * 60;
                const margin =
                    parseFloat(item.margin) < 0 || item.margin.length === 0
                        ? parseFloat(currentMargin[0])
                        : item.margin;
                const nettEA = parseFloat((totalFCost / ((100 - Number(margin)) / 100)).toFixed(2));
                item.qty = parseFloat(customToFixedDecimal(Number(totalHrs), 4)); //qty = total hrs on labour
                item.nettEA = parseFloat(useComputedNetEA ? item.nettEA : nettEA);
                item.totalPrice = parseFloat(newPrice) > 0 ? item.totalPrice : parseFloat(parseFloat(Number(item.qty) * nettEA).toFixed(2)); //parseFloat(currTotalPrice);
                item.totalCost = parseFloat(parseFloat(totalCost).toFixed(2));

                if (qtyforCompute.length === 0) assemblyHasNoItem = true;
            }

            if (!assemblyHasNoItem) return item;
            else return {};
        });

        const getValidItems = updatedData.filter((f) => f.rowId); // empty rows is/are the row for assembly header without lines or has been remove during delete.
        if (hasLabourItem) updateQuoteDetailsForId(id, { selectedItems: getValidItems });

        return getValidItems;
    };

    const [getDBExtraCharge, setGetDBExtraCharge] = useState(0);
    const getDBExtraChargeFromSettings = async () => {
        let systemSettings = null;

        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);
        setGetDBExtraCharge(dbExtraCharge);
        return dbExtraCharge;
    }

    const reComputeGroupTotals = (itemData) => {
        const dbExtraCharge = getDBExtraCharge;
        let updateItem = itemList;
        itemData.map((item) => {
            if (item.groupName === GroupItemTypes.NON_GROUPED_ITEMS) {
                updateItem = triggerComputeGrpTotals(
                    [item],
                    updateItem,
                    "Update",
                    null, //newItems[0].groupId,
                    null, //sbgrpId,
                    dbExtraCharge
                );
            } else {
                updateItem = triggerComputeGrpTotals(
                    [item],
                    updateItem,
                    "Update",
                    item.groupId, //newItems[0].groupId,
                    item.subGroupId, //sbgrpId,
                    dbExtraCharge
                );
            }
        });

        return updateItem;
    }

    const customToFixedDecimal = (value, decimalCount) => {
        if (Math.floor(value) === value) return value;
        const deciLen = value.toString().split(".")[1].length || 0;
        if (deciLen > decimalCount) {
            return value.toFixed(decimalCount);
        } else return value;
    }

    const onlabourHours = async () => {
        const grpId = uuidv4();
        let newItem = [];
        let removedItem = [];

        let systemSettings = null;

        if (settings === null) {
            const data = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
                userId: user?.homeAccountId,
            });
            systemSettings = data[0];
        } else {
            systemSettings = settings;
        }

        const dbExtraCharge = parseInt(systemSettings?.dbextraCharge);

        if (itemAssemblyList.length > 0) {
            const uniqueStId = uuidv4();

            if (editLabourItems.length > 0) {
                /////////EDIT ASSEMBLY
                //// Remove an item not exist on the new list
                const header = itemList.filter(
                    (f) => f.assemblyId === selectedItems[0].assemblyId && f.rowType === "A"
                );
                let itmIdx = 0;
                let AHGroupId = "";
                let AHGroupName = "";
                let AHSubGroupId = "";
                let AHSubGroupName = "";
                let AHAssemblyId = "";
                let displayAssemblyGrpName = "";
                let assemblyGrpName = "";

                if (header.length > 0) {
                    AHGroupId = header[0].groupId;
                    AHGroupName = header[0].groupName;
                    AHSubGroupId = header[0].subGroupId;
                    AHSubGroupName = header[0].subGroupName;
                    AHAssemblyId = header[0].assemblyId;
                    displayAssemblyGrpName = header[0].displayAssemblyGroupName;
                    assemblyGrpName = header[0].assemblyGroupName;
                }

                let itmDetail = editLabourItems.filter((f) => f.stStockCode.length > 0);
                itmDetail.filter((item) => {
                    var checkItemExist = itemAssemblyList.filter((e) => e.rowId === item.rowId);
                    if (checkItemExist.length === 0) {
                        removedItem.push(item);
                    }
                });

                ///check for new items
                itemAssemblyList.map((item) => {
                    var checkItemExist = itemList.filter((e) => e.rowId === item.rowId);
                    let count = checkItemExist.length;
                    if (count === 0) {
                        let uniqueStId = uuidv4();
                        newItem.push({
                            stStockCode: item.labStockCode,
                            description: item.labDescription,
                            rowType: "AI", ////Assembly Item
                            listPrice: "",
                            l60: "",
                            l55: "",
                            l50: "",
                            l20: "",
                            // costEA: parseFloat(Number(item.labCost).toFixed(2)),
                            // costEAExact: parseFloat(Number(item.labCost).toFixed(2)),
                            actCost: parseFloat(Number(item.labCost).toFixed(2)),
                            actCostExact: parseFloat(Number(item.labCost).toFixed(2)),
                            lastCost: "",
                            lastCostExact: "",
                            qty: Number(item.adjQty),
                            nettEA: "",
                            margin: "",
                            frcMargin: "",
                            actMargin: "",
                            lcMargin: "",
                            dcOnLp: "",
                            stEecost: parseFloat(Number(item.labCost).toFixed(2)),
                            stEecostRound: parseFloat(Number(item.labCost).toFixed(2)),
                            stFutureCost: parseFloat(Number(item.labCost).toFixed(2)),
                            stFutureCostRound: parseFloat(Number(item.labCost).toFixed(2)),
                            displayGroupName: "",
                            groupId: AHGroupId,
                            groupName: AHGroupName,
                            subGroupId: AHSubGroupId,
                            subGroupName: AHSubGroupName,
                            assemblyId: AHAssemblyId,
                            displaySubGroupName: "",
                            //commented by Manoj on 2023-09-11
                            //stId: uniqueStId,
                            totalPrice: "",
                            totalCost: parseFloat(Number(item.labCost).toFixed(2)),
                            rowId: uuidv4(), //itemAssemblyList[itemAssemblyList.length - 1]?.rowId,
                            subGroupDbExtraId: "",
                            nonGroupDbExtraId: "",
                            displayAssemblyGroupName: displayAssemblyGrpName,
                            assemblyGroupName: assemblyGrpName,
                        });

                        // setHiddenStatus((prevStatus) => {
                        //     return {
                        //         ...prevStatus,
                        //         [newItem.rowId]: { ...prevStatus[newItem.rowId], hideRow: true },
                        //     };
                        // });
                    }
                });
                // Find the item in your data and update the specified column
                ////update line items

                let updatedItemList = itemList.map((item) => {
                    const itm = item;
                    const assembly = itemAssemblyList.filter((e) => e.rowId === itm.rowId);
                    item.qty = assembly !== undefined && assembly.length > 0 ? Number(assembly[0]?.adjQty) : item.qty;
                    item.totalCost =
                        assembly !== undefined && assembly.length > 0
                            ? Number(assembly[0]?.adjQty) * item.stEecostRound
                            : item.qty * item.stEecostRound;
                    return item;
                });

                // footer calculation
                const {
                    totalPrice,
                    totalSteEeCostRound,
                    totalStFutureCost,
                    totalActCostExact,
                    totalListPrice,
                    totalNettEA,
                    totalCost,
                    totalMargin,
                    totalActMargin,
                    totalFrcMargin,
                    totalDcOnLp,
                } = footerCalculation(updatedItemList);

                setTotalItemPrice(totalPrice);
                setTotalCostAmt(totalCost);
                setTotalstEecostRound(totalSteEeCostRound);
                setTotalStFutureCost(totalStFutureCost);
                setTotalActCostEAExact(totalActCostExact);
                setTotalListPrice(totalListPrice);
                setTotalCost(totalCost);
                setTotalNettEA(totalNettEA);
                setMaxMargin(totalMargin);
                setActMargin(totalActMargin);
                setMaxFrcMargin(totalFrcMargin);
                setFooterDisc(totalDcOnLp);

                setItemList(updatedItemList);
                updateQuoteDetailsForId(id, { selectedItems: updatedItemList });

                if (removedItem.length > 0) {
                    updatedItemList = updatedItemList.filter((item) => !removedItem.includes(item));

                    // footer calculation
                    const {
                        totalPrice,
                        totalSteEeCostRound,
                        totalStFutureCost,
                        totalActCostExact,
                        totalListPrice,
                        totalNettEA,
                        totalCost,
                        totalMargin,
                        totalActMargin,
                        totalFrcMargin,
                        totalDcOnLp,
                    } = footerCalculation(updatedItemList);

                    setTotalItemPrice(totalPrice);
                    setTotalCostAmt(totalCost);
                    setTotalstEecostRound(totalSteEeCostRound);
                    setTotalStFutureCost(totalStFutureCost);
                    setTotalActCostEAExact(totalActCostExact);
                    setTotalListPrice(totalListPrice);
                    setTotalCost(totalCost);
                    setTotalNettEA(totalNettEA);
                    setMaxMargin(totalMargin);
                    setActMargin(totalActMargin);
                    setMaxFrcMargin(totalFrcMargin);
                    setFooterDisc(totalDcOnLp);

                    setItemList(updatedItemList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
                }

                let newItemList = [];

                ///get the groupId
                //let assemblyGrpId = itmDetail[0]?.groupId;

                ////update header total
                const updatedItem = updatedItemList;
                computeAssemblytotalByGroup(updatedItem);

                // triggerComputeGrpTotals(
                //     updatedItem,
                //     updatedItemList,
                //     "Update",
                //     groupId, //newItems[0].groupId,
                //     subGroupId, //sbgrpId,
                //     dbExtraCharge
                // );

                if (newItem.length > 0) {
                    let curRow = getTheActualRow(selectedItems.at(-1).rowId);
                    newItemList = [
                        ...updatedItemList.slice(
                            0,
                            selectedIndex < 0 ? 1 : curRow + 1
                        ),
                        ...newItem,
                        ...updatedItemList.slice(
                            selectedIndex < 0 ? 1 : curRow + 1
                        ),
                    ];

                    // setItemList(newItemList);
                    // updateQuoteDetailsForId(id, { selectedItems: newItemList });
                    newItemList = itemPositioning(true, newItemList);
                    computeAssemblytotalByGroup(newItemList);

                    triggerComputeGrpTotals(
                        newItem,
                        newItemList,
                        "Update",
                        AHGroupId, //newItems[0].groupId,
                        AHSubGroupId, //sbgrpId,
                        dbExtraCharge
                    );
                }
            } else {
                // debugger;
                /////////ADD or INSERT ASSEMBLY
                //setup the group header for assembly
                let assemblyGrpName = "ASSEMBLY " + groupAssembly;
                const curRow = getTheActualRow(selectedItems.length > 0 ? selectedItems[0].rowId : 0);
                let itmIdx = 0;
                let AHGroupId = "";
                let AHGroupName = "";
                let AHSubGroupId = "";
                let AHSubGroupName = "";
                let parentId = "";

                if (insertLabourItems === true) {
                    itmIdx = curRow <= 0 ? 0 : curRow;
                    if (itmIdx === 0) {
                        AHGroupId = uuidv4();
                        AHGroupName = GroupItemTypes.NON_GROUPED_ITEMS;
                        AHSubGroupId = "";
                        AHSubGroupName = "";
                        parentId = null;
                    } else if (itemList[itmIdx]?.stStockCode.length === 0 && itmIdx > 0) {
                        AHGroupId = itemList[itmIdx - 1]?.groupId;
                        AHGroupName = itemList[itmIdx - 1]?.groupName;
                        AHSubGroupId = itemList[itmIdx - 1]?.subGroupId;
                        AHSubGroupName = itemList[itmIdx - 1]?.subGroupName;
                        parentId = itemList[itmIdx - 1]?.parentId;
                    } else if (
                        itemList[itmIdx]?.stStockCode.length > 0 &&
                        itemList[itmIdx]?.groupName.includes("Non Grouped")
                    ) {
                        AHGroupId = itemList[itmIdx - 1]?.groupId;
                        AHGroupName = itemList[itmIdx - 1]?.groupName;
                        AHSubGroupId = itemList[itmIdx - 1]?.subGroupId;
                        AHSubGroupName = itemList[itmIdx - 1]?.subGroupName;
                        parentId = itemList[itmIdx - 1]?.parentId;
                    } else {
                        AHGroupId = itemList[itmIdx]?.groupId;
                        AHGroupName = itemList[itmIdx]?.groupName;
                        AHSubGroupId = itemList[itmIdx]?.subGroupId;
                        AHSubGroupName = itemList[itmIdx]?.subGroupName;
                        parentId = itemList[itmIdx]?.parentId;
                    }
                } else {
                    itmIdx = itemList.length <= 0 ? 0 : itemList.length - 1;
                    if (!itemList[itmIdx]?.groupId) {
                        AHGroupId = uuidv4();
                        AHGroupName = GroupItemTypes.NON_GROUPED_ITEMS;
                        AHSubGroupId = "";
                        AHSubGroupName = "";
                        parentId = null;
                    } else {
                        AHGroupId = itemList[itmIdx]?.groupId;
                        AHGroupName = itemList[itmIdx]?.groupName;
                        AHSubGroupId = itemList[itmIdx]?.subGroupId;
                        AHSubGroupName = itemList[itmIdx]?.subGroupName;
                        parentId = itemList[itmIdx]?.parentId;
                    }
                }

                let newRowId = uuidv4();
                let assemblyId = uuidv4();
                let id = uuidv4();
                let AssemblyHeader = {
                    stStockCode: labourCostName,
                    description: "FITTING, WIRING & TESTING INCLUDED",
                    rowType: "A",
                    listPrice: "",
                    hideRow: true,
                    l60: "",
                    l55: "",
                    l50: "",
                    l20: "",
                    stEecostRound: parseFloat(Number(futCost).toFixed(2)),
                    stEecost: parseFloat(Number(futCost).toFixed(2)),
                    actCostExact: parseFloat(Number(futCost).toFixed(2)),
                    lastCost: "",
                    qty: parseFloat(customToFixedDecimal(Number(totalTimeApprx), 4)),
                    nettEA: parseFloat(Number(currNetEA).toFixed(2)),
                    margin: parseFloat(Number(currentMargin).toFixed(2)),
                    actMargin: parseFloat(Number(currentMargin).toFixed(2)),
                    frcMargin: parseFloat(Number(currentMargin).toFixed(2)),
                    // stEecostRound: parseFloat(Number(futCost).toFixed(2)),
                    actCost: Number(futCost),
                    stFutureCostRound: Number(futCost),
                    stFutureCost: Number(futCost),
                    groupName: labourCostName,
                    displayGroupName: "",
                    groupName: AHGroupName,
                    groupId: AHGroupId,
                    subGroupName: AHSubGroupName,
                    subGroupId: AHSubGroupId,
                    displaySubGroupName: "",
                    displayAssemblyGroupName: assemblyGrpName,
                    assemblyGroupName: assemblyGrpName,
                    //commented by Manoj on 2023-09-11
                    //stId: uniqueStId,
                    totalPrice: parseFloat(Number(currNetEA) * Number(totalTimeApprx)), //parseFloat(currTotalPrice).toFixed(2),
                    totalCost: Number(currtotalCost),
                    rowId: newRowId,
                    groupDbExtraId: "",
                    subGroupDbExtraId: "",
                    nonGroupDbExtraId: "",
                    assemblyId: assemblyId,
                    id: id,
                    parentId: parentId,
                    groupLevelName: labourCostName
                };

                //setup the line item for assembly
                let newdata = itemAssemblyList.map((item) => {
                    let uniqueStId = uuidv4();
                    return {
                        stStockCode: item.labStockCode,
                        description: item.labDescription,
                        rowType: "AI", ////Assembly Item
                        listPrice: "",
                        l60: "",
                        l55: "",
                        l50: "",
                        l20: "",
                        hideRow: true,
                        // costEA: parseFloat(Number(item.labCost).toFixed(2)),
                        // costEAExact: parseFloat(Number(item.labCost).toFixed(2)),
                        actCost: parseFloat(Number(item.labCost).toFixed(2)),
                        actCostExact: parseFloat(Number(item.labCost)),
                        lastCost: "",
                        lastCostExact: "",
                        stEecost: parseFloat(Number(item.labCost).toFixed(2)),
                        stEecostRound: parseFloat(Number(item.labCost).toFixed(2)),
                        stFutureCost: parseFloat(Number(item.labCost).toFixed(2)),
                        stFutureCostRound: parseFloat(Number(item.labCost).toFixed(2)),
                        qty: Number(item.adjQty),
                        nettEA: "",
                        margin: "",
                        frcMargin: "",
                        actMargin: "",
                        lcMargin: "",
                        dcOnLp: "",
                        displayGroupName: "",
                        groupName: AHGroupName,
                        groupId: AHGroupId,
                        subGroupName: AHSubGroupName,
                        subGroupId: AHSubGroupId,
                        displaySubGroupName: "",
                        assemblyGroupName: assemblyGrpName,
                        displayAssemblyGroupName: "",
                        //commented by Manoj on 2023-09-11
                        //stId: uniqueStId,
                        totalPrice: "",
                        totalCost: parseFloat(Number(item.labCost).toFixed(2) * Number(item.adjQty)),
                        rowId: uuidv4(),
                        subGroupDbExtraId: "",
                        nonGroupDbExtraId: "",
                        assemblyId: assemblyId,
                        id: uuidv4(),
                        parentId: id,
                        groupLevelName: ""
                    };
                });

                let updatedItemList = [];

                if (insertLabourItems === true) {
                    const sel = selectedItems[0];
                    //let curRow = getTheActualRow(sel.rowId);
                    updatedItemList = [
                        ...itemList.slice(0, itmIdx),
                        AssemblyHeader,
                        ...newdata,
                        ...itemList.slice(itmIdx),
                    ];
                    setInsertLabourItems(false);
                } else {
                    updatedItemList = [...itemList.slice(0, itmIdx), AssemblyHeader, ...newdata, ...itemList.slice(itmIdx)];
                }
                let assemblyItem = computeAssemblytotalByGroup(updatedItemList);
                // assemblyItem = itemPositioning(true, assemblyItem);
                setItemList(assemblyItem);
                updateQuoteDetailsForId(id, { selectedItems: assemblyItem });
                setComputeTotals(true);


                // triggerComputeGrpTotals(
                //     [AssemblyHeader],
                //     updatedItemList,
                //     "update",
                //     AHGroupId, //newItems[0].groupId,
                //     AHSubGroupId, //sbgrpId,
                //     dbExtraCharge
                // );

                //updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            }

            setEditLabourItems([]);
            setItemAssemblyList([]);
            setEditLabourHeader([]);
            setSelectedIndex(-1);
            setSelectedItems([]);
            setGroupAssembly((prev) => prev += 1);
        }
    };

    const onInsertNotes = () => {

        // let idx = selectedIndex < 0 ? 0 : selectedIndex - 1;
        //idx = (itemList[selectedIndex] !== undefined ? (itemList[selectedIndex].rowType === "A" && selectedIndex > 1 ? idx-1 : idx) : 0); //// checking to make sure that the line note will be inserted on top of assembly
        let selectedItem = selectedItems;
        if (selectedItem.length === 0 && itemList.length > 0) {
            // toast.error("Please choose a row before inserting a line note.", { autoClose: 5000, position: "top-center", transition: Slide, });
            // return;
            selectedItem = [itemList.at(-1)];
        }

        if (selectedItem.length > 1) {
            toast.error("Please select a single row when inserting a line note.", { autoClose: 5000, position: "top-center", transition: Slide, });
            return;
        }

        const curRow = selectedItem == 0 ? 0 : getTheActualRow(selectedItem[0].rowId);

        if (curRow === -1) {
            toast.error("You are not allowed to insert a line note on this part.", { autoClose: 5000, position: "top-center", transition: Slide, });
            return;
        }

        setInsertLineNotes(true);
        const groupId =
            selectedItem.length === 0 || selectedItem[0].displayGroupName !== ""
                ? curRow === 0
                    ? uuidv4()
                    : itemList[curRow - 1].groupId
                : selectedItem[0].groupId;

        const groupName =
            selectedItem.length === 0 || selectedItem[0].displayGroupName !== ""
                ? curRow === 0
                    ? GroupItemTypes.NON_GROUPED_ITEMS
                    : itemList[curRow - 1].groupName
                : selectedItem[0].groupName;

        const subGroupId =
            selectedItem.length === 0 || selectedItem[0].displaySubGroupName !== ""
                ? curRow === 0
                    ? ""
                    : itemList[curRow - 1].subGroupId
                : selectedItem[0].subGroupId;

        const subGroupName =
            selectedItem.length === 0 || selectedItem[0].displaySubGroupName !== ""
                ? curRow === 0
                    ? ""
                    : itemList[curRow - 1].subGroupName
                : selectedItem[0].subGroupName;

        const parentId =
            selectedItem.length === 0
                ? curRow === 0
                    ? ""
                    : itemList[curRow - 1].parentId
                : selectedItem[0].parentId;

        let lineNotes = {
            stStockCode: "Line Notes ...", //// if this is change, also change the GroupableDataGridView edit cell. This is use for initial creation of line notes
            description: "Make Line Notes",
            // type: "LN",
            rowType: "LN",
            listPrice: "",
            l60: "",
            l55: "",
            l50: "",
            l20: "",
            costEA: "",
            costEAExact: "",
            actCost: "",
            actCostExact: "",
            lastCost: "",
            lastCostExact: "",
            stEecost: "",
            stEecostRound: "",
            stFutureCost: "",
            stFutureCostRound: "",
            groupDbExtraId: "",
            nonGroupDbExtraId: "",
            qty: "",
            nettEA: "",
            margin: "",
            frcMargin: "",
            // groupName:
            //     itemList.length > 0 && itemList[idx]?.groupName !== undefined
            //         ? itemList[idx]?.groupName
            //         : "Non Grouped Items",
            groupName: groupName,
            groupId: groupId,
            displayGroupName: "",
            subGroupName: subGroupName,
            subGroupId: subGroupId,
            displaySubGroupName: "",
            //commented by Manoj on 2023-09-11
            //stId: uuidv4(),
            totalPrice: "",
            totalCost: "",
            rowId: uuidv4(),
            soh: 0,
            id: uuidv4(),
            parentId: parentId,
        };

        let updatedItemList = [];


        if (itemList.length > 1) {
            updatedItemList = [...itemList.slice(0, curRow), lineNotes, ...itemList.slice(curRow)];
        } else if (itemList.length === 1) {
            updatedItemList = [lineNotes, ...itemList];
        } else {
            updatedItemList = [...itemList.slice(0, curRow), lineNotes, ...itemList.slice(curRow)];
        }

        updatedItemList = itemPositioning(true, updatedItemList);
        setItemList(updatedItemList);
        updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        setInsertLineNotes(false);
        setSelectedIndex(-1);
        setSelectedItems([]);
        setIsQuoteSaved(false);
    };

    const handleLineNoteChanges = (event) => {

        // const editItemList = e.dataItem.dataItem.description = e.value;
        const { field } = event;
        const value = event.value;
        const updatedItemList = itemList.map((stock) => {
            let itm = stock;
            if (itm.stId === event.dataItem.stId && itm.rowType === "LN") {
                itm[field] = value;
            }
            return itm;
        });

        setItemList(updatedItemList);
        setIsQuoteSaved(false);
        updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
    };

    const editLabourHours = () => {
        setEditLabourItems([]);
        setItemAssemblyList([]);
        setEditLabourHeader([]);
        const forEditItems = itemList.filter((f) => f.rowType === "AI" && f.assemblyId === selectedItems[0].assemblyId);

        setEditLabourHeader(itemList.filter((f) => f.assemblyId === forEditItems[0].assemblyId && f.rowType === "A"));
        setEditLabourItems(forEditItems);
        setIsQuoteSaved(false);
    };

    const resetLabour = () => {
        setIsQuoteSaved(false);
        setEditLabourItems([]);
        setItemAssemblyList([]);
        setEditLabourHeader([]);
        setInsertLabourItems(false);
    };

    const handleTotalTimeApprx = (e) => {
        setTotalTimeApprx(e);
    };

    // const handleUngroup = () => {
    //     setIsUngrouped(!isUngrouped);
    // };

    // const handleGroup = () => {
    //     // If we're exiting from a group or subgroup, save the context
    //     setPreviousContext(lastAdded.type);
    // }

    const getTheActualRow = (rowId) => {
        let rowIdx = 0;
        if (itemList.length === 0 && rowId === undefined) {
            // return rowIdx
        } else {
            rowIdx = itemList.map((e) => e.rowId).indexOf(rowId);
            //if (selectedItems[0].displayGroupName !== "" && rowIdx > 0) rowIdx = rowIdx - 1;
        }

        return rowIdx;
    };

    const Addemptyrowbyselectedrow = () => {
        if (selectedItems.length === 0) {
            toast.error("Please choose a row before inserting the new row.", { autoClose: 5000, position: "top-center", transition: Slide, });
            return;
        }

        if (selectedItems.length > 0) {
            if (selectedItems[0].rowType === "AI") {
                toast.error("You are not allowed to add row within the assembly items.", { autoClose: 5000, position: "top-center", transition: Slide, });
                return;
            }
        }

        // if (selectedtm.length > 1) {
        //     toast.error("Please select a single row only when inserting a new row.", { autoClose: 5000 });
        //     return;
        // }

        const sel = selectedItems.at(0);
  
        let curRow = getTheActualRow(sel.rowId);
        let { groupId, groupName, subGroupId, subGroupName, parentGroupId } = "";
        let updatedData = [];
        if (curRow === -1) {
            toast.error("Empty row already exist. You can add an item on the selected row.", { autoClose: 5000, position: "top-center", transition: Slide, });
            return;
        }

        // if (curRow === -1) {
        //     toast.error("Please enter an item first on the selected row before adding new one.", { autoClose: 5000 });
        //     return;
        // }

        if (curRow === 0) {
            const nGId = uuidv4();
            nonGroupedId.current ? nonGroupedId.current : (nonGroupedId.current = nGId);

            groupId = nonGroupedId.current;
            groupName = GroupItemTypes.NON_GROUPED_ITEMS;
            subGroupId = "";
            subGroupName = "";
            parentGroupId = null;
            const emptyRow = createEmptyRow(groupId, groupName, subGroupId, subGroupName, "", "", "", parentGroupId);
            updatedData = [...itemList.slice(0, curRow), emptyRow, ...itemList.slice(curRow)];
        } else {
            const item = itemList[curRow - 1];
            groupId = item.groupId;
            groupName = item.groupName;
            subGroupId = item.subGroupId;
            subGroupName = item.subGroupName;
            parentGroupId = item.rowType === "G" || item.rowType === "SG"
                ? item.id
                : item.parentId;
            const emptyRow = createEmptyRow(groupId, groupName, subGroupId, subGroupName, "", "", "", parentGroupId);
            updatedData = [...itemList.slice(0, curRow), emptyRow, ...itemList.slice(curRow)];
        }




        updatedData = itemPositioning(true, updatedData);
        // Update the state to trigger a re-render of your grid
        setItemList(updatedData);
        updateQuoteDetailsForId(id, { selectedItems: updatedData });
    };

    // const handleUngroup = () => {
    //     setIsUngrouped(!isUngrouped);
    // };

    // const handleGroup = () => {
    //     // If we're exiting from a group or subgroup, save the context
    //     setPreviousContext(lastAdded.type);
    // };
    const importItems = () => {
        setImportDialogVisible(true);
    };

    const importOldQuotes = () => {
        setImportOldQuoteDialogVisible(true);
    };

    // const handleImport = (file, hasHeaders, importFields) => {
    //     handleFileUpload({ target: { files: [file] } }, hasHeaders, importFields,);
    // };
    const handleImport = (file, hasHeaders, importFields) => {
        const rows = "";
        processImportedData(file, hasHeaders, importFields, rows);
        //handleFileUpload({ target: { files: [file] } }, hasHeaders, importFields,);
    };

    //     function handleFileUpload(event, hasHeaders, importFields) {
    //         const file = event.target.files[0];
    //         const reader = new FileReader();
    //         reader.onload = (e) => {
    //             const data = e.target.result;
    //             const workbook = XLSX.read(data, { type: "binary" });
    //             const sheetName = workbook.SheetNames[0];
    //             const worksheet = workbook.Sheets[sheetName];
    //             const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 , raw: false  });//, row : false , defval: '',rawNumbers: false 

    //             const headers = jsonData[0].map(header => header.toLowerCase().replace(/\s+/g, ''));
    //             const dataRows = jsonData.slice(1);

    //             const result = dataRows.map(row => {
    //                 const rowData = {};
    //                 headers.forEach((header, index) => {
    //                     rowData[header] = row[index];
    //                 });

    //                 // Ensure PartNo, Qty, and NettEA are included, even if NettEA is not in the CSV
    //                 return {
    //                     stStockCode: rowData.groups !== undefined && rowData.groups !=='' ? rowData.groups: rowData.subgroups !== undefined && rowData.subgroups !=='' ? rowData.subgroups:  rowData.partno ,
    //                     qty: rowData.qty  || '',
    //                     nettEA : rowData.nettea || rowData.eachprice || rowData.listprice || ''//rowData.nettea  || ''
    //                 };
    //             });

    //             // For demonstration purposes, log the result to the console
    //             console.log("stringifiedDataresult :",result);


    //             const stringifiedData = jsonData.map(row => {
    //                 // Convert the first cell of the row to string
    //                 const firstCellAsString = /^\d+$/.test(String(row[0])) ? `${row[0]}` : String(row[0]);
    //                 // Return a new array with the first cell as a string and other cells unchanged
    //                 return [firstCellAsString, ...row.slice(1)];
    //             });
    //         const rows = stringifiedData.map(row => row.join('\t'));

    //         const importItems = formatJsonData(jsonData);
    //         processImportedData(result, hasHeaders, importFields, rows);//importItems
    //     };
    //     reader.readAsBinaryString(file);
    // }
    // reader.readAsBinaryString(file);
    //         const stringifiedData = jsonData.map(row => {
    //             const stringifiedRow = row.map((cell, index) => {
    //                 // Check if it's the first cell and it looks like a number with leading zeros
    //                 if (index === 0 && /^\d+$/.test(cell)) {
    //                     return `'${cell}`; // Add a single quote to preserve leading zeros
    //                 }
    //                 return cell; // Otherwise, keep the original value
    //             });
    //             return stringifiedRow;
    //             // const stringifiedRow = [...row]; // Create a copy of the row array
    //             // stringifiedRow[0] = String(stringifiedRow[0]); // Convert the first cell value to string
    //             // return stringifiedRow;
    //         });
    // console.log("stringifiedData :",stringifiedData);
    //         const rows = stringifiedData.map(row => row.join('\t')); // Use stringifiedData here
    //         const importItems = formatJsonData(stringifiedData); // Use stringifiedData here

    //         processImportedData(importItems, hasHeaders, importFields, rows);
    //     };
    //     reader.readAsBinaryString(file);
    // }

    // const dataMapping = {
    //     Management: [
    //         "groupName",
    //         "subGroupName",
    //         "stStockCode",
    //         "description",
    //         "listPrice",
    //         "l60",
    //         "l50",
    //         "l55",
    //         "l20",
    //         "costEA",
    //         "stEecostRound",
    //         "actCost",
    //         "lastCost",
    //         "qty",
    //         "nettEA",
    //         "repDisc",
    //         "margin",
    //         "actMargin",
    //         "lcMargin",
    //         "totalCost",
    //         "totalPrice",
    //     ],
    //     Normal: [
    //         "groupName",
    //         "subGroupName",
    //         "stStockCode",
    //         "description",
    //         "type",
    //         "dcCode",
    //         "dcRate",
    //         "listPrice",
    //         "l60",
    //         "l50",
    //         "l55",
    //         "l20",
    //         "costEA",
    //         "stEecostRound",
    //         "dcOnLp",
    //         "soh",
    //         "qty",
    //         "nettEA",
    //         "repDisc",
    //         "margin",
    //         "totalCost",
    //         "totalPrice",
    //     ],
    //     Report: [
    //         "groupName",
    //         "subGroupName",
    //         "stStockCode",
    //         "description",
    //         "qty",
    //         "nettEA",
    //         "repDisc",
    //         "margin",
    //         "totalPrice",
    //         "hideRow",
    //         "hideQty",
    //         "hidePrice",
    //     ],
    // };

    // function getMappingArray(view) {
    //     return dataMapping[view] || [];
    // }

    // function formatJsonData(jsonData) {
    //     // Assume mappingArray is defined elsewhere and includes all fields
    //     const headers = jsonData[0];
    //     const formattedData = [];

    //     jsonData.slice(1).forEach((row) => {
    //         let formattedRow = {};

    //         headers.forEach((header, index) => {
    //             const formattedHeader = header
    //                 .toLowerCase()
    //                 .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    //                 .replace(/ /g, ''); // Assuming this matches your fields in mappingArray

    //             formattedRow[formattedHeader] = row[index] !== undefined ? row[index] : "";
    //         });


    //         const mappingArray = columnsMapping;

    //         // Ensure all fields in mappingArray are present in formattedRow, even if they were not in headers
    //         mappingArray.forEach((field) => {
    //             if (!(field in formattedRow)) {
    //                 formattedRow[field] = "";
    //             }
    //         });

    //         formattedData.push(formattedRow);
    //     });
    //     console.log("formattedData", formattedData);  
    //     return formattedData;

    // }
    function formatJsonData(jsonData) {
        const headers = jsonData[0];
        const formattedData = [];

        // Define a mapping for header variations to standardized field names
        const headerToFieldMapping = {
            "Part No": "stStockCode",
            "Description": "description",
            "List Price": "listPrice",
            "L60 Price": "l60",
            "L50 Price": "l50",
            "L35 Price": "l35",
            "L20 (O1)": "l20",
            "Fut. Cost": "futCost",
            "CostEA": "costEA",
            "Avg. Cost": "avgCost",
            "Last Cost": "lastCost",
            "Qty": "qty",
            "NettEA": "nettEA",
            "Rep Disc (%)": "repDisc",
            "Margin (%)": "margin",
            "AvgC. Margin (%)": "avgcMargin",
            "FC. Margin (%)": "frcMargin",
            "LC. Margin (%)": "lcMargin",
            "Total Cost": "totalCost",
            "Total Price": "totalPrice",
            // Assuming your mappings for additional fields:
            "Groups": "groupName",
            "Sub Groups": "subGroupName",
            // Add other mappings as necessary
        };

        jsonData.slice(1).forEach((row) => {
            let formattedRow = {};

            headers.forEach((header, index) => {
                const formattedHeader = header
                    .toLowerCase()
                    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
                    .replace(/ /g, '');

                // Use headerToFieldMapping to find the correct field name or default to formattedHeader
                const fieldName = headerToFieldMapping[header] || formattedHeader;

                formattedRow[fieldName] = row[index] !== undefined ? row[index] : "";
            });

            // Ensure all fields in columnsMapping are present in formattedRow
            columnsMapping.forEach((field) => {
                if (!(field in formattedRow)) {
                    formattedRow[field] = "";
                }
            });

            formattedData.push(formattedRow);
        });

        return formattedData;
    }

    function processImportedData(importItems, hasHeaders) {
        if (!selectedView || !selectedView.text) {
            console.error("selectedView or selectedView.text is undefined:", selectedView);
            return;
        }

        // Assume mappingArray matches the fields defined in dataMapping for the selected view
        const mappingArray = getMappingArray(selectedView.text);

        const processedData = importItems.map((item) => {
            let processedItem = {};

            mappingArray.forEach((field) => {
                processedItem[field] = item[field] !== undefined ? item[field] : "";
            });

            return processedItem;
        });

        setItemList([...itemList, ...processedData]);
        setComputeTotals(true);
    }

    async function processImportedData(result, hasHeaders, importFields, rows) {
        try {
            setIsLoading(true);
            setOpenShowProgress(true);

            const UserId = user?.homeAccountId;
            let groupId = '';
            let subGroupId = '';
            let groupName = '';
            let subGroupName = '';
            let groupNo = 0;
            let subGroupNo = 0;
            let displayGroupName = '';
            let groupLevelName = '';
            let displaySubGroupName = '';
            let accNo = 0;
            let assemblyId = '';

            const processedItems = [];
            let systemPartsCount = 0;
            let miscellaneousPartsCount = 0;
            let duplicateItemsCount = 0;
            const importLogDetails = [];
            const stockCodeCount = {};
            let isOldImport = "";
            let progressInterval;

            const mapDataItemDetails = importFields.map((item) => ({
                stStockCode: item.stStockCode ? item.stStockCode : "",
                qty: item.qty ? item.qty : 1,
                nettEA: item.nettEA ? item.nettEA : ""
            }));
            
            const mapResponse = importFields.filter((item) => item.stStockCode !== '').map((item) => ({
                stStockCode: item.stStockCode ? item.stStockCode.toString() : "",
                qty: item.qty ? item.qty : 1,

            }));

            /*
            // Start of Progress Bar
            const expectedDuration = 10000; // Set the expected duration in milliseconds (e.g., 10 seconds)
            setProgress({ progress: 0, remainingTime: Math.ceil(expectedDuration / 1000) });

            let startTime = Date.now();
            let totalItems = mapDataItemDetails.length;

            // PROGRESS START
            let processedItems_ctr = 0;
            let itemPercentage = 0;
            let percentage = 0;
            let remainingTime = 0;
            let timePercentage = 0;

            // Clear any existing intervals before starting a new one
            if (progressInterval) {
                clearInterval(progressInterval);
            }

            // Update progress every second
            const incrementProgress = () => {
                let elapsed = Date.now() - startTime;
                remainingTime = Math.max(0, expectedDuration - elapsed);
                timePercentage = Math.min(100, Math.round((elapsed / expectedDuration) * 100));

                // Update processedItems here as per your actual processing logic
                // For demonstration, let's assume one item is processed every second
                if (processedItems_ctr < totalItems) {
                    processedItems_ctr++;
                }

                itemPercentage = Math.min(100, Math.round((processedItems_ctr / totalItems) * 100));

                // Calculate overall progress by combining time and item percentages
                percentage = Math.round((timePercentage + itemPercentage) / 2);

                setProgress({
                    progress: Math.min(100, percentage),
                    remainingTime: Math.ceil(remainingTime / 1000)
                });

                if (remainingTime <= 0) {
                    clearInterval(progressInterval);
                }
            };

            // // Call incrementProgress every second
            // progressInterval = setInterval(incrementProgress, 1000);

            // Start the interval for progress update
            progressInterval = setInterval(incrementProgress, 1000);
            //PROGRESS END
            let selectedColumns = columnsMapping
            //End of Progress bar
            */
            
            /*
            const expectedDuration = 1000000; // Set the expected duration in milliseconds (e.g., 10 seconds)
            setProgress((prev) => ({
                ...prev,
                [id]: { percentage: 0, remainingTime: Math.ceil(expectedDuration / 1000) },
              }));

            let startTime = Date.now();

            const interval = setInterval(() => {
            let elapsed = Date.now() - startTime;
            let remainingTime = Math.max(0, expectedDuration - elapsed);
            setProgress((prev) => ({
                ...prev,
                [id]: {
                  percentage: Math.round(Math.min(100, (elapsed / expectedDuration) * 100)),
                  remainingTime: Math.ceil(remainingTime / 1000),
                },
              }));
                if (remainingTime <= 0) {
                    clearInterval(interval);
                }
            }, 100);
            */

            var url = `Stocks/getstockdetailsusingimport?CustomerId=${customerId}&UserId=${UserId}`;
            const response = await postResponse(url, mapResponse);
            const values = response.map((item) => ({
                stId: item.stId,
                description: item.description,
                rowType: item.type,
                stEecost: item.stEecost,
                actCostExact: item.actCostExact,
                stFutureCost: item.stFutureCost,
                margin: item.margin,
                nettEA: item.nettEA,
                stEecostRound: item.stEecostRound,
                actCost: item.actCost,
                stFutureCostRound: item.stFutureCostRound,
                soh: item.soh,
                type: item.type,
                dcCode: item.dcCode,
                dcRate: item.dcRate,
                listPrice: item.listPrice,
                l60: item.l60,
                l50: item.l50,
                l55: item.l55,
                l20: item.l20,
                l35: item.l35,
                lastCost: item.lastCost,
                lastCostExact: item.lastCostExact,
                totalCost: item.totalCost,
                totalPrice: item.totalPrice


            }));

            for (let y = 0; y < mapDataItemDetails.length; y++) {
                const currentItem = mapDataItemDetails[y];
                console.log("currentItemcurrentItem :", currentItem)
                if (currentItem.stStockCode !== '') {
                    //for (let y = 0; y < values.length; y++) {
                    let qty = Math.floor(mapDataItemDetails[y]?.qty) || 0;// parseFloat(mapDataItemDetails[y]?.qty) || 0.00;
                    let description = values[y]?.description;
                    let soh = parseFloat(values[y]?.soh) || 0.00;
                    let listPrice = parseFloat(values[y]?.listPrice) || 0.00;
                    let actCostExact = parseFloat(values[y]?.actCostExact) || 0.00;
                    let stFutureCost = parseFloat(values[y]?.stFutureCost) || 0.00;
                    let nettEA = mapDataItemDetails[y]?.nettEA === "" ? parseFloat(values[y]?.nettEA) : mapDataItemDetails[y]?.nettEA ? parseFloat(mapDataItemDetails[y]?.nettEA) : parseFloat(values[y]?.nettEA);
                    let l60 = parseFloat(values[y]?.l60) || 0.00;
                    let l55 = parseFloat(values[y]?.l55) || 0.00;
                    let l50 = parseFloat(values[y]?.l50) || 0.00;
                    let l20 = parseFloat(values[y]?.l20) || 0.00;
                    let stEecost = parseFloat(values[y]?.stEecost) || 0.00;
                    let stEecostRound = parseFloat(values[y]?.stEecostRound) || 0.00;
                    let lastCost = parseFloat(values[y]?.lastCost) || 0.00;
                    let lastCostExact = parseFloat(values[y]?.lastCostExact) || 0.00;
                    let actCost = parseFloat(values[y]?.actCost) || 0.00;
                    let stFutureCostRound = parseFloat(values[y]?.stFutureCostRound) || 0.00;
                    let dcRate = parseFloat(values[y]?.dcRate) || 0.00;
                    let totCost = Math.floor(mapDataItemDetails[y]?.qty) * parseFloat(values[y]?.stEecost);
                    let totalCost = isNaN(parseFloat((totCost).toFixed(2))) ? 0 : parseFloat((totCost).toFixed(2));
                    let totP = parseFloat(Math.floor(mapDataItemDetails[y]?.qty) * nettEA);
                    let totalPrice = parseFloat((totP).toFixed(2));

                    let margin = nettEA === 0.0
                        ? 0
                        : parseFloat((((nettEA - stEecostRound) / nettEA) * 100).toFixed(1));
                    let dcOnLp = listPrice && nettEA
                        ? calculateItemDcOnLp(nettEA, listPrice)
                        : 0;
                    let actMargin = nettEA === 0.0
                        ? 0
                        : parseFloat((((nettEA - actCostExact) / nettEA) * 100).toFixed(1));
                    let frcMargin = calculateItemFrcMargin(
                        nettEA,
                        stFutureCost
                    );
                    let lcMargin = calculateItemLcMargin(
                        nettEA,
                        lastCostExact
                    );
                    let originalNettEA = parseFloat(values[y]?.nettEA) || 0.00;
                    let repDisc = calculateItemRepDisc(originalNettEA, nettEA);
                      // Check if the item is "AI" based on stStockCode starting with "Q_LABTIME"
                      const isAI = currentItem.stStockCode.toString().startsWith("Q_LABTIME");
                      const isA = currentItem.stStockCode.toString().startsWith("ASSEMBLY");

                    currentItem.groupId = uuidv4();
                    currentItem.subGroupId = '';
                    currentItem.groupName = 'Non Grouped Items';
                    currentItem.subGroupName = "";
                    currentItem.displaySubGroupName = "";
                    currentItem.displayGroupName = "";
                    currentItem.displayAssemblyGroupName = "";
                    currentItem.groupDbExtraId = "";
                    currentItem.groupLevelName = "";
                    currentItem.nonGroupDbExtraId = "";
                    currentItem.parentId = null;
                    currentItem.subGroupDbExtraId = "";
                    currentItem.group = "";
                    currentItem.stId = values[y]?.stId || uuidv4();
                    currentItem.customerId = customerId;
                    currentItem.rowId = uuidv4();
                    currentItem.description = values[y]?.description || "";
                    currentItem.rowType = values[y]?.type === "" ? "CI" : values[y]?.type;
                    currentItem.listPrice = listPrice;
                    currentItem.stEecost = stEecost;
                    currentItem.l60 = l60 === 0 ? "" : l60;
                    currentItem.l55 = l55 === 0 ? "" : l55;
                    currentItem.l50 = l50 === 0 ? "" : l50;
                    currentItem.l20 = l20 === 0 ? "" : l20;
                    currentItem.qty = mapDataItemDetails[y]?.qty === ""
                        ? 0
                        : values[y]?.type === ""
                            ? Math.floor(mapDataItemDetails[y]?.qty)
                            : Math.floor(qty);

                    //  currentItem.qty =mapDataItemDetails[y]?.qty ===""? 0 :values[y]?.type === "" ? mapDataItemDetails[y]?.qty:qty; 
                    currentItem.soh = soh;
                    currentItem.margin = values[y]?.type === "" ? 0 : margin;
                    currentItem.nettEA = mapDataItemDetails[y]?.nettEA === "" ? parseFloat(values[y]?.nettEA) : values[y]?.type === "" ? parseFloat(mapDataItemDetails[y]?.nettEA) : nettEA;
                    currentItem.stEecostRound = stEecostRound;
                    currentItem.stFutureCost = stFutureCost;
                    currentItem.lastCost = lastCost;
                    currentItem.lastCostExact = lastCostExact;
                    currentItem.actCost = actCost;
                    currentItem.actCostExact = actCostExact;
                    currentItem.dcOnLp = dcOnLp;
                    currentItem.actMargin = values[y]?.type === "" ? 0 : actMargin;
                    currentItem.frcMargin = values[y]?.type === "" ? 0 : frcMargin;
                    currentItem.lcMargin = values[y]?.type === "" ? "" : lcMargin;
                    currentItem.stFutureCostRound = stFutureCostRound;
                    currentItem.soh = values[y]?.soh || "";
                    currentItem.type = values[y]?.type === "" ? "CI" : values[y]?.type;
                    currentItem.dcCode = values[y]?.dcCode || "";
                    currentItem.dcRate = dcRate;
                    currentItem.totalCost = totalCost;
                    currentItem.totalPrice = totalPrice;
                    currentItem.repDisc = repDisc;
                    if (currentItem.stStockCode !== '') {
                        const itemType = values[y]?.type || "CI";
                        
                      
                    
                        // Categorize the items based on their type
                        if ((itemType === "CI" || isA) && !isAI) {
                            miscellaneousPartsCount++;
                        } else if (itemType !== "G" && itemType !== "SG") {
                            systemPartsCount++;
                    
                            // Track occurrences of stStockCode for system parts
                            if (stockCodeCount[currentItem.stStockCode]) {
                                stockCodeCount[currentItem.stStockCode]++;
                            } else {
                                stockCodeCount[currentItem.stStockCode] = 1;
                            }
                        }
                    
                        // Log details of the import
                        importLogDetails.push(`${y + 1}: ${(itemType === "CI" || isA) && !isAI ? "Miscellaneous Part" : "System Part"} (${currentItem.stStockCode})`);
                    }
                    
                    
            }

                processedItems.push(currentItem);
            }
            const updatelist = [...itemList];
            let pasteIndex = -1;
            if (isNotLastRow) {
                pasteIndex = itemList.length - 1;
                const copiedItemListFirstIndex = selectedIndex;
                const lastItem = itemList[itemList.length - 2];
                const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                if (itemList.length === 1) {
                    const updatedItemList = [...processedItems];
                    const updatedList = itemPositioning(true, updatedItemList);
                    setItemList(updatedList);

                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                }
                if (selectedItems[0] === undefined) {

                    //const copiedItemsListFirstItem = itemListNew.filter((item) => item.position == selectedIndex+1);

                    if (selectedIndex === -1) {
                        //Copied NonGroup Only

                        //Last Item in NonGroup
                        if (lastItemInNonGroup) {
                            const gid = lastItem.groupId;
                            const list = processedItems.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = lastItem.subGroupId;
                            const sgname = lastItem.subGroupName;
                            const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                            const list = processedItems.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                            const list = [];

                            processedItems.forEach((item) => {
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                });
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }

                    else {

                        // if (isLastItemNonGroup) {
                        let nonGrpId = "";
                        const list = processedItems.map((item) => {
                            nonGrpId = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nonGrpId;
                            return {
                                ...item,
                                rowId: uuidv4(),
                                groupId: nonGrpId,
                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: null,
                                //     subItems: null,
                                columns: null
                            };
                        });
                        let updatedList = [
                            ...updatelist.slice(0, copiedItemListFirstIndex),
                            ...list,
                            ...updatelist.slice(copiedItemListFirstIndex),
                        ];

                        updatedList = itemPositioning(true, updatedList);
                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);
                        //  setSelectedItems([]);
                        setSelectedIndex(copiedItemListFirstIndex);
                    }
                        processedItems_ctr +=1; 
                        itemPercentage = Math.min(100, Math.round((processedItems_ctr / totalItems) * 100));
                        percentage = Math.round((timePercentage + itemPercentage) / 2);

                        setProgress({
                            progress: Math.min(100, percentage),
                            remainingTime: Math.ceil(remainingTime / 1000)
                        });

                        if (remainingTime <= 0) {
                            clearInterval(progressInterval);
                        }
                }
                if (selectedItems[0] !== undefined) {
                    pasteIndex = updatelist.findIndex((item) => item.rowId === selectedPasteItem.rowId);
                    if (pasteIndex === -1) {
                        pasteIndex = updatelist.length - 1
                    }

                    if (selectedItems[0].rowType === '') {
                        if (lastItemInNonGroup) {
                            const gid = lastItem.groupId;
                            const list = processedItems.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        //Last Item in SubGroup
                        if (lastItemInSubGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const sid = lastItem.subGroupId;
                            const sgname = lastItem.subGroupName;
                            const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                            const list = processedItems.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: sid,
                                    subGroupName: sgname,
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //   setSelectedItems([]);
                        }
                        //Last Item in Group
                        if (lastItemInGroup) {
                            const gid = lastItem.groupId;
                            const gname = lastItem.groupName;
                            const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                            const list = [];

                            processedItems.forEach((item) => {
                                list.push({
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: gname,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: pid,
                                    position: null
                                });
                            });
                            let updatedList = [
                                ...updatelist.slice(0, itemList.length - 1),
                                ...list,

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                    }
                    if (selectedItems[0].rowType !== '' && selectedItems[0].position === 1) {

                        let nongrpid = "";
                        const list = processedItems.map((item) => {
                            nongrpid = item.groupName === GroupItemTypes.NON_GROUPED_ITEMS ? uuidv4() : nongrpid;
                            return {
                                ...item,
                                rowId: uuidv4(),
                                groupId: nongrpid,
                                groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                displayGroupName: "",
                                subGroupId: "",
                                subGroupName: "",
                                displaySubGroupName: "",
                                id: uuidv4(),
                                parentId: null,
                                position: null
                            };
                        });
                        let updatedList = [
                            ...updatelist.slice(0, pasteIndex),
                            ...list,
                            ...updatelist.slice(pasteIndex),

                        ];
                        updatedList = itemPositioning(true, updatedList);
                        setItemList(updatedList);
                        updateQuoteDetailsForId(id, { selectedItems: updatedList });
                        setComputeTotals(true);

                    }

                    else {

                        const selectNonGroupItem = selectedItems[0].groupName === 'Non Grouped Items';
                        const selectGroupItem = selectedItems[0].groupId !== '' && selectedItems[0].subGroupId === '' && selectedItems[0].groupName !== 'Non Grouped Items' && selectedItems[0].rowType !== 'G';
                        const selectSubGroupItem = selectedItems[0].subGroupId !== '' && selectedItems[0].rowType !== 'SG';
                        const selectGroupHeader = selectedItems[0].rowType === 'G';
                        const selectSubGroupHeader = selectedItems[0].rowType === 'SG';

                        const beforeItem = itemList.filter((item) => item.position == pasteIndex);
                        const beforeItemNonGroupItem = beforeItem[0].groupName === 'Non Grouped Items';
                        const beforeItemGroupItem = beforeItem[0].groupId !== '' && beforeItem[0].subGroupId === '' && beforeItem[0].groupName !== 'Non Grouped Items' && beforeItem[0].rowType !== 'G';
                        const beforeItemSubGroupItem = beforeItem[0].subGroupId !== '' && beforeItem[0].rowType !== 'SG';
                        const beforeItemGroupHeader = beforeItem[0].rowType === 'G';
                        const beforeItemSubGroupHeader = beforeItem[0].rowType === 'SG';


                        if (selectNonGroupItem) {
                            console.log("select non group item");
                            const gid = selectedItems[0].groupId;
                            const list = processedItems.map((item) => {
                                return {
                                    ...item,
                                    rowId: uuidv4(),
                                    groupId: gid,
                                    groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                    displayGroupName: "",
                                    subGroupId: "",
                                    subGroupName: "",
                                    displaySubGroupName: "",
                                    id: uuidv4(),
                                    parentId: null,
                                    position: null
                                };
                            });
                            let updatedList = [
                                ...updatelist.slice(0, pasteIndex),
                                ...list,
                                ...updatelist.slice(pasteIndex),

                            ];
                            updatedList = itemPositioning(true, updatedList);
                            setItemList(updatedList);
                            updateQuoteDetailsForId(id, { selectedItems: updatedList });
                            setComputeTotals(true);
                            //  setSelectedItems([]);
                        }
                        if (selectGroupHeader) {
                            console.log("select  group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //   setSelectedItems([]);
                            }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: uuidv4(),
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: uuidv4(),
                                        groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                                        displayGroupName: "",
                                        subGroupId: "",
                                        subGroupName: "",
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: null,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: pid,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectSubGroupHeader) {
                            console.log("select sub group header item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].id;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                        }
                        if (selectGroupItem) {
                            console.log("select  group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].id;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) { }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = "";
                                const sgname = "";
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) { }
                        }
                        if (selectSubGroupItem) {
                            console.log("select sub group item")
                            //Before Item of selected index has NonGroupItem
                            if (beforeItemNonGroupItem) { }
                            //Before Item of selected index has GroupHeader
                            if (beforeItemGroupHeader) { }
                            //Before Item of selected index has SubGroupHeader
                            if (beforeItemSubGroupHeader) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].id;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].id,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                //  setSelectedItems([]);
                            }
                            //Before Item of selected index has Group Item
                            if (beforeItemGroupItem) { }
                            //Before Item of selected index has Sub Group Item
                            if (beforeItemSubGroupItem) {
                                const gid = beforeItem[0].groupId;
                                const gname = beforeItem[0].groupName;
                                const sid = beforeItem[0].subGroupId;
                                const sgname = beforeItem[0].subGroupName;
                                const pid = beforeItem[0].parentId;
                                const list = processedItems.map((item) => {
                                    return {
                                        ...item,
                                        rowId: uuidv4(),
                                        groupId: gid,
                                        groupName: gname,
                                        displayGroupName: "",
                                        subGroupId: sid,
                                        subGroupName: sgname,
                                        displaySubGroupName: "",
                                        id: uuidv4(),
                                        parentId: beforeItem[0].parentId,
                                        position: null
                                    };
                                });
                                let updatedList = [
                                    ...updatelist.slice(0, pasteIndex),
                                    ...list,
                                    ...updatelist.slice(pasteIndex),

                                ];
                                updatedList = itemPositioning(true, updatedList);
                                setItemList(updatedList);
                                updateQuoteDetailsForId(id, { selectedItems: updatedList });
                                setComputeTotals(true);
                                // setSelectedItems([]);
                            }
                        }
                    }
                }

            }
            else {
                const lastItem = itemList[itemList.length - 2];
                const lastItemInNonGroup = lastItem && lastItem.groupName === 'Non Grouped Items';
                const lastItemInGroup = lastItem && lastItem.groupId !== '' && lastItem.subGroupId === '' && lastItem.groupName !== 'Non Grouped Items';
                const lastItemInSubGroup = lastItem && lastItem.subGroupId !== '';
                if (itemList.length === 1) {
                    const updatedItemList = [...processedItems];
                    const updatedList = itemPositioning(true, updatedItemList);
                    setItemList(updatedList);

                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                }
                if (lastItemInNonGroup) {
                    const gid = lastItem.groupId;
                    const list = processedItems.map((item) => {
                        return {
                            ...item,
                            rowId: uuidv4(),
                            groupId: gid,
                            groupName: GroupItemTypes.NON_GROUPED_ITEMS,
                            displayGroupName: "",
                            subGroupId: "",
                            subGroupName: "",
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: null,
                            position: null
                        };
                    });
                    let updatedList = [
                        ...updatelist.slice(0, itemList.length - 1),
                        ...list,

                    ];
                    updatedList = itemPositioning(true, updatedList);
                    setItemList(updatedList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                    //  setSelectedItems([]);
                }
                //Last Item in SubGroup
                if (lastItemInSubGroup) {
                    const gid = lastItem.groupId;
                    const gname = lastItem.groupName;
                    const sid = lastItem.subGroupId;
                    const sgname = lastItem.subGroupName;
                    const pid = lastItem.rowType === 'SG' ? lastItem.id : lastItem.parentId;

                    const list = processedItems.map((item) => {
                        return {
                            ...item,
                            rowId: uuidv4(),
                            groupId: gid,
                            groupName: gname,
                            displayGroupName: "",
                            subGroupId: sid,
                            subGroupName: sgname,
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: pid,
                            position: null
                        };
                    });
                    let updatedList = [
                        ...updatelist.slice(0, itemList.length - 1),
                        ...list,

                    ];
                    updatedList = itemPositioning(true, updatedList);
                    setItemList(updatedList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                    //setSelectedItems([]);
                }
                //Last Item in Group
                if (lastItemInGroup) {
                    const gid = lastItem.groupId;
                    const gname = lastItem.groupName;
                    const pid = lastItem.rowType === 'G' ? lastItem.id : lastItem.parentId;

                    const list = [];

                    processedItems.forEach((item) => {
                        list.push({
                            ...item,
                            rowId: uuidv4(),
                            groupId: gid,
                            groupName: gname,
                            displayGroupName: "",
                            subGroupId: "",
                            subGroupName: "",
                            displaySubGroupName: "",
                            id: uuidv4(),
                            parentId: pid,
                            position: null
                        });
                    });
                    let updatedList = [
                        ...updatelist.slice(0, itemList.length - 1),
                        ...list,

                    ];
                    updatedList = itemPositioning(true, updatedList);
                    setItemList(updatedList);
                    updateQuoteDetailsForId(id, { selectedItems: updatedList });
                    setComputeTotals(true);
                    //  setSelectedItems([]);
                }

                // Count distinct stock codes that have more than one occurrence (i.e., duplicates)
                duplicateItemsCount = Object.values(stockCodeCount).filter(count => count > 1).length;


                // Log details to show in the dialog
                const importLog = `
                Import Log
                ====================
                Total Records: ${mapDataItemDetails.length}
                System Items: ${systemPartsCount}
                Miscellaneous Items: ${miscellaneousPartsCount}
                Duplicate Items: ${duplicateItemsCount}
                
                ${importLogDetails.join('\n')}
                `;

                // Set the log to state to display in dialog
                setImportLog(importLog);
                setShowImportLogDialog(true); // Show the dialog after processing
            }

            /*
            processedItems_ctr += 1; 
            itemPercentage = Math.min(100, Math.round((processedItems_ctr / totalItems) * 100));
            percentage = Math.round((timePercentage + itemPercentage) / 2);

            setProgress({
                progress: Math.min(100, percentage),
                remainingTime: Math.ceil(remainingTime / 1000)
            });

            if (percentage >= 100) {
                // Delay showing the dialog for a better user experience
                setTimeout(() => {
                    setShowImportLogDialog(true); // Show the dialog after processing
                }, 500); // Adjust the delay as needed

                // Hide the progress bar by setting the progress to 0
                setProgress({
                    progress: 0,
                    remainingTime: 0
                });
            }

            if (remainingTime <= 0) {
                clearInterval(progressInterval);
            }*/        
        }
        catch (error) {
            console.error('Error:', error.message);
        } finally {
            setIsLoading(false);
            SetIsNotLastRow(false);
            // setOpenShowProgress(false);
        }
    };



    let selectedColumns = columnsMapping;
    /*UI/UX Feature: Item Grid Button to continuously show when scrolling*/
    const [shouldAddClass, setShouldAddClass] = useState(false);

    useEffect(() => {
        const targetId = "ip-items-detect";
        const idElement = document.getElementById(targetId);

        try {
            if (idElement) {
                const handleScroll = () => {
                    try {
                        const rect = idElement.getBoundingClientRect();
                        const isElementInView = rect.top <= window.innerHeight && rect.bottom <= 30;

                        if (isElementInView && !shouldAddClass) {
                            setShouldAddClass(true);
                        } else if (!isElementInView && shouldAddClass) {
                            setShouldAddClass(false);
                        }
                    } catch (error) {
                        console.error("Error in handleScroll:", error);
                    }
                };

                window.addEventListener("scroll", handleScroll);

                return () => {
                    window.removeEventListener("scroll", handleScroll);
                };
            }
        } catch (error) {
            console.error("Error in useEffect:", error);
        }
    }, [shouldAddClass]);

    useEffect(() => {
        try {
            const apprMsgContainer = document.querySelector('.appr-msg-container-show');
            const apprMsgContainerGreen = document.querySelector('.appr-status.green');
            const apprMsgContainerRed = document.querySelector('.appr-status.red');

            const kGridToolbar = document.querySelector(".ip-itembase-grid .k-grid-toolbar");

            if (shouldAddClass && kGridToolbar) {
                kGridToolbar.classList.add("k-grid-fixed-toolbar");
                setTimeout(() => {
                    kGridToolbar.classList.add("k-grid-fixed-toolbar-animation");
                }, 50);
            } else if (kGridToolbar) {
                setTimeout(() => {
                    kGridToolbar.classList.remove("k-grid-fixed-toolbar-animation");
                    kGridToolbar.classList.remove("k-grid-fixed-toolbar");
                }, 50);
            }

            if (shouldAddClass && kGridToolbar && apprMsgContainer) {
                kGridToolbar.style.paddingTop = '43px';
            } else if (kGridToolbar) {
                kGridToolbar.style.paddingTop = '';
            } else if (apprMsgContainerGreen) {
                kGridToolbar.style.paddingTop = '';
            } else if (apprMsgContainerRed) {
                kGridToolbar.style.paddingTop = '';
            }

        } catch (error) {
            console.error("Error in useEffect (kGridToolbar):", error);
        }
    }, [shouldAddClass]);

    useEffect(() => {
        try {
            const kGridToolbar2 = document.querySelector(".ip-itembase-grid .k-grid-header");

            if (shouldAddClass && kGridToolbar2) {
                kGridToolbar2.classList.add("k-grid-fixed-head");
                setTimeout(() => {
                    kGridToolbar2.classList.add("k-grid-fixed-head-animation");
                }, 50);
            } else if (kGridToolbar2) {
                setTimeout(() => {
                    kGridToolbar2.classList.remove("k-grid-fixed-head-animation");
                    kGridToolbar2.classList.remove("k-grid-fixed-head");
                }, 50);
            }
        } catch (error) {
            console.error("Error in useEffect (kGridToolbar2):", error);
        }
    }, [shouldAddClass]);

    const ungroup = () => {
        setIsQuoteSaved(false);
        // debugger;

        // const { rowType } = lastAdded;
        let groupType = "";
        let groupId = "";
        let subGroupId = "";
        let groupName = "";
        let subGroupName = "";
        let rowId = "";
        let lastRow = false;
        let getIndex = -1;
        //#region way to identify the selected area that will be ungroup
        //the item will be considered on the selectedItems is the last row because it is the current selected row on the UI
        if (selectedItems.length === 0) {
            toast.error("Nothing is selected", { autoClose: 2000 });
            return;
        }

        if (itemList.length === 0) {
            toast.error("No group level exist.", { autoClose: 2000 });
            return;
        }
        const selItem = selectedItems.at(-1);
        let currParentId = selItem.parentId;
        let groupParentId = (
            selItem.groupName.startsWith("Group")
                ? itemList.filter((f) => f.groupId === selItem.groupId && f.rowType === "G").at(-1).id
                : null
        );
        let subGroupParentId = (
            selItem.subGroupName.startsWith("Sub Group")
                ? itemList.filter((f) => f.subGroupId === selItem.subGroupId && f.rowType === "SG").at(-1).id
                : null
        );

        if (selectedItems.at(-1)) {
            let sortedItems = itemPositioning(true, itemList);
            const index = sortedItems.findIndex((f) => f.rowId === selectedItems.at(-1).rowId);

            if (subGroupParentId) {
                let updatedItem = itemList[index];
                updatedItem.parentId = groupParentId;
                updatedItem.subGroupId = "";
                updatedItem.subGroupName = "";
                sortedItems[index] = updatedItem;
                const reSortItems = itemPositioning(true, sortedItems);
                setItemList(reSortItems);
                updateQuoteDetailsForId(id, { selectedItems: reSortItems });
                setSelectedItems([]);
                setSelectedIndex([]);
                setComputeTotals(true);

            } else if (groupParentId) {
                let updatedItem = itemList[index];
                updatedItem.parentId = null;
                updatedItem.subGroupId = "";
                updatedItem.subGroupName = "";
                updatedItem.groupId = uuidv4();
                updatedItem.groupName = GroupItemTypes.NON_GROUPED_ITEMS;
                sortedItems[index] = updatedItem;
                const reSortItems = itemPositioning(true, sortedItems);
                setItemList(reSortItems);
                updateQuoteDetailsForId(id, { selectedItems: reSortItems });
                setSelectedItems([]);
                setSelectedIndex([]);
                setComputeTotals(true);
            }
            setIsUngroup(true);

        }


        //#region temporarily commented

        // if (selectedItems.at(-1).rowId === itemList.at(-1).rowId) lastRow = true;
        // groupId = selectedItems.at(-1).groupId;
        // groupName = selectedItems.at(-1).groupName;
        // subGroupId = selectedItems.at(-1).subGroupId
        // subGroupName = selectedItems.at(-1).subGroupName
        // rowId = selectedItems.at(-1).rowId
        // getIndex = (rowId) ? itemList.findIndex((f) => f.rowId === rowId) : -1;

        // if (subGroupId) {
        //     // NSG value is using only on this method
        //     (subGroupName.startsWith("Non Sub Group")) ? groupType = "NSG" : groupType = "SG";
        // }
        // else groupType = "G";
        // //#endregion

        // let updatedItem = [];
        // let updatedItemList = [];
        // let sortedItems = itemPositioning(true, itemList);

        // if (groupType === "G") {
        //     updatedItem = {
        //         ...selectedItems.at(-1),
        //         groupId: uuidv4(),
        //         groupName: GroupItemTypes.NON_GROUPED_ITEMS,
        //         displayGroupName: "",
        //         subGroupId: "",
        //         subGroupName:"",
        //         displaySubGroupName: "",
        //         id:uuidv4(),
        //         parentId: null,
        //     }
        //     const filteredItems = itemList.filter((f) => f.rowId !== selectedItems.at(-1).rowId);
        //     updatedItemList = [...filteredItems.slice(0, filteredItems.length), updatedItem];
        // } else if (groupType === "SG") {
        //     const getNewParentId = itemList.filter((f) => f.groupId === selectedItems.at(-1).groupId && f.rowType === "G").at(-1).id;

        //     updatedItem = {
        //         ...selectedItems.at(-1),
        //         subGroupId: "",
        //         subGroupName:"",
        //         displaySubGroupName: "",
        //         parentId: getNewParentId,
        //         position: null
        //     }
        //     const filteredItems = itemList.filter((f) => f.rowId !== updatedItem.rowId);
        //     const sortedItems = itemPositioning(true, filteredItems);
        //     const groupItems = sortedItems.filter((f) => f.groupId === updatedItem.groupId);
        //     const lastGroupItem = groupItems.filter((f) => f.groupId === updatedItem.groupId).at(-1);
        //     const lastGroupItemIndex = sortedItems.findIndex((f) => f.rowId === lastGroupItem.rowId);
        //     updatedItemList = [...sortedItems.slice(0, lastGroupItemIndex + 1), updatedItem];

        //     if ((lastGroupItemIndex + 1) < sortedItems.length) {
        //         updatedItemList = [...updatedItemList, ...sortedItems.slice(lastGroupItemIndex + 1)];
        //     }
        // }

        // if (updatedItem) {
        //     updatedItemList = itemPositioning(true, updatedItemList);
        //     setItemList(updatedItemList);
        //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        //     setSelectedItems([]);
        //     setSelectedIndex([]);
        //     setComputeTotals(true);
        // }

        //#endregion

    };

    const group = () => {
        setIsUngroup(false);
        // const { rowType } = lastAdded;
        let groupType = "";
        let groupId = "";
        let subGroupId = "";
        let groupName = "";
        let subGroupName = "";
        let rowId = "";
        let getIndex = -1;
        let filteredItemList = [];

        //the item will be considered on the selectedItems is the last row because it is the current selected row on the UI
        console.log(selectedItems);
        if (selectedItems.length === 0) {
            toast.error("Nothing is selected", { autoClose: 2000, position: "top-center", transition: Slide, });
            return;
        }

        if (itemList.length === 0) {
            toast.error("No group level exist.", { autoClose: 2000, position: "top-center", transition: Slide, });
            return;
        }

        const selItem = selectedItems.at(-1);
        let currParentId = selItem.parentId;

        if (selectedItems.at(-1)) {
            let sortedItems = itemPositioning(true, itemList);
            const index = sortedItems.findIndex((f) => f.rowId === selectedItems.at(-1).rowId);

            if (!currParentId) { ///into grouping
                const prevIndx = index - 1;
                let updatedItem = itemList[index];
                let prevItem = itemList[prevIndx];
                updatedItem.parentId = prevItem.parentId;
                updatedItem.subGroupId = "";
                updatedItem.subGroupName = "";
                updatedItem.groupId = prevItem.groupId;
                updatedItem.groupName = prevItem.groupName;
                sortedItems[index] = updatedItem;
                const reSortItems = itemPositioning(true, sortedItems);
                setItemList(reSortItems);
                updateQuoteDetailsForId(id, { selectedItems: reSortItems });
                setSelectedItems([]);
                setSelectedIndex([]);
                setComputeTotals(true);

            } else if (currParentId) { //// into sub groupings
                const prevIndx = index - 1;
                let updatedItem = itemList[index];
                let prevItem = itemList[prevIndx];
                updatedItem.parentId = prevItem.parentId;
                updatedItem.subGroupId = prevItem.subGroupId;
                updatedItem.subGroupName = prevItem.subGroupName;
                sortedItems[index] = updatedItem;
                const reSortItems = itemPositioning(true, sortedItems);
                setItemList(reSortItems);
                updateQuoteDetailsForId(id, { selectedItems: reSortItems });
                setSelectedItems([]);
                setSelectedIndex([]);
                setComputeTotals(true);
            }
            setIsGroup(true);
        }

        //#region temporarily commented
        // groupId = selectedItems.at(-1).groupId;
        // groupName = selectedItems.at(-1).groupName;
        // subGroupId = selectedItems.at(-1).subGroupId;
        // subGroupName = selectedItems.at(-1).subGroupName;
        // rowId = selectedItems.at(-1).rowId;
        // getIndex = (rowId) ? itemList.findIndex((f) => f.rowId === rowId) : -1;

        // if (subGroupId) {
        //     // NSG value is using only on this method
        //     (subGroupName.startsWith("Non Sub Group")) ? groupType = "NSG" : groupType = "SG";
        // }
        // else if (groupName.startsWith("Non Grouped Items")) groupType = ""; /// non-group-items
        // else groupType = "G";

        // let updatedItem = [];
        // let updatedItemList = [];
        // let sortedItems = itemPositioning(true, itemList);

        // if (groupType === "") { /// non-group-items
        //     updatedItem = {
        //         ...selectedItems.at(-1),
        //         groupId: uuidv4(),
        //         groupName: GroupItemTypes.NON_GROUPED_ITEMS,
        //         displayGroupName: "",
        //         subGroupId: "",
        //         subGroupName:"",
        //         displaySubGroupName: "",
        //         parentId: null,
        //     }
        //     updatedItemList = [...sortedItems.slice(0, getIndex), updatedItem];
        // } else if (groupType === "SG") {
        //     const getNewParentId = itemList.filter((f) => f.groupId === selectedItems.at(-1).groupId && f.rowType === "G").at(-1).id;

        //     updatedItem = {
        //         ...selectedItems.at(-1),
        //         subGroupId: "",
        //         subGroupName:"",
        //         displaySubGroupName: "",
        //         parentId: getNewParentId,
        //     }
        //     const filteredItems = itemList.filter((f) => f.rowId !== updatedItem.rowId);
        //     const sortedItems = itemPositioning(true, filteredItems);
        //     const groupItems = sortedItems.filter((f) => f.groupId === updatedItem.groupId);
        //     const lastGroupItem = groupItems.filter((f) => f.groupId === updatedItem.groupId).at(-1);
        //     const lastGroupItemIndex = sortedItems.findIndex((f) => f.rowId === lastGroupItem.rowId);
        //     updatedItemList = [...sortedItems.slice(0, lastGroupItemIndex + 1), updatedItem];

        //     if ((lastGroupItemIndex + 1) < sortedItems.length) {
        //         updatedItemList = [...updatedItemList, ...sortedItems.slice(lastGroupItemIndex + 1)];
        //     }
        // }

        // if (updatedItem) {
        //     updatedItemList = itemPositioning(true, updatedItemList);
        //     setItemList(updatedItemList);
        //     updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
        // }

        //#endregion
    };

    function getGroupLevelStatus() {
        if (groupId && subGroupId) {
            return <strong>The Last empty row belongs to Last Subgroup</strong>;
        } else if (groupId && !subGroupId) {
            return <strong>The Last empty row belongs to Last Group</strong>;
        } else {
            return <strong>The Last empty row belongs to Top Level</strong>;
        }
    }

    const ConsolidateMappings = {
        General: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
        DBEstimators: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            { field: "stEecostRound", title: "CostEA ($)", width: "100px" },
            //{ field: "margin", title: "Margin", width: "100px" },
            { field: "marginDiff", title: "Margin (%)", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
        StateManagers: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            { field: "stEecostRound", title: "CostEA ($)", width: "100px" },
            //{ field: "margin", title: "Margin", width: "100px" },
            { field: "marginDiff", title: "Margin (%)", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
        Management: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            { field: "stEecostRound", title: "CostEA ($)", width: "100px" },
            //{ field: "margin", title: "Margin", width: "100px" },
            { field: "marginDiff", title: "Margin (%)", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
        Admin: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            { field: "stEecostRound", title: "CostEA ($)", width: "100px" },
            //{ field: "margin", title: "Margin", width: "100px" },
            { field: "marginDiff", title: "Margin (%)", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
        GeneralManager: [
            { field: "stStockCode", title: "Part No", width: "100px" },
            { field: "description", title: "Description", width: "100px" },
            { field: "qty", title: "Qty", width: "100px" },
            { field: "stEecostRound", title: "CostEA ($)", width: "100px" },
            //{ field: "margin", title: "Margin", width: "100px" },
            { field: "marginDiff", title: "Margin (%)", width: "100px" },
            // { field: "nettEA", title: "NettEA", width: "100px" },
            { field: "nettEADiff", title: "NettEA ($)", width: "100px" },
            { field: "soh", title: "SOH", width: "100px" },
        ],
    };

    // Function to get the footer content based on authType and quoteViewType
    function getConsolidateMappings(authType) {
        // Check if the authType and quoteViewType exist in the mappings
        if (
            ConsolidateMappings[authType]
            // &&
            // ConsolidateMappings[authType][quoteViewType.text]
        ) {
            return ConsolidateMappings[authType];
        }

        return [];
    }

    const ConsolidateContent = getConsolidateMappings(authType);

    const columnsParConsolidate = [
        { field: "stId", title: "ID", width: "50px", show: false },
        { field: "stStockCode", title: "Part No", width: "100px" },
        { field: "description", title: "Description", width: "100px" },
        { field: "qty", title: "Qty", width: "100px" },
        { field: "dcOnLp", title: "Discount (%)", width: "100px" },
        { field: "nettEA", title: "NettEA", width: "100px" },

        { field: "listPrice", title: "List Price", width: "100px", show: false },
        { field: "totalPrice", title: "Total Price", width: "100px", show: false },
        { field: "margin", title: "Margin", width: "100px", show: false },
        { field: "actMargin", title: "Act Margin", width: "100px", show: false },
        { field: "lcMargin", title: "Lc Margin", width: "100px", show: false },

        { field: "stEecost", title: "CostEA", width: "100px", show: false },
        { field: "actCostExact", title: "ActCost Exact", width: "100px", show: false },
        { field: "lastCostExact", title: "LastCost Exact", width: "100px", show: false },
        { field: "repDisc", title: "repDisc", width: "100px", show: false },

        { field: "isDeleted", title: "isDeleted", width: "100px", show: false },
    ];

    const moveItemUp = (selectedItemIndex) => {
        const selectedItemPosition = getRowPosition(itemList, selectedItemIndex);
        let newIdx = null;

        for (let i = selectedItemIndex - 1; i >= 0; i--) {
            // Search upwards for a suitable position
            const targetPosition = getRowPosition(itemList, i);

            if (
                (targetPosition.isNonGroupedItem && selectedItemPosition.isNonGroupedItem) ||
                (targetPosition.onAGroupRow === selectedItemPosition.onAGroupRow &&
                    targetPosition.onASubGroupRow === selectedItemPosition.onASubGroupRow &&
                    targetPosition.groupDetails.prevGroupId === selectedItemPosition.groupDetails.prevGroupId &&
                    targetPosition.groupDetails.prevSubGroupId === selectedItemPosition.groupDetails.prevSubGroupId)
            ) {
                newIdx = i;
                break;
            }
        }

        if (newIdx !== null) {
            let updatedItemList = [...itemList];
            const temp = updatedItemList[newIdx];
            updatedItemList[newIdx] = updatedItemList[selectedItemIndex];
            updatedItemList[selectedItemIndex] = temp;

            // Update your state with the reordered item list
            // setItemList(updatedItemList); commented by sachini 29/03-2024 move up down bug fixed
            updatedItemList = itemPositioning(true, updatedItemList);
            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            setComputeTotals(true)
            return true;
        } else return false;
    };

    const moveItemDown = (selectedItemIndex) => {
        const selectedItemPosition = getRowPosition(itemList, selectedItemIndex);
        let newIdx = null;

        for (let i = selectedItemIndex + 1; i < itemList.length - 1; i++) {
            // Search downwards for a suitable position
            if (itemList[i].rowType === 'AI') continue;
            const targetPosition = getRowPosition(itemList, i);
            if (
                (targetPosition.isNonGroupedItem && selectedItemPosition.isNonGroupedItem) ||
                (targetPosition.onAGroupRow === selectedItemPosition.onAGroupRow &&
                    targetPosition.onASubGroupRow === selectedItemPosition.onASubGroupRow &&
                    targetPosition.groupDetails.prevGroupId === selectedItemPosition.groupDetails.prevGroupId &&
                    targetPosition.groupDetails.prevSubGroupId === selectedItemPosition.groupDetails.prevSubGroupId)
            ) {
                newIdx = i;
                break;
            }
        }

        if (newIdx !== null) {
            let updatedItemList = [...itemList];
            const temp = updatedItemList[newIdx];
            updatedItemList[newIdx] = updatedItemList[selectedItemIndex];
            updatedItemList[selectedItemIndex] = temp;

            

            // Update your state with the reordered item list
            // setItemList(updatedItemList); commented by sachini 29/03-2024 move up down bug fixed
            updatedItemList = itemPositioning(true, updatedItemList);
            setItemList(updatedItemList);
            updateQuoteDetailsForId(id, { selectedItems: updatedItemList });
            setComputeTotals(true)
            return true;
        } else return false;
    };

    // Assume selectedItemIndex is the index of the selected item in the itemList array
    const getGroupRange = (itemList, selectedItemIndex) => {
        const selectedGroupId = itemList[selectedItemIndex]?.groupId;
        const selectedSubGroupId = itemList[selectedItemIndex]?.subGroupId;
        if (!selectedGroupId) return { firstIndex: -1, lastIndex: -1 };
        let firstIndex = -1;
        let lastIndex = -1;

        for (let i = 0; i < itemList.length; i++) {
            const item = itemList[i];
            // Ignore rows representing group or subgroup names
            if (item.displayGroupName || item.displaySubGroupName) {
                continue;
            }
            if (item.groupId === selectedGroupId && item.subGroupId === selectedSubGroupId) {
                if (firstIndex === null) {
                    firstIndex = i;
                }
                lastIndex = i; // Keep updating lastIndex until the last item of the group/subgroup is found
            }
        }

        return { firstIndex, lastIndex };
    };

    const checkItemPosition = (itemList, selectedItemIndex) => {
        const { firstIndex, lastIndex } = getGroupRange(itemList, selectedItemIndex);

        const isItemAtTop = selectedItemIndex === firstIndex;
        const isItemAtBottom = selectedItemIndex === lastIndex;

        return { isItemAtTop, isItemAtBottom };
    };

    const selectedItemIndex = (selectedItems || []).length === 0 
        ? -1 
        : itemList.findIndex((item) => typeof selectedItems[0]?.rowId !== "undefined" ? selectedItems[0]?.rowId : '');
    const { isItemAtTop, isItemAtBottom } = checkItemPosition(itemList, selectedItemIndex);

    // const dataWithEmptyRow = () => {
    //     let updatedItem = [...itemList,createEmptyRow(uuidv4(),GroupItemTypes.NON_GROUPED_ITEMS,"","","","","",null)];
    //     updatedItem.at(-1).position = 1;
    //     updatedItem = itemPositioning(true,updatedItem);
    //     setItemList(updatedItem);
    //     updateQuoteDetailsForId(id, { selectedItems: updatedItem });
    //     return updatedItem;

    // }  

    const dataWithEmptyRow = () => {
        let updatedItem = [...itemList];
        // Fetch existing selected items or default to an empty array
        let existingSelectedItems = quoteDetails[id]?.selectedItems || [];


        existingSelectedItems.forEach((existingItem) => {
            updatedItem.push(existingItem);
        });
        if (existingSelectedItems.length === 0) {
            // Add an empty row to the end
            updatedItem.push(createEmptyRow(uuidv4(), GroupItemTypes.NON_GROUPED_ITEMS, "", "", "", "", "", null));
        }

        updatedItem.at(-1).position = 1;

        updatedItem = itemPositioning(true, updatedItem);

        setItemList(updatedItem);

        updateQuoteDetailsForId(id, { selectedItems: updatedItem });

        return updatedItem;
    };
    //shouldAddEmptyRow ? [...itemList, {}] : [...itemList];
    // const visibleColumns = columnsParConsolidate.filter((column) => column.show !== false);
    const visibleColumns = ConsolidateContent.map((column) => ({
        ...column,
        show: true,
    }));

    useEffect(() => {
        try {
            const hasEditorLabourhours =
                selectedItems.length > 0 ? (selectedItems.some((item) => item.rowType === "A") ? true : false) : false;
            const hasInsertLabourhours =
                selectedItems.length > 0 ? (selectedItems[0].rowType === "AI" ? false : selectedItems.length > 0) : false;
            setIsEditLabourHoursEnabled(hasEditorLabourhours);
            setIsInserttEditLabourHoursEnabled(hasInsertLabourhours);
        } catch (error) {
            console.log("Error in useEffect:", error);
        }
    }, [itemList, selectedItems]);


    //added by Manoj
    useEffect(() => {
        if (selectedItems.length > 0) {

            const copyOfItemList = itemList;
            const emptyRowList = copyOfItemList.filter((f) => f.rowType === '');
            if (emptyRowList !== undefined && emptyRowList.length > 0) {

                for (let row of emptyRowList) {

                    if (row.position === copyOfItemList[copyOfItemList.length - 1]) {
                        setShouldAddEmptyRow(false);
                    }

                }

            }


            // const lastRow =  itemList[itemList.length -1] ;

            // const c = itemList;
            // const d = c.filter((f) => f.rowType === '');
            // if(d !== undefined && d.length > 0) {
            //     //const e = d.rowId;
            //     const f = Array.Object.value(d).sort((a, b) => b.value - a.value);
            //     console.log(f);
            // }




            //const lastRowDetails = [...itemList, rowId = getTheActualRow(rowId) ];
            //console.log(lastRowDetails);
            //const lastRowID = getTheActualRow(lastRow.rowId);

            //const a = [];

            //let curRow = getTheActualRow(selectedItems[0].rowId);

            // let b = {rowId : 0, itemId : "0", stStockCode : "", description : "", qty : 0, nettEA : 0, soh : 0, stEecostRound : 0, margin : 0, nettEADiff : 0, marginDiff : 0, groupName : "", subGroupName : "", groupId : "", subGroupId : "", rowType : "", aggregates : ""};
            //         for (let row of itemList) {
            //             console.log(getTheActualRow(row.rowId) + '- '+ row.stStockCode);
            //         }




        }

    }, [selectedItems]);

    useEffect(() => {
        // debugger;
        const hasExportedItems = itemList.length > 0;
        const hasExportConsolidatedItems = consolidateItems.length > 0;
        setIsExportedItems(hasExportedItems);
        setIsExportedConsolidateItems(hasExportConsolidatedItems);
    }, [itemList, consolidateItems, isExportedItems]);

    useEffect(() => {
        //debugger;
        if (addButtonClick) {

            let isGroupItemSelected = false;
            let isNonGroupItemSelected = false;

            const hasNonEmptyDisplayGroupName = itemList.some(
                (item) => item.displayGroupName && item.displayGroupName.trim() !== "" && selectedItems.length === 0
            );

            // Determine if the last item in itemList is a non-group item
            const isLastItemNonGroup =
                itemList.length > 0 && itemList[itemList.length - 1].groupName.includes("Non Grouped Items");

            const selectedItem = selectedItems[0]; // since only one item can be selected at a time
            // if (selectedItem === undefined) return; // exit if no item is selected
            //if (selectedItem.displaySubGroupName === undefined) return; // exit if no displaySubGroupName is available since it is exist when group is created first

            const isSubGroupItemSelected =
                selectedItem && selectedItem.displaySubGroupName === "" && selectedItem.subGroupId !== "";

            //this if used for, stop the error coming from inclues    
            if (selectedItem !== undefined && selectedItem !== '') {
                isGroupItemSelected = selectedItem && selectedItem.groupName.includes("Group");
                isNonGroupItemSelected = selectedItem && selectedItem.groupName.includes("Non Grouped Items");
            }

            // const isNonGroupItemSelected = selectedItem && selectedItem.groupName === "Non Grouped Items";

            const isGroupHeaderSelected =
                selectedItem &&
                selectedItem.displayGroupName &&
                selectedItem.displayGroupName.trim() !== "" &&
                !selectedItem.subGroupName;
            const isSubGroupHeaderSelected =
                selectedItem && selectedItem.displaySubGroupName !== '';

            isSubGroupItemSelected === undefined ? false : isSubGroupItemSelected;
            isGroupItemSelected === undefined ? false : isGroupItemSelected;
            isNonGroupItemSelected === undefined ? false : isNonGroupItemSelected;
            isGroupHeaderSelected === undefined ? false : isGroupHeaderSelected;
            isSubGroupHeaderSelected === undefined ? false : isSubGroupHeaderSelected;


            // setIsSubGroupEnabled(!isLastItemNonGroup || (isGroupHeaderSelected && hasNonEmptyDisplayGroupName));
            setIsInsertGroupEnabled(isGroupHeaderSelected || isNonGroupItemSelected); // Enable if a group is selected
            setIsInsertSubGroupEnabled(
                selectedItems.length > 0 &&
                !isNonGroupItemSelected &&
                !isSubGroupItemSelected &&
                isGroupItemSelected &&
                (isSubGroupHeaderSelected || !isGroupHeaderSelected)
            ); // Enable if a subgroup is selected
            setAddButtonClick(false);
        }
    }, [itemList, selectedItems]);

   useEffect(() => {
        setIsSubGroupEnabled(false);
        setIsInsertGroupEnabled(false);
        setIsInsertSubGroupEnabled(false);
    
        if (itemList.length === 0) return;
        if (selectedItems.length === 0) return; // Exit the useEffect if no item is selected
    
        const selectedItem = selectedItems[0]; // Assuming only one item can be selected at a time
    
        const enableAddSubgroup = (selectedItem?.rowType === "G" && selectedItem.subGroupId === "");
        setIsSubGroupEnabled(enableAddSubgroup);
    
        const enableInsertGroup = (selectedItem?.rowType === "G" || (selectedItem.groupName && selectedItem.groupName.startsWith(GroupItemTypes.NON_GROUPED_ITEMS)));
        setIsInsertGroupEnabled(enableInsertGroup);
    
        if (enableAddSubgroup) return;
    
        let enableInsertSubgroup = false;
        if (selectedItem.rowType === "SG") {
            enableInsertSubgroup = true;
        } else if (
            selectedItem.rowType === "S" && 
            selectedItem.groupName && 
            selectedItem.groupName.startsWith("Group") && 
            selectedItem.subGroupName.length === 0
        ) {
            enableInsertSubgroup = true;
        }
    
        setIsInsertSubGroupEnabled(enableInsertSubgroup);
    
    }, [itemList, selectedItems]);

    useEffect(() => {
        // debugger;
        // Find the last added group, subgroup, and non-group
        const lastAddedGroup = itemList
            .slice()
            .reverse()
            .find((item) => item.groupId && item.subGroupId === "" && !item.groupName?.includes("Non Grouped Items"));
        const lastAddedSubGroup = itemList
            .slice()
            .reverse()
            .find((item) => item.subGroupId);
        const lastAddedNonGroup = itemList
            .slice()
            .reverse()
            .find((item) => item.groupName?.includes("Non Grouped Items"));

        const IsAssemblyItemSelected = selectedItems.some((selectedItem) => selectedItem.rowType === "AI");

        // Check if a DB extra can be added for a group, subgroup, or non-group
        const canAddGroupDbExtra = lastAddedGroup && lastAddedGroup.groupDbExtraId === "";
        const canAddSubGroupDbExtra = lastAddedSubGroup && lastAddedSubGroup.subGroupDbExtraId === "";
        const canAddNonGroupDbExtra = lastAddedNonGroup && lastAddedNonGroup.nonGroupDbExtraId === "";

        // Additional check for non-subgroup items: Ensure the parent group doesn't have a DB extra
        // This check runs only if there are non-subgroup items in the itemList
        let isGroupDbExtraInNonSubgroup = false;
        const nonSubGroupItemsExist = itemList.some((item) => item.subGroupName?.startsWith("Non Sub Group"));
        if (nonSubGroupItemsExist) {
            const nonSubGroupItems = itemList.filter((item) => item.subGroupId === "");
            isGroupDbExtraInNonSubgroup = nonSubGroupItems.some((item) => item.groupDbExtraId !== "");
        }

        // Determine if the "Add DB Extra" button should be enabled
        // const hasNoAddDbExtra =
        //     (canAddGroupDbExtra || canAddSubGroupDbExtra || canAddNonGroupDbExtra) && !isGroupDbExtraInNonSubgroup;
        const hasInsertItems =
            (canAddGroupDbExtra || canAddSubGroupDbExtra || canAddNonGroupDbExtra) &&
            !isGroupDbExtraInNonSubgroup &&
            selectedItems.length >= 1;

        const lastItem = itemList.at(-1);
        let inAGroup = false;
        let inASGroup = false;
        if (lastItem) {
            //
            if (lastItem.subGroupName.startsWith("Sub Group")) inASGroup = true;
            if (lastItem.groupName.startsWith("Group")) inAGroup = true;
        }

        let filteredItemList = [];
        itemList.forEach(f => {
            filteredItemList.push(f); // Push the item to filteredItemList if the condition is met
        });

        let hasNoAddDbExtra = true; // Default to true if filteredItemList is empty
        if (filteredItemList.length > 0) {
            let hasNoAddDbExtra;
            if (inASGroup) {
                const existsInSameSubGroup = filteredItemList.some((f) => f.rowType === "DX" && f.subGroupId === lastItem.subGroupId);
                hasNoAddDbExtra = !existsInSameSubGroup;
            } else if (inAGroup) {
                const existsInSameGroupNoSubGroup = filteredItemList.some((f) => f.rowType === "DX" && f.groupId === lastItem.groupId && f.subGroupId === "");
                hasNoAddDbExtra = !existsInSameGroupNoSubGroup;
            } else {
                const existsInNonGroupedItems = filteredItemList.some((item) => {
                    // If the item is an array, we need to check its elements
                    if (Array.isArray(item)) {
                        return item.some((f) => {
                            // Validate that groupName exists and is a string
                            if(f.groupName === "Non Grouped Items"){
                            return typeof f.groupName === 'string' && f.groupName.startsWith("Non Grouped Items") && f.rowType === "DX";
                            }
                        });
                    } else {
                        // If the item is not an array, treat it as a single object
                        return typeof item.groupName === 'string' && item.groupName.startsWith("Non Grouped Items") && item.rowType === "DX";
                    }
                });
                hasNoAddDbExtra = !existsInNonGroupedItems ? true : false;
            }
        }
        else{
            hasNoAddDbExtra = true;
        }

        // const hasNoAddDbExtra = (inASGroup
        //     ? !itemList.some((f) => f.rowType == "DX" && f.subGroupId === lastItem.subGroupId)
        //     : inAGroup
        //         ? !itemList.some((f) => f.rowType == "DX" && f.groupId === lastItem.groupId && f.subGroupId === "")
        //         : !itemList.some((f) => f.groupName.startsWith("Non Grouped Items") && f.rowType === "DX")
        //             ? true : false);
        let hasNoAddDbExtraForInsert = false;

        if (selectedItems.length > 0) {
            if ((selectedItems.at(-1).subGroupId || "").length > 0) {
                if (selectedItems.at(-1).rowType === "SG") {
                    hasNoAddDbExtraForInsert = !itemList.some((f) => f.rowType == "DX" && f.groupId === selectedItems.at(-1).groupId && f.subGroupId === "");
                } else
                    hasNoAddDbExtraForInsert = !itemList.some((f) => f.rowType == "DX" && f.subGroupId === selectedItems.at(-1).subGroupId);
            } else if ((selectedItems.at(-1).groupId || "").length > 0) {
                hasNoAddDbExtraForInsert = !itemList.some((f) => f.rowType == "DX" && f.groupId === selectedItems.at(-1).groupId && f.subGroupId === "");
            } else {
                console.log("STEPH 1");
                hasNoAddDbExtraForInsert = !itemList.some((f) => f.rowType == "DX" && f.groupName.startsWith("Non Grouped Items"));
                console.log("STEPH 2");
            }
        }

        // Enable the "Add DB Extra" button when itemList is empty
        ///check if the grid is empty or empty row only in the grid.
        const emptyGrid = itemList.length === 0 || (itemList.length === 1 && itemList[0].stStockCode === "");
        if (emptyGrid) {
            setIsAddDbExtra(true);
            setIsInsertItems(hasInsertItems);
            setIsInsertDbExtra(false);
            return;
        }

        // Set state based on the conditions
        // setIsInsertItems(hasInsertItems && !IsAssemblyItemSelected);
        setIsInsertItems(selectedItems.length >= 1 && !IsAssemblyItemSelected);
        setIsAddDbExtra(hasNoAddDbExtra === undefined ? true : hasNoAddDbExtra); // Enabled if a new group, subgroup, or non-group section can have a DB extra and no parent group DB extra for non-subgroup items
        setIsInsertDbExtra(hasNoAddDbExtraForInsert && !IsAssemblyItemSelected);
    }, [itemList, selectedItems, quoteDetails]);

    useEffect(() => {
        const IsAssemblyItemSelected = selectedItems.some((selectedItem) => selectedItem.rowType === "AI");
        setDisableNote(IsAssemblyItemSelected);
    }, [selectedItems]);

    const disableGroup = () => {
        try {
            if (selectedItems.length === 0) return true;
            if (selectedItems.length > 1) return true;
            if (!itemList.some((f) => f.rowType === "G" || f.rowType === "SG")) return true;
            if (selectedItems.at(-1)?.subGroupName?.startsWith("Sub Group")) return true;
            if (selectedItems.at(-1)?.rowType === "G" || selectedItems.at(-1).rowType === "SG") return true;
            if (typeof selectedItems.at(-1)?.rowId === itemList.at(-1).rowId && itemList.length === 1) return true;
            const { groupId, subGroupId, subGroupName, groupName } = selectedItems.at(-1);
            if (selectedItems.at(-1)?.rowId === itemList.at(-1)?.rowId) {
                if (itemList.length > 1) {
                    const lastItem = itemList.at(-1);
                    if (lastItem.groupName?.startsWith("Non Grouped Items") && lastItem.stStockCode === "") return false;
                }
            }
            if (itemList.length === 1 && groupName.startsWith("Non Grouped Items")) return true;
            const checkSubGroupExist = itemList.some((item) => item.groupId === groupId && item.rowType === "SG" && item.subGroupName?.startsWith("Sub Group"));
            if (groupName?.startsWith("Non Grouped Items")) return true;
            if (selectedItems.at(-1)?.rowId !== itemList.at(-1)?.rowId && checkSubGroupExist) return true;

            return false;    
        } catch (error) {
            console.log("disableGroup",error);
            return true;
        }
            
        
    };

    const disableUngroup = () => {
        setIsQuoteSaved(false);
        if (selectedItems === undefined) return true;
        if (selectedItems === null) return true;
        if (selectedItems.length === 0) return true;
        if (selectedItems.length > 1) return true;
        if (selectedItems.length > 0 && selectedItems.at(-1)?.groupName?.startsWith("Non Grouped Items")) return true;
        const checkSubGroupExist = itemList.some((item) => item.groupId === groupId && item.rowType === "SG" && item.subGroupName?.startsWith("Sub Group"));
        if (selectedItems.at(-1)?.groupName?.startsWith("Non Grouped Items")) return true;
        if (selectedItems.at(-1)?.rowId !== itemList.at(-1).rowId && checkSubGroupExist) return true;
        const hasGroupSubGroup = selectedItems.some((item) => item?.rowType === "G" || item?.rowType === "SG");
        if (hasGroupSubGroup) return true;
        if (selectedItems?.length > 0 && 
            selectedItems.at(-1)?.stStockCode?.length > 0 &&
            selectedItems.at(-1)?.rowType !== "G" && 
            selectedItems.at(-1)?.rowType !== "SG") {
            return true;
        }
        
        return false;
        
    };

    useEffect(() => {
        if (quoteDetails) {
            const quoteDetailsString = JSON.stringify(quoteDetails);
            const compressedQuoteDetails = LZString.compress(quoteDetailsString);

            try {
                sessionStorage.setItem("quoteDetails", compressedQuoteDetails);
            } catch (e) {
                if (e instanceof DOMException && (e.code === 22 || e.code === 1014) || e.name === 'QuotaExceededError') {
                    console.warn('SessionStorage quota exceeded. Consider using IndexedDB for large data.');
                    
                } else {
                    console.error('An error occurred while setting item in sessionStorage', e);
                }
            }
        }
    }, [quoteDetails]);


    const updatedConsolidateItems = consolidateItems.map((item) => ({
        ...item,
        margin: differ ? "Differ" : item.margin,
        nettEA: authType !== "General" && differNettEA ? "Differ" : item.nettEA,
    }));

    const ogItem = quoteDetails[id]?.selectedItems?.filter(item => item?.rowType !== '').map((item) => {
        //const displayNongroup = item.groupName.startsWith(GroupItemTypes.NON_GROUPED_ITEMS) ? item.groupName : item.displayGroupName
        const stEecostValue =
            qteCurGridViewType === "Normal" &&
                qteAuthType === "General" &&
                item.rowType !== "CI" &&
                item.stEecostRound != null
                ? "***"
                : item.stEecostRound;

        return {
            ...item,
            stEecostRound: stEecostValue,
            //displayGroupName : displayNongroup
        };
    });

    const [show, setShow] = React.useState(false);

    // Global click detection
    // document.addEventListener('click', () => {
    //     show ? setShow(false) : null;
    //     });
    document.addEventListener('click', function(event) {
        var viewType = qteCurGridViewType;
        // qteCurGridViewType === "Normal"
        show ? setShow(false) : null;
        console.log('Mouse clicked on the page!');
    });

    // const ogItems = quoteDetails[id]?.selectedItems?.reduce((acc, item) => {
    //     const existingItem = acc.find((accItem) => accItem.stStockCode === item.stStockCode);

    //     if (existingItem) {
    //         // If the item with the same stStockCode already exists, update its qty
    //         existingItem.qty += item.qty;
    //     } else {
    //         // If the item with stStockCode doesn't exist, add it to the accumulator
    //         acc.push({ ...item });
    //     }

    //     return acc;
    // }, []);
    // const ogItems = (() => {
    //     if (!quoteDetails || !quoteDetails[id]) {
    //         return [];
    //     }

    //     const newId = id;
    //     const selectedItems = quoteDetails[newId]?.selectedItems || [];

    //     const newItemsGridData = selectedItems.filter(
    //         (f) => f.rowType !== "A" && f.rowType !== "AI" && f.rowType !== "LN" 
    //     );

    //     const dxItems = selectedItems.filter(
    //         (f) => f.rowType === "DX"
    //     );

    //     const newItemsGridDataAssembly = selectedItems.filter((f) => f.rowType === "A");

    //     const sumByStId = newItemsGridData.reduce((acc, item) => {
    //         const { stId, stStockCode, qty, rowType, groupId, subGroupId } = item;

    //         if (stStockCode !== 'CONSUMABLES') {
    //             if (!acc[stId]) {
    //                 acc[stId] = 0;
    //             }

    //             if (rowType !== 'G' && rowType !== "SG") {
    //                 const hasGroup = newItemsGridData.find((f) => f.groupId === item.groupId && f.rowType === 'G');
    //                 const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === item.subGroupId && f.rowType === 'SG');

    //                 if (hasGroup) {
    //                     let totals = 0;
    //                     if (hasSubGroup) {
    //                         totals += qty * hasSubGroup.qty;
    //                         acc[stId] += hasGroup.qty * totals;
    //                     } else {
    //                         acc[stId] += qty * hasGroup.qty;
    //                     }
    //                 }
    //             }
    //         }
    //         return acc;
    //     }, {});

    //     for (let i = 0; i < dxItems.length; i++) {
    //         const { stId, qty } = dxItems[i];
    //         if (!sumByStId[stId]) {
    //             sumByStId[stId] = 0;
    //         }
    //         sumByStId[stId] += qty;
    //     }

    //     const newGridData = Object.keys(sumByStId).map((stId) => {
    //         const item = newItemsGridData.find((item) => item.stId === stId);
    //         return {
    //             stId,
    //             stStockCode: item.stStockCode,
    //             soh: item.soh,
    //             description: item.description,
    //             nettEA: parseFloat(item.nettEA || 0).toFixed(2),
    //             nettEADiff:parseFloat(item.nettEA || 0).toFixed(2),
    //             dcOnLp: parseFloat(item.dcOnLp || 0).toFixed(1),
    //             qty: sumByStId[stId],
    //             listPrice: parseFloat(item.listPrice || 0).toFixed(2),
    //             totalPrice: parseFloat(item.totalPrice || 0).toFixed(2),
    //             margin: parseFloat(item.margin || 0).toFixed(2),
    //             marginDiff: parseFloat(item.margin || 0).toFixed(2),
    //             actMargin: parseFloat(item.actMargin || 0).toFixed(2),
    //             lcMargin: parseFloat(item.lcMargin || 0).toFixed(2),
    //             frcMargin: parseFloat(item.frcMargin || 0).toFixed(2),
    //             repDisc: parseFloat(item.repDisc || 0),
    //             stEecostRound: parseFloat(item.stEecostRound || 0),
    //             actCostExact: parseFloat(item.actCostExact || 0),
    //             lastCostExact: parseFloat(item.lastCostExact || 0),
    //             isDeleted: false,
    //             rowType: item.rowType,
    //             hidden: item.rowType === 'LN' || item.rowType === 'G' || item.rowType === 'SG'
    //         };
    //     });

    //     const newGridDataAssembly = newItemsGridDataAssembly.map((item) => ({
    //         assemblyId: item.assemblyId,
    //         stStockCode: item.stStockCode,
    //         soh: item.soh,
    //         description: item.description,
    //         nettEA: parseFloat(item.nettEA || 0).toFixed(2),
    //         nettEADiff:parseFloat(item.nettEA || 0).toFixed(2),
    //         dcOnLp: parseFloat(item.dcOnLp || 0).toFixed(1),
    //         qty: item.qty,
    //         listPrice: parseFloat(item.listPrice || 0).toFixed(2),
    //         totalPrice: parseFloat(item.totalPrice || 0).toFixed(2),
    //         margin: parseFloat(item.margin || 0).toFixed(2),
    //         marginDiff: parseFloat(item.margin || 0).toFixed(2),
    //         actMargin: parseFloat(item.actMargin || 0).toFixed(2),
    //         frcMargin: parseFloat(item.frcMargin || 0).toFixed(2),
    //         stEecostRound: parseFloat(item.stEecostRound || 0),
    //         actCostExact: parseFloat(item.actCostExact || 0),
    //         lastCostExact: parseFloat(item.lastCostExact || 0).toFixed(2),
    //         isDeleted: false,
    //         rowType: item.rowType,
    //     }));
    //     const combinedList = [...newGridData, ...newGridDataAssembly];
    //     const FinalList = combinedList.filter((f) => f.rowType !== 'G' && f.rowType !== 'SG' && f.rowType !== 'LN' && f.rowType !== '');
    // console.log("items conso :",FinalList );
    //     return FinalList.sort((a, b) => a.position - b.position);
    // })();  

    function isNettEASame(items, stStockCode) {
        if(stStockCode !== ""){
        const lstItems = items.find((item) => item.stStockCode === stStockCode);
        let netEAVal = lstItems?.nettEA;

        for (let i = 0; i < items.length; i++) {
            if (items[i].stStockCode === stStockCode && items[i].nettEA !== netEAVal) {
                return false;
            }
        }
    }

        return true;

    }

    function isMarginSame(items, stStockCode) {
        if(stStockCode !== ""){
        const lstItems = items.find((item) => item.stStockCode  === stStockCode );
        let marginVal = lstItems.margin;

        for (let i = 0; i < items.length; i++) {
            if (items[i].stStockCode  === stStockCode  && items[i].margin !==  marginVal) {
                return false;
            }
        }
    }

        return true;
    }

    const ogItems = (() => {
        if (!quoteDetails || !quoteDetails[id]) {
            return [];
        }

        const newId = id;
        const selectedItems = quoteDetails[newId]?.selectedItems || [];

        let newItemsGridData = selectedItems.filter(
            (f) =>f.rowType !== "AI" && f.rowType !== "LN"
        );

        newItemsGridData = newItemsGridData.reduce((acc, item) => {
            if (item.rowType === 'CI' || item.rowType === 'A') {
                const existingItem = acc.find(i => i.stStockCode === item.stStockCode);
                if (existingItem) {
                    existingItem.qty += item.qty;
                } else {
                    acc.push({ ...item });
                }
            } else {
                acc.push(item);
            }
            return acc;
        }, []);


        newItemsGridData = newItemsGridData.map(item => {
            if (item === undefined || item.stId === undefined) {
                return {
                    ...item,
                    stId: '00000000-0000-0000-0000-000000000000'
                    
                };
            }
            else if (item.rowType === 'CI') {
                return {
                    ...item,
                    stId: uuidv4(),
                };
            }
            return item;
        });

        


        const dxItems = selectedItems.filter(
            (f) => f.rowType === "DX"
        );
        let assemblyItems = selectedItems.filter(
            (f) => f.rowType === "A"
        );


        const findDuplicates = (arr) => {
            const set = new Set();
            const duplicates = [];
            arr.forEach((item) => {
                if (set.has(item)) {
                    duplicates.push(item);
                } else {
                    set.add(item);
                }
            });
            return duplicates;
        };
        let consumable = '';
        let assembly = '';
        const sumByStId =
            newItemsGridData === undefined
                ? 0
                : newItemsGridData.reduce((acc, item) => {
                    const { stId, stStockCode, qty} = item;

                    findDuplicates(newItemsGridData.map((item) => item.stStockCode));

                    if (stStockCode !== 'CONSUMABLES' && stStockCode !== 'ASSEMBLY') {
                        if (!acc[stId]) {
                            acc[stId] = 0;
                        }

                        if (item.rowType !== 'G' && item.rowType !== "SG") {
                            const hasGroup = newItemsGridData.find((f) => f.groupId === item.groupId && f.rowType === 'G');
                            const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === item.subGroupId && f.rowType === 'SG');

                            if (hasGroup) {
                                let totals = 0;
                                if (hasSubGroup) {
                                    totals += qty * hasSubGroup.qty;
                                    acc[stId] += hasGroup.qty * totals;
                                } else {
                                    acc[stId] += qty * hasGroup.qty;
                                }
                            } else {
                                acc[stId] += qty;
                            }
                        }
                    }
                    return acc;
                }, []);

        for (let i = 0; i < dxItems.length; i++) {
            const { stId, qty } = dxItems[i];

            const hasGroup = newItemsGridData.find((f) => f.groupId === dxItems[i].groupId && f.rowType === 'G');
            const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === dxItems[i].subGroupId && f.rowType === 'SG');

            if (consumable === '' || consumable === undefined) {
                if (!sumByStId[stId]) {
                    sumByStId[stId] = 0;
                }

                if (hasGroup) {
                    if (hasSubGroup) {
                        sumByStId[stId] += qty * hasSubGroup.qty * hasGroup.qty;
                    } else {
                        sumByStId[stId] += qty * hasGroup.qty;
                    }
                } else {
                    sumByStId[stId] += qty;
                }
                consumable = stId;
            }
             else {
                if (hasGroup) {
                    if (hasSubGroup) {
                        sumByStId[consumable] += qty * hasSubGroup.qty * hasGroup.qty;
                    } else {
                        sumByStId[consumable] += qty * hasGroup.qty;
                    }
                } else {
                    sumByStId[consumable] += qty;
                }
            }
        }

        for (let i = 0; i < assemblyItems.length; i++) {
            assemblyItems = assemblyItems.map(item => {
                if (item === undefined || item.stId === undefined) {
                    return {
                        ...item,
                        stId: '00000000-0000-0000-0000-000000000000'
                    };
                }
                return item;
            });
        
            const { stId, qty } = assemblyItems[i];
        
            // Round the qty to the nearest whole number
            const roundedQty = Math.round(qty);
        
            const hasGroup = newItemsGridData.find((f) => f.groupId === assemblyItems[i].groupId && f.rowType === 'G');
            const hasSubGroup = newItemsGridData.find((f) => f.subGroupId === assemblyItems[i].subGroupId && f.rowType === 'SG');
        
            if (assembly === '' || assembly === undefined) {
                if (!sumByStId[stId]) {
                    sumByStId[stId] = 0;
                }
        
                if (hasGroup) {
                    if (hasSubGroup) {
                        sumByStId[stId] += roundedQty * hasSubGroup.qty * hasGroup.qty;
                    } else {
                        sumByStId[stId] += roundedQty * hasGroup.qty;
                    }
                } else {
                    sumByStId[stId] += roundedQty;
                }
                assembly = stId;
            } else {
                if (hasGroup) {
                    if (hasSubGroup) {
                        sumByStId[assembly] += roundedQty * hasSubGroup.qty * hasGroup.qty;
                    } else {
                        sumByStId[assembly] += roundedQty * hasGroup.qty;
                    }
                } else {
                    sumByStId[assembly] += roundedQty;
                }
            }
        }
        

       



        const newGridData = Object.keys(sumByStId).map((stId) => {
            const item = newItemsGridData.find((item) => item.stId === stId);
            if (item) {
                return {
                    stId,
                    stStockCode: item.stStockCode || '',
                    soh: item.soh || 0,
                    description: item.description || '',
                    nettEA:  parseFloat(item.nettEA || 0).toFixed(2),
                    nettEADiff: !isNettEASame(newItemsGridData , item.stStockCode)  ? 'Differ' :  parseFloat(item.nettEA || 0).toFixed(2),
                    dcOnLp: parseFloat(item.dcOnLp || 0).toFixed(1),
                    qty: sumByStId[stId],
                    listPrice: parseFloat(item.listPrice || 0).toFixed(2),
                    totalPrice: parseFloat(item.totalPrice || 0).toFixed(2),
                    margin: parseFloat(item.margin || 0).toFixed(2),
                    marginDiff:  !isMarginSame(newItemsGridData , item.stStockCode) ? 'Differ' : parseFloat(item.margin || 0).toFixed(2),
                    actMargin: parseFloat(item.actMargin || 0).toFixed(2),
                    lcMargin: parseFloat(item.lcMargin || 0).toFixed(2),
                    frcMargin: parseFloat(item.frcMargin || 0).toFixed(2),
                    repDisc: parseFloat(item.repDisc || 0),
                    stEecostRound: parseFloat(item.stEecostRound || 0),
                    actCostExact: parseFloat(item.actCostExact || 0),
                    lastCostExact: parseFloat(item.lastCostExact || 0),
                    isDeleted: false,
                    rowType: item.rowType,
                    hidden: item.rowType === 'LN' || item.rowType === 'G' || item.rowType === 'SG'
                };
            } else {
                console.error(`Item with stId ${stId} not found in newItemsGridData`);
                return null;
            }
        }).filter(item => item !== null); // Filter out null values

        // const newGridDataAssembly = newItemsGridDataAssembly.map((item) => ({
        //     assemblyId: item.assemblyId || '',
        //     stStockCode: item.stStockCode || '',
        //     soh: item.soh || 0,
        //     description: item.description || '',
        //     nettEA: parseFloat(item.nettEA || 0).toFixed(2),
        //     nettEADiff: parseFloat(item.nettEA || 0).toFixed(2),
        //     dcOnLp: parseFloat(item.dcOnLp || 0).toFixed(1),
        //     qty: item.qty || 0,
        //     listPrice: parseFloat(item.listPrice || 0).toFixed(2),
        //     totalPrice: parseFloat(item.totalPrice || 0).toFixed(2),
        //     margin: parseFloat(item.margin || 0).toFixed(2),
        //     marginDiff: parseFloat(item.margin || 0).toFixed(2),
        //     actMargin: parseFloat(item.actMargin || 0).toFixed(2),
        //     frcMargin: parseFloat(item.frcMargin || 0).toFixed(2),
        //     stEecostRound: parseFloat(item.stEecostRound || 0),
        //     actCostExact: parseFloat(item.actCostExact || 0),
        //     lastCostExact: parseFloat(item.lastCostExact || 0).toFixed(2),
        //     isDeleted: false,
        //     rowType: item.rowType,
        // }));

        const combinedList = [...newGridData].filter(item => item !== null);

        const FinalList = combinedList.filter((f) => f.rowType !== 'G' && f.rowType !== 'SG' && f.rowType !== 'LN' && f.rowType !== '');

        return FinalList.sort((a, b) => (a.position || 0) - (b.position || 0));
    });


    const excelview = [
        { field: "groupName", title: "GroupHidden", show: false },
        { field: "subGroupName", title: "SubGroupHidden", show: false },
        { field: "rowCategory", title: "RowCategoryHidden", show: false },
        { field: "displayGroupName", title: "Groups", editable: true, show: true },
        { field: "displaySubGroupName", title: "Sub Groups", editable: true, show: true },
        { field: "stStockCode", title: "Part No", editable: true, show: true, minWidth: 100 },
        { field: "description", title: "Description", editable: true, show: true, minWidth: 150 },
        { field: "type", title: "Type", show: true },
        { field: "rowType", title: "Row Type", show: false },
        { field: "dcCode", title: "Dc Code", show: true },
        { field: "dcRate", title: "Dc Rate(%)", show: true },
        { field: "listPrice", title: "List Price", show: true },
        { field: "l60", title: "L60 Price", show: true },
        { field: "l50", title: debtorType ? `L50 (${debtorType})` : "L50 Price", show: true },
        //20/12/2023 changed by sachini -add L35 but same as L55 but this use for getting NettEA and changed order of price levels
        { field: "l55", title: "L35 Price", show: true },
        { field: "l20", title: priceCategory ? `L20 (${priceCategory})` : "L20 Price", show: true },
        { field: "stEecostRound", title: "CostEA", editable: true, show: true },
        { field: "stEecost", title: "CostEA Exact", show: false },
        { field: "costEA", title: "CostEA old", show: false },
        // { field: "actCost", title: "Act. Cost", show: true },
        { field: "actCost", title: "Avg. Cost", show: true },
        // { field: "stFutureCostRound", title: "Fut.Real Cost", show: true },
        { field: "stFutureCostRound", title: "Fut. Cost", show: true },
        { field: "stFutureCost", title: "Fut.Real Cost exact ", show: false },
        { field: "lastCost", title: "Last Cost", show: true },
        { field: "costEAExact", title: "CostEAExact old", show: false },
        { field: "dcOnLp", title: "Dc on LP(%)", editable: true, show: true },
        { field: "soh", title: "SOH", show: true },
        { field: "qty", title: "Qty", editable: true, show: true },
        { field: "nettEA", title: "NettEA", editable: true, show: true },
        { field: "repDisc", title: "Rep Disc (%)", editable: true, footerCell: RepDiscFooterCell, show: true },
        { field: "margin", title: "Margin (%)", editable: true, show: true },
        { field: "actMargin", title: "AvgC. Margin (%)", show: true },
        { field: "frcMargin", title: "FC. Margin (%)", show: true },
        { field: "lcMargin", title: "LC. Margin (%)", show: true },
        { field: "totalCost", title: "Total Cost", footerCell: TotalCostFooterCell, show: true, minWidth: 70 },
        { field: "totalPrice", title: "Total Price", footerCell: TotalPriceFooterCell, show: true, minWidth: 70 },
        { field: "hideRow", title: "Hide Row", show: false },
        { field: "hideQty", title: "Hide Qty", show: false },
        { field: "hidePrice", title: "Hide Price", show: false },
        { field: "assemblyId", title: "assemblyId", editable: false, show: false },
    ];

    //#region approval workflow - qteApprovalActionForm

    //#region RepDiscount
    const [hasAprvlNotifMsg, setHasAprvlNotifMsg] = useState(false);

    const checkRepDiscLimit = async (data) => {
        let dataItem;
        try {
            dataItem = typeof data.repDisc !== "undefined" ? data : data.at(-1);
        } catch (error) {
            console.log(error);
        }

        if (!quoteDetails[id].savedQuote) return;
        if (quoteDetails[id].savedQuote.approvalStatusId > 3) return;
        if (dataItem === null || dataItem === undefined) return;
        if (dataItem.length === 0) return;
        const repDiscValue = dataItem.repDisc;
        let repDiscData = await getResponse("Quote/checkmaxrepdiscount", {
            custId: customerId,
            userId: quoteDetails[id].savedQuote.createdBy ? quoteDetails[id].savedQuote.createdBy : user?.homeAccountId,
            repDiscount: isNaN(parseFloat(repDiscValue)) ? 0 : repDiscValue,
        });

        if (repDiscData.statusCode !== null && repDiscValue > 0) {
            if (repDiscData.statusCode) {
                if (quoteRepDiscData.length === 0) {
                    const rpDisc = repDiscValue > 0 ? repDiscValue * 0.01 : 0;
                    setQuoteRepDiscData([
                        {
                            ...quoteRepDiscData, ...repDiscData.data,
                            stStockCode: dataItem.stStockCode,
                            rowId: dataItem.rowId,
                            currRepDisc: rpDisc
                        }
                    ]);

                    if (rpDisc > repDiscData.data.cuMaxDiscount) setApprovalForRepDiscount();
                }
                else {
                    let index = quoteRepDiscData.findIndex(x => x.rowId === dataItem.rowId);
                    if (index !== -1) {
                        quoteRepDiscData[index].currRepDisc = repDiscValue > 0 ? repDiscValue * 0.01 : 0
                        setQuoteRepDiscData([...quoteRepDiscData]);
                        if (quoteRepDiscData[index].currRepDisc > quoteRepDiscData[index].cuMaxDiscount) setApprovalForRepDiscount();
                    }
                    else {
                        quoteRepDiscData.push({
                            ...repDiscData.data,
                            stStockCode: dataItem.stStockCode,
                            rowId: dataItem.rowId,
                            currRepDisc: repDiscValue > 0 ? repDiscValue * 0.01 : 0
                        });
                    }
                }

            }


        }
        else {
            //check if item previously added to quoteRepDiscData
            if (quoteRepDiscData.length > 0) {
                let index = quoteRepDiscData.findIndex(x => x.rowId === dataItem.rowId);
                if (index !== -1) {
                    quoteRepDiscData[index].currRepDisc = repDiscValue > 0 ? repDiscValue * 0.01 : 0
                    setQuoteRepDiscData([...quoteRepDiscData]);
                }
                else {
                    quoteRepDiscData.push({
                        ...repDiscData.data,
                        stStockCode: dataItem.stStockCode,
                        rowId: dataItem.rowId,
                        currRepDisc: repDiscValue > 0 ? repDiscValue * 0.01 : 0
                    });
                }
            }
        }
    }
    //#endregion


    const [approvedLimit, setApprovedLimit] = useState(0);
    let qteAppHeaderText = (`Approval needs to be sent in this quote. Select your approver and enter your comment. Your approver will then receive an email notification to approve or reject your quote.`);
    let varShareQuoteFlag = shareQuoteFlag;

    const approFormTitle = ["Send For Approval", "Approval Action", "Share Quote"];
    const approFormType = ["User", "Approver"];
    const [qteApprovalActionForm, setQteApprovalActionForm] = useState(false);

    const [qteApprovalHeaderMsg, setQteApprovalHeaderMsg] = useState(qteAppHeaderText);
    const [qteApprovalFormtitle, setQteApprovalFormtitle]
        = useState(
            approFormTitle[quoteApprovalStatId >= 2 ? 1 : 0]
        );
    const [qteApprovalFormType, setQteApprovalFormType]
        = useState(
            approFormType[quoteApprovalStatId >= 2 || shareQuoteFlag === 1 ? 1 : 0]
        );

    useEffect(() => {
        if (shareQuoteFlag) {
            setQteApprovalHeaderMsg("");
            setQteApprovalFormtitle(approFormTitle[2]);
        } else {
            setQteApprovalHeaderMsg(qteAppHeaderText);
            setQteApprovalFormtitle(approFormTitle[quoteApprovalStatId >= 2 ? 1 : 0]);
        }
        setQteApprovalFormType(approFormType[quoteApprovalStatId >= 2 || shareQuoteFlag === 1 ? 1 : 0]);
    }, [shareQuoteFlag]);

    const resetQteApprovalForm = () => {
        // setQuoteApprovalMesasge('');
        setQuoteApprovalStatus('');
        setQuoteApprovalStatId(0);
        setCurrentApprover("");
        setQuoteOwner("");

    }

    // const retrieveApprovalReference = async () => {
    //     let retmsg = await getResponse("Quote/getapprovalmessage", {});

    //     if (retmsg.statusCode) {
    //         setQteApprovalMessage(retmsg.data);
    //     }

    //     const response = await getResponse("Quote/getapprovalstatslist", {});
    //     if (Object.keys(response).length > 0) {
    //         setApprovalStatList(response.data);
    //     }
    // }


    const checkApprovalStatusState = async () => {

        resetQteApprovalForm();
        if (quoteDetails[id] !== null && quoteDetails[id] !== undefined) {
            checkApprovalStatusOnChange();
        }
    }

    const [checkedAprvlMargin, setCheckedAprvMargin] = useState({ data: [] });
    const [checkedAmtLimit, setCheckedAmtLimit] = useState({ data: [] });

    const checkAprvlMargin = async () => {
        if (!quoteDetails[id]) return;
        if (quoteDetails[id].savedQuote === undefined) return;
        if (itemList.length === 0) return;
        if (itemList.length === 1 && itemList[0].rowType === "") return;
        const approvalMarginDto = {
            userId: (typeof quoteDetails[id].savedQuote === "undefined" || (!checkApprovalStateFor === "" && !checkApprovalStateFor === null && !typeof checkApprovalStateFor === "undefined"))
                ? user?.homeAccountId
                : checkApprovalStateFor.length > 0 
                    ? user?.homeAccountId
                    : (quoteDetails[id].savedQuote?.createdBy) ? quoteDetails[id].savedQuote?.createdBy : user?.homeAccountId,
            quoteType: quoteDetails[id]?.quoteType,
            company: quoteDetails[id]?.header,
            margin: !isNaN(maxMargin)
                ? maxMargin === 0 && typeof quoteDetails[id].savedQuote !== 'undefined'
                    ? quoteDetails[id].savedQuote?.margin
                    : maxMargin
                : 0,
        }

        const retMargin = await postResponse("Quote/getmarginlimit", approvalMarginDto);
        setCheckedAprvMargin(retMargin); //always get the first record
    }

    const checkAprvlAmtLimit = async () => {
        if (!quoteDetails[id]) return;
        if (quoteDetails[id].savedQuote === undefined) return;
        if (itemList.length === 0) return;
        if (itemList.length === 1 && itemList[0].rowType === "") return;
        let approvalAmountDto = {
            userId: (typeof quoteDetails[id].savedQuote === "undefined"
                || (!checkApprovalStateFor === "" && !checkApprovalStateFor === null && !typeof checkApprovalStateFor === "undefined")) 
                ? user?.homeAccountId 
                : checkApprovalStateFor.length > 0 
                    ? user?.homeAccountId
                    : quoteDetails[id].savedQuote?.createdBy,
            quoteType: quoteDetails[id]?.quoteType,
            quoteAmt: totalItemPrice <= 0 && typeof quoteDetails[id].savedQuote !== 'undefined' ? quoteDetails[id].savedQuote?.amount : totalItemPrice,
        }

        const retAmtLimit = await postResponse("Quote/getquoteamtapprovallimit", approvalAmountDto) ?? "";
        setCheckedAmtLimit(retAmtLimit);
    }

    const processedApprovalStatus = () => {
        let localApprovalState = {
            "ApprovalStatus" : "",
            "ApprovalStatusId" : null
        }
        if (itemList.length === 0) return;
        const retAmtLimit = checkedAmtLimit;
        const retMargin = checkedAprvlMargin;
        if (!quoteDetails[id]) return;
        if (approvalStatList.length === 0) return;
        if (typeof quoteDetails[id] === "undefined") return;
        if (quoteDetails[id]?.savedQuote && (checkApprovalStateFor === "" || checkApprovalStateFor === null || typeof checkApprovalStateFor === "undefined")) {
            const checkFormat = isNumber(quoteDetails[id]?.savedQuote?.approvalStatusId);
            if (checkFormat) {
                if (quoteDetails[id].savedQuote.approvalStatusId > 3) {
                    const idxapp = approvalStatList.findIndex(x => x.approvalStatusID === quoteDetails[id].savedQuote.approvalStatusId);
                    if (idxapp < 0) return;
                    const appStatus = idxapp > -1 ? approvalStatList[idxapp].approvalStatus : '';
                    setQuoteApprovalMesasge("");
                    setQuoteApprovalStatus(appStatus);
                    setQuoteApprovalStatId(quoteDetails[id].savedQuote.approvalStatusId);
                    localApprovalState.ApprovalStatus = appStatus;
                    localApprovalState.ApprovalStatusId = quoteDetails[id].savedQuote.approvalStatusId;
                    return;
                }
                // else if (quoteDetails[id].savedQuote.approvalStatusId === 2) return;
            }

        }

        if (typeof retAmtLimit.data !== "undefined" && retMargin.data !== null) {
            if (retAmtLimit.statusCode && approvalStatList !== null && maxMargin > 0 && retAmtLimit.data.length > 0) {

                const idx = qteApprovalMessage.findIndex(x => x.ulAccessLevel === retAmtLimit.data[0].qlApprover);
                const ul_msg = idx > -1 ? qteApprovalMessage[idx].ulMessage + " - " + QuoteApprovalMsg.QTE_AMT_HIGH : "";
                setQuoteApprovalMesasge(ul_msg);

                if (ul_msg.length > 0) {
                    setCurrAccessLevel(retAmtLimit.data[0].qlApprover);
                }

                if (typeof quoteDetails[id].savedQuote !== 'undefined') {
                    if (typeof quoteDetails[id].savedQuote.approvalStatusId !== 'undefined' && quoteDetails[id].savedQuote.approvalStatusId !== null) /// it means it is draft state
                    {
                        const appStatus = approvalStatList[quoteDetails[id].savedQuote.approvalStatusId].approvalStatus;
                        setQuoteApprovalStatus(appStatus);
                        setQuoteApprovalStatId(quoteDetails[id].savedQuote.approvalStatusId);
                        localApprovalState.ApprovalStatus = appStatus;
                        localApprovalState.ApprovalStatusId = quoteDetails[id].savedQuote.approvalStatusId;

                    }
                    else {
                        const appStatus = approvalStatList[1].approvalStatus;
                        setQuoteApprovalStatus(appStatus);
                        setQuoteApprovalStatId(1);
                        localApprovalState.ApprovalStatus = appStatus;
                        localApprovalState.ApprovalStatusId = 1;
                    }

                }
                else {
                    const appStatus = approvalStatList[1].approvalStatus;
                    setQuoteApprovalStatus(appStatus);
                    setQuoteApprovalStatId(1);
                    localApprovalState.ApprovalStatus = appStatus;
                    localApprovalState.ApprovalStatusId = 1;
                }
            }
        }
        const repDisc = itemList.filter((r)=>r.repDisc > 0).reduce((total, item) => { return total = total + parseFloat(isNaN(item.repDisc) ? 0 : item.repDisc) }, 0)
        const customItem = itemList.filter((f) => f.rowType === "CI" && f.nettEA > 0);
        if (repDisc > 0 || (maxMargin !== 0 && customItem.length > 0)) {

            if (retMargin.data !== null) {
                if (Object.keys(retMargin.data).length > 0) {
                    if (retMargin.statusCode && approvalStatList !== null) {
                        const idx = qteApprovalMessage.findIndex(x => x.ulAccessLevel === retMargin.data[0].qlApprover);
                        const ul_msg = idx > -1 ? qteApprovalMessage[idx].ulMessage + " - " + QuoteApprovalMsg.QTE_MARGIN_LOW : "";
                        setQuoteApprovalMesasge(ul_msg);
                        if (ul_msg.length > 0) {
                            setCurrAccessLevel(retMargin.data[0].qlApprover);
                        }

                        if (typeof quoteDetails[id].savedQuote !== 'undefined') {
                            if (typeof quoteDetails[id].savedQuote.approvalStatusId !== 'undefined' && quoteDetails[id].savedQuote.approvalStatusId !== null) /// it means it is draft state
                            {
                                const appStatus = approvalStatList[quoteDetails[id].savedQuote.approvalStatusId].approvalStatus;
                                setQuoteApprovalStatus(appStatus);
                                setQuoteApprovalStatId(quoteDetails[id].savedQuote.approvalStatusId);
                                localApprovalState.ApprovalStatus = appStatus;
                                localApprovalState.ApprovalStatusId = quoteDetails[id].savedQuote.approvalStatusId;
                            }
                            else {
                                const appStatus = approvalStatList[1].approvalStatus;
                                setQuoteApprovalStatus(appStatus);
                                setQuoteApprovalStatId(1);
                                localApprovalState.ApprovalStatus = appStatus;
                                localApprovalState.ApprovalStatusId = 1;
                            }
                        }
                        else {
                            const appStatus = approvalStatList[1].approvalStatus;
                            setQuoteApprovalStatus(appStatus);
                            setQuoteApprovalStatId(1);
                            localApprovalState.ApprovalStatus = appStatus;
                            localApprovalState.ApprovalStatusId = 1;
                        }

                    }
                } else {
                    if (itemList.length > 0 && repDisc > 0)
                    {
                        checkRepDiscLimit(itemList.filter((item) => item.repDisc > 0));
                    }
                }
            }
            else {
                if (itemList.length > 0)
                {
                    checkRepDiscLimit(itemList.filter((item) => item.repDisc > 0));
                }
            }
        }

        if (typeof quoteDetails[id].savedQuote?.approvalAudit !== 'undefined' && quoteDetails[id].savedQuote.approvalAudit !== null) {
            if (quoteDetails[id].savedQuote?.approvalAudit === null) return;
            else {
                try {
                    if (checkApprovalStateFor.length === 0) {
                        setCurrentApprover(quoteDetails[id]?.savedQuote.approvalAudit.approvedUserID);
                        setQuoteOwner(quoteDetails[id]?.savedQuote.owner ? quoteDetails[id]?.savedQuote.owner : user?.userFullName);
                        // setApproverName(quoteDetails[id]?.savedQuote.approvalAudit.approvedUserID === user?.homeAccountId ? user?.userFullName : "");
                        const idxapp = approvalStatList.findIndex(x => x.approvalStatusID === quoteDetails[id].savedQuote.approvalStatusId);
                        const appStatus = idxapp > -1 ? approvalStatList[idxapp].approvalStatus : approvalStatList[0].approvalStatus;
                        setQuoteApprovalStatus(appStatus);
                        setQuoteApprovalStatId(quoteDetails[id]?.savedQuote.approvalStatusId);
                        localApprovalState.ApprovalStatus = appStatus;
                        localApprovalState.ApprovalStatusId = quoteDetails[id].savedQuote.approvalStatusId;
                    }
                } catch (error) {
                    console.log("Error loading QuoteID =" + id + "\n" + "Error msg: " + error);
                    // run approval state checking again because of delayed checking of amtlimit or margin
                    // checkApprovalStatusState()
                }


            }

        }
        //#region - added to override the status of existing quote when triggering save as new, save as revision and save as suffix to create or replicate the existing quote
        if (checkApprovalStateFor === "SaveAsNew") setTriggerSaveAsNewFlag(1);
        else if (checkApprovalStateFor === "SaveAsRevision") setTriggerRevision(1);
        else if (checkApprovalStateFor === "SaveAsSuffix") setTriggerSuffix(1);

        if (checkApprovalStateFor.length > 0) {
            if (localApprovalState.ApprovalStatusId !== null) {
                if (localApprovalState.ApprovalStatusId >= 2) {
                    setQuoteApprovalStatus("Approval Required");
                    setQuoteApprovalStatId(1);
                } else {
                    setQuoteApprovalStatus(localApprovalState.ApprovalStatus);
                    setQuoteApprovalStatId(localApprovalState.ApprovalStatusId);
                }
                
            }
            setCheckApprovalStateFor("");
        }
        //#endregion
    }

    const checkApprovalStatusOnChange = () => {
        // resetQteApprovalForm();
        if (itemList.length === 0) return;
        let appStatus = "";
        checkAprvlAmtLimit();
    }

    useEffect(() => {
        checkAprvlMargin();
    }, [checkedAmtLimit]);

    useEffect(() => {
        processedApprovalStatus();
    }, [checkedAprvlMargin]);

    useEffect(() => {
        if (quoteApprovalStatId !== 0) {
            sessionStorage.setItem('approvalStatusId', quoteApprovalStatId);
        } else {
            sessionStorage.setItem('approvalStatusId', '0'); // Store as string for consistency
        }
    }, [quoteApprovalStatId]);


    // // Handle click outside to close the dropdown
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
                var clickVal = event.button;
                var gridView = qteCurGridViewType;
                if(clickVal !== 2 && gridView === "Normal"){
                    
                    dropdownRef.current.classList.add('hidden');
                    dropdownRef.style.display = 'none';
                }
                //dropdownRef.current.classList.add('hidden');
                //dropdownRef.style.display = 'none'; // Hide the dropdown
            }
           
        };
    
        // Add event listener for clicks
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);



    const setApprovalForRepDiscount = () => {
        if (Object.keys(itemList).length === 0) return;
        setQuoteApprovalMesasge(QuoteApprovalMsg.ITEM_REPDISC_HIGH)
        setQuoteApprovalStatus("Approval Required");
        if (quoteDetails[id].savedQuote !== 'undefined') {
            if (typeof quoteDetails[id].savedQuote.approvalStatusId === "undefined") setQuoteApprovalStatId(1);
            else if (quoteDetails[id].savedQuote.approvalStatusId === null) setQuoteApprovalStatId(1);
            else setQuoteApprovalStatId(quoteDetails[id].savedQuote.approvalStatusId);
        } else {
            setQuoteApprovalStatId(1);
        }
        setCurrAccessLevel(2)

    }


    const isSelectedItemValidateOnMoveUp = (selectedItems) => {
        if (selectedItems.length === 0) return true;
        if (selectedItems.length > 0) {
            if (selectedItems.some((item) => item?.rowType === "A")) return false;
            if (selectedItems.some((item) => item?.rowType === "AI")) return true;

        }

    }


    const isSelectedItemValidateOnMoveDown = (selectedItems) => {
        if (selectedItems.length === 0) return true;
        if (selectedItems.length > 0) {

            try {
                const selectedRowIndex = itemList.findIndex(row => row.rowId === selectedItems.at(selectedItems.length - 1).rowId);

                if (selectedItems[0].rowType === '' || selectedItems[0].rowType === 'AI' || selectedItems[0].position === itemList.length) return true;

                if (selectedItems.some((item) => item.rowType === "A")) return false;

                if (selectedItems.some((item) => item.rowType === "AI")) return true;
                return false;
            } catch (error) {
                console.log("error disabling MoveDownItem", error);
            }
            

        }

    }


    const scrollOptions = [
        { text: "100%", id: "100" },{ text: "150%", id: "150" }, { text: "200%", id: "200" }, { text: "300%", id: "300" }
    ]

    const customFieldWidth = [
        {
            100: { 'stStockCode': '100px', 'description': '100px', 'qty': '60px' }
            , 150: { 'stStockCode': '180px', 'description': '180px' }
            , 200: { 'stStockCode': '240px', 'description': '480px' }
            , 300: { 'stStockCode': '260px', 'description': '500px' }
        }
    ]

    return (
        <>
            {/* This is for the Progress Bar and its Percentage. Please do not change it  */}
            {/* {openShowProgress && (
            <div className="importbar-progress-wrap">
                <div className="bar-txt">Importing In Progress, this may take a few seconds/minutes... </div>
                <ProgressBar  
                    completed={progress.progress}
                    bgColor="#1a3a7b"
                    baseBgColor="#ddd"
                    height="18px"
                    width="100%"
                    borderRadius="4px"
                    labelClassName="bar-progress-label"
                    label={`${progress.progress}%`} 
                />
            </div>)}  */}

            <ExcelExport
                data={ogItem}
                ref={excelExportRef}
                fileName="ItemList.xlsx"
                headerPaddingCellOptions={{ rowHeight: 0 }}
                style={{ display: "none" }}>
                {[...columnsMapping, ...excelview].map((field) => {
                    const column = excelview.find((col) => col.field === field);
                    if (column && column.show) { 
                        return (
                            <ExcelExportColumn
                                key={field}
                                title={column.title}
                                field={field}
                                cell={(props) => {
                                    if (qteCurGridViewType === "Normal" && qteAuthType === "General") {
                                        if (column.field === "stEecostRound") {
                                            return "****";
                                        }
                                    }
                                    return props.dataItem[column.field];
                                }}
                            />
                        );
                    }
                    return null;
                })}
            </ExcelExport>

            <ExcelExport
                data={ogItems()}
                ref={excelExportPartConsolidateRef}
                fileName="ItemList.xlsx"
                headerPaddingCellOptions={{ rowHeight: 0 }}
                style={{ display: "none" }}>
                {visibleColumns.map((column) => (
                    <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
                ))}
            </ExcelExport>

            {/* {consolidateGrid === false ? (
                <ExcelExport
                    data={ogItem}
                    ref={excelExportRef}
                    fileName="ItemList.xlsx"
                    headerPaddingCellOptions={{ rowHeight: 0 }}
                    style={{ display: "none" }}>
                    {columnsMapping.map((field) => {
                        const column = excelview.find((col) => col.field === field);
                        if (column && column.show) {
                            return <ExcelExportColumn key={field} title={column.title} field={field} />;
                        }
                        return null;
                    })}
                </ExcelExport>
            ) : (
                <ExcelExport
                    data={ogItems}
                    ref={excelExportPartConsolidateRef}
                    fileName="ItemList.xlsx"
                    headerPaddingCellOptions={{ rowHeight: 0 }}
                    style={{ display: "none" }}>
                    {visibleColumns.map((column) => (
                        <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
                    ))}
                </ExcelExport>
            )} */}
            {/* <Badge color="yellow" marginRight={8}>
                {getGroupLevelStatus()}
            </Badge> */}

            {isLoading && <Spinner />}

            {(
                <><GroupDataGridView
                    // data={paginate(itemList ? dataWithEmptyRow() : [], itemspageNumber, itemspageSize)}
                    data={itemList.length === 0 ? dataWithEmptyRow() : itemList}
                    columns={columns.filter((column) => selectedColumns.includes(column.field))}
                    onAddGroup={onAddGroup}
                    handleItemChange={handleItemChange}
                    onAddItem={onAddItem}
                    showSelectionColumn={true}
                    excelExportRef={excelExportRef}
                    idField="rowId"
                    resizable={true}
                    isHeaderCheckboxRender={true}
                    isSubGroupEnabled={isSubGroupEnabled}
                    isInsertGroupEnabled={isInsertGroupEnabled}
                    isInsertSubGroupEnabled={isInsertSubGroupEnabled}
                    isInsertItems={isInsertItems}
                    isAddDbExtra={isAddDbExtra}
                    isInsertDbExtra={isInsertDbExtra}
                    handleAddGroup={handleAddGroup}
                    onAdd={onAdd}
                    isEditLabourHoursEnabled={isEditLabourHoursEnabled}
                    isInsertEditLabourHoursEnabled={isInsertEditLabourHoursEnabled}
                    onActionLabour={onActionLabour}
                    onClickBOM={onClickBOM}
                    isExportedItems={isExportedItems}
                    isExportedConsolidateItems={isExportedConsolidateItems}
                    onActionItems={onActionItems}
                    selectedView={selectedView}
                    selectedItems={selectedItems}
                    isCollapseAll={isCollapseAll}
                    isExpandCollapseTriggered={isExpandCollapseTriggered}
                    toolbarButtons={
                        [
                            {
                                label: (
                                    <strong title="Add Group or use the arrow to select more grouping options">
                                        Add Group
                                    </strong>
                                ),
                                onClick: handleAddGroup,
                                onButtonClick: addGroup,
                            },
                            {
                                label: (
                                    <strong title="Add Item or use the arrow to select more Item options">
                                        Add Item
                                    </strong>
                                ),
                                onClick: onAdd,
                                onButtonClick: addItem,
                            },
                            {
                                label: (
                                    <strong
                                        title="Add library unit that you have selected"
                                    >
                                        Add Unit
                                    </strong>
                                ),
                                onClick: handleAddUnits,
                                //disabled : !unitSelected 

                            },


                            {
                                label: (
                                    <strong title="Click on BOM">
                                        {/* //style={{ opacity: bomPart ? 1 : 0.5 }}> */}
                                        BOM
                                    </strong>
                                ),
                                onClick: onClickBOM,
                                onButtonClick: onClickBOM,

                                // Disable the entire button when bomPart is false
                                disabled: selectedItems === undefined || selectedItems === null ? true : !(selectedItems && selectedItems.length > 0 && (selectedItems[0]?.rowType === 'M' || selectedItems[0]?.rowType === 'K')),

                            },
                            {
                                label: (
                                    <strong
                                        title="Click on paste"
                                    //   style={{ opacity: visiblePasteOption ? 1 : 0.5 }}
                                    >
                                        Paste
                                    </strong>
                                ),
                                onClick: unitCopy ? handlePasteUnits : isPriceListCopyed ? handlePasteUnits : handlePasteValidation //handlePaste   //copyDataFromOtherSrc ?handlePasteFromClipboard  :  handlePaste,
                                //allowCopy ? handlePaste :visiblePasteOption? handleAddComponentParts : null
                                //  onButtonClick: handleAddComponentParts,
                                //   disabled: !visiblePasteOption,
                            },
                            {
                                label: <strong title="Click on copy">Copy</strong>,
                                onClick: handleCopy,
                                disabled: selectedItems <= 0,
                            },
                            //   {
                            //     label: <strong title="Click on paste">
                            //       Paste</strong>,
                            //     onClick: handlePaste,
                            //    // disabled: selectedItems <= 0,

                            //   },
                            {
                                label: <strong>Remove Items</strong>,
                                onClick: () => removeSelectedItems(data),
                                disabled: selectedItems <= 0,
                            },
                            {
                                label: (
                                    <strong title="Inserts New Row (adds a new row to the top of your selected row">
                                        Insert Row
                                    </strong>
                                ),
                                onClick: Addemptyrowbyselectedrow,
                                disabled: checkedItems[id]?.length > 0 ? true : selectedItems?.length <= 0,
                            },
                            {
                                label: <strong title="UnGroup - UnGroups the selected items">Ungroup</strong>,
                                onClick: ungroup,
                                disabled: disableUngroup(),
                                //   disabled: selectedItems.length > 1 || (!groupId && !subGroupId), // Disable Ungroup button if both groupId and subGroupId are empty
                            },
                            {
                                label: <strong title="Group - Groups the selected items">Group</strong>,
                                onClick: group,
                                disabled: disableGroup(),
                                //   disabled: selectedItems.length > 1 || (!groupId && !subGroupId), // Disable Group button if both groupId and subGroupId have values
                            },

                            {
                                label: <strong title="Labour Hours">Add Labour Hours</strong>,
                                onClick: onActionLabour,
                                onButtonClick: onAddLabour,
                            },
                            {
                                label: (
                                    <strong title="Insert Line Notes (adds to the top of your selected row)">
                                        Notes
                                    </strong>
                                ),
                                onClick: onInsertNotes,
                                //disabled: selectedItems == 0 || disableNote,
                            },
                            {
                                label: <i title="Move Item Up (moves selected row item up)" className="ri-arrow-up-fill"></i>,
                                onClick: () => {
                                    // const selectedItemIndex = itemList.findIndex(
                                    //     (item) => item.rowId === selectedItems[0]?.rowId
                                    // ); commented by sachini 29/03/2024
                                    const selectedItemIndex = itemList.findIndex(
                                        (item) => item.rowId === (selectedItems && selectedItems.length > 0 ? selectedItems[0]?.rowId : null)
                                    );
                                    // assuming selectedItems is an array of selected rows and rowId is the identifier
                                    if (selectedItemIndex != null && selectedItemIndex !== -1) {
                                        const retVal = moveItemUp(selectedItemIndex);
                                        if (retVal) {
                                            let currRowIdx = Number(sessionStorage.getItem("currRowIndex"))||0;
                                            currRowIdx = currRowIdx === 0 ? currRowIdx : (currRowIdx-1);
                                            sessionStorage.setItem("currRowIndex",currRowIdx);
                                        }
                                    } else {
                                        toast.error("Please select a row to move up", {
                                            position: "top-center",
                                            transition: Slide,
                                        }); // show error if no row is selected
                                    }
                                },

                                disabled: isSelectedItemValidateOnMoveUp(selectedItems) || isItemAtTop || selectedItems[0].rowType === '' || selectedItems[0].rowType === 'AI' || selectedItems[0].position === 1, // Disable button if multiple items are selected

                                //   disabled: selectedItems.length !== 1 ? selectedItems !== undefined && selectedItems[0].rowType === 'A' ? false : true : true  || isItemAtTop || selectedItems[0].rowType === '' || selectedItems[0].rowType === 'AI' || selectedItems[0].position === 1, // Disable button if multiple items are selected

                                // disabled: selectedItems.length !== 1 || isItemAtTop || selectedItems[0].rowType === '' || selectedItems[0].rowType === 'AI' , // Disable button if multiple items are selected
                            },
                            {
                                label: <i title="Move Item Down (moves selected row item down)" className="ri-arrow-down-fill"></i>,
                                onClick: () => {
                                    // const selectedItemIndex = itemList.findIndex(
                                    //     (item) => item.rowId === selectedItems[0].rowId
                                    // ); // same assumption as above
                                    const selectedItemIndex = itemList.findIndex(
                                        (item) => item.rowId === (selectedItems && selectedItems.length > 0 ? selectedItems[0]?.rowId : null)
                                    );
                                    if (selectedItemIndex != null && selectedItemIndex !== -1) {
                                        const retVal = moveItemDown(selectedItemIndex);
                                        if (retVal) {
                                            let currRowIdx =Number(sessionStorage.getItem("currRowIndex"))||0;
                                            currRowIdx = currRowIdx === 0 ? currRowIdx : (currRowIdx+1);
                                            sessionStorage.setItem("currRowIndex",currRowIdx);
                                        }
                                    } else {
                                        toast.error("Please select a row to move down", {
                                            position: "top-center",
                                            transition: Slide,
                                        }); // show error if no row is selected
                                    }
                                },

                                //disabled: selectedItems.length !== 1 || isItemAtBottom || selectedItems[0].rowType === '' || selectedItems[0].rowType === 'AI', // Disable button if multiple items are selected
                                disabled: isSelectedItemValidateOnMoveDown(selectedItems), // Disable button if multiple items are selected

                            },
                            {
                                label: (
                                    <div className="ip-item-view-btn">
                                        <span>View:</span>
                                        <DropDownList
                                            id="btnViewOptions"
                                            data={viewOptions}
                                            textField="text"
                                            dataItemKey="id"
                                            value={selectedView}
                                            onChange={handleChangeView}
                                            ref={changedView}
                                        />
                                    </div>
                                ),
                            },
                            {
                                label: (
                                    <div data-tooltip-id="hscroll-tooltip" data-tooltip-content="Choose your preferred horizontal scroll %" data-tooltip-place="right" className="ip-item-view-btn grid-hscroller">
                                        <span></span>
                                        <Tooltip id="hscroll-tooltip" />
                                        <DropDownList
                                            id="btnScrollViewOptions"
                                            data={scrollOptions}//{scrollOptions.filter((item, index) => index !== 0)}
                                            textField="text"
                                            dataItemKey="id"
                                            //defaultItem={{ text: sessionStorage.getItem('scrollValText') == null ? "100%" : sessionStorage.getItem('scrollValText') ,
                                               // id: sessionStorage.getItem('scrollValId') == null ? 100 : sessionStorage.getItem('scrollValId') }}
                                             value = {{ text: sessionStorage.getItem('scrollValText') == null ? "100%" : sessionStorage.getItem('scrollValText') ,
                                                id: sessionStorage.getItem('scrollValId') == null ? 100 : sessionStorage.getItem('scrollValId') }}
                                            onChange={showHideScroll}
                                            ref={changedScrollView}
                                        />
                                    </div>
                                )
                                //disabled: selectedItems == 0 || disableNote,
                            },
                            {
                                label: <strong title="More Options, Export, Import etc...">More...</strong>,
                                onClick: onActionItems,
                                onButtonClick: exportItems,
                            },

                        ]
                    }
                    selectedRows={selectedItems}
                    setSelectedRows={setSelectedItems}
                    total={totalPartLines}
                    onPageChange={handlePageChange}
                    tableName={"Items"}
                    isGroupable={true}
                    itemAssemblyList={itemAssemblyList}
                    labourCostName={labourCostName}
                    currentMargin={currentMargin}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    loadItem={loadItem}
                    setLoadItem={setLoadItem}
                    handleLabourChange={handleLabourChange}
                    insertLineNotes={insertLineNotes}
                    setInsertLineNotes={setInsertLineNotes}
                    handleLineNoteChanges={handleLineNoteChanges}
                    allowCopy={allowCopy}
                    scrollValue={scrollValue}
                    customFieldWidth={customFieldWidth}
                    setSelectedView={setSelectedView}
                    viewOptions={viewOptions}
                    handleItemGrid={handleItemGrid}
                    setDropdownValue={setDropdownValue}
                    setIsQuoteSaved={setIsQuoteSaved}
                />
                    {/* <QuotesItemGrid data={data} columns={columns.filter((column) => selectedColumns.includes(column.field))} /> */}
                </>
            )}

            {/* <input
                type="file"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileUpload}
            /> */}

            {importDialogVisible && (
                <ImportDialog
                    visible={importDialogVisible}
                    onClose={() => setImportDialogVisible(false)}
                    onImport={handleImport}
                />
            )}

            {dialogVisible && (
                <Dialog width="100%" title={"Parts Lookup"} onClose={closeDialog}>
                    <ItemLookup onAddItems={handleSelectedItems} />

                    <DialogActionsBar>
                        <span hidden={showLoaderBtn}>
                            <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                <Button id="btupLookUAddItems" className="px-4 me-2 ip-button-general" onClick={handleAddParts}>
                                    Add
                                </Button>
                                <Button id="btupLookUAddItemsCancel" className="px-4" onClick={closeDialog}>
                                    Cancel
                                </Button>
                            </div>
                        </span>
                        <span hidden={!showLoaderBtn}>
                            <div className="d-flex justify-content-end ip-miniloader-btnstatic">
                                <Loader className="mini-loader-footer" size="medium" type={loaderType} />
                            </div>
                        </span>
                    </DialogActionsBar>
                </Dialog>
            )}


{showImportLogDialog && (
        <ImportLogDialog
            visible={showImportLogDialog}
            logDetails={importLog}
            onClose={() => setShowImportLogDialog(false)}
        />
    )}

            

            {labourHoursForm && (
                <Dialog
                    autoFocus={true}
                    className="ipd-dialog-container"
                    title={"Assembly Hours"}
                    onClose={() => setLabourFormVisible(false)}>
                    {/* <LabourDataGrid setLabourFormVisible={setLabourFormVisible} labourHoursForm={labourHoursForm} /> */}
                    <LabourDataGrid
                        setLabourFormVisible={setLabourFormVisible}
                        labourHoursForm={labourHoursForm}
                        itemList={itemList}
                        itemAssemblyList={itemAssemblyList}
                        setItemAssemblyList={setItemAssemblyList}
                        labourCostName={labourCostName}
                        setLabourCostName={setLabourCostName}
                        currentMargin={currentMargin}
                        setCurrentMargin={setCurrentMargin}
                        currtotalCost={currtotalCost}
                        currtotalPrice={currTotalPrice}
                        setCurrtotalCost={setCurrtotalCost}
                        setCurrTotalPrice={setCurrTotalPrice}
                        setEditLabourItems={setEditLabourItems}
                        editLabourItems={editLabourItems}
                        loadItem={loadItem}
                        setLoadItem={setLoadItem}
                        handleTotalTimeApprx={handleTotalTimeApprx}
                        totalTimeApprx={totalTimeApprx}
                        setCurrNetEA={setCurrNetEA}
                        currNetEA={currNetEA}
                        setFutCost={setFutCost}
                        futCost={futCost}
                    />
                </Dialog>
            )}

            {componentPartsWhseDialogVisibleSub && (
                <Dialog
                    className="ip-dialog-whse"
                    width="100%"
                    height="70%"
                    title={"Component Parts Availability by Warehouse"}
                    onClose={toggleComponentPartsWhseSub}>
                    <ComponentPartsWhseSOHSub />
                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            {compstatus === "M" || compstatus === "K" ? (
                                <>
                                    <Button
                                        className="px-4 me-2"
                                        onClick={toggleComponentPartsWhseSubB}
                                        disabled={componentPartsWhseDialogVisibleSubB}>
                                        Show Components
                                    </Button>
                                    {/* {componentPartsWhseDialogVisibleSub && <ComponentPartsWhseSOHSub />} */}
                                </>
                            ) : (
                                <Button
                                    //  className="px-4 me-2"
                                    disabled={!isFormModified}
                                    className={
                                        isFormModified ? "px-4 me-2 ip-button-general" : "disabled-button, px-4 me-2"
                                    }
                                    onClick={toggleComponentPartsWhseSubB}>
                                    Show Components
                                </Button>
                            )}

                            <Button className="px-4 me-2 ip-button-general" onClick={toggleComponentPartsWhseSub}>
                                Close
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

            {componentPartsWhseDialogVisibleSubB && (
                <Dialog
                    className="ip-dialog-whse"
                    width="60%"
                    title={"Component Parts Availability by Warehouse"}
                    onClose={toggleComponentPartsWhseSubB}>
                    <ComponentPartsWhseSubSOH />
                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button
                                className="px-4 me-2"
                                // onClick={toggleComponentPartsWhseSubB}
                                disabled={componentPartsWhseDialogVisibleSub}>
                                Show Components
                            </Button>
                            <Button className="px-4 me-2 ip-button-general" onClick={toggleComponentPartsWhseSubB}>
                                Close
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}
            {/* <button onClick={()=>setQteApprovalActionForm(true)}> approval</button>  */}
            {showFormForApproval && (
                <Dialog
                    style={{
                        Width: 100,
                    }}
                    className="ipd-dialog-container-approval"
                    title={qteApprovalFormtitle}
                    onClose={() => setShowFormForApproval(false)}
                >
                    <QteUserApprovalAction
                        id={id}
                        qteApprovalHeaderMsg={qteApprovalHeaderMsg}
                        qteApprovalFormType={qteApprovalFormType}
                    />
                </Dialog>
            )}


        </>
    );
};

export default ItemsMainDataGrid;
