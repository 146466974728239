import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import DataGridView from "../Layouts/DataGridView";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { Row, Accordion, AccordionItem, Collapse } from "reactstrap";
import classnames from "classnames"; //for accordion classnames
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { toast, Slide } from "react-toastify";
import ItemLookup from "../Qoutes/ItemLookup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { CustomerCustPriceContext } from "../../Contexts/CustomerCustPriceContext";
import CustomerIdContext from "../../Contexts/CustomerIdContext";
import Spinner from "../Common/Spinner";
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import { guid } from "@progress/kendo-react-common";
import { isNumber, set } from "lodash";
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { useParams } from "react-router-dom";
import { convertToModel, GetSelectedPriceListCustomerModel } from "../../models/Quotations";
import PLCustomerLookup from "../PriceLists/PLCustomerLookup";
//// import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import { usePriceListDetails } from "../../Contexts/PriceListDetailsProvider";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import { useNavigate } from "react-router-dom";

const NewPriceListItemDataGrid = ({ triggerPriceListSaving }) => {
    //#region variables
    const user = useContext(UserContext);
    const userId = user?.homeAccountId;
    const [selectedOption, setSelectedOption] = useState({ id: 1, text: "Import items" });
    const [col1, setcol1] = useState(true);
    const [col2, setcol2] = useState(true);
    const changedOptionRef = useRef(null);
    const [libraryTypes, setLibraryTypes] = useState([]);
    const [libraryListing, setLibraryListing] = useState([]);
    const [itemLookUpVisible, setItemLookUpVisible] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [itemList, setItemList] = useState([]);
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(50);
    const customerIdContext = useContext(CustomerIdContext);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const { customerId } = useContext(CustomerIdContext);
    const nonGroupedId = useRef();
    const [selectedRows, setSelectedRows] = useState([]);
    const [triggerNewItem, setTriggerNewItem] = useState(false);
    const [itemCount, setItemCount] = useState(0);
    let newItem = [];
    const [isLoading, setIsLoading] = useState(false);
    const [isCustLoading, setIsCustLoading] = useState(false);
    // const [unitName, setUnitName] = useState("");
    const [priceListName, setPriceListName] = useState("");
    const [optType, setOptType] = useState([]);
    const [optTypeId, setOptTypeId] = useState(-1);
    const [skipPriceLists, setSkipPriceLists] = useState(0);
    const [takePriceLists, setTakePriceLists] = useState(50);
    const [skipCustomerPriceLists, setCustomerSkipPriceLists] = useState(0);
    const [takeCustomerPriceLists, setCustomerTakePriceLists] = useState(50);
    const [debtorType, setDebtorType] = useState("");
    const [priceCategory, setPriceCategory] = useState("");
    const [isProcessListTypeChangeExcuted, setIsProcessListTypeChangeExcuted] = useState(false);

    //let itemList = [];
    //#endregion
    const { getPriceListColMap, getPriceListViewOpt, getAccountRole } = useAuthorizationContext();
    let [viewOptions, setviewOptions] = useState([]);
    const [selectedView, setSelectedView] = useState(0);
    const changedView = useRef();
    let viewType = [];
    let viewText = "";
    let index = 0;
    let text = "";
    // let columnsMapping = ["stStockCode"];
    let columnsMapping = ["prodGroup"];
    const [customerData, setCustomerData] = useState({});
    const excelExportRef = React.useRef(null);
    const { id } = useParams();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(50);
    const [lastValidCustomerNumber, setLastValidCustomerNumber] = useState("");
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState([]);
    const [pListTypeTypes, setPListTypeTypes] = useState([]);
    const [optPListTypeTypesId, setOptPListTypeTypesId] = useState('af37b668-6774-475c-a9af-a7ab5eba5ccb');
    const [optPListTypeTypesText, setOptPListTypeTypesText] = useState("");
    const navigate = useNavigate();
    const [pListTypeListing, setPListTypeListing] = useState([]);
    const { addCustomerCustPrice, setCustomerCustPrice } = useContext(CustomerCustPriceContext);

    //manoj - quote details
    // // const { quoteDetails, updateQuoteDetailsForId } = useQuoteDetails();
    const { priceListDetails, updatePriceListDetailsForId, setIsPriceListCopyed, isPriceListCopyed, copiedPriceList, setCopiedPriceList, isPriceListItemSelected } = usePriceListDetails();
    const { setUnitCopy } = useUniteDetails();

    const [selectedListType, setSelectedListType] = useState("Customer Price (Level 60)");
    const [selectedListTypeState, setSelectedListTypeState] = React.useState({
        value: {
            text: "",
            id: null,
        },
    });
    const [selectedTypeId, setSelectedTypeId] = useState("");
    // setSelectedTypeId
    const [selectedCustomer, setSelectedCustomer] = useState("");
    const [isLoadOnIdChange, setIsLoadOnIdChange] = useState(false);
    const [newList, setNewList] = useState([]);
    const [listTypeChange, setListTypeChange] = useState(false);
    const [dropdownValue, setDropdownValue] = useState({});
    const [csvData, setCsvData] = useState([]);

    //let selectedColumns = [];
    //#region Const

    // const columns = useMemo(
    //     () => 
    const [columns, setColumns] = useState([

        { field: "prodGroup", title: "PGroup", show: true, editable: false },
        { field: "stStockCode", title: "Part No", show: true, editable: false },
        { field: "description", title: "Description", show: true, editable: false },
        { field: "type", title: "Type", show: true, editable: false },
        { field: "dcCode", title: "Dc Code", show: true, editable: false },
        {
            field: "dcRate", title: "Dc Rate(%)", show: true, editable: false, cell: (props) => {
                const value = props.dataItem.dcRate;
                const formattedValue = value != null ? value.toFixed(1) : "";

                return (
                    <td className="text-right">
                        {formattedValue}
                    </td>
                );
            }
        },
        { field: "listPrice", title: "List Price", show: true, editable: false },
        { field: "l60", title: "L60 Price", show: true, editable: false },
        { field: "l50", title: "L50 Price", show: true, editable: false },
        { field: "l55", title: "L35 Price", show: true, editable: false },
        { field: "l20", title: "L20 Price", show: true, editable: false },
        { field: "costEA", title: "CostEA", show: true, editable: false },
        { field: "dconLp", title: "Dc on LP(%)", show: true, editable: false },
        { field: "qtyB1", title: "Qty1 >=", show: true, editable: false },
        { field: "nettEA", title: "NettEA 1", show: true, editable: false },
        { field: "qtyB2", title: "Qty2 >=", show: true, editable: false },
        { field: "priceB2", title: "NettEA 2", show: true, editable: false },
        { field: "qtyB3", title: "Qty3 >=", show: true, editable: false },
        { field: "priceB3", title: "NettEA 3", show: true, editable: false },
        { field: "margin", title: "Margin1", show: true, editable: false },
        { field: "stFutureCost", title: "Fut. Cost", show: true, editable: false },
        { field: "lastCost", title: "Last Cost", show: true, editable: false },

    ]
        // ,[]
    );

    const columnsNormalView = useMemo(
        () => [

            { field: "prodGroup", title: "PGroup", show: true, editable: false },
            { field: "stStockCode", title: "Part No", show: true, editable: true },
            { field: "description", title: "Description", show: true, editable: false },
            { field: "type", title: "Type", show: true, editable: false },
            { field: "dcCode", title: "DcCode", show: true, editable: false },
            {
                field: "dcRate", title: "DcRate", show: true, editable: false, cell: (props) => {
                    const value = props.dataItem.dcRate;
                    const formattedValue = value != null ? value.toFixed(1) : "";

                    return (
                        <td className="text-right">
                            {formattedValue}
                        </td>
                    );
                }
            },
            { field: "listPrice", title: "List Price", show: true, editable: false },
            { field: "l60", title: "L60 Price", show: true, editable: false },
            { field: "l50", title: "L50 Price", show: true, editable: false },
            { field: "l35", title: "L35 Price", show: true, editable: false },
            { field: "l20", title: "L20 (PV)", show: true, editable: false },
            { field: "costEA", title: "costEA", show: true, editable: false },
            { field: "dconLp", title: "Dc on LP", show: true, editable: false },
            // { field: "qtyB1", title: "Qty1 >=", show: true, editable: false },
            { field: "nettEA", title: "nettEA 1", show: true, editable: false },
            // { field: "qtyB2", title: "Qty2 >=", show: true, editable: false },
            // { field: "priceB2", title: "nettEA 2", show: true, editable: false },
            // { field: "qtyB3", title: "Qty3 >=", show: true, editable: false },
            // { field: "priceB3", title: "nettEA 3", show: true, editable: false },
            // { field: "margin", title: "margin1", show: true, editable: false },
            // { field: "stFutureCost", title: "Fut. Cost", show: true, editable: false },
            // { field: "lastCost", title: "Last Cost", show: true, editable: false },

        ],
        []
    );

    const columnsManagementView = useMemo(
        () => [

            { field: "prodGroup", title: "PGroup", show: true, editable: false },
            { field: "stStockCode", title: "Part No", show: true, editable: true },
            { field: "description", title: "Description", show: true, editable: false },
            // { field: "type", title: "Type", show: true, editable: false },
            // { field: "dcCode", title: "DcCode", show: true, editable: false },
            // {
            //     field: "dcRate", title: "DcRate", show: true, editable: false, cell: (props) => {
            //         const value = props.dataItem.dcRate;
            //         const formattedValue = value != null ? value.toFixed(1) : "";

            //         return (
            //             <td className="text-right">
            //                 {formattedValue}
            //             </td>
            //         );
            //     }
            // },
            { field: "listPrice", title: "List Price", show: true, editable: false },
            { field: "l60", title: "L60 Price", show: true, editable: false },
            { field: "l50", title: "L50 Price", show: true, editable: false },
            { field: "l35", title: "L35 Price", show: true, editable: false },
            { field: "l20", title: "L20 (PV)", show: true, editable: false },
            { field: "costEA", title: "costEA", show: true, editable: false },
            // { field: "dconLp", title: "Dc on LP", show: true, editable: false },
            // { field: "qtyB1", title: "Qty1 >=", show: true, editable: false },
            { field: "nettEA", title: "nettEA 1", show: true, editable: false },
            // { field: "qtyB2", title: "Qty2 >=", show: true, editable: false },
            // { field: "priceB2", title: "nettEA 2", show: true, editable: false },
            // { field: "qtyB3", title: "Qty3 >=", show: true, editable: false },
            // { field: "priceB3", title: "nettEA 3", show: true, editable: false },
            { field: "margin", title: "margin1", show: true, editable: false },
            { field: "stFutureCost", title: "Fut. Cost", show: true, editable: false },
            { field: "lastCost", title: "Last Cost", show: true, editable: false },

        ],
        []
    );

    const columnsQtyBreaksView = useMemo(
        () => [

            { field: "prodGroup", title: "PGroup", show: true, editable: false },
            { field: "stStockCode", title: "Part No", show: true, editable: false },
            { field: "description", title: "Description", show: true, editable: false },
            // { field: "type", title: "Type", show: true, editable: false },
            // { field: "dcCode", title: "DcCode", show: true, editable: false },
            // {
            //     field: "dcRate", title: "DcRate", show: true, editable: false, cell: (props) => {
            //         const value = props.dataItem.dcRate;
            //         const formattedValue = value != null ? value.toFixed(1) : "";

            //         return (
            //             <td className="text-right">
            //                 {formattedValue}
            //             </td>
            //         );
            //     }
            // },
            // { field: "listPrice", title: "List Price", show: true, editable: false },
            // { field: "l60", title: "L60 Price", show: true, editable: false },
            // { field: "l50", title: "L50 Price", show: true, editable: false },
            // { field: "l35", title: "L35 Price", show: true, editable: false },
            // { field: "l20", title: "L20 (PV)", show: true, editable: false },
            { field: "costEA", title: "costEA", show: true, editable: false },
            // { field: "dconLp", title: "Dc on LP", show: true, editable: false },
            { field: "qtyB1", title: "Qty1 >=", show: true, editable: false },
            { field: "nettEA", title: "nettEA 1", show: true, editable: false },
            { field: "qtyB2", title: "Qty2 >=", show: true, editable: false },
            { field: "priceB2", title: "nettEA 2", show: true, editable: false },
            { field: "qtyB3", title: "Qty3 >=", show: true, editable: false },
            { field: "priceB3", title: "nettEA 3", show: true, editable: false },
            { field: "margin", title: "margin1", show: true, editable: false },
            // { field: "stFutureCost", title: "Fut. Cost", show: true, editable: false },
            // { field: "lastCost", title: "Last Cost", show: true, editable: false },

        ],
        []
    );

    const [selectedColumns, setSelectedColumns] = useState(["prodGroup"]);

    const handleItemGrid = (index, viewType) => {

        const newColumnsMapping = getPriceListColMap(index, viewType);
        setSelectedColumns(newColumnsMapping);


        // // columnsMapping = getPriceListColMap(index, viewType);
        // // console.log("columnsMapping", columnsMapping);


        // // selectedColumns = columnsMapping;
        // // const c1 = columns.filter((column) => selectedColumns.includes(column.field));
        // // console.log("c1", c1);
        // // //selectedColumns = getPriceListColMap(index, viewType);

        // // // const excelColumns = columnsMapping.map((column) => (
        // // //     <ExcelExportColumn key={column.field} title={column.title} field={column.field} />
        // // // ));

        // // // excelExportRef.current.columns = excelColumns;
    };
    const handleChangeView = (event) => {
        handleItemGrid(event.target.index, event.target.value.text);
        setSelectedView(event.target.value);
        setDropdownValue(event.target.value.text);

        //manoj edited for quote details    
        ////updateQuoteDetailsForId(id, { selectedView: event.target.value });
        updatePriceListDetailsForId(id, { selectedView: event.target.value });

    };

    const [custCount, setCustCount] = useState(0);

    useEffect(() => {
        if (newList.length > 0) {
            setItemList(newList);
            setItemCount(newList.length);
        }
    }, [newList]);

    useEffect(() => {

        if (customerData[id]) {

            //manoj edited for quote details
            // updateQuoteDetailsForId(id, { selectedCustomers: customerData[id] });
            updatePriceListDetailsForId(id, {
                selectedCustomers: customerData[id]
            });
            setCustCount(customerData[id]?.length ?? 0);
            if (customerData[id]?.length == 0) {
                setItemList([]);
            }
            customerGridcolumnsUpdate();
        }

    }, [customerData[id]]);

    //#region Price List - Customer grid functions

    const handleEditChange = ({ dataItem, field, value }) => {
        let newData = [...(customerData[id] || [])];

        let item = newData.find((item) => item.customerId === dataItem.customerId);

        item[field] = value;

        const isLastRow = newData.indexOf(item) === newData.length - 1;

        if (isLastRow) {
            setEditingLastRow(false);
        }

        if (newData.length > 0 && newData[0].customerId === dataItem.customerId) {
            newData[0].isPrimary = true;
        }

        setCustomerData((prevCustomerData) => ({
            ...prevCustomerData,

            [id]: newData,
        }));
    };

    // const customerGridColumns = useMemo(
    //     () => [


    //         { field: "customerNumber", title: "Customer No", editable: true, onchange: handleEditChange },
    //     ],
    //     []
    // );

    //#endregion Price List - Customer grid functions


    const options = useMemo(
        () => [
            { id: 1, text: "Import items" },
            { id: 2, text: "Export items (As in the grid)" },
        ],
        []
    );

    const handleChangeOption = (e) => {
        console.log("e.target.value ", e.target.value);
        setSelectedOption(e.target.value);
    };

    const t_col2 = () => {
        setcol2(!col2);
        setcol1(true);
    };

    const handleAdd = () => {
        setItemLookUpVisible(true);
    }

    const closeDialog = () => {
        setItemLookUpVisible(false);
        setAddItems([]);
    };

    const closeCustomerDialog = () => {
        setDialogVisible(false);
        // setAddItems([]);
    };

    const handleSelectedItems = (items) => {
        setSelectedItems(items);

    };

    const validateItem = (itemTocheck) => {
        let itemExist = itemList.filter((e) => {
            if (itemTocheck.some((item) => item.stStockCode === e.stStockCode)) return e;
        }
        )

        return itemExist;
    }

    const handleAddParts = () => {
        const UserId = user?.homeAccountId;
        let systemSettings = null;

        newItem = [];

        setIsLoading(true);
        addItems.map((item) =>
            newItem.push({
                groups: "",
                stStockCode: item.stStockCode,
                description: item.stDesc1 + item.stDesc2 + item.stDesc3,
                type: item.stStatus,
                listPrice: item.spListP,
                qty: 1,
                rowId: uuidv4()
            })
        )
        console.log("newItem", newItem);


        ///validation if exist
        let itemExist = validateItem(newItem);

        let existMsg = "";
        let i = 1;
        if (itemExist.length > 0) {
            existMsg += "Duplicate item(s) detected\n";
            existMsg += "==========================  \n";
            existMsg += "Total Item(s) : " + itemExist.length + " \n";
            itemExist.forEach((item) => {
                existMsg += i.toString() + ". " + item.stStockCode + "\n";
                i += 1;
            })

            toast.error(<pre><b>{existMsg}</b></pre>, { autoClose: 8000, position: "top-center", transition: Slide, });
        }

        newItem = newItem.filter((e) => {
            if (!itemExist.some((item) => item.stStockCode === e.stStockCode)) return e;
        })


        let updatedIItem = [...itemList, ...newItem];
        setItemList(updatedIItem);
        setItemCount(updatedIItem.length);

        closeDialog();
        setIsLoading(false);
    }

    const handleItemChange = async (event) => {
        const { field, dataItem, clearInput } = event;
        const value = event.value;
        let updatedItemList = [];
        const UserId = user?.homeAccountId;
        if (field === "stStockCode") {

            const responses = await getResponse("Stocks/getstockdetailsusingpartno", {
                partno: value,
                UserId: UserId,
            });

            ///validation if exist
            let itemExist = validateItem([{ stStockCode: value }]);
            if (itemExist.length > 0) {
                toast.error("Sorry! The item named '" + value + "' already exist in this view.", { autoClose: 5000, position: "top-center", transition: Slide, });
                event.value = "";
                return;
            }

            const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
            newItem = [];
            if (responses !== null && responses !== undefined) {
                if (dataItem.stStockCode) {
                    if (responses.stStockCode !== null && responses.stStockCode !== undefined) {
                        const itemRow = itemList[itemIndex];
                        let updatedItem = {
                            ...itemRow,
                            groups: "",
                            stStockCode: responses.stStockCode,
                            description: responses.description,
                            type: responses.type,
                            listPrice: parseFloat(responses.listPrice).toFixed(2),
                            qty: 1,
                        }


                        itemList[itemIndex] = updatedItem;
                    }
                } else {
                    if (responses.stStockCode !== null && responses.stStockCode !== undefined) {
                        newItem.push({
                            groups: "",
                            stStockCode: responses.stStockCode,
                            description: responses.description,
                            type: responses.type,
                            listPrice: parseFloat(responses.listPrice).toFixed(2),
                            qty: 1,
                            rowId: uuidv4()
                        })
                        let updatedIItem = [...itemList, ...newItem];
                        setItemList(updatedIItem);
                        setItemCount(itemCount + updatedIItem.length);
                    } else {
                        toast.error("Part may either Obsolete or not exist in the system", {
                            autoClose: 3000,
                            closeOnClick: true,
                            position: "top-center",
                            transition: Slide,
                        });
                    }
                }
            }

        } else if (field === "qty") {
            if (dataItem.stStockCode) {
                const itemIndex = itemList.findIndex((item) => item.rowId === dataItem.rowId);
                const itemRow = itemList[itemIndex];
                let updatedItem = {
                    ...itemRow,
                    qty: parseInt(value),
                }

                itemList[itemIndex] = updatedItem;
            }
        }

    }

    function getPriceListModel() {

    }

    const onChangePriceListName = (e) => {
        setPriceListName(e.target.value);
    }

    // const [listTypeChange, setListTypeChange] = useState(false);

    useEffect(() => {
        setIsLoadOnIdChange(false);

    }, [optPListTypeTypesId]);

    const onChangeType = (e) => {
        setIsCustLoading(true);
        processListTypeChange(e);
    }

    useEffect(() => {
        if (pListTypeListing.length === 0) return;
        processListTypeChange(selectedListTypeState);
    }, [pListTypeListing]);

    useEffect(() => {

        try {
            // setIsLoading(true);
            customerGridcolumnsUpdate();
        } catch (error) {
            console.error("Failed loading grid columns", error);
            // setIsLoading(false);
        } finally {
            // setTimeout(() => {
            //     setIsLoading(false);
            // }, 500);

        }


    }, [selectedListTypeState]);

    useEffect(() => {
        console.log("priceListDetailspriceListDetails :", priceListDetails);

        if (window.performance && priceListDetails?.length === 0) {
            let navigationEntries = performance.getEntriesByType("navigation");
            if (navigationEntries.length > 0) {
                let type = navigationEntries[0].type;
                if (type === 'reload') {
                    navigate("/dashboard");
                } else {
                    console.log('This page was not reloaded');
                }
            }
        }
    }, []);
    const processListTypeChange = (e) => {
        setIsProcessListTypeChangeExcuted(true);
        const value = typeof e.target === "undefined" ? e : e.target.value;
        setListTypeChange(value);

        if (value.length > 0) {
            const pListType = pListTypeListing.filter((itm) => itm.list_Type === value);
            if (pListType) {
                setSelectedListTypeState({
                    value: {
                        text: value,
                        id: pListType[0].id,
                    },

                })
                setOptPListTypeTypesId(pListType[0].id);
                setSelectedTypeId(pListType[0].id);
                setOptPListTypeTypesText(pListType[0].list_Type.toString());
                // manoj edited for quote details
                // updateQuoteDetailsForId(id, { selectedListType: pListType[0].list_Type.toString(), selectedListTypeId: pListType[0].id.toString() });
                updatePriceListDetailsForId(id, { selectedListType: pListType[0].list_Type.toString(), selectedListTypeId: pListType[0].id.toString() });
                console.log("pListType[0].id", pListType[0].list_Type);

            }
        }
        setIsCustLoading(false);


    }


    const handleSavePriceLists = () => {

        let priceLists = [];
        const priceListId = uuidv4();

        // if (unitName.trim().length === 0) {
        if (priceListName.trim().length === 0) {
            toast.error("Price List Name is required.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        if (optTypeId < 0) {
            toast.error("List Type is required.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }

        if (itemList.length == 0) {
            toast.error("No items on the grid.", {
                autoClose: 3000,
                closeOnClick: true,
                position: "top-center",
                transition: Slide,
            });
            return;
        }


        setIsLoading(true);
        itemList.map((item) => {
            //units = [
            priceLists = [
                // ...units,
                ...priceLists,
                {
                    priceListId: priceListId,
                    unItemId: uuidv4(),
                    createdBy: user?.homeAccountId,
                    modifiedBy: user?.homeAccountId,
                    createdOn: new Date(),
                    modifiedOn: new Date(),
                    stStockCode: item.stStockCode,
                    description: item.description,
                    qty: parseInt(item.qty),
                    type: item.type,
                    listPrice: parseFloat(item.listPrice)
                }
            ]

        })

        // const unitHeader = {
        const priceListHeader = {

            priceListId: priceListId,
            priceListName: priceListName,
            createdOn: new Date(),
            modified: new Date(),
            createdBy: user?.homeAccountId,
            modifiedBy: user?.homeAccountId,
            libraryId: optTypeId,

            tblPriceLists: priceLists
        }

        postResponse("NewPriceLists/createnewpricelist", priceListHeader)
            .then((res) => {
                if (res.statusCode) {

                    toast.success("New Price List created successfully", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                } else {
                    toast.error("Failed to create the New Price List.", {
                        position: "top-center",
                        transition: Slide,
                    });
                    return;
                }
            })
            .catch((error) => {
                console.error("Failed to save data:", error);
                toast.error("Connection error. Please try again later.", {
                    position: "top-center",
                    transition: Slide,
                });
                return;
            })
            .finally(() => {
                setIsLoading(false); // Set loading back to false after request completes (either success or error)
            });
    }

    const removeSelectedItems = () => {
        try {
            if (selectedItems.length > 0) {
                if (selectedItems[0].stStockCode == null || selectedItems[0].stStockCode == undefined) {
                    toast.error("Please select atleast one Item!", {
                        autoClose: 3000,
                        closeOnClick: true,
                        position: "top-center",
                        transition: Slide,
                    });
                } else {
                    confirmAlert({
                        title: "Delete Confirmation",
                        message: "Are you sure you want to delete the item(s)?",
                        buttons: [
                            {
                                label: "Yes",
                                onClick: () => {
                                    const filteredItemList = itemList.filter((item) => {
                                        return !selectedItems.some((selectedItem) => selectedItem.rowId === item.rowId);
                                    });

                                    const updatedItemList = filteredItemList;
                                    setItemList(updatedItemList);
                                    setItemCount(updatedItemList.length);
                                },
                            },
                            {
                                label: "No",
                                onClick: () => {
                                    return;
                                },
                            },
                        ],
                    });
                }
            } else {
                toast.error("Please select atleast one item!", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
            }
        } catch (error) {
            console.error("Error : ", error);
        }

    };

    const itemMove = (move) => {
        let currIdx = 0;
        let updatedItemList;
        if (selectedItems.length > 0) {
            if (selectedItems[0].stStockCode == null || selectedItems[0].stStockCode == undefined) {
                toast.error("Please select an item", {
                    autoClose: 3000,
                    closeOnClick: true,
                    position: "top-center",
                    transition: Slide,
                });
            } else {
                currIdx = itemList.findIndex((item) => item.stStockCode === selectedItems[0].stStockCode);
                const filteredItemList = itemList.filter((item) => {
                    return !selectedItems.some((selectedItem) => selectedItem.stStockCode === item.stStockCode);
                });

                let idx = currIdx;

                switch (move) {
                    case "Up":
                        if (idx > 1) {
                            updatedItemList = [...itemList.slice(0, idx - 1), ...selectedItems, ...itemList.slice(idx - 1, idx)];
                            setItemList(updatedItemList);
                        } else {
                            updatedItemList = [...selectedItems, ...itemList.slice(idx - 1, idx), ...itemList.slice(idx + 1)];
                            setItemList(updatedItemList);
                        }

                    case "Down":
                        if (idx < itemList.length - 1 && move === "Down") {
                            idx = idx + 1;
                            updatedItemList = filteredItemList;
                            updatedItemList.splice(idx, 0, ...selectedItems);
                            setItemList(updatedItemList);
                        }
                }
            }
        }


    };

    //commented by manoj
    const paginate = (items, pageNumber, pageSize) => {
        if (!Array.isArray(items) || items.length <= 0) {
            return [];
        } else {
            const startIndex = (pageNumber - 1) * pageSize;
            return items.slice(startIndex, startIndex + pageSize);
        }
    };

    const handlePageChangePriceList = ({ skip, take }) => {
        setSkipPriceLists(skip);
        setTakePriceLists(take);
        setitemsPageNumber(skip / take + 1);
    };

    const handleCustomerPageChangePriceList = ({ skip, take }) => {
        setCustomerSkipPriceLists(skip);
        setCustomerTakePriceLists(take);
        setPageNumber(skip / take + 1);
    };


    // const handlePageChange = ({ skip, take }) => {
    //     setitemsPageNumber(skip / take + 1);
    //     setitemsPageSize(take);
    // };

    useEffect(() => {
        try {
            setIsLoading(true);
            const fetchPriceListTypeData = async () => {
                let PriceListTypes = [];

                try {
                    const data = await getResponse("PriceListType/getallpricelisttype");
                    const pListType = data.map((item) => item.list_Type);
                    setPListTypeTypes(pListType);
                    setPListTypeListing(data);
                    data.map((itm) =>
                        PriceListTypes = [...PriceListTypes, { text: itm.list_Type, id: itm.id }]
                    )
                    //   onChangeType({ target: { value: PriceListTypes } });
                    setOptPListTypeTypesId(PriceListTypes);
                    updatePriceListDetailsForId(id, { selectedListType: pListType[0].list_Type.toString(), selectedListTypeId: PriceListTypes[0].id.toString() });


                    console.log("PriceListTypes", optPListTypeTypesId)
                } catch (error) {
                    console.error("Error:", error);
                }

            };

            fetchPriceListTypeData();

        }
        catch (error) {
            console.error("Error:", error);

        }
        finally {
            setIsLoading(false);
        }

    }, []);

    useEffect(() => {
        console.log("optPListTypeTypesId", optPListTypeTypesId);


    }, [optPListTypeTypesId]);

    const [selectedListTypeOnLoad, setSelectedListTypeOnLoad] = useState([id, text]);

    useEffect(() => {
        setIsLoadOnIdChange(true);

        if (priceListDetails[id]) {

            // selectedListTypeId
            if (priceListDetails[id].selectedListType) {
                setSelectedListType(priceListDetails[id].selectedListType);
            }
            if (priceListDetails[id].selectedListTypeId) {
                setSelectedTypeId(priceListDetails[id].selectedListTypeId);
                setOptPListTypeTypesId(priceListDetails[id].selectedListTypeId);
                setSelectedListTypeOnLoad(priceListDetails[id].selectedListType);

            }
            if (priceListDetails[id].selectedView) {
                setSelectedView(priceListDetails[id].selectedView);
                setDropdownValue(priceListDetails[id].selectedView);
            }

            if (priceListDetails[id].selectedRow) {
                setIsChecked(priceListDetails[id].selectedRow);
            }

            if (priceListDetails[id].selectedCustomers) {
                setCustomerData((prevCustomerData) => ({
                    ...prevCustomerData,
                    [id]: priceListDetails[id].selectedCustomers,
                }));
            }

            if (priceListDetails[id].selectedItems) {
                newItem = [];
                priceListDetails[id].selectedItems.map((item) =>
                    newItem.push({
                        prodGroup: item.prodGroup,
                        stStockCode: item.stStockCode,
                        description: item.description,
                        type: item.type,
                        dcCode: item.dcCode,
                        dcRate: item.dcRate,
                        listPrice: item.listPrice,
                        l60: item.l60,
                        l50: item.l50,
                        l35: item.l35,
                        l20: item.l20,
                        costEA: item.costEA,
                        dconLp: item.dconLp,
                        qtyB1: item.qtyB1,
                        nettEA: item.nettEA,
                        qtyB2: item.qtyB2,
                        priceB2: item.priceB2,
                        qtyB3: item.qtyB3,
                        priceB3: item.priceB3,
                        margin: item.margin,
                        stFutureCost: item.stFutureCost,
                        lastCost: item.lastCost,
                    })
                )

                setNewList(newItem);
            }

            if (priceListDetails[id].selectedCustomer) {
                setSelectedRows(priceListDetails[id].selectedCustomer);
            }


        }

        setIsLoadOnIdChange(false);

    }, [id]);

    // // useEffect(() => {

    // //     if (!isLoadOnIdChange) {
    // //         const fetchData = async () => {
    // //             try {
    // //                 setIsLoading(true);

    // //                 if (selectedRows.length > 0 && typeof optPListTypeTypesId === 'string') {
    // //                     let API_URL = "";

    // //                     if (optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
    // //                         API_URL = "Stocks/getstockdetailsforpricelist";
    // //                     } else if (optPListTypeTypesId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
    // //                         API_URL = "Stocks/getstockdetailsforl50pricelist";
    // //                     }

    // //                     if (API_URL === "") {
    // //                         return;
    // //                     }

    // //                     const responses = await getResponse(API_URL, {
    // //                         custno: selectedRows[0].customerNumber,
    // //                         //currentUserId: user?.homeAccountId,
    // //                     })

    // //                     if (responses.length == 0 || responses == null || responses == undefined) {
    // //                         toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
    // //                         setItemList([]);
    // //                         setItemCount(0);
    // //                     }

    // //                     console.log("responses 1", responses);

    // //                     setItemList(responses);
    // //                     setItemCount(responses.length);
    // //                     // console.log("itemList", itemList);
    // //                     const responsesArray = responses;
    // //                     //manoj edited for quote details
    // //                     // updateQuoteDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });
    // //                     updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });

    // //                 }

    // //                 else if (selectedRows.length > 0 && typeof priceListDetails[id].selectedListTypeId !== "" || typeof priceListDetails[id].selectedListTypeId !== null || typeof priceListDetails[id].selectedListTypeId !== undefined) {
    // //                     let API_URL = "";

    // //                     if (priceListDetails[id].selectedListTypeId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
    // //                         API_URL = "Stocks/getstockdetailsforpricelist";
    // //                     } else if (priceListDetails[id].selectedListTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
    // //                         API_URL = "Stocks/getstockdetailsforl50pricelist";
    // //                     }

    // //                     if (API_URL === "") {
    // //                         return;
    // //                     }

    // //                     const responses = await getResponse(API_URL, {
    // //                         custno: selectedRows[0].customerNumber,
    // //                         //currentUserId: user?.homeAccountId,
    // //                     })

    // //                     if (responses.length == 0 || responses == null || responses == undefined) {
    // //                         toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
    // //                         setItemList([]);
    // //                         setItemCount(0);
    // //                     }

    // //                     console.log("responses 1", responses);

    // //                     setItemList(responses);
    // //                     // console.log("itemList", itemList);
    // //                     const responsesArray = responses;
    // //                     updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });

    // //                 }

    // //             } catch (error) {
    // //                 console.error("Error:", error);
    // //                 toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
    // //                 setItemList([]);    // Clear the grid if there's an error
    // //                 setItemCount(0);
    // //                 //manoj edited for quote details
    // //                 updatePriceListDetailsForId(id, { selectedItems: [], selectedCustomer: selectedRows });
    // //             } finally {
    // //                 setIsLoading(false);
    // //             }
    // //         };

    // //         if (selectedRows.length > 0) {
    // //             if (itemList.length > 0) {
    // //                 confirmAlert({
    // //                     title: "Confirm clear all and reload",
    // //                     message: "This action will clear all the parts and reload parts for selected pricelist item.Do you want to clear and reload?",
    // //                     buttons: [
    // //                         {
    // //                             label: "Yes",
    // //                             onClick: () => {
    // //                                 fetchData();
    // //                             },
    // //                         },
    // //                         {
    // //                             label: "No",
    // //                             onClick: () => {
    // //                                 return;
    // //                             },
    // //                         },
    // //                     ],
    // //                 });
    // //             } else {
    // //                 fetchData();
    // //             }
    // //         } else {
    // //              setItemList([]);
    // //              setItemCount(0);

    // //         }

    // //     }

    // // }, [selectedRows]);





    //test method - end

    useEffect(() => {

        itemGridSetup(); //fetch dropdown view based on role and populate the column grid. Also, set the initial view.


    }, []);

    const itemGridSetup = () => {
        if (itemList.length <= 0) {
            viewType = getPriceListViewOpt(); // fetch the listing of view based on role

            if (viewType.length === 0) {
                columnsMapping = [];
                return;
            } else {
                viewText = viewType?.text;
                switch (viewType[0].text) {
                    case "No View":
                        if (noViewAccessSetup == 0) toast.error("No View has been configured.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        columnsMapping = [];
                        break;
                    case "No Role":
                        if (noViewAccessSetup == 0)
                            toast.error("No role has been set to your account.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        columnsMapping = [];
                        break;
                    case "":
                        if (noViewAccessSetup == 0) toast.error("No View has been configured.", { autoClose: 5000, position: "top-center", transition: Slide, });
                        noViewAccessSetup += 1; // to eliminate multiple message.
                        columnsMapping = [];
                        break;
                    default:
                        if (!!viewType.filter((src) => src.id == 0)[0]) {
                            if (noViewAccessSetup == 0)
                                toast.error(
                                    "Please check the matrix setup for " +
                                    viewType.filter((src) => src.id == 0)[0].text +
                                    ". Id should not be zero.",
                                    { autoClose: 5000, position: "top-center", transition: Slide, }
                                );
                            noViewAccessSetup += 1;
                            break;
                        } else {
                            setviewOptions(viewType); // set the values of dropdown view
                            setSelectedView(viewType.filter((src) => src.id == 1)[0]); // set the initial value of the dropdown view

                            setDropdownValue(viewType.filter((src) => src.id == 1)[0].text); index = selectedView;

                            text =
                                viewType.filter((src) => src.id == 1)[0].id > 0
                                    ? viewType.filter((src) => src.id == 1)[0].text
                                    : "";
                            index >= 0 ? handleItemGrid(index, text) : null;
                        }
                }
            }
        }
    };




    //#region -- Customer grid functions

    const handleCustomerNumberChange = async ({ value: customerNumber, dataItem, clearInput }, item) => {
        setIsLoading(true);
        try {
            if (!customerNumber) {
                throw new Error("Customer number cannot be empty");
            }
            if (Object.keys(customerData).length !== 0) {
                if (customerData[id].length > 0) {
                    const isCustomerExists = customerData[id].filter((item) => item.customerNumber === customerNumber.toUpperCase());
                    if (isCustomerExists.length > 0) {
                        toast.warning("Customer is already exists in the list!.", {
                            autoClose: 5000,
                            position: "top-center",
                            transition: Slide,
                        });
                        return;
                    }
                }
            }
            let API_URL = "";
            if (optPListTypeTypesId.text != null || optPListTypeTypesId != undefined || optPListTypeTypesId != "" || optPListTypeTypesId != "0") {
                if (priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted
                    ? optPListTypeTypesId[0]?.id : optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
                    API_URL = "Customer/getselectedpricelistcustomer";
                } else if (optPListTypeTypesId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
                    API_URL = "Customer/getselectedl50pricelistitembycustomer";
                }
            }
            else if (priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted
                ? optPListTypeTypesId[0]?.id : priceListDetails[id].selectedListTypeId != null && priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted
                    ? optPListTypeTypesId[0]?.id : priceListDetails[id].selectedListTypeId != undefined) {
                if (priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted
                    ? optPListTypeTypesId[0]?.id : optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
                    API_URL = "Customer/getselectedpricelistcustomer";
                } else if (priceListDetails[id].selectedListTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
                    API_URL = "Customer/getselectedl50pricelistitembycustomer";
                }
            } else {
                toast.error("List Type is required.", {
                    autoClose: 5000,
                    position: "top-center",
                    transition: Slide,
                });
                setIsLoading(false);
                return;
            }
            if (API_URL === "") {
                return;
            }

            const items = await getResponse(API_URL, {
                customerNumber: customerNumber,
                currentUserId: user?.homeAccountId,
            });

            const newItem = items.map(item => ({
                customerNumber: item.customerNumber,
                customerName: item.customerName,
                debtorType: item.debtorType,
                priceCategory: item.priceCategory,
                startDate: new Date(item.startDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }),
                endDate: new Date(item.endDate).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                }),
                type: "PriceList"
            }));

            setDebtorType(newItem[0].debtorType);
            setPriceCategory(newItem[0].priceCategory);

            // Merge newItem with existing customerData[id]
            if (customerData[id]) {
                customerData[id] = [...customerData[id], ...newItem];
            } else {
                customerData[id] = newItem;
            }

            // updateQuoteDetailsForId(id, { selectedCustomers: customerData[id] });

            // manoj edited for quote details
            // updateQuoteDetailsForId(id, { selectedCustomers: customerData[id] });
            // updatePriceListDetailsForId(id, { selectedCustomers: customerData[id] });



        } catch (error) {
            // Clear the input if there's an error
            clearInput(lastValidCustomerNumber);

            if (error.message === "Customer number cannot be empty") {
                return;
            }

            // if (error.response && error.response.status === 404) {
            //     toast.error(error.response.data.message || "Error occurred while fetching customer data", {
            //         position: "top-center",
            //         transition: Slide,
            //     });
            // } else {
            //     toast.error("Customer does not exist.", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
            //     console.error(error);
            //     //setCustomerDataEmpty("");
            //     return;
            // }
        }
        finally {
            setIsLoading(false);
        }
    };

    //#region  HandleRemove
    /*
    const handleRemove = (selectedRow) => {
        try {
            //setIsLoading(true);
            // // if (selectedRows.length > 0) {
            if (selectedRow[0].customerNumber) {
                // // const selectedCustomer = selectedRows[0];
                const selectedCustomer = { customerNumber: selectedRow[0].customerNumber };

                if (!selectedCustomer.customerNumber) {
                    toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
                } else {
                    confirmAlert({
                        title: "Confirm to submit",
                        message: "Are you sure you want to delete the customer?",
                        buttons: [
                            {
                                label: "Yes",
                                onClick: () => {
                                    const customerNumber = selectedCustomer.customerNumber;

                                    const newCustomerList = customerData[id].filter((c) => {
                                        return c.customerNumber !== selectedCustomer.customerNumber;
                                    });

                                    setCustomerData((prevCustomerData) => ({
                                        ...prevCustomerData,
                                        [id]: newCustomerList,
                                    }));

                                    if (priceListDetails[id].selectedRow.customerNumber == selectedCustomer.customerNumber) {
                                        setItemList([]);
                                        const newData = priceListDetails[id].selectedRow;
                                        Object.keys(isChecked).forEach(key => {
                                            if (key !== 'customerNumber' && key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                                                newData[key] = false; // Uncheck all other checkboxes
                                        });
                                        updatePriceListDetailsForId(id, { selectedItems: "", selectedCustomer: "", selectedRow: "" });
                                        setSelectedRows([]); // Reset selected rows
                                        setItemCount(0);
                                    }
                                    else {
                                        const newData = priceListDetails[id].selectedRow;
                                        const dataIndex = newCustomerList.findIndex((item) => item.customerNumber === priceListDetails[id].selectedRow.customerNumber);
                                        Object.keys(isChecked).forEach(key => {
                                            if (key !== 'customerNumber' && key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                                                newData[key] = false; // Uncheck all other checkboxes
                                        });
                                        setIsChecked(newData);
                                        // newData.value = true; // Check the clicked checkbox
                                        if (dataIndex > -1) {
                                            newData[dataIndex] = true;
                                            newData.value = true;
                                            newData.customerNumber = newCustomerList[dataIndex].customerNumber;
                                            newData.customerName = newCustomerList[dataIndex].customerName;
                                            newData.debtorType = newCustomerList[dataIndex].debtorType;
                                            newData.priceCategory = newCustomerList[dataIndex].priceCategory;
                                            newData.startDate = newCustomerList[dataIndex].startDate;
                                            newData.endDate = newCustomerList[dataIndex].endDate;
                                            newData.type = "PriceList";
                                            setIsChecked(newData);
                                            updatePriceListDetailsForId(id, { selectedRow: newData });
                                            setSelectedRows([]);
                                        }
                                    }
                                },
                            },
                            {
                                label: "No",
                                onClick: () => { },
                            },
                        ],
                    });
                }
            } else {
                toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
            }
        }
        catch (error) {
            console.error("Error : ", error);
        }
        finally {
            setIsLoading(false);
        }
    };
    */
    //#endregion

    const handleRemove = (selectedRow) => {
        try {
            //setIsLoading(true);
            // // if (selectedRows.length > 0) {
            if (selectedRow[0].customerNumber) {
                // // const selectedCustomer = selectedRows[0];
                const selectedCustomer = { customerNumber: selectedRow[0].customerNumber };

                if (!selectedCustomer.customerNumber) {
                    toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
                } else {
                    confirmAlert({
                        title: "Confirm to submit",
                        message: "Are you sure you want to delete the customer?",
                        buttons: [
                            {
                                label: "Yes",
                                onClick: () => {
                                    const customerNumber = selectedCustomer.customerNumber;
                                    const newCustomerList = customerData[id].filter((c) =>
                                        c.customerNumber !== selectedCustomer.customerNumber
                                    );



                                    const customerNumber_ListSample = priceListDetails[id].selectedCustomers.customerNumber;
                                    const isCustomerNumberExist = priceListDetails[id].selectedCustomers.find((i) => i.customerNumber === selectedCustomer.customerNumber)
                                    // if (priceListDetails[id].selectedCustomers.customerNumber == selectedCustomer.customerNumber) {
                                    if (isCustomerNumberExist) {
                                        // setItemList([]);
                                        const newData = priceListDetails[id].selectedCustomers;
                                        Object.keys(isChecked).forEach(key => {
                                            if (key !== 'customerNumber' && key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                                                newData[key] = false; // Uncheck all other checkboxes
                                        });
                                        // updatePriceListDetailsForId(id, { selectedItems: "", selectedCustomer: "", selectedRow: "" });
                                        //setSelectedRows([]); // Reset selected rows
                                        // setItemCount(0);
                                        // setItemList([]);

                                        setCustomerData((prevCustomerData) => ({
                                            ...prevCustomerData,
                                            [id]: newCustomerList,
                                        }));
                                    }
                                    else {
                                        //const newData = priceListDetails[id].selectedRow;
                                        //const dataIndex = newCustomerList.find((item) => item.customerNumber === priceListDetails[id].selectedCustomers.customerNumber);
                                        const dataIndex = priceListDetails[id].selectedCustomers.find((item) => item.customerNumber === newCustomerList.customerNumber);
                                        var objectKey = Object.keys(isChecked);
                                        Object.keys(isChecked).forEach(key => {
                                            if (key !== 'customerNumber' && key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                                                var sample = newData[key];
                                            newData[key] = false; // Uncheck all other checkboxes
                                        });
                                        // setIsChecked(newData);
                                        const newData = {
                                            value: true,
                                            customerNumber: newCustomerList.customerNumber,
                                            customerName: newCustomerList.customerName,
                                            debtorType: newCustomerList.debtorType,
                                            priceCategory: newCustomerList.priceCategory,
                                            startDate: newCustomerList.startDate,
                                            endDate: newCustomerList.endDate,
                                            type: "PriceList"
                                        };
                                        setDebtorType(newCustomerList.debtorType);
                                        setPriceCategory(newCustomerList.priceCategory);
                                        setIsChecked(newData);
                                        updatePriceListDetailsForId(id, { selectedRow: newData });
                                        //   setSelectedRows([]); // Reset selected rows
                                        //   setItemCount(0);
                                        //setSelectedRows(dataIndex);
                                        //   setSelectedRows(newData);
                                        // newData.value = true; // Check the clicked checkbox
                                        // if (dataIndex > -1) {
                                        //     newData[dataIndex] = true;
                                        // newData.value = true;
                                        // newData.customerNumber = newCustomerList.customerNumber;
                                        // newData.customerName = newCustomerList.customerName;
                                        // newData.debtorType = newCustomerList.debtorType;
                                        // newData.priceCategory = newCustomerList.priceCategory;
                                        // newData.startDate = newCustomerList.startDate;
                                        // newData.endDate = newCustomerList.endDate;
                                        // newData.type = "PriceList";
                                        // setIsChecked(newData);
                                        // updatePriceListDetailsForId(id, { selectedRow: newData });
                                        // setSelectedRows([]);
                                        // }
                                    }
                                },
                            },
                            {
                                label: "No",
                                onClick: () => { },
                            },
                        ],
                    });
                }
            } else {
                toast.error("Please select at least one Customer!", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide, });
            }
        }
        catch (error) {
            console.error("Error : ", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleAddCustomer = () => {

        try {


            if (listTypeChange) {
                setDialogVisible(true);
            }
            else if (optPListTypeTypesId.text != null && optPListTypeTypesId.text != undefined && optPListTypeTypesId.text != "" && optPListTypeTypesId.text != "0") {
                setDialogVisible(true);
            }
            else if (priceListDetails[id].selectedListTypeId != null && priceListDetails[id].selectedListTypeId != undefined && priceListDetails[id].selectedListTypeId != "" && priceListDetails[id].selectedListTypeId != "0") {
                setDialogVisible(true);
            }
            else {
                toast.error("Please select a List Type", {
                    autoClose: 5000,
                    position: "top-center",
                    transition: Slide,
                });
            }
        }
        catch (error) {
            console.error("Error : ", error);
        }
        finally {


        }

    };


    const handleSelectedCustomers = (items) => {
        setSelectedCustomers(items);
    };

    const handleAddCustomers = async () => {
        try {
            if (isLoading) {
                return; // Exit if the function is still processing
            }



            setIsLoading(true);

            if (!addItems || addItems.length === 0) {
                toast.error("No Customers added", {
                    autoClose: 5000,
                    position: "top-center",
                    transition: Slide,
                });
                setIsLoading(false);
                return;
            }

            newItem = [];

            const items = await getCustomerData(addItems);

            for (let i = 0; i < items.length; i++) {
                items[i].map((item) => {

                    if (customerData[id] !== undefined && customerData[id] !== null && customerData[id].length > 0) { }

                    const exists = customerData[id] !== undefined ? customerData[id].some((newItem) => newItem.customerNumber === item.customerNumber) : false;
                    if (!exists) {
                        newItem.push({
                            customerNumber: item.customerNumber,
                            customerName: item.customerName,
                            debtorType: item.debtorType,
                            priceCategory: item.priceCategory,


                            //startDate: item.startDate,
                            startDate: new Date(item.startDate).toLocaleDateString('en-GB', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            }),
                            endDate: new Date(item.endDate).toLocaleDateString('en-GB', {

                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric'
                            }),
                            type: "PriceList"
                        })
                        setDebtorType(item.debtorType);
                        setPriceCategory(item.priceCategory);
                    }

                }
                )
            }
            setCustomerData((prevCustomerData) => ({
                ...prevCustomerData,
                [id]: [...(prevCustomerData[id] || []), ...newItem],
            }));

            setSelectedItems([]);
            setAddItems([]);
            setIsLoading(false);
            closeCustomerDialog();

        }
        catch (error) {
            console.error("Error : ", error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const getCustomerData = async () => {
        try {
            setIsLoading(true);
            // Removing duplicates based on customerNumber
            const uniqueAddItems = Array.from(new Set(addItems.map((item) => item.CustomerNumber))).map(
                (CustomerNumber) => addItems.find((item) => item.CustomerNumber === CustomerNumber)
            );

            let API_URL = "";
            if ((priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted)
                ? optPListTypeTypesId[0]?.id
                : priceListDetails[id]?.selectedListTypeId
                != undefined) {
                if (priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted
                    ? optPListTypeTypesId[0]?.id : optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
                    API_URL = "Customer/getselectedpricelistcustomer";

                } else if (optPListTypeTypesId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
                    API_URL = "Customer/getselectedl50pricelistitembycustomer";
                }
            }
            // manoj edited for quote details
            // else if (quoteDetails[id].selectedListTypeId != null && quoteDetails[id].selectedListTypeId != undefined) {
            //     if (quoteDetails[id].selectedListTypeId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
            //         API_URL = "Customer/getselectedpricelistcustomer";
            //     } else if (quoteDetails[id].selectedListTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
            //         API_URL = "Customer/getselectedl50pricelistitembycustomer";
            //     }
            // }

            else if (priceListDetails[id].selectedListTypeId != null && priceListDetails[id].selectedListTypeId != undefined) {
                if (priceListDetails[id].selectedListTypeId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
                    API_URL = "Customer/getselectedpricelistcustomer";
                } else if (priceListDetails[id].selectedListTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
                    API_URL = "Customer/getselectedl50pricelistitembycustomer";
                }
            }

            if (API_URL === "") {
                return;
            }

            const _response = await Promise.all(
                addItems.map((item) =>
                    getResponse(API_URL, {
                        customerNumber: item.customerNumber,
                        //currentUserId: user?.homeAccountId,
                    })
                )
            );
            console.log("responses", _response);
            return _response;

        } catch (error) {
            console.error("Error : ", error);
        }
        finally {
            setIsLoading(false);
        }

    };

    const [checkboxval, setCheckboxVal] = React.useState(null);
    // const [isChecked, setIsChecked] = useState(false);
    const [isChecked, setIsChecked] = useState({});
    const customerNoCol = ['Level 50'];
    const [customerGridcolumns, setCustomerGridcolumns] = useState([

        { field: "customerNumber", title: "Customer No", editable: true, onchange: handleEditChange },
        { field: "customerName", title: "Name", userConfig: false, userSelected: false },

        { field: "debtorType", title: "Debtor Type" },
        { field: "priceCategory", title: "Price Cat" },
        { field: "startDate", title: "Start Date", format: "{dd/MM/yyyy}" },
        { field: "endDate", title: "End Date", format: "{dd/MM/yyyy}" },
        // { field: "isChecked", title: "isChecked", show: true },

        // { field: "", title: "", cell: (props) => props.dataItem.customerNumber !== undefined  ? <button title="Delete" look="flat" class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button delete-quote-icon" onClick={() => handleRemove(props.dataItem)}><span class="k-icon k-i-delete k-button-icon" role="presentation"></span></button> : null, minWidth: 20 }
        // { field: "", title: "", cell: (props) => props.dataItem.customerNumber !== undefined  ? <button title="Delete" look="flat" class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-icon-button delete-quote-icon" onClick={() => handleRemove(props.dataItem)}><span class="k-icon k-i-delete k-button-icon" role="presentation"></span></button> : null, minWidth: 20 }
        {
            field: "",
            title: "Load",
            cell: (props) => props.dataItem.customerNumber !== undefined ?
                <td><input type="checkbox" className="form-check-input form-check-input ip-tick-show" onChange={(e) => handleCheckboxChange(e, props)} /></td> : null,
            minWidth: 20
        }
    ]);
    const customerGridcolumnsUpdate = () => {
        let custGridcolumns = customerGridcolumns;
        const index = custGridcolumns.findIndex((item) => item.field === "customerNumber");
        custGridcolumns[index].title = selectedListTypeState.value.text.includes(customerNoCol) ? "Code" : "Customer No";

        const newCols = columns.map((item) => {
            if (item.field === "l20") return { ...item, title: priceCategory ? `L20 (${priceCategory})` : "L20 Price" };
            else if (item.field === "l50") return { ...item, title: debtorType ? `L50 (${debtorType})` : "L50 Price" };
            else return item;

        });

        setCustomerGridcolumns(custGridcolumns);
        setColumns(newCols);
    }



    const [previousDataIndex, setPreviousDataIndex] = useState(null);

    const handleCheckboxChange = async (e, props) => {

        const checkboxes = document.querySelectorAll('.form-check-input.ip-tick-show');
        checkboxes.forEach((checkbox) => {
            if (checkbox !== e.target) {
                checkbox.checked = false;
            }
        });


        let PriceListTypes = [];
        // load the parameters needed for the load parts
        console.log(drpRef);
        const data = await getResponse("PriceListType/getallpricelisttype");
        const pListType = data.map((item) => item.list_Type);
        setPListTypeTypes(pListType);
        setPListTypeListing(data);
        data.map((itm) =>
            PriceListTypes = [...PriceListTypes, { text: itm.list_Type, id: itm.id }]
        )
        const details = {
            selectedCustomers: customerData[id],
            selectedListTypeId: PriceListTypes.find((src) => src.text === drpRef.current.value).id,
            selectedListType: drpRef.current.value,
            selectedRow: props.dataItem,
        }

        priceListDetails[id] = details;

        if (previousDataIndex !== null) {
            if (previousDataIndex !== props.dataIndex) {
                confirmAlert({
                    title: "Confirm clear all and reload",
                    message: "This action will clear all the parts and reload parts for selected pricelist item.Do you want to clear and reload?",
                    buttons: [
                        {
                            label: "Yes",
                            onClick: () => {
                                // fetchData();
                                loadParts(props);
                            },
                        },
                        {
                            label: "No",
                            onClick: () => {

                                return;
                            },
                        },
                    ],
                });

                //return;
            }
            else {
                loadParts(e, props);
            }
        }
        else {
            loadParts(e, props);
        }
        //how to get the previous value of the checkbox


        // const newData = {};
        // Object.keys(isChecked).forEach(key => {
        //     newData[key] = false; // Uncheck all other checkboxes
        // });
        // newData[props.dataIndex] = true; // Check the clicked checkbox
        // setIsChecked(newData);


        // // const newData = { id: props.dataIndex, value: !isChecked[props.dataIndex], customerNumber: props.dataItem.customerNumber, customerName: props.dataItem.customerName, debtorType: props.dataItem.debtorType, priceCategory: props.dataItem.priceCategory, startDate: props.dataItem.startDate, endDate: props.dataItem.endDate };



        // // if (newData.value) {

        // //     Object.keys(isChecked).forEach(key => {
        // //         newData[key] = false; // Uncheck all other checkboxes
        // //     });
        // //     newData.value = true; // Check the clicked checkbox
        // //     newData[props.dataIndex] = true;
        // // }
        // // else {
        // //     Object.keys(isChecked).forEach(key => {
        // //         newData[key] = false; // Uncheck all other checkboxes
        // //     });
        // //     newData.value = false;
        // //     newData[props.dataIndex] = false;
        // // }
        // // // Check the clicked checkbox
        // // setIsChecked(newData);




        // // //     setIsChecked(!isChecked);

        // // if (newData[props.dataIndex] === true) {
        // //     // if (!isLoadOnIdChange) {
        // //     const fetchData = async () => {
        // //         try {
        // //             setIsLoading(true);

        // //             if (Object.keys(props.dataItem).length > 0 && typeof optPListTypeTypesId === 'string') {
        // //                 let API_URL = "";

        // //                 if (optPListTypeTypesId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
        // //                     API_URL = "Stocks/getstockdetailsforpricelist";
        // //                 } else if (optPListTypeTypesId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
        // //                     API_URL = "Stocks/getstockdetailsforl50pricelist";
        // //                 }

        // //                 if (API_URL === "") {
        // //                     return;
        // //                 }

        // //                 const responses = await getResponse(API_URL, {
        // //                     custno: props.dataItem.customerNumber,
        // //                     //currentUserId: user?.homeAccountId,
        // //                 })

        // //                 if (responses.length == 0 || responses == null || responses == undefined) {
        // //                     toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
        // //                     setItemList([]);
        // //                     setItemCount(0);
        // //                 }

        // //                 console.log("responses 1", responses);

        // //                 setItemList(responses);
        // //                 setItemCount(responses.length);
        // //                 // console.log("itemList", itemList);
        // //                 const responsesArray = responses;
        // //                 //manoj edited for quote details
        // //                 // updateQuoteDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });
        // //                 updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });

        // //             }

        // //             else if (Object.keys(props.dataItem).length.length > 0 && typeof priceListDetails[id].selectedListTypeId !== "" || typeof priceListDetails[id].selectedListTypeId !== null || typeof priceListDetails[id].selectedListTypeId !== undefined) {
        // //                 let API_URL = "";

        // //                 if (priceListDetails[id].selectedListTypeId.toUpperCase() === "AF37B668-6774-475C-A9AF-A7AB5EBA5CCB") {
        // //                     API_URL = "Stocks/getstockdetailsforpricelist";
        // //                 } else if (priceListDetails[id].selectedListTypeId.toUpperCase() === "C6342437-6CD6-4213-BA14-C69E43A51345") {
        // //                     API_URL = "Stocks/getstockdetailsforl50pricelist";
        // //                 }

        // //                 if (API_URL === "") {
        // //                     return;
        // //                 }

        // //                 const responses = await getResponse(API_URL, {
        // //                     custno: selectedRows[0].customerNumber,
        // //                     //currentUserId: user?.homeAccountId,
        // //                 })

        // //                 if (responses.length == 0 || responses == null || responses == undefined) {
        // //                     toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
        // //                     setItemList([]);
        // //                     setItemCount(0);
        // //                 }

        // //                 console.log("responses 1", responses);

        // //                 setItemList(responses);
        // //                 // console.log("itemList", itemList);
        // //                 const responsesArray = responses;
        // //                 updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });

        // //             }

        // //         } catch (error) {
        // //             console.error("Error:", error);
        // //             toast.info("There is no old list to load for the price list item " + selectedRows[0].customerNumber, { autoClose: 5000 });
        // //             setItemList([]);    // Clear the grid if there's an error
        // //             setItemCount(0);
        // //             //manoj edited for quote details
        // //             updatePriceListDetailsForId(id, { selectedItems: [], selectedCustomer: selectedRows });
        // //         } finally {
        // //             setIsLoading(false);
        // //         }
        // //     };

        // //     if (Object.keys(props.dataItem).length > 0) {
        // //         if (itemList.length > 0) {
        // //             confirmAlert({
        // //                 title: "Confirm clear all and reload",
        // //                 message: "This action will clear all the parts and reload parts for selected pricelist item.Do you want to clear and reload?",
        // //                 buttons: [
        // //                     {
        // //                         label: "Yes",
        // //                         onClick: () => {
        // //                             fetchData();
        // //                         },
        // //                     },
        // //                     {
        // //                         label: "No",
        // //                         onClick: () => {

        // //                             Object.keys(isChecked).forEach(key => {
        // //                                 newData[key] = false; // Uncheck all other checkboxes
        // //                             });
        // //                             newData[previousDataIndex] = true;
        // //                             // newData.value = true;
        // //                             setIsChecked(newData);
        // //                             return;
        // //                         },
        // //                     },
        // //                 ],
        // //             });
        // //         } else {

        // //             fetchData();
        // //         }
        // //     } else {
        // //         setItemList([]);
        // //         setItemCount(0);
        // //         Object.keys(isChecked).forEach(key => {
        // //             newData[key] = false; // Uncheck all other checkboxes
        // //         });

        // //     }

        // //     // }
        // // }
        // // else {
        // //     setItemList([]);
        // //     setItemCount(0);
        // //     Object.keys(isChecked).forEach(key => {
        // //         newData[key] = false; // Uncheck all other checkboxes
        // //     });
        // // }

        // // setPreviousDataIndex(props.dataIndex);



    }

    const loadParts = (e, props) => {
        const newData = { id: props.dataIndex, value: e.target.checked, customerNumber: props.dataItem.customerNumber, customerName: props.dataItem.customerName, debtorType: props.dataItem.debtorType, priceCategory: props.dataItem.priceCategory, startDate: props.dataItem.startDate, endDate: props.dataItem.endDate };

        if (newData.value) {

            Object.keys(isChecked).forEach(key => {
                if (key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                    newData[key] = false; // Uncheck all other checkboxes
            });
            newData.value = true; // Check the clicked checkbox
            newData[props.dataIndex] = true;
        }
        else {
            Object.keys(isChecked).forEach(key => {
                if (key !== 'customerName' && key !== 'debtorType' && key !== 'priceCategory' && key !== 'startDate' && key !== 'endDate' && key !== 'type' && key !== 'customerNumber')
                    newData[key] = false; // Uncheck all other checkboxes
            });
            newData.value = false;
            newData[props.dataIndex] = false;
        }
        // Check the clicked checkbox
        setIsChecked(newData);




        //     setIsChecked(!isChecked);

        if (newData[props.dataIndex] === true) {
            // if (!isLoadOnIdChange) {
            const fetchData = async () => {
                try {
                    setIsLoading(true);

                    if (Object.keys(props.dataItem).length > 0 && typeof optPListTypeTypesId === 'string') {
                        let API_URL = "";

                        if (priceListDetails[id].selectedListType.includes("50")) {
                            API_URL = "Stocks/getstockdetailsforl50pricelist";
                        } else API_URL = "Stocks/getstockdetailsforpricelist";

                        if (API_URL === "") {
                            return;
                        }

                        const responses = await getResponse(API_URL, {
                            custno: props.dataItem.customerNumber,
                            //currentUserId: user?.homeAccountId,
                        })

                        if (responses.length == 0 || responses == null || responses == undefined) {
                            toast.info("There is no old list to load for the selected price list item ", { autoClose: 5000 });
                            setItemList([]);
                            setItemCount(0);
                        }

                        console.log("responses 1", responses);

                        setItemList(responses);
                        setItemCount(responses.length);
                        // console.log("itemList", itemList);
                        const responsesArray = responses;
                        //manoj edited for quote details

                        // // updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });
                        updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows, selectedRow: newData });

                    }

                    else if (Object.keys(props.dataItem).length > 0 && typeof priceListDetails[id].selectedListTypeId !== "" || typeof priceListDetails[id].selectedListTypeId !== null || typeof priceListDetails[id].selectedListTypeId !== undefined) {
                        let API_URL = "";

                        if (priceListDetails[id].selectedListType.includes("50")) {
                            API_URL = "Stocks/getstockdetailsforl50pricelist";
                        } else API_URL = "Stocks/getstockdetailsforpricelist";

                        if (API_URL === "") {
                            return;
                        }

                        const responses = await getResponse(API_URL, {
                            //custno: selectedRows[0].customerNumber,
                            custno: priceListDetails[id].selectedRow.customerNumber !== false ? priceListDetails[id].selectedRow.customerNumber : props.dataItem.customerNumber,
                        })

                        if (responses.length == 0 || responses == null || responses == undefined) {
                            toast.info("There is no old list to load for the selected price list item ", { autoClose: 5000 });
                            setItemList([]);
                            setItemCount(0);
                        }

                        console.log("responses 1", responses);

                        setItemList(responses);
                        // console.log("itemList", itemList);
                        const responsesArray = responses;
                        // // updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows });
                        updatePriceListDetailsForId(id, { selectedItems: responsesArray, selectedCustomer: selectedRows, selectedRow: newData });

                    }

                } catch (error) {
                    console.error("Error:", error);
                    toast.info("There is no old list to load for the price list item ", { autoClose: 5000 });
                    setItemList([]);    // Clear the grid if there's an error
                    setItemCount(0);
                    //manoj edited for quote details
                    // // updatePriceListDetailsForId(id, { selectedItems: [], selectedCustomer: selectedRows });
                    updatePriceListDetailsForId(id, { selectedItems: [], selectedCustomer: selectedRows, selectedRow: newData });
                } finally {
                    setIsLoading(false);
                }
            };

            // // if (Object.keys(props.dataItem).length > 0) {
            // //     if (itemList.length > 0) {
            // //         confirmAlert({
            // //             title: "Confirm clear all and reload",
            // //             message: "This action will clear all the parts and reload parts for selected pricelist item.Do you want to clear and reload?",
            // //             buttons: [
            // //                 {
            // //                     label: "Yes",
            // //                     onClick: () => {
            // //                         fetchData();
            // //                     },
            // //                 },
            // //                 {
            // //                     label: "No",
            // //                     onClick: () => {

            // //                         Object.keys(isChecked).forEach(key => {
            // //                             newData[key] = false; // Uncheck all other checkboxes
            // //                         });
            // //                         newData[previousDataIndex] = true;
            // //                         // newData.value = true;
            // //                         setIsChecked(newData);
            // //                         return;
            // //                     },
            // //                 },
            // //             ],
            // //         });
            // //     } else {

            // //         fetchData();
            // //     }
            // // } else {
            // //     setItemList([]);
            // //     setItemCount(0);
            // //     Object.keys(isChecked).forEach(key => {
            // //         newData[key] = false; // Uncheck all other checkboxes
            // //     });

            // // }

            // }

            fetchData();
        }
        else {
            setItemList([]);
            setItemCount(0);
            Object.keys(isChecked).forEach(key => {
                newData[key] = false; // Uncheck all other checkboxes
            });
        }

        setPreviousDataIndex(props.dataIndex);

    }

    const _export = React.useRef(null);

    const exportItems = () => {
        if (_export.current) {
            if (itemList.length > 0) {

                _export.current.save(itemList, columns.filter((column) => selectedColumns.includes(column.field)));

            } else {

                toast.error("No data to export.", {
                    autoClose: 5000,
                    position: "top-center",
                    transition: Slide,
                });
                console.log("No data to export.");
            }
        } else {
            console.error("Export component is not initialized.");
        }
    };

    const handleCopy = () => {
        console.log("selected items :", selectedItems)
        // setAddItems(selectedItems)
        // setCopiedUnits(selectedItems)
        setCopiedPriceList(selectedItems);
        setSelectedItems([]);
        setIsPriceListCopyed(true);
        setUnitCopy(false);


    };

    const [selectedItemIndex, setSelectedItemIndex] = useState(null);
    const initialSelectedIndex = 1;
    const drpRef = useRef();
    //#endregion 
    let priceListTypeId = '';
    priceListTypeId =
        (priceListDetails[id]?.selectedListTypeId === undefined || priceListDetails?.length === 0 || !isProcessListTypeChangeExcuted)
            ? optPListTypeTypesId[0]?.id
            : priceListDetails[id]?.selectedListTypeId;

    if (isProcessListTypeChangeExcuted) {
        priceListTypeId = priceListDetails[id]?.selectedListTypeId;
        setIsProcessListTypeChangeExcuted(false);
    }
    return (
        <>
            <ExcelExport ref={_export} fileName="PriceListItems.xlsx" />

            <Row>
                <div className="row p-0 m-0">
                    <div className="mb-3">
                        <div className="col-12 col-md-2 pe-2 float-start">
                            <strong>List Type</strong>

                            <DropDownList
                                data={pListTypeTypes}
                                valueFied="options"
                                ref={drpRef}
                                // defaultValue="Select one from the list"
                                defaultValue={selectedListType}
                                onChange={onChangeType}
                                disabled={customerData[id] == undefined || customerData[id].length == 0 ? false : true}
                            />

                            {/* <DropDownList
                                data={pListTypeTypes}
                                valueFied="options"
                                // defaultValue="Select one from the list"
                                //value={selectedItemIndex}
                                defaultValue={pListTypeTypes}
                                onChange={onChangeType}
                                disabled={customerData[id] == undefined || customerData[id].length == 0 ? false : true}
                            /> */}
                        </div>
                    </div>
                </div>
            </Row>

            <Accordion id="default-accordion-example2">
                <AccordionItem>
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className={classnames("accordion-button", { collapsed: !col2 })}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}>
                            Price List Items ({custCount})



                        </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse" id="collapseTwo">
                        <div className="accordion-body">
                            {/* {isCustLoading && (
                                <div className="ip-spin-dialog-holder-sm">
                                    <Spinner />
                                </div>
                            )}
                            {!isCustLoading && ( */}
                            <DataGridView
                                data={paginate(customerData[id] || [], pageNumber, pageSize)}
                                columns={customerGridcolumns}
                                // onEdit={onEdit}
                                selectedRows={selectedRows}
                                setSelectedRows={setSelectedRows}
                                handleCustomerNumberChange={handleCustomerNumberChange}
                                showSelectionColumn={true}
                                isHeaderCheckboxRendered={false}
                                singleSelect={true}
                                idField="customerNumber"
                                tableName="Customers"
                                toolbarButtons={[
                                    {
                                        label: <strong>Add Item</strong>,
                                        onClick: handleAddCustomer,
                                        look: "flat",
                                    },

                                    {
                                        label: <strong>Remove Customer</strong>,
                                        // onClick: handleRemove,
                                        onClick: () => handleRemove(selectedRows),
                                        disabled: selectedRows.length < 1,
                                        // disabled: isPriceListItemSelected ? false : true,
                                        look: "flat",
                                    },

                                ]}
                                total={customerData[id]?.length || 0}
                                showEmptyRow={true}
                                skip={skipCustomerPriceLists}
                                take={takeCustomerPriceLists}
                                onPageChange={handleCustomerPageChangePriceList}
                                isGroupable={false}
                            />
                            {/* )} */}

                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>

            <div class="mt-3"></div>

            {/* Item PriceList */}
            <Accordion id="default-accordion-example2">
                <AccordionItem>
                    <h2 className="accordion-header" id="headingTwo">
                        <button
                            className={classnames("accordion-button", { collapsed: !col2 })}
                            type="button"
                            onClick={t_col2}
                            style={{ cursor: "pointer" }}>
                            Parts  ({itemCount})
                        </button>
                    </h2>
                    <Collapse isOpen={col2} className="accordion-collapse" id="collapseTwo">
                        <div className="accordion-body">
                            {isLoading && (
                                <div className="ip-spin-dialog-holder-sm">
                                    <Spinner />
                                </div>
                            )}

                            {!isLoading && (

                                <DataGridView
                                    data={paginate(itemList || [], itemspageNumber, itemspageSize)}

                                    columns={columns.filter((column) => selectedColumns.includes(column.field))}
                                    // onEdit={onEdit}
                                    selectedRows={selectedItems}
                                    setSelectedRows={setSelectedItems}
                                    handleItemChange={handleItemChange}
                                    showSelectionColumn={true}
                                    isHeaderCheckboxRender={true}
                                    singleSelect={false}
                                    idField="stStockCode"
                                    tableName="PriceListItems"
                                    toolbarButtons={[

                                        {
                                            label: (
                                                <div className="ip-price-view-btn">
                                                    <span>View:</span>
                                                    <DropDownList
                                                        data={viewOptions}
                                                        textField="text"
                                                        dataItemKey="id"
                                                        value={selectedView}
                                                        onChange={handleChangeView}
                                                        ref={changedView}
                                                    />
                                                </div>
                                            ),
                                        },
                                        {
                                            label: <strong title="Export Items (As in the Grid)">Export Items (As in the Grid)</strong>,
                                            onClick: exportItems,

                                        },
                                        {
                                            label: <strong title="Click on copy">Copy</strong>,
                                            onClick: () => handleCopy(),
                                            disabled: selectedItems <= 0,
                                            look: "flat",
                                        },


                                    ]}
                                    skip={skipPriceLists}
                                    take={takePriceLists}
                                    total={itemCount}
                                    showEmptyRow={false}
                                    onPageChange={handlePageChangePriceList}
                                    isGroupable={false}
                                />

                            )}
                        </div>
                    </Collapse>
                </AccordionItem>
            </Accordion>


            {itemLookUpVisible && (
                <Dialog width="100%" title={"Parts Lookup"} onClose={closeDialog}>
                    <ItemLookup onAddItems={handleSelectedItems} />

                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button className="px-4 me-2 ip-button-general" onClick={handleAddParts}>
                                Add
                            </Button>
                            <Button className="px-4" onClick={closeDialog}>
                                Cancel
                            </Button>


                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

            {dialogVisible && (
                <Dialog width="100%" title={"Pricelist Lookup"} onClose={closeCustomerDialog}>
                    {/* <PLCustomerLookup onAddItems={handleSelectedCustomers} optPListTypeTypesId={optPListTypeTypesId} plistTypeId={quoteDetails[id].selectedListTypeId} /> */}
                    <PLCustomerLookup
                        onAddItems={handleSelectedCustomers}
                        optPListTypeTypesId={optPListTypeTypesId}
                        plistTypeId={priceListTypeId !== undefined ? priceListTypeId : priceListDetails[id]?.selectedListTypeId}
                    />


                    <DialogActionsBar>
                        <div className="d-flex justify-content-end ip-dialog-btnstatic">
                            <Button className="px-4 me-2 ip-button-general" onClick={handleAddCustomers}>
                                {/* <Button className="px-4 me-2 ip-button-general"> */}

                                Add
                            </Button>
                            <Button className="px-4" onClick={closeCustomerDialog}>
                                Cancel
                            </Button>
                        </div>
                    </DialogActionsBar>
                </Dialog>
            )}

        </>
    );
};

export default NewPriceListItemDataGrid;
