import { GroupTypes } from "../enums/GroupTypes";
import {
    getAllItemsInAGroup,
    getAllExistingSubGroupedItems,
    getAllExistingNonSubGroupedItems,
    getGroupedRowItems,
    getGroupRowByGroup,
    getSubGroupRowByGroupAndSubGroup,
    getGroupRowIndex,
    getSubGroupRowIndex,
    getAllExistingSubGroupsByGroup,
    getExistingNonGroupedItems,
    getAllGroupRows,
    getAllSubGroupRows,
    getSubGroupedRowItems,
} from "./GroupFilters";
import {
    isDbExtraAdded,
    getExistingGroupWiseDbExtraRow,
    getItemsWithoutDBExtra,
    getDbExtraRowIndex,
} from "./DbExtra/DbExtraFilters";
import { calculateDbExtra, getNonGroupDbExtraRow } from "./DbExtra/DbExtraCalculations";

export function itemGridRecalculation(formattedList) {
    const groupedListWithListPriceSum = groupAndSumField(formattedList, "listPrice");
    const groupedListWithNettEaSum = groupAndSumField(formattedList, "nettEA");
    const groupedListWithstEecostSum = groupAndSumField(formattedList, "stEecost");
    const groupedListWithStFutureCostSum = groupAndSumField(formattedList, "stFutureCost");
    const groupedListWithActCostExact = groupAndSumField(formattedList, "actCostExact");
    const groupedListWithTotalPriceSum = groupAndSumField(formattedList, "totalPrice");
    const groupedListWithTotalCostSum = groupAndSumField(formattedList, "totalCost");
    // const groupedListWithActCostExactSum = groupAndSumField(formattedList, "actCostExact");

    const subGroupedTotalForListPrice = calculateSumOfListPrices(groupedListWithListPriceSum, "listPrice");
    const subGroupedTotalForNettEa = calculateSumOfListPrices(groupedListWithNettEaSum, "nettEA");
    const subGroupedTotalForstEecost = calculateSumOfListPrices(groupedListWithstEecostSum, "stEecost");
    const subGroupedTotalForActCostExact = calculateSumOfListPrices(groupedListWithActCostExact, "actCostExact");
    const subGroupedTotalForStFutureCost = calculateSumOfListPrices(groupedListWithStFutureCostSum, "stFutureCost");
    const subGroupedTotalForTotalPrice = calculateSumOfListPrices(groupedListWithTotalPriceSum, "totalPrice");
    const subGroupedTotalForTotalCost = calculateSumOfListPrices(groupedListWithTotalCostSum, "totalCost");
    // const subGroupedListWithActCostExactSum = groupAndSumField(groupedListWithActCostExactSum, "actCostExact");

    for (const groupName in subGroupedTotalForListPrice) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedListPrice = parseFloat(subGroupedTotalForListPrice[groupName].sumFieldValue.toFixed(2));

        const updatedRow = {
            ...groupRow,
            listPrice: updatedListPrice,
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in subGroupedTotalForNettEa) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            nettEA: parseFloat(subGroupedTotalForNettEa[groupName].sumFieldValue.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in subGroupedTotalForTotalPrice) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            totalPrice: parseFloat(subGroupedTotalForTotalPrice[groupName].sumFieldValue.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in subGroupedTotalForstEecost) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            stEecost: parseFloat(subGroupedTotalForstEecost[groupName].sumFieldValue.toString()),
            stEecostRound: parseFloat(parseFloat(subGroupedTotalForstEecost[groupName].sumFieldValue).toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in subGroupedTotalForStFutureCost) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            stFutureCost: parseFloat(subGroupedTotalForStFutureCost[groupName].sumFieldValue.toString()),
            stFutureCostRound: parseFloat(
                parseFloat(subGroupedTotalForStFutureCost[groupName].sumFieldValue.toFixed(2))
            ),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in subGroupedTotalForActCostExact) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            actCostExact: parseFloat(subGroupedTotalForActCostExact[groupName].sumFieldValue.toString()),
            actCost: parseFloat(parseFloat(subGroupedTotalForActCostExact[groupName].sumFieldValue.toFixed(2))),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    //handling actCostExact
    // for (const groupName in subGroupedListWithActCostExactSum) {
    //     const groupRow = groupedListWithListPriceSum.filter(
    //         (item) => item.groupName === groupName && item.displayGroupName === groupName
    //     )[0];

    //     const groupRowIndex = groupedListWithListPriceSum.findIndex(
    //         (item) => item.groupName === groupName && item.displayGroupName === groupName
    //     );

    //     const updatedRow = {
    //         ...groupRow,
    //         actCost: parseFloat(subGroupedListWithActCostExactSum[groupName].sumFieldValue.toFixed(2)),
    //     };

    //     groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    // }
    //end handling actCostExact

    for (const groupName in subGroupedTotalForTotalCost) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            totalCost: parseFloat(subGroupedTotalForTotalCost[groupName].sumFieldValue.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    const subGroupWiseDcOnLp = calculateGroupWiseDcOnLp(formattedList, GroupTypes.SUB_GROUP);
    const groupWiseDcOnLp = calculateGroupWiseDcOnLp(formattedList, GroupTypes.GROUP);

    const subGroupWiseMargin = calculateGroupWiseMargin(formattedList, GroupTypes.SUB_GROUP);
    const groupWiseMargin = calculateGroupWiseMargin(formattedList, GroupTypes.GROUP);

    const subGroupWiseFrcMargin = calculateGroupWiseFrcMargin(formattedList, GroupTypes.SUB_GROUP);
    const groupWiseFrcMargin = calculateGroupWiseFrcMargin(formattedList, GroupTypes.GROUP);

    const subGroupWiseActMargin = calculateGroupWiseActMargin(formattedList, GroupTypes.SUB_GROUP);
    const groupWiseActMargin = calculateGroupWiseActMargin(formattedList, GroupTypes.GROUP);

    const subGroupWiseDcOnLpEmpty = Object.keys(subGroupWiseDcOnLp).length === 0;
    const subGroupWiseMarginEmpty = Object.keys(subGroupWiseDcOnLp).length === 0;
    const subGroupWiseFrcMarginEmpty = Object.keys(subGroupWiseDcOnLp).length === 0;
    const subGroupWiseActMarginEmpty = Object.keys(subGroupWiseDcOnLp).length === 0;

    if (!subGroupWiseDcOnLpEmpty) {
        for (const subGroupName in subGroupWiseDcOnLp) {
            const subGroupRow = groupedListWithListPriceSum.filter(
                (item) =>
                    item.groupName === subGroupWiseDcOnLp[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            )[0];

            const subGroupRowIndex = groupedListWithListPriceSum.findIndex(
                (item) =>
                    item.groupName === subGroupWiseDcOnLp[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            );

            const updatedRow = {
                ...subGroupRow,
                dcOnLp: parseFloat(subGroupWiseDcOnLp[subGroupName].dcOnLp.toFixed(2)),
            };

            groupedListWithListPriceSum[subGroupRowIndex] = updatedRow;
        }
    }

    if (!subGroupWiseMarginEmpty) {
        for (const subGroupName in subGroupWiseMargin) {
            const subGroupRow = groupedListWithListPriceSum.filter(
                (item) =>
                    item.groupName === subGroupWiseMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            )[0];

            const subGroupRowIndex = groupedListWithListPriceSum.findIndex(
                (item) =>
                    item.groupName === subGroupWiseMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            );

            const updatedRow = {
                ...subGroupRow,
                margin: parseFloat(subGroupWiseMargin[subGroupName].margin.toFixed(2)),
            };

            groupedListWithListPriceSum[subGroupRowIndex] = updatedRow;
        }
    }

    if (!subGroupWiseFrcMarginEmpty) {
        for (const subGroupName in subGroupWiseFrcMargin) {
            const subGroupRow = groupedListWithListPriceSum.filter(
                (item) =>
                    item.groupName === subGroupWiseFrcMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            )[0];

            const subGroupRowIndex = groupedListWithListPriceSum.findIndex(
                (item) =>
                    item.groupName === subGroupWiseFrcMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            );

            const updatedRow = {
                ...subGroupRow,
                frcMargin: parseFloat(subGroupWiseFrcMargin[subGroupName].frcMargin.toFixed(2)),
            };

            groupedListWithListPriceSum[subGroupRowIndex] = updatedRow;
        }
    }

    if (!subGroupWiseActMarginEmpty) {
        for (const subGroupName in subGroupWiseActMargin) {
            const subGroupRow = groupedListWithListPriceSum.filter(
                (item) =>
                    item.groupName === subGroupWiseActMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            )[0];

            const subGroupRowIndex = groupedListWithListPriceSum.findIndex(
                (item) =>
                    item.groupName === subGroupWiseActMargin[subGroupName].groupName &&
                    item.displaySubGroupName === subGroupName
            );

            const updatedRow = {
                ...subGroupRow,
                actMargin: parseFloat(subGroupWiseActMargin[subGroupName].actMargin.toFixed(2)),
            };

            groupedListWithListPriceSum[subGroupRowIndex] = updatedRow;
        }
    }

    for (const groupName in groupWiseDcOnLp) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            dcOnLp: parseFloat(groupWiseDcOnLp[groupName].dcOnLp.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in groupWiseMargin) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            margin: parseFloat(groupWiseMargin[groupName].margin.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }

    for (const groupName in groupWiseFrcMargin) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            frcMargin: parseFloat(groupWiseFrcMargin[groupName].frcMargin.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }
    for (const groupName in groupWiseActMargin) {
        const groupRow = groupedListWithListPriceSum.filter(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        )[0];

        const groupRowIndex = groupedListWithListPriceSum.findIndex(
            (item) => item.groupName === groupName && item.displayGroupName === groupName
        );

        const updatedRow = {
            ...groupRow,
            actMargin: parseFloat(groupWiseActMargin[groupName].actMargin.toFixed(2)),
        };

        groupedListWithListPriceSum[groupRowIndex] = updatedRow;
    }
    return groupedListWithListPriceSum;
}

//ref : grouping function 01
export function groupAndSumField(data, fieldName) {
    const groupedData = {};

    // Step 1: Calculate the sums
    data.forEach((item) => {
        const { groupName, subGroupName } = item;
        const fieldValue = item[fieldName];
        let value = -1;

        if (fieldName !== "stEecost") {
            value = fieldValue === "" ? 0 : isNaN(fieldValue) ? 0 : parseFloat(parseFloat(fieldValue).toFixed(2));
        } else if (fieldName !== "stFutureCost") {
            value = fieldValue === "" ? 0 : isNaN(fieldValue) ? 0 : parseFloat(parseFloat(fieldValue).toFixed(2));
        } else if (fieldName !== "actCostExact") {
            value = fieldValue === "" ? 0 : isNaN(fieldValue) ? 0 : parseFloat(parseFloat(fieldValue).toFixed(2));
        } else if (fieldName !== "lastCostExact" || fieldName !== "LastCost") {
            value = fieldValue === "" ? 0 : isNaN(fieldValue) ? 0 : parseFloat(parseFloat(fieldValue).toFixed(2));
        } else {
            value = fieldValue === "" ? 0 : isNaN(fieldValue) ? 0 : parseFloat(fieldValue);
        }

        if (groupName && subGroupName) {
            if (!groupedData[groupName]) {
                groupedData[groupName] = {};
            }

            if (!groupedData[groupName][subGroupName]) {
                groupedData[groupName][subGroupName] = 0;
            }

            groupedData[groupName][subGroupName] += value;
        } else if (groupName) {
            if (!groupedData[groupName]) {
                groupedData[groupName] = {};
            }
        }
    });

    // Step 2: Update the original objects with the calculated sums for matching subGroupName and displaySubGroupName
    data.forEach((item) => {
        const { subGroupName, displaySubGroupName } = item;

        if (subGroupName && displaySubGroupName && subGroupName === displaySubGroupName) {
            const total = groupedData[item.groupName][subGroupName];

            if (fieldName === "stEecost") {
                item["stEecost"] = parseFloat(total);
                item["stEecostRound"] = parseFloat(parseFloat(total).toFixed(2));
            } else if (fieldName === "stFutureCost") {
                item["stFutureCost"] = parseFloat(total);
                item["stFutureCostRound"] = parseFloat(parseFloat(total).toFixed(2));
            } else if (fieldName === "actCostExact") {
                item["actCostExact"] = parseFloat(total);
                item["actCost"] = parseFloat(parseFloat(total).toFixed(2));
            } else {
                item[fieldName] = parseFloat(parseFloat(total).toFixed(2));
            }
            // item[fieldName] =
            //     fieldName === "stEecost" ? parseFloat(total) : parseFloat(parseFloat(total).toFixed(2));
        }
    });

    return data;
}

// ref : calculate group wise values
export function calculateSumOfListPrices(data, fieldName) {
    const groupedData = {};

    data.forEach((item) => {
        const { groupName, [fieldName]: fieldValue, subGroupName, displaySubGroupName, stStockCode } = item;
        const numericFieldValue =
            fieldName === "stEecost" ||
            fieldName === "stFutureCost" ||
            fieldName === "actCostExact" ||
            fieldName === "lastCostExact" ||
            fieldName !== "LastCost"
                ? parseFloat(fieldValue)
                : parseFloat(parseFloat(isNaN(fieldValue) ? 0 : fieldValue).toFixed(2));

        if (
            groupName &&
            !isNaN(numericFieldValue) &&
            (subGroupName === "" || displaySubGroupName === "") &&
            stStockCode === ""
        ) {
            if (!groupedData[groupName]) {
                groupedData[groupName] = { sumFieldValue: 0 };
            }
            groupedData[groupName].sumFieldValue += numericFieldValue;
        }
    });

    let totalSum = 0;

    for (const groupName in groupedData) {
        const group = groupedData[groupName];
        totalSum += parseFloat(parseFloat(group.sumFieldValue).toFixed(2));
    }

    return groupedData;
}

export function calculateGroupWiseDcOnLp(itemList, rowType) {
    const groupedData = {};

    if (rowType === GroupTypes.GROUP) {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, listPrice, nettEA, stStockCode } = item;

            if (groupName && (subGroupName === "" || displaySubGroupName === "") && stStockCode === "") {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = { listPrice: 0, nettEA: 0, dcOnLp: 0 };
                }
                (groupedData[groupName].listPrice = listPrice), (groupedData[groupName].nettEA = nettEA);
            }
        });

        for (const groupName in groupedData) {
            const group = groupedData[groupName];
            group.dcOnLp = calculateGroupDcOnLp(group.listPrice, group.nettEA);
        }
    } else {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, listPrice, nettEA } = item;

            if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                if (!groupedData[subGroupName]) {
                    groupedData[subGroupName] = { listPrice: 0, nettEA: 0, dcOnLp: 0, groupName: "" };
                }
                (groupedData[subGroupName].listPrice = listPrice),
                    (groupedData[subGroupName].nettEA = nettEA),
                    (groupedData[subGroupName].groupName = groupName);
            }
        });

        for (const subGroupName in groupedData) {
            const subGroup = groupedData[subGroupName];
            subGroup.dcOnLp = calculateGroupDcOnLp(subGroup.listPrice, subGroup.nettEA);
        }
    }

    return groupedData;
}

export function calculateGroupWiseMargin(itemList, rowType) {
    const groupedData = {};

    if (rowType === GroupTypes.GROUP) {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, stEecost, stStockCode } = item;

            if (groupName && (subGroupName === "" || displaySubGroupName === "") && stStockCode === "") {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = { nettEA: 0, stEecost: 0, margin: 0 };
                }
                (groupedData[groupName].nettEA = nettEA), (groupedData[groupName].stEecost = stEecost);
            }
        });

        for (const groupName in groupedData) {
            const group = groupedData[groupName];
            group.margin = calculateMargin(group.nettEA, group.stEecost);
        }
    } else {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, stEecost } = item;

            if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                if (!groupedData[subGroupName]) {
                    groupedData[subGroupName] = {
                        nettEA: 0,
                        stEecost: 0,
                        margin: 0,
                        groupName: "",
                    };
                }
                (groupedData[subGroupName].nettEA = nettEA),
                    (groupedData[subGroupName].stEecost = stEecost),
                    (groupedData[subGroupName].groupName = groupName);
            }
        });

        for (const subGroupName in groupedData) {
            const subGroup = groupedData[subGroupName];
            subGroup.margin = calculateMargin(subGroup.nettEA, subGroup.stEecost);
        }
    }

    return groupedData;
}

export function calculateGroupWiseFrcMargin(itemList, rowType) {
    const groupedData = {};

    if (rowType === GroupTypes.GROUP) {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, stFutureCost, stStockCode } = item;

            if (groupName && (subGroupName === "" || displaySubGroupName === "") && stStockCode === "") {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = { nettEA: 0, stFutureCost: 0, frcMargin: 0 };
                }
                (groupedData[groupName].nettEA = nettEA), (groupedData[groupName].stFutureCost = stFutureCost);
            }
        });

        for (const groupName in groupedData) {
            const group = groupedData[groupName];

            group.frcMargin = calculateFrcMargin(group.nettEA, group.stFutureCost);
        }
    } else {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, stFutureCost } = item;

            if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                if (!groupedData[subGroupName]) {
                    groupedData[subGroupName] = {
                        nettEA: 0,
                        stFutureCost: 0,
                        frcMargin: 0,
                        groupName: "",
                    };
                }
                (groupedData[subGroupName].nettEA = nettEA),
                    (groupedData[subGroupName].stFutureCost = stFutureCost),
                    (groupedData[subGroupName].groupName = groupName);
            }
        });

        for (const subGroupName in groupedData) {
            const subGroup = groupedData[subGroupName];

            subGroup.frcMargin = calculateFrcMargin(subGroup.nettEA, subGroup.stFutureCost);
        }
    }

    return groupedData;
}

export function calculateGroupWiseActMargin(itemList, rowType) {
    const groupedData = {};

    if (rowType === GroupTypes.GROUP) {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, actCostExact, stStockCode } = item;

            if (groupName && (subGroupName === "" || displaySubGroupName === "") && stStockCode === "") {
                if (!groupedData[groupName]) {
                    groupedData[groupName] = { nettEA: 0, actCostExact: 0, actMargin: 0 };
                }
                (groupedData[groupName].nettEA = nettEA), (groupedData[groupName].actCostExact = actCostExact);
            }
        });

        for (const groupName in groupedData) {
            const group = groupedData[groupName];
            group.actMargin = calculateActMargin(group.nettEA, group.actCostExact);
        }
    } else {
        itemList.forEach((item) => {
            const { groupName, subGroupName, displaySubGroupName, nettEA, actCostExact } = item;

            if (groupName !== "" && subGroupName !== "" && displaySubGroupName !== "") {
                if (!groupedData[subGroupName]) {
                    groupedData[subGroupName] = {
                        nettEA: 0,
                        actCostExact: 0,
                        actMargin: 0,
                        groupName: "",
                    };
                }
                (groupedData[subGroupName].nettEA = nettEA),
                    (groupedData[subGroupName].actCostExact = actCostExact),
                    (groupedData[subGroupName].groupName = groupName);
            }
        });

        for (const subGroupName in groupedData) {
            const subGroup = groupedData[subGroupName];
            subGroup.actMargin = calculateActMargin(subGroup.nettEA, subGroup.actCostExact);
        }
    }

    return groupedData;
}

export function calculateGroupDcOnLp(listPrice, nettEA) {
    return parseFloat(parseFloat(listPrice).toFixed(2)) == 0.0
        ? 0.0
        : parseFloat((((listPrice - nettEA) / listPrice) * 100).toFixed(2));
}

export function calculateMargin(groupWiseNettEa, groupWiseCostEaExact) {
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseCostEaExact)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(2)
          );
}

export function calculateFrcMargin(groupWiseNettEa, groupWiseStFutureCost) {
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseStFutureCost)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(2)
          );
}

export function calculateActMargin(groupWiseNettEa, groupWiseActCostExact) {
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  (parseFloat(groupWiseNettEa - parseFloat(groupWiseActCostExact)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(1)
          );
}

export function resetGroupSubGroupRows(items) {
    return items.map((item) => {
        if (item.displayGroupName !== "" && item.stStockCode === "") {
            return {
                ...item,
                listPrice: 0,
                nettEA: 0,
                stEecost: 0,
                stFutureCost: 0,
                stFutureCostRound: 0,
                stEecostRound: 0,
                actCost: 0,
                actCostExact: 0,
                dcOnLp: 0,
                totalCost: 0,
                totalPrice: 0,
                frcMargin: 0,
                actMargin: 0,
                margin: 0,
                lcMargin: "",
            };
        } else if (item.displaySubGroupName !== "" && item.stStockCode === "") {
            return {
                ...item,
                listPrice: 0,
                nettEA: 0,
                stEecost: 0,
                stFutureCost: 0,
                stFutureCostRound: 0,
                stEecostRound: 0,
                actCost: 0,
                actCostExact: 0,
                dcOnLp: 0,
                totalCost: 0,
                totalPrice: 0,
                frcMargin: 0,
                actMargin: 0,
                margin: 0,
                lcMargin: "",
            };
        } else {
            return item;
        }
    });
}

export function calculateItemRepDisc(prevNettEa, updatedNettEa) {
    if (parseFloat(prevNettEa) === 0.0) return 0.0;
    const repDisc = parseFloat((100 - (updatedNettEa / prevNettEa) * 100).toFixed(1));
    return repDisc > 0 ? repDisc : 0.0;
}

export function calculateItemMargin(newNettEa, currentstEecost) {
    if (parseFloat(newNettEa) === 0.0) return 0.0;
    return parseFloat((((newNettEa - currentstEecost) / newNettEa) * 100).toFixed(1));
}

// export function calculateItemDcOnLp(newNettEa, currentListPrice) {
//     if (parseFloat(currentListPrice) === 0.0) return 0.0;
//     const dcOnLp = parseFloat((((currentListPrice - newNettEa) / currentListPrice) * 100).toFixed(1));
//     return dcOnLp > 0 ? dcOnLp : 0.0;
// }

export function calculateItemDcOnLp(newNettEa, currentListPrice) {
    if (!currentListPrice || parseFloat(currentListPrice) === 0.0) return 0.0;
    const dcOnLp = parseFloat((((currentListPrice - newNettEa) / currentListPrice) * 100).toFixed(1));
    return dcOnLp > 0 ? dcOnLp : 0.0;
}

export function calculateItemFrcMargin(newNettEa, currentStFutureCost) {
    if (parseFloat(newNettEa) === 0.0) return 0.0;
    return parseFloat((((newNettEa - currentStFutureCost) / newNettEa) * 100).toFixed(1));
}

export function calculateItemActMargin(newNettEa, actCostExact) {
    if (parseFloat(newNettEa) === 0.0) return 0.0;
    return parseFloat((((newNettEa - actCostExact) / newNettEa) * 100).toFixed(1));
}

export function calculateItemLcMargin(newNettEa, lastCostExact) {
    if (parseFloat(newNettEa) === 0.0) return 0.0;
    return parseFloat((((newNettEa - lastCostExact) / newNettEa) * 100).toFixed(1));
}

//ref : repDisc change related equations
export function calculateNettEaForRepDiscChange(currentNettEa, newRepDisc) {
    return parseFloat(((parseFloat(currentNettEa) * (100 - parseFloat(newRepDisc))) / 100).toFixed(2));
}

//ref: qty change related equationss
//commented by sachini- tofixed issue when changing group qty
// export function calculateItemTotalPrice(updatedQty, nettEA) {
//     return parseFloat((updatedQty * parseFloat(nettEA.toFixed(2))).toFixed(2));
// }
export function calculateItemTotalPrice(updatedQty, nettEA) {
    return parseFloat((updatedQty * parseFloat(nettEA)).toFixed(2));
}

export function calculateItemTotalCost(updatedQty, stEecost) {
    return parseFloat((updatedQty * parseFloat(stEecost)).toFixed(2));
}

//Start - created By Manoj - parts consolidated view - Dc on LP change related equations
export function calculateNettEaForDConLPChange(listPrice, newDConLP) {
    if (!listPrice || !newDConLP) return 0;

    const convertedListPrice = typeof listPrice === "string" ? parseFloat(listPrice) : listPrice;
    const convertedNewDConLP = typeof newDConLP === "string" ? parseFloat(newDConLP) : newDConLP;

    let calculatedValue = parseFloat(listPrice - (convertedListPrice * convertedNewDConLP) / 100).toFixed(2);

    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;

    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);
}

export function calculateNettEaForDConLP(listPrice, newDConLP) {
    const convertedListPrice = typeof listPrice === "string" ? parseFloat(listPrice) : listPrice;
    const convertedNewDConLP = typeof newDConLP === "string" ? parseFloat(newDConLP) : newDConLP;

    const calculatedValue = parseFloat(
        parseFloat(convertedListPrice - (convertedListPrice * convertedNewDConLP) / 100).toFixed(2)
    );
    return calculatedValue <= 0 || isNaN(calculatedValue) ? 0 : parseFloat(calculatedValue);
}

export function calculateTotalPriceForDConLPChange(qty, nettEA) {
    const convertedQty = typeof qty === "string" ? parseFloat(qty) : qty;
    const convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;
    //return parseFloat(parseFloat(convertedQty * convertedNettEA).toFixed(2));
    const calculatedValue = parseFloat(parseFloat(convertedQty * convertedNettEA).toFixed(2));
    return calculatedValue <= 0 || isNaN(calculatedValue) ? 0 : parseFloat(calculatedValue);
}

export function calculateRepDiscForDConLPChange(updatededNetEA, oldNetEA) {
    const convertedUpdatededNetEA = typeof updatededNetEA === "string" ? parseFloat(updatededNetEA) : updatededNetEA;
    const convertedOldNetEA = typeof oldNetEA === "string" ? parseFloat(oldNetEA) : oldNetEA;
    //  return parseFloat(parseFloat(100 - (convertedUpdatededNetEA / convertedOldNetEA) * 100).toFixed(1));
    let calculatedValue = parseFloat(
        parseFloat(100 - (convertedUpdatededNetEA / convertedOldNetEA) * 100).toFixed(1)
    );

    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);
}

export function calculateMarginForDConLPChange(stEecostRound_Exact, nettEA) {
    // if (!stEecostRound_Exact || !nettEA) return "";
    if (!stEecostRound_Exact || !nettEA) return 0;

    let convertedStEecostRoundExact = 0;
    let convertedNettEA = 0;

    if (typeof stEecostRound_Exact === "string" && stEecostRound_Exact === "") return ""; // for handling db extra
    else if (typeof stEecostRound_Exact === "string" && stEecostRound_Exact !== "")
        convertedStEecostRoundExact = parseFloat(stEecostRound_Exact);
    else convertedStEecostRoundExact = stEecostRound_Exact || 0;

    convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    let calculatedValue = parseFloat(
        (((convertedNettEA - convertedStEecostRoundExact) / convertedNettEA) * 100).toFixed(1)
    );

    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 || convertedNettEA <= 0 ? 0 : parseFloat(calculatedValue);
}

export function calculateActMarginForDConLPChange(ActCost_Exact, nettEA) {
    // const convertedActCostExact = typeof ActCost_Exact === "string" ? parseFloat(ActCost_Exact) : ActCost_Exact;
    // const convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    let convertedActCostExact = 0;
    let convertedNettEA = 0;

    if (typeof ActCost_Exact === "string" && ActCost_Exact === "") return ""; // for handling db extra
    else if (typeof ActCost_Exact === "string" && ActCost_Exact !== "")
        convertedActCostExact = parseFloat(ActCost_Exact);
    else convertedActCostExact = ActCost_Exact;

    convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    const calculatedValue = parseFloat(
        parseFloat(((convertedNettEA - convertedActCostExact) / convertedNettEA) * 100).toFixed(1)
    );
    return calculatedValue <= 0 || convertedNettEA <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(parseFloat(((convertedNettEA - convertedActCostExact) / convertedNettEA) * 100).toFixed(2));
}

export function calculateFrcMarginForDConLPChange(stFutureCost, nettEA) {
    // const convertedStFutureCost = typeof stFutureCost === "string" ? parseFloat(stFutureCost) : stFutureCost;
    // const convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    let convertedStFutureCost = 0;
    let convertedNettEA = 0;

    if (typeof stFutureCost === "string" && stFutureCost === "") return ""; // for handling db extra
    else if (typeof stFutureCost === "string" && stFutureCost !== "") convertedStFutureCost = parseFloat(stFutureCost);
    else convertedStFutureCost = stFutureCost;
    convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    const calculatedValue = parseFloat(((convertedNettEA - convertedStFutureCost) / convertedNettEA) * 100).toFixed(1);
    return calculatedValue <= 0 || convertedNettEA <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(((convertedNettEA - convertedStFutureCost) / convertedNettEA) * 100).toFixed(1);
}

export function calculateLcMarginForDConLPChange(LastCost_Exact, nettEA) {
    // const convertedLastCostExact = typeof LastCost_Exact === "string" ? parseFloat(LastCost_Exact) : LastCost_Exact;
    // const convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    let convertedLastCostExact = 0;
    let convertedNettEA = 0;

    if (typeof LastCost_Exact === "string" && LastCost_Exact === "") return ""; // for handling db extra
    else if (typeof LastCost_Exact === "string" && LastCost_Exact !== "")
        convertedLastCostExact = parseFloat(LastCost_Exact);
    else convertedLastCostExact = LastCost_Exact;

    convertedNettEA = typeof nettEA === "string" ? parseFloat(nettEA) : nettEA;

    const calculatedValue = parseFloat(
        parseFloat(((convertedNettEA - convertedLastCostExact) / convertedNettEA) * 100).toFixed(2)
    );
    return calculatedValue <= 0 || convertedNettEA <= 0 ? 0 : parseFloat(calculatedValue);

    //  return parseFloat(parseFloat(((convertedNettEA - convertedLastCostExact) / convertedNettEA) * 100).toFixed(2));
}

//End - created By Manoj - Parts consolidated view - Dc on LP change related equations

//Start - created By Manoj - parts consolidated view - Margin change related equations
export function calculateNettEaForMarginChange(stEecostRound_Exact, Margin) {
    let calculatedValue = parseFloat(parseFloat((stEecostRound_Exact * 100) / (100 - Margin)).toFixed(2));

    // Check if the value is NaN or Infinity
    calculatedValue = isNaN(calculatedValue) || !isFinite(calculatedValue) ? 0 : calculatedValue;
    
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);
}


export function calculateTotalPriceForMarginChange(qty, nettEA) {
    const calculatedValue = parseFloat(parseFloat(qty * nettEA).toFixed(2));
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(parseFloat(qty * nettEA).toFixed(2));
}

export function calculateRepDiscForMarginChange(updatededNetEA, oldNetEA) {
    // RepDisc =100-((updated nettea/old nettea)*100)
    let calculatedValue = parseFloat(parseFloat(100 - (updatededNetEA / oldNetEA) * 100).toFixed(1));
    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //return parseFloat(parseFloat(100 - (updatededNetEA / oldNetEA) * 100).toFixed(1));
}

export function calculateDConLPForMarginChange(ListPrice, updatededNetEA) {
    let calculatedValue = parseFloat(parseFloat(((ListPrice - updatededNetEA) / ListPrice) * 100).toFixed(1));
    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(parseFloat(((ListPrice - updatededNetEA) / ListPrice) * 100).toFixed(1));
}

// export function calculateMarginForMarginChange(stEecostRound_Exact , nettEA ) {

//     return parseFloat((((nettEA - stEecostRound_Exact) / nettEA) * 100).toFixed(2));
// }

export function calculateActMarginForMarginChange(ActCost_Exact, updatededNetEA) {
    let calculatedValue = parseFloat(
        parseFloat(((updatededNetEA - ActCost_Exact) / updatededNetEA) * 100).toFixed(2)
    );
    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //   return parseFloat(parseFloat(((updatededNetEA - ActCost_Exact) / updatededNetEA) * 100).toFixed(2));
}

export function calculateFrcMarginForMarginChange(stFutureCost, updatededNetEA) {
    const calculatedValue = parseFloat(parseFloat(((updatededNetEA - stFutureCost) / updatededNetEA) * 100).toFixed(1));
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(((updatededNetEA - stFutureCost) / updatededNetEA) * 100).toFixed(1);
}

export function calculateLcMarginForMarginChange(LastCost_Exact, updatededNetEA) {
    let calculatedValue = parseFloat(
        parseFloat(((updatededNetEA - LastCost_Exact) / updatededNetEA) * 100).toFixed(2)
    );
    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(parseFloat(((updatededNetEA - LastCost_Exact) / updatededNetEA) * 100).toFixed(2));
}

//End - created By Manoj - Parts consolidated view - Dc on LP change related equations

//Start - created By Manoj - parts consolidated view - NetEE price change related equations
export function calculateNettEaForNetEEPriceChange(stEecostRound_Exact, Margin) {
    const calculatedValue = parseFloat(parseFloat((stEecostRound_Exact * 100) / (100 - Margin)).toFixed(2));
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //  return parseFloat((stEecostRound_Exact * 100) / (100 - Margin)).toFixed(2);
}

export function calculateTotalPriceForNetEEPriceChange(qty, nettEA) {
    const calculatedValue = parseFloat(parseFloat(qty * nettEA).toFixed(2));
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //return parseFloat(parseFloat(qty * nettEA).toFixed(2));
}

export function calculateRepDiscForNetEEPriceChange(updatededNetEA, oldNetEA) {
    // RepDisc =100-((updated nettea/old nettea)*100)

    const repDisc = parseFloat(parseFloat(100 - (updatededNetEA / oldNetEA) * 100).toFixed(2));
    return repDisc > 0 ? repDisc : 0.0;
}

export function calculateDConLPForNetEEPriceChange(ListPrice, updatededNetEA) {
    const dcOnLp = parseFloat(parseFloat(((ListPrice - updatededNetEA) / ListPrice) * 100).toFixed(2));
    return dcOnLp > 0 ? dcOnLp : 0;
}

export function calculateMarginForNetEEPriceChange(stEecostRound_Exact, nettEA) {
    if (stEecostRound_Exact === "" || nettEA === "" || nettEA === 0) return 0; //for db extra
    let calculatedValue = parseFloat((((nettEA - stEecostRound_Exact) / nettEA) * 100).toFixed(2));
    calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
    return parseFloat(calculatedValue);

    //  return parseFloat((((nettEA - stEecostRound_Exact) / nettEA) * 100).toFixed(2));
}

export function calculateActMarginForNetEEPriceChange(ActCost_Exact, updatededNetEA) {
    if (ActCost_Exact === "" || updatededNetEA === "" || updatededNetEA === 0) return 0;
    const calculatedValue = parseFloat(
        parseFloat(((updatededNetEA - ActCost_Exact) / updatededNetEA) * 100).toFixed(2)
    );
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    // return parseFloat(parseFloat(((updatededNetEA - ActCost_Exact) / updatededNetEA) * 100).toFixed(2));
}

export function calculateFrcMarginForNetEEPriceChange(stFutureCost, updatededNetEA) {
    if (stFutureCost === "" || updatededNetEA === "" || updatededNetEA === 0) return 0;
    const calculatedValue = parseFloat(parseFloat(((updatededNetEA - stFutureCost) / updatededNetEA) * 100).toFixed(1));
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //return parseFloat(parseFloat(((updatededNetEA - stFutureCost) / updatededNetEA) * 100).toFixed(1));
}

export function calculateLcMarginForNetEEPriceChange(LastCost_Exact, updatededNetEA) {
    if (LastCost_Exact === "" || updatededNetEA === "" || updatededNetEA === 0) return 0;
    const calculatedValue = parseFloat(
        parseFloat(((updatededNetEA - LastCost_Exact) / updatededNetEA) * 100).toFixed(2)
    );
    return calculatedValue <= 0 ? 0 : parseFloat(calculatedValue);

    //  return parseFloat(parseFloat(((updatededNetEA - LastCost_Exact) / updatededNetEA) * 100).toFixed(2));
}
//End - created By Manoj - Parts consolidated view - Dc on LP change related equations

//SBL -> Sub Group Level
export function calculateValuesOnSBLQtyChange(qty, value) {
    return parseFloat(qty * value);
}

//start - created by sachini - Edit Quote Price -related calculations
function calculateTotalPrice(items) {
    return items.reduce((totalPrice, item) => {
        if (item.stStockCode !== "" && item.totalPrice) {
            totalPrice += parseFloat(item.totalPrice);
        }
        return totalPrice;
    }, 0);
}

export function calculateItemWisePropotionalTotalPrice(items, newPrice) {
    
    const filteredItems = items.filter((item) =>  item.rowType !== "AI" && item.rowType !== "G" && item.rowType !== "SG" && item.rowType !== "");

    let oldPrice = calculateTotalPrice(filteredItems);
    oldPrice = parseFloat(oldPrice);
    
    const oldDX = filteredItems.filter((f)=>f.rowType === "DX").reduce((totalPrice, item) => {
        totalPrice += parseFloat(item.totalPrice);
        return totalPrice;
    }, 0);
    const newDX = parseFloat((oldDX / oldPrice) * newPrice).toFixed(2);

    let newSetPriceWODX = parseFloat(newPrice) - parseFloat(newDX);
    let oldPriceWODX = oldPrice - oldDX;
    let refItems = filteredItems.filter((f)=>f.rowType !== "DX");

    const updatedList = calculateNewTotalPrice(refItems, parseFloat(newSetPriceWODX), oldPriceWODX);
    const updatedList2 = calculateItemWiseNettEAUsingNewTotalPrice(updatedList);
    const updatedList3 = calculateItemWiseDcOnLpUsingNewTotalPrice(updatedList2);
    const updatedList4 = calculateItemWiseMarginUsingNewTotalPrice(updatedList3);
    const updatedList5 = calculateItemWiseActMarginUsingNewTotalPrice(updatedList4);
    const updatedList6 = calculateItemWiseFrcMarginUsingNewTotalPrice(updatedList5);
    const FinalUpdatedList = calculateItemWiseLcMarginUsingNewTotalPrice(updatedList6);

    return FinalUpdatedList;
    
    // const updated = items.map((item) => {
    //     let newTotalPrice = 0;
    //     let newNettEA = 0;
    //     let newRepDisc = 0;
    //     let newDcOnLp = 0;
    //     let newMargin = 0;
    //     let newActMargin = 0;
    //     let newFrcMargin = 0;
    //     let newLcMargin = 0;

    //     if (item.rowType === "DX") {
    //         if (item.stStockCode && item.totalPrice) {
    //             newTotalPrice = parseFloat(parseFloat((newPrice * item.totalPrice) / oldPrice).toFixed(2));
    //         }

    //         if (item.totalPrice && item.qty && item.nettEA) {
    //             newNettEA = parseFloat(parseFloat(item.totalPrice / item.qty).toFixed(2));
    //         }

    //         return {
    //             ...item,
    //             totalPrice: newNettEA,
    //             nettEA: newNettEA,
    //         };
    //     } else {
    //         if (item.stStockCode && item.totalPrice) {
    //             newTotalPrice = parseFloat(parseFloat((newPrice * item.totalPrice) / oldPrice).toFixed(2));
    //         }

    //         if (item.totalPrice && item.qty && item.nettEA) {
    //             newNettEA = parseFloat(parseFloat(item.totalPrice / item.qty).toFixed(2));
    //             newRepDisc = parseFloat(parseFloat(100 - (newNettEA / item.nettEA) * 100).toFixed(2));
    //         }

    //         if (item.ListPrice && item.nettEA) {
    //             newDcOnLp = parseFloat(parseFloat(((item.ListPrice - item.nettEA) / item.ListPrice) * 100).toFixed(1));
    //         }

    //         if (item.stEecost && item.nettEA) {
    //             newMargin = parseFloat(parseFloat(((item.nettEA - item.stEecost) / item.nettEA) * 100).toFixed(1));
    //         }

    //         if (item.actCostExact && item.nettEA) {
    //             newActMargin = parseFloat(
    //                 parseFloat(((item.nettEA - item.actCostExact) / item.nettEA) * 100).toFixed(1)
    //             );
    //         }

    //         if (item.stFutureCost && item.nettEA) {
    //             newFrcMargin = parseFloat(
    //                 parseFloat(((item.nettEA - item.stFutureCost) / item.nettEA) * 100).toFixed(1)
    //             );
    //         }

    //         if (item.lastCostExact && item.nettEA) {
    //             newLcMargin = parseFloat(
    //                 parseFloat(((item.nettEA - item.lastCostExact) / item.nettEA) * 100).toFixed(1)
    //             );
    //         }

    //         return {
    //             ...item,
    //             totalPrice: newTotalPrice,
    //             nettEA: newNettEA,
    //             repDisc: newRepDisc,
    //             dcOnLp: newDcOnLp,
    //             margin: newMargin,
    //             actMargin: newActMargin,
    //             frcMargin: newFrcMargin,
    //             lcMargin: newLcMargin,
    //         };
    //     }
    // });

    // return updated;
}

export function calculateNewTotalPrice(items, newPrice, oldPrice) {
    const totalPriceList = items.map((item) => {
        if (item.stStockCode && item.totalPrice) {
            const newTotalPrice = parseFloat(parseFloat((newPrice * item.totalPrice) / oldPrice).toFixed(2));
            return {
                ...item,
                totalPrice: newTotalPrice,
            };
        } else {
            return item;
        }
    });

    return totalPriceList;
}


export function calculateItemWiseNettEAUsingNewTotalPrice(updatedList) {
    const updatedListWithNewNettEA = updatedList.map((item) => {
        if (item.totalPrice && item.qty && item.nettEA) {
            const newNettEA = parseFloat(parseFloat(item.totalPrice / item.qty).toFixed(2));
            const newRepDisc = parseFloat(parseFloat(100 - (newNettEA / item.nettEA) * 100).toFixed(2));
            return {
                ...item,
                nettEA: newNettEA,
                repDisc: newRepDisc < 0 ? 0 : newRepDisc,
            };
        } else {
            return item;
        }
    });

    return updatedListWithNewNettEA;
}

export function calculateItemWiseDcOnLpUsingNewTotalPrice(updatedList2) {
    const updatedListWithDcOnLp = updatedList2.map((item) => {
        if (item.ListPrice && item.nettEA) {
            const NewDcOnLp = parseFloat(
                parseFloat(((item.ListPrice - item.nettEA) / item.ListPrice) * 100).toFixed(1)
            );
            return {
                ...item,
                nettEA: NewDcOnLp,
            };
        } else {
            return item;
        }
    });

    return updatedListWithDcOnLp;
}

export function calculateItemWiseMarginUsingNewTotalPrice(updatedList3) {
    const updatedListWithMargin = updatedList3.map((item) => {
        if (item.stEecost && item.nettEA) {
            const NewMargin = parseFloat(parseFloat(((item.nettEA - (item.rowType === "A" ? item.stEecostRound : item.stEecost)) / item.nettEA) * 100).toFixed(1));
            return {
                ...item,
                margin: NewMargin,
            };
        } else {
            return item;
        }
    });

    return updatedListWithMargin;
}

export function calculateItemWiseActMarginUsingNewTotalPrice(updatedList4) {
    const updatedListWithActMargin = updatedList4.map((item) => {
        if (item.actCostExact && item.nettEA) {
            const NewActMargin = parseFloat(
                parseFloat(((item.nettEA - (item.rowType === "A" ? item.stEecostRound : item.actCostExact)) / item.nettEA) * 100).toFixed(1)
            );
            return {
                ...item,
                actMargin: NewActMargin,
            };
        } else {
            return item;
        }
    });

    return updatedListWithActMargin;
}

export function calculateItemWiseFrcMarginUsingNewTotalPrice(updatedList5) {
    const updatedListWithFrcMargin = updatedList5.map((item) => {
        if (item.stFutureCost && item.nettEA) {
            const NewFrcMargin = parseFloat(
                parseFloat(((item.nettEA - (item.rowType === "A" ? item.stEecostRound : item.stFutureCost)) / item.nettEA) * 100).toFixed(1)
            );
            return {
                ...item,
                frcMargin: NewFrcMargin,
            };
        } else {
            return item;
        }
    });

    return updatedListWithFrcMargin;
}

export function calculateItemWiseLcMarginUsingNewTotalPrice(frcMarginList) {
    const updatedFrcMarginList = frcMarginList.map((item) => {
        if (item.lastCostExact && item.nettEA) {
            const NewLcMargin = parseFloat(
                parseFloat(((item.nettEA - item.lastCostExact) / item.nettEA) * 100).toFixed(1)
            );
            return {
                ...item,
                lcMargin: NewLcMargin,
            };
        }
        return item;
    });

    return updatedFrcMarginList;
}

//End - created by sachini - Edit Quote Price -related calculations

export function calculateProportion(partValue, previousTotalValue) {
    if (previousTotalValue === 0) {
        return 0;
    }
    return parseFloat(partValue / previousTotalValue);
}

export function calculatePropotionalNettEA(propotion, updatedSubGroupNettEa) {
    return parseFloat((propotion * updatedSubGroupNettEa).toFixed(2));
}

export function calculateGroupWiseTotal(itemList, field) {
    const groupTotal = itemList.reduce(
        (total, item) =>
            total +
            (typeof item[field] !== "string"
                ? item[field]
                : typeof item[field] == "string" && item[field].includes("%")
                ? parseFloat(item[field].replace("%", ""))
                : 0),
        0
    );
    if (field === "stEecost" || field === "stFutureCost" || field === "actCostExact") {
        return parseFloat(groupTotal);
    } else {
        return parseFloat(parseFloat(groupTotal).toFixed(2));
    }
}

//GROUP / SUB GROUP MARGIN RELATED CALCULATIONS
export function calculateItemNettEAfromMargin(itemStEeCost, updatedMargin) {
    return parseFloat(((itemStEeCost * 100) / (100 - updatedMargin)).toFixed(2));
}

export function calculateGroupLevelNettEAFromMargin(groupWiseStEeCost, updatedMargin) {
    return parseFloat(((groupWiseStEeCost / (100 - updatedMargin)) * 100).toFixed(2));
}

export function getListWithQtyBasedNettEA(items) {
    return items.map((item) => {
        return {
            ...item,
            nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
        };
    });
}

export function getSubGroupsBySubGroups(items) {
    const groups = getAllGroupRows(items);

    let groupWiseSubGroups = {};

    groups.forEach((group) => {
        const subGroups = getAllSubGroupRows(items, group.groupId);
        const subGroupIds = subGroups.map((subGroup) => {
            return subGroup.subGroupId;
        });
        groupWiseSubGroups[group.groupId] = subGroupIds;
    });

    return groupWiseSubGroups;
}

export function gridCalculation(items, dbExtraCharge) {
    let updatedItemList = [];
    const groups = getAllGroupRows(items);

    //further optimise by updating the group rows only once the sub group rows are updated, not on every sub group row update
    if (groups.length > 0) {
        groups.forEach((group) => {
            const groupId = group.groupId;
            const subGroups = getAllSubGroupRows(items, groupId);

            if (subGroups.length > 0) {
                subGroups.forEach((subGroup) => {
                    const subGroupId = subGroup.subGroupId;
                    let subGroupDbExtra = isDbExtraAdded(items, groupId, subGroupId, GroupTypes.SUB_GROUP);
                    let updatedNettEA = 0;
                    let groupDbExtra = 0;

                    //check if there is any dbExtra added for the sub group
                    if (subGroupDbExtra) {
                        const subGroupDXIndex = getDbExtraRowIndex(items, groupId, subGroupId, GroupTypes.SUB_GROUP);
                        let dXRow = items[subGroupDXIndex];
                        let listWithoutDX = [];

                        listWithoutDX = getItemsWithoutDBExtra(items, GroupTypes.SUB_GROUP, groupId, subGroupId);
                        listWithoutDX = getListWithQtyBasedNettEA(listWithoutDX);

                        const subGroupNettEA = calculateGroupWiseTotal(listWithoutDX, "nettEA");
                        updatedNettEA = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                        dXRow = {
                            ...dXRow,
                            nettEA: updatedNettEA,
                            totalPrice: updatedNettEA,
                        };

                        items[subGroupDXIndex] = dXRow;

                        //group db extra calculations
                        let groupDxRow = getExistingGroupWiseDbExtraRow(items, groupId)[0];

                        if (groupDxRow) {
                            const groupItems = getAllItemsInAGroup(items, groupId);
                            const groupDXIndex = getDbExtraRowIndex(items, groupId, null, GroupTypes.GROUP);
                            const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                            groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);
                            groupDxRow = {
                                ...groupDxRow,
                                nettEA: groupDbExtra,
                                totalPrice: groupDbExtra,
                            };

                            items[groupDXIndex] = groupDxRow;
                        } else {
                            //if there is no dbExtra added for the sub group
                            //check if there is any dbExtra added for the group
                            let groupDxRow = getExistingGroupWiseDbExtraRow(items, groupId)[0];

                            if (groupDxRow) {
                                const groupItems = getAllItemsInAGroup(items, groupId);
                                const groupDXIndex = getDbExtraRowIndex(items, groupId, null, GroupTypes.GROUP);
                                const groupNettEA = calculateGroupWiseTotal(groupItems, "nettEA");
                                groupDbExtra = calculateDbExtra(dbExtraCharge, groupNettEA);
                                groupDxRow = {
                                    ...groupDxRow,
                                    nettEA: groupDbExtra,
                                    totalPrice: groupDbExtra,
                                };

                                items[groupDXIndex] = groupDxRow;
                            }
                        }
                    }

                    //to persist the group values(list price, act Cost, nettEA etc) when the qty is changed
                    const subGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
                    const subGroupItemsWithoutAI = items.filter((f) => f.rowType !== "AI");
                    const subGroupItems = getAllExistingSubGroupedItems(subGroupItemsWithoutAI, groupId, subGroupId);
                    const hasQtyChangedInSubGroups = subGroupRows.some((item) => item.qty > 1);

                    let rowList = [];
                    let qtyUnchangedSubGroups = [];

                    if (hasQtyChangedInSubGroups) {
                        qtyUnchangedSubGroups = subGroupRows.filter((item) => item.qty === 1);
                    }

                    if (hasQtyChangedInSubGroups) {
                        const subGroupRow = getSubGroupRowByGroupAndSubGroup(items, groupId, subGroupId)[0];
                        let subGroupRowIndex = -1;
                        let updatedQty = 1;
                        let updatedSubGroupRow = null;

                        if (subGroupRow.length > 0) {
                            subGroupRowIndex = getSubGroupRowIndex(items, groupId, subGroupId);
                            updatedQty = subGroupRow.qty;
                        }

                        let updatedSGTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                        let updatedSGNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        let updatedSGActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        let updatedSGstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        let updatedSGStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        let updatedSGListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");

                        updatedSubGroupRow = {
                            ...subGroupRow,
                            qty: updatedQty,
                            totalCost: calculateItemTotalCost(updatedQty, updatedSGstEecost),
                            totalPrice: updatedSGTotalPrice * updatedQty,
                            nettEA: updatedSGNettEA,
                            actCostExact: updatedSGActCostExact,
                            stEecost: updatedSGstEecost,
                            stFutureCost: updatedSGStFutureCost,
                            listPrice: updatedSGListPrice,
                            actCost: parseFloat(parseFloat(updatedSGActCostExact).toFixed(2)),
                            stEecostRound: parseFloat(parseFloat(updatedSGstEecost).toFixed(2)),
                            stFutureCostRound: parseFloat(parseFloat(updatedSGStFutureCost).toFixed(2)),
                        };

                        items[subGroupRowIndex] = updatedSubGroupRow;

                        //group row calculations
                        let groupRow = getGroupRowByGroup(items, groupId)[0];
                        let groupRowIndex = getGroupRowIndex(items, groupId);
                        let subGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
                        let groupRowItems = getGroupedRowItems(items, groupId);

                        let updatedGroupRow = null;
                        let subGroupsWithoutCurrent = subGroupRows.filter((item) => item.subGroupId !== subGroupId);
                        let updatedSubGroupsWithoutCurrent = subGroupsWithoutCurrent.map((item) => {
                            if (item.qty > 1) {
                                return {
                                    ...item,
                                    listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                                    actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                                    nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                                    stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                                    stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                                    actCost: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(
                                            2
                                        )
                                    ),
                                    stEecostRound: parseFloat(
                                        parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                                    ),
                                    stFutureCostRound: parseFloat(
                                        parseFloat(
                                            calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound)
                                        ).toFixed(2)
                                    ),
                                };
                            }
                            return item;
                        });

                        const concatList = [...updatedSubGroupsWithoutCurrent, ...groupRowItems];

                        let gTotalCost = calculateGroupWiseTotal(concatList, "totalCost");
                        let gTotalPrice = calculateGroupWiseTotal(concatList, "totalPrice");
                        let gNettEA = calculateGroupWiseTotal(concatList, "nettEA");
                        let gActCostExact = calculateGroupWiseTotal(concatList, "actCostExact");
                        let gstEecost = calculateGroupWiseTotal(concatList, "stEecost");
                        let gStFutureCost = calculateGroupWiseTotal(concatList, "stFutureCost");
                        let gListPrice = calculateGroupWiseTotal(concatList, "listPrice");

                        //quantity changed group calculations
                        const hasGroupQtyChanged = groupRow.qty > 1;
                        updatedGroupRow = {
                            ...groupRow,
                            totalCost: hasGroupQtyChanged
                                ? parseFloat(
                                      (
                                          (calculateItemTotalCost(groupRow.qty, gstEecost) +
                                              updatedSubGroupRow.totalCost) *
                                          groupRow.qty
                                      ).toFixed(2)
                                  )
                                : parseFloat(
                                      (
                                          calculateItemTotalCost(groupRow.qty, gstEecost) + updatedSubGroupRow.totalCost
                                      ).toFixed(2)
                                  ),
                            totalPrice: hasGroupQtyChanged
                                ? parseFloat(((gTotalPrice + updatedSubGroupRow.totalPrice) * groupRow.qty).toFixed(2))
                                : parseFloat((gTotalPrice + updatedSubGroupRow.totalPrice).toFixed(2)),
                            nettEA: parseFloat((gNettEA + updatedSubGroupRow.nettEA * updatedQty).toFixed(2)),
                            actCostExact: gActCostExact + updatedSubGroupRow.actCostExact * updatedQty,
                            stEecost: gstEecost + updatedSubGroupRow.stEecost * updatedQty,
                            stFutureCost: gStFutureCost + updatedSubGroupRow.stFutureCost * updatedQty,
                            listPrice: parseFloat((gListPrice + updatedSubGroupRow.listPrice * updatedQty).toFixed(2)),
                            actCost: parseFloat(
                                parseFloat(gActCostExact + updatedSubGroupRow.actCostExact * updatedQty).toFixed(2)
                            ),
                            stEecostRound: parseFloat(
                                parseFloat(gstEecost + +(updatedSubGroupRow.stEecost * updatedQty)).toFixed(2)
                            ),
                            stFutureCostRound: parseFloat(
                                parseFloat(gStFutureCost + +(updatedSubGroupRow.stFutureCost * updatedQty)).toFixed(2)
                            ),
                        };

                        items[groupRowIndex] = updatedGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(items);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(items, nonGroupId, null, GroupTypes.NON_GROUP);

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(items, dbExtraCharge);
                                items[dbExtraRowIndex] = dbExtraRow;
                            }
                        }

                        updatedItemList = [...items];
                    } else {
                        let subGroupMargin = -1;
                        let subGroupFrcMargin = -1;
                        let subGroupActMargin = -1;
                        let subGroupDbExtraValue = 0;

                        // let subGroupItems = getAllExistingSubGroupedItems(subGroupItemsWithoutAI, groupId, subGroupId);

                        let subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
                        let subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
                        let subGroupNettEANonAI = calculateGroupWiseTotal(
                            subGroupItems.filter((f) => f.rowType !== "A" && f.rowType !== "AI"),
                            "nettEA"
                        );
                        const subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
                        let subGroupstEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
                        let subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
                        let subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
                        // const totalDcOnLp = calculateItemDcOnLp(subGroupNettEANonAI, subGroupListPrice);
                        let totalDcOnLp = calculateItemDcOnLp(subGroupNettEANonAI, subGroupListPrice);
                        subGroupDbExtraValue = calculateDbExtra(dbExtraCharge, subGroupNettEA);

                        if (subGroupDbExtra) {
                            const nonAIAndNonDXItems = subGroupItems.filter(
                                (i) => i.rowType !== "AI" && i.rowType !== "DX"
                            );
                            subGroupNettEA = calculateGroupWiseTotal(nonAIAndNonDXItems, "nettEA");
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(
                                //subGroupNettEA - groupDbExtra,
                                subGroupNettEA,
                                subGroupstEecost
                            );
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                //subGroupNettEA - groupDbExtra,
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                // subGroupNettEA - groupDbExtra,
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        } else {
                            subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupstEecost);
                            subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupStFutureCost
                            );
                            subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                subGroupNettEA,
                                subGroupActCostExact
                            );
                        }

                        const subgrpItemsCostUpdate1 = subGroupItems.map((item) => {
                            if (item.rowType === "A" || item.rowType === "S") {
                                return {
                                    ...item,
                                    listPrice: item.listPrice * item.qty,
                                    stEecostRound: item.stEecostRound * item.qty,
                                    actCost: item.actCost * item.qty,
                                    actCostExact: item.actCostExact * item.qty,
                                    stFutureCostRound: item.stFutureCostRound * item.qty,
                                    stEecost: item.stEecost * item.qty,
                                    stFutureCost: item.stFutureCost * item.qty,
                                    nettEA: item.nettEA * item.qty,
                                };
                            } else return item;
                        });

                        subGroupListPrice = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "listPrice");
                        subGroupstEecost = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "stEecost");
                        subGroupStFutureCost = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "stFutureCost");
                        subGroupActCostExact = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "actCostExact");
                        subGroupNettEA = calculateGroupWiseTotal(subgrpItemsCostUpdate1, "nettEA");

                        const emptySubGroupRow = getSubGroupRowByGroupAndSubGroup(items, groupId, subGroupId)[0];
                        const subGroupIndex = getSubGroupRowIndex(items, groupId, subGroupId);
                        let updatedEmptySubGroupRow = null;

                        if (emptySubGroupRow) {
                            updatedEmptySubGroupRow = {
                                ...emptySubGroupRow,
                                listPrice: subGroupListPrice,
                                nettEA: subGroupNettEA,
                                totalPrice: subGroupTotalPrice,
                                dcOnLp: totalDcOnLp,
                                stEecostRound: parseFloat(subGroupstEecost.toFixed(2)),
                                stFutureCost: subGroupStFutureCost,
                                stFutureCostRound: parseFloat(subGroupStFutureCost.toFixed(2)),
                                qty: emptySubGroupRow.qty ? emptySubGroupRow.qty : 1,
                                margin: subGroupMargin,
                                frcMargin: subGroupFrcMargin,
                                actMargin: subGroupActMargin,
                                totalCost: calculateItemTotalCost(emptySubGroupRow.qty, subGroupstEecost),
                                stEecost: subGroupstEecost,
                                actCost: parseFloat(subGroupActCostExact.toFixed(2)),
                                actCostExact: subGroupActCostExact,
                            };

                            items[subGroupIndex] = updatedEmptySubGroupRow;
                        }
                        //ref : for group calculations
                        const updatedSubGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
                        const groupRows = getGroupedRowItems(items, groupId);
                        const emptyGroupRow = getGroupRowByGroup(items, groupId);
                        const emptyGroupRowIndex = getGroupRowIndex(items, groupId);
                        const nonsubGroupItems = getAllExistingNonSubGroupedItems(subGroupItems, groupId, subGroupId);
                        const subGrpItemsCombined = [...groupRows, ...nonsubGroupItems];

                        hasQtyChangedInSubGroups
                            ? (rowList = [...emptyGroupRow, ...qtyUnchangedSubGroups, ...subGrpItemsCombined])
                            : (rowList = [...updatedSubGroupRows, ...subGrpItemsCombined]);

                        //check for group level db extra
                        const groupLevelDbExtra = isDbExtraAdded(items, groupId, null, GroupTypes.GROUP);

                        let groupMargin = -1;
                        let groupFrcMargin = -1;
                        let groupActMargin = -1;

                        const groupTotalAssemblyNettEA = items.filter(
                            (f) => f.groupId === groupId && f.rowType === "A"
                        );
                        const totalNettEAAssembly = groupTotalAssemblyNettEA.reduce(
                            (total, item) => total + item.nettEA,
                            0
                        );

                        const totalQtyAssembly = groupTotalAssemblyNettEA.reduce((total, item) => total + item.qty, 0);
                        const groupListPrice = calculateGroupWiseTotal(rowList, "listPrice");
                        const groupNettEA = calculateGroupWiseTotal(rowList, "nettEA");
                        const groupTotalPrice = calculateGroupWiseTotal(rowList, "totalPrice");
                        let groupstEecost = calculateGroupWiseTotal(rowList, "stEecost");
                        let groupStFutureCost = calculateGroupWiseTotal(rowList, "stFutureCost");
                        let groupActCostExact = calculateGroupWiseTotal(rowList, "actCostExact");

                        const groupDcOnLp = calculateItemDcOnLp(
                            parseFloat(groupNettEA) - parseFloat(totalNettEAAssembly) * totalQtyAssembly,
                            groupListPrice
                        );

                        if (groupLevelDbExtra) {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupstEecost
                            );
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA - groupDbExtra,
                                groupActCostExact
                            );
                        } else {
                            groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                            groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                groupNettEA,
                                groupStFutureCost
                            );
                            groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                groupNettEA,
                                groupActCostExact
                            );
                        }

                        const hasGroupQtyChanged =
                            emptySubGroupRow.length > 0
                                ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty > 1
                                    ? true
                                    : false
                                : false;

                        const updatedEmptyGroupRow = {
                            ...emptyGroupRow[0],
                            listPrice: groupListPrice,
                            nettEA: groupNettEA,
                            totalPrice: hasGroupQtyChanged
                                ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty * groupTotalPrice
                                : groupTotalPrice,
                            dcOnLp: groupDcOnLp,
                            stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                            stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                            stFutureCost: groupStFutureCost,
                            qty: emptySubGroupRow.length > 0 ? emptyGroupRow.length > 0 && emptyGroupRow[0].qty : 1,
                            margin: groupMargin,
                            actMargin: groupActMargin,
                            frcMargin: groupFrcMargin,
                            totalCost:
                                emptyGroupRow.length > 0 && calculateItemTotalCost(emptyGroupRow[0].qty, groupstEecost),
                            stEecost: groupstEecost,
                            actCost: parseFloat(groupActCostExact.toFixed(2)),
                            actCostExact: groupActCostExact,
                        };

                        items[emptyGroupRowIndex] = updatedEmptyGroupRow;

                        // non group db extra calculation
                        const nonGroupItems = getExistingNonGroupedItems(items);

                        if (nonGroupItems.length > 0) {
                            const nonGroupId = nonGroupItems[0].groupId;
                            const nonGroupDbExtra = isDbExtraAdded(items, nonGroupId, null, GroupTypes.NON_GROUP);

                            if (nonGroupDbExtra) {
                                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(items, dbExtraCharge);
                                items[dbExtraRowIndex] = dbExtraRow;
                            }
                        }
                        //discuss with John
                        const groupItmStat = items.filter((f) => f.groupName.includes("Non Grouped"));

                        if (groupItmStat.length === 0) {
                            const groupRow = getGroupRowByGroup(items, groupId);
                            const groupRowItems = getGroupedRowItems(items, groupId);
                            const subGroupRows = getAllExistingSubGroupsByGroup(items, groupId);
                            const nonGroupRowItems = getAllExistingNonSubGroupedItems(items, groupId, subGroupId);
                            const updatedGroupRowItems = getQtyBasedValues(groupRowItems);

                            // const updatedGroupRowItems = groupRowItems.map((item) => {
                            //     if (item.rowType === "A" || item.rowType === "S") {
                            //         return {
                            //             ...item,
                            //             listPrice: item.listPrice,
                            //             nettEA: item.nettEA * item.qty,
                            //             totalPrice: item.totalPrice,
                            //             dcOnLp: item.dcOnLp,
                            //             stEecostRound: item.stEecostRound * item.qty,
                            //             stFutureCost: item.stFutureCost * item.qty,
                            //             stFutureCostRound: item.stFutureCostRound * item.qty,
                            //             qty: item.qty,
                            //             margin: item.margin,
                            //             frcMargin: item.frcMargin,
                            //             actMargin: item.actMargin,
                            //             totalCost: item.totalCost,
                            //             stEecost: item.stEecost * item.qty,
                            //             actCost: item.actCost * item.qty,
                            //             actCostExact: item.actCostExact * item.qty,
                            //         };
                            //     }
                            //     return item;
                            // });

                            const aIRemovedGRList = updatedGroupRowItems.filter((f) => f.rowType !== "AI");
                            const groupItems = [...subGroupRows, ...nonGroupRowItems, ...aIRemovedGRList]; // includes the non sub grouped items

                            // group re-calculation
                            let groupstEecostUpdated = calculateGroupWiseTotal(groupItems, "stEecost");
                            let groupStFutureCostUpdated = calculateGroupWiseTotal(groupItems, "stFutureCost");
                            let groupActCostExactUpdated = calculateGroupWiseTotal(groupItems, "actCostExact");
                            let groupTotalPrice = calculateGroupWiseTotal(groupItems, "totalPrice");

                            let groupItems2 = [...subGroupRows, ...nonGroupRowItems, ...updatedGroupRowItems]; // includes the non sub grouped items

                            let groupNettEA = calculateGroupWiseTotal(groupItems2, "nettEA");
                            let groupListPrice = calculateGroupWiseTotal(groupItems2, "listPrice");
                            let groupMargin = 0;
                            let groupFrcMargin = 0;
                            let groupActMargin = 0;

                            if (subGroupRows.length > 1) {
                                groupMargin = calculateGroupWiseMarginForSNumberChange(
                                    groupNettEA,
                                    groupstEecostUpdated
                                );
                                groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                    groupNettEA,
                                    groupStFutureCostUpdated
                                );
                                groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                    groupNettEA,
                                    groupActCostExactUpdated
                                );
                            } else if (groupLevelDbExtra && !subGroupDbExtra) {
                                groupMargin = items[emptyGroupRowIndex].margin;
                                groupFrcMargin = items[emptyGroupRowIndex].frcMargin;
                                groupActMargin = items[emptyGroupRowIndex].actMargin;
                            } else if (groupLevelDbExtra && subGroupDbExtra) {
                                groupMargin = calculateGroupWiseMarginForSNumberChange(
                                    groupNettEA - groupDbExtra - subGroupDbExtraValue,
                                    groupstEecostUpdated
                                );
                                groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                                    groupNettEA - groupDbExtra - subGroupDbExtraValue,
                                    groupStFutureCostUpdated
                                );
                                groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                                    groupNettEA - groupDbExtra - subGroupDbExtraValue,
                                    groupActCostExactUpdated
                                );
                            }                            
                            else {
                                groupMargin = subGroupRows[0].margin;
                                groupFrcMargin = subGroupRows[0].frcMargin;
                                groupActMargin = subGroupRows[0].actMargin;
                            }

                            const updatedGroupRow = {
                                ...groupRow[0],
                                stEecost: groupstEecostUpdated,
                                stEecostRound: parseFloat(groupstEecostUpdated.toFixed(2)),
                                stFutureCost: groupStFutureCostUpdated,
                                stFutureCostRound: parseFloat(groupStFutureCostUpdated.toFixed(2)),
                                actCostExact: groupActCostExactUpdated,
                                actCost: parseFloat(groupActCostExactUpdated.toFixed(2)),
                                nettEA: groupNettEA,
                                totalPrice: groupTotalPrice,
                                totalCost: calculateItemTotalCost(groupRow[0].qty, groupstEecostUpdated),
                                margin: groupMargin,
                                frcMargin: groupFrcMargin,
                                actMargin: groupActMargin,
                                listPrice: groupListPrice,
                            };

                            items[emptyGroupRowIndex] = updatedGroupRow;
                            updatedItemList = [...items];
                        }
                    }
                });
            } else {
                
                const groupDbExtra = isDbExtraAdded(items, groupId, null, GroupTypes.GROUP);
                let updatedNettEA;
                let listWithoutDbExtra = [];

                if (groupDbExtra) {
                    const dbExtraIndex = getDbExtraRowIndex(items, groupId, null, GroupTypes.GROUP);
                    listWithoutDbExtra = getItemsWithoutDBExtra(items, GroupTypes.GROUP, groupId);
                    listWithoutDbExtra = getListWithQtyBasedNettEA(listWithoutDbExtra);
                    const totalNettEA = calculateGroupWiseTotal(listWithoutDbExtra, "nettEA");
                    updatedNettEA = calculateDbExtra(dbExtraCharge, totalNettEA);
                    const dbExtraRow = items[dbExtraIndex];

                    const updatedDbExtraRow = {
                        ...dbExtraRow,
                        nettEA: updatedNettEA,
                        totalPrice: updatedNettEA,
                    };

                    items[dbExtraIndex] = updatedDbExtraRow;
                }

                const groupRows = getGroupedRowItems(items, groupId);

                let groupMargin = -1;
                let groupFrcMargin = -1;
                let groupActMargin = -1;

                //#region  Added by john 11/13/2023: To included non sub group item for computation
                let groupRowsUpdated = groupRows.filter((f) => f.rowType !== "AI");
                groupRowsUpdated = groupRowsUpdated.map((item) => {
                    if (item.qty > 1) {
                        return {
                            ...item,
                            listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                            stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                            actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                            stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                            actCost: parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact).toFixed(2)),
                            stEecostRound: parseFloat(
                                calculateValuesOnSBLQtyChange(item.qty, item.stEecost).toFixed(2)
                            ),
                            stFutureCostRound: parseFloat(
                                calculateValuesOnSBLQtyChange(item.qty, item.stFutureCostRound).toFixed(2)
                            ),
                            nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                        };
                    }
                    return item;
                });
                const nonsubGroupItems = getAllExistingNonSubGroupedItems(groupRowsUpdated, groupId);
                const grpItemsCombined = [...groupRowsUpdated, ...nonsubGroupItems];
                //#endregion

                const groupListPrice = calculateGroupWiseTotal(grpItemsCombined, "listPrice");
                const groupNettEA = calculateGroupWiseTotal(grpItemsCombined, "nettEA");
                const groupNettEANonAI = calculateGroupWiseTotal(
                    grpItemsCombined.filter((f) => f.rowType !== "A" && f.rowType !== "AI"),
                    "nettEA"
                );
                const groupTotalPrice = calculateGroupWiseTotal(grpItemsCombined, "totalPrice");
                const groupstEecost = calculateGroupWiseTotal(grpItemsCombined, "stEecost");
                const groupStFutureCost = calculateGroupWiseTotal(grpItemsCombined, "stFutureCost");
                const groupActCostExact = calculateGroupWiseTotal(grpItemsCombined, "actCostExact");
                const groupTotalCost = calculateGroupWiseTotal(grpItemsCombined, "totalCost");
                // const groupTotalDcOnLp = calculateGroupDcOnLp(groupListPrice, groupNettEANonAI);
                const groupTotalDcOnLp = calculateItemDcOnLp(groupNettEANonAI, groupListPrice);

                if (groupDbExtra) {
                    groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA - updatedNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupStFutureCost
                    );
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(
                        groupNettEA - updatedNettEA,
                        groupActCostExact
                    );
                } else {
                    groupMargin = calculateGroupWiseMarginForSNumberChange(groupNettEA, groupstEecost);
                    groupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(groupNettEA, groupStFutureCost);
                    groupActMargin = calculateGroupWiseActMarginForSNumberChange(groupNettEA, groupActCostExact);
                }

                const emptyGroupRow = getGroupRowByGroup(items, groupId);
                const emptyGroupRowIndex = getGroupRowIndex(items, groupId);
                const groupQty = emptyGroupRow.length > 0 ? emptyGroupRow[0].qty : 0;
                const hasGroupQtyChanged = groupQty > 1;

                if (emptyGroupRow.length > 0) {
                    const updatedEmptyGroupRow = {
                        ...emptyGroupRow[0],
                        listPrice: groupListPrice,
                        nettEA: groupNettEA,
                        totalPrice: hasGroupQtyChanged ? groupTotalPrice * groupQty : groupTotalPrice,
                        dcOnLp: groupTotalDcOnLp,
                        stEecostRound: parseFloat(groupstEecost.toFixed(2)),
                        stFutureCostRound: parseFloat(groupStFutureCost.toFixed(2)),
                        stFutureCost: groupStFutureCost,
                        margin: groupMargin,
                        actMargin: groupActMargin,
                        frcMargin: groupFrcMargin,
                        totalCost: calculateItemTotalCost(groupQty, groupstEecost),
                        stEecost: groupstEecost,
                        actCost: parseFloat(groupActCostExact.toFixed(2)),
                        actCostExact: groupActCostExact,
                    };

                    items[emptyGroupRowIndex] = updatedEmptyGroupRow;
                }

                // non group db extra calculation
                const nonGroupItems = getExistingNonGroupedItems(items);

                if (nonGroupItems.length > 0) {
                    const nonGroupId = nonGroupItems[0].groupId;
                    const nonGroupDbExtra = isDbExtraAdded(items, nonGroupId, null, GroupTypes.NON_GROUP);

                    if (nonGroupDbExtra) {
                        const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(items, dbExtraCharge);
                        items[dbExtraRowIndex] = dbExtraRow;
                    }
                }

                updatedItemList = [...items];
            }
        });
    } else {
        console.log("items in grid calculation :", items);
        // non group db extra calculation
        const nonGroupItems = getExistingNonGroupedItems(items);

        if (nonGroupItems.length > 0) {
            const nonGroupId = nonGroupItems[0].groupId;
            const nonGroupDbExtra = isDbExtraAdded(items, nonGroupId, null, GroupTypes.NON_GROUP);

            if (nonGroupDbExtra) {
                const { dbExtraRow, dbExtraRowIndex } = getNonGroupDbExtraRow(items, dbExtraCharge);
                items[dbExtraRowIndex] = dbExtraRow;
            }
        }

        updatedItemList = [...items];
    }

    return updatedItemList;
}

export function calculateGroupWiseMarginForSNumberChange(groupWiseNettEa, groupWisestEecost) {
    if (groupWiseNettEa === 0.0 || groupWiseNettEa === "" || groupWisestEecost === "") return 0.0;
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  ((parseFloat(groupWiseNettEa) - parseFloat(groupWisestEecost)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(1)
          );
}

export function calculateGroupWiseFrcMarginForSNumberChange(groupWiseNettEa, groupWiseStFutureCost) {
    if (groupWiseNettEa === 0.0 || groupWiseNettEa === "" || groupWiseStFutureCost === "") return 0.0;
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseStFutureCost)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(1)
          );
}

export function calculateGroupWiseActMarginForSNumberChange(groupWiseNettEa, groupWiseActCostExact) {
    if (groupWiseNettEa === 0.0 || groupWiseNettEa === "" || groupWiseActCostExact === "") return 0.0;
    return parseFloat(groupWiseNettEa) === 0.0
        ? 0.0
        : parseFloat(
              (
                  ((parseFloat(groupWiseNettEa) - parseFloat(groupWiseActCostExact)) / parseFloat(groupWiseNettEa)) *
                  100
              ).toFixed(1)
          );
}

export function getQtyBasedValues(items) {
    return items.map((item) => {
        if (item.rowType === "A" || item.rowType === "S" || item.rowType === "CI") {
            return {
                ...item,
                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                actCost: parseFloat(parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)),
                stEecostRound: parseFloat(
                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                ),
                stFutureCostRound: parseFloat(
                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(2)
                ),
            };
        }
        return item;
    });
}

export function getQtyBasedValuesWithGroups(items) {
    return items.map((item) => {
        if (
            item.rowType === "A" ||
            item.rowType === "S" ||
            item.rowType === "CI" ||
            item.rowType === "G" ||
            item.rowType === "SG"
        ) {
            return {
                ...item,
                listPrice: calculateValuesOnSBLQtyChange(item.qty, item.listPrice),
                actCostExact: calculateValuesOnSBLQtyChange(item.qty, item.actCostExact),
                nettEA: calculateValuesOnSBLQtyChange(item.qty, item.nettEA),
                stEecost: calculateValuesOnSBLQtyChange(item.qty, item.stEecost),
                stFutureCost: calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost),
                actCost: parseFloat(parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.actCostExact)).toFixed(2)),
                stEecostRound: parseFloat(
                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stEecost)).toFixed(2)
                ),
                stFutureCostRound: parseFloat(
                    parseFloat(calculateValuesOnSBLQtyChange(item.qty, item.stFutureCost)).toFixed(2)
                ),
            };
        }
        return item;
    });
}

export function getSubGroupsWithoutDX(subGroups, items) {
    const arr = subGroups.map((subGroup) => {
        let subGroupItems = getItemsWithoutDBExtra(items, GroupTypes.SUB_GROUP, subGroup.groupId, subGroup.subGroupId);
        subGroupItems = getQtyBasedValues(subGroupItems);

        let subGroupListPrice = calculateGroupWiseTotal(subGroupItems, "listPrice");
        let subGroupNettEA = calculateGroupWiseTotal(subGroupItems, "nettEA");
        let subGroupStEecost = calculateGroupWiseTotal(subGroupItems, "stEecost");
        let subGroupStFutureCost = calculateGroupWiseTotal(subGroupItems, "stFutureCost");
        let subGroupActCostExact = calculateGroupWiseTotal(subGroupItems, "actCostExact");
        let subGroupTotalPrice = calculateGroupWiseTotal(subGroupItems, "totalPrice");
        let subGroupTotalCost = calculateGroupWiseTotal(subGroupItems, "totalCost");
        let subGroupDcOnLp = calculateItemDcOnLp(subGroupNettEA, subGroupListPrice);
        let subGroupMargin = calculateGroupWiseMarginForSNumberChange(subGroupNettEA, subGroupStEecost);
        let subGroupFrcMargin = calculateGroupWiseFrcMarginForSNumberChange(subGroupNettEA, subGroupStFutureCost);
        let subGroupActMargin = calculateGroupWiseActMarginForSNumberChange(subGroupNettEA, subGroupActCostExact);

        return {
            ...subGroup,
            listPrice: subGroupListPrice,
            nettEA: subGroupNettEA,
            totalPrice: subGroupTotalPrice,
            totalCost: subGroupTotalCost,
            dcOnLp: subGroupDcOnLp,
            margin: subGroupMargin,
            frcMargin: subGroupFrcMargin,
            actMargin: subGroupActMargin,
            stEecost: subGroupStEecost,
            stFutureCost: subGroupStFutureCost,
            actCostExact: subGroupActCostExact,
        };
    });

    return arr;
}
