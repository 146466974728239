import React, { useState, useCallback, useRef, useEffect, useContext } from "react";
import { Grid, GridColumn as Column, GridToolbar, GridRow } from "@progress/kendo-react-grid";
import { Button, SplitButton } from "@progress/kendo-react-buttons";
import "../../styles/LeftNavigationBar.scss";
import EditableCell from "./EditableCell"; // adjust the path according to your project structure
import CheckboxCell from "./CheckboxCell"; // adjust the path according to your project structure
import HeaderCheckboxCell from "./HeaderCheckboxCell"; // adjust the path according to your project structure
import { setExpandedState, setGroupIds } from "@progress/kendo-react-data-tools";
import { groupBy } from "@progress/kendo-data-query";
import { LookupTypes } from "../../enums/LeftNavTypes";

/*UI*/
import { Tooltip } from "@progress/kendo-react-tooltip";

//#region approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
//#endregion

import { usePriceListDetails } from "../../Contexts/PriceListDetailsProvider";


const processWithGroups = (data, group) => {
    const newDataState = groupBy(data, group);
    setGroupIds({
        data: newDataState,
        group: group,
    });
    return newDataState;
};

const initialGroups = [{ field: "groupName" }];

const DataGridView = ({
    data = [],
    columns = [{ autoSize: true }],
    tableName,
    toolbarButtons = [],
    selectedRows = [],
    setSelectedRows = () => { },
    idField = "",
    isHeaderCheckboxRender = false,
    total,
    onPageChange,
    isCustomerGrid,
    showSelectionColumn = true,
    singleSelect = false, // new prop for single selection
    showEmptyRow = true,
    handleCustomerNumberChange, // new prop
    handleItemChange,
    getQuoteDetailsById,
    isGroupable,
    stockNumber,
    setStockNumber,
    skip, // Added skip as prop
    take, // Added take as prop
    totalSOH,
    type,
    handleStockPriceQtyBreakRow,
    isDeleted,
    isApplyDisabled,
}) => {

    const [resultState, setResultState] = useState(processWithGroups(data, initialGroups));
    const [collapsedState, setCollapsedState] = useState([]);
    const [group, setGroup] = useState(initialGroups);

    const visibleColumns = columns.filter((column) => column.show !== false);
    //#region approval workflow
    const {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected
    } = useContext(QteApprovalNotificationContext);
    //#endregion

    const { isPriceListItemSelected, setIsPriceListItemSelected } = usePriceListDetails();

    const pageChange = (event) => {
        if (onPageChange) {
            onPageChange(event.page);
        }
    };

    const getStatusClass = (status) => {
        switch (status) {
            case 'Approved': return 'appr-commontxt-green';
            case 'Pending Approval': return 'appr-commontxt-orange';
            case 'Approval Required': return 'appr-commontxt-darkorange';
            case 'Rejected': return 'appr-commontxt-red';
            case 'No Approval Required': return 'appr-commontxt-grey';
            default: return '';
        }
    };

    const StatusCell = (props) => {
        const statusClass = getStatusClass(props.dataItem.status);
        return (
            <td className={statusClass}>
                {props.dataItem.status}
            </td>
        );
    };
    const rowRender = (trElement, props) => {
        const isDeleted = props.dataItem.isDeleted;
        const isRowSelected = selectedRows.some((row) => row[idField] === props.dataItem[idField]);
        const isStrikethrough = isApplyDisabled && isDeleted;
        const statusClass = getStatusClass(props.dataItem.status); // Assuming status is a property of dataItem
        const isAssemblyHeader = props.dataItem.rowType === "A"; // Check if it's an Assembly Item
        const isCustomItem = props.dataItem.rowType === "CI"; // Check if it's a Custom Item
        
        const handleRowClick = (e) => {
            
            const itemToChange = props.dataItem;
            // const checkboxContainer = e.currentTarget.querySelector(`div[data-id="${itemToChange[idField]}"]`);
            // if (checkboxContainer) {
            //     checkboxContainer.click();
            // }

            setIsPriceListItemSelected(false);

            let isChecked;

            if (props.dataItem !== undefined && props.dataItem !== null) {
                if (props.dataItem.type === "PriceList") {

                    // const checkboxContainer = e.currentTarget.querySelector(`div[data-id="${itemToChange[idField]}"]`);
                    // let isAHeader = // 3 is the additional columns including the checkbox and the empty 2 columns
                    //     e.currentTarget.querySelector(`[colspan="${columns.length + 3}"]`) ||
                    //     e.currentTarget.querySelector(`[colspan="${columns.length - 1 + 3}"]`);
        
                    // if (isAHeader) {
                    //     return;
                    // } else {
                    //     let arrowIcon = document.querySelector(".arrowRight");
                    //     let rowHighLight = document.querySelector(".selected-row");
                    //     arrowIcon !== null ? arrowIcon.classList.remove("arrowRight") : <></>;
                    //     rowHighLight !== null ? rowHighLight.classList.remove("selected-row") : <></>;
                    //     e.currentTarget.cells[0].classList.add("arrowRight");
                    //     e.currentTarget.classList.add("selected-row");
                    // }


                    isChecked = false;
                    setIsPriceListItemSelected(true);

                } else {

                    const checkboxContainer = e.currentTarget.querySelector(`div[data-id="${itemToChange[idField]}"]`);
                    if (checkboxContainer) {
                        checkboxContainer.click();
                    }
                    isChecked = isRowSelected;

                    if (singleSelect) {
                        if (isChecked) {
                            setSelectedRows([]);
                        } else if (itemToChange.customerNumber !== undefined) {
                            setSelectedRows([itemToChange]);
                        }
                    } else {
                        if (isChecked) {
                            setSelectedRows(selectedRows.filter((row) => row[idField] !== itemToChange[idField]));
                        } else if (itemToChange.customerNumber !== undefined) {
                            setSelectedRows([...selectedRows, itemToChange]);
                        }
                    }

                    setIsPriceListItemSelected(false);
                }
            }
        };

        // Construct the class name string based on various conditions
    let classNames = `${isRowSelected ? "selected-row" : ""} ${isStrikethrough ? "ip-p-partdelete" : ""}`
    if (isAssemblyHeader) {
        classNames += " ip-normalrow-style ip-gridclr-assembly"; // Append classes for Assembly Items
    }
    if (isCustomItem) { 
        classNames += " ip-normalrow-style ip-gridclr-custom"; // Append classes for Custom Items
    }

        return React.cloneElement(
            trElement,
            {
                onClick: handleRowClick,
                className: classNames,
            },
            trElement.props.children
        );
    };

    const getDataSet = (isGroupable, data) => {
        if (isGroupable) {
            return setExpandedState({
                data: resultState,
                collapsedIds: collapsedState,
            });
        } else {
            return data;
        }
    };

    const onExpandChange = useCallback(
        (event) => {
            const item = event.dataItem;
            if (item.groupId) {
                const newCollapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(newCollapsedIds);
            }
        },
        [collapsedState]
    );

    const onGroupChange = useCallback((event) => {
        const newDataState = processWithGroups(data, event.group);
        setGroup(event.group);
        setResultState(newDataState);
    }, []);

    const dataWithEmptyRow = showEmptyRow ? [...data, {}] : data;

    const isLastSubGroup = (groupId, dataList, subGroupedData) => {
        const groupedData = dataList.filter((item) => item.groupId === groupId);
        const remainingList = groupedData.filter((g) => !subGroupedData.some((s) => s[idField] === g[idField]));

        if (remainingList.length === 1) {
            return true;
        }
        return false;
    };

    //     const handleStockPriceQtyBreakRow = (e) => {
    // debugger;
    //         console.log("handleStockPriceQtyBreakRowClick ::", e);
    //     };

    const selectMultipleItems = (dataItem, status) => {
        const checkedGroupId = dataItem?.groupId;
        const checkedSubGroupId = dataItem?.subGroupId;
        const isMainGroup = dataItem?.displayGroupName !== undefined ? true : false;
        const isMainSubGroup = dataItem?.displaySubGroupName !== undefined ? true : false;

        if (isMainGroup) {
            if (status) {
                const groupedData = dataWithEmptyRow.filter((item) => item.groupId === checkedGroupId);
                const combinedList = [...selectedRows, ...groupedData];
                setSelectedRows(combinedList);
            } else {
                const groupedData = dataWithEmptyRow.filter((item) => item.groupId === checkedGroupId);
                const combinedList = selectedRows.filter(
                    (row) => !groupedData.some((item) => item[idField] === row[idField])
                );
                setSelectedRows(combinedList);
            }
        } else if (isMainSubGroup) {
            let groupId = null;

            if (status) {
                const subGroupedData = dataWithEmptyRow.filter((item) => item.subGroupId === checkedSubGroupId);
                let isLast = false;

                if (subGroupedData.length > 0) {
                    groupId = subGroupedData[0].groupId;
                    isLast = isLastSubGroup(groupId, dataWithEmptyRow, subGroupedData);
                }

                if (isLast) {
                    const groupedData = dataWithEmptyRow.filter((item) => item.groupId === groupId);
                    const combinedList = [...selectedRows, ...groupedData];
                    setSelectedRows(combinedList);
                } else {
                    const combinedList = [...selectedRows, ...subGroupedData];
                    setSelectedRows(combinedList);
                }
            } else {
                const subGroupedData = dataWithEmptyRow.filter((item) => item.subGroupId === checkedSubGroupId);
                let isLast = false;

                if (subGroupedData.length > 0) {
                    groupId = subGroupedData[0].groupId;
                    isLast = isLastSubGroup(groupId, dataWithEmptyRow, subGroupedData);
                }

                if (isLast) {
                    const groupedData = dataWithEmptyRow.filter((item) => item.groupId === groupId);
                    const combinedList = selectedRows.filter(
                        (row) => !groupedData.some((item) => item[idField] === row[idField])
                    );
                    setSelectedRows(combinedList);
                } else {
                    const subGroupedData = dataWithEmptyRow.filter((item) => item.subGroupId === checkedSubGroupId);
                    const combinedList = selectedRows.filter(
                        (row) => !subGroupedData.some((item) => item[idField] === row[idField])
                    );
                    setSelectedRows(combinedList);
                }
            }
        } else {
            setSelectedRows([dataItem]);
        }
    };

    // Combine filtering and mapping to create updatedColumns
    const updatedColumns = columns
        .filter(column => column.show !== false)  // Filter columns based on visibility
        .map(column => {
            // Apply custom cell renderer for status column
            if (column.field === "status") {
                return { ...column, cell: StatusCell };
            }
            return column;
        });

    /*Tooltip UI*/
    const ColumnNameHeader = (props) => {
        return (
            <div
                className="k-link"
                onClick={props.onClick}
                onContextMenu={(e) => {
                    e.preventDefault();
                    handleContext(e, props.field);
                }}
            >
                <a className="k-link" onClick={props.onClick}>
                    <span title={props.title}>{props.title}</span>
                    {props.children}
                </a>
            </div>
        );
    }
    class ColumnNameCell extends React.Component {
        render() {
            return this.props.rowType !== "groupHeader" ? (
                <td className="ip-base-grid-noneditable" title={this.props.dataItem[this.props.field]}>
                    {this.props.dataItem[this.props.field]}
                </td>
            ) : null;
        }
    }

    return (
        <div className="grid-container">
            <div className="grid-box">
                <div className="grid-header-box">
                    <h6>{tableName}</h6>
                </div>

                <Tooltip openDelay={100} position="bottom" anchorElement="target">
                    <Grid
                        data={dataWithEmptyRow}
                        pageable
                        skip={skip}
                        take={take}
                        total={total}
                        tableName={tableName}
                        onPageChange={pageChange}
                        scrollable="scrollable"
                        rowRender={rowRender}
                        resizable={true}
                        className="ip-base-grid"
                        columns="columns"
                        //test
                        groupable={isGroupable}
                        expandField="expanded"
                        group={group}
                        onGroupChange={onGroupChange}
                        onExpandChange={onExpandChange}>
                        {/* onRowClick={handleStockPriceQtyBreakRow} */}
                        <GridToolbar>
                            {toolbarButtons.map(
                                (button) => {
                                    return button.label.props.children === "Add Group" ? (
                                        <SplitButton
                                            key={button.label}
                                            text={<strong>Add Group</strong>}
                                            onItemClick={button.onClick}
                                            items={[{ text: "Add Group" }, { text: "Add Sub Group" }]}></SplitButton>
                                    ) : (
                                        <Button
                                            key={button.label}
                                            onClick={button.onClick}
                                            icon={button.icon}
                                            look={button.look}
                                            disabled={(quoteApprovalStatId >= 4) ? true : button.disabled}>
                                            {button.label}
                                        </Button>
                                    );
                                }
                                //console.log("button ::", button");
                            )}
                        </GridToolbar>

                        {showSelectionColumn && (
                            <Column
                                field="select"
                                title={
                                    <HeaderCheckboxCell
                                        isHeaderCheckboxRender={isHeaderCheckboxRender}
                                        selectedRows={selectedRows}
                                        total={data.length}
                                        onHeaderSelectionChange={(checked) => {
                                            if (checked) {
                                                setSelectedRows(data);
                                            } else {
                                                setSelectedRows([]);
                                            }
                                        }}
                                    />
                                }
                                width="32px" // Set the width for the column
                                cell={(props) =>
                                    type === LookupTypes.SOH ? (
                                        <div></div>
                                    ) : (
                                        <CheckboxCell
                                            {...props}
                                            idField={idField}
                                            selectedRows={selectedRows}
                                            setSelectedRows={setSelectedRows}
                                            singleSelect={singleSelect}
                                            getQuoteDetailsById={getQuoteDetailsById}
                                            isGroupable={isGroupable}
                                            selectMultipleItems={selectMultipleItems}
                                            stockNumber={stockNumber}
                                            setStockNumber={setStockNumber}
                                        />
                                    )
                                }
                            />
                        )}

                        {/* {columns.map((column, index) =>
                        column.cell ? (
                            <Column title={column.title} cell={column.cell} />
                        ) : column.editable ? (
                            <Column
                                key={index}
                                title={column.title}
                                cell={(props) => (
                                    <EditableCell
                                        {...props}
                                        field={column.field}
                                        onChange={
                                            column.field === "customerNumber"
                                                ? (value) => handleCustomerNumberChange(value, props.dataItem)
                                                : column.field === "repDisc" ||
                                                  column.field === "nettEA" ||
                                                  column.field === "stStockCode" ||
                                                  column.field === "qty"
                                                ? handleItemChange
                                                : column.onChange
                                        }
                                    />
                                )}
                            />
                        ) : (
                            <Column field={column.field} title={column.title} />
                        )
                    )} */}

                        {updatedColumns.map((column, index) =>
                            column.cell ? (
                                <Column key={index} title={column.title} headerCell={ColumnNameHeader} cell={column.cell} groupable={false} width={column.width} />
                            ) : column.editable ? (
                                <Column
                                    key={index}
                                    title={column.title}
                                    headerCell={ColumnNameHeader}
                                    width={column.width}
                                    cell={(props) => {
                                        return (
                                            <EditableCell
                                                {...props}
                                                field={column.field}
                                                groupable={false}
                                                isCustomerGrid = {isCustomerGrid}
                                                disabled={(quoteApprovalStatId >= 4) ? true : //disable all buttons if quote is approved or more
                                                    props.dataItem.displayGroupName !== undefined ||
                                                        props.dataItem.displaySubGroupName !== undefined
                                                        ? true
                                                        : false
                                                }
                                                onChange={
                                                    column.field === "customerNumber"
                                                        ? (value) => handleCustomerNumberChange(value, props.dataItem)
                                                        : column.onChange
                                                        
                                                }
                                                handleItemChange={handleItemChange}
                                            />
                                        );
                                    }}
                                />
                            ) : (
                                <Column key={index} field={column.field} headerCell={ColumnNameHeader} cell={ColumnNameCell} title={column.title} groupable={false} width={column.width}/>
                            )
                        )}
                    </Grid>
                </Tooltip>

                {type === LookupTypes.SOH && (
                    <div className="row m-0 ip-whse-summary">
                        <div className="d-flex justify-content-between flex-sm-column flex-md-row">
                            <div className="d-flex flex-row">
                                Total SOH:{" "}
                                <span class="text-black ms-1">
                                    <strong>{totalSOH.toLocaleString()}</strong>
                                </span>
                            </div>
                            <div className="d-flex flex-row">
                                Stock Number:{" "}
                                <span class="text-black ms-1">
                                    <strong>{stockNumber}</strong>
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default DataGridView;
