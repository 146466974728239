import React, { useState, useEffect, useRef, useContext } from "react";
import { LookupTypes } from "../../enums/LeftNavTypes";
import DataGridView from "./DataGridView";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { postResponse } from "../../Services/Common/Post";
import { getResponse } from "../../Services/Common/Get"; // adjust the path according to your project structure
import { RemoveItemsContext } from "../../Contexts/ReomoveItemsContext";
import { toast, Slide } from "react-toastify";
import Spinner from "../Common/Spinner";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import { Loader } from "@progress/kendo-react-indicators";


const Lookup = ({
    filterOptions,
    idField,
    selectColumns,
    addColumns,
    selectTableName,
    addTableName,
    
    stockNumber,
    setStockNumber,
    apiURL,
    conversionFunction,
    type,
    onAddItems,
    onAddItemsToGrid,
}) => {
   
    const {stockCode, setStockCode,ststatus, setStstatus,totalSoh, setTotalSoh,totalCompParts, setTotalCompParts} = useStockCode();
    const [selectItems, setSelectItems] = useState([]);
    const [total, setTotal] = useState(0);
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedAddedItems, setSelectedAddedItems] = useState([]);
    const [search, setSearch] = useState("");
    //const [filter, setFilter] = useState(filterOptions[0]); // default filter option
    const [filter, setFilter] = useState(type === LookupTypes.CUSTOMER ? filterOptions[1] : filterOptions[0]); // default filter option
    const [pageSize, setPageSize] = useState(50);
    const { addItems, setAddItems } = useContext(RemoveItemsContext);
    const [pageNumberSelectItems, setPageNumberSelectItems] = useState(1);
    const [pageNumberAddItems, setPageNumberAddItems] = useState(1);
    const [skipSelectItems, setSkipSelectItems] = useState(0);
    const [takeSelectItems, setTakeSelectItems] = useState(50);
    const [skipAddItems, setSkipAddItems] = useState(0);
    const [takeAddItems, setTakeAddItems] = useState(50);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRun = useRef(true);
    const [soh, setSoh] = useState([]);
    const [whse, setWhse] = useState([]);
    const [totalSOH, setTotalSOH] = useState("");
    const [sohAndWhse, setSohAndWhse] = useState([]);
    const handleSearch = async () => {
        setIsLoading(true);
        setSohAndWhse([]);
        // Add wildcard characters to the search query
        const wildcardSearch = search.replace(/\*|\?|_|\\|\#|\@|\$|\-|\=/g, "%");

        const searchRequest = {
            name: wildcardSearch,
            filter: filter.value,
            pageNumber: pageNumberSelectItems,
            pageSize: pageSize,
        };
       

        try {

            if(type == LookupTypes.SOH){
                const search = {
                    name: wildcardSearch,
                    filter: "all",
                    pageNumber: pageNumberSelectItems,
                    pageSize: pageSize,
                };
                const result = await postResponse(apiURL, search);
               
           console.log("result :",result?.data)
                if (result && result?.statusCode) {
    
                    const convertedItems = result?.data;
              
                    if (result.count == 0) {
                        setSelectItems([])
                        toast.error("No parts found", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide });
                    }   
                    if (convertedItems[0].stStockCode) {

                        const fullDesc = convertedItems.map(item => { return { ...item, fullDescription: item.stDesc1 + ' ' + item.stDesc2 + ' ' + item.stDesc3 } })
        
                            setSelectItems(fullDesc);
                            setTotal(result.count);    
                        }
                    else {
                        setSelectItems(convertedItems);
                        setTotal(result.count);                  
    
                    }
    
                } 
                setIsLoading(false);
            }
            else{
            const result = await postResponse(apiURL, searchRequest);

            if (result && result?.statusCode) {

                const convertedItems = result?.data.map(conversionFunction);
                console.log("convertedItems", convertedItems)
                
                if (result.count == 0) {
                    setSelectItems([]);
                    switch(type) {
                        case LookupTypes.CUSTOMER:
                            toast.error("No Customer Records Found", { autoClose: 5000, closeOnClick: true, position: "top-center", transition: Slide });
                            break;
                        case LookupTypes.ITEM:
                            toast.error("No Part Records Found", { autoClose: 5000, closeOnClick: true, position: "top-center", transition: Slide });
                            break;
                        default:
                            toast.error("No Parts Found", { autoClose: 3000, closeOnClick: true, position: "top-center", transition: Slide });
                    }
                }

                if (convertedItems[0].spStockCode) {

                    const fullDesc = convertedItems.map(item => { return { ...item, fullDescription: item.stDesc1 + ' ' + item.stDesc2 + ' ' + item.stDesc3 } })
                    setSelectItems(fullDesc);
                  
                    
                    setTotal(result.count);                  
                }
                else {
                    setSelectItems(convertedItems);
                    setTotal(result.count);                  

                }

            } else {
                toast.error("Could not find customers.", {
                    position: "top-center",
                    transition: Slide,
                });        
                return;
            }
            setIsLoading(false);
        }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching search results:", error);
        }
    };

    const [loaderType, setLoaderType] = useState("pulsing");
    const [showLoaderBtn,setShowLoaderBtn] =  useState(false);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
        } else {
            handleSearch();
        }
    }, [pageNumberSelectItems]);

    const handlePageChangeSelectItems = ({ skip, take }) => {
        setSkipSelectItems(skip);
        setTakeSelectItems(take);
        setPageNumberSelectItems(skip / take + 1);
    };
    const handlePageChangeAddItems = ({ skip, take }) => {
        setSkipAddItems(skip);
        setTakeAddItems(take);
        setPageNumberAddItems(skip / take + 1);
    };

    const paginate = (items, pageNumber, pageSize) => {
        const startIndex = (pageNumber - 1) * pageSize;
        return items.slice(startIndex, startIndex + pageSize);
    };
    useEffect(() => {

       if(stockNumber === '') {
            setSoh('');
            setWhse('');
            setTotalSOH('');
            setTotalSoh('')
            setSohAndWhse([]);
       }
        if (stockNumber) {
          const fetchData = async () => {
            try {
              const data = await getResponse("SOH/getsohbystockcode", { stStockCode: stockNumber });
              console.log("data:", data);
    
              const values = data
             .filter((item) => item.whse !== 'NATW')
             .map((item) => ({
             soh: item.soh,
             whse: item.whse,
             }));

              const sohValues = data.filter((item) => item.whse !== 'NATW').map((item) => item.soh);
              const whseValues = data.filter((item) => item.whse !== 'NATW').map((item) => item.whse);
              const totalSoh = sohValues.reduce((acc, currentValue) => acc + currentValue, 0);
              console.log("Total SOH:", totalSoh);
    
              console.log("whse:", whseValues);
              console.log("SOH:", sohValues);
              console.log("list:", values);
    
              setSoh(sohValues);
              setWhse(whseValues);
              setTotalSOH(totalSoh);
              setTotalSoh(totalSoh)
              setSohAndWhse([...values]);
            //  setSelectedItems([]);
            } catch (error) {
              console.error("Error fetching data:", error);
              // Handle the error, e.g., show an error message to the user
            }
          };
          const fetchLoadData = async () => {
            try {
              const data = await getResponse("SOH/gettotalcomppartsbystockcode", { stStockCode: stockNumber });
              console.log("gettotalcomppartsbystockcode:", data);
              setTotalCompParts(data);
            } catch (error) {
              console.error("Error fetching data:", error);
              // Handle the error, e.g., show an error message to the user
            }
          };
          fetchData();
          fetchLoadData();
        }
      
      }, [stockNumber]);
    const handleAdd = async () => {
        // Filter out duplicates
        if(type == LookupTypes.ITEM){
            setShowLoaderBtn(true);
            const uniqueSelectedItems = selectedItems.filter(
                (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
            );
    
            // if (uniqueSelectedItems.length === 0) {
            //     console.log("No unique items to add");
            //     setShowLoaderBtn(false);
            //     return;
            // }

            console.log("uniqueSelectedItems:", uniqueSelectedItems);
    
            // Merge unique items with current addItems and set the state
            setAddItems((prevItems) => [...prevItems, ...selectedItems]);
    
            // Clear selected items
            setSelectedItems([]);
        }
        else if(type == LookupTypes.CUSTOMER){
            setShowLoaderBtn(true);
            const uniqueSelectedItems = selectedItems.filter(
                (selectedItem) => !addItems.some((addItem) => addItem[idField] === selectedItem[idField])
            );
    
            // if (uniqueSelectedItems.length === 0) {
            //     console.log("No unique items to add");
            //     setShowLoaderBtn(false);
            //     return;
            // }

            console.log("uniqueSelectedItems:", uniqueSelectedItems);
    
            // Merge unique items with current addItems and set the state
            setAddItems((prevItems) => [...prevItems, ...uniqueSelectedItems]);
    
            // Clear selected items
            setSelectedItems([]);
        }
       
        else{
            console.log("ERROR !!")
        }
       
    };

    useEffect(()=> {
        showLoaderBtn ? setShowLoaderBtn(false) : null;        
    },[addItems])
   
    const handleRemove = () => {
        // First, create a mapping of current items in selectItems based on idField
        setShowLoaderBtn(true);
        const selectItemsMap = {};
        selectItems.forEach((item) => {
            selectItemsMap[item[idField]] = item;
        });

        // Now, iterate over selectedAddedItems
        selectedAddedItems.forEach((item) => {
            // If an item with the same idField doesn't exist in selectItemsMap, add it
            if (!selectItemsMap[item[idField]]) {
                selectItemsMap[item[idField]] = item;
            }
        });

        // Convert the mapping back to an array
        const updatedSelectItems = Object.values(selectItemsMap);

        setSelectItems(updatedSelectItems);
        setAddItems(addItems.filter((item) => !selectedAddedItems.includes(item)));
        setSelectedAddedItems([]);
    };

    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearch();

        }
    };

    return (
        <>
            {isLoading && (
                <div className="ip-spin-dialog-holder">
                    <Spinner />
                </div>
            )}

            {!isLoading ? (
                <>
                    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                        <div className="ip_search_container mb-4">
                            <Input
                                id = "txtlookupsearch"
                                className="me-1"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                onKeyDown={handleKeyPress}
                            />
                            {type != LookupTypes.SOH && (
                            <DropDownList
                                id = "btnDrpDwnLookupFilter"
                                className="me-1"
                                data={filterOptions}
                                textField="text"
                                dataItemKey="value"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                            />)}
                            <Button id= "btnlookupfind" onClick={handleSearch} disabled={!search.trim()}>
                                <strong>Find</strong>
                            </Button>
                        </div>

                        <div className="ip-dialog-grid-container" style={{ display: "flex", justifyContent: "space-between"}}>
                            <div className="ip-selector-gridsize">
                            <DataGridView
                                data={selectItems}
                                columns={selectColumns}
                                idField={idField}
                                tableName={selectTableName}
                                selectedRows={selectedItems}
                                setSelectedRows={setSelectedItems}
                                isHeaderCheckboxRender={type != LookupTypes.SOH ? true:false}
                                showEmptyRow={false}
                                singleSelect={type != LookupTypes.SOH ? false: true}
                                total={total}
                                pageable={true}
                                pageSize={pageSize}
                                skip={skipSelectItems}
                                take={takeSelectItems}
                                onPageChange={handlePageChangeSelectItems}
                                stockNumber={stockNumber}
                                setStockNumber={setStockNumber}
                             
                            />
                            </div>

                            <div className="ipl-button-set">
                            {type != LookupTypes.SOH && ( 
                                <Button
                                    id = "btnlookupadd"
                                    hidden={showLoaderBtn}
                                    className="ipl-button-add"
                                    style={{ display: "flex", marginBottom: "20px" }}
                                    onClick={handleAdd}
                                    icon="ri ri-arrow-right-line">
                                   <strong>Add</strong>
                                </Button>
                            )}
                            {type != LookupTypes.SOH && (
                            <Button
                                id = "btnlookupremove"
                                hidden={showLoaderBtn}
                                className="ipl-button-remove"
                                style={{ display: "flex" }}
                                onClick={handleRemove}
                                icon="ri ri-arrow-left-line">
                                <strong>Remove</strong>
                            </Button>
                            )}
                            <span hidden={!showLoaderBtn}>
                                <Loader className="mini-loader" size="medium" type={loaderType}/>
                            </span>
                
                            </div>

                            <div className="ip-adder-gridsize">
                            <DataGridView
                                data={type != LookupTypes.SOH ? paginate(addItems, pageNumberAddItems, pageSize) : paginate(sohAndWhse, pageNumberAddItems, pageSize)}                  
                                columns={addColumns}
                                tableName={addTableName}
                                idField={idField}
                                selectedRows={selectedAddedItems}
                                setSelectedRows={setSelectedAddedItems}
                                isHeaderCheckboxRender={type != LookupTypes.SOH ? true:false}
                                showEmptyRow={false}
                                total={type != LookupTypes.SOH ?(addItems ? addItems.length : 0):(sohAndWhse ? sohAndWhse.length : 0)}
                                pageable={true}
                                pageSize={pageSize}
                                skip={skipAddItems}
                                take={takeAddItems}
                                onPageChange={handlePageChangeAddItems}
                                stockNumber={stockNumber}
                                setStockNumber={setStockNumber}
                                totalSOH={totalSOH}
                                type = {type}
                               
                            />
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <></>
            )}
        </>
    );
};

export default Lookup;
