import React, { useState, useEffect, useRef } from "react";
import { isNumber } from "lodash";

const EditableCell = (props) => {
    let { dataItem, field, handleItemChange, onChange, isCustomerGrid, disabled } = props;
    const [localValue, setLocalValue] = useState(dataItem[field]);
    const [previousValidValue, setPreviousValidValue] = useState(
        isNumber(dataItem[field]) ? parseFloat(dataItem[field]).toFixed(2) : ""
    );
    const [hasError, setHasError] = useState(false);
    const [firstClick, setFirstClick] = useState(true);
    let isEnterTriggered = false;

    const inputRef = useRef(null);

    // Determine if the row or fields should be disabled
    const isRowDisabled = dataItem.rowType === "DX";
    const isFieldDisabled = dataItem.rowType === "A" && field !== "nettEA" && field !== "margin";

    // Disable input if the row or specific field needs to be disabled
    disabled = isRowDisabled || isFieldDisabled || (field === "dcOnLp" && dataItem[field] === "");

    useEffect(() => {
        // If inputRef.current is not null, store row and column indexes in sessionStorage
        // if (inputRef.current) {
        //     const currentCell = inputRef.current.closest("td");
        //     const currentRow = currentCell.closest("tr");
        //     const rowIndex = currentRow.rowIndex;
        //     const colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);

        //     // Store rowIndex and colIndex in sessionStorage
        //     sessionStorage.setItem('lastFocusedInput', JSON.stringify({ rowIndex, colIndex}));
        // }

        if (hasError) {
            setLocalValue(previousValidValue);
            setHasError(false);
        }
    }, [hasError, previousValidValue]);

    useEffect(() => {
        // Determine decimal places based on field name
        const getDecimalPlaces = () => {
            if (field === "qty") return 3;
            if (field === "margin") return 1;
            if (field === "dcOnLp") return 1;
            if (field === "nettEA") return 2;
            return 2; // Default to 2 decimal places if no specific rule
        };

        // Set local value with the appropriate decimal places
        setLocalValue(
            isNumber(dataItem[field])
                ? parseFloat(dataItem[field]).toFixed(getDecimalPlaces())
                : dataItem[field]
        );

        setPreviousValidValue(
            isNumber(dataItem[field])
                ? parseFloat(dataItem[field]).toFixed(getDecimalPlaces())
                : dataItem[field]
        ); // Update previous valid value whenever dataItem[field] changes
    }, [dataItem[field], field]);


    const handleLocalChange = (e) => {
        if (isCustomerGrid) {
            setLocalValue(e.target.value);
        }
        const value = e.target.value;
        // Allow only numbers and at most one decimal point
        const isValidNumber = /^-?\d*\.?\d*$/.test(value);

        if (isValidNumber) {
            setLocalValue(value);
        }
    };



    const handleBlur = () => {
        if (
            (localValue !== previousValidValue &&
                localValue !== "NaN" &&
                previousValidValue !== "NaN" &&
                localValue !== undefined) ||
            (localValue === "" && previousValidValue !== "NaN")
        ) {
            setLocalValue(previousValidValue);
        }
        setFirstClick(true); // Reset to allow full selection on next click
    };

    // useEffect(() => {
    //     // Handle click outside of the cell to reset firstClick
    //     const handleClickOutside = (event) => {
    //         if (inputRef.current && !inputRef.current.contains(event.target)) {
    //             setFirstClick(true); // Reset firstClick when clicking outside
    //         }
    //     };

    //     document.addEventListener("mousedown", handleClickOutside);

    //     // Cleanup the event listener on component unmount
    //     return () => {
    //         document.removeEventListener("mousedown", handleClickOutside);
    //     };
    // }, []);

    const handleKeyDown = (e) => {
        const cursorPosition = inputRef.current ? inputRef.current.selectionStart : 0;
        const ableToNavigate = parseFloat(localValue) === parseFloat(dataItem[field]);
        const ableToEnter = localValue !== "";
        let currentCell = ''
        let currentRow = ''
        let rowIndex = ''
        let colIndex = ''
        let allRows = ''

        switch (e.key) {

            case "Enter":
                if (!ableToEnter) return; // Prevent Enter if the input is empty
                e.preventDefault(); // Prevent default Enter behavior
                currentCell = inputRef.current.closest("td");
                currentRow = currentCell.closest("tr");
                rowIndex = currentRow.rowIndex;
                colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                allRows = Array.from(currentRow.parentElement.children);
                isEnterTriggered = true;

                if (isCustomerGrid) {
                    onChange({
                        dataItem,
                        field,
                        value: localValue,
                        clearInput: () => setHasError(true),
                    });
                    setPreviousValidValue(localValue);
                } else {
                    handleItemChange({ dataItem, field, value: localValue, clearInput: () => setHasError(true) })
                        .then(() => {
                            setPreviousValidValue(localValue); // Update the valid value after handleItemChange completes
                            focusCell(1, 0, allRows, rowIndex, colIndex); // Move to the next row
                        })
                        .catch((error) => {
                            console.error("Error handling item change:", error);
                        });
                }
                break;

            case "Tab":
                if (!ableToEnter) return; // Prevent Enter if the input is empty
                e.preventDefault(); // Prevent default Enter behavior
                currentCell = inputRef.current.closest("td");
                currentRow = currentCell.closest("tr");
                rowIndex = currentRow.rowIndex;
                colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                allRows = Array.from(currentRow.parentElement.children);

                if (isCustomerGrid) {
                    onChange({
                        dataItem,
                        field,
                        value: localValue,
                        clearInput: () => setHasError(true),
                    });
                    setPreviousValidValue(localValue);
                } else {
                    handleItemChange({ dataItem, field, value: localValue, clearInput: () => setHasError(true) })
                        .then(() => {
                            setPreviousValidValue(localValue); // Update the valid value after handleItemChange completes
                            focusCell(1, 0, allRows, rowIndex, colIndex); // Move to the next row
                        })
                        .catch((error) => {
                            console.error("Error handling item change:", error);
                        });
                }
                break;


            case "ArrowUp":
                if (ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    currentCell = inputRef.current.closest("td");
                    currentRow = currentCell.closest("tr");
                    rowIndex = currentRow.rowIndex;
                    colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                    allRows = Array.from(currentRow.parentElement.children);

                    focusCell(-1, 0, allRows, rowIndex, colIndex);
                }
                break;

            case "ArrowDown":
                if (ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    currentCell = inputRef.current.closest("td");
                    currentRow = currentCell.closest("tr");
                    rowIndex = currentRow.rowIndex;
                    colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                    allRows = Array.from(currentRow.parentElement.children);

                    focusCell(1, 0, allRows, rowIndex, colIndex);
                }
                break;

            case "ArrowLeft":
                if (cursorPosition === 0 && ableToNavigate) {
                    e.preventDefault(); // Prevent scrolling
                    currentCell = inputRef.current.closest("td");
                    currentRow = currentCell.closest("tr");
                    rowIndex = currentRow.rowIndex;
                    colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                    allRows = Array.from(currentRow.parentElement.children);

                    focusCell(0, -1, allRows, rowIndex, colIndex);
                }
                break;

            case "ArrowRight":
                if ((cursorPosition === localValue.length && ableToNavigate) || localValue.length === undefined) {
                    e.preventDefault(); // Prevent scrolling
                    currentCell = inputRef.current.closest("td");
                    currentRow = currentCell.closest("tr");
                    rowIndex = currentRow.rowIndex;
                    colIndex = Array.from(currentCell.parentElement.children).indexOf(currentCell);
                    allRows = Array.from(currentRow.parentElement.children);

                    focusCell(0, 1, allRows, rowIndex, colIndex);
                }
                break;

            default:
                break;
        }
    };

    const focusCell = (rowOffset, colOffset, allRows, rowIndex, colIndex) => {
        const targetRow = allRows[rowIndex + rowOffset];
        if (!targetRow) return;




        const targetCell = targetRow.children[colIndex + colOffset];
        if (targetCell) {
            const targetInput = targetCell.querySelector("input");
            if (targetInput) {
                if (isEnterTriggered) {


                    switch (colIndex) {
                        case 5:
                            targetInput.value = parseFloat(targetInput.value).toFixed(1);
                            break;
                        case 6:
                            targetInput.value = parseFloat(targetInput.value).toFixed(2);
                            break;
                        case 7:
                            targetInput.value = parseFloat(targetInput.value).toFixed(1);
                            break;
                        default:
                            targetInput.value = parseFloat(targetInput.value).toFixed(2);
                            break;
                    }
                    isEnterTriggered = false;

                }

                targetInput.focus();
                targetInput.select(); // Selects the entire content of the input
            }
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        if (firstClick) {
            inputRef.current.select(); // Select entire content on first click
            setFirstClick(false); // Subsequent clicks will not select the entire content
        }
    };

    const inputStyle = hasError ? { border: "2px solid red" } : {};

    return (
        <td onClick={handleClick}>
            <input
                className={`ip-editable-input ${props.className}`}
                autoComplete="on"
                type="text"
                value={localValue}
                onChange={handleLocalChange}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                disabled={disabled}
                ref={inputRef}
                style={inputStyle}

            />
        </td>
    );
};

export default EditableCell;
