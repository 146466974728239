import { GroupItemTypes, GroupTypes } from "../enums/GroupTypes";
import { isDbExtraAdded } from "./DbExtra/DbExtraFilters";

//ref: returns the items in group excluding dbExtra row
// export function getGroupedItemsWithoutDBExtra(items, currentItem) {
//     return items.filter(
//         (subItem) =>
//             currentItem.groupId === subItem.groupId &&
//             subItem.groupName !== GroupItemTypes.NON_GROUPED_ITEMS &&
//             subItem.displayGroupName === "" &&
//             subItem.stStockCode !== "" &&
//             (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === "")) &&
//             (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
//             //newly added 18th october 2023
//             (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === ""))
//     );
// }

//ref : returns the items in subgroup excluding dbExtra row
// export function getSubGroupedItemsWithoutDBExtra(items, currentItem) {
//     return items.filter(
//         (subItem) =>
//             currentItem.groupId === subItem.groupId &&
//             subItem.subGroupId !== "" &&
//             currentItem.subGroupId === subItem.subGroupId &&
//             subItem.displaySubGroupName === "" &&
//             subItem.stStockCode !== "" &&
//             (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
//             //newly added 18th october 2023
//             (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === "")) &&
//             (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === ""))
//     );
// }

// //ref : returns the items in non group excluding dbExtra row
// export function getNonGroupedItemsWithoutDBExtra(items, currentItem) {
//     return items.filter(
//         (subItem) =>
//             currentItem.groupId === subItem.groupId &&
//             subItem.groupName === GroupItemTypes.NON_GROUPED_ITEMS &&
//             subItem.displaySubGroupName === "" &&
//             subItem.stStockCode !== "" &&
//             (!subItem.nonGroupDbExtraId || (subItem.nonGroupDbExtraId && subItem.nonGroupDbExtraId === "")) &&
//             //newly added 18th october 2023
//             (!subItem.subGroupDbExtraId || (subItem.subGroupDbExtraId && subItem.subGroupDbExtraId === "")) &&
//             (!subItem.groupDbExtraId || (subItem.groupDbExtraId && subItem.groupDbExtraId === ""))
//     );
// }

// //ref : returns the items in a group without dbExtra row
// export function getItemsWithoutDBExtra(items, type, groupId = null, subGroupId = null) {
//     switch (type) {
//         case GroupTypes.NON_GROUP:
//             return items.filter(
//                 (item) =>
//                     item.groupDbExtraId === "" &&
//                     item.nonGroupDbExtraId === "" &&
//                     item.subGroupDbExtraId === "" &&
//                     item.groupId === groupId &&
//                     item.stStockCode !== ""
//             );
//         case GroupTypes.GROUP:
//             if (groupId !== null) {
//                 return items.filter(
//                     (item) =>
//                         item.groupDbExtraId === "" &&
//                         item.nonGroupDbExtraId === "" &&
//                         item.subGroupDbExtraId === "" &&
//                         item.groupId === groupId &&
//                         item.displayGroupName === "" &&
//                         item.displaySubGroupName === "" &&
//                         item.stStockCode !== ""
//                 );
//             }
//         case GroupTypes.SUB_GROUP:
//             if (groupId !== null && subGroupId !== null) {
//                 return items.filter(
//                     (item) =>
//                         item.groupDbExtraId === "" &&
//                         item.nonGroupDbExtraId === "" &&
//                         item.subGroupDbExtraId === "" &&
//                         item.displaySubGroupName === "" &&
//                         item.subGroupId === subGroupId &&
//                         item.groupId === groupId &&
//                         item.stStockCode !== ""
//                 );
//             }
//         default:
//             break;
//     }
// }

//ref : returns the items in a group without group row
export function getItemsWithoutGroup(items) {
    return items.filter((item) => item.displayGroupName === "");
}

export function getAllItemsInAGroup(items, groupId) {
    return items.filter(
        (item) =>
            item.groupId === groupId &&
            item.displayGroupName === "" &&
            item.displaySubGroupName === "" &&
            item.groupDbExtraId === "" &&
            item.subGroupDbExtraId === "" &&
            item.nonGroupDbExtraId === ""
    );
}

//ref : returns existing items in a group/sub group/non group
export function getExistingNonGroupedItems(items) {
    return items.filter((item) => {
        return item.stStockCode !== "" && item.groupName === GroupItemTypes.NON_GROUPED_ITEMS;
    });
}

export function getAllExistingSubGroupedItems(items, groupId, subGroupId) {
    return items.filter((item) => {
        return item.groupId === groupId && item.subGroupId === subGroupId && item.stStockCode !== "";
    });
}

export function getAllExistingNonSubGroupedItems(items, groupId) {
    return items.filter((item) => {
        return item?.groupId === groupId && item?.subGroupName?.includes("Non Sub") && item.stStockCode !== "";
    });
}

export function getAllExistingGroupedItems(items, groupId) {
    return items.filter((item) => {
        return item.groupId === groupId && item.stStockCode !== "";
    });
}

export function getAllExistingSubGroupsByGroup(items, groupId) {
    return items.filter((item) => {
        return item.groupId === groupId && item.displaySubGroupName !== "";
    });
}

//ref : return the items in a group row list
export function getGroupedRowItems(items, groupId) {
    return items.filter((item) => item.groupId === groupId && item.stStockCode !== "" && item.subGroupId === "");
}

export function getSubGroupedRowItems(items, groupId, subGroupId) {
    return items.filter(
        (item) => item.groupId === groupId && item.subGroupId === subGroupId && item.stStockCode !== ""
    );
}

export function getGroupRowByGroup(items, groupId) {
    return items.filter((item) => item.groupId === groupId && item.displayGroupName !== "");
}

export function getSubGroupRowByGroupAndSubGroup(items, groupId, subGroupId) {
    return items.filter(
        (item) => item.groupId === groupId && item.subGroupId === subGroupId && item.displaySubGroupName !== ""
    );
}

export function getGroupRowIndex(items, groupId) {
    return items.findIndex((item) => item.groupId === groupId && item.displayGroupName !== "");
}

export function getSubGroupRowIndex(items, groupId, subGroupId) {
    return items.findIndex(
        (item) => item.groupId === groupId && item.subGroupId === subGroupId && item.displaySubGroupName !== ""
    );
}

export function sortItemListByPosition(items) {
    return items.sort((a, b) => {
        return a.position - b.position; //? 1 : -1;
    });
}

export function getAllItemsWithoutGroupSubGroupAndDbExtra(items) {
    return items.filter(
        (item) =>
            item.stStockCode !== "" &&
            item.groupDbExtraId === "" &&
            item.subGroupDbExtraId === "" &&
            item.nonGroupDbExtraId === ""
    );
}

// export function getDbExtraRowIndex(items, groupId, subGroupId, type) {
//     let index = -1;

//     switch (type) {
//         case GroupTypes.GROUP:
//             if (items && items.length > 0 && groupId !== null)
//                 index = items.findIndex((item) => {
//                     return item.groupId === groupId && item.groupDbExtraId !== "" && item.subGroupId === "";
//                 });
//             break;
//         case GroupTypes.SUB_GROUP:
//             if (items && items.length > 0 && groupId !== null && subGroupId !== null)
//                 index = items.findIndex((item) => {
//                     return item.groupId === groupId && item.subGroupId === subGroupId && item.subGroupDbExtraId !== "";
//                 });
//             break;
//         case GroupTypes.NON_GROUP:
//             if (items && items.length > 0 && groupId !== null)
//                 index = items.findIndex((item) => {
//                     return item.nonGroupDbExtraId !== "" && item.groupId === groupId;
//                 });
//             break;
//         default:
//             break;
//     }

//     return index;
// }

export function getRowIndex(items, rowId) {
    return items.findIndex((item) => item.rowId === rowId);
}

export function getAllGroupRows(items) {
    return items.filter((item) => item.rowType === "G");
}

export function getAllSubGroupRows(items, groupId) {
    return items.filter((item) => item.rowType === "SG" && item.groupId === groupId);
}

export function getItemsWithoutAIandDX(items) {
    return items.filter((item) => item.rowType !== "AI" && item.rowType !== "DX");
}

export function getAllNonGroupedItems(items) {
    return items.filter((item) => item.groupName === GroupItemTypes.NON_GROUPED_ITEMS || item.groupName === "");
}
