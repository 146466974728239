import React, { useState, useEffect, useContext, useRef } from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useParams } from "react-router-dom";
import DataGridView from "../Layouts/DataGridView";
import { Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getResponse } from "../../Services/Common/Get.js";
import { QuoteCustomerContext } from "../../Contexts/QuoteCustomerContext"; // adjust path as needed
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { toast, Slide } from "react-toastify";
import { postResponse } from "../../Services/Common/Post";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { set } from "lodash";
import { data } from "jquery";

const QuotesLookup = ({
    toggleQuotesModal,
    getQuoteDetailsById,
    setSelectedRowsParent,
    selectedRows,
    setSelectedRows,
    defaultTab
}) => {
    const [selected, setSelected] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const [recentQuotes, setRecentQuotes] = useState([]);
    const [sortedRecentAllQuotes, setSortedRecentAllQuotes] = useState([]);
    const [sortedDraftAllQuotes, setSortedDraftAllQuotes] = useState([]);
    const [sortedApprovedQuotes , setSortedApprovedQuotes] = useState([]);
    const [sortedPendingQuotes , setSortedPendingQuotes] = useState([]);
    const [sortedRejectedQuotes , setSortedRejectedQuotes] = useState([]);
    const [recentAllQuotes, setRecentAllQuotes] = useState([]);
    const [draftAllQuotes, setDraftAllQuotes] = useState([]);
    const [approvedSearchQuotes, setApprovedSearchQuotes] = useState([]);
    const [pendingSearchQuotes, setPendingSearchQuotes] = useState([]);
    const [topTenQuotes, setTopTenQuotes] = useState([]);
    const [rejectedSearchQuotes, setRejectedSearchQuotes] = useState([]);
    //const [selectedRows, setSelectedRows] = useState([]);
    const { quoteCustomer, setQuoteCustomer } = useContext(QuoteCustomerContext);
    const [totalQuotesCount,setTotalQuotesCount]=useState(0);
    const [totalQuotesDraftCount,setTotalQuotesDraftCount] = useState(0); 
    const [totalQuotesPendingCount,setTotalQuotesPendingCount] = useState(0); 
    const [totalQuotesApprovedCount,setTotalQuotesApprovedCount] = useState(0);
    const [totalQuotesRejectedCount,setTotalQuotesRejectedCount] = useState(0);
    const user = useContext(UserContext);
    const { subNodeTitle } = useParams();
    const [pageNumber, setPageNumber] = useState(1);
    const PAGE_SIZE = 50;
    const [isNew, setIsNew] = useState(true);
    const [itemspageNumber, setitemsPageNumber] = useState(1);
    const [itemspageSize, setitemsPageSize] = useState(50);
    const [skipSelectItems, setSkipSelectItems] = useState(0);
    const [takeSelectItems, setTakeSelectItems] = useState(50);
    const [total, setTotal] = useState(0);
    const [disable, setDisable] = useState(true);

    const [approvedQuotes, setApprovedQuotes] = useState([]);
    const [pendingQuotes, setPendingQuotes] = useState([]);
    const [rejectedQuotes, setRejectedQuotes] = useState([]);
    const filterColumns = [
        { field: "quoteNumber", title: "Quote Number" },
        { field: "customerNo", title: "Customer No" },
        { field: "customerName", title: "Customer Name" }
    ];

    const filterOptions = filterColumns.map(column => ({ text: column.title, value: column.field }));
    const [searchFilterValue, setSearchFilterValue] = useState(filterOptions[0]);


    const handleSearchChange = (e) => {
        if (searchResults.length > 0) setSearchResults([]);
        setSearchValue(e.target.value);
        setDisable(false);
    };

    const handleSearchFilterChange = (e) => {
        setSearchFilterValue(e.target.value);
    };


    const formatDateCustom = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is zero-based
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleClearSearchClick  = (e) => {
        setSearchValue('');
        setSearchResults([]);
        setTotalQuotesCount(sortedRecentAllQuotes.length);
        setTotalQuotesDraftCount(sortedDraftAllQuotes.length);
        setTotalQuotesPendingCount(pendingSearchQuotes.length);
        setTotalQuotesApprovedCount(approvedSearchQuotes.length);
        setTotalQuotesRejectedCount(rejectedSearchQuotes.length);
        setDisable(true);
        setRecentQuotes(topTenQuotes);
        
    };
  
    
    const handleSearchClick = async () => {
        // Trim the search value to remove leading and trailing whitespace
        const trimmedSearchValue = searchValue.trim() + "#" + searchFilterValue.value;
        
        // Check if the search value is empty
        if (trimmedSearchValue === "") {
            setDisable(true);
            // Show a toast notification
            toast.error("Please Enter Some Search Keyword ", "Search Warning", 3000, {
                position: "top-center",
                transition: Slide,
               
            });    
            return;
        }
    
        let endpoint;
        let query;
    
        const normalizeValue = (value) => {
            return value.toUpperCase();
        };
    
        const normalizedSearchValue = normalizeValue(searchValue.trim());
    
        if (selected === 0) {
            // Filter topTenQuotes based on the trimmedSearchValue
            const filteredQuotes = topTenQuotes.filter(quote => {
                return (
                    quote.quoteNumber.includes(normalizedSearchValue) ||
                    quote.customerNo.includes(normalizedSearchValue) ||
                    quote.customerName.includes(normalizedSearchValue)
                );
            });
    
            const processedQuotes = filteredQuotes.map(quote => {
                const statusIdProp = quote.approvalStatusId;
                const formattedQuoteDate =  formatDateCustom(quote?.createdOn);
    
                return {
                    ...quote,
                    quoteDate: formattedQuoteDate,
                    status: statusIdProp === 4 ? 'Approved' :
                            (statusIdProp === 2 ? 'Pending Approval' :
                            (statusIdProp === 1 ? 'Approval Required' :
                            (statusIdProp === 5 ? 'Rejected' : 'No Approval Required'))),
                    customerNo: quote.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                    customerName: quote.tblQuoteCustomers?.map(c => c.customerName).join(", "),
                    projectName: quote.projectName,
                    quoteNumber: quote.quoteNumber,
                    owner: quote.owner
                };
            });
    
            setSearchResults(processedQuotes);
            setRecentQuotes(processedQuotes);

            
    
            if (processedQuotes.length === 0) {
                toast.error("No Records Available. Please Try Searching Again for the Valid Record", { autoClose: 3000, position: "top-center", transition: Slide });
            }
    
            return;
        }
     else if (selected === 1) {
            // All Quotes tab
            endpoint = "Quote/searchallquotes";
            query = {
                name: trimmedSearchValue,
                filter: "all",
                pageNumber: itemspageNumber,
                pageSize: PAGE_SIZE,
                approvalStatusId: 0
            };
        } else if ([2, 3, 4].includes(selected)) {
            // Approved, Pending, and Rejected Quotes tabs
            endpoint = "Quote/searchapprovalquotes";
            query = {
                createdBy: user?.homeAccountId,
                filterNumber: trimmedSearchValue,
                pageNumber: itemspageNumber,
                pageSize: PAGE_SIZE,
                approvalStatusId :selected === 2 
                    ? 4
                    : selected === 3
                        ? 2
                        : 5 
            };
        }
        else if (selected === 5) {
            // Draft Quotes tab
            endpoint = "QuoteDraft/searchDraftquote";
            query = {
                createdBy: user?.homeAccountId,
                filterNumber: trimmedSearchValue,
                pageNumber: itemspageNumber,
                pageSize: PAGE_SIZE,
            };
        }
    
        try {
            const res = await postResponse(endpoint, query);
            if ( res.data && res.data.length > 0) {
                const isApprovalQuotesSearch = endpoint === "Quote/searchapprovalquotes";
            const processedQuotes = res.data.map(quote => {
                const statusIdProp = isApprovalQuotesSearch ? quote.approvalStatusID : quote.approvalStatusId;
                // Format date fields
                const formattedQuoteDate = formatDateCustom(quote.createdOn);
                const formattedExpirationDate = quote.expirationDate ? formatDateCustom(quote.expirationDate) : null;
                const formattedApprovedDateTime = quote.approvedDateTime ? formatDateCustom(quote.approvedDateTime) : null;

                return {
                    ...quote,
                    quoteDate: formattedQuoteDate,
                    expirationDate: formattedExpirationDate,
                    approvedDateTime: formattedApprovedDateTime,
                    status: statusIdProp === 4 ? 'Approved' :
                            (statusIdProp === 2 ? 'Pending Approval' :
                            (statusIdProp === 1 ? 'Approval Required' :
                            (statusIdProp === 5 ? 'Rejected' : 'No Approval Required'))),
                    customerNo: [5].includes(selected) ? quote.tblQuoteCustomers_Drafts?.map(c => c.customerNumber).join(", ") :
                            [2,3,4].includes(selected) ? quote.originalQuote.tblQuoteCustomers?.map(c => c.customerNumber).join(", ") : 
                            quote.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                    customerName: [5].includes(selected) ? quote.tblQuoteCustomers_Drafts?.map(c => c.customerName).join(", ") :
                            [2,3,4].includes(selected) ? quote.originalQuote.tblQuoteCustomers?.map(c => c.customerName).join(", ") : 
                            quote.tblQuoteCustomers?.map(c => c.customerName).join(", "),
                    projectName: [2,3,4].includes(selected) ? quote.originalQuote.projectName : quote.projectName,
                    quoteNumber: [2,3,4].includes(selected) 
                        ? typeof quote.quoteNumber === "undefined" || quote.quoteNumber === null 
                            ? quote.originalQuote.quoteNumber 
                            : quote.quoteNumber 
                        :  quote.quoteNumber,
                    owner: [2,3,4].includes(selected) ? quote.originalQuote.owner : quote.owner

                };
            });
                setSearchResults(processedQuotes); 
                setApprovedSearchQuotes(processedQuotes.filter(quote => quote.approvalStatusID === 4));
                setPendingSearchQuotes(processedQuotes.filter(quote => quote.approvalStatusID === 2));
                setRejectedSearchQuotes(processedQuotes.filter(quote => quote.approvalStatusID === 5));
                setRecentQuotes(processedQuotes);

                if (res.statusCode) {
                    setTotal(res.count);
                    setTotalQuotesCount(res.count);
                    setTotalQuotesDraftCount(res.count);
                    setTotalQuotesPendingCount(res.count);
                    setTotalQuotesApprovedCount(res.count);
                    setTotalQuotesRejectedCount(res.count);
                    console.log("setSearchResults:", res);
                }
            }
                
           
            if (!res.data || res.data.length < 1) {
                toast.error("No Records Available. Please Try Searching Again for the Valid Record", { autoClose: 3000,  position: "top-center", transition: Slide, });
            }
        } catch (error) {
            console.error("Error during search:", error);
        }
    };
    
    useEffect(() => {
        setSelected(defaultTab);
    },[]);
    
    useEffect(() => {
        if (!isNew && searchValue) {
            handleSearchClick();
        } else {
            setIsNew(false);
        }
    }, [itemspageNumber]);

    useEffect(() => {
        const fetchRecentQuotes = async () => {
            const currentUser = user?.homeAccountId;
            try {
                const data = await getResponse("Quote/getquotes", { createdBy: currentUser });
                if (data && data.length > 0) {
                    const processedQuotes = data.map(quote => ({
                        ...quote,
                        quoteDate: formatDateCustom(quote.createdOn),
                        status: quote.approvalStatusId === 4 ? 'Approved' :
                                (quote.approvalStatusId === 2 ? 'Pending Approval' :
                                (quote.approvalStatusId === 1 ? 'Approval Required' :
                                (quote.approvalStatusId === 5 ? 'Rejected' : 'No Approval Required'))),
                        customerNo: quote.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                        customerName: quote.tblQuoteCustomers?.map(c => c.customerName).join(", ")
                    }));
                    const sortedQuotes = processedQuotes.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                    setTopTenQuotes(sortedQuotes.slice(0, 10));
                    setRecentQuotes(sortedQuotes.slice(0, 10));
                }
            } catch (error) {
                console.error("Error during fetching recent quotes:", error);
            }
        };
        fetchRecentQuotes();
    }, []);
    useEffect(() => {
        const fetchRecentAllQuotes = async () => {
            try {
                const data = await getResponse("Quote/getallquoteswithcustomers");
                if (data && data.length > 0) {
                    const processedQuotes = data.map(quote => ({
                        ...quote,
                        quoteDate: formatDateCustom(quote.createdOn),
                        status: quote.approvalStatusId === 4 ? 'Approved' :
                                (quote.approvalStatusId === 2 ? 'Pending Approval' :
                                (quote.approvalStatusId === 1 ? 'Approval Required' :
                                (quote.approvalStatusId === 5 ? 'Rejected' : 'No Approval Required'))),
                        customerNo: quote.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                        customerName: quote.tblQuoteCustomers?.map(c => c.customerName).join(", ")
                    }));
                    const sortedQuotes = processedQuotes.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn));
                    setRecentAllQuotes(sortedQuotes); 
                    setTotalQuotesCount(sortedQuotes.length);
                    setSortedRecentAllQuotes(sortedQuotes);
                }
            } catch (error) {
                console.error("Error during fetching recent quotes:", error);
            }
        };
    
        fetchRecentAllQuotes();
    }, []);
    useEffect(() => {
        const fetchDraftAllQuotes = async () => {
            try {
                const currentUser = user?.homeAccountId;
                const data = await getResponse("QuoteDraft/getallDraftquoteswithcustomers", { UserId: currentUser });
                if (data && data.length > 0) {
                    const processedQuotes = data.map(quote => ({
                        ...quote,
                        quoteDate: formatDateCustom(quote.createdOn), // Format the createdOn date
                        expirationDate : formatDateCustom(quote.expirationDate),
                        customerNo: quote.tblQuoteCustomers_Drafts?.map(c => c.customerNumber).join(", "),
                        customerName: quote.tblQuoteCustomers_Drafts?.map(c => c.customerName).join(", ")
                        // Apply formatDateCustom to other date fields as necessary
                    }));
                    const sortedQuotes = processedQuotes.sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn)); 
                    console.log("sortedQuotes :",sortedQuotes)  
                    setDraftAllQuotes(sortedQuotes); 
                    setTotalQuotesDraftCount(sortedQuotes.length);

                    setSortedDraftAllQuotes(sortedQuotes);      
                }
            } catch (error) {
                console.error("Error during fetching recent quotes:", error);
            }
        };
    
        fetchDraftAllQuotes();
    }, []);

    useEffect(() => {
        const fetchQuotes = async () => {
            const currentUser = user?.homeAccountId;
            try {
                const response = await getResponse("Quote/getapprovalquotes", { LoggedUserID: currentUser });
                if (response && response.length > 0) {
                    const processedQuotes = response.map(quote => {
                        // Determine the status based on approvalStatusID
                        const status = quote.approvalStatusID === 4
                            ? 'Approved'
                            : (quote.approvalStatusID === 2 ? 'Pending Approval' : 'Rejected');
        
                        // Only show latest approver if it's pending and currentUser is not the latest approver
                        //const showLatestApprover = quote.approvalStatusID === 2 && currentUser !== quote.approvedUserID;
                        const showLatestApprover = quote.approvalStatusID === 2 && quote.originalQuote.createdBy !== currentUser;
                        //console.log("quoteapprover :", quote.latestApproverUserId)
                        console.log("processing--" + quote.quoteID);
                        return {
                            ...quote,
                            approvedDateTime: new Date(quote.approvedDateTime), // Ensure approvedDateTime is a Date object
                            status: status,
                            projectName: quote.projectName,
                            quoteNumber: quote?.quoteNumber,
                            fullName: quote.fullName,
                            createdUserEmail: quote?.email,
                            customerNo: quote.originalQuote?.tblQuoteCustomers?.map(c => c.customerNumber).join(", "),
                            customerName: quote.originalQuote?.tblQuoteCustomers?.map(c => c.customerName).join(", "),
                            latestApproverFullName: showLatestApprover ? quote.latestApprover : "", // Conditionally show latest approver
                            //latestApproverEmail: showLatestApprover ? quote.latestApproverEmail : "", // Conditionally show latest approver
                        };
                        
                    });
        
                    // Filter and sort approved quotes
                    const approvedQuotes = processedQuotes.filter(quote => quote.approvalStatusID === 4);
                    const sortApprovedQuotes = (approvedQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setSortedApprovedQuotes(approvedQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setTotalQuotesApprovedCount(approvedQuotes.length);
        
                    // Filter and sort pending quotes
                    const pendingQuotes = processedQuotes.filter(quote => quote.approvalStatusID === 2);
                    const sortPendingQuotes = (pendingQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setSortedPendingQuotes(pendingQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setTotalQuotesPendingCount(pendingQuotes.length);
        
                    // Filter and sort rejected quotes
                    const rejectedQuotes = processedQuotes.filter(quote => quote.approvalStatusID === 5);
                    const sortRejectedQuotes = (rejectedQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setSortedRejectedQuotes(rejectedQuotes.sort((a, b) => b.approvedDateTime - a.approvedDateTime));
                    setTotalQuotesRejectedCount(rejectedQuotes.length);
        
                    // Convert the approvedDateTime back to formatted strings for rendering
                    const formattedApprovedQuotes = sortApprovedQuotes.map(quote => ({
                        ...quote,
                        approvedDateTime: formatDateCustom(quote.approvedDateTime)
                    }));
                    setApprovedQuotes(formattedApprovedQuotes);
        
                    const formattedPendingQuotes = sortPendingQuotes.map(quote => ({
                        ...quote,
                        approvedDateTime: formatDateCustom(quote.approvedDateTime)
                    }));
                    setPendingQuotes(formattedPendingQuotes);
        
                    const formattedRejectedQuotes = sortRejectedQuotes.map(quote => ({
                        ...quote,
                        approvedDateTime: formatDateCustom(quote.approvedDateTime)
                    }));
                    setRejectedQuotes(formattedRejectedQuotes);
                }
            } catch (error) {
                console.error("Error during fetching quotes:", error);
            }
        };
        
    
        fetchQuotes();
    }, [selected]);
    
    
    
    
    const selectColumns = [
        // { field: "quoteId", title: "Quote Id" },
        { field: "projectName", title: "Project Name" },
        { field: "quoteNumber", title: "Quote Number" },
        { field: "fullName", title: "Created By" },
        { field: "email", title: "Created By Email" },
        { field: "customerNo", title: "Customer(s) No." },
        { field: "customerName", title: "Customer(s) Name" },
        { field: "quoteDate", title: "Created Date", format: "{0:MM/dd/yyyy}" },
        { field: "status", title: "Status" }
    ];
    const selectDraftColumns = [
        // { field: "quoteId", title: "Quote Id" },
        { field: "projectName", title: "Project Name" },
        { field: "quoteNumber", title: "Quote Number" },
        { field: "fullName", title: "Created By" },
        { field: "email", title: "Created By Email" },
        { field: "customerNo", title: "Customer(s) No." },
        { field: "customerName", title: "Customer(s) Name" },
        { field: "quoteDate", title: "Created Date", format: "{0:MM/dd/yyyy}" },
         {field: "expirationDate", title: "Expiry Date", format: "{0:MM/dd/yyyy}"}
    ];
    
    const selectAprovalColumns = [
        { field: "projectName", title: "Project Name" },
        { field: "quoteNumber", title: "Quote Number" },
        { field: "fullName", title: "Created By" },
        { field: "email", title: "Created By Email" },
        { field: "customerNo", title: "Customer(s) No." },
        { field: "customerName", title: "Customer(s) Name" },
        ...(selected === 3 ? [{ field: "latestApproverFullName", title: "Escalated To" }] : []),
        { field: "approvedDateTime", title: "Approval Date", format: "{0:MM/dd/yyyy}" },
        { field: "status", title: "Status" }
    ];
    

    const paginate = (dataToDisplay,  pageNumber, itemspageSize) => {
        const startIndex = (pageNumber - 1) * itemspageSize;
        return dataToDisplay.slice(startIndex, startIndex + itemspageSize);
    };
    const dataToDisplay = searchResults.length > 0 && searchValue ? searchResults : paginate(recentAllQuotes, itemspageNumber, itemspageSize);
    const displayRejected = searchResults.length > 0 && searchValue ? rejectedSearchQuotes :paginate(rejectedQuotes, itemspageNumber, itemspageSize);
    const displayPending = searchResults.length > 0 && searchValue ? pendingSearchQuotes :paginate(pendingQuotes, itemspageNumber, itemspageSize);
    const displayApproved = searchResults.length > 0 && searchValue ? approvedSearchQuotes :paginate(approvedQuotes, itemspageNumber, itemspageSize);
    const displayDraft = searchResults.length > 0 && searchValue ? searchResults : draftAllQuotes;

    // const paginateforRejected = (displayRejected,  pageNumber, itemspageSize) => {
    //     const startIndex = (pageNumber - 1) * itemspageSize;
    //     return displayRejected.slice(startIndex, startIndex + itemspageSize);
    // };

    // const paginateforPending = (displayPending,  pageNumber, itemspageSize) => {
    //     const startIndex = (pageNumber - 1) * itemspageSize;
    //     return displayPending.slice(startIndex, startIndex + itemspageSize);
    // };

    // const paginateforApproved = (displayApproved,  pageNumber, itemspageSize) => {
    //     const startIndex = (pageNumber - 1) * itemspageSize;
    //     return displayApproved.slice(startIndex, startIndex + itemspageSize);
    // };

    // const paginateforDraft = (displayDraft,  pageNumber, itemspageSize) => {
    //     const startIndex = (pageNumber - 1) * itemspageSize;
    //     return displayDraft.slice(startIndex, startIndex + itemspageSize);
    // };
    
    const handlePageChangeSelectItems = ({ skip, take }) => {
        setSkipSelectItems(skip);
        setTakeSelectItems(take);
        setitemsPageNumber(skip / take + 1);
    };
    // // Monitor for changes in the selectedRows state
    // useEffect(() => {
    //   // Function to call the API endpoint
    //   const fetchSelectedQuoteCustomers = async () => {
    //     if (selectedRows.length > 0) { // ensure there is a selected row
    //       const quoteId = selectedRows[0].quoteId; // Assumes single select is true, so we only care about first item
    //       const quoteNumber = selectedRows[0].quoteNumber; // Get quoteNumber from selected row
    //       try {
    //         const data = await getResponse('Quote/getfilteredquotecustomers', { quoteId: quoteId });
    //         setQuoteCustomer(data);// Do something with the data
    //         onSelectQuote({quoteNumber, selectedRows}); // Pass back the quote number and the selected rows
    //         console.log('Selected quote customers: ', data);
    //       } catch (error) {
    //         console.error('Error during fetching quote customers:', error);
    //       }
    //     }
    //   };
    //   // Call the function
    //   fetchSelectedQuoteCustomers();
    // }, [selectedRows]); // useEffect dependency array - re-run when selectedRows changes

    const handleSelection = (selectedRows) => {
        setSelectedRows(selectedRows); // set selected rows in the local state
        setSelectedRowsParent(selectedRows); // propagate the change to the parent component
    };

    const handleSelect = (e) => {
        setSelected(e.selected);
        searchResults.length > 0 && setSearchResults([]);
        searchValue.length > 0 && setSearchValue("");
        setTotal(recentQuotes.length);
        setTotalQuotesCount(sortedRecentAllQuotes.length);
        setTotalQuotesDraftCount(sortedDraftAllQuotes.length);
        setTotalQuotesPendingCount(sortedPendingQuotes.length);
        setTotalQuotesApprovedCount(sortedApprovedQuotes.length);
        setTotalQuotesRejectedCount(sortedRejectedQuotes.length);
        setRecentQuotes(topTenQuotes);
    };

      const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            handleSearchClick();
        }
        if(searchValue.length === 0){
            setDisable(true);
        }
    };

    useEffect(() => {
        if(searchValue.length === 0){
            setDisable(true);
        }
    }, [searchValue]);
   


    const changePage = (event) => {
        const targetEvent = event.targetEvent;
        const take = targetEvent.value === "All" ? 77 : event.page.take;
        // if (targetEvent.value) {
        //     setPageSizeValue(targetEvent.value);
        // }
        // setPage({
        //     ...event.page,
        //     take,
        // });
    };
   console.log("totalQuotesCount :",totalQuotesCount)
   console.log("total :",total)
   console.log("search value :",searchValue)
   console.log("data :",paginate(dataToDisplay, itemspageNumber, itemspageSize))
    return (
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <div className="col-12 ip_search_container">
                <Input
                    className="me-1"
                    value={searchValue}
                    onChange={handleSearchChange}
                    onKeyDown={handleKeyPress}
                    placeholder="Search keyword..."
                />

                <DropDownList
                    className="me-1"
                    data={filterOptions}
                    textField="text"
                    dataItemKey="value"
                    value={searchFilterValue}
                    onChange={handleSearchFilterChange}
                 />
                            
                <Button id = "btnOpQteFind"  onClick={handleSearchClick} disabled = {disable}>
                    <strong>Find</strong>
                </Button>
                &nbsp;
                <Button id = "btnOpQteFindClear" onClick={handleClearSearchClick} disabled={disable}>
                    <strong>Clear</strong>
                </Button>
            </div>

            <br />
            <hr />

            <TabStrip selected={selected} onSelect={handleSelect}>
            {/* <TabStripTab title="All Quotes">
                    <DataGridView data={[]} columns={selectColumns} idField="quoteId" tableName="All Quotes" showSelectionColumn={false} />
                </TabStripTab> */}

                {/* <TabStripTab title="My Quotes"> */}
                    {/* <DataGridView
                        data={searchResults}
                        columns={selectColumns}
                        idField="quoteId"
                        tableName="My Quotes"
                        showEmptyRow={false}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        singleSelect={true}
                        getQuoteDetailsById={getQuoteDetailsById}
                        total={searchResults?.count}
                        pageable={true}
                        pageSize={PAGE_SIZE}
                        onPageChange={({ skip, take }) => {
                            const page = skip / take + 1;
                            setPageNumber(page);
                        }}
                        //onPageChange={changePage}
                    /> */}
                {/* </TabStripTab> */}

                <TabStripTab title="My Recent Quotes">
                    <DataGridView
                        data={(recentQuotes)}
                        columns={selectColumns}
                        idField="quoteId"
                        tableName="Recent Quotes"
                        total={recentQuotes?.count}
                        showSelectionColumn={true}
                        showEmptyRow={false}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        getQuoteDetailsById={getQuoteDetailsById}
                        singleSelect={true}
                    />
                </TabStripTab>
                <TabStripTab title="All Quotes">                     
                    <DataGridView
                    data={searchValue ? dataToDisplay : paginate(recentAllQuotes, itemspageNumber, itemspageSize)}
                   //data={searchResults.length > 0 && searchValue ? searchResults : recentAllQuotes}
                    columns={selectColumns}
                    idField="quoteId"
                    tableName="All Quotes"
                    showSelectionColumn={true}
                    showEmptyRow={false}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    skip = {skipSelectItems}
                    take={takeSelectItems}
                    getQuoteDetailsById={getQuoteDetailsById}
                    singleSelect={true}
                    total={totalQuotesCount} 
                  //  onPageChange={handlePageChange}
                  onPageChange={handlePageChangeSelectItems}
                        
                />
                </TabStripTab>

                <TabStripTab title="Approved Quotes">
                        <DataGridView
                            data={searchValue ? displayApproved : paginate(approvedQuotes , itemspageNumber, itemspageSize)}
                            //data = {approvedQuotes}
                            columns={selectAprovalColumns}
                            idField="quoteID"
                            tableName="Approved Quotes"  
                            showSelectionColumn={true}
                            showEmptyRow={false}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            skip = {skipSelectItems}
                            take={takeSelectItems}
                            singleSelect={true}
                            getQuoteDetailsById={getQuoteDetailsById}
                            total={totalQuotesApprovedCount}
                            pageable={true}
                            pageSize={PAGE_SIZE}
                            onPageChange={handlePageChangeSelectItems}
                        />
                </TabStripTab>
                <TabStripTab title="Pending Quotes">
                    <div className="ql-draftquote-note"><i className="ri-information-2-fill"></i>Some received approval requests may not be listed here if they no longer require approval</div>
                    <DataGridView
                        data={searchValue ? displayPending : paginate(pendingQuotes , itemspageNumber, itemspageSize)}
                        columns={selectAprovalColumns}
                        idField="quoteID"
                        tableName="Pending Quotes"
                        showSelectionColumn={true}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        skip = {skipSelectItems}
                        take={takeSelectItems}
                        showEmptyRow={false}
                        singleSelect={true}
                        getQuoteDetailsById={getQuoteDetailsById}
                        total={totalQuotesPendingCount}
                        pageable={true}
                        pageSize={PAGE_SIZE}
                        onPageChange={handlePageChangeSelectItems}
                    />
                </TabStripTab>

                <TabStripTab title="Rejected Quotes">
                <DataGridView
                    data={searchValue ? displayRejected : paginate(rejectedQuotes , itemspageNumber, itemspageSize)}
                    columns={selectAprovalColumns}
                    idField="quoteID"
                    tableName="Rejected Quotes"
                    showSelectionColumn={true}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    skip = {skipSelectItems}
                    take={takeSelectItems}
                    showEmptyRow={false}
                    singleSelect={true}
                    getQuoteDetailsById={getQuoteDetailsById}
                    total={totalQuotesRejectedCount}
                    pageable={true}
                    pageSize={PAGE_SIZE}
                    onPageChange={handlePageChangeSelectItems}
                />
                </TabStripTab>

                <TabStripTab title="Draft Quotes">
                        <div className="ql-draftquote-note"><i className="ri-information-2-fill"></i>Draft quote will be discarded after expiration date.</div>
                        <DataGridView
                            data={paginate(displayDraft , itemspageNumber, itemspageSize)}
                            //data = {searchResults}
                            columns={selectDraftColumns}
                            idField="quoteId"
                            tableName="Draft Quotes"
                            showSelectionColumn={true}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            showEmptyRow={false}
                            singleSelect={true}
                            skip = {skipSelectItems}
                            take={takeSelectItems}
                            getQuoteDetailsById={getQuoteDetailsById}
                            total={totalQuotesDraftCount}
                            pageable={true}
                            pageSize={PAGE_SIZE}
                            onPageChange={handlePageChangeSelectItems}
                        />
                </TabStripTab>

                {/* <TabStripTab title="Shared Quotes">
                    <div>
                        <DataGridView
                            data={[]} // Replace this with your data
                            columns={selectColumns}
                            idField="quoteId"
                            tableName="Shared Quotes"
                        />
                    </div>
                </TabStripTab> */}
            </TabStrip>
        </div>
    );
};

export default QuotesLookup;
