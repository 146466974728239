import React, { useState, useEffect, useContext, useRef } from 'react';
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { Input, Checkbox } from '@progress/kendo-react-inputs';
import { getResponse } from "../../Services/Common/Get";
import { putResponse } from "../../Services/Common/Put";
import { TreeView } from '@progress/kendo-react-treeview';
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import { toast, Slide } from "react-toastify";
import { Button } from "@progress/kendo-react-buttons";
import { Interweave } from 'interweave';
import Spinner from "../Common/Spinner";
import { useParams } from "react-router-dom";
import useQuoteState from '../../utils/useQuoteState';
import { useNodeContext } from "../../Contexts/NodeProvider";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";

const TermsAndConditions = ({ updateTrigger,setTermsDialogVisible}) => {
    const { id } = useParams();
    const {
        loading, setLoading,
        selectedNode, setSelectedNode,
        data, setData,
        header, setHeader,
        showOnQuote, setShowOnQuote,
        selectedTab, setSelectedTab,
        editorContent, setEditorContent,
        mainNodeData, setMainNodeData,
        defaultEditorContent, setDefaultEditorContent,
        appendedContent, setAppendedContent,
        previousNode, setPreviousNode,
        appendedNodes, setAppendedNodes,
        storedCombinedItems, setStoredCombinedItems,
        toDefaultConfirmed, setToDefaultConfirmed,
        editorModified, setEditorModified,
        nodeChanges, setNodeChanges,
        isMainNode, setIsMainNode,
        isClear, setIsClear,
        checkboxStates, setCheckboxStates
    } = useQuoteState(id);
    const {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        handleCloseNode,
        handleDeleteNode,
        handleDeleteOpenQuote,
        handleNodeWhenUpdating,
        handleNodeWhenSaving,
        setIsQuoteSaved,
        openQuoteCount,
        quoteUpdated,
        setQuoteUpdated,
        quoteContactDetailsMobile,
        setQuoteContactDetailsMobile,
        isSelectedUnitAdded,
        setIsSelectedUnitAdded,
        isPastedUnits,
        setIsPastedUnits,
        isRemoveItemsClicked,
        isOldQuoteDone,
        setIsOldQuoteDone,
        setIsRemoveItemsClicked,
        hideTotalState, setHideTotalState,
        quoteUpdateTriggered, setQuoteUpdateTrigger,
        quoteContactTypes, setQuoteContactTypes, module, setModule, saveRef, saveQuotation,
        isDoneClickForPdf, setIsDoneClickForPdf,
        openShowProgress, setOpenShowProgress,
        importItemShowProgress, setImportItemShowProgress,
        copyPasteItemShowProgress, setCopyPasteItemShowProgress,
        progress, setProgress,
        isDoneChangesForQuoteTerms, setIsDoneChangesForQuoteTerms,
        hasQuoteChanged, setHasQuoteChanged
    } = useNodeContext();
    const { quoteDetails } = useQuoteDetails();
    const user = useContext(UserContext);
    const editorRef = useRef();

    const {
        Bold,
        Italic,
        Underline,
        Strikethrough,
        OrderedList,
        UnorderedList,
    } = EditorTools;

    const styles = `
    p {
        font-family: Poppins, Arial, sans-serif;
        font-size: 12px;
    }
    `;

    const onMountStyle = (event) => {
        const iframeDocument = event.dom.ownerDocument;
        const style = iframeDocument.createElement("style");
        style.appendChild(iframeDocument.createTextNode(styles));
        iframeDocument.head.appendChild(style);
    };

    const parseQuoteTermsAndConditions = (htmlString) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        const listItems = doc.querySelectorAll('li[data-id]');

        const parsedData = Array.from(listItems).map(item => {
            const id = item.getAttribute('data-id');
            const text = item.querySelector('strong').innerHTML;
            const templateDescription = item.innerHTML.replace(`<strong>${text}</strong><br>`, '');

            return {
                id,
                text,
                templateDescription
            };
        });

        return parsedData;
    };
    const fetchAndUpdateData = async () => {
        try {
            const userId = user?.homeAccountId;
            const response = await getResponse("TermsCondition/GetCombinedTermsAndConditions", { userId });

            const treeData = response.map(item => {
                // Get the edited header from sessionStorage if it exists
                const storedHeader = sessionStorage.getItem(`editHeader-${id}-${item.id}`);
                return {
                    text: storedHeader || item.headerName,  // Use the edited header if available
                    id: item.id,
                    templateDescription: item.templateDescription
                };
            });
            const mainNode = {
                text: 'Terms and Condition',
                id: 0,
                expanded: true,
                items: treeData
            };

            // Fetch quote details
            const quoteTermsAndConditions = quoteDetails[id]?.quoteTermsAndConditions;
            const pdfQuoteTermsAndConditions = quoteDetails[id]?.pdfQuoteTermsAndConditions;

            // Retrieve existing checkbox states from sessionStorage
            const existingCheckboxStates = JSON.parse(sessionStorage.getItem('checkboxStates')) || {};

            if ((quoteTermsAndConditions != null || quoteTermsAndConditions !== undefined || quoteTermsAndConditions !== "") && (pdfQuoteTermsAndConditions === null || pdfQuoteTermsAndConditions === undefined || pdfQuoteTermsAndConditions === "")) {
                // Parse the quoteTermsAndConditions
                const parsedQuoteTermsAndConditions = parseQuoteTermsAndConditions(quoteTermsAndConditions);

                // Parse the pdfQuoteTermsAndConditions
                // const parsedPdfQuoteTermsAndConditions = parseQuoteTermsAndConditions(pdfQuoteTermsAndConditions);

                // // Extract IDs from parsedPdfQuoteTermsAndConditions
                // const pdfQuoteIds = parsedPdfQuoteTermsAndConditions.map(item => item.id);

                // // Filter parsedQuoteTermsAndConditions to only include those in pdfQuoteTermsAndConditions
                // const filteredTermsAndConditions = parsedQuoteTermsAndConditions.filter(item =>
                //     pdfQuoteIds.includes(item.id)
                // );

                setData([mainNode]);
                setMainNodeData(treeData);

                let aggregatedTemplate = '<ol>';
                parsedQuoteTermsAndConditions.forEach(item => {
                    aggregatedTemplate += `<li data-id="${item.id}"><strong>${item.text}</strong><br/>${item.templateDescription}</li>`;
                });
                aggregatedTemplate += '</ol>';
                setStoredCombinedItems(aggregatedTemplate);

                // Initialize subnode editor content with filtered data
                parsedQuoteTermsAndConditions.forEach(item => {
                    const storedContent = sessionStorage.getItem(`editorContent-${id}-${item.id}`);
                    const storedHeader = sessionStorage.getItem(`editHeader-${id}-${item.id}`);

                    // Retain current subnode's session storage editor content if it is different
                    if (storedContent !== null && storedContent !== item.templateDescription) {
                        sessionStorage.setItem(`editorContent-${id}-${item.id}`, storedContent);
                        sessionStorage.setItem(`editHeader-${id}-${item.id}`, storedHeader);
                    } else {
                        sessionStorage.setItem(`editorContent-${id}-${item.id}`, item.templateDescription);
                        sessionStorage.setItem(`editHeader-${id}-${item.id}`, item.text);
                    }

                    // Initialize checkbox states based on pdfQuoteTermsAndConditions, retaining existing false values
                    setCheckboxStates(prevStates => ({
                        ...prevStates,
                        [item.id]: parsedQuoteTermsAndConditions.some(term => term.id === item.id) ? true : existingCheckboxStates[item.id] !== undefined ? existingCheckboxStates[item.id] : false,
                    }));
                });
            }
            else if ((quoteTermsAndConditions != null || quoteTermsAndConditions !== undefined || quoteTermsAndConditions !== "") && pdfQuoteTermsAndConditions !== null || pdfQuoteTermsAndConditions !== undefined || pdfQuoteTermsAndConditions !== "") {

                const parsedQuoteTermsAndConditions = parseQuoteTermsAndConditions(quoteTermsAndConditions);

                // Parse the pdfQuoteTermsAndConditions
                const parsedPdfQuoteTermsAndConditions = parseQuoteTermsAndConditions(pdfQuoteTermsAndConditions);

                // Extract IDs from parsedPdfQuoteTermsAndConditions
                const pdfQuoteIds = parsedPdfQuoteTermsAndConditions.map(item => item.id);

                // Filter parsedQuoteTermsAndConditions to only include those in pdfQuoteTermsAndConditions
                const filteredTermsAndConditions = parsedQuoteTermsAndConditions.filter(item =>
                    pdfQuoteIds.includes(item.id)
                );

                setData([mainNode]);
                setMainNodeData(treeData);

                let aggregatedTemplate = '<ol>';
                filteredTermsAndConditions.forEach(item => {
                    aggregatedTemplate += `<li data-id="${item.id}"><strong>${item.text}</strong><br/>${item.templateDescription}</li>`;
                });
                aggregatedTemplate += '</ol>';
                setStoredCombinedItems(aggregatedTemplate);

                // Initialize subnode editor content with filtered data
                parsedQuoteTermsAndConditions.forEach(item => {
                    const storedContent = sessionStorage.getItem(`editorContent-${id}-${item.id}`);
                    const storedHeader = sessionStorage.getItem(`editHeader-${id}-${item.id}`);

                    // Retain current subnode's session storage editor content if it is different
                    if (storedContent !== null && storedContent !== item.templateDescription) {
                        sessionStorage.setItem(`editorContent-${id}-${item.id}`, storedContent);
                        sessionStorage.setItem(`editHeader-${id}-${item.id}`, storedHeader);
                    } else {
                        sessionStorage.setItem(`editorContent-${id}-${item.id}`, item.templateDescription);
                        sessionStorage.setItem(`editHeader-${id}-${item.id}`, item.text);
                    }

                    // Initialize checkbox states based on pdfQuoteTermsAndConditions, retaining existing false values
                    setCheckboxStates(prevStates => ({
                        ...prevStates,
                        [item.id]: filteredTermsAndConditions.some(term => term.id === item.id) ? true : (existingCheckboxStates[item.id] !== undefined ? existingCheckboxStates[item.id] : false),
                    }));
                });

            }
            else {
                setData([mainNode]);
                setMainNodeData(treeData);
                let aggregatedTemplate = '<ol>';
                treeData.forEach(item => {
                    aggregatedTemplate += `<li data-id="${item.id}"><strong>${item.text}</strong><br/>${item.templateDescription}</li>`;
                })
                aggregatedTemplate += '</ol>';
            }

            setDefaultEditorContent(storedCombinedItems);
            setSelectedNode(mainNode);
            handleTreeNodeClick({ item: mainNode }, treeData);

            setLoading(false);
        } catch (error) {
            console.error("Failed to fetch terms and conditions:", error);
            setLoading(false);
        }
    };





    useEffect(() => {
        // Clear storedCombinedItems when initializing a new quote
        setStoredCombinedItems("<ol></ol>");
        fetchAndUpdateData();
    }, [id]);

    const updateUserTemplateDescription = async () => {
        if (toDefaultConfirmed === false) {
            combinedContent();
            return;
        }

        if (editorModified === true) {
            for (const nodeId in nodeChanges) {
                if (nodeChanges.hasOwnProperty(nodeId)) {
                    const { header, content } = nodeChanges[nodeId];
                    const updateData = {
                        Id: nodeId,
                        NewDescription: content,
                        HeaderName: header,
                        UserId: user?.homeAccountId,
                    };

                     // Persist header changes to session storage
                sessionStorage.setItem(`editHeader-${id}-${nodeId}`, header);
                sessionStorage.setItem(`editorContent-${id}-${nodeId}`, content);

                    try {
                        await putResponse('TermsCondition/UpdateUserTemplateDescription', updateData);
                    } catch (error) {
                        console.error(`Failed to update terms and conditions for node ${nodeId}:`, error);
                        toast.error(`Error: ${error.message}`, {
                            position: "top-center",
                            transition: Slide,
                        });
                    }
                }
            }

            toast.success("Terms & Conditions are successfully updated", {
                position: "top-center",
                transition: Slide,
            });

            fetchAndUpdateData();
            setToDefaultConfirmed(false);
            setNodeChanges({});
        }
    };

    useEffect(() => {
        if (updateTrigger ) {
            updateUserTemplateDescription().then(() => {
                setTermsDialogVisible(false);
            });
        }
    }, [updateTrigger]);

    const handleToDefaultClick = () => {
        setToDefaultConfirmed(true);
    };

    const onExpandChange = event => {
        const updatedData = [...data];
        const itemIndex = updatedData.findIndex(item => item.id === event.item.id);
        if (itemIndex !== -1) {
            updatedData[itemIndex] = { ...updatedData[itemIndex], expanded: !updatedData[itemIndex].expanded };
            setData(updatedData);
        }
    };

    const getSpecificNodesContent = () => {
        const specificNodes = ["Pricing", "Delivery", "Validity"];
        return mainNodeData
            .filter(item => specificNodes.includes(item.text))
            .map(item => `<li><strong>${item.text}</strong><br/>${item.templateDescription}</li>`)
            .join('');
    };

    const isSpecialNodeSelected = () =>
        ["Pricing", "Delivery", "Validity"].includes(header);

    const isHeaderOnlydisabledchilnodes = () =>
        ["Exchange Variation", "Lead Times", "Drawing Details"].includes(header);

    const doesContentExist = (content, nodeText, nodeTemplateDescription) => {
        return content.includes(nodeText) && content.includes(nodeTemplateDescription);
    };

    const doesNodeTextExist = (content, nodeId) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        return !!doc.querySelector(`li[data-id="${nodeId}"]`);
    };

    const handleEditorChange = (e) => {
        setEditorContent(e.value);
        setEditorModified(true);
    };

    const handleEditorHeaderChange = (e) => {
        setHeader(e.value);
        setEditorModified(true);
    };

    const updateTemplateDescription = (content, nodeId, newHeader, newTemplateDescription) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(content, 'text/html');
        const liElem = doc.querySelector(`li[data-id="${nodeId}"]`);
        if (liElem) {
            liElem.innerHTML = `<strong>${newHeader}</strong><br/>${newTemplateDescription}`;
        }
        return doc.body.innerHTML;
    };

    const editorContentSave = () => {
        if (!editorRef.current) {
            console.error("Editor reference is not available.");
            return;
        }
        const editedHeaderValue = header;
        const HtmlContent = EditorUtils.getHtml(editorRef.current.view.state);
        const nodeId = selectedNode?.id;
        setNodeChanges({
            ...nodeChanges,
            [selectedNode.id]: {
                header: header,
                content: HtmlContent,
            },
        });
        if (nodeId != null) {
            sessionStorage.setItem(`editorContent-${id}-${nodeId}`, HtmlContent);
            sessionStorage.setItem(`editHeader-${id}-${nodeId}`, editedHeaderValue);
        }
    };

    useEffect(() => {
        if (editorModified) {
            editorContentSave();
        }
    }, [editorContent, header]);

    const handleTreeNodeClick = (event, treeData) => {
        if (selectedNode) {
            selectedNode.selected = false;
        }
        event.item.selected = true;

        const nodeId = event.item.id;
        setSelectedNode(event.item);
        const savedEditorContent = sessionStorage.getItem(`editorContent-${id}-${nodeId}`);
        const savedHeader = sessionStorage.getItem(`editHeader-${id}-${nodeId}`);

        if (savedEditorContent && !updateTrigger) {
            setEditorContent(savedEditorContent);
            setDefaultEditorContent(event.item.templateDescription);
            setHeader(savedHeader);
            return;
        } else {
            setEditorContent(event.item.templateDescription);
            setDefaultEditorContent(event.item.templateDescription);
            setHeader(event.item.text);
        }

        if (event.item.id === 0) {
            const aggregatedTemplate = `<ol>${combinedContent()}</ol>`;
            setEditorContent(aggregatedTemplate);

            const allTemplates = treeData ? `<ol>${treeData.map(item => `<li><strong>${item.text}</strong><br/>${item.templateDescription}</li>`).join('')}</ol>` : `<ol>${mainNodeData.map(item => `<li><strong>${item.text}</strong><br/>${item.templateDescription}</li>`).join('')}</ol>`;
            setDefaultEditorContent(allTemplates);
            setShowOnQuote(checkboxStates[nodeId] || false);
            return;
        }

        if (event.item.id !== 0 && previousNode && previousNode.id === 0) {
            setEditorContent(event.item.templateDescription);
            setDefaultEditorContent(event.item.templateDescription);
        } else {
            const appendedNode = appendedNodes.find(node => node.id === event.item.id);
            if (appendedNode) {
                setHeader(appendedNode.text);
                setEditorContent(appendedNode.templateDescription);
                setDefaultEditorContent(appendedNode.templateDescription);
            } else {
                setEditorContent(event.item.templateDescription);
                setDefaultEditorContent(event.item.templateDescription);
            }
        }

        setPreviousNode(event.item);
    };

    const getCurrentTemplate = () => {
        if (!editorRef.current) {
            console.error("Editor reference is not available.");
            return '';
        }
        const editorHtmlContent = EditorUtils.getHtml(editorRef.current.view.state);
        return editorHtmlContent;
    };

    const handleCheckboxChange = () => {
        setCheckboxStates(prevStates => {
            const newCheckedState = !prevStates[selectedNode.id];
            const updatedCheckboxStates = {
                ...prevStates,
                [selectedNode.id]: newCheckedState
            };
            setCheckboxStates(updatedCheckboxStates);

            const currentTemplate = getCurrentTemplate();
            combinedContent(currentTemplate, newCheckedState, selectedTab);

            return updatedCheckboxStates;
        });
    };


    const combinedContent = (currentTemplate, newCheckedState, selectedTab) => {
        if (!selectedNode && !updateTrigger) {
            console.error("No selected node");
            return;
        }

        const quoteId = sessionStorage.getItem('id');
        const currentCombinedItemsKey = `combinedItems_${quoteId}`;
        const currentsessionTerms = `sessionTerms_${quoteId}`;

        // Initialize combinedItems
        let combinedItems = storedCombinedItems || "<ol></ol>";
        const parser = new DOMParser();
        const doc = parser.parseFromString(combinedItems, 'text/html');

        // Set to track unique node IDs and prevent duplicates
        const nodeIds = new Set();

        const insertOrUpdateNodeContent = (node, contentToUse) => {
            let liElem = doc.querySelector(`li[data-id="${node.id}"]`);
            if (!liElem) {
                liElem = doc.createElement('li');
                liElem.setAttribute('data-id', node.id);
                doc.body.appendChild(liElem);
            }
            liElem.innerHTML = `<strong>${node.text}</strong><br/>${contentToUse}`;
            nodeIds.add(node.id);
        };

        // Process main node data
        mainNodeData.forEach(item => {
            const showOnQuote = checkboxStates[item.id];
            const modifiedContent = sessionStorage.getItem(`editorContent-${id}-${item.id}`);
            const contentToUse = modifiedContent || item.templateDescription;

            if (showOnQuote || (selectedNode.id === item.id && newCheckedState) || ["Pricing", "Delivery", "Validity"].includes(item.text)) {
                insertOrUpdateNodeContent(item, contentToUse);
            }
        });

        // Process appended nodes
        appendedNodes.forEach(node => {
            if (!nodeIds.has(node.id)) {
                insertOrUpdateNodeContent(node, node.templateDescription);
            }
        });

        // Filter out items from combinedItemsHTML that are unchecked
        const combinedItemsHTML = Array.from(doc.querySelectorAll('li'))
            .filter(li => checkboxStates[li.getAttribute('data-id')] !== false)
            .map(li => li.outerHTML)
            .join('');

        // Remove old session storage keys
        // Object.keys(sessionStorage).forEach(key => {
        //     if (key.startsWith('combinedItems_') && key !== currentCombinedItemsKey) {
        //         sessionStorage.removeItem(key);
        //     }
        // });

        // Ensure editor content is included even if not related to checkbox states
        const storedEditorContents = Object.keys(sessionStorage)
            .filter(key => key.startsWith(`editorContent-${id}-`))
            .map(key => {
                const content = sessionStorage.getItem(key);
                const nodeId = key.split('-').pop();
                const savedHeader = sessionStorage.getItem(`editHeader-${id}-${nodeId}`);

                // Check if the header is saved, use it; otherwise, use the default header
                const header = savedHeader || mainNodeData.find(item => item.id === parseInt(nodeId))?.text || '';

                if (!nodeIds.has(nodeId)) {
                    return `<li data-id="${nodeId}"><strong>${header}</strong><br/>${content}</li>`;
                }
                return '';
            })
            .join('');
        //storedEditorContents
        // Combine items ensuring no duplicates
        const tempDoc = parser.parseFromString(`<ol>${storedEditorContents}${combinedItemsHTML}</ol>`, 'text/html');
        const uniqueItems = new Map();
        const tempDocCombinedItems = parser.parseFromString(`<ol>${combinedItemsHTML}</ol>`, 'text/html');
        const fullCombinedContent = tempDoc.querySelectorAll('ol, ul');
        const uniqueCombinedItems = new Map();
        // Step 1: Add combinedItemsHTML to uniqueCombinedItems first
        tempDocCombinedItems.querySelectorAll('li').forEach(li => {
            const dataId = li.getAttribute('data-id');
            // Check if the item exists in uniqueCombinedItems before adding
            if (dataId && !uniqueCombinedItems.has(dataId)) {
                uniqueCombinedItems.set(dataId, li.outerHTML);
            }
        });

        // Step 2: Update from storedEditorContents only if the data-id exists in uniqueCombinedItems
        if (storedEditorContents !== "") {
            const tempDocStoredEditorContents = parser.parseFromString(storedEditorContents, 'text/html');
            
            // Iterate over the li elements in storedEditorContents
            tempDocStoredEditorContents.querySelectorAll('li').forEach(li => {
                const dataId = li.getAttribute('data-id');
                // Only set the item from storedEditorContents if it already exists in uniqueCombinedItems
                if (dataId && uniqueCombinedItems.has(dataId)) {
                    uniqueCombinedItems.set(dataId, li.outerHTML);  // Use li.outerHTML to get the full li content
                }
            });
        }
        // else {
        //     fullCombinedContent.forEach(list => {
        //         list.querySelectorAll('li').forEach(li => {
        //             const id = li.getAttribute('data-id');
        //             if (id && !uniqueItems.has(id)) { // Check for null or undefined id
        //                 uniqueItems.set(id, li.outerHTML);
        //             }
        //         });
        //     });

        // }



        //const fullCombinedContent = tempDoc.querySelectorAll('ol, ul');
        const pdfCombinedContent = tempDocCombinedItems.querySelectorAll('ol, ul');

        fullCombinedContent .forEach(list => {
            list.querySelectorAll('li').forEach(li => {
                const id = li.getAttribute('data-id');
                if (id && !uniqueItems.has(id)) { // Check for null or undefined id
                    uniqueItems.set(id, li.outerHTML);
                }
            });
        });

        const sortedUniqueItems = Array.from(uniqueItems.entries()).sort((a, b) => parseInt(a[0]) - parseInt(b[0]));

        pdfCombinedContent.forEach(list => {
            list.querySelectorAll('li').forEach(li => {
                const id = li.getAttribute('data-id');
                if (id && !uniqueCombinedItems.has(id)) { // Check for null or undefined id
                    uniqueCombinedItems.set(id, li.outerHTML);
                }
            });
        });


        const updatedFullCombinedContent = `<ol>${sortedUniqueItems.map(item => item[1]).join('')}</ol>`;
        const updatedCombinedItems = `<ol>${[...uniqueCombinedItems.values()].join('')}</ol>`;
        sessionStorage.setItem(currentsessionTerms, updatedFullCombinedContent);
        sessionStorage.setItem(currentCombinedItemsKey, updatedCombinedItems);

        return updatedCombinedItems;
    };




    useEffect(() => {
        const quoteId = sessionStorage.getItem('id');
        const combinedItemsKey = `combinedItems_${quoteId}`;
        const storedAppendedNodes = sessionStorage.getItem(combinedItemsKey);

        if (storedAppendedNodes) {
            setStoredCombinedItems(storedAppendedNodes);
        }
    }, [storedCombinedItems]);

    useEffect(() => {
        // Only reset the checkbox state if it hasn't been set yet
        if (selectedNode && checkboxStates[selectedNode.id] === undefined) {
            setCheckboxStates(prevStates => ({
                ...prevStates,
                [selectedNode.id]: false,
            }));
        }
    }, [selectedNode, selectedTab]);


    useEffect(() => {
        sessionStorage.setItem('checkboxStates', JSON.stringify(checkboxStates));
    }, [checkboxStates]);

    const handleSetAllDefaultAndDoneClick = () => {
        setToDefaultConfirmed(true);
    };

    const handleClearChangesClick = () => {
        for (const nodeId in nodeChanges) {
            sessionStorage.removeItem(`editorContent-${id}-${nodeId}`);
            sessionStorage.removeItem(`editHeader-${id}-${nodeId}`);
        }
        setIsClear(true);
        setNodeChanges({});
    };

    useEffect(() => {
        if (isClear) {
            fetchAndUpdateData();
            setIsClear(false);
        }
    }, [isClear]);

    if (loading) {
        <div className="ip-spinner-page">{loading && <Spinner />}</div>
    }

    const isMainNodeSelected = () => selectedNode && selectedNode.id === 0;



    return (
        <div>
            <div className='mb-3'>You can modify the Terms and Conditions of the quote. By selecting a Terms & Condition category on the left, you can modify the details on the right hand side. Click Set All Default to apply the changes to all the future quotes.</div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '20px' }}>
                    <TreeView
                        data={data}
                        expandIcons={true}
                        onExpandChange={onExpandChange}
                        onItemClick={handleTreeNodeClick}
                        aria-multiselectable={false}
                        hasChildren={dataItem => dataItem.hasChildren}
                    />
                    {!isMainNodeSelected() && (
                        <Button title="Sets all terms and condition you modified to be used as default to current and future quotes" className="mt-4 me-2 w-100" onClick={handleSetAllDefaultAndDoneClick}>Set All Default</Button>
                    )}
                </div>
                <div style={{ flex: 1 }}>
                    <div className="ip-qterms-btnwrap">
                        {!isMainNodeSelected() && (
                            <Button title="Clears the changes you made and reverts it back to its last saved data before you hit done." className="me-2" onClick={handleClearChangesClick}>Clear Changes</Button>
                        )}
                        {!isMainNodeSelected() && (
                            <Button title="Sets the current selected modification to be used as default to all future quotes" className="me-2" onClick={handleToDefaultClick}>Set Current to Default</Button>
                        )}
                    </div>
                    {!isMainNodeSelected() && (
                        <div className="ip-qterms-showquote">
                            <label style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
                                <Checkbox
                                    checked={(selectedNode ? checkboxStates[selectedNode.id] : false) || isSpecialNodeSelected()}
                                    onChange={handleCheckboxChange}
                                    disabled={isSpecialNodeSelected()}
                                    style={{ marginRight: '6px' }}
                                />
                                Show on quote
                            </label>
                        </div>
                    )}
                    {!isMainNodeSelected() && (
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                            <Input
                                label='Header:'
                                value={header}
                                onChange={handleEditorHeaderChange}
                                placeholder="Header"
                                disabled={isSpecialNodeSelected() || isHeaderOnlydisabledchilnodes()}
                            />
                        </div>
                    )}
                    <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)}>
                        <TabStripTab title="Current">
                            {selectedTab === 0 && (
                                selectedNode && selectedNode.id === 0 ?
                                    <Interweave style={{ width: '100%', height: '630px', overflow: 'auto', border: '1px solid #e0e0e0', padding: '10px' }} content={
                                        `<ol>${combinedContent()}</ol>`
                                    }
                                    />
                                    :
                                    <Editor
                                        ref={editorRef}
                                        tools={[
                                            [Bold, Italic, Underline, Strikethrough],
                                            [OrderedList, UnorderedList],
                                        ]}
                                        contentStyle={{ height: 630 }}
                                        value={editorContent}
                                        onChange={handleEditorChange}
                                        onMount={onMountStyle}
                                    />
                            )}
                        </TabStripTab>
                        <TabStripTab title="Default">
                            {selectedTab === 1 && (
                                <div style={{ width: '100%', height: '400px', overflow: 'auto', border: '1px solid #e0e0e0', padding: '10px' }}>
                                    <Interweave content={defaultEditorContent} />
                                </div>
                            )}
                        </TabStripTab>
                    </TabStrip>
                </div>
            </div>
        </div>
    );
};

export default TermsAndConditions;
