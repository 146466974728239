import React, { useState, useEffect, useContext } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import LeftNavigationBar from "../Layouts/LeftNavigationBar";
import { UserContext } from "../../Contexts/HomeAccountIdContext";
import SystemSettingForm from "../Qoutes/SystemSettingForm";
import QuotesLookup from "../Qoutes/QuotesLookup";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { OpenQuoteDataContext } from "../../Contexts/OpenQuoteDataContext";
import { getResponse } from "../../Services/Common/Get";
import { postResponse } from "../../Services/Common/Post";
import "../../styles/PageLayout.css";
import { useNavigate } from "react-router-dom";
import { useNodeContext } from "../../Contexts/NodeProvider";
import { Button } from "@progress/kendo-react-buttons";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import { InteractionStatus } from "@azure/msal-browser";
import { useUniteDetails } from "../../Contexts/UnitDetailsProvider";
import HandleExportWithComponent from "../Qoutes/HandleExportPDF";
import HandlePreviewWithComponent from "../Qoutes/PdfPreview";
import { PageCountContext } from "../../Contexts/PageCountContext";
import { useQuoteContact } from "../../Contexts/QuoteContactProvider";
import handleExportToExcel from "../Qoutes/HandleExportExcel";
import { useParams } from "react-router";
import { useQuoteDetails } from "../../Contexts/QuoteDetailsProvider";
import TermsAndConditions from "../Qoutes/TermsAndConditions";
import PartsConsolidatedView from "../Qoutes/PartsConsolidatedView";
import { toast, Slide } from "react-toastify";
import ComponentPartsWhse from "../Qoutes/ComponentPartsWhseSOH";
import ComponentPartsWhseSOHSub from "../Qoutes/ComponentPartsWhseSOHSub";
import ComponentPartsWhseSubSOH from "../Qoutes/ComponentPartsWhseSubSOH";
import StockPriceQtyBreakSearch from "../Qoutes/StockPriceQtyBreakSearch";
import ResetQuote from "../Qoutes/ResetQuote";
import GraphQuotes from "../Qoutes/GraphQuotes";
import { HiddenStatusContext } from "../../Contexts/HiddenStatusContext";
import { useAuthorizationContext } from "../Auth/useAuthorization";
import { useRoundUp } from "../../Contexts/RoundUpProvider";
import EditQuotePriceandMargin from "../Qoutes/EditQuotePriceandMargin";
import { useMarginAndPrice } from "../../Contexts/MarginAndPriceProvider";
import { useStockCode } from "../../Contexts/StockCodeProvider";
import { useTrigerSaveQuoteContext } from "../../Contexts/TrigerSaveQuoteContext";
import QuoteContact from "../Qoutes/QuoteContact";
/*UI*/
import { Col, Row, Container, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Header from "../Layouts/Header";
import Introduction from "../Layouts/Introduction";
import Spinner from "../Common/Spinner";
import { Tooltip } from "react-tooltip";
import { usePartConsolidatedDetail } from "../../Contexts/PartConsolidatedDetailContext";
import { useResetQuote } from "../../Contexts/ResetProvider";
import { Checkbox } from "@progress/kendo-react-inputs";
import { confirmAlert } from "react-confirm-alert";
import { putResponse } from "../../Services/Common/Put";
import { QuoteCustomerIdContext } from "../../Contexts/QouteCustomerIdContext";
import { QuoteContactTypes } from "../../enums/GroupTypes";
import ImportOldQuoteDialog from "../Qoutes/ImportOldQuotes";
import { useTriggerOldQuoteOpenContext } from "../../Contexts/TriggerOldQuoteOpenContext";
import {format, parse, isValid } from 'date-fns';

//start - Manoj codings for Idle timeout

import useIdle from "../Hooks/useIdleTimer";
//end - Manoj codings for Idle timeout

//#region import approval workflow
import { QteApprovalNotificationContext } from "../../Contexts/QteApprovalNotificationContext";
import { v4 as uuidv4 } from "uuid";
import { set, xorBy } from "lodash";
//#endregion

import { searchQuoteByQuotesId } from "../../utils/GetQuoteByQuoteID";

export const PageLayout = (props) => {
    const [defaultTab, setDefaultTab] = useState(0);
    const { suffix, setSuffix, revision, setRevision, isSaveAs, setIsSaveAs, isSaved, setVisibleSaveAs, visibleSaveAs, quoteCreatedbyUser,
        quoteDateCreatedOn, setQuoteDateCreatedOn } = useTrigerSaveQuoteContext();
    const { isOldQuoteOpen, setIsOldQuoteOpen, file, setFile, hasHeaders, setHasHeaders, isOldQuote, setIsOldQuote } = useTriggerOldQuoteOpenContext();
    const [selectedRows, setSelectedRows] = useState([]);
    const isAuthenticated = useIsAuthenticated();
    const { instance } = useMsal();
    const { accounts, inProgress } = useMsal();
    const [graphData, setGraphData] = useState(null);
    const [quotesDialogVisible, setQuotesDialogVisible] = useState(false);
    const [systemSettingFormVisible, setSystemSettingFormVisible] = useState(false);
    const { setIsDataFromOpenQuote } = useContext(OpenQuoteDataContext);
    const { unitDetails, createdName, setIsUnitsLoad, unitName, setUnitName, totalOfUnitItems, newUnitName, triggerUnitSaving, setTriggerUnitSaving } = useUniteDetails();
    const [subnodeSelected, setSubnodeSelected] = useState(false);
    const [selectedQuote, setSelectedQuote] = useState(null);
    // const [show, setShow] = React.useState(false);
    const {
        quotes,
        setQuotes,
        maxQuoteIndex,
        setMaxQuoteIndex,
        handleAddNode,
        module, 
        setModule,
        handleDeleteOpenQuote,
        setSaveQuotation,
        isQuoteSaved,
        setIsQuoteSaved,
        saveQuotation,
        setNewUnits,
        setQuoteUpdated,
        newUnits,
        handleDeleteOpenUnit,
        hideTotalState, setHideTotalState,
        quoteUpdateTriggered , setQuoteUpdateTrigger,
        isDoneClickForPdf, setIsDoneClickForPdf, setIsDoneChangesForQuoteTerms,
        hasQuoteChanged, setHasQuoteChanged, isDoneChangesForQuoteTerms,
     
    } = useNodeContext();
    const navigate = useNavigate();
    const { quoteDetails, updateQuoteDetailsForId } = useQuoteDetails();
    // const { quoteDetails, updateQuoteDetailsForId, showContextMenu, setShowContextMenu} = useQuoteDetails();
    //const user = useContext(UserContext);
    const { id } = useParams();
     ///graph data
    const [ viewOption, setViewOption ] = useState('Month');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [quotesData, setQuotesData] = useState([]);
    const viewOptionData = ['Month', 'Day'];
       
    const [termsDialogVisible, setTermsDialogVisible] = useState(false);
    const [partConsolidatedDialogVisible, setPartConsolidatedDialogVisible] = useState(false);
    const [componentPartsWhseDialogVisible, setComponentPartsWhseDialogVisible] = useState(false);
    const [componentPartsWhseDialogVisibleSub, setComponentPartsWhseDialogVisibleub] = useState(false);
    const [componentPartsWhseDialogVisibleSubB, setComponentPartsWhseDialogVisibleubB] = useState(false);
    const [componentItemPriceQtyBreaksDialogVisible, setComponentItemPriceQtyBreaksDialogVisible] = useState(false);
    const [oldQuoteUploadDialogVisible, setOldQuoteUploadDialogVisible] = useState(false);
    const [resetQuoteDialogVisible, setResetQuoteDialogVisible] = useState(false);
    const [graphDialogVisible, setGraphQuoteDialogVisible] = useState(false);
    const [quoteContactDialogVisible, setQuoteContactDialogVisible] = useState(false);
    // const [currentContactOption , setCurrentContactOption] = useState("");
    const { hiddenStatus,graphVisible, setGraphVisible } = useContext(HiddenStatusContext);
    const [updateTrigger, setUpdateTrigger] = useState(false);
  
    const [editQuoteDialogVisible, setEditQuoteDialogVisible] = useState(false);
    const [printFunction, setPrintFunction] = useState(null);
    const [showHideContent, setShowHideContent] = useState("block"); //Used to show/hide Dashboard
    const url = window.location.pathname.split("/").pop(); //Used to show/hide Dashboard
    const { setAllowAddValue, isApplyActionForParts, setIsApplyActionForParts , isAppliedDeleted,setIsAppliedDeleted } = usePartConsolidatedDetail();
    const { totalCompParts,setStstatus,ststatus, compcode, compstatus, bomPartSub, setBOMPartSub, setSubStockCode, setSubSOH, setBomIsChecked,setStockCode,stockCode,stockNumber,setStockNumber } =
        useStockCode();

    const [qcVisible, setQcVisible] = useState(false);

   
    //#region const approval workflow
    const {
        quoteApprovalMesasge, setQuoteApprovalMesasge,
        quoteApprovalStatus, setQuoteApprovalStatus,
        quoteApprovalStatId, setQuoteApprovalStatId,
        showFormForApproval, setShowFormForApproval,
        triggerSaveQteForApproval, setTriggerSaveQteForApproval,
        approvalStatList, setApprovalStatList,
        forApprovalCount, setForApprovalCount,
        currentApprover, setCurrentApprover,
        quoteOwner, setQuoteOwner,
        approverName, setApproverName,
        triggerSaveNewQuoteFromRejected, setTriggerSaveNewQuoteFromRejected,
        refreshStatus, setRefreshStatus,
        shareQuoteFlag, setShareQuoteFlag,
        saveAsNewFlag, setSaveAsNewFlag,
        approvalDateTime, setApprovalDateTime,
        triggerSaveAsNewFlag, setTriggerSaveAsNewFlag,
        triggerSaveRevision, setTriggerRevision,
        triggerSuffix, setTriggerSuffix,
        checkApprovalStateFor, setCheckApprovalStateFor,
        currAccessLevel, setCurrAccessLevel,
        checkTriggerExport, setCheckTriggerExport
    } = useContext(QteApprovalNotificationContext);

    const { totalPages , setTotalPages } = useContext(PageCountContext);
    const [triggerExport , setTriggerExport] = useState(false);
    const [triggerSaveBefore, setTriggerSaveBefore] = useState(false);
    

    const handleOpenQuote = () => {
        toggleQuotesModal();
        setDefaultTab(0);
    };

    const handleSendForApproval = () => {
        if (quoteDetails[id].savedQuote === undefined) {
            toast.error("Please save the quote before sending for approval.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        if (!quoteDetails[id].savedQuote || quoteDetails[id].savedQuote.quoteNumber.includes("Draft")) {
            toast.error("Please save the quote before sending for approval.", {
                position: "top-center",
                transition: Slide,
            });
            return;

        }
        setShareQuoteFlag(0);
        setShowFormForApproval(true);
    }

    const resetQteApprovalForm = () => {
        // setQuoteApprovalMesasge('');
        setQuoteApprovalStatus('');
        setQuoteApprovalStatId(0);
        setCurrentApprover("");
        setQuoteOwner("");
    }

    const handleCreateNewQuoteFromRejected = () => {
        resetQteApprovalForm();
        setTriggerSaveNewQuoteFromRejected({
            oldQuoteId: id,
            newQuoteId: uuidv4()
        });


    }    //#endregion


    //const [isApplyActionForParts, setIsApplyActionForParts] = useState(false);
    const {
        setAllowSetValue,
        setAllowSetMarginValue,
        isMarginSelected,
        isPriceSelected,
        itemGridCount,
        isKeyPressedForSave,
        setIsKeyPressedForSave,
        newPrice,
        newMargin,
        isValue, setIsValue
        
    } = useMarginAndPrice();
    const { setRoundupPrice } = useRoundUp();
    const { setAllowSet, selectedOption } = useResetQuote();
    const { getAccountRole } = useAuthorizationContext();
    const authType = getAccountRole();
    const [selectedWhse, setSelectedWhse] = useState("All Rows with selected Cols");
    const USER_ID = accounts[0]?.localAccountId;
    const { quoteCustomerId, setQuoteCustomerId } = useContext(QuoteCustomerIdContext);
    //const {allowSetContact, setAllowSetContact,selectedContactOption, setSelectedContactOption } = useQuoteContact();
    const { customEmail, setCustomEmail, customPhone, setCustomPhone, owner, updatedQuoteContact, setUpdatedQuoteContact, search, setSearch, allowSetContact, setAllowSetContact, selectedContactOption,
        setSelectedContactOption, currentContact, setCurrentContact, setContactList, fullName, setTotal, customName, setCustomName, newQuoteContact, setNewQuoteContact, isDoneButtonClicked, setIsDoneButtonClicked, searchContactMobile, currentContactOption, setCurrentContactOption, usermobile } = useQuoteContact();
    // const systemUser = quoteDetails[id]?.quoteContact;
    const systemUser = accounts[0]?.name;
    const accountOwner = quoteDetails[id]?.selectedquoteCustomers;
   

    // const user = useContext(UserContext);
    // const userId = user?.homeAccountId;
    //     useEffect(() => {
    // setIsApplyActionForParts(false);
    //     }, [isApplyActionForParts]);

    // useEffect(() => {
    //     inProgress === InteractionStatus.None && !isAuthenticated ? navigate("/") : navigate("/dashboard");
    // }, [isAuthenticated]);

    // useEffect(() => {
    //     async function getSystemSettings() {
    //         try {
    //             const systemSettings = await getResponse("SystemSetting/getusersystemsettingbyuserid", {
    //                 userId: USER_ID,
    //             });
    //             console.log("data in page layout:", systemSettings);
    //         } catch (error) {
    //             console.error(error);
    //         }
    //     }

    //     getSystemSettings();
    // }, []);

    //Used to show/hide intro
    let getQuoteData = false;
    useEffect(() => {
        setIsApplyActionForParts(true);
        if (url == "dashboard") {
            setShowHideContent("block");
            setSubnodeSelected(false);
        }
        else {
            setShowHideContent("none");
            setSubnodeSelected(true);
        }
    }, [url]);

    
    const [navigateToQuote,setNavigateToQuote] = useState(false);

    useEffect(() => {
        if (navigateToQuote)
        {
            getApprovalDetails(selectedQuote?.quoteNumber);
             handleAddNode(
                quotes,
                setQuotes,
                maxQuoteIndex,
                setMaxQuoteIndex,
                selectedQuote.approvalStatusID ? selectedQuote?.quoteID : selectedQuote?.quoteId,
                true,
                selectedQuote
            );
            selectedQuote &&
                navigate(`/quotes/${selectedQuote.approvalStatusID ? selectedQuote?.quoteID : selectedQuote?.quoteId}`, { state: { selectedQuote } }, { replace: true });
        
            setNavigateToQuote(false);
            // // setSubnodeSelected(true);
            
        }
    }, [navigateToQuote]);

   
    


    //Manoj Start - Used to trigger the Edit Quote Price and Margin save on key press event
    useEffect(() => {

      

        // if ((newPrice === '' || isNaN(newPrice)) || (newMargin === '' || isNaN(newMargin))) {
        //     setIsValue(true);
        // }
        // else {
        //     setIsValue(false);
        // }

        if(newPrice === "" || newMargin === ""){
            setIsValue(true);
            }

        if (isKeyPressedForSave) {
            handleOkSetNewMarginOrTotal();
            setIsKeyPressedForSave(false);

        }

    }, [isKeyPressedForSave, newPrice, newMargin]);

    sessionStorage.setItem("id", id);

    //Used to show/hide Dashboard
    function DisplayDashboard({ showHideContent }) {
        return (
            <div style={{ display: showHideContent }}>
                <Introduction toggleQuotesModal={toggleQuotesModal} onSubnodeClick={handleSubnodeClick} />
            </div>
        );
    }

    const handleSubnodeClick = () => {
        setSubnodeSelected(true);
    };

    const handleDoneClick = () => {
        setUpdateTrigger(true);
        setIsDoneChangesForQuoteTerms(true);
        setQuoteUpdated(true);
        // Increment updateTrigger to trigger updateUserTemplateDescription
    };



    // const handleExportClick = () => {
    //     if (!quoteDetails[id]?.quoteId || quoteDetails[id]?.savedQuote?.tblQuoteItems_Drafts != undefined) {
    //         toast.error("Please save the quote before export to PDF.", {
    //             position: "top-center",
    //             transition: Slide,
    //         });
    //         return;
    //     }
    //     setCheckTriggerExport(true);
    //     setTriggerExport(true);
    // };
    
    // useEffect(() => {
    //     if (triggerExport) {
    //         const handleTriggerSave = async () => {
    //             if (
    //                 quoteDetails[id]?.savedQuote?.createdBy === USER_ID && 
    //                 quoteDetails[id]?.savedQuote?.approvalStatusId !== 4 && 
    //                 quoteDetails[id]?.savedQuote?.approvalStatusId !== 5 &&
    //                 quoteDetails[id]?.savedQuote?.approvalStatusId !== 2 &&
    //                 quoteDetails[id]?.savedQuote?.approvalStatusId !== 3
    //             ) {
    //                 if (hasQuoteChanged) {
    //                     confirmAlert({
    //                         title: 'Unsaved Changes',
    //                         message: 'There are unsaved changes. Do you want to save before exporting?',
    //                         buttons: [
    //                             {
    //                                 label: 'Yes',
    //                                 onClick: async () => {
    //                                     await triggerSaveQuotation();
    //                                     setTimeout(() => {
    //                                         setTriggerSaveBefore(true);
    //                                         setCheckTriggerExport(false);
    //                                     }, 5000);
    //                                 }
    //                             },
    //                             {
    //                                 label: 'No',
    //                                 onClick: () => {
    //                                     setTriggerSaveBefore(true);
    //                                     setCheckTriggerExport(false);
    //                                 }
    //                             }
    //                         ]
    //                     });
    //                 } else {
    //                     setTimeout(() => {
    //                         setTriggerSaveBefore(true);
    //                         setCheckTriggerExport(false);
    //                     }, 1000);
    //                 }
    //             } else {
    //                 setTimeout(() => {
    //                     setTriggerSaveBefore(true);
    //                     setCheckTriggerExport(false);
    //                 }, 1000);
    //             }
    //         };
    
    //         handleTriggerSave();
    //     }
    // }, [triggerExport, hasQuoteChanged]);
    
    // useEffect(() => {
    //     if(triggerSaveBefore){
    //         const userId = accounts[0]?.localAccountId;
    //         const userFullName = accounts[0]?.name;
    //         const shouldHideTotalPrice = hideTotalState[id];
    //         HandleExportWithComponent(quoteDetails[id]?.quoteId, userId, userFullName, shouldHideTotalPrice, hiddenStatus, totalPages, setTotalPages);
    //         toast.success("Exporting to PDF...", {
    //             position: "top-center",
    //             transition: Slide,
    //         });
    //         setTriggerExport(false);
    //         setTriggerSaveBefore(false);
    //     }
    // }, [triggerSaveBefore]);
    const handleExportClick = () => {
        if (!quoteDetails[id]?.quoteId || quoteDetails[id]?.savedQuote?.tblQuoteItems_Drafts != undefined) {
            toast.error("Please save the quote before export to PDF.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        setCheckTriggerExport(true);
        setTriggerExport(true);
    };

    useEffect(() => {
        if (triggerExport) {
            const handleTriggerSave = async () => {
                if (
                    quoteDetails[id]?.savedQuote?.createdBy === USER_ID && 
                    quoteDetails[id]?.savedQuote?.approvalStatusId !== 4 && 
                    quoteDetails[id]?.savedQuote?.approvalStatusId !== 5 &&
                    quoteDetails[id]?.savedQuote?.approvalStatusId !== 2 &&
                    quoteDetails[id]?.savedQuote?.approvalStatusId !== 3

                ) {
                    await triggerSaveQuotation();
                    setTimeout(() => {
                        setTriggerSaveBefore(true);
                        setCheckTriggerExport(false);
                    }, 5000); 
                } else {
                    setTimeout(() => {
                        setTriggerSaveBefore(true);
                        setCheckTriggerExport(false);
                    }, 1000); // Delay of 1000 milliseconds (1 second)
                }
            };
    
            handleTriggerSave();
        }
    }, [triggerExport]);
    
    

    useEffect(() => {
        if(triggerSaveBefore){
            const userId = accounts[0]?.localAccountId;
            const userFullName = accounts[0]?.name;
            const shouldHideTotalPrice = hideTotalState[id];
            HandleExportWithComponent(quoteDetails[id]?.quoteId, userId, userFullName, shouldHideTotalPrice, hiddenStatus , totalPages , setTotalPages); // Changed isTotalPriceHidden to !hideTotalState[id]\
            toast.success("Exporting to PDF...", {
                position: "top-center",
                transition: Slide,
            });
            setTriggerExport(false);
            setTriggerSaveBefore(false);
        }
    }, [triggerSaveBefore]);
    const handleExportExcelClick = () => {
        const userId = accounts[0]?.localAccountId;
        const userFullName = accounts[0]?.name;
        const userEmail = accounts[0]?.username;
        const shouldHideTotalPrice = hideTotalState[id];
        handleExportToExcel(userId, userFullName, userEmail, shouldHideTotalPrice, hiddenStatus, usermobile); // Changed isTotalPriceHidden to !hideTotalState[id]
    };
    const handlePreviewClick = () => {
        if(quoteDetails[id]?.savedQuote != undefined){
        const userId = accounts[0]?.localAccountId;
        const userFullName = accounts[0]?.name;
        const shouldHideTotalPrice = hideTotalState[id];
        HandlePreviewWithComponent(userId, userFullName, shouldHideTotalPrice, hiddenStatus); // Changed isTotalPriceHidden to !hideTotalState[id]
        }
        else{
            toast.error("Please save the quote as Draft before preview to PDF.", 
            {
                position: "top-center",
                transition: Slide,
            });
        }
        //toast.success("Preview PDF...");
    };

    const toggleQuotesModal = () => {
        
        setQuotesDialogVisible(!quotesDialogVisible);
        
    };

    const toggleTermsDialog = () => {
        if (!isDoneChangesForQuoteTerms || !updateTrigger) {
            const idPrefix = id; // replace this with dynamic id if needed
    
            // Remove session storage items that start with editHeader-{id} and editorContent-{id}
            Object.keys(sessionStorage).forEach(key => {
                if (key.startsWith(`editHeader-${idPrefix}`) || key.startsWith(`editorContent-${idPrefix}` || key.startsWith(`sessionTerms-${idPrefix}`) || key.startsWith(`combinedItems-${idPrefix}`))) {
                    sessionStorage.removeItem(key);
                }
            });
    
            // Update checkboxStates in session storage to retain only IDs 1, 2, and 3
            const checkboxStatesKey = `checkboxStates-${idPrefix}`;
            const checkboxStates = JSON.parse(sessionStorage.getItem(checkboxStatesKey)) || {};
    
            const filteredCheckboxStates = Object.keys(checkboxStates).reduce((newState, key) => {
                if (['1', '2', '3'].includes(key)) {
                    newState[key] = checkboxStates[key];
                }
                return newState;
            }, {});
    
            sessionStorage.setItem(checkboxStatesKey, JSON.stringify(filteredCheckboxStates));
            setIsDoneChangesForQuoteTerms(false);
        }
        setTermsDialogVisible(!termsDialogVisible);
        setUpdateTrigger(false);
    };

    const togglePartConsolidatedDialog = () => {
        setPartConsolidatedDialogVisible(!partConsolidatedDialogVisible);
    };
    const toggleCancelPartConsolidatedDialog = () => {
        setPartConsolidatedDialogVisible(!partConsolidatedDialogVisible);
        setIsAppliedDeleted(false);
    };
    

    const toggleRoundupPrice = () => {
        setRoundupPrice(true);
    };

    const toggleEditQuotePriceandMarginDialog = () => {
        setEditQuoteDialogVisible(!editQuoteDialogVisible);
    };

    const toggleComponentPartsWhse = () => {
        setComponentPartsWhseDialogVisible(!componentPartsWhseDialogVisible);
        setBOMPartSub(!bomPartSub);
        setStockCode("");
        setStstatus("");
        setStockNumber("");
        
    };

    const toggleComponentPartsWhseSub = () => {
        if(totalCompParts > 0){
            setBomIsChecked(true);
            setBOMPartSub(false);
            setComponentPartsWhseDialogVisibleub(!componentPartsWhseDialogVisibleSub);
            setStockCode(stockNumber)
        }
        else{
            toast.error("Sorry ! No Component part is found.", {
                position: "top-center",
                transition: Slide,
            });
        }
     
    };

    const toggleComponentPartsWhseSubB = () => {
        setBomIsChecked(true);
        setBOMPartSub(false);
        const abc = compcode === '' || compcode === undefined ? '' : compcode[compcode.length - 1].compcode;
        const sohvalue = compcode === '' || compcode === undefined ? 0 : compcode[compcode.length - 1].soh;
       
        setSubStockCode(abc);
        setSubSOH(sohvalue);
        //    const part = compcode.filter((item) =>
        //     item.id === "" && item.compcode !== ""
        // );
        // console.log("part:", part);
        // console.log("part comp:", [...compcode, { ...part, id: uuidv4() ,instance:1}]);

        // if (part) {
        //     setCompcode([...compcode, { ...part, id: uuidv4(),instance:1}]);
        // }
        setComponentPartsWhseDialogVisibleubB(!componentPartsWhseDialogVisibleSubB);
    };
    const toggleQuoteContactDialog = () => {
        setQuoteContactDialogVisible(!quoteContactDialogVisible);
        setSelectedContactOption(QuoteContactTypes.OPTION1);
        setSearch("");
        setCustomName("");
        setCustomEmail("");
        setCustomPhone("");
        // setUpdatedQuoteContact("")
    };

    const toggleQuoteContactDialogCancel = () => {
        // setQuoteContactDialogVisible(!quoteContactDialogVisible);
        // setSelectedContactOption(QuoteContactTypes.OPTION1);
        // setSearch("");
        // setCustomName("");
        // setCustomEmail("");
        // setCustomPhone("");
        // // setUpdatedQuoteContact("")
        setQcVisible(!qcVisible);
    };

    const toggleResetQuoteDialog = () => {
        setResetQuoteDialogVisible(!resetQuoteDialogVisible);
    };
    const closeGraphQuoteDialog = () => {
        //setGraphQuoteDialogVisible(!graphDialogVisible);
        setGraphVisible(false);
    }
    const toggleGraphQuoteDialog = async () => { // graph
        let x = accounts[0]?.localAccountId;
        setViewOption('month');
        let startDT = new Date(currentDate.getFullYear(), 0, 1).toString();
        let endDT = new Date();
        let startViewOption = "month$"+startDT+"$"+endDT;
        const response = await getResponse("Quote/getquotesperusergraph", {userId: accounts[0]?.localAccountId,
             paramViewOption : startViewOption
            })
        .then((response) => 
        {
            if (response.data !== null) 
            {
                let newArrayGraph = [];
                for (let i = 0; i < response.length; i++) {
                        newArrayGraph.push ({ Month: response[i].month, Total: response[i].totalQuotes });    
                }
                let rr = newArrayGraph;
                setQuotesData({
                    ...quotesData, ...newArrayGraph,
                  });
                let xx = quotesData;
                console.log("Successfully retrieved graph details");
                //setGraphQuoteDialogVisible(!graphDialogVisible);

            }
            else 
            {
                console.log("Failed to retrieved data.");
            }
        })
        .catch((error) => 
        {
            console.error("Error retrieving graph details:", error);

        });

        
    };

    const resetGraph  = () => {
        toggleGraphQuoteDialog();
    };

    const refreshGraph = async () => {
        let x = accounts[0]?.localAccountId;

        let startDT = startDate === "" ? new Date(currentDate.getFullYear(), 0, 1).toString() : startDate;
        let endDT = endDate === "" ? new Date() : endDate;
        let viewOptionData = viewOption;
        let startViewOption = viewOptionData.toLocaleLowerCase()+"$"+startDT+"$"+endDT;
        const response = await getResponse("Quote/getquotesperusergraph", {userId: accounts[0]?.localAccountId,
             paramViewOption : startViewOption
            })
        .then((response) => 
        {
            if (response.data !== null) 
            {
                let newArrayGraph = [];
                //setQuotesData({});
                for (let i = 0; i < response.length; i++) {
                        newArrayGraph.push ({ Month: response[i].month, Total: response[i].totalQuotes });    
                }
                let rr = newArrayGraph;
                setQuotesData(newArrayGraph);

                let xx = quotesData;
                console.log("Successfully retrieved graph details");

            }
            else 
            {
                console.log("Failed to retrieved data.");
            }
        })
        .catch((error) => 
        {
            console.error("Error retrieving graph details:", error);

        });
    };

    const toggleComponentPriceQtyBreakSearchClose = () => {
        setComponentItemPriceQtyBreaksDialogVisible(false);
    };

    const toggleComponentItemPriceQtyBreaks = () => {
        setComponentItemPriceQtyBreaksDialogVisible(true);
    };

    const toggleOldQuoteOpen = () => {
        setOldQuoteUploadDialogVisible(true);
    };

    const toggleSystemSettingModal = () => {
        setSystemSettingFormVisible(!systemSettingFormVisible);
    };

    const getQuoteDetailsById = (data) => {
        setSelectedQuote(data);
        setIsDataFromOpenQuote(true);

    };

    const retrievedQuoteViaLink = (quote) => {
        setSelectedQuote(quote[0]);
        setSelectedRows(quote);
    }
    const formatDateTime = (date) => {
        if (typeof date === "undefined" || date === null) return "";
        const newDate = new Date(date);
        let datePart = [
            newDate.toLocaleDateString("en-au", { weekday: 'short' }),
            newDate.toLocaleDateString("en-au", { month: 'short' }),
            newDate.getDate(),
            newDate.getFullYear()
        ].join(" ");
        // [
        //     newDate.getDate(),
        //     newDate.getMonth() + 1,
        //     newDate.getFullYear()
        // ].map((n, i) => n.toString().padStart(i === 2 ? 4 : 2, "0")).join("/");
        let timePart = [
            newDate.getHours(),
            newDate.getMinutes()
        ].map((n, i) => n.toString().padStart(2, "0")).join(":");
        return datePart + " " + timePart;
    }
    let apprvName = "";
    const getApprovalDetails = async (quoteNumber) => {
        
        try {
            const endpoint = "Quote/searchapprovalquotes";
            const query = {
                createdBy: (!typeof id === "undefined") ? quoteDetails[id].savedQuote.createdBy : accounts[0]?.localAccountId,
                filterNumber: quoteNumber + "#quoteNumber",
                pageNumber: 1,
                pageSize: 50,
            };
            const res = await postResponse(endpoint, query);
            if (res.data.length > 0) {
                setApprovalDateTime(formatDateTime(res.data[0].approvedDateTime));
                const approverQry = {
                    userId: res.data[0].approvedUserID,
                }
                const approverRes = await getResponse("Quote/GetSpecificApprover", approverQry);
                if (approverRes.data.length > 0) {
                    setApproverName(approverRes.data.approver??"");
                    apprvName = approverRes.data.approver;
                }
            }

            

        } catch (error) {
            console.log("Error getting approval date: ", error);
        }
        
    };

    const navigateToQuotes = () => {
        if (selectedRows.length === 0) {
            toast.error("Please Select a Quote", {
                position: "top-center",
                transition: Slide,
            });

        } else {
            getApprovalDetails(selectedRows[0].quoteNumber);
            toggleQuotesModal();
            setDefaultTab(0);
            handleAddNode(
                quotes,
                setQuotes,
                maxQuoteIndex,
                setMaxQuoteIndex,
                selectedQuote.approvalStatusID ? selectedQuote?.quoteID : selectedQuote?.quoteId,
                true,
                selectedQuote
            );
            selectedQuote &&
                navigate(`/quotes/${selectedQuote.approvalStatusID ? selectedQuote?.quoteID : selectedQuote?.quoteId}`, { state: { selectedQuote } }, { replace: true });
        }

    };
    // const handleOkSetNewMarginOrTotal =() =>{
    //    setAllowSetValue(true) ;
    //    toggleEditQuotePriceandMarginDialog();
    // }
    const handleOkSetNewMarginOrTotal = () => {
        if (isPriceSelected) {
            // If the "newPrice" radio button is selected
            setAllowSetValue(true);
            setAllowSetMarginValue(false);
        } else if (isMarginSelected) {
            // If the "newMargin" radio button is selected
            setAllowSetValue(false);
            setAllowSetMarginValue(true);
        }

        toggleEditQuotePriceandMarginDialog();
    };

    //   const handleOkSet = () => {
    //     if (isDefaultPriceSelected) {

    //         setAllowKeepMarginValue(false);
    //         setAllowKeepNettEAValue(false);
    //         setIsKeepMarginSelected(false);
    //         setIsKeepNettEASelected(false);
    //     }

    //     else if (isKeepNettEASelected) {

    //         setAllowSet(false);
    //         setAllowKeepMarginValue(false);
    //         setIsKeepMarginSelected(false);
    //         setIsDefaultPriceSelected(false);
    //     }

    //     else if (isKeepMarginSelected) {

    //         setAllowSet(false);
    //         setAllowKeepNettEAValue(false);
    //         setIsDefaultPriceSelected(false);
    //         setIsKeepNettEASelected(false);
    //     }
    //     setAllowSet(true);
    //     setAllowKeepNettEAValue(true);
    //     setAllowKeepMarginValue(true);
    //     toggleResetQuoteDialog();
    //   }

    const handleOkActionForPartsConsolidatedView = () => {
        setAllowAddValue(true);
        togglePartConsolidatedDialog();
    };

    const handleApplyActionForPartsConsolidatedView = () => {
        setIsApplyActionForParts(true);
        // Finally, close the dialog
        //togglePartConsolidatedDialog();
    };

    //Start - Manoj codings for Idle timeout

    const [showModal, setShowModal] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);

    //this function takes milliseconds and converts them to min and seconds.
    function millisToMinutesAndSeconds(millis) {
        var minutes = Math.floor(millis / 60000);
        var seconds = ((millis % 60000) / 1000).toFixed(0);
        return minutes + ":" + (seconds < 10 ? "0" : "") + seconds;
    }

    const handleIdle = () => {
        setShowModal(true); //show modal
        setRemainingTime(30); //set 30 seconds as time remaining
    };

    const { isIdle } = useIdle({ onIdle: handleIdle, idleTime: 0.3 });

    useEffect(() => {
        let interval;

        if (isIdle && showModal) {
            interval = setInterval(() => {
                setRemainingTime(
                    (prevRemainingTime) => (prevRemainingTime > 0 ? prevRemainingTime - 1 : 0) //reduces the second by 1
                );
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [isIdle, showModal]);

    useEffect(() => { //graph
        if (graphVisible === true) {
            toggleGraphQuoteDialog();
        }
    }, [graphVisible]); 

    useEffect(() => {
        if (remainingTime === 0 && showModal) {
            //alert("Time out!");
            //debugger;
            setShowModal(false); //close the modal
            navigate("/"); //go to login page
        }
    }, [remainingTime, showModal, navigate]); // this function will only run
    //considering the remaining time , show modal and navigate , though I don't
    //its necessary.

    const handleLogOut = () => {
        setShowModal(false);
        navigate("/"); // go to login page
    };

    const handleStayLoggedIn = () => {
        setShowModal(false); //close the modal
    };

    //SubMenu Dropdown Toggle
    const [isQuoteSettingsDropdown, setIsQuoteSettingsDropdown] = useState(false);
    const toggleQuoteSettingsDropdown = () => {
        setIsQuoteSettingsDropdown(!isQuoteSettingsDropdown);
    };

    const [isQuoteActionDropdown, setIsQuoteActionDropdown] = useState(false);
    const toggleQuoteActionDropdown = () => {
        setIsQuoteActionDropdown(!isQuoteActionDropdown);
    };

    const [isQuoteExportDropdown, setIsQuoteExportDropdown] = useState(false);
    const toggleQuoteExportDropdown = () => {
        setIsQuoteExportDropdown(!isQuoteExportDropdown);
    };
    const handleOkSet = () => {
        setAllowSet(selectedOption);
        setResetQuoteDialogVisible(!resetQuoteDialogVisible);
    };
    const handleOkSetContact = () => {
        setAllowSetContact(selectedContactOption);
        setQuoteContactDialogVisible(!quoteContactDialogVisible);
    };
    const handleOkSetContactInBottom = () => {
        if (selectedContactOption == QuoteContactTypes.OPTION1) {
            setUpdatedQuoteContact(currentContact);
            setCurrentContactOption(selectedContactOption);


            updateQuoteDetailsForId(id, {
                quoteContact: currentContact,

            });
            setQuoteContactDialogVisible(false);
        }
        if (selectedContactOption == QuoteContactTypes.OPTION2) {
            setUpdatedQuoteContact(systemUser);
            setCurrentContact(systemUser);
            setCurrentContactOption(selectedContactOption);
            updateQuoteDetailsForId(id, {
                quoteContact: systemUser,

            });
            setQuoteContactDialogVisible(false);
        }
        if (selectedContactOption == QuoteContactTypes.OPTION3) {
            //  setUpdatedQuoteContact(accountOwner);
            setUpdatedQuoteContact(owner);
            setCurrentContact(owner);
            setCurrentContactOption(selectedContactOption);
            updateQuoteDetailsForId(id, {
                quoteContact: owner,

            });
            setQuoteContactDialogVisible(false);
        }
        if (selectedContactOption == QuoteContactTypes.OPTION4) {
            if (search.trim() === '') {
                toast.error("No name is entered for this position. Please eneter a name and validate ", {
                    position: "top-center",
                    transition: Slide,
                });
                setQuoteContactDialogVisible(true)
            }
            else {
                setUpdatedQuoteContact(search);
                setCurrentContact(search);
                setCurrentContactOption(selectedContactOption);
                updateQuoteDetailsForId(id, {
                    quoteContact: search,
                    searchContactMobile: searchContactMobile

                });
                setQuoteContactDialogVisible(false);
            }
        }
        if (selectedContactOption == QuoteContactTypes.OPTION5) {
            if (!customName || !customPhone || !customEmail) {
                toast.error("Not enough information is provide. Please enter all fields and try again. ", {
                    position: "top-center",
                    transition: Slide,
                });
            }
            else {
                setUpdatedQuoteContact(customName);
                setCurrentContact(customName);
                setCurrentContactOption(selectedContactOption);
                updateQuoteDetailsForId(id, {
                    quoteContact: customName,
                    customPhone: customPhone,

                });
                setQuoteContactDialogVisible(false);
            }
        }

        setNewQuoteContact(true)
        setIsDoneButtonClicked(true);
        setIsDoneClickForPdf(true);
    };

    const handleShowtotal = (quoteId) => {
       
        // Receive the quoteId as an argument
        setHideTotalState((prevState) => ({
            ...prevState,
            [quoteId]: !prevState[quoteId], // Toggle the state for the given quoteId
        }));
    };

   

    const navigateToDashboard = () => {
        navigate("/dashboard");
    };



    const triggerSaveUnit = () => {
        setTriggerUnitSaving((triggerUnitSaving) => triggerUnitSaving + 1);
        setTimeout(setTriggerUnitSaving, 100, 0);

    };

    const handleDeleteUnit = () => {
        confirmAlert({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this unit?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        // if (!unitDetails[id]?.unitId) {
                        //     toast.error(
                        //         "The unit you are trying to delete is not saved. If you want to close the unit, use the close button instead."
                        //     );
                        // } else {
                        putResponse(`NewUnits/deletenewunit?unitId=${id}`)
                            .then((res) => {
                                if (res) {
                                    // handleUnitDeleteNode,newUnits,setNewUnits
                                    //   handleDeleteOpenQuote(quotes, setQuotes, id);
                                    handleDeleteOpenUnit(newUnits, setNewUnits, id);
                                    setIsUnitsLoad(true);
                                    toast.success("The Quote has been Deleted Successfully", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                    navigate(`/dashboard`, { replace: true });
                                } else {
                                    toast.error("Either unit is not saved or something went wrong while deleting.", {
                                        position: "top-center",
                                        transition: Slide,
                                    });

                                }
                            })
                            .catch((error) => {
                                console.error("Failed to delete quote:", error);
                                toast.error("Connection error. Please try again later.", {
                                    position: "top-center",
                                    transition: Slide,
                                });
                            });
                    }
                    // },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };


    const handleDelete = () => {
        if (quoteDetails[id]?.savedQuote?.tblQuoteItems_Drafts != undefined) {
            toast.error(
                "Save the draft quote before try to delete, or use the close button to exist the current draft quote.", {
                position: "top-center",
                transition: Slide,
            });
            return;
        }
        confirmAlert({
            title: "Delete Confirmation",
            message: "Are you sure you want to delete this?",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        if (!quoteDetails[id]?.savedQuote) {
                            toast.error(
                                "The quote you are trying to delete is not saved. If you want to close the quote, use the close button instead.", {
                                position: "top-center",
                                transition: Slide,
                            });
                        } else {
                            putResponse(`Quote/deletequotewithupdateisdeleted?quoteId=${id}`)
                                .then((res) => {
                                    if (res) {
                                        handleDeleteOpenQuote(quotes, setQuotes, id);
                                        toast.success("The Quote has been Deleted Successfully", {
                                            position: "top-center",
                                            transition: Slide,
                                        });
                                    } else {
                                        toast.error(
                                            "Either quote is not saved or something went wrong while deleting.", {
                                            position: "top-center",
                                            transition: Slide,
                                        });
                                    }
                                })
                                .catch((error) => {
                                    console.error("Failed to delete quote:", error);
                                    toast.error("Connection error. Please try again later.", {
                                        position: "top-center",
                                        transition: Slide,
                                    });
                                });
                        }
                    },
                },
                {
                    label: "No",
                    onClick: () => {
                        return;
                    },
                },
            ],
        });
    };

    useEffect(() => {
        if (quoteUpdateTriggered) {
            setHideTotalState((prevState) => ({ ...prevState, [id]: true }));
        }
    }, [quoteUpdateTriggered, id, setHideTotalState]);

    const triggerSaveQuotation = async () => {
        !hideTotalState[id] ? setHideTotalState((prevState) => ({ ...prevState, [id]: false })) : true;
        
        //// Temporary replace the selectedquoteCustomers to selectedCustomer because it is not yet used during update of quotetation 02/06/2024
        if (!(quoteDetails[id]?.selectedCustomers)) {
            toast.error("Sorry! Cannot save without customers.", {
                position: "top-center",
                transition: Slide,
            });
        }
        else if (!(quoteDetails[id]?.selectedItems) || (quoteDetails[id]?.selectedItems[0].rowType === "LN" && quoteDetails[id]?.selectedItems.length === 1)) {
            toast.error("Sorry! Cannot save without items.", {
                position: "top-center",
                transition: Slide,
            });
        }
        else {
            setSaveQuotation((saveQuotation) => saveQuotation + 1);
            setTimeout(setSaveQuotation, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
            setRevision(false);
            setSuffix(false);
        }

    };

    const triggerOldQuoteOpen = (file, hasHeaders, isOldQuote) => {
        setIsOldQuoteOpen(true);
        setFile(file);
        setHasHeaders(hasHeaders);
        setIsOldQuote(isOldQuote);
    };

    const triggerSaveAsQuotation = () => {

        setSaveQuotation((saveQuotation) => saveQuotation + 1);
        setTimeout(setSaveQuotation, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
        setIsSaveAs(true);
    };

    const quoteCreatedDate = quoteDetails[id]?.quoteDate;

// Function to parse date in multiple formats
const parseDate = (dateString) => {
    if (typeof dateString !== 'string') {
        return new Date('1999-01-01');
    }

    let parsedDate = parse(dateString, 'dd/MM/yyyy', new Date());
    if (!isValid(parsedDate)) {
        parsedDate = parse(dateString, 'MM/dd/yyyy', new Date());
    }
    return parsedDate;
};

let convertedCreatedDate;
if (quoteCreatedDate) {
    // Ensure the date is a string before parsing
    convertedCreatedDate = parseDate(quoteCreatedDate.toString());
} else {
    convertedCreatedDate = new Date('1999-01-01');
}

// Convert the date to the required timezone (Asia/Kolkata)
const options = { timeZone: 'Asia/Kolkata' };
const localDateString = convertedCreatedDate.toLocaleString('en-GB', options); // Use 'en-GB' to avoid locale issues
convertedCreatedDate = new Date(localDateString);

// Ensure the date is valid
if (isNaN(convertedCreatedDate.getTime())) {
    convertedCreatedDate = new Date('1999-01-01');
}

// Format the converted date to 'yyyy-MM-dd'
const createdDateOnly = format(convertedCreatedDate, 'yyyy-MM-dd');

const currentDate = new Date();
const nowDate = format(currentDate, 'yyyy-MM-dd');

    const toggleRevision = () => {
        if (createdDateOnly != nowDate && createdDateOnly != undefined) {
            confirmAlert({
                title: "Quote Date Change",
                message: "The Quote Date is not changed. Do you want to change it?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            setQuoteDateCreatedOn(currentDate)
                            setCheckApprovalStateFor("SaveAsRevision");
                        },
                    },
                    {
                        label: "No",
                        onClick: () => {
                            setQuoteDateCreatedOn(quoteCreatedDate);
                            setCheckApprovalStateFor("SaveAsRevision");
                        },
                    },
                ],
            });
        }
        else {
            setQuoteDateCreatedOn(currentDate);
            setCheckApprovalStateFor("SaveAsRevision");
        }

    };

    useEffect(() => {
        if (triggerSaveRevision === 1) {
            setSaveQuotation((saveQuotation) => saveQuotation + 1);
            setTimeout(setSaveQuotation, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
            setIsSaveAs(true);
            setRevision(true);
            setSuffix(false);
            setSaveAsNewFlag(0);
            setTriggerRevision(0);
            setCheckApprovalStateFor("");
        }
    },[triggerSaveRevision]);

    const toggleShareQuote = () => {
        setShareQuoteFlag(1);
        setShowFormForApproval(true);
        //setSaveAsNewFlag(1);
        setIsQuoteSaved(false);
    }

    const toggleSendApproval = () => {
        setShareQuoteFlag(0);
        setShowFormForApproval(true);
    }

    const toggleSaveAsNew = () => {
        setCheckApprovalStateFor('SaveAsNew'); // Trigger validation first
    };

    useEffect(() => {
        if (triggerSaveAsNewFlag === 1) { //indicator to proceed on saving new quote
            setSaveAsNewFlag(1);
            setQuoteDateCreatedOn(currentDate);
            setSaveQuotation((saveQuotation) => saveQuotation + 1);
            setTimeout(setSaveQuotation, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
            setIsSaveAs(true);
            setRevision(false);
            setSuffix(false);
            setTriggerSaveAsNewFlag(0);
            setCheckApprovalStateFor("");
        }
    },[triggerSaveAsNewFlag]);

    const quoteNumber = quoteDetails[id]?.savedQuote?.quoteNumber
    const checkDraft = quoteNumber && quoteNumber.includes("Draft")

    const toggleSuffix = () => {
        if (createdDateOnly != nowDate && createdDateOnly != undefined) {
            confirmAlert({
                title: "Quote Date Change",
                message: "The Quote Date is not changed. Do you want to change it?",
                buttons: [
                    {
                        label: "Yes",
                        onClick: () => {
                            setQuoteDateCreatedOn(currentDate);
                            setCheckApprovalStateFor('SaveAsSuffix'); // Trigger validation first
                        },
                    },
                    {
                        label: "No",
                        onClick: () => {
                            setQuoteDateCreatedOn(quoteCreatedDate);
                            setCheckApprovalStateFor('SaveAsSuffix'); // Trigger validation first
                        },
                    },
                ],
            });
        }
        else {
           setCheckApprovalStateFor('SaveAsSuffix'); // Trigger validation first
        }
    };

    useEffect(() => {
        if (triggerSuffix === 1) {
            setSaveQuotation((saveQuotation) => saveQuotation + 1);
            setTimeout(setSaveQuotation, 100, 0); //add delay to prevent further execution after triggering saving due to form is loading after saving.
            setIsSaveAs(true);
            setRevision(false);
            setSuffix(true);
            setSaveAsNewFlag(0);
            setTriggerSuffix(0);
            setCheckApprovalStateFor("");
        }
        

    },[triggerSuffix]);

    //#region const approval workflow

    const [approvalStats, setApprovalStats] = useState('');
    const [approvalStarted, setApprovalStarted] = useState(false);
    const [approvalComment, setApprovalComment] = useState('');

    const fetchApprovalStatusList = async () => {
        const response = await getResponse("Quote/getapprovalstatslist", {});
        if (Object.keys(response).length > 0) {
            setApprovalStatList(response.data);
        }

    }

    //Get Comment 
    const fetchApprovalComment = async () => {
            let endpoint = "Quote/approvalComment";
            let query = {
                quoteId: id,
                quoteApprovalStatId: quoteApprovalStatId
            };
            var response = await getResponse(endpoint, query);

            if (Object.keys(response).length > 0) {
                setApprovalComment(response.message === "No Comment" ? "" : response.message);
            }
    }

    const [loginRequired, setLoginRequired] = useState(false);
    const initialLoad = () => {
        fetchApprovalStatusList();
        viewQuotesById(id);
    }
    const viewQuotesById = async () => {
        const result = await searchQuoteByQuotesId(url);
        if (result) {
            setSelectedRows(result);
            setSelectedQuote(result[0]);
        }
    }

    useEffect(() => {
        if (selectedQuote && !quotesDialogVisible) {
            setNavigateToQuote(true);
        }
    },[selectedQuote])
    useEffect(() => {
         
        if (!isAuthenticated) {
            setLoginRequired(true);
        } 
        else {
            fetchApprovalStatusList();
        }
        resetQteApprovalForm();
    }, []);

    useEffect(() => {
        if (loginRequired)
        {
            const curretAccount = instance.getActiveAccount();
            var loginRequest = {
                scopes: ["user.read"], // optional Array<string>
                loginHint: curretAccount?.username,
            };

            try {
                
                instance.ssoSilent(loginRequest).then((response) => {
                    instance.setActiveAccount(response);
                    initialLoad();
                }).catch((error) => {
                    if (error.errorCode === 'login_required')
                    {
                        const loginResponse = instance
                        .loginPopup(loginRequest)
                        .then((response) => {
                            instance.setActiveAccount(response);
                            initialLoad();
                        })
                        .catch((error) => {
                            // handle error
                        });

                    }
                });
            } catch (err) {
                console.log(err);
                if (err instanceof InteractionRequiredAuthError) {
                    const loginResponse = instance
                        .loginPopup(silentRequest)
                        .catch((error) => {
                            // handle error
                        });
                } else {
                    // handle error
                }
            }
        }
    }, [loginRequired]);

    useEffect(() => {
        if(id != undefined && quoteApprovalStatId > 0 ){
        fetchApprovalComment();
        }
    }, [id,quoteApprovalStatId]);

    useEffect(() => {
        // if (approvalStatList === null || approvalStatList === undefined) return;
        // if (approvalStatList.length === 0) return;
        // if (quoteApprovalStatId < 0) return;
        const idx = approvalStatList.findIndex((item) => item.approvalStatusID === quoteApprovalStatId);
        setApprovalStarted(quoteApprovalStatId > 0 && quoteApprovalMesasge.length > 0 ? true : false);
        if (idx < 0) {
            setApprovalStats('')
            return;
        }
        setApprovalStats
            (
                approvalStatList[idx].approvalStatus
            );
        
    }, [quoteApprovalStatId, quoteApprovalMesasge]);

    //#endregion

    
    try {
         location.pathname.slice(1, location.pathname.lastIndexOf("/")).length > 0 
        ? setModule(location.pathname.slice(1, location.pathname.lastIndexOf("/")))
        : <></>;
    } catch (error) {
        console.error("Error getting module:", error);
    }
   
    const updatebyOwn = quoteCreatedbyUser === USER_ID;

    const toggleDialog = () => {
        setQcVisible(!qcVisible);
    }

    const handleRemove = () => {
        setQuoteContactDialogVisible(!quoteContactDialogVisible);
        setSelectedContactOption(QuoteContactTypes.OPTION1);
        setSearch("");
        setCustomName("");
        setCustomEmail("");
        setCustomPhone("");
        // setUpdatedQuoteContact("")
        setQcVisible(!qcVisible);
    }

    const handleOk = () => {
        setQcVisible(!qcVisible);
        handleOkSetContactInBottom();
    }

    const optionsDTTime = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
        timeZone: "Australia/Sydney",
        timeZoneName: "short",
    };

    document.querySelectorAll('html').forEach(function(element) {
        element.addEventListener('click', function(e) {
            let hasPopUp = document.getElementById("popUpId");
            if (hasPopUp?.checkVisibility()) {
                hasPopUp.style.display = 'none';
            }
        });
    });

    return (
        <>
            {!isAuthenticated && <Spinner></Spinner>}

            {isAuthenticated ? (
                <UserContext.Provider
                    value={{
                        homeAccountId: accounts[0]?.localAccountId,
                        userName: accounts[0]?.username,
                        user: accounts[0]?.username.split("@")[0],
                        userFullName: accounts[0]?.name,
                    }}>
                    <>
                        <div id="layout-wrapper">
                            <Header
                                toggleQuotesModal={toggleQuotesModal}
                                toggleSystemSettingModal={toggleSystemSettingModal}
                                setDefaultTab={setDefaultTab}
                            />

                            {isAuthenticated && accounts.length > 0 && (
                                <>{<LeftNavigationBar onSubnodeClick={handleSubnodeClick} />}</>
                            )}

                            <div className="main-content">
                                <div className="page-content">
                                    <Container fluid>
                                        {subnodeSelected && (
                                            <Row>

                                                <div className="page-title-box d-flex align-items-center justify-content-between">


                                                    {module === "quotes" && (
                                                        <Col xs={12}>
                                                            <div className="float-start desktop">
                                                                <React.Fragment>
                                                                    <Dropdown
                                                                        id="btnDrpQuoteAction"
                                                                        isOpen={isQuoteActionDropdown}
                                                                        toggle={toggleQuoteActionDropdown}
                                                                        className="header-item topbar-user topbar-quote">
                                                                        <DropdownToggle
                                                                            id="btnDrpTgQuoteAction"
                                                                            tag="button"
                                                                            type="button"
                                                                            className="btn">
                                                                            <span className="d-flex align-items-center">
                                                                                <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                                <span className="text-start ms-xl-2">
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Quote Action
                                                                                    </span>
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                            <h6 className="dropdown-header">Open</h6>
                                                                            <DropdownItem id="btnDrpItemOpQt" onClick={toggleQuotesModal}>
                                                                                <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Open Quote
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header"> General</h6>

                                                                            <DropdownItem id="btnQuoteDrpItemQT" onClick={toggleTermsDialog}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Quote Terms
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemPL"
                                                                                onClick={togglePartConsolidatedDialog}
                                                                                disabled={itemGridCount < 1}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Part List
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemCPWS"
                                                                                onClick={toggleComponentPartsWhse}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Component Parts and Whse SOH
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemIPQB"
                                                                                onClick={toggleComponentItemPriceQtyBreaks}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Item Price Qty Breaks
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Import</h6>

                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemIOQ"
                                                                                onClick={toggleOldQuoteOpen}>
                                                                                <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Import Old Quotes
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Share</h6>

                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemSQ"
                                                                                onClick={toggleShareQuote}>
                                                                                <i className="align-middle ri-share-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Share Quote
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Save As</h6>

                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemSR"
                                                                                onClick={toggleRevision}
                                                                                disabled={(quoteApprovalStatId == -1) ? true : (quoteNumber == undefined || checkDraft || quoteDetails.length < 1)}
                                                                            >
                                                                                <i className="align-middle ri-save-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Save as Revision
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemSS"
                                                                                onClick={toggleSuffix}
                                                                                disabled={(quoteApprovalStatId == -1) ? true : (quoteNumber == undefined || checkDraft || quoteDetails.length < 1)}
                                                                            >
                                                                                <i className="align-middle ri-save-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Save as Suffix
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemSN"
                                                                                onClick={toggleSaveAsNew}
                                                                            >
                                                                                <i className="align-middle ri-save-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Save as New
                                                                                </span>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                    <Dropdown
                                                                        id="btnDrpQuoteSettings"
                                                                        isOpen={isQuoteSettingsDropdown}
                                                                        toggle={toggleQuoteSettingsDropdown}
                                                                        className="header-item topbar-user">
                                                                        <DropdownToggle
                                                                            id="btnDrpTgQuoteSettings"
                                                                            tag="button"
                                                                            type="button"
                                                                            className="btn">
                                                                            <span className="d-flex align-items-center">
                                                                                <i className="align-middle ri-settings-3-line ip-font-btn"></i>
                                                                                <span className="text-start ms-xl-2">
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Quote Setting
                                                                                    </span>
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id="btnDrpMenuQuoteSettings" className="dropdown-menu-end">
                                                                            <h6 className="dropdown-header">
                                                                                Quote Settings
                                                                            </h6>
                                                                            <DropdownItem
                                                                                id="btnDrpItemRUP"
                                                                                onClick={toggleRoundupPrice}
                                                                                disabled={itemGridCount < 1}>
                                                                                <i className="align-middle ri-funds-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Roundup Price
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnDrpItemQC"
                                                                                onClick={toggleQuoteContactDialog}
                                                                                //</DropdownMenu>  
                                                                                disabled={quoteDetails[id]?.selectedCustomers?.length < 1 || quoteDetails[id]?.selectedCustomers?.length == undefined}
                                                                            >
                                                                                <i className="align-middle ri-contacts-book-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Quote Contact
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnDrpItemSTDP"
                                                                                onClick={toggleResetQuoteDialog}
                                                                                disabled={itemGridCount < 1}>
                                                                                <i className="align-middle ri-refund-2-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Set to Default Price
                                                                                </span>
                                                                            </DropdownItem>

                                                                            {authType === "General" ? (
                                                                                <DropdownItem
                                                                                    id="btnDrpItemSPM"
                                                                                    onClick={
                                                                                        toggleEditQuotePriceandMarginDialog
                                                                                    }
                                                                                    disabled>
                                                                                    <i className="align-middle ri-price-tag-3-line me-2 ip-font-btn"></i>
                                                                                    <span className="align-middle">
                                                                                        Set Margin or Price
                                                                                    </span>
                                                                                </DropdownItem>
                                                                            ) : (
                                                                                <DropdownItem
                                                                                    id="btnDrpItemSPM"
                                                                                    onClick={
                                                                                        toggleEditQuotePriceandMarginDialog
                                                                                    }
                                                                                    disabled={itemGridCount < 1}>
                                                                                    <i className="align-middle ri-price-tag-3-line me-2 ip-font-btn"></i>
                                                                                    <span className="align-middle">
                                                                                         Set Margin or Price
                                                                                    </span>
                                                                                </DropdownItem>
                                                                            )}
                                                            
                                                                            <div className="dropdown-divider"></div>

                                                                            <DropdownItem id="btnDrpItemSTOQ">

                                                                                <div
                                                                                    className="ip-submenu-show-total"
                                                                                    title="Shows the total on the quote at export and preview screens">
                                                                                    <Checkbox
                                                                                        id="btnCheckBoxSTOQ"
                                                                                        type="checkbox"
                                                                                        checked={!hideTotalState[id]}
                                                                                        onChange={() => handleShowtotal(id)} // Pass the id to the handler
                                                                                        className="me-2"
                                                                                    />

                                                                                    <span
                                                                                        className="align-middle"
                                                                                        check
                                                                                        onClick={() => handleShowtotal(id)}>
                                                                                        Show Total on Quote
                                                                                    </span>
                                                                                </div>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                </React.Fragment>
                                                            </div>

                                                            <div className="float-end desktop">

                                                                <div className={`me-2 appr-secondarymenu-btn ${!approvalStarted ? 'displaynoneimp' : ''}`} title="Approval workflow started">
                                                                    <i className="align-middle ri-checkbox-multiple-line me-2 ip-font-btn"></i>
                                                                    Approval <span>1</span>
                                                                </div>
                                                                <div className={`ip-line-seperator ${!approvalStarted ? 'displaynone' : ''}`}></div>

                                                                <Button
                                                                    id="btnPreview"
                                                                    className="me-2 ip-secondarymenu-btn"
                                                                    title="Print Preview"
                                                                    onClick={handlePreviewClick}>
                                                                    <i className="align-middle ri-slideshow-3-line me-2 ip-font-btn"></i>
                                                                    Preview
                                                                </Button>
                                                                <Dropdown
                                                                    id="btnDrpExport"
                                                                    isOpen={isQuoteExportDropdown}
                                                                    toggle={toggleQuoteExportDropdown}
                                                                    className="header-item topbar-user">
                                                                    <DropdownToggle
                                                                        id="btnDrpTgExport"
                                                                        tag="button"
                                                                        type="button"
                                                                        className="btn">
                                                                        <span className="d-flex align-items-center">
                                                                            <i className="align-middle ri-download-2-line ip-font-btn"></i>
                                                                            <span className="text-start ms-xl-2">
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Export
                                                                                </span>
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu id="btnDrpMenuExport" className="dropdown-menu-end">
                                                                        <h6 className="dropdown-header">Export</h6>

                                                                        <DropdownItem id="btnDrpItemPE" onClick={handleExportClick}>
                                                                            <i className="align-middle ri-file-pdf-2-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">PDF Export</span>
                                                                        </DropdownItem>
                                                                        <DropdownItem id="btnDrpItemEE" onClick={handleExportExcelClick}
                                                                            disabled={!(quoteDetails[id]?.selectedItems)}
                                                                        >
                                                                            <i className="align-middle ri-file-excel-2-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Excel Export
                                                                            </span>
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>


                                                                <div className="d-flex">
                                                                    <div className="ip-line-seperator"></div>
                                                                    <div className="col">
                                                                        <div className="d-flex flex-row-reverse">
                                                                            <button
                                                                                id="btnQteHide"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Hide"
                                                                                onClick={navigateToDashboard}>
                                                                                <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                                                                            </button>
                                                                            {quoteDetails[id]?.savedQuote && (
                                                                                <button
                                                                                    id="btnQteDelete"
                                                                                    disabled={(quoteApprovalStatId >= 4 || quoteApprovalStatId === 2) || (((quoteDetails[id]?.savedQuote?.createdBy) !== USER_ID) && quoteDetails[id]?.savedQuote?.createdBy != undefined) ? true : false}
                                                                                    type="button"
                                                                                    className="btn btn-icon waves-effect waves-light mx-1 quote-another-person"
                                                                                    title={((quoteApprovalStatId >= 4) || (((quoteDetails[id]?.savedQuote?.createdBy) !== USER_ID) && quoteDetails[id]?.savedQuote?.createdBy != undefined)) ? "Delete option disabled: You cannot delete another person's quote" : "Delete Permanently"}
                                                                                    onClick={handleDelete}>
                                                                                    <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                                                                </button>
                                                                            )}
                                                                            <button
                                                                                id="btnQteSave"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1 quote-another-person"
                                                                                title={(quoteApprovalStatId >= 4) || isQuoteSaved || (((quoteDetails[id]?.savedQuote?.createdBy) !== USER_ID) && quoteDetails[id]?.savedQuote?.createdBy != undefined) ? "Save option disabled: You cannot overwrite another person's quote. Please use 'Save as New' or 'Save as Suffix/Revision' options instead" : "Save Quote"}
                                                                                disabled={(quoteApprovalStatId >= 4 || quoteApprovalStatId === 2) || isQuoteSaved || (((quoteDetails[id]?.savedQuote?.createdBy) !== USER_ID) && quoteDetails[id]?.savedQuote?.createdBy != undefined) ? true : false}
                                                                                onClick={() => {
                                                                                    triggerSaveQuotation();
                                                                                }}
                                                                                //style={{ display: isQuoteSaved ? 'none' : 'inline-block' }} // Conditional style based on isQuoteSaved
                                                                                >
                                                                                
                                                                             <i className="ri-save-3-line ip-icon-size-lg blue"></i> 
                                                                            </button>
                                                                            {/* <button
                                                                                    type="button"
                                                                                    className="btn btn-icon waves-effect waves-light mx-1 border-0"
                                                                                    title="Save As Quote"
                                                                                    onClick={() => {
                                                                                        triggerSaveAsQuotation();
                                                                                    }}
                                                                                    disabled={(visibleSaveAs || updatebyOwn)? false : true }
                                                                                //  disabled={(!visibleSaveAs || quoteCreatedbyUser !== USER_ID) ? false : true}
                                                                                    >
                                                                                    <i className="ri-draft-line ip-icon-size-lg blue"></i>
                                                                                </button> */}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="float-end mobile">
                                                                <div className="btn-group" role="group">
                                                                    <SplitButton text="Action" className="me-2"> 
                                                                         <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                    </SplitButton>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {module === "new-units" && (
                                                        <Col xs={12}>
                                                            <div className="float-start desktop">
                                                                <React.Fragment>
                                                                    <Dropdown
                                                                        id="btnDrpQuoteAction"
                                                                        isOpen={isQuoteActionDropdown}
                                                                        toggle={toggleQuoteActionDropdown}
                                                                        className="header-item topbar-user">
                                                                        <DropdownToggle
                                                                            id="btnDrpTgQuoteAction"
                                                                            tag="button"
                                                                            type="button"
                                                                            className="btn">
                                                                            <span className="d-flex align-items-center">
                                                                                <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                                <span className="text-start ms-xl-2">
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Action
                                                                                    </span>
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                            <h6 className="dropdown-header">Open</h6>
                                                                            <DropdownItem id="btnDrpItemOpQt" onClick={toggleQuotesModal}>
                                                                                <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Open Quote
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">
                                                                                General
                                                                            </h6>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemCPWS"
                                                                                onClick={toggleComponentPartsWhse}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Component Parts and Whse SOH
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemIPQB"
                                                                                onClick={toggleComponentItemPriceQtyBreaks}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Item Price Qty Breaks
                                                                                </span>
                                                                            </DropdownItem>

                                                                            {/*<div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Import</h6>

                                                                            <DropdownItem
                                                                                onClick={toggleOldQuoteOpen}>
                                                                                <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Import Old Quotes
                                                                                </span>
                                                                            </DropdownItem>*/}

                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                </React.Fragment>
                                                            </div>

                                                            <div className="float-end desktop">

                                                                <div className={`me-2 appr-secondarymenu-btn ${!approvalStarted ? 'displaynoneimp' : ''}`} title="Approval workflow started">
                                                                    <i className="align-middle ri-checkbox-multiple-line me-2 ip-font-btn"></i>
                                                                    Approval <span>1</span>
                                                                </div>
                                                                {/*<div className={`ip-line-seperator ${!approvalStarted ? 'displaynone' : ''}`}></div>*/}

                                                                <div className="d-flex">
                                                                    {/* <div className="ip-line-seperator"></div> */}
                                                                    <div className="col">
                                                                        <div className="d-flex flex-row-reverse">
                                                                            <button
                                                                                id="btnNewUnitHide"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Hide"
                                                                                onClick={navigateToDashboard}>
                                                                                <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                                                                            </button>
                                                                            {true && (
                                                                                <button
                                                                                    id="btnNewUnitDelete"
                                                                                    type="button"
                                                                                    className="btn btn-icon waves-effect waves-light mx-1"
                                                                                    title="Delete Permanently"
                                                                                    onClick={handleDeleteUnit}>
                                                                                    <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                                                                </button>
                                                                            )}
                                                                            <button
                                                                                id="btnNewUnitSave"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Save Units"
                                                                                onClick={triggerSaveUnit}>
                                                                                <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="float-end mobile">
                                                                <div className="btn-group" role="group">
                                                                    <SplitButton text="Action" className="me-2"> 
                                                                         <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                    </SplitButton>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )}

                                                    {module === "unit" && (
                                                        <Col xs={12}>
                                                            <div className="float-start desktop">
                                                                <React.Fragment>
                                                                    <Dropdown
                                                                        id="btnDrpQuoteAction"
                                                                        isOpen={isQuoteActionDropdown}
                                                                        toggle={toggleQuoteActionDropdown}
                                                                        className="header-item topbar-user">
                                                                        <DropdownToggle
                                                                            id="btnDrpTgQuoteAction"
                                                                            tag="button"
                                                                            type="button"
                                                                            className="btn">
                                                                            <span className="d-flex align-items-center">
                                                                                <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                                <span className="text-start ms-xl-2">
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Action
                                                                                    </span>
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                            <h6 className="dropdown-header">Open</h6>
                                                                            <DropdownItem id="btnDrpItemOpQt" onClick={toggleQuotesModal}>
                                                                                <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Open Quote
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">
                                                                                General
                                                                            </h6>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemCPWS"
                                                                                onClick={toggleComponentPartsWhse}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Component Parts and Whse SOH
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemIPQB"
                                                                                onClick={toggleComponentItemPriceQtyBreaks}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Item Price Qty Breaks
                                                                                </span>
                                                                            </DropdownItem>
                                                                            

                                                                            {/*<div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Import</h6>

                                                                            <DropdownItem
                                                                                onClick={toggleOldQuoteOpen}>
                                                                                <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Import Old Quotes
                                                                                </span>
                                                                            </DropdownItem>*/}

                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                </React.Fragment>
                                                            </div>

                                                            <div className="float-end desktop">

                                                                <div className={`me-2 appr-secondarymenu-btn ${!approvalStarted ? 'displaynoneimp' : ''}`} title="Approval workflow started">
                                                                    <i className="align-middle ri-checkbox-multiple-line me-2 ip-font-btn"></i>
                                                                    Approval <span>1</span>
                                                                </div>
                                                                {/*<div className={`ip-line-seperator ${!approvalStarted ? 'displaynone' : ''}`}></div>*/}

                                                                <div className="d-flex">
                                                                    {/* <div className="ip-line-seperator"></div> */}
                                                                    <div className="col">
                                                                        <div className="d-flex flex-row-reverse">
                                                                            <button
                                                                                id="btnUnitHide"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Hide"
                                                                                onClick={navigateToDashboard}>
                                                                                <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                                                                            </button>
                                                                            {true && (
                                                                                <button
                                                                                    id="btnUnitDelete"
                                                                                    type="button"
                                                                                    className="btn btn-icon waves-effect waves-light mx-1"
                                                                                    title="Delete Permanently"
                                                                                    onClick={handleDeleteUnit}>
                                                                                    <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                                                                </button>
                                                                            )}
                                                                            <button
                                                                                id="btnUnitSave"
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Save Units"
                                                                                onClick={triggerSaveUnit}>
                                                                                <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                                                                            </button>

                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="float-end mobile">
                                                                <div className="btn-group" role="group">
                                                                    <SplitButton text="Action" className="me-2"> 
                                                                         <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                    </SplitButton>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )}


                                                    {module === "price-lists" && (
                                                        <Col xs={12}>
                                                            <div className="float-start desktop">
                                                                <React.Fragment>
                                                                    <Dropdown
                                                                        id="btnDrpQuoteAction"
                                                                        isOpen={isQuoteActionDropdown}
                                                                        toggle={toggleQuoteActionDropdown}
                                                                        className="header-item topbar-user">
                                                                        <DropdownToggle
                                                                            id="btnDrpTgQuoteAction"
                                                                            tag="button"
                                                                            type="button"
                                                                            className="btn">
                                                                            <span className="d-flex align-items-center">
                                                                                <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                                <span className="text-start ms-xl-2">
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        Action
                                                                                    </span>
                                                                                    <span className="d-none d-xl-inline-block ms-1">
                                                                                        <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                    </span>
                                                                                </span>
                                                                            </span>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                            <h6 className="dropdown-header">Open</h6>
                                                                            <DropdownItem id="btnDrpItemOpQt" onClick={toggleQuotesModal}>
                                                                                <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Open Quote
                                                                                </span>
                                                                            </DropdownItem>

                                                                            <div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">
                                                                                General
                                                                            </h6>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemCPWS"
                                                                                onClick={toggleComponentPartsWhse}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Component Parts and Whse SOH
                                                                                </span>
                                                                            </DropdownItem>
                                                                            <DropdownItem
                                                                                id="btnQuoteDrpItemIPQB"
                                                                                onClick={toggleComponentItemPriceQtyBreaks}>
                                                                                <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Item Price Qty Breaks
                                                                                </span>
                                                                            </DropdownItem>
                                                                            

                                                                            {/*<div className="dropdown-divider"></div>
                                                                            <h6 className="dropdown-header">Import</h6>

                                                                            <DropdownItem
                                                                                onClick={toggleOldQuoteOpen}>
                                                                                <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                                <span className="align-middle">
                                                                                    Import Old Quotes
                                                                                </span>
                                                                            </DropdownItem>*/}

                                                                        </DropdownMenu>
                                                                    </Dropdown>

                                                                </React.Fragment>
                                                            </div>

                                                            <div className="float-end desktop">

                                                                <div className={`me-2 appr-secondarymenu-btn ${!approvalStarted ? 'displaynoneimp' : ''}`} title="Approval workflow started">
                                                                    <i className="align-middle ri-checkbox-multiple-line me-2 ip-font-btn"></i>
                                                                    Approval <span>1</span>
                                                                </div>
                                                                <div className={`ip-line-seperator ${!approvalStarted ? 'displaynone' : ''}`}></div>

                                                                <Dropdown
                                                                    id="btnDrpExport"
                                                                    isOpen={isQuoteExportDropdown}
                                                                    toggle={toggleQuoteExportDropdown}
                                                                    className="header-item topbar-user displaynoneimp">
                                                                    <DropdownToggle
                                                                        id="btnDrpTgExport"
                                                                        tag="button"
                                                                        type="button"
                                                                        className="btn">
                                                                        <span className="d-flex align-items-center">
                                                                            <i className="align-middle ri-download-2-line ip-font-btn"></i>
                                                                            <span className="text-start ms-xl-2">
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Export
                                                                                </span>
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu id="btnDrpMenuExport" className="dropdown-menu-end">
                                                                        <h6 className="dropdown-header">Export</h6>

                                                                        <DropdownItem id="btnDrpItemEE" onClick={handleExportExcelClick}
                                                                            disabled={!(quoteDetails[id]?.selectedItems)}
                                                                        >
                                                                            <i className="align-middle ri-file-excel-2-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Excel Export
                                                                            </span>
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </Dropdown>{/*Temporary hidden with displaynoneimp since feature not implemented*/}
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-icon waves-effect waves-light mx-1"
                                                                    title="Hide"
                                                                    onClick={navigateToDashboard}>
                                                                    <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                                                                </button>
                                                                {/*<div className="d-flex">
                                                                    <div className="ip-line-seperator"></div>
                                                                    <div className="col">
                                                                        <div className="d-flex flex-row-reverse">
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Hide"
                                                                                onClick={navigateToDashboard}>
                                                                                <i className="ri-eye-off-line ip-icon-size-lg blue"></i>
                                                                            </button>

                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Delete Permanently"
                                                                            //onClick={handleDelete}
                                                                            >
                                                                                <i className="ri-delete-bin-6-line ip-icon-size-lg red"></i>
                                                                            </button>

                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-icon waves-effect waves-light mx-1"
                                                                                title="Save Pricing"
                                                                            //onClick={() => {
                                                                            //    triggerSaveQuotation();
                                                                            // }}
                                                                            >
                                                                                <i className="ri-save-3-line ip-icon-size-lg blue"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    </div>*/}

                                                            </div>

                                                            <div className="float-end mobile">
                                                                <div className="btn-group" role="group">
                                                                    <SplitButton text="Action" className="me-2"> 
                                                                         <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                    </SplitButton>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    )}

                                                </div>

                                                {(quoteApprovalStatId !== null && quoteApprovalStatId !== 0) && (
                                                <> 
                                                {/* Approval Required */}
                                                <div className={(quoteApprovalStatId === 1 ? "appr-msg-container appr-msg-container-show" : "appr-msg-container")}>
                                                    <Col xs={12} className="h-100">
                                                        <div className="row d-flex justify-content-evenly align-items-center h-100">
                                                            <div className={`${quoteApprovalStatId === 1 && (accounts[0]?.localAccountId !== quoteDetails[id]?.savedQuote?.createdBy) ? 'col px-4 d-flex appr-req-msg k-whitespace-pre' : 'col px-4 d-flex appr-req-msg'}`}>{quoteApprovalMesasge}</div>
                                                            <div className={`col px-2 d-flex justify-content-end align-items-center`}> 
                                                                <div className={`${quoteApprovalStatId === 1 && (accounts[0]?.localAccountId !== quoteDetails[id]?.savedQuote?.createdBy) ? 'me-4 py-2 appr-status darkorange' : 'me-2 appr-status darkorange'}`} data-tooltip-id="tooltip-approval-required-1"><span>Status: </span>Approval Required <i className="align-middle ri-information-2-fill ms-1 me-2"></i></div>
                                                                <Tooltip
                                                                    id="tooltip-approval-required-1"
                                                                    html={`<div class='appr-tooltip-msg'>${quoteApprovalMesasge}<br /><span> ${(approvalDateTime ? `Sent on: ` + (approvalDateTime).toLocaleString() : ``)}</span><br /></div>`}
                                                                    place='bottom'
                                                                />
                                                                <Button
                                                                    className="me-2 appr-msg-btn mx-4 px-4"
                                                                    onClick={handleSendForApproval}
                                                                    hidden={quoteApprovalStatId === 1 && (accounts[0]?.localAccountId === quoteDetails[id]?.savedQuote?.createdBy) ? false : true}
                                                                >
                                                                    <i className="align-middle ri-mail-send-line me-2 ip-font-btn"></i>
                                                                    Send For Approval
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                               {/* Approval request & sent */}
                                                <div className={(quoteApprovalStatId === 2 ? "appr-msg-container appr-msg-container-show" : "appr-msg-container")}>
                                                    <Col xs={12} className="h-100">
                                                        <div className="col d-flex justify-content-evenly align-items-center h-100">
                                                            {/* <div className={`row px-4 d-flex appr-req-msg ${quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'displaynoneimp' : ''}`}>{quoteApprovalMesasge}</div> */}
                                                            <div className={`row px-4 d-flex appr-req-msg`}>{quoteApprovalMesasge}</div>
                                                            <div className={`col d-flex justify-content-end align-items-center ${quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'ps-3 px-0 py-0' : 'ps-2 px-2 py-2'}`}>
                                                                <div className={`mx-2 appr-status ${quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'orange' : 'blue'}`} data-tooltip-id="tooltip-approval-required-2"><span>Status: </span>{quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'Approval Requested' : 'Approval Sent'} <i className="align-middle ri-information-2-fill ms-1 me-2"></i></div>
                                                                    <Tooltip
                                                                        id="tooltip-approval-required-2" //approverComment
                                                                        html={`${approvalComment.length > 0 ? `<div class='appr-tooltip-msg'>${approvalComment}<br />` : ``}<span>${(approvalDateTime ? `Sent on: ` + (approvalDateTime).toLocaleString() : ``)}</span></div>`}
                                                                        place='bottom'
                                                                    />

                                                                <Button
                                                                    hidden={quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? false : true}
                                                                    className={`appr-msg-btn ${quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'mx-4 px-4 me-2' : ''}`}
                                                                    onClick={() => { toggleSendApproval() }}
                                                                >
                                                                    <i className="align-middle ri-mail-send-line me-2 ip-font-btn"></i>
                                                                    Proceed for Approval
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>

                                                {/* Approved */}
                                                <div className={(quoteApprovalStatId === 4 ? "appr-msg-container appr-msg-container-show" : "appr-msg-container")}>
                                                    <Col xs={12} className="h-100">
                                                        <div className="row d-flex justify-content-evenly align-items-center h-100">
                                                            <div className="col px-4 py-2 d-flex justify-content-end align-items-center">
                                                                <div className="mx-2 appr-status green" data-tooltip-id="tooltip-approval-required-3"><span>Status: </span>Approved <i className="align-middle ri-information-2-fill ms-1 me-2"></i></div>
                                                                <Tooltip
                                                                    id="tooltip-approval-required-3"
                                                                    html={`<div class='appr-tooltip-msg'>${approvalComment}<br /><span>${(approvalDateTime ? `By: ` + (apprvName.length > 0 ? apprvName : approverName) + ` Manager on: ` + (approvalDateTime).toLocaleString() : ``)}</span><br /></span></div>`}
                                                                    place='bottom'
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>

                                                {/* Need Change */}
                                                <div className={(quoteApprovalStatId === 3 ? "appr-msg-container appr-msg-container-show" : "appr-msg-container")}>
                                                    <Col xs={12} className="h-100">
                                                        <div className="col d-flex justify-content-evenly align-items-center h-100">
                                                            {/* <div className={`row px-4 d-flex appr-req-msg ${quoteApprovalStatId === 2 && accounts[0]?.localAccountId === currentApprover ? 'displaynoneimp' : ''}`}>{quoteApprovalMesasge}</div> */}
                                                            <div className={`row px-4 d-flex appr-req-msg`}>{quoteApprovalMesasge}</div>
                                                            <div className={`col d-flex justify-content-end align-items-center ps-3 px-0 py-0`}>
                                                                    <div className="mx-2 appr-status purple" data-tooltip-id="tooltip-approval-required-4"><span>Status: </span>Need Change <i className="align-middle ri-information-2-fill ms-1 me-2"></i></div>
                                                                
                                                                    <Tooltip
                                                                        id="tooltip-approval-required-4"
                                                                        html={`<div class='appr-tooltip-msg'>${approvalComment}<br /><span>${(approvalDateTime ? `By: ` + (apprvName.length > 0 ? apprvName : approverName) + ` Manager on: ` + (approvalDateTime).toLocaleString() : ``)}</span><br /></span></div>`}
                                                                        place='bottom'
                                                                    />
                                                                    <Button className="appr-msg-btn ms-1 px-4" style={{ marginRight: '-5px' }}
                                                                        hidden={accounts[0]?.localAccountId !== quoteDetails[id]?.savedQuote?.createdBy ? true : false}
                                                                        onClick={handleSendForApproval}
                                                                    >
                                                                        <i className="align-middle ri-mail-send-line me-2 ip-font-btn"></i>
                                                                        Send For Re-Approval
                                                                    </Button>
                                                            </div>
                                                        </div>

                                                        
                                                    </Col>
                                                </div>

                                                {/* Rejected */}
                                                <div className={(quoteApprovalStatId === 5 ? "appr-msg-container appr-msg-container-show" : "appr-msg-container")}>
                                                    <Col xs={12} className="h-100">
                                                        <div className="row d-flex justify-content-evenly align-items-center h-100">
                                                            <div className="col px-4 d-flex justify-content-end align-items-center">
                                                                <div className="mx-2 py-2 appr-status red" data-tooltip-id="tooltip-approval-required-4"><span>Status: </span>Rejected <i className="align-middle ri-information-2-fill ms-1 me-2"></i></div>
                                                                <Tooltip
                                                                    id="tooltip-approval-required-4"
                                                                    html={`<div class='appr-tooltip-msg'>${approvalComment}<br /><span>${(approvalDateTime ? `By: ` + (apprvName.length > 0 ? apprvName : approverName) + ` Manager on: ` + (approvalDateTime).toLocaleString() : ``)}</span><br /></span></div>`}
                                                                    place='bottom'
                                                                />
                                                                {/* <Button 
                                                                    className="appr-msg-btn ms-1 px-4" style={{marginRight: '-5px'}}
                                                                    onClick={() => {handleCreateNewQuoteFromRejected()}}
                                                                >
                                                                    <i className="align-middle ri-draft-line me-2 ip-font-btn"></i>
                                                                    Save a New Quote
                                                                </Button>                                                            */}
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                                </>
                                               )}

                                            </Row>
                                        )}

                                        {module === "library" && (
                                            <Row>
                                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                                    <Col xs={12}>
                                                        <div className="float-start desktop">
                                                            <React.Fragment>
                                                                <Dropdown
                                                                    id="btnDrpQuoteAction"
                                                                    isOpen={isQuoteActionDropdown}
                                                                    toggle={toggleQuoteActionDropdown}
                                                                    className="header-item topbar-user">
                                                                    <DropdownToggle
                                                                        id="btnDrpTgQuoteAction"
                                                                        tag="button"
                                                                        type="button"
                                                                        className="btn">
                                                                        <span className="d-flex align-items-center">
                                                                            <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                            <span className="text-start ms-xl-2">
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Action
                                                                                </span>
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                        <h6 className="dropdown-header">Open</h6>
                                                                        <DropdownItem id="btnDrpItemOpQt" onClick={toggleQuotesModal}>
                                                                            <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Open Quote
                                                                            </span>
                                                                        </DropdownItem>

                                                                        <div className="dropdown-divider"></div>
                                                                        <h6 className="dropdown-header">
                                                                            General
                                                                        </h6>
                                                                        <DropdownItem
                                                                            id="btnQuoteDrpItemCPWS"
                                                                            onClick={toggleComponentPartsWhse}>
                                                                            <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Component Parts and Whse SOH
                                                                            </span>
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            id="btnQuoteDrpItemIPQB"
                                                                            onClick={toggleComponentItemPriceQtyBreaks}>
                                                                            <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Item Price Qty Breaks
                                                                            </span>
                                                                        </DropdownItem>
                                                                        

                                                                        {/*<div className="dropdown-divider"></div>
                                                                    <h6 className="dropdown-header">Import</h6>
                        
                                                                   <DropdownItem
                                                                        onClick={toggleOldQuoteOpen}>
                                                                        <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                        <span className="align-middle">
                                                                            Import Old Quotes
                                                                        </span>
                                                                    </DropdownItem>*/}
                                                                    </DropdownMenu>
                                                                </Dropdown>

                                                            </React.Fragment>
                                                        </div>

                                                        <div className="float-end mobile">
                                                            <div className="btn-group" role="group">
                                                                <SplitButton text="Action" className="me-2"> 
                                                                    <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                </SplitButton>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Row>
                                        )}

                                        {url == "dashboard" && (
                                            <Row>
                                                <div className="page-title-box d-flex align-items-center justify-content-between">
                                                    <Col xs={12}>
                                                        <div className="float-start desktop">
                                                            <React.Fragment>
                                                                <Dropdown
                                                                    id="btnDrpQuoteAction"
                                                                    isOpen={isQuoteActionDropdown}
                                                                    toggle={toggleQuoteActionDropdown}
                                                                    className="header-item topbar-user">
                                                                    <DropdownToggle
                                                                        id="btnDrpTgQuoteAction"
                                                                        tag="button"
                                                                        type="button"
                                                                        className="btn">
                                                                        <span className="d-flex align-items-center">
                                                                            <i className="align-middle ip-action-ico ip-font-btn"></i>
                                                                            <span className="text-start ms-xl-2">
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    Action
                                                                                </span>
                                                                                <span className="d-none d-xl-inline-block ms-1">
                                                                                    <i className="ri-arrow-down-s-fill text-muted fs-16 align-middle"></i>
                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu id="btnQuoteDrpMenu" className="dropdown-menu-end">

                                                                        <h6 className="dropdown-header">Open</h6>
                                                                        <DropdownItem id="btnDrpItemOpQt" onClick={handleOpenQuote}>
                                                                            <i className="align-middle ri-folder-open-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Open Quote
                                                                            </span>
                                                                        </DropdownItem>

                                                                        <div className="dropdown-divider"></div>
                                                                        <h6 className="dropdown-header">
                                                                            General
                                                                        </h6>
                                                                        <DropdownItem
                                                                            id="btnQuoteDrpItemCPWS"
                                                                            onClick={toggleComponentPartsWhse}>
                                                                            <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Component Parts and Whse SOH
                                                                            </span>
                                                                        </DropdownItem>
                                                                        <DropdownItem
                                                                            id="btnQuoteDrpItemIPQB"
                                                                            onClick={toggleComponentItemPriceQtyBreaks}>
                                                                            <i className="align-middle ri-file-list-3-line me-2 ip-font-btn"></i>
                                                                            <span className="align-middle">
                                                                                Item Price Qty Breaks
                                                                            </span>
                                                                        </DropdownItem>
                                                                    

                                                                        {/*<div className="dropdown-divider"></div>
                                                                    <h6 className="dropdown-header">Import</h6>
                        
                                                                   <DropdownItem
                                                                        onClick={toggleOldQuoteOpen}>
                                                                        <i className="align-middle ri-download-2-line me-2 ip-font-btn"></i>
                                                                        <span className="align-middle">
                                                                            Import Old Quotes
                                                                        </span>
                                                                    </DropdownItem>*/}
                                                                    </DropdownMenu>
                                                                </Dropdown>

                                                            </React.Fragment>
                                                        </div>

                                                        <div className="float-end mobile">
                                                            <div className="btn-group" role="group">
                                                                <SplitButton text="Action" className="me-2"> 
                                                                    <SplitButtonItem text="Not ready for mobile view, please continue on desktop view" />
                                                                </SplitButton>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </div>
                                            </Row>
                                        )}

                                        <DisplayDashboard
                                            showHideContent={showHideContent}
                                            onSubnodeClick={handleSubnodeClick}
                                        />
                                    </Container>
                                    {props.children}
                                </div>
                            </div>
                        </div>

                        <br />
                        <br />

                        {quotesDialogVisible && (
                            <Dialog
                                className="ip-dialog-selectquote"
                                title={"Select Quote"}
                                onClose={() => {
                                    setQuotesDialogVisible(false);
                                }}>
                                {/* <QuotesLookup  handleSelectQuote={handleSelectQuote} /> */}
                                <QuotesLookup
                                    toggleQuotesModal={toggleQuotesModal}
                                    getQuoteDetailsById={getQuoteDetailsById}
                                    setSelectedRowsParent={null}
                                    selectedRows={selectedRows}
                                    setSelectedRows={setSelectedRows}
                                    defaultTab={defaultTab}
                                />

                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button id="btnOpenQte" className="px-4 me-2 ip-button-general" onClick={navigateToQuotes}>
                                            Open
                                        </Button>
                                        <Button id="btnOpenQteCancel" className="px-4" onClick={toggleQuotesModal}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {systemSettingFormVisible && (
                            <Dialog
                                className="ip-dialog-container-semi"
                                title={"System Settings"}
                                onClose={() => setSystemSettingFormVisible(false)}>
                                <SystemSettingForm setSystemSettingFormVisible={setSystemSettingFormVisible} />
                            </Dialog>
                        )}

                        {termsDialogVisible && (
                            <Dialog width="60%" title={"Terms And Conditions"} onClose={toggleTermsDialog}>
                                <TermsAndConditions
                                    updateTrigger={updateTrigger}
                                    setTermsDialogVisible = {setTermsDialogVisible}
                                />

                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button id="btnTCDone" className="px-4 me-2 ip-button-general" onClick={handleDoneClick}>
                                            Done
                                        </Button>
                                        <Button id="btnTCCancel" className="px-4" onClick={toggleTermsDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {partConsolidatedDialogVisible && (
                            <Dialog
                                width="100%"
                                title={"Parts Consolidated View"}
                                onClose={togglePartConsolidatedDialog}>
                                <PartsConsolidatedView id={id} />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={handleApplyActionForPartsConsolidatedView}
                                            disabled={isApplyActionForParts}>
                                            Apply
                                        </Button>
                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={handleOkActionForPartsConsolidatedView}>
                                            Done
                                        </Button>
                                        <Button className="px-4" onClick={toggleCancelPartConsolidatedDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {componentPartsWhseDialogVisible && (
                            <Dialog
                                className="ip-dialog-whse"
                                width="100%"
                                title={"Stock Availability by Warehouse"}
                                onClose={toggleComponentPartsWhse}>
                                <ComponentPartsWhse />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        {ststatus === "M" || ststatus === "K" || compstatus === "M" || compstatus === "K"  || ststatus === undefined ? (
                                            <>
                                                <Button
                                                    className="px-4 me-2"
                                                    onClick={toggleComponentPartsWhseSub}
                                                    disabled={componentPartsWhseDialogVisibleSub}>
                                                    Show Components
                                                </Button>
                                                {/* {componentPartsWhseDialogVisibleSub && <ComponentPartsWhseSOHSub />} */}
                                            </>
                                        ) : (
                                            <Button
                                                className="px-4 me-2"
                                                onClick={toggleComponentPartsWhseSub}
                                                disabled={componentPartsWhseDialogVisible}>
                                                Show Components
                                            </Button>
                                        )}
                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={toggleComponentPartsWhse}>
                                            Close
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                        {componentPartsWhseDialogVisibleSub && (
                            <Dialog
                                className="ip-dialog-whse"
                                width="85%"
                                height="70%"
                                title={"Component Parts Availability by Warehouse"}
                                onClose={toggleComponentPartsWhseSub}>
                                <ComponentPartsWhseSOHSub />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        {compstatus === "M" || compstatus === "K" ? (
                                            <>
                                                <Button
                                                    className="px-4 me-2"
                                                    onClick={toggleComponentPartsWhseSubB}
                                                    disabled={componentPartsWhseDialogVisibleSubB}>
                                                    Show Components
                                                </Button>
                                                {/* {componentPartsWhseDialogVisibleSub && <ComponentPartsWhseSOHSub />} */}
                                            </>
                                        ) : (
                                            <Button
                                                className="px-4 me-2"
                                                onClick={toggleComponentPartsWhseSubB}
                                                disabled={componentPartsWhseDialogVisible}>
                                                Show Components
                                            </Button>
                                        )}

                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={toggleComponentPartsWhseSub}>
                                            Close
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                        {componentPartsWhseDialogVisibleSubB && (
                            <Dialog
                                className="ip-dialog-whse"
                                width="80%"
                                height={"60%"}
                                title={"Component Parts Availability by Warehouse"}
                                onClose={toggleComponentPartsWhseSubB}>
                                <ComponentPartsWhseSubSOH />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button
                                            className="px-4 me-2"
                                            onClick={toggleComponentPartsWhseSubB}
                                            disabled={componentPartsWhseDialogVisible}>
                                            Show Components
                                        </Button>
                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={toggleComponentPartsWhseSubB}>
                                            Close
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {editQuoteDialogVisible && (
                            <Dialog title={"Edit Quote Price and Margin"} onClose={toggleEditQuotePriceandMarginDialog}>
                                <EditQuotePriceandMargin />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button
                                            disabled={isValue}
                                            className="px-4 me-2 ip-button-general"
                                            onClick={handleOkSetNewMarginOrTotal}>
                                            Done
                                        </Button>
                                        <Button className="px-4" onClick={toggleEditQuotePriceandMarginDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {componentItemPriceQtyBreaksDialogVisible && (
                            <Dialog
                                className="ip-dialog-whse"
                                width="100%"
                                title={"Item Price Qty Breaks"}
                                onClose={toggleComponentPriceQtyBreakSearchClose}>
                                <StockPriceQtyBreakSearch />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button
                                            className="px-4 me-2 ip-button-general"
                                            onClick={toggleComponentPriceQtyBreakSearchClose}>
                                            Done
                                        </Button>
                                        <Button className="px-4" onClick={toggleComponentPriceQtyBreakSearchClose}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {resetQuoteDialogVisible && (
                            <Dialog title={"Reset Quote"} onClose={toggleResetQuoteDialog}>
                                <ResetQuote handleOkSet={handleOkSet} />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4 me-2 ip-button-general" onClick={handleOkSet}>
                                            Execute
                                        </Button>
                                        <Button className="px-4" onClick={toggleResetQuoteDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                         {graphVisible && ( ///graph
                            <Dialog  width={'70%'} title={"Graph Quote Created"} onClose={closeGraphQuoteDialog}>
                                <div>
                                <div>
                                    
                                    <div className="col-12 col-md-2 float-start" style={{marginRight: "20px"}}>
                                        <strong>View by:</strong>
                                        <DropDownList
                                            id = "btnViewByOption"
                                            data={viewOptionData}
                                            //valueField="headerName" // Add this line
                                            value={viewOption}
                                            onChange={(e) => setViewOption(e.target.value)}
                                            defaultValue={viewOptionData[0]}
                                            style={{ marginLeft: "auto", marginRight: "20px" }}
                                        />
                                    </div>
                                    
                                    <div className="col-12 col-md-2 pe-2 float-start">
                                        <strong>Start Date:</strong>
                                        <DatePicker 
                                            id = "dtpStartDate"
                                            format={"dd/MM/yyyy"}
                                            value={startDate}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                    </div>

                                    <div className="col-12 col-md-2 pe-2 float-start">
                                        <strong>End Date:</strong>
                                        <DatePicker 
                                            id = "dtpStartDate"
                                            format={"dd/MM/yyyy"}
                                            value={endDate}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                    </div>
                                    
                                        <Button className="px-4" onClick={refreshGraph} style={{marginRight: "10px", marginTop: "20px"}}>
                                                Refresh
                                        </Button>
                                        <Button className="px-4" onClick={resetGraph} style={{marginTop: "20px"}}>
                                                Reset
                                        </Button>
                                  

                                </div>
                                <GraphQuotes data={quotesData} /></div>
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4" onClick={closeGraphQuoteDialog}>
                                            Close
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                        {quoteContactDialogVisible && (
                            <Dialog width={'50%'} title={"Quote Contact"} onClose={toggleQuoteContactDialogCancel}>
                                <QuoteContact handleOkSetContact={handleOkSetContact} />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4 me-2 ip-button-general" onClick={handleOkSetContactInBottom}>
                                            Done
                                        </Button>
                                        <Button className="px-4" onClick={toggleQuoteContactDialogCancel}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                        {qcVisible && (
                            <Dialog title={"Confirm to submit"} onClose={toggleDialog}>
                                <p
                                    style={{
                                        margin: "30px",
                                        textAlign: "center",
                                    }}>
                                    Do you want to save changes you made to the 'Quote Settings'?
                                </p>
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-center py-3 px-2">
                                        <Button className="px-5 me-2 ip-button-positive" onClick={handleOk}>
                                            Yes
                                        </Button>
                                        <Button className="px-5 me-2 ip-button-negative" onClick={handleRemove}>
                                            No
                                        </Button>
                                        <Button className="px-5" onClick={toggleDialog}>
                                            Cancel
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}
                        {/* handle isIdle for the modal */}
                        {showModal && (
                            <Dialog
                                className="ip-dialog-whse"
                                width="40%"
                                title={"Idle Timeout Warning"}
                                onClose={handleStayLoggedIn}>
                                <p>You are about to be logged out automatically due to inactivity.</p>
                                <br />
                                Time remaining until logout:{" "}
                                <span className="fw-bold">{millisToMinutesAndSeconds(remainingTime * 1000)}</span>
                                <br />
                                <DialogActionsBar>
                                    <div className="d-flex justify-content-end ip-dialog-btnstatic">
                                        <Button className="px-4 me-2" onClick={handleLogOut}>
                                            Logout
                                        </Button>
                                        <Button className="px-4 me-2 ip-button-general" onClick={handleStayLoggedIn}>
                                            Stay Logged In
                                        </Button>
                                    </div>
                                </DialogActionsBar>
                            </Dialog>
                        )}

                        {oldQuoteUploadDialogVisible && (
                            <ImportOldQuoteDialog
                                visible={oldQuoteUploadDialogVisible}
                                onClose={() => setOldQuoteUploadDialogVisible(false)}
                                onImport={triggerOldQuoteOpen}
                            />
                        )}
                    </>
                </UserContext.Provider>
            ) : (
                <></>
            )}
        </>
    );
};
