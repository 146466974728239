import React, { useState, useEffect } from "react";

const EditableLabourCellNumeric = (props) => {
    const { dataItem, field, onChange, onBlur, index, disabled, rowType, gridData } = props;
    const [localValue, setLocalValue] = useState(dataItem[field]);
    const [previousValidValue, setPreviousValidValue] = useState(dataItem[field]);
    const [hasError, setHasError] = useState(false);
    const [rowChanges, setRowChanges] = useState(false);

    useEffect(() => {
        if (hasError) {
            setLocalValue(previousValidValue);
            setHasError(false);
        }
    }, [hasError, previousValidValue]);

    useEffect(() => {
        setLocalValue(dataItem[field]);
        setPreviousValidValue(dataItem[field]); // Update previous valid value whenever dataItem[field] changes
        const row = Number(sessionStorage.getItem('gridDataLabourRow')||1);
        const keepFocus = ['idLabourCostName','labourMarginField']
        if (!keepFocus.includes(document.activeElement.id)) {
            document.activeElement.id !== "labourInput" ? document.querySelector(".labourInput").focus() : <></>;
            try {
                (document.activeElement.parentElement.parentElement.parentElement.rows[row].cells[5] !== undefined) 
                    ? document.activeElement.parentElement.parentElement.parentElement.rows[row].cells[5].children[0].focus()
                    : <></>;
            } catch (error) {
                console.log(error);
            }
        }
        setRowChanges(false);
    }, [rowChanges === true]);

    //compute the totals in the grid
    const computetotals = (qty) => {
         dataItem["totalCost"] = parseFloat(Number(qty) *  Number(dataItem["labCost"])).toFixed(2);
         dataItem["totalTime"] = parseFloat(Number(qty) *  Number(dataItem["labTime"])).toFixed(0);
    }

    const handleLocalChange = (e) => {
        setLocalValue(e.target.value < 0 ? 0 :e.target.value);
    };
    

    const handleBlur = () => {
        if (localValue !== dataItem[field]) {
            const con = onChange({
                dataItem,
                field,
                value: localValue,
                clearInput: () => setHasError(true), // Trigger error when input is invalid
            });
            dataItem[field] = localValue;
            computetotals(dataItem[field]);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            let row = e.target.parentElement.parentElement.rowIndex + 1;
            if (typeof e.target.parentElement.parentElement.parentElement.rows[row] !== "undefined") {
                if (e.target.parentElement.parentElement.parentElement.rows[row].cells[5] === undefined) {
                    row = row + 1;
                }
                handleBlur();
                sessionStorage.setItem('gridDataLabourRow', row);
                setRowChanges(true);
            } else handleBlur();
            
            
        } else if (e.key === "Tab"
        || e.key === "ArrowLeft" || e.key === "ArrowRight" 
        || e.key === "ArrowUp" || e.key === "ArrowDown") { 
            let row = e.target.parentElement.parentElement.rowIndex;
            if (e.key === "ArrowUp") row = row - 1;
            else if (e.key === "ArrowDown") row = row + 1;

            if (e.target.parentElement.parentElement.parentElement.rows[row] !== undefined) {
                if (e.target.parentElement.parentElement.parentElement.rows[row].cells[5] === undefined) {
                    (e.key === "ArrowDown") ? row = row + 1 : row = row - 1;
                }
                (e.target.parentElement.parentElement.parentElement.rows[row] !== undefined) 
                    ? sessionStorage.setItem('gridDataLabourRow', row)
                    : <></>;
            }
            setRowChanges(true);
        }else {
            if (!/[0-9]/.test(e.key) && !e.key === "Delete" && !e.key === "Backspace") {
                e.preventDefault();
            } else if (/[a-zA-Z]/.test(e.key) && e.key !== "Backspace" && e.key !== "Control" && e.key !== "Shift") {
                e.preventDefault();
            }
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
    };

    const onFocus = (e) => {
        sessionStorage.setItem('gridDataLabourRow', e.target.parentElement.parentElement.rowIndex);
        e.target.parentElement.parentElement.parentElement.rows[e.target.parentElement.parentElement.rowIndex].cells[5].children[0].focus();
    }

    const inputStyle = hasError ? { border: "2px solid red" } : {}; // Style the input border red if there's an error

    if (rowType === "groupHeader") {
      return null
    } else {
        
        return (
            <td onClick={handleClick}>
                <input
                    className="ip-editable-input labourInput"
                    type="numeric"
                    step=".00"
                    min="0"
                    id="labourInput"
                    onFocus={onFocus}
                    value={localValue || ""}
                    onChange={handleLocalChange}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    disabled={disabled}
                    onSubmit={false}
                />
            </td>
        );
    }

};

export default EditableLabourCellNumeric;
